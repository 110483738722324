import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { PincodeValidateControllerService } from 'src/app/api/emr/services';

@Component({
  selector: 'app-pincode-check',
  templateUrl: './pincode-check.component.html',
  styleUrls: ['./pincode-check.component.scss']
})
export class PincodeCheckComponent implements OnInit {

  pincode = new FormControl('',[Validators.required, Validators.pattern(/^[0-9]{6}$/)]);
  validatingPincode: boolean;
  pinInvalid: boolean;
  isuncover:boolean = false;
  constructor(
    private PincodeValidateControllerService : PincodeValidateControllerService,
    private MatDialogRef: MatDialogRef<PincodeCheckComponent>,
    private MatSnackBar: MatSnackBar,
  ) { }

  proceed() {
    if(this.pincode.value) {
      this.MatDialogRef.close(this.pincode.value)
    }
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
    this.pincode.valueChanges.subscribe(value => {
      if(value.length > 5) {
        let pincode ={
          value
        }
        this.validatingPincode = true;
        this.PincodeValidateControllerService.pincodeValidateUsingGET(pincode).subscribe(
          res => {
            if(res === 'true') {
              this.pinInvalid = false;
              this.MatSnackBar.open('Entered Pincode is serviceable.','ok',{duration: 4000,
                verticalPosition: 'top'})
            } else {
              this.pinInvalid = true;
              this.pincode.setErrors({notUnique : true});
              this.MatSnackBar.open('Entered Pincode is not serviceable for home collection of samples.','OK',{duration: 4000,
                verticalPosition: 'top'})
            }
            this.validatingPincode = false;
          },
          err => {
            this.pinInvalid = true;
            this.validatingPincode = false;
            this.pincode.setErrors({notUnique : true});
            this.MatSnackBar.open('Entered Pincode is not serviceable for home collection of samples.','OK',{duration: 4000,
              verticalPosition: 'top'})
          }
        )
      }
    })
  }

}
