import { Injectable } from '@angular/core';

@Injectable()
export class EmbeddedApiService<T> {

  constructor() { }

  parse(model: any) {
    for(let key in model._embedded) {
      model[key] = model._embedded[key];
    }
    return model;
  }
}
