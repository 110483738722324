/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesVital } from '../models/resources-vital-';
import { ResourceVital } from '../models/resource-vital-';
import { Vital } from '../models/vital';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceVitalType } from '../models/resource-vital-type-';

/**
 * $ Proxy 202
 */
@Injectable()
class VitalEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VitalEntityService.FindAllVitalUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllVitalUsingGETResponse(params: VitalEntityService.FindAllVitalUsingGETParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.FindAllVitalUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllVitalUsingGET(params: VitalEntityService.FindAllVitalUsingGETParams): Observable<ResourcesVital> {
    return this.findAllVitalUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveVitalUsingPOSTResponse(body: Vital): Observable<HttpResponse<ResourceVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVital = null;
        _body = _resp.body as ResourceVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVital>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveVitalUsingPOST(body: Vital): Observable<ResourceVital> {
    return this.saveVitalUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.FindByConsultationIdVitalUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdVitalUsingGETResponse(params: VitalEntityService.FindByConsultationIdVitalUsingGETParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitals/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.FindByConsultationIdVitalUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdVitalUsingGET(params: VitalEntityService.FindByConsultationIdVitalUsingGETParams): Observable<ResourcesVital> {
    return this.findByConsultationIdVitalUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdVitalUsingGETResponse(id: number): Observable<HttpResponse<ResourceVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVital = null;
        _body = _resp.body as ResourceVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVital>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdVitalUsingGET(id: number): Observable<ResourceVital> {
    return this.findByIdVitalUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.SaveVitalUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalUsingPUTResponse(params: VitalEntityService.SaveVitalUsingPUTParams): Observable<HttpResponse<ResourceVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVital = null;
        _body = _resp.body as ResourceVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVital>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.SaveVitalUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalUsingPUT(params: VitalEntityService.SaveVitalUsingPUTParams): Observable<ResourceVital> {
    return this.saveVitalUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteVitalUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/vitals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteVitalUsingDELETE(id: number): Observable<void> {
    return this.deleteVitalUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.SaveVitalUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalUsingPATCHResponse(params: VitalEntityService.SaveVitalUsingPATCHParams): Observable<HttpResponse<ResourceVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVital = null;
        _body = _resp.body as ResourceVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVital>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.SaveVitalUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalUsingPATCH(params: VitalEntityService.SaveVitalUsingPATCHParams): Observable<ResourceVital> {
    return this.saveVitalUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  vitalConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitals/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  vitalConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.vitalConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.VitalConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalConsultationUsingPOSTResponse(params: VitalEntityService.VitalConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.VitalConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalConsultationUsingPOST(params: VitalEntityService.VitalConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.vitalConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.VitalConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalConsultationUsingPUTResponse(params: VitalEntityService.VitalConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.VitalConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalConsultationUsingPUT(params: VitalEntityService.VitalConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.vitalConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  vitalConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/vitals/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  vitalConsultationUsingDELETE(id: number): Observable<void> {
    return this.vitalConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.VitalConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalConsultationUsingPATCHResponse(params: VitalEntityService.VitalConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.VitalConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalConsultationUsingPATCH(params: VitalEntityService.VitalConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.vitalConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  vitalVitalTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitals/${id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  vitalVitalTypeUsingGET(id: number): Observable<ResourceVitalType> {
    return this.vitalVitalTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.VitalVitalTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalVitalTypeUsingPOSTResponse(params: VitalEntityService.VitalVitalTypeUsingPOSTParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.VitalVitalTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalVitalTypeUsingPOST(params: VitalEntityService.VitalVitalTypeUsingPOSTParams): Observable<ResourceVitalType> {
    return this.vitalVitalTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.VitalVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalVitalTypeUsingPUTResponse(params: VitalEntityService.VitalVitalTypeUsingPUTParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.VitalVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalVitalTypeUsingPUT(params: VitalEntityService.VitalVitalTypeUsingPUTParams): Observable<ResourceVitalType> {
    return this.vitalVitalTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  vitalVitalTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/vitals/${id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  vitalVitalTypeUsingDELETE(id: number): Observable<void> {
    return this.vitalVitalTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalEntityService.VitalVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalVitalTypeUsingPATCHResponse(params: VitalEntityService.VitalVitalTypeUsingPATCHParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/vitals/${params.id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `VitalEntityService.VitalVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalVitalTypeUsingPATCH(params: VitalEntityService.VitalVitalTypeUsingPATCHParams): Observable<ResourceVitalType> {
    return this.vitalVitalTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module VitalEntityService {

  /**
   * Parameters for findAllVitalUsingGET
   */
  export interface FindAllVitalUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdVitalUsingGET
   */
  export interface FindByConsultationIdVitalUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for saveVitalUsingPUT
   */
  export interface SaveVitalUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Vital;
  }

  /**
   * Parameters for saveVitalUsingPATCH
   */
  export interface SaveVitalUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Vital;
  }

  /**
   * Parameters for vitalConsultationUsingPOST
   */
  export interface VitalConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for vitalConsultationUsingPUT
   */
  export interface VitalConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for vitalConsultationUsingPATCH
   */
  export interface VitalConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for vitalVitalTypeUsingPOST
   */
  export interface VitalVitalTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for vitalVitalTypeUsingPUT
   */
  export interface VitalVitalTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for vitalVitalTypeUsingPATCH
   */
  export interface VitalVitalTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { VitalEntityService }
