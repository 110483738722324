import { BaseService } from './../base-service';
import { Injectable } from '@angular/core';
import { ApiConfiguration } from '../api-configuration';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class IssueService extends BaseService{

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  fetchAllIssues(params): Observable<any> {
    return this.fetchAllIssuesResponse(params).pipe(
      map(_r => _r.body)
    );
  };

  fetchAllIssuesResponse(params){
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.reasonType != null) __params = __params.set('reasonType', params.reasonType.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/reason/fetch/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body = null;
        _body = _resp.body;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  };

  reasonActivePatchCall(body): Observable<any> {
    return this.reasonActivePatchCallResponse(body).pipe(
      map(_r => _r.body)
    );
  };

  reasonActivePatchCallResponse(body){
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = body;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/reason/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body = null;
        _body = _resp.body;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  };

  addIssuesAdminPost(body) {
    return this.http.post(this.rootUrl + '/reason/create' , body);
  };


}

module IssueService{}

export { IssueService }
