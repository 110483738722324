/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesFinancialIncome } from '../models/resources-financial-income-';
import { ResourcesDoctorInformation } from '../models/resources-doctor-information-';
import { ResourcesDoctorPatientList } from '../models/resources-doctor-patient-list-';

/**
 * Doctor Dashboard Controller
 */
@Injectable()
class DoctorDashboardControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingGETResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingGETParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingGET(params: DoctorDashboardControllerService.FinancialIncomeUsingGETParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingHEADResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingHEADParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingHEAD(params: DoctorDashboardControllerService.FinancialIncomeUsingHEADParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingPOSTResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingPOSTParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingPOST(params: DoctorDashboardControllerService.FinancialIncomeUsingPOSTParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingPUTResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingPUTParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingPUT(params: DoctorDashboardControllerService.FinancialIncomeUsingPUTParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingDELETEResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingDELETEParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingDELETE(params: DoctorDashboardControllerService.FinancialIncomeUsingDELETEParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingOPTIONSResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingOPTIONSParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingOPTIONS(params: DoctorDashboardControllerService.FinancialIncomeUsingOPTIONSParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingPATCHResponse(params: DoctorDashboardControllerService.FinancialIncomeUsingPATCHParams): Observable<HttpResponse<ResourcesFinancialIncome>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/financialIncome`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFinancialIncome = null;
        _body = _resp.body as ResourcesFinancialIncome;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFinancialIncome>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.FinancialIncomeUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  financialIncomeUsingPATCH(params: DoctorDashboardControllerService.FinancialIncomeUsingPATCHParams): Observable<ResourcesFinancialIncome> {
    return this.financialIncomeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingGETResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingGET(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingGETResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingHEADResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingHEAD(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingHEADResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingPOSTResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingPOST(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingPOSTResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingPUTResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingPUT(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingPUTResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingDELETEResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingDELETE(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingDELETEResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingOPTIONSResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingOPTIONS(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingOPTIONSResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingPATCHResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInformation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInformation = null;
        _body = _resp.body as ResourcesDoctorInformation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInformation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getDoctorInfoUsingPATCH(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInformation> {
    return this.getDoctorInfoUsingPATCHResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingGETResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingGETParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingGET(params: DoctorDashboardControllerService.GetPatientOnFilterUsingGETParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingHEADResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingHEADParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingHEAD(params: DoctorDashboardControllerService.GetPatientOnFilterUsingHEADParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingPOSTResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingPOST(params: DoctorDashboardControllerService.GetPatientOnFilterUsingPOSTParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingPUTResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingPUTParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingPUT(params: DoctorDashboardControllerService.GetPatientOnFilterUsingPUTParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingDELETEResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingDELETEParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingDELETE(params: DoctorDashboardControllerService.GetPatientOnFilterUsingDELETEParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingOPTIONSResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingOPTIONSParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingOPTIONS(params: DoctorDashboardControllerService.GetPatientOnFilterUsingOPTIONSParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingPATCHResponse(params: DoctorDashboardControllerService.GetPatientOnFilterUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/patientListByFilter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetPatientOnFilterUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  getPatientOnFilterUsingPATCH(params: DoctorDashboardControllerService.GetPatientOnFilterUsingPATCHParams): Observable<ResourcesDoctorPatientList> {
    return this.getPatientOnFilterUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingGETResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingGETParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingGET(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingGETParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingHEADResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingHEADParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingHEAD(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingHEADParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPOSTResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPOST(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPOSTParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPUTResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPUTParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPUT(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPUTParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingDELETEResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingDELETEParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingDELETE(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingDELETEParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingOPTIONSResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingOPTIONSParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingOPTIONS(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingOPTIONSParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPATCHResponse(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorPatientList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/patientListWithDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorPatientList = null;
        _body = _resp.body as ResourcesDoctorPatientList;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorPatientList>;
      })
    );
  }

  /**
   * @param params The `DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPATCH(params: DoctorDashboardControllerService.GetDoctorPatientListBetweenDateUsingPATCHParams): Observable<ResourcesDoctorPatientList> {
    return this.getDoctorPatientListBetweenDateUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorDashboardControllerService {

  /**
   * Parameters for financialIncomeUsingGET
   */
  export interface FinancialIncomeUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for financialIncomeUsingHEAD
   */
  export interface FinancialIncomeUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for financialIncomeUsingPOST
   */
  export interface FinancialIncomeUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for financialIncomeUsingPUT
   */
  export interface FinancialIncomeUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for financialIncomeUsingDELETE
   */
  export interface FinancialIncomeUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for financialIncomeUsingOPTIONS
   */
  export interface FinancialIncomeUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for financialIncomeUsingPATCH
   */
  export interface FinancialIncomeUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getPatientOnFilterUsingGET
   */
  export interface GetPatientOnFilterUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getPatientOnFilterUsingHEAD
   */
  export interface GetPatientOnFilterUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getPatientOnFilterUsingPOST
   */
  export interface GetPatientOnFilterUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getPatientOnFilterUsingPUT
   */
  export interface GetPatientOnFilterUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getPatientOnFilterUsingDELETE
   */
  export interface GetPatientOnFilterUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getPatientOnFilterUsingOPTIONS
   */
  export interface GetPatientOnFilterUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getPatientOnFilterUsingPATCH
   */
  export interface GetPatientOnFilterUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingGET
   */
  export interface GetDoctorPatientListBetweenDateUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingHEAD
   */
  export interface GetDoctorPatientListBetweenDateUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingPOST
   */
  export interface GetDoctorPatientListBetweenDateUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingPUT
   */
  export interface GetDoctorPatientListBetweenDateUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingDELETE
   */
  export interface GetDoctorPatientListBetweenDateUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingOPTIONS
   */
  export interface GetDoctorPatientListBetweenDateUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingPATCH
   */
  export interface GetDoctorPatientListBetweenDateUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }
}

export { DoctorDashboardControllerService }
