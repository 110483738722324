/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Analysis Report Controller
 */
@Injectable()
class AnalysisReportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingGETResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingGET(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingGETParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingHEADResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingHEAD(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingHEADParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingPOSTResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingPOST(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingPOSTParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingPUTResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingPUT(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingPUTParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingDELETEResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingDELETE(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingDELETEParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingOPTIONSResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingOPTIONS(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingOPTIONSParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingPATCHResponse(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/coverage/dipanseNdelivery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.GetDeliveryNDispenseUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDeliveryNDispenseUsingPATCH(params: AnalysisReportControllerService.GetDeliveryNDispenseUsingPATCHParams): Observable<{}> {
    return this.getDeliveryNDispenseUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingGETResponse(params: AnalysisReportControllerService.YourClinicEarningUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingGET(params: AnalysisReportControllerService.YourClinicEarningUsingGETParams): Observable<{}> {
    return this.yourClinicEarningUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingHEADResponse(params: AnalysisReportControllerService.YourClinicEarningUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingHEAD(params: AnalysisReportControllerService.YourClinicEarningUsingHEADParams): Observable<{}> {
    return this.yourClinicEarningUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingPOSTResponse(params: AnalysisReportControllerService.YourClinicEarningUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingPOST(params: AnalysisReportControllerService.YourClinicEarningUsingPOSTParams): Observable<{}> {
    return this.yourClinicEarningUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingPUTResponse(params: AnalysisReportControllerService.YourClinicEarningUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingPUT(params: AnalysisReportControllerService.YourClinicEarningUsingPUTParams): Observable<{}> {
    return this.yourClinicEarningUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingDELETEResponse(params: AnalysisReportControllerService.YourClinicEarningUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingDELETE(params: AnalysisReportControllerService.YourClinicEarningUsingDELETEParams): Observable<{}> {
    return this.yourClinicEarningUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingOPTIONSResponse(params: AnalysisReportControllerService.YourClinicEarningUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingOPTIONS(params: AnalysisReportControllerService.YourClinicEarningUsingOPTIONSParams): Observable<{}> {
    return this.yourClinicEarningUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingPATCHResponse(params: AnalysisReportControllerService.YourClinicEarningUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/earning/yourClinicEarning`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportControllerService.YourClinicEarningUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  yourClinicEarningUsingPATCH(params: AnalysisReportControllerService.YourClinicEarningUsingPATCHParams): Observable<{}> {
    return this.yourClinicEarningUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AnalysisReportControllerService {

  /**
   * Parameters for getDeliveryNDispenseUsingGET
   */
  export interface GetDeliveryNDispenseUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDeliveryNDispenseUsingHEAD
   */
  export interface GetDeliveryNDispenseUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDeliveryNDispenseUsingPOST
   */
  export interface GetDeliveryNDispenseUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDeliveryNDispenseUsingPUT
   */
  export interface GetDeliveryNDispenseUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDeliveryNDispenseUsingDELETE
   */
  export interface GetDeliveryNDispenseUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDeliveryNDispenseUsingOPTIONS
   */
  export interface GetDeliveryNDispenseUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for getDeliveryNDispenseUsingPATCH
   */
  export interface GetDeliveryNDispenseUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourClinicEarningUsingGET
   */
  export interface YourClinicEarningUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for yourClinicEarningUsingHEAD
   */
  export interface YourClinicEarningUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for yourClinicEarningUsingPOST
   */
  export interface YourClinicEarningUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for yourClinicEarningUsingPUT
   */
  export interface YourClinicEarningUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for yourClinicEarningUsingDELETE
   */
  export interface YourClinicEarningUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for yourClinicEarningUsingOPTIONS
   */
  export interface YourClinicEarningUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for yourClinicEarningUsingPATCH
   */
  export interface YourClinicEarningUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
  }
}

export { AnalysisReportControllerService }
