/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesSpecialities } from '../models/resources-specialities-';
import { ResourcesMedicineSearchInTranscription } from '../models/resources-medicine-search-in-transcription-';
import { Resourcesobject } from '../models/resources-object-';

/**
 * Common Symptom Controller
 */
@Injectable()
class CommonSymptomControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingGETResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingGET(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingGETResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingHEADResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingHEAD(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingHEADResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingPOSTResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingPOST(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingPOSTResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingPUTResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingPUT(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingPUTResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingDELETEResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingDELETE(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingDELETEResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingOPTIONSResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingOPTIONS(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingOPTIONSResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingPATCHResponse(doctorID: number): Observable<HttpResponse<ResourcesSpecialities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorID != null) __params = __params.set('doctorID', doctorID.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/commonSymptomsAndVitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialities = null;
        _body = _resp.body as ResourcesSpecialities;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialities>;
      })
    );
  }

  /**
   * @param doctorID doctorID
   * @return OK
   */
  getSymptomsAndVitalDataUsingPATCH(doctorID: number): Observable<ResourcesSpecialities> {
    return this.getSymptomsAndVitalDataUsingPATCHResponse(doctorID).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingGETParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingGETResponse(params: CommonSymptomControllerService.MedicineFilterUsingGETParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingGETParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingGET(params: CommonSymptomControllerService.MedicineFilterUsingGETParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingHEADParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingHEADResponse(params: CommonSymptomControllerService.MedicineFilterUsingHEADParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/medicines/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingHEADParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingHEAD(params: CommonSymptomControllerService.MedicineFilterUsingHEADParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingPOSTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingPOSTResponse(params: CommonSymptomControllerService.MedicineFilterUsingPOSTParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicines/secondary-medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingPOSTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingPOST(params: CommonSymptomControllerService.MedicineFilterUsingPOSTParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingPUTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingPUTResponse(params: CommonSymptomControllerService.MedicineFilterUsingPUTParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicines/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingPUTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingPUT(params: CommonSymptomControllerService.MedicineFilterUsingPUTParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingDELETEParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingDELETEResponse(params: CommonSymptomControllerService.MedicineFilterUsingDELETEParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingDELETEParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingDELETE(params: CommonSymptomControllerService.MedicineFilterUsingDELETEParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingOPTIONSParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingOPTIONSResponse(params: CommonSymptomControllerService.MedicineFilterUsingOPTIONSParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/medicines/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingOPTIONSParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingOPTIONS(params: CommonSymptomControllerService.MedicineFilterUsingOPTIONSParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingPATCHParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingPATCHResponse(params: CommonSymptomControllerService.MedicineFilterUsingPATCHParams): Observable<HttpResponse<ResourcesMedicineSearchInTranscription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicines/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSearchInTranscription = null;
        _body = _resp.body as ResourcesMedicineSearchInTranscription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSearchInTranscription>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.MedicineFilterUsingPATCHParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  MedicineFilterUsingPATCH(params: CommonSymptomControllerService.MedicineFilterUsingPATCHParams): Observable<ResourcesMedicineSearchInTranscription> {
    return this.MedicineFilterUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingGETParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingGETResponse(params: CommonSymptomControllerService.PatientInfoUsingGETParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingGETParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingGET(params: CommonSymptomControllerService.PatientInfoUsingGETParams): Observable<Resourcesobject> {
    return this.patientInfoUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingHEADParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingHEADResponse(params: CommonSymptomControllerService.PatientInfoUsingHEADParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingHEADParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingHEAD(params: CommonSymptomControllerService.PatientInfoUsingHEADParams): Observable<Resourcesobject> {
    return this.patientInfoUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingPOSTParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingPOSTResponse(params: CommonSymptomControllerService.PatientInfoUsingPOSTParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingPOSTParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingPOST(params: CommonSymptomControllerService.PatientInfoUsingPOSTParams): Observable<Resourcesobject> {
    return this.patientInfoUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingPUTParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingPUTResponse(params: CommonSymptomControllerService.PatientInfoUsingPUTParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingPUTParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingPUT(params: CommonSymptomControllerService.PatientInfoUsingPUTParams): Observable<Resourcesobject> {
    return this.patientInfoUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingDELETEParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingDELETEResponse(params: CommonSymptomControllerService.PatientInfoUsingDELETEParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingDELETEParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingDELETE(params: CommonSymptomControllerService.PatientInfoUsingDELETEParams): Observable<Resourcesobject> {
    return this.patientInfoUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingOPTIONSParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingOPTIONSResponse(params: CommonSymptomControllerService.PatientInfoUsingOPTIONSParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingOPTIONSParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingOPTIONS(params: CommonSymptomControllerService.PatientInfoUsingOPTIONSParams): Observable<Resourcesobject> {
    return this.patientInfoUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingPATCHParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingPATCHResponse(params: CommonSymptomControllerService.PatientInfoUsingPATCHParams): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.moblieno != null) __params = __params.set('moblieno', params.moblieno.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patients/patientDetail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.PatientInfoUsingPATCHParams` containing the following parameters:
   *
   * - `moblieno`: moblieno
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  patientInfoUsingPATCH(params: CommonSymptomControllerService.PatientInfoUsingPATCHParams): Observable<Resourcesobject> {
    return this.patientInfoUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingGETParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingGETResponse(params: CommonSymptomControllerService.SearchSpecialityUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingGETParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingGET(params: CommonSymptomControllerService.SearchSpecialityUsingGETParams): Observable<{}> {
    return this.searchSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingHEADParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingHEADResponse(params: CommonSymptomControllerService.SearchSpecialityUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingHEADParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingHEAD(params: CommonSymptomControllerService.SearchSpecialityUsingHEADParams): Observable<{}> {
    return this.searchSpecialityUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingPOSTResponse(params: CommonSymptomControllerService.SearchSpecialityUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingPOST(params: CommonSymptomControllerService.SearchSpecialityUsingPOSTParams): Observable<{}> {
    return this.searchSpecialityUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingPUTResponse(params: CommonSymptomControllerService.SearchSpecialityUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingPUT(params: CommonSymptomControllerService.SearchSpecialityUsingPUTParams): Observable<{}> {
    return this.searchSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingDELETEResponse(params: CommonSymptomControllerService.SearchSpecialityUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingDELETE(params: CommonSymptomControllerService.SearchSpecialityUsingDELETEParams): Observable<{}> {
    return this.searchSpecialityUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingOPTIONSParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingOPTIONSResponse(params: CommonSymptomControllerService.SearchSpecialityUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingOPTIONSParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingOPTIONS(params: CommonSymptomControllerService.SearchSpecialityUsingOPTIONSParams): Observable<{}> {
    return this.searchSpecialityUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingPATCHResponse(params: CommonSymptomControllerService.SearchSpecialityUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/search/speciality/for/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `CommonSymptomControllerService.SearchSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchSpecialityUsingPATCH(params: CommonSymptomControllerService.SearchSpecialityUsingPATCHParams): Observable<{}> {
    return this.searchSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module CommonSymptomControllerService {

  /**
   * Parameters for MedicineFilterUsingGET
   */
  export interface MedicineFilterUsingGETParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for MedicineFilterUsingHEAD
   */
  export interface MedicineFilterUsingHEADParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for MedicineFilterUsingPOST
   */
  export interface MedicineFilterUsingPOSTParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for MedicineFilterUsingPUT
   */
  export interface MedicineFilterUsingPUTParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for MedicineFilterUsingDELETE
   */
  export interface MedicineFilterUsingDELETEParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for MedicineFilterUsingOPTIONS
   */
  export interface MedicineFilterUsingOPTIONSParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for MedicineFilterUsingPATCH
   */
  export interface MedicineFilterUsingPATCHParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for patientInfoUsingGET
   */
  export interface PatientInfoUsingGETParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for patientInfoUsingHEAD
   */
  export interface PatientInfoUsingHEADParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for patientInfoUsingPOST
   */
  export interface PatientInfoUsingPOSTParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for patientInfoUsingPUT
   */
  export interface PatientInfoUsingPUTParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for patientInfoUsingDELETE
   */
  export interface PatientInfoUsingDELETEParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for patientInfoUsingOPTIONS
   */
  export interface PatientInfoUsingOPTIONSParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for patientInfoUsingPATCH
   */
  export interface PatientInfoUsingPATCHParams {

    /**
     * moblieno
     */
    moblieno: string;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for searchSpecialityUsingGET
   */
  export interface SearchSpecialityUsingGETParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchSpecialityUsingHEAD
   */
  export interface SearchSpecialityUsingHEADParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchSpecialityUsingPOST
   */
  export interface SearchSpecialityUsingPOSTParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchSpecialityUsingPUT
   */
  export interface SearchSpecialityUsingPUTParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchSpecialityUsingDELETE
   */
  export interface SearchSpecialityUsingDELETEParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchSpecialityUsingOPTIONS
   */
  export interface SearchSpecialityUsingOPTIONSParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchSpecialityUsingPATCH
   */
  export interface SearchSpecialityUsingPATCHParams {

    /**
     * searchQuery
     */
    searchQuery: string;
    sort?: string;
    size?: number;
    page?: number;
  }
}

export { CommonSymptomControllerService }
