/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DoctorRatings } from '../models/doctor-ratings';

/**
 * Doctor Rating Controller
 */
@Injectable()
class DoctorRatingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getOngoingMedicationPercentageUsingPATCHResponse(): Observable<HttpResponse<Array<DoctorRatings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctor/rating`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<DoctorRatings> = null;
        _body = _resp.body as Array<DoctorRatings>;
        return _resp.clone({body: _body}) as HttpResponse<Array<DoctorRatings>>;
      })
    );
  }

  /**
   * @return OK
   */
  getOngoingMedicationPercentageUsingPATCH(): Observable<Array<DoctorRatings>> {
    return this.getOngoingMedicationPercentageUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorRatingControllerService {
}

export { DoctorRatingControllerService }
