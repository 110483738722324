/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ModelAndView } from '../models/model-and-view';
import { View } from '../models/view';

/**
 * Authorization Endpoint
 */
@Injectable()
class AuthorizationEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingGETParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingGETResponse(params: AuthorizationEndpointService.AuthorizeUsingGETParams): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingGETParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingGET(params: AuthorizationEndpointService.AuthorizeUsingGETParams): Observable<ModelAndView> {
    return this.authorizeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingHEADParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingHEADResponse(params: AuthorizationEndpointService.AuthorizeUsingHEADParams): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingHEADParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingHEAD(params: AuthorizationEndpointService.AuthorizeUsingHEADParams): Observable<ModelAndView> {
    return this.authorizeUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.ApproveOrDenyUsingPOSTParams` containing the following parameters:
   *
   * - `user_oauth_approval`:
   *
   * - `approvalParameters`: approvalParameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  approveOrDenyUsingPOSTResponse(params: AuthorizationEndpointService.ApproveOrDenyUsingPOSTParams): Observable<HttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userOauthApproval != null) __params = __params.set('user_oauth_approval', params.userOauthApproval.toString());
    if (params.approvalParameters != null) __params = __params.set('approvalParameters', params.approvalParameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: View = null;
        _body = _resp.body as View;
        return _resp.clone({body: _body}) as HttpResponse<View>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.ApproveOrDenyUsingPOSTParams` containing the following parameters:
   *
   * - `user_oauth_approval`:
   *
   * - `approvalParameters`: approvalParameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  approveOrDenyUsingPOST(params: AuthorizationEndpointService.ApproveOrDenyUsingPOSTParams): Observable<View> {
    return this.approveOrDenyUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingPUTParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingPUTResponse(params: AuthorizationEndpointService.AuthorizeUsingPUTParams): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingPUTParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingPUT(params: AuthorizationEndpointService.AuthorizeUsingPUTParams): Observable<ModelAndView> {
    return this.authorizeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingDELETEParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingDELETEResponse(params: AuthorizationEndpointService.AuthorizeUsingDELETEParams): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingDELETEParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingDELETE(params: AuthorizationEndpointService.AuthorizeUsingDELETEParams): Observable<ModelAndView> {
    return this.authorizeUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingOPTIONSParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingOPTIONSResponse(params: AuthorizationEndpointService.AuthorizeUsingOPTIONSParams): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingOPTIONSParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingOPTIONS(params: AuthorizationEndpointService.AuthorizeUsingOPTIONSParams): Observable<ModelAndView> {
    return this.authorizeUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingPATCHParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingPATCHResponse(params: AuthorizationEndpointService.AuthorizeUsingPATCHParams): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.model != null) __params = __params.set('model', params.model.toString());
    if (params.complete != null) __params = __params.set('complete', params.complete.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/oauth/authorize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param params The `AuthorizationEndpointService.AuthorizeUsingPATCHParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * - `model`: model
   *
   * - `complete`:
   *
   * @return OK
   */
  authorizeUsingPATCH(params: AuthorizationEndpointService.AuthorizeUsingPATCHParams): Observable<ModelAndView> {
    return this.authorizeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AuthorizationEndpointService {

  /**
   * Parameters for authorizeUsingGET
   */
  export interface AuthorizeUsingGETParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }

  /**
   * Parameters for authorizeUsingHEAD
   */
  export interface AuthorizeUsingHEADParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }

  /**
   * Parameters for approveOrDenyUsingPOST
   */
  export interface ApproveOrDenyUsingPOSTParams {
    userOauthApproval: string;

    /**
     * approvalParameters
     */
    approvalParameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }

  /**
   * Parameters for authorizeUsingPUT
   */
  export interface AuthorizeUsingPUTParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }

  /**
   * Parameters for authorizeUsingDELETE
   */
  export interface AuthorizeUsingDELETEParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }

  /**
   * Parameters for authorizeUsingOPTIONS
   */
  export interface AuthorizeUsingOPTIONSParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }

  /**
   * Parameters for authorizeUsingPATCH
   */
  export interface AuthorizeUsingPATCHParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;

    /**
     * model
     */
    model?: {};
    complete?: boolean;
  }
}

export { AuthorizationEndpointService }
