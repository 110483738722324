import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ApiConfiguration } from "./api-configuration";

import { WebMvcEndpointHandlerMappingService } from "./services/web-mvc-endpoint-handler-mapping.service";
import { OperationHandlerService } from "./services/operation-handler.service";
import { OrderAndPatientControllerService } from "./services/order-and-patient-controller.service";
import { OrderAndPaymentControllerService } from "./services/order-and-payment-controller.service";
import { AnalysisReportControllerService } from "./services/analysis-report-controller.service";
import { DiagnosticInClinicControllerService } from "./services/diagnostic-in-clinic-controller.service";
import { DiagnosticOrderEntityService } from "./services/diagnostic-order-entity.service";
import { SwaggerGenControllerService } from "./services/swagger-gen-controller.service";
import { InvoiceControllerService } from "./services/invoice-controller.service";
import { OrderItemEntityService } from "./services/order-item-entity.service";
import { OrderEntityService } from "./services/order-entity.service";
import { OrderItemStatusflowEntityService } from "./services/order-item-statusflow-entity.service";
import { OrderStatusflowEntityService } from "./services/order-statusflow-entity.service";
import { PaymentModeEntityService } from "./services/payment-mode-entity.service";
import { PaymentEntityService } from "./services/payment-entity.service";
import { PaymentSummaryService } from "./services/payment-summary.service";
import { ProductTypeEntityService } from "./services/product-type-entity.service";
import { SendOtpControllerService } from "./services/send-otp.service";
import { ProfileControllerService } from "./services/profile-controller.service";
import { RiderEntityService } from "./services/rider-entity.service";
import { RiderWithOrderItemEntityService } from "./services/rider-with-order-item-entity.service";
import { OrderControllerService } from "./services/order-controller.service";
import { OrderItemControllerService } from "./services/order-item-controller.service";
import { BasicErrorControllerService } from "./services/basic-error-controller.service";

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    ApiConfiguration,
    WebMvcEndpointHandlerMappingService,
    OperationHandlerService,
    OrderAndPatientControllerService,
    OrderAndPaymentControllerService,
    AnalysisReportControllerService,
    DiagnosticInClinicControllerService,
    DiagnosticOrderEntityService,
    SwaggerGenControllerService,
    InvoiceControllerService,
    OrderItemEntityService,
    OrderEntityService,
    OrderItemStatusflowEntityService,
    OrderStatusflowEntityService,
    PaymentModeEntityService,
    SendOtpControllerService,
    PaymentEntityService,
    ProductTypeEntityService,
    ProfileControllerService,
    RiderEntityService,
    RiderWithOrderItemEntityService,
    OrderControllerService,
    OrderItemControllerService,
    BasicErrorControllerService,
    PaymentSummaryService,
  ],
})
export class ApiModule {}
