/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PagedResourcesResourceOrderAndPatient } from '../models/paged-resources-resource-order-and-patient-';
import { OrderAndPatient } from '../models/order-and-patient';

/**
 * Order And Patient Controller
 */
@Injectable()
class OrderAndPatientControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderAndPatientControllerService.GetAllPatientOrdersInFamilyUsingGETParams` containing the following parameters:
   *
   * - `Authorization`: Authorization
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  getAllPatientOrdersInFamilyUsingGETResponse(params: OrderAndPatientControllerService.GetAllPatientOrdersInFamilyUsingGETParams): Observable<HttpResponse<PagedResourcesResourceOrderAndPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/all/by-family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PagedResourcesResourceOrderAndPatient = null;
        _body = _resp.body as PagedResourcesResourceOrderAndPatient;
        return _resp.clone({body: _body}) as HttpResponse<PagedResourcesResourceOrderAndPatient>;
      })
    );
  }

  /**
   * @param params The `OrderAndPatientControllerService.GetAllPatientOrdersInFamilyUsingGETParams` containing the following parameters:
   *
   * - `Authorization`: Authorization
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  getAllPatientOrdersInFamilyUsingGET(params: OrderAndPatientControllerService.GetAllPatientOrdersInFamilyUsingGETParams): Observable<PagedResourcesResourceOrderAndPatient> {
    return this.getAllPatientOrdersInFamilyUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPatientControllerService.GetOrderDetailsForOrderUsingGETParams` containing the following parameters:
   *
   * - `orderId`: orderId
   *
   * - `Authorization`: Authorization
   *
   * @return OK
   */
  getOrderDetailsForOrderUsingGETResponse(params: OrderAndPatientControllerService.GetOrderDetailsForOrderUsingGETParams): Observable<HttpResponse<OrderAndPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderId != null) __params = __params.set('orderId', params.orderId.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/detail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: OrderAndPatient = null;
        _body = _resp.body as OrderAndPatient;
        return _resp.clone({body: _body}) as HttpResponse<OrderAndPatient>;
      })
    );
  }

  /**
   * @param params The `OrderAndPatientControllerService.GetOrderDetailsForOrderUsingGETParams` containing the following parameters:
   *
   * - `orderId`: orderId
   *
   * - `Authorization`: Authorization
   *
   * @return OK
   */
  getOrderDetailsForOrderUsingGET(params: OrderAndPatientControllerService.GetOrderDetailsForOrderUsingGETParams): Observable<OrderAndPatient> {
    return this.getOrderDetailsForOrderUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderAndPatientControllerService {

  /**
   * Parameters for getAllPatientOrdersInFamilyUsingGET
   */
  export interface GetAllPatientOrdersInFamilyUsingGETParams {

    /**
     * Authorization
     */
    Authorization: string;

    /**
     * status
     */
    status?: string;
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for getOrderDetailsForOrderUsingGET
   */
  export interface GetOrderDetailsForOrderUsingGETParams {

    /**
     * orderId
     */
    orderId: number;

    /**
     * Authorization
     */
    Authorization: string;
  }
}

export { OrderAndPatientControllerService }
