/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ModelAndView } from '../models/model-and-view';

/**
 * Whitelabel Approval Endpoint
 */
@Injectable()
class WhitelabelApprovalEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingGETResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingGET(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingGETResponse(model).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingHEADResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingHEAD(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingHEADResponse(model).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingPOSTResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingPOST(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingPOSTResponse(model).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingPUTResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingPUT(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingPUTResponse(model).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingDELETEResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingDELETE(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingDELETEResponse(model).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingOPTIONSResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingOPTIONS(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingOPTIONSResponse(model).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingPATCHResponse(model?: {}): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (model != null) __params = __params.set('model', model.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/oauth/confirm_access`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @param model model
   * @return OK
   */
  getAccessConfirmationUsingPATCH(model?: {}): Observable<ModelAndView> {
    return this.getAccessConfirmationUsingPATCHResponse(model).pipe(
      map(_r => _r.body)
    );
  }
}

module WhitelabelApprovalEndpointService {
}

export { WhitelabelApprovalEndpointService }
