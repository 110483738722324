/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosis } from '../models/resources-diagnosis-';
import { ResourceDiagnosis } from '../models/resource-diagnosis-';
import { Diagnosis } from '../models/diagnosis';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceDiagnosisType } from '../models/resource-diagnosis-type-';

/**
 * $ Proxy 206
 */
@Injectable()
class DiagnosisEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosisEntityService.FindAllDiagnosisUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosisUsingGETResponse(params: DiagnosisEntityService.FindAllDiagnosisUsingGETParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.FindAllDiagnosisUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosisUsingGET(params: DiagnosisEntityService.FindAllDiagnosisUsingGETParams): Observable<ResourcesDiagnosis> {
    return this.findAllDiagnosisUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosisUsingPOSTResponse(body: Diagnosis): Observable<HttpResponse<ResourceDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosis = null;
        _body = _resp.body as ResourceDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosis>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosisUsingPOST(body: Diagnosis): Observable<ResourceDiagnosis> {
    return this.saveDiagnosisUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.FindByConsultationIdDiagnosisUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdDiagnosisUsingGETResponse(params: DiagnosisEntityService.FindByConsultationIdDiagnosisUsingGETParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosis/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.FindByConsultationIdDiagnosisUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdDiagnosisUsingGET(params: DiagnosisEntityService.FindByConsultationIdDiagnosisUsingGETParams): Observable<ResourcesDiagnosis> {
    return this.findByConsultationIdDiagnosisUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosisUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosis/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosis = null;
        _body = _resp.body as ResourceDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosis>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosisUsingGET(id: number): Observable<ResourceDiagnosis> {
    return this.findByIdDiagnosisUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.SaveDiagnosisUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisUsingPUTResponse(params: DiagnosisEntityService.SaveDiagnosisUsingPUTParams): Observable<HttpResponse<ResourceDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosis = null;
        _body = _resp.body as ResourceDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.SaveDiagnosisUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisUsingPUT(params: DiagnosisEntityService.SaveDiagnosisUsingPUTParams): Observable<ResourceDiagnosis> {
    return this.saveDiagnosisUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosisUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosis/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosisUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosisUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.SaveDiagnosisUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisUsingPATCHResponse(params: DiagnosisEntityService.SaveDiagnosisUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosis = null;
        _body = _resp.body as ResourceDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.SaveDiagnosisUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisUsingPATCH(params: DiagnosisEntityService.SaveDiagnosisUsingPATCHParams): Observable<ResourceDiagnosis> {
    return this.saveDiagnosisUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosisConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosis/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosisConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.diagnosisConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisConsultationUsingPOSTResponse(params: DiagnosisEntityService.DiagnosisConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisConsultationUsingPOST(params: DiagnosisEntityService.DiagnosisConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.diagnosisConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisConsultationUsingPUTResponse(params: DiagnosisEntityService.DiagnosisConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisConsultationUsingPUT(params: DiagnosisEntityService.DiagnosisConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.diagnosisConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosisConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosis/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosisConsultationUsingDELETE(id: number): Observable<void> {
    return this.diagnosisConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisConsultationUsingPATCHResponse(params: DiagnosisEntityService.DiagnosisConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisConsultationUsingPATCH(params: DiagnosisEntityService.DiagnosisConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.diagnosisConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosisDiagnosisTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosis/${id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosisDiagnosisTypeUsingGET(id: number): Observable<ResourceDiagnosisType> {
    return this.diagnosisDiagnosisTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisDiagnosisTypeUsingPOSTResponse(params: DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisDiagnosisTypeUsingPOST(params: DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPOSTParams): Observable<ResourceDiagnosisType> {
    return this.diagnosisDiagnosisTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisDiagnosisTypeUsingPUTResponse(params: DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPUTParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisDiagnosisTypeUsingPUT(params: DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPUTParams): Observable<ResourceDiagnosisType> {
    return this.diagnosisDiagnosisTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosisDiagnosisTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosis/${id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosisDiagnosisTypeUsingDELETE(id: number): Observable<void> {
    return this.diagnosisDiagnosisTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisDiagnosisTypeUsingPATCHResponse(params: DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosis/${params.id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisDiagnosisTypeUsingPATCH(params: DiagnosisEntityService.DiagnosisDiagnosisTypeUsingPATCHParams): Observable<ResourceDiagnosisType> {
    return this.diagnosisDiagnosisTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosisEntityService {

  /**
   * Parameters for findAllDiagnosisUsingGET
   */
  export interface FindAllDiagnosisUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdDiagnosisUsingGET
   */
  export interface FindByConsultationIdDiagnosisUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for saveDiagnosisUsingPUT
   */
  export interface SaveDiagnosisUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Diagnosis;
  }

  /**
   * Parameters for saveDiagnosisUsingPATCH
   */
  export interface SaveDiagnosisUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Diagnosis;
  }

  /**
   * Parameters for diagnosisConsultationUsingPOST
   */
  export interface DiagnosisConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosisConsultationUsingPUT
   */
  export interface DiagnosisConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosisConsultationUsingPATCH
   */
  export interface DiagnosisConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosisDiagnosisTypeUsingPOST
   */
  export interface DiagnosisDiagnosisTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosisDiagnosisTypeUsingPUT
   */
  export interface DiagnosisDiagnosisTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosisDiagnosisTypeUsingPATCH
   */
  export interface DiagnosisDiagnosisTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { DiagnosisEntityService }
