/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PaymentMode } from '../models/payment-mode';
import {PagePaymentMode} from '../models/page-payment-mode';

/**
 * $ Proxy 178
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
class PaymentModeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PaymentModeControllerService.FindAllPaymentModeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPaymentModeUsingGETResponse(params: PaymentModeControllerService.FindAllPaymentModeUsingGETParams): Observable<HttpResponse<PagePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.code != null) __params = __params.set('code', params.code.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payment-mode/fetch-payment-mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PagePaymentMode = null;
        _body = _resp.body as PagePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<PagePaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentModeControllerService.FindAllPaymentModeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPaymentModeUsingGET(params: PaymentModeControllerService.FindAllPaymentModeUsingGETParams): Observable<PagePaymentMode> {
    return this.findAllPaymentModeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentModeControllerService.FindAllPaymentModeUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByIdPaymentModeUsingGETResponse(params: PaymentModeControllerService.FindByIdPaymentModeUsingGETParams): Observable<HttpResponse<PaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.id != null) __params = __params.set('id', params.id.toString());

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payment-mode/find-id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PaymentMode = null;
        _body = _resp.body as PaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<PaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentModeControllerService.FindAllPaymentModeUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByIdPaymentModeUsingGET(params: PaymentModeControllerService.FindByIdPaymentModeUsingGETParams): Observable<PaymentMode> {
    return this.findByIdPaymentModeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }


}

module PaymentModeControllerService {

  /**
   * Parameters for findAllPaymentModeUsingGET
   */
  export interface FindAllPaymentModeUsingGETParams {

    /**
     * code
     */
    code?: string;
    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findAllPaymentModeUsingGET
   */
  export interface FindByIdPaymentModeUsingGETParams {

    /**
     * id
     */
    id?: number;
  }
}

export { PaymentModeControllerService }
