/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDoctorTagType } from '../models/resources-doctor-tag-type-';
import { ResourceDoctorTagType } from '../models/resource-doctor-tag-type-';
import { DoctorTagType } from '../models/doctor-tag-type';
import { ResourcesDoctorTags } from '../models/resources-doctor-tags-';
import { ResourceDoctorTags } from '../models/resource-doctor-tags-';

/**
 * $ Proxy 180
 */
@Injectable()
class DoctorTagTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoctorTagTypeEntityService.FindAllDoctorTagTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorTagTypeUsingGETResponse(params: DoctorTagTypeEntityService.FindAllDoctorTagTypeUsingGETParams): Observable<HttpResponse<ResourcesDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTagTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTagType = null;
        _body = _resp.body as ResourcesDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTagType>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.FindAllDoctorTagTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorTagTypeUsingGET(params: DoctorTagTypeEntityService.FindAllDoctorTagTypeUsingGETParams): Observable<ResourcesDoctorTagType> {
    return this.findAllDoctorTagTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorTagTypeUsingPOSTResponse(body: DoctorTagType): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorTagTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorTagTypeUsingPOST(body: DoctorTagType): Observable<ResourceDoctorTagType> {
    return this.saveDoctorTagTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorTagTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorTagTypeUsingGET(id: number): Observable<ResourceDoctorTagType> {
    return this.findByIdDoctorTagTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagTypeUsingPUTResponse(params: DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPUTParams): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagTypeUsingPUT(params: DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPUTParams): Observable<ResourceDoctorTagType> {
    return this.saveDoctorTagTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDoctorTagTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDoctorTagTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteDoctorTagTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagTypeUsingPATCHResponse(params: DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPATCHParams): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagTypeUsingPATCH(params: DoctorTagTypeEntityService.SaveDoctorTagTypeUsingPATCHParams): Observable<ResourceDoctorTagType> {
    return this.saveDoctorTagTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagTypeTagsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagTypeTagsUsingGET_1(id: number): Observable<ResourcesDoctorTags> {
    return this.doctorTagTypeTagsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagTypeTagsUsingPOSTResponse(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagTypeTagsUsingPOST(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPOSTParams): Observable<ResourcesDoctorTags> {
    return this.doctorTagTypeTagsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagTypeTagsUsingPUTResponse(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPUTParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagTypeTagsUsingPUT(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPUTParams): Observable<ResourcesDoctorTags> {
    return this.doctorTagTypeTagsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorTagTypeTagsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorTagTypeTagsUsingDELETE_1(id: number): Observable<void> {
    return this.doctorTagTypeTagsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagTypeTagsUsingPATCHResponse(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagTypeTagsUsingPATCH(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingPATCHParams): Observable<ResourcesDoctorTags> {
    return this.doctorTagTypeTagsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   *
   * @return OK
   */
  doctorTagTypeTagsUsingGETResponse(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingGETParams): Observable<HttpResponse<ResourceDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}/tags/${params.doctortagsId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTags = null;
        _body = _resp.body as ResourceDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   *
   * @return OK
   */
  doctorTagTypeTagsUsingGET(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingGETParams): Observable<ResourceDoctorTags> {
    return this.doctorTagTypeTagsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   */
  doctorTagTypeTagsUsingDELETEResponse(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorTagTypes/${params.id}/tags/${params.doctortagsId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorTagTypeEntityService.DoctorTagTypeTagsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   */
  doctorTagTypeTagsUsingDELETE(params: DoctorTagTypeEntityService.DoctorTagTypeTagsUsingDELETEParams): Observable<void> {
    return this.doctorTagTypeTagsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorTagTypeEntityService {

  /**
   * Parameters for findAllDoctorTagTypeUsingGET
   */
  export interface FindAllDoctorTagTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDoctorTagTypeUsingPUT
   */
  export interface SaveDoctorTagTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DoctorTagType;
  }

  /**
   * Parameters for saveDoctorTagTypeUsingPATCH
   */
  export interface SaveDoctorTagTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DoctorTagType;
  }

  /**
   * Parameters for doctorTagTypeTagsUsingPOST
   */
  export interface DoctorTagTypeTagsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorTagTypeTagsUsingPUT
   */
  export interface DoctorTagTypeTagsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorTagTypeTagsUsingPATCH
   */
  export interface DoctorTagTypeTagsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorTagTypeTagsUsingGET
   */
  export interface DoctorTagTypeTagsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctortagsId
     */
    doctortagsId: string;
  }

  /**
   * Parameters for doctorTagTypeTagsUsingDELETE
   */
  export interface DoctorTagTypeTagsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctortagsId
     */
    doctortagsId: string;
  }
}

export { DoctorTagTypeEntityService }
