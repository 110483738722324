/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPatientPreviousDocument } from '../models/resources-patient-previous-document-';
import { ResourcePatientPreviousDocument } from '../models/resource-patient-previous-document-';
import { PatientPreviousDocument } from '../models/patient-previous-document';
import { ResourceCommentOnDocument } from '../models/resource-comment-on-document-';

/**
 * $ Proxy 225
 */
@Injectable()
class PatientPreviousDocumentEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.FindAllPatientPreviousDocumentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientPreviousDocumentUsingGETResponse(params: PatientPreviousDocumentEntityService.FindAllPatientPreviousDocumentUsingGETParams): Observable<HttpResponse<ResourcesPatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientPreviousDocument = null;
        _body = _resp.body as ResourcesPatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.FindAllPatientPreviousDocumentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientPreviousDocumentUsingGET(params: PatientPreviousDocumentEntityService.FindAllPatientPreviousDocumentUsingGETParams): Observable<ResourcesPatientPreviousDocument> {
    return this.findAllPatientPreviousDocumentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientPreviousDocumentUsingPOSTResponse(body: PatientPreviousDocument): Observable<HttpResponse<ResourcePatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientPreviousDocument = null;
        _body = _resp.body as ResourcePatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientPreviousDocument>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientPreviousDocumentUsingPOST(body: PatientPreviousDocument): Observable<ResourcePatientPreviousDocument> {
    return this.savePatientPreviousDocumentUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientPreviousDocumentUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientPreviousDocument = null;
        _body = _resp.body as ResourcePatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientPreviousDocument>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientPreviousDocumentUsingGET(id: number): Observable<ResourcePatientPreviousDocument> {
    return this.findByIdPatientPreviousDocumentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientPreviousDocumentUsingPUTResponse(params: PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPUTParams): Observable<HttpResponse<ResourcePatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientPreviousDocument = null;
        _body = _resp.body as ResourcePatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientPreviousDocumentUsingPUT(params: PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPUTParams): Observable<ResourcePatientPreviousDocument> {
    return this.savePatientPreviousDocumentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePatientPreviousDocumentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePatientPreviousDocumentUsingDELETE(id: number): Observable<void> {
    return this.deletePatientPreviousDocumentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientPreviousDocumentUsingPATCHResponse(params: PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPATCHParams): Observable<HttpResponse<ResourcePatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientPreviousDocument = null;
        _body = _resp.body as ResourcePatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientPreviousDocumentUsingPATCH(params: PatientPreviousDocumentEntityService.SavePatientPreviousDocumentUsingPATCHParams): Observable<ResourcePatientPreviousDocument> {
    return this.savePatientPreviousDocumentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingGETResponse(id: number): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingGET(id: number): Observable<ResourceCommentOnDocument> {
    return this.patientPreviousDocumentCommentOnDocumentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingPOSTResponse(params: PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPOSTParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${params.id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingPOST(params: PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPOSTParams): Observable<ResourceCommentOnDocument> {
    return this.patientPreviousDocumentCommentOnDocumentUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingPUTResponse(params: PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPUTParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${params.id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingPUT(params: PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPUTParams): Observable<ResourceCommentOnDocument> {
    return this.patientPreviousDocumentCommentOnDocumentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientPreviousDocumentCommentOnDocumentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientPreviousDocumentCommentOnDocumentUsingDELETE(id: number): Observable<void> {
    return this.patientPreviousDocumentCommentOnDocumentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingPATCHResponse(params: PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPATCHParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientPreviousDocument/${params.id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPreviousDocumentCommentOnDocumentUsingPATCH(params: PatientPreviousDocumentEntityService.PatientPreviousDocumentCommentOnDocumentUsingPATCHParams): Observable<ResourceCommentOnDocument> {
    return this.patientPreviousDocumentCommentOnDocumentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientPreviousDocumentEntityService {

  /**
   * Parameters for findAllPatientPreviousDocumentUsingGET
   */
  export interface FindAllPatientPreviousDocumentUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for savePatientPreviousDocumentUsingPUT
   */
  export interface SavePatientPreviousDocumentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientPreviousDocument;
  }

  /**
   * Parameters for savePatientPreviousDocumentUsingPATCH
   */
  export interface SavePatientPreviousDocumentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientPreviousDocument;
  }

  /**
   * Parameters for patientPreviousDocumentCommentOnDocumentUsingPOST
   */
  export interface PatientPreviousDocumentCommentOnDocumentUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientPreviousDocumentCommentOnDocumentUsingPUT
   */
  export interface PatientPreviousDocumentCommentOnDocumentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientPreviousDocumentCommentOnDocumentUsingPATCH
   */
  export interface PatientPreviousDocumentCommentOnDocumentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { PatientPreviousDocumentEntityService }
