/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesMedicineSalt } from '../models/resources-medicine-salt-';
import { ResourceMedicineSalt } from '../models/resource-medicine-salt-';
import { MedicineSalt } from '../models/medicine-salt';
import { ResourceMedicineGeneral } from '../models/resource-medicine-general-';

/**
 * $ Proxy 227
 */
@Injectable()
class MedicineSaltEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MedicineSaltEntityService.FindAllMedicineSaltUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineSaltUsingGETResponse(params: MedicineSaltEntityService.FindAllMedicineSaltUsingGETParams): Observable<HttpResponse<ResourcesMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-salts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSalt = null;
        _body = _resp.body as ResourcesMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.FindAllMedicineSaltUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineSaltUsingGET(params: MedicineSaltEntityService.FindAllMedicineSaltUsingGETParams): Observable<ResourcesMedicineSalt> {
    return this.findAllMedicineSaltUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineSaltUsingPOSTResponse(body: MedicineSalt): Observable<HttpResponse<ResourceMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicine-salts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineSalt = null;
        _body = _resp.body as ResourceMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineSalt>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineSaltUsingPOST(body: MedicineSalt): Observable<ResourceMedicineSalt> {
    return this.saveMedicineSaltUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param medicineSaltIds medicineSaltIds
   * @return OK
   */
  findAllByIdInMedicineSaltUsingGETResponse(medicineSaltIds?: Array<number>): Observable<HttpResponse<ResourcesMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (medicineSaltIds || []).forEach((val, index) => {if (val != null) __params = __params.append('medicineSaltIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-salts/search/findAllByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSalt = null;
        _body = _resp.body as ResourcesMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSalt>;
      })
    );
  }

  /**
   * @param medicineSaltIds medicineSaltIds
   * @return OK
   */
  findAllByIdInMedicineSaltUsingGET(medicineSaltIds?: Array<number>): Observable<ResourcesMedicineSalt> {
    return this.findAllByIdInMedicineSaltUsingGETResponse(medicineSaltIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineSaltUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-salts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineSalt = null;
        _body = _resp.body as ResourceMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineSalt>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineSaltUsingGET(id: number): Observable<ResourceMedicineSalt> {
    return this.findByIdMedicineSaltUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.SaveMedicineSaltUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineSaltUsingPUTResponse(params: MedicineSaltEntityService.SaveMedicineSaltUsingPUTParams): Observable<HttpResponse<ResourceMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicine-salts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineSalt = null;
        _body = _resp.body as ResourceMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.SaveMedicineSaltUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineSaltUsingPUT(params: MedicineSaltEntityService.SaveMedicineSaltUsingPUTParams): Observable<ResourceMedicineSalt> {
    return this.saveMedicineSaltUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteMedicineSaltUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-salts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteMedicineSaltUsingDELETE(id: number): Observable<void> {
    return this.deleteMedicineSaltUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.SaveMedicineSaltUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineSaltUsingPATCHResponse(params: MedicineSaltEntityService.SaveMedicineSaltUsingPATCHParams): Observable<HttpResponse<ResourceMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicine-salts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineSalt = null;
        _body = _resp.body as ResourceMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.SaveMedicineSaltUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineSaltUsingPATCH(params: MedicineSaltEntityService.SaveMedicineSaltUsingPATCHParams): Observable<ResourceMedicineSalt> {
    return this.saveMedicineSaltUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-salts/${id}/medicineGeneralForSalt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingGET(id: number): Observable<ResourceMedicineGeneral> {
    return this.medicineSaltMedicineGeneralForSaltUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingPOSTResponse(params: MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPOSTParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicine-salts/${params.id}/medicineGeneralForSalt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingPOST(params: MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPOSTParams): Observable<ResourceMedicineGeneral> {
    return this.medicineSaltMedicineGeneralForSaltUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingPUTResponse(params: MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPUTParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicine-salts/${params.id}/medicineGeneralForSalt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingPUT(params: MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPUTParams): Observable<ResourceMedicineGeneral> {
    return this.medicineSaltMedicineGeneralForSaltUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineSaltMedicineGeneralForSaltUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-salts/${id}/medicineGeneralForSalt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineSaltMedicineGeneralForSaltUsingDELETE(id: number): Observable<void> {
    return this.medicineSaltMedicineGeneralForSaltUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingPATCHResponse(params: MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPATCHParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicine-salts/${params.id}/medicineGeneralForSalt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineSaltMedicineGeneralForSaltUsingPATCH(params: MedicineSaltEntityService.MedicineSaltMedicineGeneralForSaltUsingPATCHParams): Observable<ResourceMedicineGeneral> {
    return this.medicineSaltMedicineGeneralForSaltUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module MedicineSaltEntityService {

  /**
   * Parameters for findAllMedicineSaltUsingGET
   */
  export interface FindAllMedicineSaltUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveMedicineSaltUsingPUT
   */
  export interface SaveMedicineSaltUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicineSalt;
  }

  /**
   * Parameters for saveMedicineSaltUsingPATCH
   */
  export interface SaveMedicineSaltUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicineSalt;
  }

  /**
   * Parameters for medicineSaltMedicineGeneralForSaltUsingPOST
   */
  export interface MedicineSaltMedicineGeneralForSaltUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineSaltMedicineGeneralForSaltUsingPUT
   */
  export interface MedicineSaltMedicineGeneralForSaltUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineSaltMedicineGeneralForSaltUsingPATCH
   */
  export interface MedicineSaltMedicineGeneralForSaltUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { MedicineSaltEntityService }
