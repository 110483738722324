/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesScribble } from '../models/resources-scribble-';
import { ResourceScribble } from '../models/resource-scribble-';
import { Scribble } from '../models/scribble';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 167
 */
@Injectable()
class ScribbleEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScribbleEntityService.FindAllScribbleUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllScribbleUsingGETResponse(params: ScribbleEntityService.FindAllScribbleUsingGETParams): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.FindAllScribbleUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllScribbleUsingGET(params: ScribbleEntityService.FindAllScribbleUsingGETParams): Observable<ResourcesScribble> {
    return this.findAllScribbleUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveScribbleUsingPOSTResponse(body: Scribble): Observable<HttpResponse<ResourceScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceScribble = null;
        _body = _resp.body as ResourceScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourceScribble>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveScribbleUsingPOST(body: Scribble): Observable<ResourceScribble> {
    return this.saveScribbleUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param consultationId consultationId
   * @return OK
   */
  findByConsultationIdScribbleUsingGETResponse(consultationId?: number): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (consultationId != null) __params = __params.set('consultationId', consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/scribbles/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param consultationId consultationId
   * @return OK
   */
  findByConsultationIdScribbleUsingGET(consultationId?: number): Observable<ResourcesScribble> {
    return this.findByConsultationIdScribbleUsingGETResponse(consultationId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ScribbleEntityService.FindByConsultationIdOrderByLastModifiedAscScribbleUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdOrderByLastModifiedAscScribbleUsingGETResponse(params: ScribbleEntityService.FindByConsultationIdOrderByLastModifiedAscScribbleUsingGETParams): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/scribbles/search/findByConsultationIdOrderByLastModifiedAsc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.FindByConsultationIdOrderByLastModifiedAscScribbleUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdOrderByLastModifiedAscScribbleUsingGET(params: ScribbleEntityService.FindByConsultationIdOrderByLastModifiedAscScribbleUsingGETParams): Observable<ResourcesScribble> {
    return this.findByConsultationIdOrderByLastModifiedAscScribbleUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdScribbleUsingGETResponse(id: number): Observable<HttpResponse<ResourceScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/scribbles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceScribble = null;
        _body = _resp.body as ResourceScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourceScribble>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdScribbleUsingGET(id: number): Observable<ResourceScribble> {
    return this.findByIdScribbleUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ScribbleEntityService.SaveScribbleUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveScribbleUsingPUTResponse(params: ScribbleEntityService.SaveScribbleUsingPUTParams): Observable<HttpResponse<ResourceScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/scribbles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceScribble = null;
        _body = _resp.body as ResourceScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourceScribble>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.SaveScribbleUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveScribbleUsingPUT(params: ScribbleEntityService.SaveScribbleUsingPUTParams): Observable<ResourceScribble> {
    return this.saveScribbleUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteScribbleUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/scribbles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteScribbleUsingDELETE(id: number): Observable<void> {
    return this.deleteScribbleUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ScribbleEntityService.SaveScribbleUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveScribbleUsingPATCHResponse(params: ScribbleEntityService.SaveScribbleUsingPATCHParams): Observable<HttpResponse<ResourceScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/scribbles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceScribble = null;
        _body = _resp.body as ResourceScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourceScribble>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.SaveScribbleUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveScribbleUsingPATCH(params: ScribbleEntityService.SaveScribbleUsingPATCHParams): Observable<ResourceScribble> {
    return this.saveScribbleUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  scribbleConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/scribbles/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  scribbleConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.scribbleConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ScribbleEntityService.ScribbleConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  scribbleConsultationUsingPOSTResponse(params: ScribbleEntityService.ScribbleConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/scribbles/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.ScribbleConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  scribbleConsultationUsingPOST(params: ScribbleEntityService.ScribbleConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.scribbleConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ScribbleEntityService.ScribbleConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  scribbleConsultationUsingPUTResponse(params: ScribbleEntityService.ScribbleConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/scribbles/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.ScribbleConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  scribbleConsultationUsingPUT(params: ScribbleEntityService.ScribbleConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.scribbleConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  scribbleConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/scribbles/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  scribbleConsultationUsingDELETE(id: number): Observable<void> {
    return this.scribbleConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ScribbleEntityService.ScribbleConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  scribbleConsultationUsingPATCHResponse(params: ScribbleEntityService.ScribbleConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/scribbles/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ScribbleEntityService.ScribbleConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  scribbleConsultationUsingPATCH(params: ScribbleEntityService.ScribbleConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.scribbleConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ScribbleEntityService {

  /**
   * Parameters for findAllScribbleUsingGET
   */
  export interface FindAllScribbleUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdOrderByLastModifiedAscScribbleUsingGET
   */
  export interface FindByConsultationIdOrderByLastModifiedAscScribbleUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for saveScribbleUsingPUT
   */
  export interface SaveScribbleUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Scribble;
  }

  /**
   * Parameters for saveScribbleUsingPATCH
   */
  export interface SaveScribbleUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Scribble;
  }

  /**
   * Parameters for scribbleConsultationUsingPOST
   */
  export interface ScribbleConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for scribbleConsultationUsingPUT
   */
  export interface ScribbleConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for scribbleConsultationUsingPATCH
   */
  export interface ScribbleConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { ScribbleEntityService }
