/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDeclineReason } from '../models/resources-decline-reason-';
import { ResourceDeclineReason } from '../models/resource-decline-reason-';
import { DeclineReason } from '../models/decline-reason';
import { ResourcesDiagnostic } from '../models/resources-diagnostic-';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';
import { ResourcesPrescription } from '../models/resources-prescription-';
import { ResourcePrescription } from '../models/resource-prescription-';
import { ResourcesMedicalProcedure } from '../models/resources-medical-procedure-';
import { ResourceMedicalProcedure } from '../models/resource-medical-procedure-';

/**
 * $ Proxy 205
 */
@Injectable()
class DeclineReasonEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DeclineReasonEntityService.FindAllDeclineReasonUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDeclineReasonUsingGETResponse(params: DeclineReasonEntityService.FindAllDeclineReasonUsingGETParams): Observable<HttpResponse<ResourcesDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons?projection=declineReasonsForConsultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDeclineReason = null;
        _body = _resp.body as ResourcesDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDeclineReason>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.FindAllDeclineReasonUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDeclineReasonUsingGET(params: DeclineReasonEntityService.FindAllDeclineReasonUsingGETParams): Observable<ResourcesDeclineReason> {
    return this.findAllDeclineReasonUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDeclineReasonUsingPOSTResponse(body: DeclineReason): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/declinereasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDeclineReasonUsingPOST(body: DeclineReason): Observable<ResourceDeclineReason> {
    return this.saveDeclineReasonUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDeclineReasonUsingGETResponse(id: number): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDeclineReasonUsingGET(id: number): Observable<ResourceDeclineReason> {
    return this.findByIdDeclineReasonUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.SaveDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDeclineReasonUsingPUTResponse(params: DeclineReasonEntityService.SaveDeclineReasonUsingPUTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.SaveDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDeclineReasonUsingPUT(params: DeclineReasonEntityService.SaveDeclineReasonUsingPUTParams): Observable<ResourceDeclineReason> {
    return this.saveDeclineReasonUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDeclineReasonUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDeclineReasonUsingDELETE(id: number): Observable<void> {
    return this.deleteDeclineReasonUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.SaveDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDeclineReasonUsingPATCHResponse(params: DeclineReasonEntityService.SaveDeclineReasonUsingPATCHParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.SaveDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDeclineReasonUsingPATCH(params: DeclineReasonEntityService.SaveDeclineReasonUsingPATCHParams): Observable<ResourceDeclineReason> {
    return this.saveDeclineReasonUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  declineReasonDiagnosticsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  declineReasonDiagnosticsUsingGET_1(id: number): Observable<ResourcesDiagnostic> {
    return this.declineReasonDiagnosticsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingPOSTResponse(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingPOST(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPOSTParams): Observable<ResourcesDiagnostic> {
    return this.declineReasonDiagnosticsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingPUTResponse(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPUTParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingPUT(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPUTParams): Observable<ResourcesDiagnostic> {
    return this.declineReasonDiagnosticsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  declineReasonDiagnosticsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  declineReasonDiagnosticsUsingDELETE_1(id: number): Observable<void> {
    return this.declineReasonDiagnosticsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingPATCHResponse(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingPATCH(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingPATCHParams): Observable<ResourcesDiagnostic> {
    return this.declineReasonDiagnosticsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingGETResponse(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingGETParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/diagnostics/${params.diagnosticId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  declineReasonDiagnosticsUsingGET(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingGETParams): Observable<ResourceDiagnostic> {
    return this.declineReasonDiagnosticsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   */
  declineReasonDiagnosticsUsingDELETEResponse(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/diagnostics/${params.diagnosticId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonDiagnosticsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   */
  declineReasonDiagnosticsUsingDELETE(params: DeclineReasonEntityService.DeclineReasonDiagnosticsUsingDELETEParams): Observable<void> {
    return this.declineReasonDiagnosticsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  declineReasonPrescriptionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  declineReasonPrescriptionsUsingGET_1(id: number): Observable<ResourcesPrescription> {
    return this.declineReasonPrescriptionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingPOSTResponse(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPOSTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingPOST(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPOSTParams): Observable<ResourcesPrescription> {
    return this.declineReasonPrescriptionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingPUTResponse(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPUTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingPUT(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPUTParams): Observable<ResourcesPrescription> {
    return this.declineReasonPrescriptionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  declineReasonPrescriptionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  declineReasonPrescriptionsUsingDELETE_1(id: number): Observable<void> {
    return this.declineReasonPrescriptionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingPATCHResponse(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPATCHParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingPATCH(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingPATCHParams): Observable<ResourcesPrescription> {
    return this.declineReasonPrescriptionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingGETResponse(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingGETParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  declineReasonPrescriptionsUsingGET(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingGETParams): Observable<ResourcePrescription> {
    return this.declineReasonPrescriptionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  declineReasonPrescriptionsUsingDELETEResponse(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonPrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  declineReasonPrescriptionsUsingDELETE(params: DeclineReasonEntityService.DeclineReasonPrescriptionsUsingDELETEParams): Observable<void> {
    return this.declineReasonPrescriptionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  declineReasonProceduresUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  declineReasonProceduresUsingGET_1(id: number): Observable<ResourcesMedicalProcedure> {
    return this.declineReasonProceduresUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonProceduresUsingPOSTResponse(params: DeclineReasonEntityService.DeclineReasonProceduresUsingPOSTParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonProceduresUsingPOST(params: DeclineReasonEntityService.DeclineReasonProceduresUsingPOSTParams): Observable<ResourcesMedicalProcedure> {
    return this.declineReasonProceduresUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonProceduresUsingPUTResponse(params: DeclineReasonEntityService.DeclineReasonProceduresUsingPUTParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonProceduresUsingPUT(params: DeclineReasonEntityService.DeclineReasonProceduresUsingPUTParams): Observable<ResourcesMedicalProcedure> {
    return this.declineReasonProceduresUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  declineReasonProceduresUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  declineReasonProceduresUsingDELETE_1(id: number): Observable<void> {
    return this.declineReasonProceduresUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonProceduresUsingPATCHResponse(params: DeclineReasonEntityService.DeclineReasonProceduresUsingPATCHParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  declineReasonProceduresUsingPATCH(params: DeclineReasonEntityService.DeclineReasonProceduresUsingPATCHParams): Observable<ResourcesMedicalProcedure> {
    return this.declineReasonProceduresUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingGETParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   *
   * @return OK
   */
  declineReasonProceduresUsingGETResponse(params: DeclineReasonEntityService.DeclineReasonProceduresUsingGETParams): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/procedures/${params.medicalprocedureId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingGETParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   *
   * @return OK
   */
  declineReasonProceduresUsingGET(params: DeclineReasonEntityService.DeclineReasonProceduresUsingGETParams): Observable<ResourceMedicalProcedure> {
    return this.declineReasonProceduresUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingDELETEParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   */
  declineReasonProceduresUsingDELETEResponse(params: DeclineReasonEntityService.DeclineReasonProceduresUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/declinereasons/${params.id}/procedures/${params.medicalprocedureId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DeclineReasonEntityService.DeclineReasonProceduresUsingDELETEParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   */
  declineReasonProceduresUsingDELETE(params: DeclineReasonEntityService.DeclineReasonProceduresUsingDELETEParams): Observable<void> {
    return this.declineReasonProceduresUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DeclineReasonEntityService {

  /**
   * Parameters for findAllDeclineReasonUsingGET
   */
  export interface FindAllDeclineReasonUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDeclineReasonUsingPUT
   */
  export interface SaveDeclineReasonUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DeclineReason;
  }

  /**
   * Parameters for saveDeclineReasonUsingPATCH
   */
  export interface SaveDeclineReasonUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DeclineReason;
  }

  /**
   * Parameters for declineReasonDiagnosticsUsingPOST
   */
  export interface DeclineReasonDiagnosticsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonDiagnosticsUsingPUT
   */
  export interface DeclineReasonDiagnosticsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonDiagnosticsUsingPATCH
   */
  export interface DeclineReasonDiagnosticsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonDiagnosticsUsingGET
   */
  export interface DeclineReasonDiagnosticsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticId
     */
    diagnosticId: string;
  }

  /**
   * Parameters for declineReasonDiagnosticsUsingDELETE
   */
  export interface DeclineReasonDiagnosticsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticId
     */
    diagnosticId: string;
  }

  /**
   * Parameters for declineReasonPrescriptionsUsingPOST
   */
  export interface DeclineReasonPrescriptionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonPrescriptionsUsingPUT
   */
  export interface DeclineReasonPrescriptionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonPrescriptionsUsingPATCH
   */
  export interface DeclineReasonPrescriptionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonPrescriptionsUsingGET
   */
  export interface DeclineReasonPrescriptionsUsingGETParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for declineReasonPrescriptionsUsingDELETE
   */
  export interface DeclineReasonPrescriptionsUsingDELETEParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for declineReasonProceduresUsingPOST
   */
  export interface DeclineReasonProceduresUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonProceduresUsingPUT
   */
  export interface DeclineReasonProceduresUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonProceduresUsingPATCH
   */
  export interface DeclineReasonProceduresUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for declineReasonProceduresUsingGET
   */
  export interface DeclineReasonProceduresUsingGETParams {

    /**
     * medicalprocedureId
     */
    medicalprocedureId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for declineReasonProceduresUsingDELETE
   */
  export interface DeclineReasonProceduresUsingDELETEParams {

    /**
     * medicalprocedureId
     */
    medicalprocedureId: string;

    /**
     * id
     */
    id: number;
  }
}

export { DeclineReasonEntityService }
