/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesRider } from '../models/resources-rider-';
import { ResourceRider } from '../models/resource-rider-';
import { Rider } from '../models/rider';
import { ResourcesRiderWithOrderItem } from '../models/resources-rider-with-order-item-';
import { ResourceRiderWithOrderItem } from '../models/resource-rider-with-order-item-';

/**
 * $ Proxy 181
 */
@Injectable()
class RiderEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RiderEntityService.FindAllRiderUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllRiderUsingGETResponse(params: RiderEntityService.FindAllRiderUsingGETParams): Observable<HttpResponse<ResourcesRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/riders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRider = null;
        _body = _resp.body as ResourcesRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRider>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.FindAllRiderUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllRiderUsingGET(params: RiderEntityService.FindAllRiderUsingGETParams): Observable<ResourcesRider> {
    return this.findAllRiderUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveRiderUsingPOSTResponse(body: Rider): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/riders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveRiderUsingPOST(body: Rider): Observable<ResourceRider> {
    return this.saveRiderUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdRiderUsingGETResponse(id: number): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/riders/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdRiderUsingGET(id: number): Observable<ResourceRider> {
    return this.findByIdRiderUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.SaveRiderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderUsingPUTResponse(params: RiderEntityService.SaveRiderUsingPUTParams): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/riders/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.SaveRiderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderUsingPUT(params: RiderEntityService.SaveRiderUsingPUTParams): Observable<ResourceRider> {
    return this.saveRiderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteRiderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/riders/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteRiderUsingDELETE(id: number): Observable<void> {
    return this.deleteRiderUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.SaveRiderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderUsingPATCHResponse(params: RiderEntityService.SaveRiderUsingPATCHParams): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/riders/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.SaveRiderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderUsingPATCH(params: RiderEntityService.SaveRiderUsingPATCHParams): Observable<ResourceRider> {
    return this.saveRiderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  riderOrderItemsWithRidersUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/riders/${id}/orderItemsWithRiders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  riderOrderItemsWithRidersUsingGET_1(id: number): Observable<ResourcesRiderWithOrderItem> {
    return this.riderOrderItemsWithRidersUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingPOSTResponse(params: RiderEntityService.RiderOrderItemsWithRidersUsingPOSTParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/riders/${params.id}/orderItemsWithRiders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingPOST(params: RiderEntityService.RiderOrderItemsWithRidersUsingPOSTParams): Observable<ResourcesRiderWithOrderItem> {
    return this.riderOrderItemsWithRidersUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingPUTResponse(params: RiderEntityService.RiderOrderItemsWithRidersUsingPUTParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/riders/${params.id}/orderItemsWithRiders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingPUT(params: RiderEntityService.RiderOrderItemsWithRidersUsingPUTParams): Observable<ResourcesRiderWithOrderItem> {
    return this.riderOrderItemsWithRidersUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  riderOrderItemsWithRidersUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/riders/${id}/orderItemsWithRiders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  riderOrderItemsWithRidersUsingDELETE_1(id: number): Observable<void> {
    return this.riderOrderItemsWithRidersUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingPATCHResponse(params: RiderEntityService.RiderOrderItemsWithRidersUsingPATCHParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/riders/${params.id}/orderItemsWithRiders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingPATCH(params: RiderEntityService.RiderOrderItemsWithRidersUsingPATCHParams): Observable<ResourcesRiderWithOrderItem> {
    return this.riderOrderItemsWithRidersUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingGETParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingGETResponse(params: RiderEntityService.RiderOrderItemsWithRidersUsingGETParams): Observable<HttpResponse<ResourceRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/riders/${params.id}/orderItemsWithRiders/${params.riderwithorderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRiderWithOrderItem = null;
        _body = _resp.body as ResourceRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingGETParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  riderOrderItemsWithRidersUsingGET(params: RiderEntityService.RiderOrderItemsWithRidersUsingGETParams): Observable<ResourceRiderWithOrderItem> {
    return this.riderOrderItemsWithRidersUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingDELETEParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   */
  riderOrderItemsWithRidersUsingDELETEResponse(params: RiderEntityService.RiderOrderItemsWithRidersUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/riders/${params.id}/orderItemsWithRiders/${params.riderwithorderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `RiderEntityService.RiderOrderItemsWithRidersUsingDELETEParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   */
  riderOrderItemsWithRidersUsingDELETE(params: RiderEntityService.RiderOrderItemsWithRidersUsingDELETEParams): Observable<void> {
    return this.riderOrderItemsWithRidersUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module RiderEntityService {

  /**
   * Parameters for findAllRiderUsingGET
   */
  export interface FindAllRiderUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveRiderUsingPUT
   */
  export interface SaveRiderUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Rider;
  }

  /**
   * Parameters for saveRiderUsingPATCH
   */
  export interface SaveRiderUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Rider;
  }

  /**
   * Parameters for riderOrderItemsWithRidersUsingPOST
   */
  export interface RiderOrderItemsWithRidersUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for riderOrderItemsWithRidersUsingPUT
   */
  export interface RiderOrderItemsWithRidersUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for riderOrderItemsWithRidersUsingPATCH
   */
  export interface RiderOrderItemsWithRidersUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for riderOrderItemsWithRidersUsingGET
   */
  export interface RiderOrderItemsWithRidersUsingGETParams {

    /**
     * riderwithorderitemId
     */
    riderwithorderitemId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for riderOrderItemsWithRidersUsingDELETE
   */
  export interface RiderOrderItemsWithRidersUsingDELETEParams {

    /**
     * riderwithorderitemId
     */
    riderwithorderitemId: string;

    /**
     * id
     */
    id: number;
  }
}

export { RiderEntityService }
