/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesConsultationHistory } from '../models/resources-consultation-history-';
import { ResourceConsultationHistory } from '../models/resource-consultation-history-';
import { ConsultationHistory } from '../models/consultation-history';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 169
 */
@Injectable()
class ConsultationHistoryEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindAllConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllConsultationHistoryUsingGETResponse(params: ConsultationHistoryEntityService.FindAllConsultationHistoryUsingGETParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindAllConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllConsultationHistoryUsingGET(params: ConsultationHistoryEntityService.FindAllConsultationHistoryUsingGETParams): Observable<ResourcesConsultationHistory> {
    return this.findAllConsultationHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveConsultationHistoryUsingPOSTResponse(body: ConsultationHistory): Observable<HttpResponse<ResourceConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultationHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationHistory = null;
        _body = _resp.body as ResourceConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationHistory>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveConsultationHistoryUsingPOST(body: ConsultationHistory): Observable<ResourceConsultationHistory> {
    return this.saveConsultationHistoryUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETResponse(params: ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory/search/findByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGET(params: ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETParams): Observable<ResourcesConsultationHistory> {
    return this.findByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETResponse(params: ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory/search/findByConsultationDoctorInClinicIdInAndCreatedAtBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGET(params: ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETParams): Observable<ResourcesConsultationHistory> {
    return this.findByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETResponse(params: ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory/search/findByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGET(params: ConsultationHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETParams): Observable<ResourcesConsultationHistory> {
    return this.findByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `previous`: previous
   *
   * - `id`: id
   *
   * - `current`: current
   *
   * @return OK
   */
  findByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETResponse(params: ConsultationHistoryEntityService.FindByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.previous != null) __params = __params.set('previous', params.previous.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.current != null) __params = __params.set('current', params.current.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory/search/findByConsultationIdInAndPreviousStatusAndCurrentStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.FindByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETParams` containing the following parameters:
   *
   * - `previous`: previous
   *
   * - `id`: id
   *
   * - `current`: current
   *
   * @return OK
   */
  findByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGET(params: ConsultationHistoryEntityService.FindByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETParams): Observable<ResourcesConsultationHistory> {
    return this.findByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationHistoryUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationHistory = null;
        _body = _resp.body as ResourceConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationHistoryUsingGET(id: number): Observable<ResourceConsultationHistory> {
    return this.findByIdConsultationHistoryUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.SaveConsultationHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationHistoryUsingPUTResponse(params: ConsultationHistoryEntityService.SaveConsultationHistoryUsingPUTParams): Observable<HttpResponse<ResourceConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultationHistory/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationHistory = null;
        _body = _resp.body as ResourceConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.SaveConsultationHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationHistoryUsingPUT(params: ConsultationHistoryEntityService.SaveConsultationHistoryUsingPUTParams): Observable<ResourceConsultationHistory> {
    return this.saveConsultationHistoryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteConsultationHistoryUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultationHistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteConsultationHistoryUsingDELETE(id: number): Observable<void> {
    return this.deleteConsultationHistoryUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.SaveConsultationHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationHistoryUsingPATCHResponse(params: ConsultationHistoryEntityService.SaveConsultationHistoryUsingPATCHParams): Observable<HttpResponse<ResourceConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultationHistory/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationHistory = null;
        _body = _resp.body as ResourceConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.SaveConsultationHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationHistoryUsingPATCH(params: ConsultationHistoryEntityService.SaveConsultationHistoryUsingPATCHParams): Observable<ResourceConsultationHistory> {
    return this.saveConsultationHistoryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationHistoryConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultationHistory/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationHistoryConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.consultationHistoryConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationHistoryConsultationUsingPOSTResponse(params: ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultationHistory/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationHistoryConsultationUsingPOST(params: ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.consultationHistoryConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationHistoryConsultationUsingPUTResponse(params: ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultationHistory/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationHistoryConsultationUsingPUT(params: ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.consultationHistoryConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationHistoryConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultationHistory/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationHistoryConsultationUsingDELETE(id: number): Observable<void> {
    return this.consultationHistoryConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationHistoryConsultationUsingPATCHResponse(params: ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultationHistory/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationHistoryConsultationUsingPATCH(params: ConsultationHistoryEntityService.ConsultationHistoryConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.consultationHistoryConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ConsultationHistoryEntityService {

  /**
   * Parameters for findAllConsultationHistoryUsingGET
   */
  export interface FindAllConsultationHistoryUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndConsultationAppointmentTimeBetweenConsultationHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndCreatedAtBetweenConsultationHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndCurrentStatusAndCreatedAtBetweenConsultationHistoryUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGET
   */
  export interface FindByConsultationIdInAndPreviousStatusAndCurrentStatusConsultationHistoryUsingGETParams {

    /**
     * previous
     */
    previous?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * id
     */
    id?: number;

    /**
     * current
     */
    current?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
  }

  /**
   * Parameters for saveConsultationHistoryUsingPUT
   */
  export interface SaveConsultationHistoryUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ConsultationHistory;
  }

  /**
   * Parameters for saveConsultationHistoryUsingPATCH
   */
  export interface SaveConsultationHistoryUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ConsultationHistory;
  }

  /**
   * Parameters for consultationHistoryConsultationUsingPOST
   */
  export interface ConsultationHistoryConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationHistoryConsultationUsingPUT
   */
  export interface ConsultationHistoryConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationHistoryConsultationUsingPATCH
   */
  export interface ConsultationHistoryConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { ConsultationHistoryEntityService }
