/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesMedicationDate } from '../models/resources-medication-date-';
import { ResourceMedicationDate } from '../models/resource-medication-date-';
import { MedicationDate } from '../models/medication-date';
import { ResourcePrescriptionDescription } from '../models/resource-prescription-description-';

/**
 * Simple Jpa Repository
 */
@Injectable()
class MedicationDateEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MedicationDateEntityService.FindAllMedicationDateUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicationDateUsingGETResponse(params: MedicationDateEntityService.FindAllMedicationDateUsingGETParams): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/MedicationDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.FindAllMedicationDateUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicationDateUsingGET(params: MedicationDateEntityService.FindAllMedicationDateUsingGETParams): Observable<ResourcesMedicationDate> {
    return this.findAllMedicationDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicationDateUsingPOSTResponse(body: MedicationDate): Observable<HttpResponse<ResourceMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/MedicationDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicationDate = null;
        _body = _resp.body as ResourceMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicationDate>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicationDateUsingPOST(body: MedicationDate): Observable<ResourceMedicationDate> {
    return this.saveMedicationDateUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicationDateEntityService.FindByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `date`: date
   *
   * @return OK
   */
  findByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETResponse(params: MedicationDateEntityService.FindByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETParams): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/MedicationDates/search/findByConsumptionDateAndPrescriptionDescriptionId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.FindByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `date`: date
   *
   * @return OK
   */
  findByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGET(params: MedicationDateEntityService.FindByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETParams): Observable<ResourcesMedicationDate> {
    return this.findByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByPrescriptionDescriptionIdMedicationDateUsingGETResponse(id?: number): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/MedicationDates/search/findByPrescriptionDescriptionId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByPrescriptionDescriptionIdMedicationDateUsingGET(id?: number): Observable<ResourcesMedicationDate> {
    return this.findByPrescriptionDescriptionIdMedicationDateUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicationDateUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/MedicationDates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicationDate = null;
        _body = _resp.body as ResourceMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicationDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicationDateUsingGET(id: number): Observable<ResourceMedicationDate> {
    return this.findByIdMedicationDateUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicationDateEntityService.SaveMedicationDateUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicationDateUsingPUTResponse(params: MedicationDateEntityService.SaveMedicationDateUsingPUTParams): Observable<HttpResponse<ResourceMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/MedicationDates/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicationDate = null;
        _body = _resp.body as ResourceMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicationDate>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.SaveMedicationDateUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicationDateUsingPUT(params: MedicationDateEntityService.SaveMedicationDateUsingPUTParams): Observable<ResourceMedicationDate> {
    return this.saveMedicationDateUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteMedicationDateUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/MedicationDates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteMedicationDateUsingDELETE(id: number): Observable<void> {
    return this.deleteMedicationDateUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicationDateEntityService.SaveMedicationDateUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicationDateUsingPATCHResponse(params: MedicationDateEntityService.SaveMedicationDateUsingPATCHParams): Observable<HttpResponse<ResourceMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/MedicationDates/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicationDate = null;
        _body = _resp.body as ResourceMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicationDate>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.SaveMedicationDateUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicationDateUsingPATCH(params: MedicationDateEntityService.SaveMedicationDateUsingPATCHParams): Observable<ResourceMedicationDate> {
    return this.saveMedicationDateUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/MedicationDates/${id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingGET(id: number): Observable<ResourcePrescriptionDescription> {
    return this.medicationDatePrescriptionDescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingPOSTResponse(params: MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPOSTParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/MedicationDates/${params.id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingPOST(params: MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPOSTParams): Observable<ResourcePrescriptionDescription> {
    return this.medicationDatePrescriptionDescriptionUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingPUTResponse(params: MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPUTParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/MedicationDates/${params.id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingPUT(params: MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPUTParams): Observable<ResourcePrescriptionDescription> {
    return this.medicationDatePrescriptionDescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicationDatePrescriptionDescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/MedicationDates/${id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicationDatePrescriptionDescriptionUsingDELETE(id: number): Observable<void> {
    return this.medicationDatePrescriptionDescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingPATCHResponse(params: MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPATCHParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/MedicationDates/${params.id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicationDatePrescriptionDescriptionUsingPATCH(params: MedicationDateEntityService.MedicationDatePrescriptionDescriptionUsingPATCHParams): Observable<ResourcePrescriptionDescription> {
    return this.medicationDatePrescriptionDescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module MedicationDateEntityService {

  /**
   * Parameters for findAllMedicationDateUsingGET
   */
  export interface FindAllMedicationDateUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGET
   */
  export interface FindByConsumptionDateAndPrescriptionDescriptionIdMedicationDateUsingGETParams {

    /**
     * id
     */
    id?: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for saveMedicationDateUsingPUT
   */
  export interface SaveMedicationDateUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicationDate;
  }

  /**
   * Parameters for saveMedicationDateUsingPATCH
   */
  export interface SaveMedicationDateUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicationDate;
  }

  /**
   * Parameters for medicationDatePrescriptionDescriptionUsingPOST
   */
  export interface MedicationDatePrescriptionDescriptionUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicationDatePrescriptionDescriptionUsingPUT
   */
  export interface MedicationDatePrescriptionDescriptionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicationDatePrescriptionDescriptionUsingPATCH
   */
  export interface MedicationDatePrescriptionDescriptionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { MedicationDateEntityService }
