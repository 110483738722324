/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { AuthenticationService } from '../../../login/service/authentication.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


/**
 * Forgot Pass Controller
 */
@Injectable()
class SendOtpControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private AuthenticationService : AuthenticationService
  ) {
    super(config, http);
  }

  /**
   * @param mobile
   * @param clinicName
   * @param userId
   */
  getSendOtpUsingPOSTResponse(mobile: String, clinicName: String, userId: String): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let AuthorizationToken = environment.otpToken;
    __headers = __headers.set('Authorization', AuthorizationToken);
    if (mobile != null) __params = __params.set('mobile', mobile.toString());
    if (clinicName != null) __params = __params.set('clinicName', clinicName.toString());
    if (userId != null) __params = __params.set('userId', userId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `api/v1/auth/send-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param mobile
   * @param clinicName
   * @param userId
   */
  getSendOtpUsingPOST(mobile: String, clinicName: String,  userId: String): Observable<void> {
    return this.getSendOtpUsingPOSTResponse(mobile, clinicName, userId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params
   */
  getVarifyOtpUsingGETResponse(params: SendOtpControllerService.VerifyOtpGETParams): Observable<HttpResponse<String>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let AuthorizationToken = environment.otpToken;
    __headers = __headers.set('Authorization', AuthorizationToken);
    if (params.mobile != null) __params = __params.set('mobile', params.mobile.toString());
    if (params.otp != null) __params = __params.set('otp', params.otp.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `api/v1/auth/verify-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<String>;
        let _body: String = null;
        _body = _resp.body;

        return _resp.clone({body: _body}) as HttpResponse<String>;
      })
    );
  }

  /**
   * @param mobile
   * @param otp
   */
  getVerifyOtpUsingGET(params: SendOtpControllerService.VerifyOtpGETParams): Observable<any> {
    return this.getVarifyOtpUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SendOtpControllerService {

  /**
   * Parameters for findAllUserUsingGET
   */
  export interface VerifyOtpGETParams {

    /**
     * mobile
     */
    mobile?: String;

    /**
     * otp
     */
    otp?: String;

  }
}

export { SendOtpControllerService }
