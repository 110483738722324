import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'radiologySearch'
})
export class RadiologySearchPipe implements PipeTransform {

  transform(diagnosticTypeInLabs: any, labName?, city?): any {

    if (labName) {
      return diagnosticTypeInLabs.filter(diagnosticTypeInLab => diagnosticTypeInLab.diagnosticLab.name.toLowerCase().indexOf(labName.toLowerCase()) != -1)
    } else if (city) {
      return diagnosticTypeInLabs.filter(diagnosticTypeInLab => diagnosticTypeInLab.diagnosticLab.address.toLowerCase().indexOf(city.toLowerCase()) != -1)
    } else {
      return diagnosticTypeInLabs;
    }
  }
}
