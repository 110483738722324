/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesProcedureType } from '../models/resources-procedure-type-';
import { ResourceProcedureType } from '../models/resource-procedure-type-';
import { ProcedureType } from '../models/procedure-type';
import { ResourceClinic } from '../models/resource-clinic-';
import { ResourcesMedicalProcedure } from '../models/resources-medical-procedure-';
import { ResourceMedicalProcedure } from '../models/resource-medical-procedure-';

/**
 * $ Proxy 217
 */
@Injectable()
class ProcedureTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProcedureTypeEntityService.FindAllProcedureTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllProcedureTypeUsingGETResponse(params: ProcedureTypeEntityService.FindAllProcedureTypeUsingGETParams): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.FindAllProcedureTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllProcedureTypeUsingGET(params: ProcedureTypeEntityService.FindAllProcedureTypeUsingGETParams): Observable<ResourcesProcedureType> {
    return this.findAllProcedureTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveProcedureTypeUsingPOSTResponse(body: ProcedureType): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/proceduretypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveProcedureTypeUsingPOST(body: ProcedureType): Observable<ResourceProcedureType> {
    return this.saveProcedureTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.FindAllByClinic_IdProcedureTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findAllByClinic_IdProcedureTypeUsingGETResponse(params: ProcedureTypeEntityService.FindAllByClinic_IdProcedureTypeUsingGETParams): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes/search/findAllByClinic_Id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.FindAllByClinic_IdProcedureTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findAllByClinic_IdProcedureTypeUsingGET(params: ProcedureTypeEntityService.FindAllByClinic_IdProcedureTypeUsingGETParams): Observable<ResourcesProcedureType> {
    return this.findAllByClinic_IdProcedureTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.FindAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETResponse(params: ProcedureTypeEntityService.FindAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETParams): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes/search/findAllByNameIgnoreCaseContainingAndClinic_Id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.FindAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGET(params: ProcedureTypeEntityService.FindAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETParams): Observable<ResourcesProcedureType> {
    return this.findAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdProcedureTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdProcedureTypeUsingGET(id: number): Observable<ResourceProcedureType> {
    return this.findByIdProcedureTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.SaveProcedureTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProcedureTypeUsingPUTResponse(params: ProcedureTypeEntityService.SaveProcedureTypeUsingPUTParams): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.SaveProcedureTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProcedureTypeUsingPUT(params: ProcedureTypeEntityService.SaveProcedureTypeUsingPUTParams): Observable<ResourceProcedureType> {
    return this.saveProcedureTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteProcedureTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/proceduretypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteProcedureTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteProcedureTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.SaveProcedureTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProcedureTypeUsingPATCHResponse(params: ProcedureTypeEntityService.SaveProcedureTypeUsingPATCHParams): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.SaveProcedureTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProcedureTypeUsingPATCH(params: ProcedureTypeEntityService.SaveProcedureTypeUsingPATCHParams): Observable<ResourceProcedureType> {
    return this.saveProcedureTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  procedureTypeClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes/${id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  procedureTypeClinicUsingGET(id: number): Observable<ResourceClinic> {
    return this.procedureTypeClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeClinicUsingPOSTResponse(params: ProcedureTypeEntityService.ProcedureTypeClinicUsingPOSTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeClinicUsingPOST(params: ProcedureTypeEntityService.ProcedureTypeClinicUsingPOSTParams): Observable<ResourceClinic> {
    return this.procedureTypeClinicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeClinicUsingPUTResponse(params: ProcedureTypeEntityService.ProcedureTypeClinicUsingPUTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeClinicUsingPUT(params: ProcedureTypeEntityService.ProcedureTypeClinicUsingPUTParams): Observable<ResourceClinic> {
    return this.procedureTypeClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  procedureTypeClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/proceduretypes/${id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  procedureTypeClinicUsingDELETE(id: number): Observable<void> {
    return this.procedureTypeClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeClinicUsingPATCHResponse(params: ProcedureTypeEntityService.ProcedureTypeClinicUsingPATCHParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeClinicUsingPATCH(params: ProcedureTypeEntityService.ProcedureTypeClinicUsingPATCHParams): Observable<ResourceClinic> {
    return this.procedureTypeClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  procedureTypeMedicalProceduresUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes/${id}/medicalProcedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  procedureTypeMedicalProceduresUsingGET_1(id: number): Observable<ResourcesMedicalProcedure> {
    return this.procedureTypeMedicalProceduresUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingPOSTResponse(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPOSTParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/medicalProcedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingPOST(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPOSTParams): Observable<ResourcesMedicalProcedure> {
    return this.procedureTypeMedicalProceduresUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingPUTResponse(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPUTParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/medicalProcedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingPUT(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPUTParams): Observable<ResourcesMedicalProcedure> {
    return this.procedureTypeMedicalProceduresUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  procedureTypeMedicalProceduresUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/proceduretypes/${id}/medicalProcedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  procedureTypeMedicalProceduresUsingDELETE_1(id: number): Observable<void> {
    return this.procedureTypeMedicalProceduresUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingPATCHResponse(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPATCHParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/medicalProcedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingPATCH(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingPATCHParams): Observable<ResourcesMedicalProcedure> {
    return this.procedureTypeMedicalProceduresUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingGETParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingGETResponse(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingGETParams): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/medicalProcedures/${params.medicalprocedureId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingGETParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   *
   * @return OK
   */
  procedureTypeMedicalProceduresUsingGET(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingGETParams): Observable<ResourceMedicalProcedure> {
    return this.procedureTypeMedicalProceduresUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingDELETEParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   */
  procedureTypeMedicalProceduresUsingDELETEResponse(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/proceduretypes/${params.id}/medicalProcedures/${params.medicalprocedureId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingDELETEParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   */
  procedureTypeMedicalProceduresUsingDELETE(params: ProcedureTypeEntityService.ProcedureTypeMedicalProceduresUsingDELETEParams): Observable<void> {
    return this.procedureTypeMedicalProceduresUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ProcedureTypeEntityService {

  /**
   * Parameters for findAllProcedureTypeUsingGET
   */
  export interface FindAllProcedureTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findAllByClinic_IdProcedureTypeUsingGET
   */
  export interface FindAllByClinic_IdProcedureTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * clinicId
     */
    clinicId?: number;
  }

  /**
   * Parameters for findAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGET
   */
  export interface FindAllByNameIgnoreCaseContainingAndClinic_IdProcedureTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * clinicId
     */
    clinicId?: number;
  }

  /**
   * Parameters for saveProcedureTypeUsingPUT
   */
  export interface SaveProcedureTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ProcedureType;
  }

  /**
   * Parameters for saveProcedureTypeUsingPATCH
   */
  export interface SaveProcedureTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ProcedureType;
  }

  /**
   * Parameters for procedureTypeClinicUsingPOST
   */
  export interface ProcedureTypeClinicUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for procedureTypeClinicUsingPUT
   */
  export interface ProcedureTypeClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for procedureTypeClinicUsingPATCH
   */
  export interface ProcedureTypeClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for procedureTypeMedicalProceduresUsingPOST
   */
  export interface ProcedureTypeMedicalProceduresUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for procedureTypeMedicalProceduresUsingPUT
   */
  export interface ProcedureTypeMedicalProceduresUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for procedureTypeMedicalProceduresUsingPATCH
   */
  export interface ProcedureTypeMedicalProceduresUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for procedureTypeMedicalProceduresUsingGET
   */
  export interface ProcedureTypeMedicalProceduresUsingGETParams {

    /**
     * medicalprocedureId
     */
    medicalprocedureId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for procedureTypeMedicalProceduresUsingDELETE
   */
  export interface ProcedureTypeMedicalProceduresUsingDELETEParams {

    /**
     * medicalprocedureId
     */
    medicalprocedureId: string;

    /**
     * id
     */
    id: number;
  }
}

export { ProcedureTypeEntityService }
