import { ProductOverviewComponent } from "./../layout/product-overview/product-overview.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ImageViewerModule } from "ng2-image-viewer";
import { OrderSummaryComponent } from "./order-summary/order-summary.component";
import { ConsultationPriceDirective } from "./order-summary/directives/consultation-price.directive";
import { MaterialModule } from "./angular-material/material.module";
import { TranscriptionDiagnosticsDialog } from "./dialog/diagnostics/diagnostics.component";
import { RadiologyDialog } from "./dialog/radiology/radiology.component";
import { SafePipe } from "./pipes/safe/safe.pipe";
import { MomentModule } from "ngx-moment";
import { ApiModule as AuthApiModule } from "../api/auth/api.module";
import { HttpModule } from "@angular/http";
import { NgOtpInputModule } from "ng-otp-input";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { DashboardSearch } from "./pipes/dashboard-search/dashboard-search";
import { GeneralInformationComponent } from "./general-information/general-information.component";
import { MedicalInformationComponent } from "./medical-information/medical-information.component";
import { ConfirmationPageComponent } from "./confirmation-page/confirmation-page.component";
import { RouterModule } from "@angular/router";
import { RadiologySearchPipe } from "./pipes/radiology-search/radiology-search.pipe";
import { NewMedicineComponent } from "./dialog/new-medicine/new-medicine.component";
import { AddNewPatientComponent } from "./dialog/add-new-patient/add-new-patient.component";
import { NewReasonDialogComponent } from "./dialog/new-reason-dialog/new-reason-dialog.component";
import { CreateNewBatchComponent } from "./dialog/create-new-batch/create-new-batch.component";
import { ReasonComponent } from "./dialog/reason/reason.component";
import { ViewAllPatientsComponent } from "./components/view-all-patients/view-all-patients.component";
import { NewProcedureTypeDialogComponent } from "./dialog/new-procedure-type-dialog/new-procedure-type-dialog.component";
import { SelectAddressComponent } from "./dialog/select-address/select-address.component";
import { OrderCartSummaryComponent } from "./order-cart-summary/order-cart-summary.component";
import { OrderCartPriceDirective } from "./order-cart-summary/directives/order-cart-price.directive";
import { ConsultationDialogComponent } from "./dialog/consultation-dialog/consultation-dialog.component";
import { ConsultationSelectionComponent } from "./dialog/consultation-selection/consultation-selection.component";
import { AddPatientInformationComponent } from "./add-patient-information/add-patient-information.component";
import { SendOtpDialogComponent } from "./dialog/send-otp-dialog/send-otp-dialog.component";
import { TranscriberListComponent } from "../layout/transcriber-list/transcriber-list.component";
import { EditTranscriberComponent } from "./dialog/edit-transcriber/edit-transcriber.component";
import { PendingTranscribingComponent } from "../layout/pending-transcribing/pending-transcribing.component";
import { TimeSlotsComponent } from "./time-slots/time-slots.component";
import { SelectDoctorProfileComponent } from "./dialog/select-doctor-profile/select-doctor-profile.component";
import { OrderReasonComponent } from "./dialog/order-reason/order-reason.component";
import { ConfirmationAppointmentComponent } from "./confirmation-appointment/confirmation-appointment.component";
import { PincodeCheckComponent } from "./dialog/pincode-check/pincode-check.component";
import { CustomSnackbarComponent } from "./custom-snackbar/custom-snackbar.component";
import { LoginDialogComponent } from "./login-dialog/login-dialog.component";
import { MultiplePatientDialogComponent } from "./multiple-patient-dialog/multiple-patient-dialog.component";
import { ScanBarcodeDialogComponent } from './dialog/scan-barcode-dialog/scan-barcode-dialog.component';
import { SkipBarcodeDialogComponent } from './dialog/skip-barcode-dialog/skip-barcode-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule, //,
    //AuthApiModule
  ],
  declarations: [
    OrderSummaryComponent,
    OrderCartSummaryComponent,
    ConsultationPriceDirective,
    ConsultationSelectionComponent,
    OrderCartPriceDirective,
    SafePipe,
    ConfirmDialogComponent,
    TranscriptionDiagnosticsDialog,
    RadiologyDialog,
    DashboardSearch,
    GeneralInformationComponent,
    MedicalInformationComponent,
    ConfirmationPageComponent,
    RadiologySearchPipe,
    NewMedicineComponent,
    AddNewPatientComponent,
    NewReasonDialogComponent,
    CreateNewBatchComponent,
    ReasonComponent,
    ViewAllPatientsComponent,
    NewProcedureTypeDialogComponent,
    SelectAddressComponent,
    ConsultationDialogComponent,
    AddPatientInformationComponent,
    SendOtpDialogComponent,
    TranscriberListComponent,
    EditTranscriberComponent,
    ConfirmationAppointmentComponent,
    PendingTranscribingComponent,
    TimeSlotsComponent,
    SelectDoctorProfileComponent,
    OrderReasonComponent,
    PincodeCheckComponent,
    CustomSnackbarComponent,
    LoginDialogComponent,
    MultiplePatientDialogComponent,
    ScanBarcodeDialogComponent,
    SkipBarcodeDialogComponent,
  ],
  exports: [
    OrderSummaryComponent,
    OrderCartSummaryComponent,
    ConsultationPriceDirective,
    OrderCartPriceDirective,
    SafePipe,
    HttpModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    ImageViewerModule,
    MaterialModule,
    DashboardSearch,
    GeneralInformationComponent,
    MedicalInformationComponent,
    AddPatientInformationComponent,
    ConsultationDialogComponent,
    SendOtpDialogComponent,
    ConfirmationPageComponent,
    SelectDoctorProfileComponent,
    TranscriberListComponent,
    EditTranscriberComponent,
    PendingTranscribingComponent,
    TimeSlotsComponent,
    CustomSnackbarComponent,
    LoginDialogComponent,
    MultiplePatientDialogComponent,
    ScanBarcodeDialogComponent,
    SkipBarcodeDialogComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    TranscriptionDiagnosticsDialog,
    RadiologyDialog,
    NewMedicineComponent,
    AddNewPatientComponent,
    NewReasonDialogComponent,
    CreateNewBatchComponent,
    ReasonComponent,
    ViewAllPatientsComponent,
    NewProcedureTypeDialogComponent,
    SelectDoctorProfileComponent,
    ConsultationDialogComponent,
    ConfirmationAppointmentComponent,
    OrderReasonComponent,
    SendOtpDialogComponent,
    SelectAddressComponent,
    ConsultationSelectionComponent,
    EditTranscriberComponent,
    PincodeCheckComponent,
    CustomSnackbarComponent,
    LoginDialogComponent,
    MultiplePatientDialogComponent,
    ScanBarcodeDialogComponent,
    SkipBarcodeDialogComponent,
  ],
})
export class SharedModule {}
