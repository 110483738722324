/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosisTypes } from '../models/resources-diagnosis-types-';
import { ResourcesSymptomTypes } from '../models/resources-symptom-types-';

/**
 * Diagnosis Type Controller
 */
@Injectable()
class DiagnosisTypeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingGETResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingGET(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingGETResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingHEADResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingHEAD(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingHEADResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingPOSTResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingPOST(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingPOSTResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingPUTResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingPUT(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingPUTResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingDELETEResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingDELETE(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingDELETEResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingOPTIONSResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingOPTIONS(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingOPTIONSResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingPATCHResponse(symptomName: string): Observable<HttpResponse<ResourcesDiagnosisTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosistypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisTypes = null;
        _body = _resp.body as ResourcesDiagnosisTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInDiagnosisTypeUsingPATCH(symptomName: string): Observable<ResourcesDiagnosisTypes> {
    return this.findInDiagnosisTypeUsingPATCHResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingGETResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingGET(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingGETResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingHEADResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingHEAD(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingHEADResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param name symptomName
   * @return OK
   */
  addSymptomsUsingPOSTResponse(body): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptomTypes/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }
  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingPOSTResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingPOST(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingPOSTResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param name symptomName
   * @return OK
   */
  addSymptomsUsingPOST(body): Observable<ResourcesSymptomTypes> {
    return this.addSymptomsUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingPUTResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingPUT(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingPUTResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingDELETEResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingDELETE(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingDELETEResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingOPTIONSResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingOPTIONS(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingOPTIONSResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingPATCHResponse(symptomName: string): Observable<HttpResponse<ResourcesSymptomTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptomName != null) __params = __params.set('symptomName', symptomName.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/symptomTypes/symptomsList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomTypes = null;
        _body = _resp.body as ResourcesSymptomTypes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomTypes>;
      })
    );
  }

  /**
   * @param symptomName symptomName
   * @return OK
   */
  findInSymptomsTypeUsingPATCH(symptomName: string): Observable<ResourcesSymptomTypes> {
    return this.findInSymptomsTypeUsingPATCHResponse(symptomName).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosisTypeControllerService {
}

export { DiagnosisTypeControllerService }
