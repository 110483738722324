/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPatientSymptom } from '../models/resources-patient-symptom-';
import { ResourcePatientSymptom } from '../models/resource-patient-symptom-';
import { PatientSymptom } from '../models/patient-symptom';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourcePatient } from '../models/resource-patient-';

/**
 * $ Proxy 221
 */
@Injectable()
class PatientSymptomEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PatientSymptomEntityService.FindAllPatientSymptomUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientSymptomUsingGETResponse(params: PatientSymptomEntityService.FindAllPatientSymptomUsingGETParams): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.FindAllPatientSymptomUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientSymptomUsingGET(params: PatientSymptomEntityService.FindAllPatientSymptomUsingGETParams): Observable<ResourcesPatientSymptom> {
    return this.findAllPatientSymptomUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientSymptomUsingPOSTResponse(body: PatientSymptom): Observable<HttpResponse<ResourcePatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientSymptom = null;
        _body = _resp.body as ResourcePatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientSymptom>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientSymptomUsingPOST(body: PatientSymptom): Observable<ResourcePatientSymptom> {
    return this.savePatientSymptomUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientSymptomUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientSymptom = null;
        _body = _resp.body as ResourcePatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientSymptom>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientSymptomUsingGET(id: number): Observable<ResourcePatientSymptom> {
    return this.findByIdPatientSymptomUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.SavePatientSymptomUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientSymptomUsingPUTResponse(params: PatientSymptomEntityService.SavePatientSymptomUsingPUTParams): Observable<HttpResponse<ResourcePatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientSymptom = null;
        _body = _resp.body as ResourcePatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.SavePatientSymptomUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientSymptomUsingPUT(params: PatientSymptomEntityService.SavePatientSymptomUsingPUTParams): Observable<ResourcePatientSymptom> {
    return this.savePatientSymptomUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePatientSymptomUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePatientSymptomUsingDELETE(id: number): Observable<void> {
    return this.deletePatientSymptomUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.SavePatientSymptomUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientSymptomUsingPATCHResponse(params: PatientSymptomEntityService.SavePatientSymptomUsingPATCHParams): Observable<HttpResponse<ResourcePatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientSymptom = null;
        _body = _resp.body as ResourcePatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.SavePatientSymptomUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientSymptomUsingPATCH(params: PatientSymptomEntityService.SavePatientSymptomUsingPATCHParams): Observable<ResourcePatientSymptom> {
    return this.savePatientSymptomUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientSymptomConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientSymptomConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.patientSymptomConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomConsultationUsingPOSTResponse(params: PatientSymptomEntityService.PatientSymptomConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomConsultationUsingPOST(params: PatientSymptomEntityService.PatientSymptomConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.patientSymptomConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomConsultationUsingPUTResponse(params: PatientSymptomEntityService.PatientSymptomConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomConsultationUsingPUT(params: PatientSymptomEntityService.PatientSymptomConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.patientSymptomConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientSymptomConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientSymptomConsultationUsingDELETE(id: number): Observable<void> {
    return this.patientSymptomConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomConsultationUsingPATCHResponse(params: PatientSymptomEntityService.PatientSymptomConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomConsultationUsingPATCH(params: PatientSymptomEntityService.PatientSymptomConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.patientSymptomConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientSymptomPatientUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientSymptomPatientUsingGET(id: number): Observable<ResourcePatient> {
    return this.patientSymptomPatientUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomPatientUsingPOSTResponse(params: PatientSymptomEntityService.PatientSymptomPatientUsingPOSTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomPatientUsingPOST(params: PatientSymptomEntityService.PatientSymptomPatientUsingPOSTParams): Observable<ResourcePatient> {
    return this.patientSymptomPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomPatientUsingPUTResponse(params: PatientSymptomEntityService.PatientSymptomPatientUsingPUTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomPatientUsingPUT(params: PatientSymptomEntityService.PatientSymptomPatientUsingPUTParams): Observable<ResourcePatient> {
    return this.patientSymptomPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientSymptomPatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientSymptomPatientUsingDELETE(id: number): Observable<void> {
    return this.patientSymptomPatientUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomPatientUsingPATCHResponse(params: PatientSymptomEntityService.PatientSymptomPatientUsingPATCHParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientSymptoms/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientSymptomEntityService.PatientSymptomPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientSymptomPatientUsingPATCH(params: PatientSymptomEntityService.PatientSymptomPatientUsingPATCHParams): Observable<ResourcePatient> {
    return this.patientSymptomPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientSymptomEntityService {

  /**
   * Parameters for findAllPatientSymptomUsingGET
   */
  export interface FindAllPatientSymptomUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for savePatientSymptomUsingPUT
   */
  export interface SavePatientSymptomUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientSymptom;
  }

  /**
   * Parameters for savePatientSymptomUsingPATCH
   */
  export interface SavePatientSymptomUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientSymptom;
  }

  /**
   * Parameters for patientSymptomConsultationUsingPOST
   */
  export interface PatientSymptomConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientSymptomConsultationUsingPUT
   */
  export interface PatientSymptomConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientSymptomConsultationUsingPATCH
   */
  export interface PatientSymptomConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientSymptomPatientUsingPOST
   */
  export interface PatientSymptomPatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientSymptomPatientUsingPUT
   */
  export interface PatientSymptomPatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientSymptomPatientUsingPATCH
   */
  export interface PatientSymptomPatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { PatientSymptomEntityService }
