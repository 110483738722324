/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDoctor } from '../models/resources-doctor-';
import { ResourceDoctor } from '../models/resource-doctor-';
import { Doctor } from '../models/doctor';
import { ResourcesDoctorInClinic } from '../models/resources-doctor-in-clinic-';
import { ResourceDoctorInClinic } from '../models/resource-doctor-in-clinic-';
import { ResourcesHealthProblemTagsForDoctor } from '../models/resources-health-problem-tags-for-doctor-';
import { ResourceHealthProblemTagsForDoctor } from '../models/resource-health-problem-tags-for-doctor-';
import { ResourcesSpeciality } from '../models/resources-speciality-';
import { ResourceSpeciality } from '../models/resource-speciality-';
import { ResourcesDoctorTags } from '../models/resources-doctor-tags-';
import { ResourceDoctorTags } from '../models/resource-doctor-tags-';

/**
 * Simple Elasticsearch Repository
 */
@Injectable()
class DoctorEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoctorEntityService.FindAllDoctorUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorUsingGETResponse(params: DoctorEntityService.FindAllDoctorUsingGETParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.FindAllDoctorUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorUsingGET(params: DoctorEntityService.FindAllDoctorUsingGETParams): Observable<ResourcesDoctor> {
    return this.findAllDoctorUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorUsingPOSTResponse(body: Doctor): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctor-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorUsingPOST(body: Doctor): Observable<ResourceDoctor> {
    return this.saveDoctorUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorUsingGETResponse(id: string): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor-search/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorUsingGET(id: string): Observable<ResourceDoctor> {
    return this.findByIdDoctorUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPUTResponse(params: DoctorEntityService.SaveDoctorUsingPUTParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctor-search/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPUT(params: DoctorEntityService.SaveDoctorUsingPUTParams): Observable<ResourceDoctor> {
    return this.saveDoctorUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDoctorUsingDELETEResponse(id: string): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctor-search/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDoctorUsingDELETE(id: string): Observable<void> {
    return this.deleteDoctorUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPATCHResponse(params: DoctorEntityService.SaveDoctorUsingPATCHParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctor-search/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPATCH(params: DoctorEntityService.SaveDoctorUsingPATCHParams): Observable<ResourceDoctor> {
    return this.saveDoctorUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.FindAllDoctorUsingGET_1Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorUsingGET_1Response(params: DoctorEntityService.FindAllDoctorUsingGET_1Params): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.FindAllDoctorUsingGET_1Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorUsingGET_1(params: DoctorEntityService.FindAllDoctorUsingGET_1Params): Observable<ResourcesDoctor> {
    return this.findAllDoctorUsingGET_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorUsingPOST_1Response(body: Doctor): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorUsingPOST_1(body: Doctor): Observable<ResourceDoctor> {
    return this.saveDoctorUsingPOST_1Response(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorUsingGET_1Response(id: number): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorUsingGET_1(id: number): Observable<ResourceDoctor> {
    return this.findByIdDoctorUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPUT_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPUT_1Response(params: DoctorEntityService.SaveDoctorUsingPUT_1Params): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPUT_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPUT_1(params: DoctorEntityService.SaveDoctorUsingPUT_1Params): Observable<ResourceDoctor> {
    return this.saveDoctorUsingPUT_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDoctorUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDoctorUsingDELETE_1(id: number): Observable<void> {
    return this.deleteDoctorUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPATCH_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPATCH_1Response(params: DoctorEntityService.SaveDoctorUsingPATCH_1Params): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.SaveDoctorUsingPATCH_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorUsingPATCH_1(params: DoctorEntityService.SaveDoctorUsingPATCH_1Params): Observable<ResourceDoctor> {
    return this.saveDoctorUsingPATCH_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorDoctorInClinicsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorDoctorInClinicsUsingGET_1(id: number): Observable<ResourcesDoctorInClinic> {
    return this.doctorDoctorInClinicsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingPOSTResponse(params: DoctorEntityService.DoctorDoctorInClinicsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingPOST(params: DoctorEntityService.DoctorDoctorInClinicsUsingPOSTParams): Observable<ResourcesDoctorInClinic> {
    return this.doctorDoctorInClinicsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingPUTResponse(params: DoctorEntityService.DoctorDoctorInClinicsUsingPUTParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingPUT(params: DoctorEntityService.DoctorDoctorInClinicsUsingPUTParams): Observable<ResourcesDoctorInClinic> {
    return this.doctorDoctorInClinicsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorDoctorInClinicsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorDoctorInClinicsUsingDELETE_1(id: number): Observable<void> {
    return this.doctorDoctorInClinicsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingPATCHResponse(params: DoctorEntityService.DoctorDoctorInClinicsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingPATCH(params: DoctorEntityService.DoctorDoctorInClinicsUsingPATCHParams): Observable<ResourcesDoctorInClinic> {
    return this.doctorDoctorInClinicsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingGETResponse(params: DoctorEntityService.DoctorDoctorInClinicsUsingGETParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/doctorInClinics/${params.doctorinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  doctorDoctorInClinicsUsingGET(params: DoctorEntityService.DoctorDoctorInClinicsUsingGETParams): Observable<ResourceDoctorInClinic> {
    return this.doctorDoctorInClinicsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   */
  doctorDoctorInClinicsUsingDELETEResponse(params: DoctorEntityService.DoctorDoctorInClinicsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/doctorInClinics/${params.doctorinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorDoctorInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   */
  doctorDoctorInClinicsUsingDELETE(params: DoctorEntityService.DoctorDoctorInClinicsUsingDELETEParams): Observable<void> {
    return this.doctorDoctorInClinicsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${id}/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourcesHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingGET_1(id: number): Observable<ResourcesHealthProblemTagsForDoctor> {
    return this.doctorHealthProblemTagsForDoctorUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingPOSTResponse(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPOSTParams): Observable<HttpResponse<ResourcesHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourcesHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingPOST(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPOSTParams): Observable<ResourcesHealthProblemTagsForDoctor> {
    return this.doctorHealthProblemTagsForDoctorUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingPUTResponse(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPUTParams): Observable<HttpResponse<ResourcesHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourcesHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingPUT(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPUTParams): Observable<ResourcesHealthProblemTagsForDoctor> {
    return this.doctorHealthProblemTagsForDoctorUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorHealthProblemTagsForDoctorUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${id}/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorHealthProblemTagsForDoctorUsingDELETE_1(id: number): Observable<void> {
    return this.doctorHealthProblemTagsForDoctorUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingPATCHResponse(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPATCHParams): Observable<HttpResponse<ResourcesHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourcesHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingPATCH(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingPATCHParams): Observable<ResourcesHealthProblemTagsForDoctor> {
    return this.doctorHealthProblemTagsForDoctorUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsfordoctorId`: healthproblemtagsfordoctorId
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingGETResponse(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingGETParams): Observable<HttpResponse<ResourceHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/healthProblemTagsForDoctor/${params.healthproblemtagsfordoctorId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourceHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsfordoctorId`: healthproblemtagsfordoctorId
   *
   * @return OK
   */
  doctorHealthProblemTagsForDoctorUsingGET(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingGETParams): Observable<ResourceHealthProblemTagsForDoctor> {
    return this.doctorHealthProblemTagsForDoctorUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsfordoctorId`: healthproblemtagsfordoctorId
   */
  doctorHealthProblemTagsForDoctorUsingDELETEResponse(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/healthProblemTagsForDoctor/${params.healthproblemtagsfordoctorId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsfordoctorId`: healthproblemtagsfordoctorId
   */
  doctorHealthProblemTagsForDoctorUsingDELETE(params: DoctorEntityService.DoctorHealthProblemTagsForDoctorUsingDELETEParams): Observable<void> {
    return this.doctorHealthProblemTagsForDoctorUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorSpecialityUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorSpecialityUsingGET_1(id: number): Observable<ResourcesSpeciality> {
    return this.doctorSpecialityUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorSpecialityUsingPOSTResponse(params: DoctorEntityService.DoctorSpecialityUsingPOSTParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorSpecialityUsingPOST(params: DoctorEntityService.DoctorSpecialityUsingPOSTParams): Observable<ResourcesSpeciality> {
    return this.doctorSpecialityUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorSpecialityUsingPUTResponse(params: DoctorEntityService.DoctorSpecialityUsingPUTParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorSpecialityUsingPUT(params: DoctorEntityService.DoctorSpecialityUsingPUTParams): Observable<ResourcesSpeciality> {
    return this.doctorSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorSpecialityUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorSpecialityUsingDELETE_1(id: number): Observable<void> {
    return this.doctorSpecialityUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorSpecialityUsingPATCHResponse(params: DoctorEntityService.DoctorSpecialityUsingPATCHParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorSpecialityUsingPATCH(params: DoctorEntityService.DoctorSpecialityUsingPATCHParams): Observable<ResourcesSpeciality> {
    return this.doctorSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingGETParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   *
   * @return OK
   */
  doctorSpecialityUsingGETResponse(params: DoctorEntityService.DoctorSpecialityUsingGETParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/speciality/${params.specialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingGETParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   *
   * @return OK
   */
  doctorSpecialityUsingGET(params: DoctorEntityService.DoctorSpecialityUsingGETParams): Observable<ResourceSpeciality> {
    return this.doctorSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   */
  doctorSpecialityUsingDELETEResponse(params: DoctorEntityService.DoctorSpecialityUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/speciality/${params.specialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   */
  doctorSpecialityUsingDELETE(params: DoctorEntityService.DoctorSpecialityUsingDELETEParams): Observable<void> {
    return this.doctorSpecialityUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagsUsingGET_1(id: number): Observable<ResourcesDoctorTags> {
    return this.doctorTagsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsUsingPOSTResponse(params: DoctorEntityService.DoctorTagsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsUsingPOST(params: DoctorEntityService.DoctorTagsUsingPOSTParams): Observable<ResourcesDoctorTags> {
    return this.doctorTagsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsUsingPUTResponse(params: DoctorEntityService.DoctorTagsUsingPUTParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsUsingPUT(params: DoctorEntityService.DoctorTagsUsingPUTParams): Observable<ResourcesDoctorTags> {
    return this.doctorTagsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorTagsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorTagsUsingDELETE_1(id: number): Observable<void> {
    return this.doctorTagsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsUsingPATCHResponse(params: DoctorEntityService.DoctorTagsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsUsingPATCH(params: DoctorEntityService.DoctorTagsUsingPATCHParams): Observable<ResourcesDoctorTags> {
    return this.doctorTagsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   *
   * @return OK
   */
  doctorTagsUsingGETResponse(params: DoctorEntityService.DoctorTagsUsingGETParams): Observable<HttpResponse<ResourceDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/tags/${params.doctortagsId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTags = null;
        _body = _resp.body as ResourceDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   *
   * @return OK
   */
  doctorTagsUsingGET(params: DoctorEntityService.DoctorTagsUsingGETParams): Observable<ResourceDoctorTags> {
    return this.doctorTagsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   */
  doctorTagsUsingDELETEResponse(params: DoctorEntityService.DoctorTagsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/${params.id}/tags/${params.doctortagsId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorEntityService.DoctorTagsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctortagsId`: doctortagsId
   */
  doctorTagsUsingDELETE(params: DoctorEntityService.DoctorTagsUsingDELETEParams): Observable<void> {
    return this.doctorTagsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorEntityService {

  /**
   * Parameters for findAllDoctorUsingGET
   */
  export interface FindAllDoctorUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDoctorUsingPUT
   */
  export interface SaveDoctorUsingPUTParams {

    /**
     * id
     */
    id: string;

    /**
     * body
     */
    body: Doctor;
  }

  /**
   * Parameters for saveDoctorUsingPATCH
   */
  export interface SaveDoctorUsingPATCHParams {

    /**
     * id
     */
    id: string;

    /**
     * body
     */
    body: Doctor;
  }

  /**
   * Parameters for findAllDoctorUsingGET_1
   */
  export interface FindAllDoctorUsingGET_1Params {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDoctorUsingPUT_1
   */
  export interface SaveDoctorUsingPUT_1Params {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Doctor;
  }

  /**
   * Parameters for saveDoctorUsingPATCH_1
   */
  export interface SaveDoctorUsingPATCH_1Params {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Doctor;
  }

  /**
   * Parameters for doctorDoctorInClinicsUsingPOST
   */
  export interface DoctorDoctorInClinicsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorDoctorInClinicsUsingPUT
   */
  export interface DoctorDoctorInClinicsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorDoctorInClinicsUsingPATCH
   */
  export interface DoctorDoctorInClinicsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorDoctorInClinicsUsingGET
   */
  export interface DoctorDoctorInClinicsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId: string;
  }

  /**
   * Parameters for doctorDoctorInClinicsUsingDELETE
   */
  export interface DoctorDoctorInClinicsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId: string;
  }

  /**
   * Parameters for doctorHealthProblemTagsForDoctorUsingPOST
   */
  export interface DoctorHealthProblemTagsForDoctorUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorHealthProblemTagsForDoctorUsingPUT
   */
  export interface DoctorHealthProblemTagsForDoctorUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorHealthProblemTagsForDoctorUsingPATCH
   */
  export interface DoctorHealthProblemTagsForDoctorUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorHealthProblemTagsForDoctorUsingGET
   */
  export interface DoctorHealthProblemTagsForDoctorUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * healthproblemtagsfordoctorId
     */
    healthproblemtagsfordoctorId: string;
  }

  /**
   * Parameters for doctorHealthProblemTagsForDoctorUsingDELETE
   */
  export interface DoctorHealthProblemTagsForDoctorUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * healthproblemtagsfordoctorId
     */
    healthproblemtagsfordoctorId: string;
  }

  /**
   * Parameters for doctorSpecialityUsingPOST
   */
  export interface DoctorSpecialityUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorSpecialityUsingPUT
   */
  export interface DoctorSpecialityUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorSpecialityUsingPATCH
   */
  export interface DoctorSpecialityUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorSpecialityUsingGET
   */
  export interface DoctorSpecialityUsingGETParams {

    /**
     * specialityId
     */
    specialityId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for doctorSpecialityUsingDELETE
   */
  export interface DoctorSpecialityUsingDELETEParams {

    /**
     * specialityId
     */
    specialityId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for doctorTagsUsingPOST
   */
  export interface DoctorTagsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorTagsUsingPUT
   */
  export interface DoctorTagsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorTagsUsingPATCH
   */
  export interface DoctorTagsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorTagsUsingGET
   */
  export interface DoctorTagsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctortagsId
     */
    doctortagsId: string;
  }

  /**
   * Parameters for doctorTagsUsingDELETE
   */
  export interface DoctorTagsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctortagsId
     */
    doctortagsId: string;
  }
}

export { DoctorEntityService }
