import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Mgo+DSMBaFt/QHRqVVhlXFpHaV5KQmFJfFBmQGldelRycUU3HVdTRHRcQl9iT35Wck1hWHdec3c=;Mgo+DSMBPh8sVXJ0S0J+XE9BdlRBQmJBYVF2R2BJelR0cV9EZUwgOX1dQl9gSXxScEVmXXdccXxXQ2M=;ORg4AjUWIQA/Gnt2VVhkQlFac19JXnxIdkx0RWFab1t6cFBMYllBNQtUQF1hSn5Rd0NjXHtWcnVdQmdf;MTE0MjczNkAzMjMwMmUzNDJlMzBvN3VSUy8wTTUwajJ1aGg3NW43Zm03eE1ieUJ2OU9zVjQ0ZnA4V3M4aHhFPQ==;MTE0MjczN0AzMjMwMmUzNDJlMzBQRzFGTFZIL3h6TmNDMkFpaEJmVngyemxqMmJ1QmZQeUloeTQ3Ui9yK0FBPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpCVmBWf1tpR2NbfE51flFDal5UVAciSV9jS31TdERlWXpaeHZUT2NaUg==;MTE0MjczOUAzMjMwMmUzNDJlMzBTNnJQR3lwSVhsRldpQUo0QWUrcG5sMGQvT2k3NlE3a1VRd204VHhRSjlBPQ==;MTE0Mjc0MEAzMjMwMmUzNDJlMzBNMDlpNkhUcEpaWHEvajZjMG9BK3FnN0pQd2tiM3VsbVlKc0pmbVk4MmE4PQ==;Mgo+DSMBMAY9C3t2VVhkQlFac19JXnxIdkx0RWFab1t6cFBMYllBNQtUQF1hSn5Rd0NjXHtWcnZVRmVf;MTE0Mjc0MkAzMjMwMmUzNDJlMzBnMm5MWjVTeFFxTHNVY3Y3R3kwcm42QmRSNHpKZXJOWEk0ZWJPZXNpM0FJPQ==;MTE0Mjc0M0AzMjMwMmUzNDJlMzBMOHdhMnQ3NElQZmdwMUkxK2orTnE3b1dBM0lkdktnbUcxNVRoZ0hDTHdBPQ==;MTE0Mjc0NEAzMjMwMmUzNDJlMzBTNnJQR3lwSVhsRldpQUo0QWUrcG5sMGQvT2k3NlE3a1VRd204VHhRSjlBPQ==');
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
