/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Patient History Search Controller
 */
@Injectable()
class PatientHistorySearchControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingGETResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingGET(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingGETResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingHEADResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingHEAD(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingHEADResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingPOSTResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingPOST(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingPOSTResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingPUTResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingPUT(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingPUTResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingDELETEResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingDELETE(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingDELETEResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingOPTIONSResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingOPTIONS(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingOPTIONSResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingPATCHResponse(doctorinclinicid: Array<number>): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/assistant/visited/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param doctorinclinicid doctorinclinicid
   * @return OK
   */
  getVisitedPatientForTodayUsingPATCH(doctorinclinicid: Array<number>): Observable<{}> {
    return this.getVisitedPatientForTodayUsingPATCHResponse(doctorinclinicid).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingGETResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = _resp;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingGET(params: PatientHistorySearchControllerService.SearchInVisitedUsingGETParams): Observable<{}> {
    return this.searchInVisitedUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingHEADResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingHEAD(params: PatientHistorySearchControllerService.SearchInVisitedUsingHEADParams): Observable<{}> {
    return this.searchInVisitedUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingPOSTResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingPOST(params: PatientHistorySearchControllerService.SearchInVisitedUsingPOSTParams): Observable<{}> {
    return this.searchInVisitedUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingPUTResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingPUT(params: PatientHistorySearchControllerService.SearchInVisitedUsingPUTParams): Observable<{}> {
    return this.searchInVisitedUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingDELETEResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingDELETE(params: PatientHistorySearchControllerService.SearchInVisitedUsingDELETEParams): Observable<{}> {
    return this.searchInVisitedUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingOPTIONSResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingOPTIONS(params: PatientHistorySearchControllerService.SearchInVisitedUsingOPTIONSParams): Observable<{}> {
    return this.searchInVisitedUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingPATCHResponse(params: PatientHistorySearchControllerService.SearchInVisitedUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/esearch/patient/search/visit/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientHistorySearchControllerService.SearchInVisitedUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchInVisitedUsingPATCH(params: PatientHistorySearchControllerService.SearchInVisitedUsingPATCHParams): Observable<{}> {
    return this.searchInVisitedUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientHistorySearchControllerService {

  /**
   * Parameters for searchInVisitedUsingGET
   */
  export interface SearchInVisitedUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchInVisitedUsingHEAD
   */
  export interface SearchInVisitedUsingHEADParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchInVisitedUsingPOST
   */
  export interface SearchInVisitedUsingPOSTParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchInVisitedUsingPUT
   */
  export interface SearchInVisitedUsingPUTParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchInVisitedUsingDELETE
   */
  export interface SearchInVisitedUsingDELETEParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchInVisitedUsingOPTIONS
   */
  export interface SearchInVisitedUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchInVisitedUsingPATCH
   */
  export interface SearchInVisitedUsingPATCHParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }
}

export { PatientHistorySearchControllerService }
