/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesRole } from '../models/resources-role-';
import { ResourceRole } from '../models/resource-role-';
import { Role } from '../models/role';

/**
 * $ Proxy 155
 */
@Injectable()
class RoleEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RoleEntityService.FindAllRoleUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllRoleUsingGETResponse(params: RoleEntityService.FindAllRoleUsingGETParams): Observable<HttpResponse<ResourcesRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRole = null;
        _body = _resp.body as ResourcesRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRole>;
      })
    );
  }

  /**
   * @param params The `RoleEntityService.FindAllRoleUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllRoleUsingGET(params: RoleEntityService.FindAllRoleUsingGETParams): Observable<ResourcesRole> {
    return this.findAllRoleUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveRoleUsingPOSTResponse(body: Role): Observable<HttpResponse<ResourceRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rest/api/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRole = null;
        _body = _resp.body as ResourceRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRole>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveRoleUsingPOST(body: Role): Observable<ResourceRole> {
    return this.saveRoleUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdRoleUsingGETResponse(id: number): Observable<HttpResponse<ResourceRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRole = null;
        _body = _resp.body as ResourceRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRole>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdRoleUsingGET(id: number): Observable<ResourceRole> {
    return this.findByIdRoleUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RoleEntityService.SaveRoleUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRoleUsingPUTResponse(params: RoleEntityService.SaveRoleUsingPUTParams): Observable<HttpResponse<ResourceRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rest/api/roles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRole = null;
        _body = _resp.body as ResourceRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRole>;
      })
    );
  }

  /**
   * @param params The `RoleEntityService.SaveRoleUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRoleUsingPUT(params: RoleEntityService.SaveRoleUsingPUTParams): Observable<ResourceRole> {
    return this.saveRoleUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteRoleUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rest/api/roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteRoleUsingDELETE(id: number): Observable<void> {
    return this.deleteRoleUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RoleEntityService.SaveRoleUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRoleUsingPATCHResponse(params: RoleEntityService.SaveRoleUsingPATCHParams): Observable<HttpResponse<ResourceRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/rest/api/roles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRole = null;
        _body = _resp.body as ResourceRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRole>;
      })
    );
  }

  /**
   * @param params The `RoleEntityService.SaveRoleUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRoleUsingPATCH(params: RoleEntityService.SaveRoleUsingPATCHParams): Observable<ResourceRole> {
    return this.saveRoleUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module RoleEntityService {

  /**
   * Parameters for findAllRoleUsingGET
   */
  export interface FindAllRoleUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveRoleUsingPUT
   */
  export interface SaveRoleUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Role;
  }

  /**
   * Parameters for saveRoleUsingPATCH
   */
  export interface SaveRoleUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Role;
  }
}

export { RoleEntityService }
