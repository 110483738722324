import { Directive, Input, TemplateRef, ViewContainerRef, DoCheck } from '@angular/core';
import { OrderCartPriceContext } from './order-cart-price.context';

@Directive({
  selector: '[appOrderCartPrice]'
})
export class OrderCartPriceDirective implements DoCheck {
  option;
  consultation;
  order;
  private hasView = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() set appOrderCartPrice(order: any) {
      this.order = order;
      if (this.order) {
        this.viewContainer.remove(0);
        let view = this.viewContainer.createEmbeddedView(this.templateRef, new OrderCartPriceContext(this.order));
        this.hasView = true;
      }
  }

  ngDoCheck(): void {

      if (this.order) {
        this.viewContainer.remove(0);
        let view = this.viewContainer.createEmbeddedView(this.templateRef, new OrderCartPriceContext(this.order));
        this.hasView = true;
      }
  }
}
