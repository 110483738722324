/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesVisitType } from '../models/resources-visit-type-';
import { ResourceVisitType } from '../models/resource-visit-type-';
import { VisitType } from '../models/visit-type';

/**
 * $ Proxy 201
 */
@Injectable()
class VisitTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VisitTypeEntityService.FindAllVisitTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllVisitTypeUsingGETResponse(params: VisitTypeEntityService.FindAllVisitTypeUsingGETParams): Observable<HttpResponse<ResourcesVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/visitTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVisitType = null;
        _body = _resp.body as ResourcesVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVisitType>;
      })
    );
  }

  /**
   * @param params The `VisitTypeEntityService.FindAllVisitTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllVisitTypeUsingGET(params: VisitTypeEntityService.FindAllVisitTypeUsingGETParams): Observable<ResourcesVisitType> {
    return this.findAllVisitTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveVisitTypeUsingPOSTResponse(body: VisitType): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/visitTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveVisitTypeUsingPOST(body: VisitType): Observable<ResourceVisitType> {
    return this.saveVisitTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdVisitTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/visitTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdVisitTypeUsingGET(id: number): Observable<ResourceVisitType> {
    return this.findByIdVisitTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VisitTypeEntityService.SaveVisitTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVisitTypeUsingPUTResponse(params: VisitTypeEntityService.SaveVisitTypeUsingPUTParams): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/visitTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param params The `VisitTypeEntityService.SaveVisitTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVisitTypeUsingPUT(params: VisitTypeEntityService.SaveVisitTypeUsingPUTParams): Observable<ResourceVisitType> {
    return this.saveVisitTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteVisitTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/visitTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteVisitTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteVisitTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VisitTypeEntityService.SaveVisitTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVisitTypeUsingPATCHResponse(params: VisitTypeEntityService.SaveVisitTypeUsingPATCHParams): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/visitTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param params The `VisitTypeEntityService.SaveVisitTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVisitTypeUsingPATCH(params: VisitTypeEntityService.SaveVisitTypeUsingPATCHParams): Observable<ResourceVisitType> {
    return this.saveVisitTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module VisitTypeEntityService {

  /**
   * Parameters for findAllVisitTypeUsingGET
   */
  export interface FindAllVisitTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveVisitTypeUsingPUT
   */
  export interface SaveVisitTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: VisitType;
  }

  /**
   * Parameters for saveVisitTypeUsingPATCH
   */
  export interface SaveVisitTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: VisitType;
  }
}

export { VisitTypeEntityService }
