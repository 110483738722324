import {Component, OnInit, Inject, Injectable} from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material";
import {AuthenticationService} from '../../login/service/authentication.service';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {
  Router,
} from "@angular/router";
import {AuthService} from 'angularx-social-login';

@Component({
  selector: "app-custom-snackbar",
  templateUrl: "./custom-snackbar.component.html",
  styleUrls: ["./custom-snackbar.component.scss"],
})
export class CustomSnackbarComponent implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<CustomSnackbarComponent>,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private router: Router,
    private localStorage: LocalStorage,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  clearBar(): void {
    this.bottomSheetRef.dismiss({
      message: "login again",
      data: this.data,
    });
  }

  logout() {
  this.localStorage.getItem('token').subscribe((token) => {
    if (token && token.authorities.includes('ASSISTANT')) {
      token.loginTime = null;
      this.localStorage.setItem("token", token).subscribe({
        complete: () => this.bottomSheetRef.dismiss({
          message: "logout",
          data: this.data,
        }),
    });
      this.authenticationService.posLogout(token.userID, token.clinicId).subscribe((data) => {
        if (data) {
          this.authService.signOut(true);
          this.localStorage.clear().subscribe(() => {
            window.open(
              "https://accounts.google.com/logout", "_blank");
            this.router.navigateByUrl("/login");
          });
        }
      });
    } else {
      this.bottomSheetRef.dismiss({
        message: "logout",
        data: this.data,
      });
      this.localStorage.clear().subscribe(() => {
        window.open(
          "https://accounts.google.com/logout", "_blank");
        this.router.navigateByUrl("/login");
      });
    }
  });

  }
  ngOnInit() {
    if (this.data.time < 1) {
      this.logout();
    }
  }
}
