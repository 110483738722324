/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDoctorTags } from '../models/resources-doctor-tags-';
import { ResourceDoctorTags } from '../models/resource-doctor-tags-';
import { DoctorTags } from '../models/doctor-tags';
import { ResourceDoctor } from '../models/resource-doctor-';
import { ResourceDoctorTagType } from '../models/resource-doctor-tag-type-';

/**
 * $ Proxy 179
 */
@Injectable()
class DoctorTagsEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoctorTagsEntityService.FindAllDoctorTagsUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorTagsUsingGETResponse(params: DoctorTagsEntityService.FindAllDoctorTagsUsingGETParams): Observable<HttpResponse<ResourcesDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorTags = null;
        _body = _resp.body as ResourcesDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.FindAllDoctorTagsUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorTagsUsingGET(params: DoctorTagsEntityService.FindAllDoctorTagsUsingGETParams): Observable<ResourcesDoctorTags> {
    return this.findAllDoctorTagsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorTagsUsingPOSTResponse(body: DoctorTags): Observable<HttpResponse<ResourceDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorTags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTags = null;
        _body = _resp.body as ResourceDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTags>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorTagsUsingPOST(body: DoctorTags): Observable<ResourceDoctorTags> {
    return this.saveDoctorTagsUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorTagsUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTags/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTags = null;
        _body = _resp.body as ResourceDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTags>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorTagsUsingGET(id: number): Observable<ResourceDoctorTags> {
    return this.findByIdDoctorTagsUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.SaveDoctorTagsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagsUsingPUTResponse(params: DoctorTagsEntityService.SaveDoctorTagsUsingPUTParams): Observable<HttpResponse<ResourceDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTags = null;
        _body = _resp.body as ResourceDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.SaveDoctorTagsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagsUsingPUT(params: DoctorTagsEntityService.SaveDoctorTagsUsingPUTParams): Observable<ResourceDoctorTags> {
    return this.saveDoctorTagsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDoctorTagsUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorTags/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDoctorTagsUsingDELETE(id: number): Observable<void> {
    return this.deleteDoctorTagsUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.SaveDoctorTagsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagsUsingPATCHResponse(params: DoctorTagsEntityService.SaveDoctorTagsUsingPATCHParams): Observable<HttpResponse<ResourceDoctorTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTags = null;
        _body = _resp.body as ResourceDoctorTags;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTags>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.SaveDoctorTagsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorTagsUsingPATCH(params: DoctorTagsEntityService.SaveDoctorTagsUsingPATCHParams): Observable<ResourceDoctorTags> {
    return this.saveDoctorTagsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagsDoctorUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTags/${id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagsDoctorUsingGET(id: number): Observable<ResourceDoctor> {
    return this.doctorTagsDoctorUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsDoctorUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsDoctorUsingPOSTResponse(params: DoctorTagsEntityService.DoctorTagsDoctorUsingPOSTParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsDoctorUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsDoctorUsingPOST(params: DoctorTagsEntityService.DoctorTagsDoctorUsingPOSTParams): Observable<ResourceDoctor> {
    return this.doctorTagsDoctorUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsDoctorUsingPUTResponse(params: DoctorTagsEntityService.DoctorTagsDoctorUsingPUTParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsDoctorUsingPUT(params: DoctorTagsEntityService.DoctorTagsDoctorUsingPUTParams): Observable<ResourceDoctor> {
    return this.doctorTagsDoctorUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorTagsDoctorUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorTags/${id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorTagsDoctorUsingDELETE(id: number): Observable<void> {
    return this.doctorTagsDoctorUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsDoctorUsingPATCHResponse(params: DoctorTagsEntityService.DoctorTagsDoctorUsingPATCHParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsDoctorUsingPATCH(params: DoctorTagsEntityService.DoctorTagsDoctorUsingPATCHParams): Observable<ResourceDoctor> {
    return this.doctorTagsDoctorUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagsTagTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorTags/${id}/tagType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorTagsTagTypeUsingGET(id: number): Observable<ResourceDoctorTagType> {
    return this.doctorTagsTagTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsTagTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsTagTypeUsingPOSTResponse(params: DoctorTagsEntityService.DoctorTagsTagTypeUsingPOSTParams): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}/tagType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsTagTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsTagTypeUsingPOST(params: DoctorTagsEntityService.DoctorTagsTagTypeUsingPOSTParams): Observable<ResourceDoctorTagType> {
    return this.doctorTagsTagTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsTagTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsTagTypeUsingPUTResponse(params: DoctorTagsEntityService.DoctorTagsTagTypeUsingPUTParams): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}/tagType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsTagTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsTagTypeUsingPUT(params: DoctorTagsEntityService.DoctorTagsTagTypeUsingPUTParams): Observable<ResourceDoctorTagType> {
    return this.doctorTagsTagTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorTagsTagTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorTags/${id}/tagType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorTagsTagTypeUsingDELETE(id: number): Observable<void> {
    return this.doctorTagsTagTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsTagTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsTagTypeUsingPATCHResponse(params: DoctorTagsEntityService.DoctorTagsTagTypeUsingPATCHParams): Observable<HttpResponse<ResourceDoctorTagType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorTags/${params.id}/tagType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorTagType = null;
        _body = _resp.body as ResourceDoctorTagType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorTagType>;
      })
    );
  }

  /**
   * @param params The `DoctorTagsEntityService.DoctorTagsTagTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorTagsTagTypeUsingPATCH(params: DoctorTagsEntityService.DoctorTagsTagTypeUsingPATCHParams): Observable<ResourceDoctorTagType> {
    return this.doctorTagsTagTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorTagsEntityService {

  /**
   * Parameters for findAllDoctorTagsUsingGET
   */
  export interface FindAllDoctorTagsUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDoctorTagsUsingPUT
   */
  export interface SaveDoctorTagsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DoctorTags;
  }

  /**
   * Parameters for saveDoctorTagsUsingPATCH
   */
  export interface SaveDoctorTagsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DoctorTags;
  }

  /**
   * Parameters for doctorTagsDoctorUsingPOST
   */
  export interface DoctorTagsDoctorUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorTagsDoctorUsingPUT
   */
  export interface DoctorTagsDoctorUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorTagsDoctorUsingPATCH
   */
  export interface DoctorTagsDoctorUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorTagsTagTypeUsingPOST
   */
  export interface DoctorTagsTagTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorTagsTagTypeUsingPUT
   */
  export interface DoctorTagsTagTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorTagsTagTypeUsingPATCH
   */
  export interface DoctorTagsTagTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { DoctorTagsEntityService }
