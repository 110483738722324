/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PatientHistory } from '../models/patient-history';

/**
 * Elasticsearch Controller
 */
@Injectable()
class ElasticsearchControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param patientHistory patientHistory
   * @return OK
   */
  loadDataInElasticUsingPOSTResponse(patientHistory: PatientHistory): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patientHistory;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param patientHistory patientHistory
   * @return OK
   */
  loadDataInElasticUsingPOST(patientHistory: PatientHistory): Observable<string> {
    return this.loadDataInElasticUsingPOSTResponse(patientHistory).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingGETResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingGET(params: ElasticsearchControllerService.AddAllPatientHistoryUsingGETParams): Observable<{}> {
    return this.addAllPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingHEADParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingHEADResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingHEADParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingHEAD(params: ElasticsearchControllerService.AddAllPatientHistoryUsingHEADParams): Observable<{}> {
    return this.addAllPatientHistoryUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingPOSTResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingPOST(params: ElasticsearchControllerService.AddAllPatientHistoryUsingPOSTParams): Observable<{}> {
    return this.addAllPatientHistoryUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingPUTResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingPUT(params: ElasticsearchControllerService.AddAllPatientHistoryUsingPUTParams): Observable<{}> {
    return this.addAllPatientHistoryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingDELETEParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingDELETEResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingDELETEParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingDELETE(params: ElasticsearchControllerService.AddAllPatientHistoryUsingDELETEParams): Observable<{}> {
    return this.addAllPatientHistoryUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingOPTIONSParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingOPTIONSResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingOPTIONSParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingOPTIONS(params: ElasticsearchControllerService.AddAllPatientHistoryUsingOPTIONSParams): Observable<{}> {
    return this.addAllPatientHistoryUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingPATCHResponse(params: ElasticsearchControllerService.AddAllPatientHistoryUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/history/es-populator/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `ElasticsearchControllerService.AddAllPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  addAllPatientHistoryUsingPATCH(params: ElasticsearchControllerService.AddAllPatientHistoryUsingPATCHParams): Observable<{}> {
    return this.addAllPatientHistoryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ElasticsearchControllerService {

  /**
   * Parameters for addAllPatientHistoryUsingGET
   */
  export interface AddAllPatientHistoryUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for addAllPatientHistoryUsingHEAD
   */
  export interface AddAllPatientHistoryUsingHEADParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for addAllPatientHistoryUsingPOST
   */
  export interface AddAllPatientHistoryUsingPOSTParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for addAllPatientHistoryUsingPUT
   */
  export interface AddAllPatientHistoryUsingPUTParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for addAllPatientHistoryUsingDELETE
   */
  export interface AddAllPatientHistoryUsingDELETEParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for addAllPatientHistoryUsingOPTIONS
   */
  export interface AddAllPatientHistoryUsingOPTIONSParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for addAllPatientHistoryUsingPATCH
   */
  export interface AddAllPatientHistoryUsingPATCHParams {
    sort?: string;
    size?: number;
    page?: number;
  }
}

export { ElasticsearchControllerService }
