import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { environment } from "src/environments/environment";
import {
  Headers,
  Http,
  RequestOptionsArgs,
  URLSearchParams,
} from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/mergeMap";
import { HttpResponse } from "@angular/common/http";

@Injectable()
export class AuthenticationService {
  stagingEnvironment: boolean = true;
  sailsUrl = environment.webAPI;

  private jwtHelperService: JwtHelperService = new JwtHelperService();
  constructor(
    protected localStorage: LocalStorage,
    private HttpClient: HttpClient,
    private http: Http
  ) {}

  public validate(): Observable<boolean> {
    return Observable.create((obs) => {
      const apiOptions = {
        headers: new Headers(),
      } as RequestOptionsArgs;

      let productTypeUrl;

      productTypeUrl = environment.orderBaseAPI + "/api/v1/order/productTypes";
      this.localStorage.getItem("token").subscribe((token) => {
        if (!token) {
          obs.next(false);
          obs.complete();
        } else {
          apiOptions.headers.append(
            "Authorization",
            "Bearer " + token.access_token
          );
          this.http.get(productTypeUrl, apiOptions).subscribe(
            (response) => {
              obs.next(true);
              obs.complete();
            },
            (error) => {
              obs.next(false);
              obs.complete();
            }
          );
        }
      });
    });
  }

  public validateRole(role): Observable<boolean> {
    return Observable.create((obs) => {
      this.localStorage.getItem("token").subscribe((token) => {
        if (token && token.authorities.includes(role)) {
          obs.next(true);
          obs.complete();
        } else {
          obs.next(false);
          obs.complete();
        }
      });
    });
  }

  public login(username, password): Observable<Boolean> {
    const that = this;
    const parameters = new URLSearchParams();
    parameters.set("grant_type", "password");
    parameters.set("username", username);
    parameters.set("password", password);

    const options = {
      headers: new Headers(),
    } as RequestOptionsArgs;

    let AuthorizationToken;
    let authUrl;
    AuthorizationToken = environment.otpToken;
    authUrl = environment.authBaseAPI + "oauth/token";

    options.headers.append("Content-Type", "application/x-www-form-urlencoded");
    options.headers.append("Authorization", AuthorizationToken);

    return this.http
      .post(authUrl, parameters.toString(), options)
      .flatMap((response: any) => {
        let token = response.json();
        token.loginTime = new Date();
        return that.localStorage.setItem("token", token);
      });
  }

  public verifyUser(authCode, userId) {
    const that = this;
    const parameters = new URLSearchParams();

    const options = {
      headers: new HttpHeaders(),
    };

    let AuthorizationToken;
    let authUrl;
    AuthorizationToken = environment.otpToken;
    authUrl = environment.authBaseAPI + "api/v1/auth/pos/check-in";
    let body = {
      authCode: authCode,
      userId: userId,
    };
    options.headers.append("Content-Type", "application/x-www-form-urlencoded");
    options.headers.append("Authorization", AuthorizationToken);

    return this.HttpClient.post(authUrl, body, options);
  }


  public posLogout(userId, clinicId) {
    const options = {
      headers: new HttpHeaders(),
    };

    let AuthorizationToken;
    let authUrl;
    AuthorizationToken = environment.otpToken;
    authUrl = environment.authBaseAPI + "api/v1/auth/pos/check-out";
    const body = {
      userId: userId,
      clinicId: clinicId[0]
    };
    options.headers.append("Content-Type", "application/json");
    options.headers.append("Authorization", AuthorizationToken);
    return this.HttpClient.post(authUrl, body, options);
  }

  public verifyUserV1(googleToken, userId, clinicId) {
    const parameters = new URLSearchParams();
    parameters.set('grant_type', 'password');
    if (googleToken)  {parameters.set('googleAuth', googleToken.idToken); }
    if (userId)  {parameters.set('userId', userId); }
    if (clinicId)  {parameters.set('clinicId', clinicId[0]); }
    let AuthorizationToken;
    let authUrl;
    AuthorizationToken = environment.otpToken;
    authUrl = environment.authBaseAPI + 'api/v1/auth/google/oauth/token/v1';

    const options: any = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': AuthorizationToken,
        'isAuthorized': 'no'
      }
    };

    return this.HttpClient.post(authUrl, parameters.toString(), options);
  }

  public settlementInitiate(payLoad) {
    const options: any = {
      headers: {},
    };

    let authorizationToken = environment.settlementToken;
    let url = environment.billingAPI;

    options.headers["Authorization"] = authorizationToken;

    return this.http.post(url + "/api/settlement/initiate", payLoad, options);
  }

  public settlementValidate(dicId, date) {
    const options: any = {
      headers: {},
      params: {},
    };

    let authorizationToken = environment.settlementToken;
    let url = environment.billingAPI;

    options.headers["Authorization"] = authorizationToken;
    options.params["date"] = date;

    return this.http.get(url + `/api/settlement/validate/${dicId}`, options);
  }

  public fetchClinicId(token) {
    const options: any = {
      headers: {},
      params: {},
    };

    let authorizationToken = 'Bearer ' + token.access_token;
    let url = environment.emrBaseAPI;

    options.headers["Authorization"] = authorizationToken;
    options.params["dicIds"] = token.doctorInClinicIds;
    return this.http.get(url + `/api/v1/emr/fetch-clinic-from-dic-list`, options);
  }

  public sendOtpToDoctor(dicId, date, amount) {
    const options: any = {
      headers: {},
      params: {},
    };

    let authorizationToken = environment.settlementToken;
    let url = environment.billingAPI;

    options.headers["Content-Type"] = "application/x-www-form-urlencoded";
    options.headers["Authorization"] = authorizationToken;
    options.params["date"] = date;
    options.params["amount"] = amount;

    return this.http.get(url + `/api/settlement/otp/send/${dicId}`, options);
  }

  public leaderboardAndClinicData(token, param, questionId) {
    const options: any = {
      headers: {},
      params: {
        questionId,
      },
    };

    options.headers["Authorization"] = `Bearer ${token.access_token}`;
    let url = environment.metabaseAPI;

    options.headers["Content-Type"] = "application/x-www-form-urlencoded";

    return this.http.get(
      url +
        `/api/query?parameters=${encodeURIComponent(JSON.stringify(param))}`,
      options
    );
  }

  public getSalesTargetOrIncentiveData(token, param, questionId) {
    const options: any = {
      headers: {},
      params: {
        questionId,
      },
    };

    options.headers["Authorization"] = `Bearer ${token.access_token}`;
    let url = environment.metabaseAPI;

    options.headers["Content-Type"] = "application/x-www-form-urlencoded";

    return this.http.get(
      url +
        `/api/query?parameters=${encodeURIComponent(JSON.stringify(param))}`,
      options
    );
  }
}
