/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderAndPayment } from '../models/order-and-payment';

/**
 * Order And Payment Controller
 */
@Injectable()
class OrderAndPaymentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingGETParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingGETResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingGETParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingGET(params: OrderAndPaymentControllerService.GetPaymentsUsingGETParams): Observable<{}> {
    return this.getPaymentsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingHEADParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingHEADResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingHEADParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingHEAD(params: OrderAndPaymentControllerService.GetPaymentsUsingHEADParams): Observable<{}> {
    return this.getPaymentsUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingPOSTParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingPOSTResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingPOSTParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingPOST(params: OrderAndPaymentControllerService.GetPaymentsUsingPOSTParams): Observable<{}> {
    return this.getPaymentsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingPUTParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingPUTResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingPUTParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingPUT(params: OrderAndPaymentControllerService.GetPaymentsUsingPUTParams): Observable<{}> {
    return this.getPaymentsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingDELETEParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingDELETEResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingDELETEParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingDELETE(params: OrderAndPaymentControllerService.GetPaymentsUsingDELETEParams): Observable<{}> {
    return this.getPaymentsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingOPTIONSParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingOPTIONSResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingOPTIONSParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingOPTIONS(params: OrderAndPaymentControllerService.GetPaymentsUsingOPTIONSParams): Observable<{}> {
    return this.getPaymentsUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingPATCHParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingPATCHResponse(params: OrderAndPaymentControllerService.GetPaymentsUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/consultation-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetPaymentsUsingPATCHParams` containing the following parameters:
   *
   * - `productTypeId`: productTypeId
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getPaymentsUsingPATCH(params: OrderAndPaymentControllerService.GetPaymentsUsingPATCHParams): Observable<{}> {
    return this.getPaymentsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingGETParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingGETResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingGETParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingGETParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingGET(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingGETParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingHEADParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingHEADResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingHEADParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingHEADParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingHEAD(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingHEADParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingPOSTParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingPOSTResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingPOSTParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingPOSTParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingPOST(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingPOSTParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingPUTParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingPUTResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingPUTParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingPUTParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingPUT(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingPUTParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingDELETEParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingDELETEResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingDELETEParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingDELETEParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingDELETE(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingDELETEParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingOPTIONSParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingOPTIONSResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingOPTIONSParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingOPTIONSParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingOPTIONS(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingOPTIONSParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingPATCHParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingPATCHResponse(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingPATCHParams): Observable<HttpResponse<Array<OrderAndPayment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/daily-settlement-amount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderAndPayment> = null;
        _body = _resp.body as Array<OrderAndPayment>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderAndPayment>>;
      })
    );
  }

  /**
   * @param params The `OrderAndPaymentControllerService.GetDailySettlementAmountUsingPATCHParams` containing the following parameters:
   *
   * - `dic`: dic
   *
   * - `date`: date
   *
   * @return OK
   */
  getDailySettlementAmountUsingPATCH(params: OrderAndPaymentControllerService.GetDailySettlementAmountUsingPATCHParams): Observable<Array<OrderAndPayment>> {
    return this.getDailySettlementAmountUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderAndPaymentControllerService {

  /**
   * Parameters for getPaymentsUsingGET
   */
  export interface GetPaymentsUsingGETParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getPaymentsUsingHEAD
   */
  export interface GetPaymentsUsingHEADParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getPaymentsUsingPOST
   */
  export interface GetPaymentsUsingPOSTParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getPaymentsUsingPUT
   */
  export interface GetPaymentsUsingPUTParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getPaymentsUsingDELETE
   */
  export interface GetPaymentsUsingDELETEParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getPaymentsUsingOPTIONS
   */
  export interface GetPaymentsUsingOPTIONSParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getPaymentsUsingPATCH
   */
  export interface GetPaymentsUsingPATCHParams {

    /**
     * productTypeId
     */
    productTypeId: number;

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingGET
   */
  export interface GetDailySettlementAmountUsingGETParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingHEAD
   */
  export interface GetDailySettlementAmountUsingHEADParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingPOST
   */
  export interface GetDailySettlementAmountUsingPOSTParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingPUT
   */
  export interface GetDailySettlementAmountUsingPUTParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingDELETE
   */
  export interface GetDailySettlementAmountUsingDELETEParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingOPTIONS
   */
  export interface GetDailySettlementAmountUsingOPTIONSParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for getDailySettlementAmountUsingPATCH
   */
  export interface GetDailySettlementAmountUsingPATCHParams {

    /**
     * dic
     */
    dic: number;

    /**
     * date
     */
    date?: string;
  }
}

export { OrderAndPaymentControllerService }
