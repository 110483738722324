/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsolidateHomeApis } from '../models/consolidate-home-apis';

/**
 * Patient App Consolidate Controller
 */
@Injectable()
class PatientAppConsolidateControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PatientAppConsolidateControllerService.GetClubHomePageConsolidatedApiCallUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `currentDate`: currentDate
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getClubHomePageConsolidatedApiCallUsingGETResponse(params: PatientAppConsolidateControllerService.GetClubHomePageConsolidatedApiCallUsingGETParams): Observable<HttpResponse<ConsolidateHomeApis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.currentDate != null) __params = __params.set('currentDate', params.currentDate.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/homepage/patient/app`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ConsolidateHomeApis = null;
        _body = _resp.body as ConsolidateHomeApis;
        return _resp.clone({body: _body}) as HttpResponse<ConsolidateHomeApis>;
      })
    );
  }

  /**
   * @param params The `PatientAppConsolidateControllerService.GetClubHomePageConsolidatedApiCallUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `currentDate`: currentDate
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getClubHomePageConsolidatedApiCallUsingGET(params: PatientAppConsolidateControllerService.GetClubHomePageConsolidatedApiCallUsingGETParams): Observable<ConsolidateHomeApis> {
    return this.getClubHomePageConsolidatedApiCallUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppConsolidateControllerService.ClubMyCareApiUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  clubMyCareApiUsingGETResponse(params: PatientAppConsolidateControllerService.ClubMyCareApiUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/mycarepage/patient/app`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppConsolidateControllerService.ClubMyCareApiUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  clubMyCareApiUsingGET(params: PatientAppConsolidateControllerService.ClubMyCareApiUsingGETParams): Observable<{}> {
    return this.clubMyCareApiUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientAppConsolidateControllerService {

  /**
   * Parameters for getClubHomePageConsolidatedApiCallUsingGET
   */
  export interface GetClubHomePageConsolidatedApiCallUsingGETParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;

    /**
     * currentDate
     */
    currentDate?: string;

    /**
     * basedOn
     */
    basedOn?: string;
  }

  /**
   * Parameters for clubMyCareApiUsingGET
   */
  export interface ClubMyCareApiUsingGETParams {

    /**
     * patientId
     */
    patientId: number;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;

    /**
     * basedOn
     */
    basedOn?: string;
  }
}

export { PatientAppConsolidateControllerService }
