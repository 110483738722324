import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PatientEntityService } from 'src/app/api/emr/services';

@Component({
  selector: 'app-view-all-patients',
  templateUrl: './view-all-patients.component.html',
  styleUrls: ['./view-all-patients.component.scss']
})
export class ViewAllPatientsComponent implements OnInit {

  allPatients = [];
  pageNumber = 0;
  pageInfo;
  searchValue;
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private MatDialogRef: MatDialogRef<ViewAllPatientsComponent>,
    private PatientEntityService: PatientEntityService
  ) {
    this.searchValue = data.searchValue;
  }

  changePage(option: 'inc' | 'dec') {
    if (option === 'dec') {
      if ((this.pageNumber - 1) === this.pageInfo.number) {
        this.pageNumber = this.pageInfo.totalPages;
      } else {
        this.pageNumber--;
      }
    } else if (option === 'inc') {
      if ((this.pageNumber + 1) === this.pageInfo.totalPages) {
        this.pageNumber = this.pageInfo.number;
      } else {
        this.pageNumber++;
      }
    }
    this.loadAllPatients();
  }

  loadAllPatients() {
    this.loading = true;
    this.PatientEntityService.findDistinctByNameContainingOrPhoneContainingPatientUsingGET({
      phone: this.searchValue,
      name: this.searchValue,
      size: 100,
      page: this.pageNumber
    }).subscribe(
      (res: any) => {
        if (res._embedded && res._embedded.patients) {
          this.allPatients = res._embedded.patients;
          this.pageInfo = res.page;
          this.pageNumber = this.pageInfo.number;
        } else {
          this.pageInfo = res.page;
          this.pageNumber = this.pageInfo.number;
          this.allPatients = [];
        }
        this.loading = false;
      },
      err => {
        alert('Error while searching')
        this.allPatients = [];
      }
    )
  }

  selectPatient(patient) {
    this.MatDialogRef.close(patient)
  }

  ngOnInit() {
    this.loadAllPatients();
  }

}
