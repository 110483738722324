import { Injectable, ErrorHandler} from '@angular/core'
import * as Sentry from '@sentry/browser'
import { environment } from "../../environments/environment"
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {

  constructor(
    private localStorage: LocalStorage
  ) {
    this.localStorage.getItem('token').subscribe(token => {
      Sentry.setExtra('userId',token.userID || null);
      Sentry.setExtra('email',token.email || null);
    });
    // Sentry.init({
    //   dsn: environment.sentaryDsn
    // });
  }

  handleError(error,tag?) {
    const connection = navigator['connection'] || {};
    if(tag) {
      Sentry.setTag('type',tag);
    } else {
      Sentry.setTag('type','code');
    }
    Sentry.setExtra('NetworkType',connection.effectiveType || null);
    Sentry.captureException(error.originalError || error);
  }
}