/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesTestParameterMaster } from '../models/resources-test-parameter-master-';
import { ResourceTestParameterMaster } from '../models/resource-test-parameter-master-';
import { TestParameterMaster } from '../models/test-parameter-master';

/**
 * $ Proxy 203
 */
@Injectable()
class TestParameterMasterEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TestParameterMasterEntityService.FindAllTestParameterMasterUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTestParameterMasterUsingGETResponse(params: TestParameterMasterEntityService.FindAllTestParameterMasterUsingGETParams): Observable<HttpResponse<ResourcesTestParameterMaster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/testParameterMaster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTestParameterMaster = null;
        _body = _resp.body as ResourcesTestParameterMaster;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTestParameterMaster>;
      })
    );
  }

  /**
   * @param params The `TestParameterMasterEntityService.FindAllTestParameterMasterUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTestParameterMasterUsingGET(params: TestParameterMasterEntityService.FindAllTestParameterMasterUsingGETParams): Observable<ResourcesTestParameterMaster> {
    return this.findAllTestParameterMasterUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTestParameterMasterUsingPOSTResponse(body: TestParameterMaster): Observable<HttpResponse<ResourceTestParameterMaster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/testParameterMaster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTestParameterMaster = null;
        _body = _resp.body as ResourceTestParameterMaster;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTestParameterMaster>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTestParameterMasterUsingPOST(body: TestParameterMaster): Observable<ResourceTestParameterMaster> {
    return this.saveTestParameterMasterUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTestParameterMasterUsingGETResponse(id: number): Observable<HttpResponse<ResourceTestParameterMaster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/testParameterMaster/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTestParameterMaster = null;
        _body = _resp.body as ResourceTestParameterMaster;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTestParameterMaster>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTestParameterMasterUsingGET(id: number): Observable<ResourceTestParameterMaster> {
    return this.findByIdTestParameterMasterUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TestParameterMasterEntityService.SaveTestParameterMasterUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTestParameterMasterUsingPUTResponse(params: TestParameterMasterEntityService.SaveTestParameterMasterUsingPUTParams): Observable<HttpResponse<ResourceTestParameterMaster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/testParameterMaster/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTestParameterMaster = null;
        _body = _resp.body as ResourceTestParameterMaster;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTestParameterMaster>;
      })
    );
  }

  /**
   * @param params The `TestParameterMasterEntityService.SaveTestParameterMasterUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTestParameterMasterUsingPUT(params: TestParameterMasterEntityService.SaveTestParameterMasterUsingPUTParams): Observable<ResourceTestParameterMaster> {
    return this.saveTestParameterMasterUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteTestParameterMasterUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/testParameterMaster/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteTestParameterMasterUsingDELETE(id: number): Observable<void> {
    return this.deleteTestParameterMasterUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TestParameterMasterEntityService.SaveTestParameterMasterUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTestParameterMasterUsingPATCHResponse(params: TestParameterMasterEntityService.SaveTestParameterMasterUsingPATCHParams): Observable<HttpResponse<ResourceTestParameterMaster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/testParameterMaster/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTestParameterMaster = null;
        _body = _resp.body as ResourceTestParameterMaster;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTestParameterMaster>;
      })
    );
  }

  /**
   * @param params The `TestParameterMasterEntityService.SaveTestParameterMasterUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTestParameterMasterUsingPATCH(params: TestParameterMasterEntityService.SaveTestParameterMasterUsingPATCHParams): Observable<ResourceTestParameterMaster> {
    return this.saveTestParameterMasterUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module TestParameterMasterEntityService {

  /**
   * Parameters for findAllTestParameterMasterUsingGET
   */
  export interface FindAllTestParameterMasterUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveTestParameterMasterUsingPUT
   */
  export interface SaveTestParameterMasterUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TestParameterMaster;
  }

  /**
   * Parameters for saveTestParameterMasterUsingPATCH
   */
  export interface SaveTestParameterMasterUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TestParameterMaster;
  }
}

export { TestParameterMasterEntityService }
