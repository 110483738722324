import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Provider, forwardRef, ErrorHandler } from '@angular/core';
import { SentryErrorHandler} from './services/error-handler.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule as EmrApiModule } from './api/emr/api.module';
import { ApiModule as OrderApiModule} from './api/orders/api.module';
import { ApiModule as ProviderApiModule} from './api/provider/api.module';
import { EmbeddedApiService } from './services/embedded-api.service';
import { ApiInterceptorService } from './interceptor/api-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './login/service/authentication.service';
import { FullScreenLoader } from './full-screen-loader/full-screen-loader';
import { SharedModule } from './shared/shared.module';
import { VersionCheckService } from './services/version-check.service';
import { ApiModule as TranscriberApiModule } from './api/transcriber/api.module';
import {MatDialogModule} from '@angular/material/dialog';
import { TimerPopupComponent } from './timer-popup/timer-popup.component';
import { MatSnackBarModule } from '@angular/material';
import {MatTableModule} from '@angular/material/table';
import {getAuthServiceConfigs} from '../socialloginConfig';
import {AuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptorService),
  multi: true
};

@NgModule({
  declarations: [
    AppComponent,
    FullScreenLoader,
    TimerPopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    EmrApiModule,
    OrderApiModule,
    ProviderApiModule,
    TranscriberApiModule,
    SharedModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTableModule,
    SocialLoginModule,
    ScheduleModule
  ],
  providers: [
    EmbeddedApiService,
    AuthenticationService,
    ApiInterceptorService,
    VersionCheckService,
    API_INTERCEPTOR_PROVIDER,
    { provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,
    }
    // { provide: ErrorHandler, useClass: RavenErrorHandler }
  ],
  bootstrap: [AppComponent],
  entryComponents: [TimerPopupComponent],
})
export class AppModule { }
