/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosticLab } from '../models/resources-diagnostic-lab-';
import { ResourceDiagnosticLab } from '../models/resource-diagnostic-lab-';
import { DiagnosticLab } from '../models/diagnostic-lab';
import { ResourcesDiagnosticTypeInLab } from '../models/resources-diagnostic-type-in-lab-';
import { ResourceDiagnosticTypeInLab } from '../models/resource-diagnostic-type-in-lab-';

/**
 * $ Proxy 176
 */
@Injectable()
class DiagnosticLabEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticLabEntityService.FindAllDiagnosticLabUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticLabUsingGETResponse(params: DiagnosticLabEntityService.FindAllDiagnosticLabUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticlabs?projection=diagnosticLabsWoDiagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticLab = null;
        _body = _resp.body as ResourcesDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.FindAllDiagnosticLabUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticLabUsingGET(params: DiagnosticLabEntityService.FindAllDiagnosticLabUsingGETParams): Observable<ResourcesDiagnosticLab> {
    return this.findAllDiagnosticLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticLabUsingPOSTResponse(body: DiagnosticLab): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticlabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticLabUsingPOST(body: DiagnosticLab): Observable<ResourceDiagnosticLab> {
    return this.saveDiagnosticLabUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.FindByNameContainingDiagnosticLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingDiagnosticLabUsingGETResponse(params: DiagnosticLabEntityService.FindByNameContainingDiagnosticLabUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/search/findByNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticLab = null;
        _body = _resp.body as ResourcesDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.FindByNameContainingDiagnosticLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingDiagnosticLabUsingGET(params: DiagnosticLabEntityService.FindByNameContainingDiagnosticLabUsingGETParams): Observable<ResourcesDiagnosticLab> {
    return this.findByNameContainingDiagnosticLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param diagnosticIds diagnosticIds
   * @return OK
   */
  findDistinctByDiagnosticTypeInLabsDiagnosticsIdInDiagnosticLabUsingGETResponse(diagnosticIds?: Array<number>): Observable<HttpResponse<ResourcesDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (diagnosticIds || []).forEach((val, index) => {if (val != null) __params = __params.append('diagnosticIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/search/findDistinctByDiagnosticTypeInLabsDiagnosticsIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticLab = null;
        _body = _resp.body as ResourcesDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticLab>;
      })
    );
  }

  /**
   * @param diagnosticIds diagnosticIds
   * @return OK
   */
  findDistinctByDiagnosticTypeInLabsDiagnosticsIdInDiagnosticLabUsingGET(diagnosticIds?: Array<number>): Observable<ResourcesDiagnosticLab> {
    return this.findDistinctByDiagnosticTypeInLabsDiagnosticsIdInDiagnosticLabUsingGETResponse(diagnosticIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticLabUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticLabUsingGET(id: number): Observable<ResourceDiagnosticLab> {
    return this.findByIdDiagnosticLabUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.SaveDiagnosticLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticLabUsingPUTResponse(params: DiagnosticLabEntityService.SaveDiagnosticLabUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.SaveDiagnosticLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticLabUsingPUT(params: DiagnosticLabEntityService.SaveDiagnosticLabUsingPUTParams): Observable<ResourceDiagnosticLab> {
    return this.saveDiagnosticLabUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticLabUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticLabUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticLabUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.SaveDiagnosticLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticLabUsingPATCHResponse(params: DiagnosticLabEntityService.SaveDiagnosticLabUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.SaveDiagnosticLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticLabUsingPATCH(params: DiagnosticLabEntityService.SaveDiagnosticLabUsingPATCHParams): Observable<ResourceDiagnosticLab> {
    return this.saveDiagnosticLabUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingGET_1(id: number): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingPOSTResponse(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingPOST(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPOSTParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingPUTResponse(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingPUT(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPUTParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticLabDiagnosticTypeInLabsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticLabDiagnosticTypeInLabsUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingPATCHResponse(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingPATCH(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingPATCHParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingGETResponse(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingGETParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}/diagnosticTypeInLabs/${params.diagnostictypeinlabId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   *
   * @return OK
   */
  diagnosticLabDiagnosticTypeInLabsUsingGET(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingGETParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   */
  diagnosticLabDiagnosticTypeInLabsUsingDELETEResponse(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticlabs/${params.id}/diagnosticTypeInLabs/${params.diagnostictypeinlabId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   */
  diagnosticLabDiagnosticTypeInLabsUsingDELETE(params: DiagnosticLabEntityService.DiagnosticLabDiagnosticTypeInLabsUsingDELETEParams): Observable<void> {
    return this.diagnosticLabDiagnosticTypeInLabsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticLabEntityService {

  /**
   * Parameters for findAllDiagnosticLabUsingGET
   */
  export interface FindAllDiagnosticLabUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByNameContainingDiagnosticLabUsingGET
   */
  export interface FindByNameContainingDiagnosticLabUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveDiagnosticLabUsingPUT
   */
  export interface SaveDiagnosticLabUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticLab;
  }

  /**
   * Parameters for saveDiagnosticLabUsingPATCH
   */
  export interface SaveDiagnosticLabUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticLab;
  }

  /**
   * Parameters for diagnosticLabDiagnosticTypeInLabsUsingPOST
   */
  export interface DiagnosticLabDiagnosticTypeInLabsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticLabDiagnosticTypeInLabsUsingPUT
   */
  export interface DiagnosticLabDiagnosticTypeInLabsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticLabDiagnosticTypeInLabsUsingPATCH
   */
  export interface DiagnosticLabDiagnosticTypeInLabsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticLabDiagnosticTypeInLabsUsingGET
   */
  export interface DiagnosticLabDiagnosticTypeInLabsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeinlabId
     */
    diagnostictypeinlabId: string;
  }

  /**
   * Parameters for diagnosticLabDiagnosticTypeInLabsUsingDELETE
   */
  export interface DiagnosticLabDiagnosticTypeInLabsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeinlabId
     */
    diagnostictypeinlabId: string;
  }
}

export { DiagnosticLabEntityService }
