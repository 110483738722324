/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesClinicsForAssistantDashboard } from '../models/resources-clinics-for-assistant-dashboard-';
import { ResourcesDoctorInClinicForAssistantDashboard } from '../models/resources-doctor-in-clinic-for-assistant-dashboard-';
import { ResourcesDoctorInClinicsWoConsultation } from '../models/resources-doctor-in-clinics-wo-consultation-';

/**
 * Doctors Assistant Dashboard Controller
 */
@Injectable()
class DoctorsAssistantDashboardControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param doctorinclinic doctorinclinic
   * @return OK
   */
  getDoctorInClinicsUsingGETResponse(doctorinclinic: Array<number>): Observable<HttpResponse<ResourcesClinicsForAssistantDashboard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/search/DoctorInClinicsIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinicsForAssistantDashboard = null;
        _body = _resp.body as ResourcesClinicsForAssistantDashboard;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinicsForAssistantDashboard>;
      })
    );
  }

  /**
   * @param doctorinclinic doctorinclinic
   * @return OK
   */
  getDoctorInClinicsUsingGET(doctorinclinic: Array<number>): Observable<ResourcesClinicsForAssistantDashboard> {
    return this.getDoctorInClinicsUsingGETResponse(doctorinclinic).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getAssistantDashboardDataUsingGETResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInClinicForAssistantDashboard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinicForAssistantDashboard = null;
        _body = _resp.body as ResourcesDoctorInClinicForAssistantDashboard;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinicForAssistantDashboard>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  getAssistantDashboardDataUsingGET(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInClinicForAssistantDashboard> {
    return this.getAssistantDashboardDataUsingGETResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  doctorInClinicWoConsultationUsingGETResponse(doctorInClinicIds: Array<number>): Observable<HttpResponse<ResourcesDoctorInClinicsWoConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/woConsultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinicsWoConsultation = null;
        _body = _resp.body as ResourcesDoctorInClinicsWoConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinicsWoConsultation>;
      })
    );
  }

  /**
   * @param doctorInClinicIds doctorInClinicIds
   * @return OK
   */
  doctorInClinicWoConsultationUsingGET(doctorInClinicIds: Array<number>): Observable<ResourcesDoctorInClinicsWoConsultation> {
    return this.doctorInClinicWoConsultationUsingGETResponse(doctorInClinicIds).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorsAssistantDashboardControllerService {
}

export { DoctorsAssistantDashboardControllerService }
