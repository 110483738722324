/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DiagnosticTypeBulkUploadDto } from '../models/diagnostic-type-bulk-upload-dto';
import { DoctorTags } from '../models/doctor-tags';
import { DoctorTagsDto } from '../models/doctor-tags-dto';

/**
 * Bulk Upload Controller
 */
@Injectable()
class BulkUploadControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `BulkUploadControllerService.PostDiagnosticTypeUsingPOSTParams` containing the following parameters:
   *
   * - `labId`: labId
   *
   * - `diagnosticTypes`: diagnosticTypes
   *
   * @return OK
   */
  postDiagnosticTypeUsingPOSTResponse(params: BulkUploadControllerService.PostDiagnosticTypeUsingPOSTParams): Observable<HttpResponse<Array<DiagnosticTypeBulkUploadDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.labId != null) __params = __params.set('labId', params.labId.toString());
    __body = params.diagnosticTypes;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostic-type/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<DiagnosticTypeBulkUploadDto> = null;
        _body = _resp.body as Array<DiagnosticTypeBulkUploadDto>;
        return _resp.clone({body: _body}) as HttpResponse<Array<DiagnosticTypeBulkUploadDto>>;
      })
    );
  }

  /**
   * @param params The `BulkUploadControllerService.PostDiagnosticTypeUsingPOSTParams` containing the following parameters:
   *
   * - `labId`: labId
   *
   * - `diagnosticTypes`: diagnosticTypes
   *
   * @return OK
   */
  postDiagnosticTypeUsingPOST(params: BulkUploadControllerService.PostDiagnosticTypeUsingPOSTParams): Observable<Array<DiagnosticTypeBulkUploadDto>> {
    return this.postDiagnosticTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `BulkUploadControllerService.EditDoctorTagsUsingPOSTParams` containing the following parameters:
   *
   * - `operation`: operation
   *
   * - `doctorTags`: doctorTags
   *
   * @return OK
   */
  editDoctorTagsUsingPOSTResponse(params: BulkUploadControllerService.EditDoctorTagsUsingPOSTParams): Observable<HttpResponse<Array<DoctorTags>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.operation != null) __params = __params.set('operation', params.operation.toString());
    __body = params.doctorTags;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctor-tags/bulk-edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<DoctorTags> = null;
        _body = _resp.body as Array<DoctorTags>;
        return _resp.clone({body: _body}) as HttpResponse<Array<DoctorTags>>;
      })
    );
  }

  /**
   * @param params The `BulkUploadControllerService.EditDoctorTagsUsingPOSTParams` containing the following parameters:
   *
   * - `operation`: operation
   *
   * - `doctorTags`: doctorTags
   *
   * @return OK
   */
  editDoctorTagsUsingPOST(params: BulkUploadControllerService.EditDoctorTagsUsingPOSTParams): Observable<Array<DoctorTags>> {
    return this.editDoctorTagsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module BulkUploadControllerService {

  /**
   * Parameters for postDiagnosticTypeUsingPOST
   */
  export interface PostDiagnosticTypeUsingPOSTParams {

    /**
     * labId
     */
    labId: number;

    /**
     * diagnosticTypes
     */
    diagnosticTypes: Array<DiagnosticTypeBulkUploadDto>;
  }

  /**
   * Parameters for editDoctorTagsUsingPOST
   */
  export interface EditDoctorTagsUsingPOSTParams {

    /**
     * operation
     */
    operation: string;

    /**
     * doctorTags
     */
    doctorTags: DoctorTagsDto;
  }
}

export { BulkUploadControllerService }
