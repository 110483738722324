/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Bucket Controller
 */
@Injectable()
class BucketControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param url url
   * @return OK
   */
  deleteFileUsingDELETEResponse(url: string): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    __formData.append('url', url);
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/storage/deleteFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param url url
   * @return OK
   */
  deleteFileUsingDELETE(url: string): Observable<string> {
    return this.deleteFileUsingDELETEResponse(url).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param file file
   * @return OK
   */
  uploadFileUsingPOSTResponse(file: Blob): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    __formData.append('file', file);
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/storage/uploadFile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param file file
   * @return OK
   */
  uploadFileUsingPOST(file: Blob): Observable<string> {
    return this.uploadFileUsingPOSTResponse(file).pipe(
      map(_r => _r.body)
    );
  }
}

module BucketControllerService {
}

export { BucketControllerService }
