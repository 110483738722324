/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesVitalType } from '../models/resources-vital-type-';
import { ResourceVitalType } from '../models/resource-vital-type-';
import { VitalType } from '../models/vital-type';
import { ResourcesVital } from '../models/resources-vital-';
import { ResourceVital } from '../models/resource-vital-';

/**
 * $ Proxy 215
 */
@Injectable()
class VitalTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VitalTypeEntityService.FindAllVitalTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllVitalTypeUsingGETResponse(params: VitalTypeEntityService.FindAllVitalTypeUsingGETParams): Observable<HttpResponse<ResourcesVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitaltypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVitalType = null;
        _body = _resp.body as ResourcesVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVitalType>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.FindAllVitalTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllVitalTypeUsingGET(params: VitalTypeEntityService.FindAllVitalTypeUsingGETParams): Observable<ResourcesVitalType> {
    return this.findAllVitalTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveVitalTypeUsingPOSTResponse(body: VitalType): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/vitaltypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveVitalTypeUsingPOST(body: VitalType): Observable<ResourceVitalType> {
    return this.saveVitalTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdVitalTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitaltypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdVitalTypeUsingGET(id: number): Observable<ResourceVitalType> {
    return this.findByIdVitalTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.SaveVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalTypeUsingPUTResponse(params: VitalTypeEntityService.SaveVitalTypeUsingPUTParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.SaveVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalTypeUsingPUT(params: VitalTypeEntityService.SaveVitalTypeUsingPUTParams): Observable<ResourceVitalType> {
    return this.saveVitalTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteVitalTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/vitaltypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteVitalTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteVitalTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.SaveVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalTypeUsingPATCHResponse(params: VitalTypeEntityService.SaveVitalTypeUsingPATCHParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.SaveVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveVitalTypeUsingPATCH(params: VitalTypeEntityService.SaveVitalTypeUsingPATCHParams): Observable<ResourceVitalType> {
    return this.saveVitalTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  vitalTypeVitalListUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitaltypes/${id}/vitalList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  vitalTypeVitalListUsingGET_1(id: number): Observable<ResourcesVital> {
    return this.vitalTypeVitalListUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalTypeVitalListUsingPOSTResponse(params: VitalTypeEntityService.VitalTypeVitalListUsingPOSTParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}/vitalList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalTypeVitalListUsingPOST(params: VitalTypeEntityService.VitalTypeVitalListUsingPOSTParams): Observable<ResourcesVital> {
    return this.vitalTypeVitalListUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalTypeVitalListUsingPUTResponse(params: VitalTypeEntityService.VitalTypeVitalListUsingPUTParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}/vitalList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalTypeVitalListUsingPUT(params: VitalTypeEntityService.VitalTypeVitalListUsingPUTParams): Observable<ResourcesVital> {
    return this.vitalTypeVitalListUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  vitalTypeVitalListUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/vitaltypes/${id}/vitalList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  vitalTypeVitalListUsingDELETE_1(id: number): Observable<void> {
    return this.vitalTypeVitalListUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalTypeVitalListUsingPATCHResponse(params: VitalTypeEntityService.VitalTypeVitalListUsingPATCHParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}/vitalList`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  vitalTypeVitalListUsingPATCH(params: VitalTypeEntityService.VitalTypeVitalListUsingPATCHParams): Observable<ResourcesVital> {
    return this.vitalTypeVitalListUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingGETParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   *
   * @return OK
   */
  vitalTypeVitalListUsingGETResponse(params: VitalTypeEntityService.VitalTypeVitalListUsingGETParams): Observable<HttpResponse<ResourceVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}/vitalList/${params.vitalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVital = null;
        _body = _resp.body as ResourceVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVital>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingGETParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   *
   * @return OK
   */
  vitalTypeVitalListUsingGET(params: VitalTypeEntityService.VitalTypeVitalListUsingGETParams): Observable<ResourceVital> {
    return this.vitalTypeVitalListUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingDELETEParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   */
  vitalTypeVitalListUsingDELETEResponse(params: VitalTypeEntityService.VitalTypeVitalListUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/vitaltypes/${params.id}/vitalList/${params.vitalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `VitalTypeEntityService.VitalTypeVitalListUsingDELETEParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   */
  vitalTypeVitalListUsingDELETE(params: VitalTypeEntityService.VitalTypeVitalListUsingDELETEParams): Observable<void> {
    return this.vitalTypeVitalListUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module VitalTypeEntityService {

  /**
   * Parameters for findAllVitalTypeUsingGET
   */
  export interface FindAllVitalTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveVitalTypeUsingPUT
   */
  export interface SaveVitalTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: VitalType;
  }

  /**
   * Parameters for saveVitalTypeUsingPATCH
   */
  export interface SaveVitalTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: VitalType;
  }

  /**
   * Parameters for vitalTypeVitalListUsingPOST
   */
  export interface VitalTypeVitalListUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for vitalTypeVitalListUsingPUT
   */
  export interface VitalTypeVitalListUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for vitalTypeVitalListUsingPATCH
   */
  export interface VitalTypeVitalListUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for vitalTypeVitalListUsingGET
   */
  export interface VitalTypeVitalListUsingGETParams {

    /**
     * vitalId
     */
    vitalId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for vitalTypeVitalListUsingDELETE
   */
  export interface VitalTypeVitalListUsingDELETEParams {

    /**
     * vitalId
     */
    vitalId: string;

    /**
     * id
     */
    id: number;
  }
}

export { VitalTypeEntityService }
