/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesConsultation } from '../models/resources-consultation-';
import { ResourceConsultation } from '../models/resource-consultation-';
import { Consultation } from '../models/consultation';
import { Resourcesstring } from '../models/resources-string-';
import { ResourceAddress } from '../models/resource-address-';
import { ResourcesAdvice } from '../models/resources-advice-';
import { ResourceAdvice } from '../models/resource-advice-';
import { ResourcesAllergy } from '../models/resources-allergy-';
import { ResourceAllergy } from '../models/resource-allergy-';
import { ResourcesConsultationHistory } from '../models/resources-consultation-history-';
import { ResourceConsultationHistory } from '../models/resource-consultation-history-';
import { ResourcesDiagnosis } from '../models/resources-diagnosis-';
import { ResourceDiagnosis } from '../models/resource-diagnosis-';
import { ResourcesDiagnostic } from '../models/resources-diagnostic-';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';
import { ResourceDoctorInClinic } from '../models/resource-doctor-in-clinic-';
import { ResourcesExistingCondition } from '../models/resources-existing-condition-';
import { ResourceExistingCondition } from '../models/resource-existing-condition-';
import { ResourcesFollowup } from '../models/resources-followup-';
import { ResourceFollowup } from '../models/resource-followup-';
import { ResourcePatient } from '../models/resource-patient-';
import { ResourcesPatientHistory } from '../models/resources-patient-history-';
import { ResourcePatientHistory } from '../models/resource-patient-history-';
import { ResourcesPatientSymptom } from '../models/resources-patient-symptom-';
import { ResourcePatientSymptom } from '../models/resource-patient-symptom-';
import { ResourcesPrescriptionByDate } from '../models/resources-prescription-by-date-';
import { ResourcePrescriptionByDate } from '../models/resource-prescription-by-date-';
import { ResourcesPrescription } from '../models/resources-prescription-';
import { ResourcePrescription } from '../models/resource-prescription-';
import { ResourcesMedicalProcedure } from '../models/resources-medical-procedure-';
import { ResourceMedicalProcedure } from '../models/resource-medical-procedure-';
import { ResourcesReferral } from '../models/resources-referral-';
import { ResourceReferral } from '../models/resource-referral-';
import { ResourcesScribble } from '../models/resources-scribble-';
import { ResourceScribble } from '../models/resource-scribble-';
import { ResourcesSymptom } from '../models/resources-symptom-';
import { ResourceSymptom } from '../models/resource-symptom-';
import { ResourcesTakenMedicine } from '../models/resources-taken-medicine-';
import { ResourceTakenMedicine } from '../models/resource-taken-medicine-';
import { ResourceTimeSlot } from '../models/resource-time-slot-';
import { ResourceVisitType } from '../models/resource-visit-type-';
import { ResourcesVital } from '../models/resources-vital-';
import { ResourceVital } from '../models/resource-vital-';

/**
 * $ Proxy 164
 */
@Injectable()
class ConsultationEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ConsultationEntityService.FindAllConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllConsultationUsingGETResponse(params: ConsultationEntityService.FindAllConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindAllConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllConsultationUsingGET(params: ConsultationEntityService.FindAllConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findAllConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveConsultationUsingPOSTDataResponse(body: Consultation, email: string): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    email?__headers = __headers.append('email',email):null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/create-consultation/v1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveConsultationUsingPOSTData(body: Consultation,email: string): Observable<ResourceConsultation> {
    return this.saveConsultationUsingPOSTDataResponse(body,email).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveConsultationUsingPOSTResponse(body: Consultation, email: string): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    email?__headers = __headers.append('email',email):null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveConsultationUsingPOST(body: Consultation,email: string): Observable<ResourceConsultation> {
    return this.saveConsultationUsingPOSTResponse(body,email).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.CountByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  countByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETResponse(params: ConsultationEntityService.CountByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/countByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({body: _body}) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.CountByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  countByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGET(params: ConsultationEntityService.CountByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams): Observable<number> {
    return this.countByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.CountDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  countDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETResponse(params: ConsultationEntityService.CountDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeslotId != null) __params = __params.set('timeslotId', params.timeslotId.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/countDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({body: _body}) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.CountDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  countDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGET(params: ConsultationEntityService.CountDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams): Observable<number> {
    return this.countDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientIds patientIds
   * @return OK
   */
  fetchConsultationsFromPatientIdsConsultationUsingGETResponse(patientIds?: Array<number>): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (patientIds || []).forEach((val, index) => {if (val != null) __params = __params.append('patientIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/fetchConsultationsFromPatientIds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param patientIds patientIds
   * @return OK
   */
  fetchConsultationsFromPatientIdsConsultationUsingGET(patientIds?: Array<number>): Observable<ResourcesConsultation> {
    return this.fetchConsultationsFromPatientIdsConsultationUsingGETResponse(patientIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByAppointmentTimeLessThanAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `date`: date
   *
   * @return OK
   */
  findByAppointmentTimeLessThanAndStatusConsultationUsingGETResponse(params: ConsultationEntityService.FindByAppointmentTimeLessThanAndStatusConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByAppointmentTimeLessThanAndStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByAppointmentTimeLessThanAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `date`: date
   *
   * @return OK
   */
  findByAppointmentTimeLessThanAndStatusConsultationUsingGET(params: ConsultationEntityService.FindByAppointmentTimeLessThanAndStatusConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByAppointmentTimeLessThanAndStatusConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETParams): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdAndAppointmentTime`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({body: _body}) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findByDoctorInClinicIdAndAppointmentTimeConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETParams): Observable<number> {
    return this.findByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdAndAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `doctorInClinic`: doctorInClinic
   *
   * @return OK
   */
  findByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.doctorInClinic != null) __params = __params.set('doctorInClinic', params.doctorInClinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdAndPatientPhoneContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `doctorInClinic`: doctorInClinic
   *
   * @return OK
   */
  findByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinic doctorInClinic
   * @return OK
   */
  findByDoctorInClinicIdInConsultationUsingGETResponse(doctorInClinic?: Array<number>): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param doctorInClinic doctorInClinic
   * @return OK
   */
  findByDoctorInClinicIdInConsultationUsingGET(doctorInClinic?: Array<number>): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInConsultationUsingGETResponse(doctorInClinic).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientIdConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    //(params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    //if (params.doctorinclinic != null && params.doctorinclinic.length) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/find-by-dic-and-patient-id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientIdConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndPatientIdConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientIdConsultationUsingGETResponseAuth(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    //(params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.doctorinclinic != null && params.doctorinclinic.length) __params = __params.set('doctorInClinicId', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/find-consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientIdConsultationUsingGETAuth(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndPatientIdConsultationUsingGETResponseAuth(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorIdAndPatientIdConsultationInfoUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorId`: doctorId
   *
   * @return OK
   */
  findByDoctorIdAndPatientIdConsultationInfoUsingGETResponse(params: ConsultationEntityService.FindByDoctorIdAndPatientIdConsultationInfoUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    //(params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.doctorId != null) __params = __params.set('doctorId', params.doctorId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/last-consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorIdAndPatientIdConsultationInfoUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorId`: doctorId
   *
   * @return OK
   */
  findByDoctorIdAndPatientIdConsultationInfoUsingGET(params: ConsultationEntityService.FindByDoctorIdAndPatientIdConsultationInfoUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorIdAndPatientIdConsultationInfoUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `patientId`: patientId
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    //(params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.doctorinclinic != null && params.doctorinclinic.length) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndPatientIdInAndStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `patientId`: patientId
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndPatientNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndPatientPhoneContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatus?projection=consultationForAssistantDashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAsc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `from`: from
   *
   * - `doctorinclinicid`: doctorinclinicid
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.from != null) __params = __params.set('from', params.from.toString());
    (params.doctorinclinicid || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinicid', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `from`: from
   *
   * - `doctorinclinicid`: doctorinclinicid
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `isTranscribed`: isTranscribed
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.isTranscribed != null) __params = __params.set('isTranscribed', params.isTranscribed.toString());
    (params.doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDesc?projection=consultationForTranscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `isTranscribed`: isTranscribed
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusInConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.status || []).forEach((val, index) => {if (val != null) __params = __params.append('status', val.toString())});
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusInConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusInConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('dicIds', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/fetch-consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAsc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinic`: doctorInClinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderByIdDescConsultationUsingGETResponse(params: ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.doctorInClinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByDoctorInClinicIdInOrderByIdDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinic`: doctorInClinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderByIdDescConsultationUsingGET(params: ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInOrderByIdDescConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinic`: doctorInClinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderByIdDescConsultationUsingGETResponseAuth(params: ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.doctorInClinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/find-consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinic`: doctorInClinic
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderByIdDescConsultationUsingGETAuth(params: ConsultationEntityService.FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByDoctorInClinicIdInOrderByIdDescConsultationUsingGETResponseAuth(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param params The `ConsultationEntityService.FindByIdAndPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `id`: id
   *
   * @return OK
   */
  findByIdAndPatientIdConsultationUsingGETResponse(params: ConsultationEntityService.FindByIdAndPatientIdConsultationUsingGETParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByIdAndPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIdAndPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `id`: id
   *
   * @return OK
   */
  findByIdAndPatientIdConsultationUsingGET(params: ConsultationEntityService.FindByIdAndPatientIdConsultationUsingGETParams): Observable<ResourceConsultation> {
    return this.findByIdAndPatientIdConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETResponse(params: ConsultationEntityService.FindByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGET(params: ConsultationEntityService.FindByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * @return OK
   */
  findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETResponse(params: ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * @return OK
   */
  findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGET(params: ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * @return OK
   */
  findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETResponse(params: ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams` containing the following parameters:
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * @return OK
   */
  findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGET(params: ConsultationEntityService.FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientCode`: patientCode
   *
   * - `doctorinclinicCode`: doctorinclinicCode
   *
   * @return OK
   */
  findByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientCode != null) __params = __params.set('patientCode', params.patientCode.toString());
    if (params.doctorinclinicCode != null) __params = __params.set('doctorinclinicCode', params.doctorinclinicCode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientCodeAndDoctorInClinicCodeIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientCode`: patientCode
   *
   * - `doctorinclinicCode`: doctorinclinicCode
   *
   * @return OK
   */
  findByPatientCodeAndDoctorInClinicCodeInConsultationUsingGET(params: ConsultationEntityService.FindByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.dicId != null) __params = __params.set('dicId', params.dicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  findByPatientIdAndDoctorInClinicIdInConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorinclinicId != null) __params = __params.set('doctorinclinicId', params.doctorinclinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdAndDoctorInClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  findByPatientIdAndDoctorInClinicIdInConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdAndDoctorInClinicIdInConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `isVisited`: isVisited
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.isVisited != null) __params = __params.set('isVisited', params.isVisited.toString());
    if (params.dicId != null) __params = __params.set('dicId', params.dicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdAndDoctorInClinicIdInAndIsVisited`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `isVisited`: isVisited
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndLastModifiedBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdAndLastModifiedBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdAndLastModifiedBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdAndLastModifiedBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndLastModifiedBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdAndLastModifiedBetweenConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdAndLastModifiedBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdAndLastModifiedBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `patientId`: patientId
   *
   * @return OK
   */
  findByPatientIdAndStatusConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdAndStatusConsultationUsingGETParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdAndStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndStatusConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `patientId`: patientId
   *
   * @return OK
   */
  findByPatientIdAndStatusConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdAndStatusConsultationUsingGETParams): Observable<ResourceConsultation> {
    return this.findByPatientIdAndStatusConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdAndStatusAndAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  findByPatientIdInConsultationUsingGETResponse(patientId?: number): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  findByPatientIdInConsultationUsingGET(patientId?: number): Observable<ResourcesConsultation> {
    return this.findByPatientIdInConsultationUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETResponse(params: ConsultationEntityService.FindByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByPatientIdInAndStatusAndAppointmentTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGET(params: ConsultationEntityService.FindByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETResponse(params: ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAsc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGET(params: ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `priorityQueues`: priorityQueues
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETResponse(params: ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.priorityQueues != null) __params = __params.set('priorityQueues', params.priorityQueues.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `startDate`: startDate
   *
   * - `priorityQueues`: priorityQueues
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGET(params: ConsultationEntityService.FindByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusInAndDoctorInClinicIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `id`: id
   *
   * @return OK
   */
  findByStatusInAndDoctorInClinicIdConsultationUsingGETResponse(params: ConsultationEntityService.FindByStatusInAndDoctorInClinicIdConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByStatusInAndDoctorInClinicId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusInAndDoctorInClinicIdConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `id`: id
   *
   * @return OK
   */
  findByStatusInAndDoctorInClinicIdConsultationUsingGET(params: ConsultationEntityService.FindByStatusInAndDoctorInClinicIdConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByStatusInAndDoctorInClinicIdConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusInAndDoctorInClinicIdInConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `id`: id
   *
   * @return OK
   */
  findByStatusInAndDoctorInClinicIdInConsultationUsingGETResponse(params: ConsultationEntityService.FindByStatusInAndDoctorInClinicIdInConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findByStatusInAndDoctorInClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindByStatusInAndDoctorInClinicIdInConsultationUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `id`: id
   *
   * @return OK
   */
  findByStatusInAndDoctorInClinicIdInConsultationUsingGET(params: ConsultationEntityService.FindByStatusInAndDoctorInClinicIdInConsultationUsingGETParams): Observable<ResourcesConsultation> {
    return this.findByStatusInAndDoctorInClinicIdInConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  findConsultationsThroughPatientIdConsultationUsingGETResponse(patientId?: number): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findConsultationsThroughPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  findConsultationsThroughPatientIdConsultationUsingGET(patientId?: number): Observable<ResourcesConsultation> {
    return this.findConsultationsThroughPatientIdConsultationUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  findFirstByDoctorInClinicIdOrderByAppointmentTimeDescConsultationUsingGETResponse(doctorInClinics?: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorInClinics != null) __params = __params.set('doctorInClinics', doctorInClinics.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findFirstByDoctorInClinicIdOrderByAppointmentTimeDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  findFirstByDoctorInClinicIdOrderByAppointmentTimeDescConsultationUsingGET(doctorInClinics?: number): Observable<ResourceConsultation> {
    return this.findFirstByDoctorInClinicIdOrderByAppointmentTimeDescConsultationUsingGETResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindMaxPriorityQueueNumberConsultationUsingGETParams` containing the following parameters:
   *
   * - `statusValue`: statusValue
   *
   * - `dic`: dic
   *
   * @return OK
   */
  findMaxPriorityQueueNumberConsultationUsingGETResponse(params: ConsultationEntityService.FindMaxPriorityQueueNumberConsultationUsingGETParams): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.statusValue != null) __params = __params.set('statusValue', params.statusValue.toString());
    if (params.dic != null) __params = __params.set('dic', params.dic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/findMaxPriorityQueueNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({body: _body}) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.FindMaxPriorityQueueNumberConsultationUsingGETParams` containing the following parameters:
   *
   * - `statusValue`: statusValue
   *
   * - `dic`: dic
   *
   * @return OK
   */
  findMaxPriorityQueueNumberConsultationUsingGET(params: ConsultationEntityService.FindMaxPriorityQueueNumberConsultationUsingGETParams): Observable<number> {
    return this.findMaxPriorityQueueNumberConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  getAllCodesConsultationUsingGETResponse(): Observable<HttpResponse<Resourcesstring>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/search/getAllCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesstring = null;
        _body = _resp.body as Resourcesstring;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesstring>;
      })
    );
  }

  /**
   * @return OK
   */
  getAllCodesConsultationUsingGET(): Observable<Resourcesstring> {
    return this.getAllCodesConsultationUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}?projection=consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGETResponseAuth(id: number): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (id != null) __params = __params.set('consultationId', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/find-consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = null;
        _body = _resp.body as any;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }


  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGETOrderResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}?projection=consultationForOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGETOrder(id: number): Observable<ResourceConsultation> {
    return this.findByIdConsultationUsingGETOrderResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.findByIdConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGETAuth(id: number): Observable<any> {
    return this.findByIdConsultationUsingGETResponseAuth(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.SaveConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationUsingPUTResponse(params: ConsultationEntityService.SaveConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.SaveConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationUsingPUT(params: ConsultationEntityService.SaveConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.saveConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteConsultationUsingDELETE(id: number): Observable<void> {
    return this.deleteConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.SaveConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationUsingPATCHResponse(params: ConsultationEntityService.SaveConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.SaveConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationUsingPATCH(params: ConsultationEntityService.SaveConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.saveConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.SaveConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationStatusUsingPATCHRespone(params: ConsultationEntityService.SaveConsultationStatusUsingPATCHParams): Observable<HttpResponse<String>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultation/status-update/${params.id}?status=${params.status}`,
      __body,
      {
        headers: __headers,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: String = null;
        _body = _resp.body as String;
        return _resp.clone({body: _body}) as HttpResponse<String>;
      })
    );
  }


  /**
   * @param params The `ConsultationEntityService.SaveConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveConsultationStatusUsingPATCH(params: ConsultationEntityService.SaveConsultationStatusUsingPATCHParams): Observable<String> {
    return this.saveConsultationStatusUsingPATCHRespone(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationAddressUsingGETResponse(id: number): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationAddressUsingGET(id: number): Observable<ResourceAddress> {
    return this.consultationAddressUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAddressUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAddressUsingPOSTResponse(params: ConsultationEntityService.ConsultationAddressUsingPOSTParams): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAddressUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAddressUsingPOST(params: ConsultationEntityService.ConsultationAddressUsingPOSTParams): Observable<ResourceAddress> {
    return this.consultationAddressUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAddressUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAddressUsingPUTResponse(params: ConsultationEntityService.ConsultationAddressUsingPUTParams): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAddressUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAddressUsingPUT(params: ConsultationEntityService.ConsultationAddressUsingPUTParams): Observable<ResourceAddress> {
    return this.consultationAddressUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationAddressUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationAddressUsingDELETE(id: number): Observable<void> {
    return this.consultationAddressUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAddressUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAddressUsingPATCHResponse(params: ConsultationEntityService.ConsultationAddressUsingPATCHParams): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/address`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAddressUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAddressUsingPATCH(params: ConsultationEntityService.ConsultationAddressUsingPATCHParams): Observable<ResourceAddress> {
    return this.consultationAddressUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationAdvicesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAdvice = null;
        _body = _resp.body as ResourcesAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAdvice>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationAdvicesUsingGET_1(id: number): Observable<ResourcesAdvice> {
    return this.consultationAdvicesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAdvicesUsingPOSTResponse(params: ConsultationEntityService.ConsultationAdvicesUsingPOSTParams): Observable<HttpResponse<ResourcesAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAdvice = null;
        _body = _resp.body as ResourcesAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAdvice>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAdvicesUsingPOST(params: ConsultationEntityService.ConsultationAdvicesUsingPOSTParams): Observable<ResourcesAdvice> {
    return this.consultationAdvicesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAdvicesUsingPUTResponse(params: ConsultationEntityService.ConsultationAdvicesUsingPUTParams): Observable<HttpResponse<ResourcesAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAdvice = null;
        _body = _resp.body as ResourcesAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAdvice>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAdvicesUsingPUT(params: ConsultationEntityService.ConsultationAdvicesUsingPUTParams): Observable<ResourcesAdvice> {
    return this.consultationAdvicesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationAdvicesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationAdvicesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationAdvicesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAdvicesUsingPATCHResponse(params: ConsultationEntityService.ConsultationAdvicesUsingPATCHParams): Observable<HttpResponse<ResourcesAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAdvice = null;
        _body = _resp.body as ResourcesAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAdvice>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAdvicesUsingPATCH(params: ConsultationEntityService.ConsultationAdvicesUsingPATCHParams): Observable<ResourcesAdvice> {
    return this.consultationAdvicesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `adviceId`: adviceId
   *
   * @return OK
   */
  consultationAdvicesUsingGETResponse(params: ConsultationEntityService.ConsultationAdvicesUsingGETParams): Observable<HttpResponse<ResourceAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/advices/${params.adviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAdvice = null;
        _body = _resp.body as ResourceAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAdvice>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `adviceId`: adviceId
   *
   * @return OK
   */
  consultationAdvicesUsingGET(params: ConsultationEntityService.ConsultationAdvicesUsingGETParams): Observable<ResourceAdvice> {
    return this.consultationAdvicesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `adviceId`: adviceId
   */
  consultationAdvicesUsingDELETEResponse(params: ConsultationEntityService.ConsultationAdvicesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/advices/${params.adviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAdvicesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `adviceId`: adviceId
   */
  consultationAdvicesUsingDELETE(params: ConsultationEntityService.ConsultationAdvicesUsingDELETEParams): Observable<void> {
    return this.consultationAdvicesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationAllergiesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationAllergiesUsingGET_1(id: number): Observable<ResourcesAllergy> {
    return this.consultationAllergiesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAllergiesUsingPOSTResponse(params: ConsultationEntityService.ConsultationAllergiesUsingPOSTParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAllergiesUsingPOST(params: ConsultationEntityService.ConsultationAllergiesUsingPOSTParams): Observable<ResourcesAllergy> {
    return this.consultationAllergiesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAllergiesUsingPUTResponse(params: ConsultationEntityService.ConsultationAllergiesUsingPUTParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAllergiesUsingPUT(params: ConsultationEntityService.ConsultationAllergiesUsingPUTParams): Observable<ResourcesAllergy> {
    return this.consultationAllergiesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationAllergiesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationAllergiesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationAllergiesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAllergiesUsingPATCHResponse(params: ConsultationEntityService.ConsultationAllergiesUsingPATCHParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationAllergiesUsingPATCH(params: ConsultationEntityService.ConsultationAllergiesUsingPATCHParams): Observable<ResourcesAllergy> {
    return this.consultationAllergiesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   *
   * @return OK
   */
  consultationAllergiesUsingGETResponse(params: ConsultationEntityService.ConsultationAllergiesUsingGETParams): Observable<HttpResponse<ResourceAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/allergies/${params.allergyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergy = null;
        _body = _resp.body as ResourceAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergy>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   *
   * @return OK
   */
  consultationAllergiesUsingGET(params: ConsultationEntityService.ConsultationAllergiesUsingGETParams): Observable<ResourceAllergy> {
    return this.consultationAllergiesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   */
  consultationAllergiesUsingDELETEResponse(params: ConsultationEntityService.ConsultationAllergiesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/allergies/${params.allergyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationAllergiesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   */
  consultationAllergiesUsingDELETE(params: ConsultationEntityService.ConsultationAllergiesUsingDELETEParams): Observable<void> {
    return this.consultationAllergiesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationConsultationHistoriesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/consultationHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationConsultationHistoriesUsingGET_1(id: number): Observable<ResourcesConsultationHistory> {
    return this.consultationConsultationHistoriesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingPOSTResponse(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingPOSTParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/consultationHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingPOST(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingPOSTParams): Observable<ResourcesConsultationHistory> {
    return this.consultationConsultationHistoriesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingPUTResponse(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingPUTParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/consultationHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingPUT(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingPUTParams): Observable<ResourcesConsultationHistory> {
    return this.consultationConsultationHistoriesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationConsultationHistoriesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/consultationHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationConsultationHistoriesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationConsultationHistoriesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingPATCHResponse(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingPATCHParams): Observable<HttpResponse<ResourcesConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/consultationHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationHistory = null;
        _body = _resp.body as ResourcesConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingPATCH(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingPATCHParams): Observable<ResourcesConsultationHistory> {
    return this.consultationConsultationHistoriesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationhistoryId`: consultationhistoryId
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingGETResponse(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingGETParams): Observable<HttpResponse<ResourceConsultationHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/consultationHistories/${params.consultationhistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationHistory = null;
        _body = _resp.body as ResourceConsultationHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationhistoryId`: consultationhistoryId
   *
   * @return OK
   */
  consultationConsultationHistoriesUsingGET(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingGETParams): Observable<ResourceConsultationHistory> {
    return this.consultationConsultationHistoriesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationhistoryId`: consultationhistoryId
   */
  consultationConsultationHistoriesUsingDELETEResponse(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/consultationHistories/${params.consultationhistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationConsultationHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationhistoryId`: consultationhistoryId
   */
  consultationConsultationHistoriesUsingDELETE(params: ConsultationEntityService.ConsultationConsultationHistoriesUsingDELETEParams): Observable<void> {
    return this.consultationConsultationHistoriesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationDiagnosesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationDiagnosesUsingGET_1(id: number): Observable<ResourcesDiagnosis> {
    return this.consultationDiagnosesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosesUsingPOSTResponse(params: ConsultationEntityService.ConsultationDiagnosesUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosesUsingPOST(params: ConsultationEntityService.ConsultationDiagnosesUsingPOSTParams): Observable<ResourcesDiagnosis> {
    return this.consultationDiagnosesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosesUsingPUTResponse(params: ConsultationEntityService.ConsultationDiagnosesUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosesUsingPUT(params: ConsultationEntityService.ConsultationDiagnosesUsingPUTParams): Observable<ResourcesDiagnosis> {
    return this.consultationDiagnosesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationDiagnosesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationDiagnosesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationDiagnosesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosesUsingPATCHResponse(params: ConsultationEntityService.ConsultationDiagnosesUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosesUsingPATCH(params: ConsultationEntityService.ConsultationDiagnosesUsingPATCHParams): Observable<ResourcesDiagnosis> {
    return this.consultationDiagnosesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   *
   * @return OK
   */
  consultationDiagnosesUsingGETResponse(params: ConsultationEntityService.ConsultationDiagnosesUsingGETParams): Observable<HttpResponse<ResourceDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnoses/${params.diagnosisId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosis = null;
        _body = _resp.body as ResourceDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosis>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   *
   * @return OK
   */
  consultationDiagnosesUsingGET(params: ConsultationEntityService.ConsultationDiagnosesUsingGETParams): Observable<ResourceDiagnosis> {
    return this.consultationDiagnosesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   */
  consultationDiagnosesUsingDELETEResponse(params: ConsultationEntityService.ConsultationDiagnosesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnoses/${params.diagnosisId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   */
  consultationDiagnosesUsingDELETE(params: ConsultationEntityService.ConsultationDiagnosesUsingDELETEParams): Observable<void> {
    return this.consultationDiagnosesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationDiagnosticsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationDiagnosticsUsingGET_1(id: number): Observable<ResourcesDiagnostic> {
    return this.consultationDiagnosticsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosticsUsingPOSTResponse(params: ConsultationEntityService.ConsultationDiagnosticsUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosticsUsingPOST(params: ConsultationEntityService.ConsultationDiagnosticsUsingPOSTParams): Observable<ResourcesDiagnostic> {
    return this.consultationDiagnosticsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosticsUsingPUTResponse(params: ConsultationEntityService.ConsultationDiagnosticsUsingPUTParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosticsUsingPUT(params: ConsultationEntityService.ConsultationDiagnosticsUsingPUTParams): Observable<ResourcesDiagnostic> {
    return this.consultationDiagnosticsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationDiagnosticsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationDiagnosticsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationDiagnosticsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosticsUsingPATCHResponse(params: ConsultationEntityService.ConsultationDiagnosticsUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDiagnosticsUsingPATCH(params: ConsultationEntityService.ConsultationDiagnosticsUsingPATCHParams): Observable<ResourcesDiagnostic> {
    return this.consultationDiagnosticsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  consultationDiagnosticsUsingGETResponse(params: ConsultationEntityService.ConsultationDiagnosticsUsingGETParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnostics/${params.diagnosticId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  consultationDiagnosticsUsingGET(params: ConsultationEntityService.ConsultationDiagnosticsUsingGETParams): Observable<ResourceDiagnostic> {
    return this.consultationDiagnosticsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   */
  consultationDiagnosticsUsingDELETEResponse(params: ConsultationEntityService.ConsultationDiagnosticsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/diagnostics/${params.diagnosticId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDiagnosticsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   */
  consultationDiagnosticsUsingDELETE(params: ConsultationEntityService.ConsultationDiagnosticsUsingDELETEParams): Observable<void> {
    return this.consultationDiagnosticsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationDoctorInClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/doctorInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationDoctorInClinicUsingGET(id: number): Observable<ResourceDoctorInClinic> {
    return this.consultationDoctorInClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDoctorInClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDoctorInClinicUsingPOSTResponse(params: ConsultationEntityService.ConsultationDoctorInClinicUsingPOSTParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/doctorInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDoctorInClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDoctorInClinicUsingPOST(params: ConsultationEntityService.ConsultationDoctorInClinicUsingPOSTParams): Observable<ResourceDoctorInClinic> {
    return this.consultationDoctorInClinicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDoctorInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDoctorInClinicUsingPUTResponse(params: ConsultationEntityService.ConsultationDoctorInClinicUsingPUTParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/doctorInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDoctorInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDoctorInClinicUsingPUT(params: ConsultationEntityService.ConsultationDoctorInClinicUsingPUTParams): Observable<ResourceDoctorInClinic> {
    return this.consultationDoctorInClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationDoctorInClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/doctorInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationDoctorInClinicUsingDELETE(id: number): Observable<void> {
    return this.consultationDoctorInClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDoctorInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDoctorInClinicUsingPATCHResponse(params: ConsultationEntityService.ConsultationDoctorInClinicUsingPATCHParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/doctorInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationDoctorInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationDoctorInClinicUsingPATCH(params: ConsultationEntityService.ConsultationDoctorInClinicUsingPATCHParams): Observable<ResourceDoctorInClinic> {
    return this.consultationDoctorInClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationExistingConditionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationExistingConditionsUsingGET_1(id: number): Observable<ResourcesExistingCondition> {
    return this.consultationExistingConditionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationExistingConditionsUsingPOSTResponse(params: ConsultationEntityService.ConsultationExistingConditionsUsingPOSTParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationExistingConditionsUsingPOST(params: ConsultationEntityService.ConsultationExistingConditionsUsingPOSTParams): Observable<ResourcesExistingCondition> {
    return this.consultationExistingConditionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationExistingConditionsUsingPUTResponse(params: ConsultationEntityService.ConsultationExistingConditionsUsingPUTParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationExistingConditionsUsingPUT(params: ConsultationEntityService.ConsultationExistingConditionsUsingPUTParams): Observable<ResourcesExistingCondition> {
    return this.consultationExistingConditionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationExistingConditionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationExistingConditionsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationExistingConditionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationExistingConditionsUsingPATCHResponse(params: ConsultationEntityService.ConsultationExistingConditionsUsingPATCHParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationExistingConditionsUsingPATCH(params: ConsultationEntityService.ConsultationExistingConditionsUsingPATCHParams): Observable<ResourcesExistingCondition> {
    return this.consultationExistingConditionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   *
   * @return OK
   */
  consultationExistingConditionsUsingGETResponse(params: ConsultationEntityService.ConsultationExistingConditionsUsingGETParams): Observable<HttpResponse<ResourceExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/existingConditions/${params.existingconditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingCondition = null;
        _body = _resp.body as ResourceExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   *
   * @return OK
   */
  consultationExistingConditionsUsingGET(params: ConsultationEntityService.ConsultationExistingConditionsUsingGETParams): Observable<ResourceExistingCondition> {
    return this.consultationExistingConditionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   */
  consultationExistingConditionsUsingDELETEResponse(params: ConsultationEntityService.ConsultationExistingConditionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/existingConditions/${params.existingconditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationExistingConditionsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   */
  consultationExistingConditionsUsingDELETE(params: ConsultationEntityService.ConsultationExistingConditionsUsingDELETEParams): Observable<void> {
    return this.consultationExistingConditionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationFollowupsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationFollowupsUsingGET_1(id: number): Observable<ResourcesFollowup> {
    return this.consultationFollowupsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationFollowupsUsingPOSTResponse(params: ConsultationEntityService.ConsultationFollowupsUsingPOSTParams): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationFollowupsUsingPOST(params: ConsultationEntityService.ConsultationFollowupsUsingPOSTParams): Observable<ResourcesFollowup> {
    return this.consultationFollowupsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationFollowupsUsingPUTResponse(params: ConsultationEntityService.ConsultationFollowupsUsingPUTParams): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationFollowupsUsingPUT(params: ConsultationEntityService.ConsultationFollowupsUsingPUTParams): Observable<ResourcesFollowup> {
    return this.consultationFollowupsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationFollowupsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationFollowupsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationFollowupsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationFollowupsUsingPATCHResponse(params: ConsultationEntityService.ConsultationFollowupsUsingPATCHParams): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationFollowupsUsingPATCH(params: ConsultationEntityService.ConsultationFollowupsUsingPATCHParams): Observable<ResourcesFollowup> {
    return this.consultationFollowupsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `followupId`: followupId
   *
   * @return OK
   */
  consultationFollowupsUsingGETResponse(params: ConsultationEntityService.ConsultationFollowupsUsingGETParams): Observable<HttpResponse<ResourceFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/followups/${params.followupId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFollowup = null;
        _body = _resp.body as ResourceFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFollowup>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `followupId`: followupId
   *
   * @return OK
   */
  consultationFollowupsUsingGET(params: ConsultationEntityService.ConsultationFollowupsUsingGETParams): Observable<ResourceFollowup> {
    return this.consultationFollowupsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `followupId`: followupId
   */
  consultationFollowupsUsingDELETEResponse(params: ConsultationEntityService.ConsultationFollowupsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/followups/${params.followupId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationFollowupsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `followupId`: followupId
   */
  consultationFollowupsUsingDELETE(params: ConsultationEntityService.ConsultationFollowupsUsingDELETEParams): Observable<void> {
    return this.consultationFollowupsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPatientUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPatientUsingGET(id: number): Observable<ResourcePatient> {
    return this.consultationPatientUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientUsingPOSTResponse(params: ConsultationEntityService.ConsultationPatientUsingPOSTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientUsingPOST(params: ConsultationEntityService.ConsultationPatientUsingPOSTParams): Observable<ResourcePatient> {
    return this.consultationPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientUsingPUTResponse(params: ConsultationEntityService.ConsultationPatientUsingPUTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientUsingPUT(params: ConsultationEntityService.ConsultationPatientUsingPUTParams): Observable<ResourcePatient> {
    return this.consultationPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationPatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationPatientUsingDELETE(id: number): Observable<void> {
    return this.consultationPatientUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientUsingPATCHResponse(params: ConsultationEntityService.ConsultationPatientUsingPATCHParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientUsingPATCH(params: ConsultationEntityService.ConsultationPatientUsingPATCHParams): Observable<ResourcePatient> {
    return this.consultationPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPatientHistoriesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPatientHistoriesUsingGET_1(id: number): Observable<ResourcesPatientHistory> {
    return this.consultationPatientHistoriesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientHistoriesUsingPOSTResponse(params: ConsultationEntityService.ConsultationPatientHistoriesUsingPOSTParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientHistoriesUsingPOST(params: ConsultationEntityService.ConsultationPatientHistoriesUsingPOSTParams): Observable<ResourcesPatientHistory> {
    return this.consultationPatientHistoriesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientHistoriesUsingPUTResponse(params: ConsultationEntityService.ConsultationPatientHistoriesUsingPUTParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientHistoriesUsingPUT(params: ConsultationEntityService.ConsultationPatientHistoriesUsingPUTParams): Observable<ResourcesPatientHistory> {
    return this.consultationPatientHistoriesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationPatientHistoriesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationPatientHistoriesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationPatientHistoriesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientHistoriesUsingPATCHResponse(params: ConsultationEntityService.ConsultationPatientHistoriesUsingPATCHParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientHistoriesUsingPATCH(params: ConsultationEntityService.ConsultationPatientHistoriesUsingPATCHParams): Observable<ResourcesPatientHistory> {
    return this.consultationPatientHistoriesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPatientHistoriesUsingGETResponse(params: ConsultationEntityService.ConsultationPatientHistoriesUsingGETParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientHistories/${params.patienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPatientHistoriesUsingGET(params: ConsultationEntityService.ConsultationPatientHistoriesUsingGETParams): Observable<ResourcePatientHistory> {
    return this.consultationPatientHistoriesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   */
  consultationPatientHistoriesUsingDELETEResponse(params: ConsultationEntityService.ConsultationPatientHistoriesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientHistories/${params.patienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   */
  consultationPatientHistoriesUsingDELETE(params: ConsultationEntityService.ConsultationPatientHistoriesUsingDELETEParams): Observable<void> {
    return this.consultationPatientHistoriesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPatientSymptomsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPatientSymptomsUsingGET_1(id: number): Observable<ResourcesPatientSymptom> {
    return this.consultationPatientSymptomsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientSymptomsUsingPOSTResponse(params: ConsultationEntityService.ConsultationPatientSymptomsUsingPOSTParams): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientSymptomsUsingPOST(params: ConsultationEntityService.ConsultationPatientSymptomsUsingPOSTParams): Observable<ResourcesPatientSymptom> {
    return this.consultationPatientSymptomsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientSymptomsUsingPUTResponse(params: ConsultationEntityService.ConsultationPatientSymptomsUsingPUTParams): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientSymptomsUsingPUT(params: ConsultationEntityService.ConsultationPatientSymptomsUsingPUTParams): Observable<ResourcesPatientSymptom> {
    return this.consultationPatientSymptomsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationPatientSymptomsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationPatientSymptomsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationPatientSymptomsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientSymptomsUsingPATCHResponse(params: ConsultationEntityService.ConsultationPatientSymptomsUsingPATCHParams): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientSymptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPatientSymptomsUsingPATCH(params: ConsultationEntityService.ConsultationPatientSymptomsUsingPATCHParams): Observable<ResourcesPatientSymptom> {
    return this.consultationPatientSymptomsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingGETParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPatientSymptomsUsingGETResponse(params: ConsultationEntityService.ConsultationPatientSymptomsUsingGETParams): Observable<HttpResponse<ResourcePatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientSymptoms/${params.patientsymptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientSymptom = null;
        _body = _resp.body as ResourcePatientSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingGETParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPatientSymptomsUsingGET(params: ConsultationEntityService.ConsultationPatientSymptomsUsingGETParams): Observable<ResourcePatientSymptom> {
    return this.consultationPatientSymptomsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingDELETEParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   */
  consultationPatientSymptomsUsingDELETEResponse(params: ConsultationEntityService.ConsultationPatientSymptomsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/patientSymptoms/${params.patientsymptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPatientSymptomsUsingDELETEParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   */
  consultationPatientSymptomsUsingDELETE(params: ConsultationEntityService.ConsultationPatientSymptomsUsingDELETEParams): Observable<void> {
    return this.consultationPatientSymptomsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPrescriptionByDatesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPrescriptionByDatesUsingGET_1(id: number): Observable<ResourcesPrescriptionByDate> {
    return this.consultationPrescriptionByDatesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingPOSTResponse(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingPOSTParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingPOST(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingPOSTParams): Observable<ResourcesPrescriptionByDate> {
    return this.consultationPrescriptionByDatesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingPUTResponse(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingPUTParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingPUT(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingPUTParams): Observable<ResourcesPrescriptionByDate> {
    return this.consultationPrescriptionByDatesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationPrescriptionByDatesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationPrescriptionByDatesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationPrescriptionByDatesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingPATCHResponse(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingPATCHParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingPATCH(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingPATCHParams): Observable<ResourcesPrescriptionByDate> {
    return this.consultationPrescriptionByDatesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingGETResponse(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingGETParams): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptionByDates/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPrescriptionByDatesUsingGET(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingGETParams): Observable<ResourcePrescriptionByDate> {
    return this.consultationPrescriptionByDatesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  consultationPrescriptionByDatesUsingDELETEResponse(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptionByDates/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionByDatesUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  consultationPrescriptionByDatesUsingDELETE(params: ConsultationEntityService.ConsultationPrescriptionByDatesUsingDELETEParams): Observable<void> {
    return this.consultationPrescriptionByDatesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPrescriptionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationPrescriptionsUsingGET_1(id: number): Observable<ResourcesPrescription> {
    return this.consultationPrescriptionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionsUsingPOSTResponse(params: ConsultationEntityService.ConsultationPrescriptionsUsingPOSTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionsUsingPOST(params: ConsultationEntityService.ConsultationPrescriptionsUsingPOSTParams): Observable<ResourcesPrescription> {
    return this.consultationPrescriptionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionsUsingPUTResponse(params: ConsultationEntityService.ConsultationPrescriptionsUsingPUTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionsUsingPUT(params: ConsultationEntityService.ConsultationPrescriptionsUsingPUTParams): Observable<ResourcesPrescription> {
    return this.consultationPrescriptionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationPrescriptionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationPrescriptionsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationPrescriptionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionsUsingPATCHResponse(params: ConsultationEntityService.ConsultationPrescriptionsUsingPATCHParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationPrescriptionsUsingPATCH(params: ConsultationEntityService.ConsultationPrescriptionsUsingPATCHParams): Observable<ResourcesPrescription> {
    return this.consultationPrescriptionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPrescriptionsUsingGETResponse(params: ConsultationEntityService.ConsultationPrescriptionsUsingGETParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationPrescriptionsUsingGET(params: ConsultationEntityService.ConsultationPrescriptionsUsingGETParams): Observable<ResourcePrescription> {
    return this.consultationPrescriptionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  consultationPrescriptionsUsingDELETEResponse(params: ConsultationEntityService.ConsultationPrescriptionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationPrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  consultationPrescriptionsUsingDELETE(params: ConsultationEntityService.ConsultationPrescriptionsUsingDELETEParams): Observable<void> {
    return this.consultationPrescriptionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationProceduresUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationProceduresUsingGET_1(id: number): Observable<ResourcesMedicalProcedure> {
    return this.consultationProceduresUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationProceduresUsingPOSTResponse(params: ConsultationEntityService.ConsultationProceduresUsingPOSTParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationProceduresUsingPOST(params: ConsultationEntityService.ConsultationProceduresUsingPOSTParams): Observable<ResourcesMedicalProcedure> {
    return this.consultationProceduresUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationProceduresUsingPUTResponse(params: ConsultationEntityService.ConsultationProceduresUsingPUTParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationProceduresUsingPUT(params: ConsultationEntityService.ConsultationProceduresUsingPUTParams): Observable<ResourcesMedicalProcedure> {
    return this.consultationProceduresUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationProceduresUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationProceduresUsingDELETE_1(id: number): Observable<void> {
    return this.consultationProceduresUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationProceduresUsingPATCHResponse(params: ConsultationEntityService.ConsultationProceduresUsingPATCHParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationProceduresUsingPATCH(params: ConsultationEntityService.ConsultationProceduresUsingPATCHParams): Observable<ResourcesMedicalProcedure> {
    return this.consultationProceduresUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingGETParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationProceduresUsingGETResponse(params: ConsultationEntityService.ConsultationProceduresUsingGETParams): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/procedures/${params.medicalprocedureId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingGETParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationProceduresUsingGET(params: ConsultationEntityService.ConsultationProceduresUsingGETParams): Observable<ResourceMedicalProcedure> {
    return this.consultationProceduresUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingDELETEParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   */
  consultationProceduresUsingDELETEResponse(params: ConsultationEntityService.ConsultationProceduresUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/procedures/${params.medicalprocedureId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationProceduresUsingDELETEParams` containing the following parameters:
   *
   * - `medicalprocedureId`: medicalprocedureId
   *
   * - `id`: id
   */
  consultationProceduresUsingDELETE(params: ConsultationEntityService.ConsultationProceduresUsingDELETEParams): Observable<void> {
    return this.consultationProceduresUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationReferralsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesReferral = null;
        _body = _resp.body as ResourcesReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesReferral>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationReferralsUsingGET_1(id: number): Observable<ResourcesReferral> {
    return this.consultationReferralsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationReferralsUsingPOSTResponse(params: ConsultationEntityService.ConsultationReferralsUsingPOSTParams): Observable<HttpResponse<ResourcesReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesReferral = null;
        _body = _resp.body as ResourcesReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesReferral>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationReferralsUsingPOST(params: ConsultationEntityService.ConsultationReferralsUsingPOSTParams): Observable<ResourcesReferral> {
    return this.consultationReferralsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationReferralsUsingPUTResponse(params: ConsultationEntityService.ConsultationReferralsUsingPUTParams): Observable<HttpResponse<ResourcesReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesReferral = null;
        _body = _resp.body as ResourcesReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesReferral>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationReferralsUsingPUT(params: ConsultationEntityService.ConsultationReferralsUsingPUTParams): Observable<ResourcesReferral> {
    return this.consultationReferralsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationReferralsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationReferralsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationReferralsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationReferralsUsingPATCHResponse(params: ConsultationEntityService.ConsultationReferralsUsingPATCHParams): Observable<HttpResponse<ResourcesReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesReferral = null;
        _body = _resp.body as ResourcesReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesReferral>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationReferralsUsingPATCH(params: ConsultationEntityService.ConsultationReferralsUsingPATCHParams): Observable<ResourcesReferral> {
    return this.consultationReferralsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingGETParams` containing the following parameters:
   *
   * - `referralId`: referralId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationReferralsUsingGETResponse(params: ConsultationEntityService.ConsultationReferralsUsingGETParams): Observable<HttpResponse<ResourceReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/referrals/${params.referralId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceReferral = null;
        _body = _resp.body as ResourceReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceReferral>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingGETParams` containing the following parameters:
   *
   * - `referralId`: referralId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationReferralsUsingGET(params: ConsultationEntityService.ConsultationReferralsUsingGETParams): Observable<ResourceReferral> {
    return this.consultationReferralsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingDELETEParams` containing the following parameters:
   *
   * - `referralId`: referralId
   *
   * - `id`: id
   */
  consultationReferralsUsingDELETEResponse(params: ConsultationEntityService.ConsultationReferralsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/referrals/${params.referralId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationReferralsUsingDELETEParams` containing the following parameters:
   *
   * - `referralId`: referralId
   *
   * - `id`: id
   */
  consultationReferralsUsingDELETE(params: ConsultationEntityService.ConsultationReferralsUsingDELETEParams): Observable<void> {
    return this.consultationReferralsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationScribblesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationScribblesUsingGET_1(id: number): Observable<ResourcesScribble> {
    return this.consultationScribblesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationScribblesUsingPOSTResponse(params: ConsultationEntityService.ConsultationScribblesUsingPOSTParams): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationScribblesUsingPOST(params: ConsultationEntityService.ConsultationScribblesUsingPOSTParams): Observable<ResourcesScribble> {
    return this.consultationScribblesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationScribblesUsingPUTResponse(params: ConsultationEntityService.ConsultationScribblesUsingPUTParams): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationScribblesUsingPUT(params: ConsultationEntityService.ConsultationScribblesUsingPUTParams): Observable<ResourcesScribble> {
    return this.consultationScribblesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationScribblesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationScribblesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationScribblesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationScribblesUsingPATCHResponse(params: ConsultationEntityService.ConsultationScribblesUsingPATCHParams): Observable<HttpResponse<ResourcesScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/scribbles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesScribble = null;
        _body = _resp.body as ResourcesScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesScribble>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationScribblesUsingPATCH(params: ConsultationEntityService.ConsultationScribblesUsingPATCHParams): Observable<ResourcesScribble> {
    return this.consultationScribblesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingGETParams` containing the following parameters:
   *
   * - `scribbleId`: scribbleId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationScribblesUsingGETResponse(params: ConsultationEntityService.ConsultationScribblesUsingGETParams): Observable<HttpResponse<ResourceScribble>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/scribbles/${params.scribbleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceScribble = null;
        _body = _resp.body as ResourceScribble;
        return _resp.clone({body: _body}) as HttpResponse<ResourceScribble>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingGETParams` containing the following parameters:
   *
   * - `scribbleId`: scribbleId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationScribblesUsingGET(params: ConsultationEntityService.ConsultationScribblesUsingGETParams): Observable<ResourceScribble> {
    return this.consultationScribblesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingDELETEParams` containing the following parameters:
   *
   * - `scribbleId`: scribbleId
   *
   * - `id`: id
   */
  consultationScribblesUsingDELETEResponse(params: ConsultationEntityService.ConsultationScribblesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/scribbles/${params.scribbleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationScribblesUsingDELETEParams` containing the following parameters:
   *
   * - `scribbleId`: scribbleId
   *
   * - `id`: id
   */
  consultationScribblesUsingDELETE(params: ConsultationEntityService.ConsultationScribblesUsingDELETEParams): Observable<void> {
    return this.consultationScribblesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationSymptomsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationSymptomsUsingGET_1(id: number): Observable<ResourcesSymptom> {
    return this.consultationSymptomsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationSymptomsUsingPOSTResponse(params: ConsultationEntityService.ConsultationSymptomsUsingPOSTParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationSymptomsUsingPOST(params: ConsultationEntityService.ConsultationSymptomsUsingPOSTParams): Observable<ResourcesSymptom> {
    return this.consultationSymptomsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationSymptomsUsingPUTResponse(params: ConsultationEntityService.ConsultationSymptomsUsingPUTParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationSymptomsUsingPUT(params: ConsultationEntityService.ConsultationSymptomsUsingPUTParams): Observable<ResourcesSymptom> {
    return this.consultationSymptomsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationSymptomsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationSymptomsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationSymptomsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationSymptomsUsingPATCHResponse(params: ConsultationEntityService.ConsultationSymptomsUsingPATCHParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationSymptomsUsingPATCH(params: ConsultationEntityService.ConsultationSymptomsUsingPATCHParams): Observable<ResourcesSymptom> {
    return this.consultationSymptomsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingGETParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationSymptomsUsingGETResponse(params: ConsultationEntityService.ConsultationSymptomsUsingGETParams): Observable<HttpResponse<ResourceSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/symptoms/${params.symptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptom = null;
        _body = _resp.body as ResourceSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptom>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingGETParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationSymptomsUsingGET(params: ConsultationEntityService.ConsultationSymptomsUsingGETParams): Observable<ResourceSymptom> {
    return this.consultationSymptomsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingDELETEParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   */
  consultationSymptomsUsingDELETEResponse(params: ConsultationEntityService.ConsultationSymptomsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/symptoms/${params.symptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationSymptomsUsingDELETEParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   */
  consultationSymptomsUsingDELETE(params: ConsultationEntityService.ConsultationSymptomsUsingDELETEParams): Observable<void> {
    return this.consultationSymptomsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationTakenMedicinesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTakenMedicine = null;
        _body = _resp.body as ResourcesTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTakenMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationTakenMedicinesUsingGET_1(id: number): Observable<ResourcesTakenMedicine> {
    return this.consultationTakenMedicinesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTakenMedicinesUsingPOSTResponse(params: ConsultationEntityService.ConsultationTakenMedicinesUsingPOSTParams): Observable<HttpResponse<ResourcesTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTakenMedicine = null;
        _body = _resp.body as ResourcesTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTakenMedicinesUsingPOST(params: ConsultationEntityService.ConsultationTakenMedicinesUsingPOSTParams): Observable<ResourcesTakenMedicine> {
    return this.consultationTakenMedicinesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTakenMedicinesUsingPUTResponse(params: ConsultationEntityService.ConsultationTakenMedicinesUsingPUTParams): Observable<HttpResponse<ResourcesTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTakenMedicine = null;
        _body = _resp.body as ResourcesTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTakenMedicinesUsingPUT(params: ConsultationEntityService.ConsultationTakenMedicinesUsingPUTParams): Observable<ResourcesTakenMedicine> {
    return this.consultationTakenMedicinesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationTakenMedicinesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationTakenMedicinesUsingDELETE_1(id: number): Observable<void> {
    return this.consultationTakenMedicinesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTakenMedicinesUsingPATCHResponse(params: ConsultationEntityService.ConsultationTakenMedicinesUsingPATCHParams): Observable<HttpResponse<ResourcesTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTakenMedicine = null;
        _body = _resp.body as ResourcesTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTakenMedicinesUsingPATCH(params: ConsultationEntityService.ConsultationTakenMedicinesUsingPATCHParams): Observable<ResourcesTakenMedicine> {
    return this.consultationTakenMedicinesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingGETParams` containing the following parameters:
   *
   * - `takenmedicineId`: takenmedicineId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationTakenMedicinesUsingGETResponse(params: ConsultationEntityService.ConsultationTakenMedicinesUsingGETParams): Observable<HttpResponse<ResourceTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/takenMedicines/${params.takenmedicineId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTakenMedicine = null;
        _body = _resp.body as ResourceTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingGETParams` containing the following parameters:
   *
   * - `takenmedicineId`: takenmedicineId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationTakenMedicinesUsingGET(params: ConsultationEntityService.ConsultationTakenMedicinesUsingGETParams): Observable<ResourceTakenMedicine> {
    return this.consultationTakenMedicinesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingDELETEParams` containing the following parameters:
   *
   * - `takenmedicineId`: takenmedicineId
   *
   * - `id`: id
   */
  consultationTakenMedicinesUsingDELETEResponse(params: ConsultationEntityService.ConsultationTakenMedicinesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/takenMedicines/${params.takenmedicineId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTakenMedicinesUsingDELETEParams` containing the following parameters:
   *
   * - `takenmedicineId`: takenmedicineId
   *
   * - `id`: id
   */
  consultationTakenMedicinesUsingDELETE(params: ConsultationEntityService.ConsultationTakenMedicinesUsingDELETEParams): Observable<void> {
    return this.consultationTakenMedicinesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationTimeSlotUsingGETResponse(id: number): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/timeSlot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationTimeSlotUsingGET(id: number): Observable<ResourceTimeSlot> {
    return this.consultationTimeSlotUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTimeSlotUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTimeSlotUsingPOSTResponse(params: ConsultationEntityService.ConsultationTimeSlotUsingPOSTParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/timeSlot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTimeSlotUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTimeSlotUsingPOST(params: ConsultationEntityService.ConsultationTimeSlotUsingPOSTParams): Observable<ResourceTimeSlot> {
    return this.consultationTimeSlotUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTimeSlotUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTimeSlotUsingPUTResponse(params: ConsultationEntityService.ConsultationTimeSlotUsingPUTParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/timeSlot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTimeSlotUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTimeSlotUsingPUT(params: ConsultationEntityService.ConsultationTimeSlotUsingPUTParams): Observable<ResourceTimeSlot> {
    return this.consultationTimeSlotUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationTimeSlotUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/timeSlot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationTimeSlotUsingDELETE(id: number): Observable<void> {
    return this.consultationTimeSlotUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTimeSlotUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTimeSlotUsingPATCHResponse(params: ConsultationEntityService.ConsultationTimeSlotUsingPATCHParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/timeSlot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationTimeSlotUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationTimeSlotUsingPATCH(params: ConsultationEntityService.ConsultationTimeSlotUsingPATCHParams): Observable<ResourceTimeSlot> {
    return this.consultationTimeSlotUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationVisitTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/visitType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationVisitTypeUsingGET(id: number): Observable<ResourceVisitType> {
    return this.consultationVisitTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVisitTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVisitTypeUsingPOSTResponse(params: ConsultationEntityService.ConsultationVisitTypeUsingPOSTParams): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/visitType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVisitTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVisitTypeUsingPOST(params: ConsultationEntityService.ConsultationVisitTypeUsingPOSTParams): Observable<ResourceVisitType> {
    return this.consultationVisitTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVisitTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVisitTypeUsingPUTResponse(params: ConsultationEntityService.ConsultationVisitTypeUsingPUTParams): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/visitType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVisitTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVisitTypeUsingPUT(params: ConsultationEntityService.ConsultationVisitTypeUsingPUTParams): Observable<ResourceVisitType> {
    return this.consultationVisitTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationVisitTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/visitType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationVisitTypeUsingDELETE(id: number): Observable<void> {
    return this.consultationVisitTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVisitTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVisitTypeUsingPATCHResponse(params: ConsultationEntityService.ConsultationVisitTypeUsingPATCHParams): Observable<HttpResponse<ResourceVisitType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/visitType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVisitType = null;
        _body = _resp.body as ResourceVisitType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVisitType>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVisitTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVisitTypeUsingPATCH(params: ConsultationEntityService.ConsultationVisitTypeUsingPATCHParams): Observable<ResourceVisitType> {
    return this.consultationVisitTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationVitalsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${id}/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  consultationVitalsUsingGET_1(id: number): Observable<ResourcesVital> {
    return this.consultationVitalsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVitalsUsingPOSTResponse(params: ConsultationEntityService.ConsultationVitalsUsingPOSTParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVitalsUsingPOST(params: ConsultationEntityService.ConsultationVitalsUsingPOSTParams): Observable<ResourcesVital> {
    return this.consultationVitalsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVitalsUsingPUTResponse(params: ConsultationEntityService.ConsultationVitalsUsingPUTParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVitalsUsingPUT(params: ConsultationEntityService.ConsultationVitalsUsingPUTParams): Observable<ResourcesVital> {
    return this.consultationVitalsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  consultationVitalsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${id}/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  consultationVitalsUsingDELETE_1(id: number): Observable<void> {
    return this.consultationVitalsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVitalsUsingPATCHResponse(params: ConsultationEntityService.ConsultationVitalsUsingPATCHParams): Observable<HttpResponse<ResourcesVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/vitals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesVital = null;
        _body = _resp.body as ResourcesVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesVital>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  consultationVitalsUsingPATCH(params: ConsultationEntityService.ConsultationVitalsUsingPATCHParams): Observable<ResourcesVital> {
    return this.consultationVitalsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingGETParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationVitalsUsingGETResponse(params: ConsultationEntityService.ConsultationVitalsUsingGETParams): Observable<HttpResponse<ResourceVital>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/vitals/${params.vitalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVital = null;
        _body = _resp.body as ResourceVital;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVital>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingGETParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   *
   * @return OK
   */
  consultationVitalsUsingGET(params: ConsultationEntityService.ConsultationVitalsUsingGETParams): Observable<ResourceVital> {
    return this.consultationVitalsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingDELETEParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   */
  consultationVitalsUsingDELETEResponse(params: ConsultationEntityService.ConsultationVitalsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/consultations/${params.id}/vitals/${params.vitalId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ConsultationEntityService.ConsultationVitalsUsingDELETEParams` containing the following parameters:
   *
   * - `vitalId`: vitalId
   *
   * - `id`: id
   */
  consultationVitalsUsingDELETE(params: ConsultationEntityService.ConsultationVitalsUsingDELETEParams): Observable<void> {
    return this.consultationVitalsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ConsultationEntityService {

  /**
   * Parameters for findAllConsultationUsingGET
   */
  export interface FindAllConsultationUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for countByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGET
   */
  export interface CountByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
  }

  /**
   * Parameters for countDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGET
   */
  export interface CountDistinctByDoctorInClinicIdAndStatusAndTimeSlotIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams {

    /**
     * timeslotId
     */
    timeslotId?: number;

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByAppointmentTimeLessThanAndStatusConsultationUsingGET
   */
  export interface FindByAppointmentTimeLessThanAndStatusConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdAndAppointmentTimeConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdAndAppointmentTimeConsultationUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * ids
     */
    ids?: number;
  }

  /**
   * Parameters for findByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdAndAppointmentTimeBetweenConsultationUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * ids
     */
    ids?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdAndPatientPhoneContainingConsultationUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;

    /**
     * doctorInClinic
     */
    doctorInClinic?: number;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndAppointmentTimeBetweenConsultationUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndPatientIdConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndPatientIdConsultationUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

    /**
   * Parameters for findByDoctorInClinicIdInAndPatientIdConsultationUsingGET
   */
  export interface FindByDoctorIdAndPatientIdConsultationInfoUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;

    /**
     * doctorinclinic
     */
    doctorId?: number;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndPatientIdInAndStatusConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * patientId
     */
    patientId?: number;

    /**
     * doctorinclinic
     */
    doctorinclinic?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndPatientNameContainingConsultationUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * id
     */
    id?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndPatientPhoneContainingConsultationUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;

    /**
     * id
     */
    id?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinics
     */
    doctorInClinics?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinics
     */
    doctorInClinics?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusAndAppointmentTimeGreaterThanEqualConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * from
     */
    from?: string;

    /**
     * doctorinclinicid
     */
    doctorinclinicid?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDescConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * isTranscribed
     */
    isTranscribed?: boolean;

    /**
     * doctorInClinics
     */
    doctorInClinics?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusInConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusInConsultationUsingGETParams {

    /**
     * status
     */
    status?: Array<'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE'>;

    /**
     * id
     */
    id?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusInAndAppointmentTimeBetweenOrderByPriorityQueuesAscConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesAscConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinics
     */
    doctorInClinics?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInAndStatusOrderByPriorityQueuesDescConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinics
     */
    doctorInClinics?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInOrderByIdDescConsultationUsingGET
   */
  export interface FindByDoctorInClinicIdInOrderByIdDescConsultationUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinic
     */
    doctorInClinic?: Array<number>;
  }

  /**
   * Parameters for findByIdAndPatientIdConsultationUsingGET
   */
  export interface FindByIdAndPatientIdConsultationUsingGETParams {

    /**
     * patientId
     */
    patientId?: number;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGET
   */
  export interface FindByIsTranscribedTrueAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGET
   */
  export interface FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinicIds
     */
    doctorInClinicIds?: Array<number>;
  }

  /**
   * Parameters for findByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGET
   */
  export interface FindByIsTranscribedTrueAndDoctorInClinicIdInAndAppointmentTimeGreaterThanEqualOrderByAppointmentTimeDescConsultationUsingGETParams {

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinicIds
     */
    doctorInClinicIds?: Array<number>;
  }

  /**
   * Parameters for findByPatientCodeAndDoctorInClinicCodeInConsultationUsingGET
   */
  export interface FindByPatientCodeAndDoctorInClinicCodeInConsultationUsingGETParams {

    /**
     * patientCode
     */
    patientCode?: string;

    /**
     * doctorinclinicCode
     */
    doctorinclinicCode?: string;
  }

  /**
   * Parameters for findByPatientIdConsultationUsingGET
   */
  export interface FindByPatientIdConsultationUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGET
   */
  export interface FindByPatientIdAndDoctorInClinicIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * dicId
     */
    dicId?: number;
  }

  /**
   * Parameters for findByPatientIdAndDoctorInClinicIdInConsultationUsingGET
   */
  export interface FindByPatientIdAndDoctorInClinicIdInConsultationUsingGETParams {

    /**
     * patientId
     */
    patientId?: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId?: number;
  }

  /**
   * Parameters for findByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGET
   */
  export interface FindByPatientIdAndDoctorInClinicIdInAndIsVisitedConsultationUsingGETParams {

    /**
     * patientId
     */
    patientId?: number;

    /**
     * isVisited
     */
    isVisited?: boolean;

    /**
     * dicId
     */
    dicId?: number;
  }

  /**
   * Parameters for findByPatientIdAndLastModifiedBetweenConsultationUsingGET
   */
  export interface FindByPatientIdAndLastModifiedBetweenConsultationUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientId
     */
    patientId?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByPatientIdAndStatusConsultationUsingGET
   */
  export interface FindByPatientIdAndStatusConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * patientId
     */
    patientId?: number;
  }

  /**
   * Parameters for findByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGET
   */
  export interface FindByPatientIdAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGET
   */
  export interface FindByPatientIdInAndStatusAndAppointmentTimeBetweenConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGET
   */
  export interface FindByStatusAndDoctorInClinicIdAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanOrderByPriorityQueuesAscConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
  }

  /**
   * Parameters for findByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGET
   */
  export interface FindByStatusAndDoctorInClinicIdAndPriorityQueuesGreaterThanAndAppointmentTimeGreaterThanEqualAndAppointmentTimeLessThanConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * priorityQueues
     */
    priorityQueues?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
  }

  /**
   * Parameters for findByStatusInAndDoctorInClinicIdConsultationUsingGET
   */
  export interface FindByStatusInAndDoctorInClinicIdConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByStatusInAndDoctorInClinicIdInConsultationUsingGET
   */
  export interface FindByStatusInAndDoctorInClinicIdInConsultationUsingGETParams {

    /**
     * status
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * id
     */
    id?: Array<number>;
  }

  /**
   * Parameters for findMaxPriorityQueueNumberConsultationUsingGET
   */
  export interface FindMaxPriorityQueueNumberConsultationUsingGETParams {

    /**
     * statusValue
     */
    statusValue?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';

    /**
     * dic
     */
    dic?: number;
  }

  /**
   * Parameters for saveConsultationUsingPUT
   */
  export interface SaveConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Consultation;
  }

  /**
   * Parameters for saveConsultationUsingPATCH
   */
  export interface SaveConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Consultation;
  }  /**
   * Parameters for saveConsultationSatusUsingPATCH
   */
  export interface SaveConsultationStatusUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    status?: 'OPEN' | 'CHECKED_IN' | 'NO_SHOW' | 'AWAITING' | 'VISITED' | 'TRANSCRIBING' | 'TRANSCRIBED' | 'RESCHEDULED' | 'INVOICED' | 'CANCELLED' | 'DONE';
  }




  /**
   * Parameters for consultationAddressUsingPOST
   */
  export interface ConsultationAddressUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationAddressUsingPUT
   */
  export interface ConsultationAddressUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationAddressUsingPATCH
   */
  export interface ConsultationAddressUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationAdvicesUsingPOST
   */
  export interface ConsultationAdvicesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationAdvicesUsingPUT
   */
  export interface ConsultationAdvicesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationAdvicesUsingPATCH
   */
  export interface ConsultationAdvicesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationAdvicesUsingGET
   */
  export interface ConsultationAdvicesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * adviceId
     */
    adviceId: string;
  }

  /**
   * Parameters for consultationAdvicesUsingDELETE
   */
  export interface ConsultationAdvicesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * adviceId
     */
    adviceId: string;
  }

  /**
   * Parameters for consultationAllergiesUsingPOST
   */
  export interface ConsultationAllergiesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationAllergiesUsingPUT
   */
  export interface ConsultationAllergiesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationAllergiesUsingPATCH
   */
  export interface ConsultationAllergiesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationAllergiesUsingGET
   */
  export interface ConsultationAllergiesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * allergyId
     */
    allergyId: string;
  }

  /**
   * Parameters for consultationAllergiesUsingDELETE
   */
  export interface ConsultationAllergiesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * allergyId
     */
    allergyId: string;
  }

  /**
   * Parameters for consultationConsultationHistoriesUsingPOST
   */
  export interface ConsultationConsultationHistoriesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationConsultationHistoriesUsingPUT
   */
  export interface ConsultationConsultationHistoriesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationConsultationHistoriesUsingPATCH
   */
  export interface ConsultationConsultationHistoriesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationConsultationHistoriesUsingGET
   */
  export interface ConsultationConsultationHistoriesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * consultationhistoryId
     */
    consultationhistoryId: string;
  }

  /**
   * Parameters for consultationConsultationHistoriesUsingDELETE
   */
  export interface ConsultationConsultationHistoriesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * consultationhistoryId
     */
    consultationhistoryId: string;
  }

  /**
   * Parameters for consultationDiagnosesUsingPOST
   */
  export interface ConsultationDiagnosesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationDiagnosesUsingPUT
   */
  export interface ConsultationDiagnosesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationDiagnosesUsingPATCH
   */
  export interface ConsultationDiagnosesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationDiagnosesUsingGET
   */
  export interface ConsultationDiagnosesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosisId
     */
    diagnosisId: string;
  }

  /**
   * Parameters for consultationDiagnosesUsingDELETE
   */
  export interface ConsultationDiagnosesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosisId
     */
    diagnosisId: string;
  }

  /**
   * Parameters for consultationDiagnosticsUsingPOST
   */
  export interface ConsultationDiagnosticsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationDiagnosticsUsingPUT
   */
  export interface ConsultationDiagnosticsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationDiagnosticsUsingPATCH
   */
  export interface ConsultationDiagnosticsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationDiagnosticsUsingGET
   */
  export interface ConsultationDiagnosticsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticId
     */
    diagnosticId: string;
  }

  /**
   * Parameters for consultationDiagnosticsUsingDELETE
   */
  export interface ConsultationDiagnosticsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticId
     */
    diagnosticId: string;
  }

  /**
   * Parameters for consultationDoctorInClinicUsingPOST
   */
  export interface ConsultationDoctorInClinicUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationDoctorInClinicUsingPUT
   */
  export interface ConsultationDoctorInClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationDoctorInClinicUsingPATCH
   */
  export interface ConsultationDoctorInClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationExistingConditionsUsingPOST
   */
  export interface ConsultationExistingConditionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationExistingConditionsUsingPUT
   */
  export interface ConsultationExistingConditionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationExistingConditionsUsingPATCH
   */
  export interface ConsultationExistingConditionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationExistingConditionsUsingGET
   */
  export interface ConsultationExistingConditionsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * existingconditionId
     */
    existingconditionId: string;
  }

  /**
   * Parameters for consultationExistingConditionsUsingDELETE
   */
  export interface ConsultationExistingConditionsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * existingconditionId
     */
    existingconditionId: string;
  }

  /**
   * Parameters for consultationFollowupsUsingPOST
   */
  export interface ConsultationFollowupsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationFollowupsUsingPUT
   */
  export interface ConsultationFollowupsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationFollowupsUsingPATCH
   */
  export interface ConsultationFollowupsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationFollowupsUsingGET
   */
  export interface ConsultationFollowupsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * followupId
     */
    followupId: string;
  }

  /**
   * Parameters for consultationFollowupsUsingDELETE
   */
  export interface ConsultationFollowupsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * followupId
     */
    followupId: string;
  }

  /**
   * Parameters for consultationPatientUsingPOST
   */
  export interface ConsultationPatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationPatientUsingPUT
   */
  export interface ConsultationPatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationPatientUsingPATCH
   */
  export interface ConsultationPatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationPatientHistoriesUsingPOST
   */
  export interface ConsultationPatientHistoriesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPatientHistoriesUsingPUT
   */
  export interface ConsultationPatientHistoriesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPatientHistoriesUsingPATCH
   */
  export interface ConsultationPatientHistoriesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPatientHistoriesUsingGET
   */
  export interface ConsultationPatientHistoriesUsingGETParams {

    /**
     * patienthistoryId
     */
    patienthistoryId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPatientHistoriesUsingDELETE
   */
  export interface ConsultationPatientHistoriesUsingDELETEParams {

    /**
     * patienthistoryId
     */
    patienthistoryId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPatientSymptomsUsingPOST
   */
  export interface ConsultationPatientSymptomsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPatientSymptomsUsingPUT
   */
  export interface ConsultationPatientSymptomsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPatientSymptomsUsingPATCH
   */
  export interface ConsultationPatientSymptomsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPatientSymptomsUsingGET
   */
  export interface ConsultationPatientSymptomsUsingGETParams {

    /**
     * patientsymptomId
     */
    patientsymptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPatientSymptomsUsingDELETE
   */
  export interface ConsultationPatientSymptomsUsingDELETEParams {

    /**
     * patientsymptomId
     */
    patientsymptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPrescriptionByDatesUsingPOST
   */
  export interface ConsultationPrescriptionByDatesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPrescriptionByDatesUsingPUT
   */
  export interface ConsultationPrescriptionByDatesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPrescriptionByDatesUsingPATCH
   */
  export interface ConsultationPrescriptionByDatesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPrescriptionByDatesUsingGET
   */
  export interface ConsultationPrescriptionByDatesUsingGETParams {

    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPrescriptionByDatesUsingDELETE
   */
  export interface ConsultationPrescriptionByDatesUsingDELETEParams {

    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPrescriptionsUsingPOST
   */
  export interface ConsultationPrescriptionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPrescriptionsUsingPUT
   */
  export interface ConsultationPrescriptionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPrescriptionsUsingPATCH
   */
  export interface ConsultationPrescriptionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationPrescriptionsUsingGET
   */
  export interface ConsultationPrescriptionsUsingGETParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationPrescriptionsUsingDELETE
   */
  export interface ConsultationPrescriptionsUsingDELETEParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationProceduresUsingPOST
   */
  export interface ConsultationProceduresUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationProceduresUsingPUT
   */
  export interface ConsultationProceduresUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationProceduresUsingPATCH
   */
  export interface ConsultationProceduresUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationProceduresUsingGET
   */
  export interface ConsultationProceduresUsingGETParams {

    /**
     * medicalprocedureId
     */
    medicalprocedureId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationProceduresUsingDELETE
   */
  export interface ConsultationProceduresUsingDELETEParams {

    /**
     * medicalprocedureId
     */
    medicalprocedureId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationReferralsUsingPOST
   */
  export interface ConsultationReferralsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationReferralsUsingPUT
   */
  export interface ConsultationReferralsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationReferralsUsingPATCH
   */
  export interface ConsultationReferralsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationReferralsUsingGET
   */
  export interface ConsultationReferralsUsingGETParams {

    /**
     * referralId
     */
    referralId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationReferralsUsingDELETE
   */
  export interface ConsultationReferralsUsingDELETEParams {

    /**
     * referralId
     */
    referralId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationScribblesUsingPOST
   */
  export interface ConsultationScribblesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationScribblesUsingPUT
   */
  export interface ConsultationScribblesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationScribblesUsingPATCH
   */
  export interface ConsultationScribblesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationScribblesUsingGET
   */
  export interface ConsultationScribblesUsingGETParams {

    /**
     * scribbleId
     */
    scribbleId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationScribblesUsingDELETE
   */
  export interface ConsultationScribblesUsingDELETEParams {

    /**
     * scribbleId
     */
    scribbleId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationSymptomsUsingPOST
   */
  export interface ConsultationSymptomsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationSymptomsUsingPUT
   */
  export interface ConsultationSymptomsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationSymptomsUsingPATCH
   */
  export interface ConsultationSymptomsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationSymptomsUsingGET
   */
  export interface ConsultationSymptomsUsingGETParams {

    /**
     * symptomId
     */
    symptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationSymptomsUsingDELETE
   */
  export interface ConsultationSymptomsUsingDELETEParams {

    /**
     * symptomId
     */
    symptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationTakenMedicinesUsingPOST
   */
  export interface ConsultationTakenMedicinesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationTakenMedicinesUsingPUT
   */
  export interface ConsultationTakenMedicinesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationTakenMedicinesUsingPATCH
   */
  export interface ConsultationTakenMedicinesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationTakenMedicinesUsingGET
   */
  export interface ConsultationTakenMedicinesUsingGETParams {

    /**
     * takenmedicineId
     */
    takenmedicineId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationTakenMedicinesUsingDELETE
   */
  export interface ConsultationTakenMedicinesUsingDELETEParams {

    /**
     * takenmedicineId
     */
    takenmedicineId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationTimeSlotUsingPOST
   */
  export interface ConsultationTimeSlotUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationTimeSlotUsingPUT
   */
  export interface ConsultationTimeSlotUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationTimeSlotUsingPATCH
   */
  export interface ConsultationTimeSlotUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationVisitTypeUsingPOST
   */
  export interface ConsultationVisitTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationVisitTypeUsingPUT
   */
  export interface ConsultationVisitTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationVisitTypeUsingPATCH
   */
  export interface ConsultationVisitTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for consultationVitalsUsingPOST
   */
  export interface ConsultationVitalsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationVitalsUsingPUT
   */
  export interface ConsultationVitalsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationVitalsUsingPATCH
   */
  export interface ConsultationVitalsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for consultationVitalsUsingGET
   */
  export interface ConsultationVitalsUsingGETParams {

    /**
     * vitalId
     */
    vitalId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for consultationVitalsUsingDELETE
   */
  export interface ConsultationVitalsUsingDELETEParams {

    /**
     * vitalId
     */
    vitalId: string;

    /**
     * id
     */
    id: number;
  }
}

export { ConsultationEntityService }
