import { Component, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ScanBarcodeDialogComponent } from '../dialog/scan-barcode-dialog/scan-barcode-dialog.component';

@Component({
  selector: 'app-confirmation-page',
  templateUrl: './confirmation-page.component.html',
  styleUrls: ['./confirmation-page.component.scss'],
  outputs: ['addAnotherPatient', 'closeWithConfirm','printPdfOfAppointment','skipBarcode'],
  inputs: ['confirmationPageInfo', 'usedBy']
})
export class ConfirmationPageComponent implements OnInit {

  addAnotherPatient = new EventEmitter();
  printPdfOfAppointment = new EventEmitter();
  skipBarcode = new EventEmitter();
  confirmationPageInfo;
  usedBy;
  closeWithConfirm = new EventEmitter();
  isuncover:boolean = false;
  constructor() { }

  ngOnInit() { 
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }   
  }

}
