/* tslint:disable */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/login/service/authentication.service';

/**
 * Contains global configuration for API services
 */
@Injectable()
export class ApiConfiguration {
  rootUrl: string;
  constructor(private AuthenticationService: AuthenticationService) {
    this.rootUrl = environment.orderBaseAPI;
  }
}
