/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPatientHistory } from '../models/resources-patient-history-';
import { ResourcePatientHistory } from '../models/resource-patient-history-';
import { PatientHistory } from '../models/patient-history';
import { ResourceCommentOnDocument } from '../models/resource-comment-on-document-';
import { ResourcesComment } from '../models/resources-comment-';
import { ResourceComment } from '../models/resource-comment-';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';
import { ResourcesDiagnosticAndPatientHistory } from '../models/resources-diagnostic-and-patient-history-';
import { ResourceDiagnosticAndPatientHistory } from '../models/resource-diagnostic-and-patient-history-';
import { ResourcePatient } from '../models/resource-patient-';

/**
 * Number Keyed Repository
 */
@Injectable()
class PatientHistoryEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindAllPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientHistory-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientHistoryUsingGET(params: PatientHistoryEntityService.FindAllPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findAllPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientHistoryUsingPOSTResponse(body: PatientHistory): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientHistory-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientHistoryUsingPOST(body: PatientHistory): Observable<ResourcePatientHistory> {
    return this.savePatientHistoryUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientHistoryUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientHistory-search/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientHistoryUsingGET(id: number): Observable<ResourcePatientHistory> {
    return this.findByIdPatientHistoryUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPUTResponse(params: PatientHistoryEntityService.SavePatientHistoryUsingPUTParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientHistory-search/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPUT(params: PatientHistoryEntityService.SavePatientHistoryUsingPUTParams): Observable<ResourcePatientHistory> {
    return this.savePatientHistoryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePatientHistoryUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientHistory-search/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePatientHistoryUsingDELETE(id: number): Observable<void> {
    return this.deletePatientHistoryUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPATCHResponse(params: PatientHistoryEntityService.SavePatientHistoryUsingPATCHParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientHistory-search/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPATCH(params: PatientHistoryEntityService.SavePatientHistoryUsingPATCHParams): Observable<ResourcePatientHistory> {
    return this.savePatientHistoryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllPatientHistoryUsingGET_1Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientHistoryUsingGET_1Response(params: PatientHistoryEntityService.FindAllPatientHistoryUsingGET_1Params): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllPatientHistoryUsingGET_1Params` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientHistoryUsingGET_1(params: PatientHistoryEntityService.FindAllPatientHistoryUsingGET_1Params): Observable<ResourcesPatientHistory> {
    return this.findAllPatientHistoryUsingGET_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientHistoryUsingPOST_1Response(body: PatientHistory): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientHistoryUsingPOST_1(body: PatientHistory): Observable<ResourcePatientHistory> {
    return this.savePatientHistoryUsingPOST_1Response(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param aLong aLong
   * @return OK
   */
  findAllByPatientIdPatientHistoryUsingGETResponse(aLong?: number): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (aLong != null) __params = __params.set('aLong', aLong.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findAllByPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param aLong aLong
   * @return OK
   */
  findAllByPatientIdPatientHistoryUsingGET(aLong?: number): Observable<ResourcesPatientHistory> {
    return this.findAllByPatientIdPatientHistoryUsingGETResponse(aLong).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.dicId != null) __params = __params.set('dicId', params.dicId.toString());
    if (params.aLong != null) __params = __params.set('aLong', params.aLong.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.aLong != null) __params = __params.set('aLong', params.aLong.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.aLong != null) __params = __params.set('aLong', params.aLong.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.aLong || []).forEach((val, index) => {if (val != null) __params = __params.append('aLong', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findAllByPatientIdOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationDoctorInClinicDoctorIdInPatientHistoryUsingGETResponse(id?: number): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationDoctorInClinicDoctorIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationDoctorInClinicDoctorIdInPatientHistoryUsingGET(id?: number): Observable<ResourcesPatientHistory> {
    return this.findByConsultationDoctorInClinicDoctorIdInPatientHistoryUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationDoctorInClinicDoctorIdInAndPatientHistoryType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGET(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `visitedAt`: visitedAt
   *
   * - `id`: id
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.visitedAt != null) __params = __params.set('visitedAt', params.visitedAt.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `visitedAt`: visitedAt
   *
   * - `id`: id
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationIdPatientHistoryUsingGETResponse(id?: number): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationIdPatientHistoryUsingGET(id?: number): Observable<ResourcesPatientHistory> {
    return this.findByConsultationIdPatientHistoryUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByIdAndPatientHistoryTypePatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `id`: id
   *
   * @return OK
   */
  findByIdAndPatientHistoryTypePatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByIdAndPatientHistoryTypePatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByIdAndPatientHistoryType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByIdAndPatientHistoryTypePatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `id`: id
   *
   * @return OK
   */
  findByIdAndPatientHistoryTypePatientHistoryUsingGET(params: PatientHistoryEntityService.FindByIdAndPatientHistoryTypePatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByIdAndPatientHistoryTypePatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.dicId != null) __params = __params.set('dicId', params.dicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.dicId != null) __params = __params.set('dicId', params.dicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `endDate`: endDate
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `id`: id
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.aLong || []).forEach((val, index) => {if (val != null) __params = __params.append('aLong', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findByPatientIdOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdOrderByCreatedAtDescPatientHistoryUsingGET(params: PatientHistoryEntityService.FindByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientHistoryType patientHistoryType
   * @return OK
   */
  findDoctorRatingsPatientHistoryUsingGETResponse(patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT'): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientHistoryType != null) __params = __params.set('patientHistoryType', patientHistoryType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findDoctorRatings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param patientHistoryType patientHistoryType
   * @return OK
   */
  findDoctorRatingsPatientHistoryUsingGET(patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT'): Observable<ResourcesPatientHistory> {
    return this.findDoctorRatingsPatientHistoryUsingGETResponse(patientHistoryType).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindRecentCareForPatientPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findRecentCareForPatientPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.FindRecentCareForPatientPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientHistoryType != null) __params = __params.set('patientHistoryType', params.patientHistoryType.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.aLong || []).forEach((val, index) => {if (val != null) __params = __params.append('aLong', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/search/findRecentCareForPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.FindRecentCareForPatientPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientHistoryType`: patientHistoryType
   *
   * - `endDate`: endDate
   *
   * - `aLong`: aLong
   *
   * @return OK
   */
  findRecentCareForPatientPatientHistoryUsingGET(params: PatientHistoryEntityService.FindRecentCareForPatientPatientHistoryUsingGETParams): Observable<ResourcesPatientHistory> {
    return this.findRecentCareForPatientPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientHistoryUsingGET_1Response(id: number): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPatientHistoryUsingGET_1(id: number): Observable<ResourcePatientHistory> {
    return this.findByIdPatientHistoryUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPUT_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPUT_1Response(params: PatientHistoryEntityService.SavePatientHistoryUsingPUT_1Params): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPUT_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPUT_1(params: PatientHistoryEntityService.SavePatientHistoryUsingPUT_1Params): Observable<ResourcePatientHistory> {
    return this.savePatientHistoryUsingPUT_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePatientHistoryUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePatientHistoryUsingDELETE_1(id: number): Observable<void> {
    return this.deletePatientHistoryUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPATCH_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPATCH_1Response(params: PatientHistoryEntityService.SavePatientHistoryUsingPATCH_1Params): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.SavePatientHistoryUsingPATCH_1Params` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientHistoryUsingPATCH_1(params: PatientHistoryEntityService.SavePatientHistoryUsingPATCH_1Params): Observable<ResourcePatientHistory> {
    return this.savePatientHistoryUsingPATCH_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingGETResponse(id: number): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingGET(id: number): Observable<ResourceCommentOnDocument> {
    return this.patientHistoryCommentOnDocumentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingPOSTResponse(params: PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPOSTParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingPOST(params: PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPOSTParams): Observable<ResourceCommentOnDocument> {
    return this.patientHistoryCommentOnDocumentUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingPUTResponse(params: PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPUTParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingPUT(params: PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPUTParams): Observable<ResourceCommentOnDocument> {
    return this.patientHistoryCommentOnDocumentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientHistoryCommentOnDocumentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientHistoryCommentOnDocumentUsingDELETE(id: number): Observable<void> {
    return this.patientHistoryCommentOnDocumentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingPATCHResponse(params: PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPATCHParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentOnDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentOnDocumentUsingPATCH(params: PatientHistoryEntityService.PatientHistoryCommentOnDocumentUsingPATCHParams): Observable<ResourceCommentOnDocument> {
    return this.patientHistoryCommentOnDocumentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/commentsForPatientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingGET_1(id: number): Observable<ResourcesComment> {
    return this.patientHistoryCommentsForPatientHistoryUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingPOSTResponse(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPOSTParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentsForPatientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingPOST(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPOSTParams): Observable<ResourcesComment> {
    return this.patientHistoryCommentsForPatientHistoryUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingPUTResponse(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPUTParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentsForPatientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingPUT(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPUTParams): Observable<ResourcesComment> {
    return this.patientHistoryCommentsForPatientHistoryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientHistoryCommentsForPatientHistoryUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/commentsForPatientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientHistoryCommentsForPatientHistoryUsingDELETE_1(id: number): Observable<void> {
    return this.patientHistoryCommentsForPatientHistoryUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingPATCHResponse(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPATCHParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentsForPatientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingPATCH(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingPATCHParams): Observable<ResourcesComment> {
    return this.patientHistoryCommentsForPatientHistoryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingGETResponse(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingGETParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentsForPatientHistory/${params.commentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   *
   * @return OK
   */
  patientHistoryCommentsForPatientHistoryUsingGET(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingGETParams): Observable<ResourceComment> {
    return this.patientHistoryCommentsForPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   */
  patientHistoryCommentsForPatientHistoryUsingDELETEResponse(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/commentsForPatientHistory/${params.commentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   */
  patientHistoryCommentsForPatientHistoryUsingDELETE(params: PatientHistoryEntityService.PatientHistoryCommentsForPatientHistoryUsingDELETEParams): Observable<void> {
    return this.patientHistoryCommentsForPatientHistoryUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.patientHistoryConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryConsultationUsingPOSTResponse(params: PatientHistoryEntityService.PatientHistoryConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryConsultationUsingPOST(params: PatientHistoryEntityService.PatientHistoryConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.patientHistoryConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryConsultationUsingPUTResponse(params: PatientHistoryEntityService.PatientHistoryConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryConsultationUsingPUT(params: PatientHistoryEntityService.PatientHistoryConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.patientHistoryConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientHistoryConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientHistoryConsultationUsingDELETE(id: number): Observable<void> {
    return this.patientHistoryConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryConsultationUsingPATCHResponse(params: PatientHistoryEntityService.PatientHistoryConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryConsultationUsingPATCH(params: PatientHistoryEntityService.PatientHistoryConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.patientHistoryConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryDiagnosticUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryDiagnosticUsingGET(id: number): Observable<ResourceDiagnostic> {
    return this.patientHistoryDiagnosticUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticUsingPOSTResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticUsingPOSTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticUsingPOST(params: PatientHistoryEntityService.PatientHistoryDiagnosticUsingPOSTParams): Observable<ResourceDiagnostic> {
    return this.patientHistoryDiagnosticUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticUsingPUTResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticUsingPUTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticUsingPUT(params: PatientHistoryEntityService.PatientHistoryDiagnosticUsingPUTParams): Observable<ResourceDiagnostic> {
    return this.patientHistoryDiagnosticUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientHistoryDiagnosticUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientHistoryDiagnosticUsingDELETE(id: number): Observable<void> {
    return this.patientHistoryDiagnosticUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticUsingPATCHResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticUsingPATCHParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticUsingPATCH(params: PatientHistoryEntityService.PatientHistoryDiagnosticUsingPATCHParams): Observable<ResourceDiagnostic> {
    return this.patientHistoryDiagnosticUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingGET_1(id: number): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingPOSTResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingPOST(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPOSTParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingPUTResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingPUT(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPUTParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingDELETE_1(id: number): Observable<void> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingPATCHResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingPATCH(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingPATCHParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingGETResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingGETParams): Observable<HttpResponse<ResourceDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnosticAndPatientHistories/${params.diagnosticandpatienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourceDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   *
   * @return OK
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingGET(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingGETParams): Observable<ResourceDiagnosticAndPatientHistory> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingDELETEResponse(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/diagnosticAndPatientHistories/${params.diagnosticandpatienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   */
  patientHistoryDiagnosticAndPatientHistoriesUsingDELETE(params: PatientHistoryEntityService.PatientHistoryDiagnosticAndPatientHistoriesUsingDELETEParams): Observable<void> {
    return this.patientHistoryDiagnosticAndPatientHistoriesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryPatientUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientHistoryPatientUsingGET(id: number): Observable<ResourcePatient> {
    return this.patientHistoryPatientUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryPatientUsingPOSTResponse(params: PatientHistoryEntityService.PatientHistoryPatientUsingPOSTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryPatientUsingPOST(params: PatientHistoryEntityService.PatientHistoryPatientUsingPOSTParams): Observable<ResourcePatient> {
    return this.patientHistoryPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryPatientUsingPUTResponse(params: PatientHistoryEntityService.PatientHistoryPatientUsingPUTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryPatientUsingPUT(params: PatientHistoryEntityService.PatientHistoryPatientUsingPUTParams): Observable<ResourcePatient> {
    return this.patientHistoryPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientHistoryPatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patienthistory/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientHistoryPatientUsingDELETE(id: number): Observable<void> {
    return this.patientHistoryPatientUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryPatientUsingPATCHResponse(params: PatientHistoryEntityService.PatientHistoryPatientUsingPATCHParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patienthistory/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientHistoryEntityService.PatientHistoryPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientHistoryPatientUsingPATCH(params: PatientHistoryEntityService.PatientHistoryPatientUsingPATCHParams): Observable<ResourcePatient> {
    return this.patientHistoryPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientHistoryEntityService {

  /**
   * Parameters for findAllPatientHistoryUsingGET
   */
  export interface FindAllPatientHistoryUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for savePatientHistoryUsingPUT
   */
  export interface SavePatientHistoryUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientHistory;
  }

  /**
   * Parameters for savePatientHistoryUsingPATCH
   */
  export interface SavePatientHistoryUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientHistory;
  }

  /**
   * Parameters for findAllPatientHistoryUsingGET_1
   */
  export interface FindAllPatientHistoryUsingGET_1Params {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindAllByPatientIdAndConsultationDoctorInClinicIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * dicId
     */
    dicId?: number;

    /**
     * aLong
     */
    aLong?: number;
  }

  /**
   * Parameters for findAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindAllByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * aLong
     */
    aLong?: number;
  }

  /**
   * Parameters for findAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGET
   */
  export interface FindAllByPatientIdAndLastModifiedBetweenOrderByLastModifiedDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * aLong
     */
    aLong?: number;
  }

  /**
   * Parameters for findAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindAllByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * aLong
     */
    aLong?: Array<number>;
  }

  /**
   * Parameters for findAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindAllByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicDoctorIdInAndPatientHistoryTypePatientHistoryUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';
    page?: number;

    /**
     * id
     */
    id?: Array<number>;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndCreatedAtGreaterThanEqualPatientHistoryUsingGETParams {

    /**
     * visitedAt
     */
    visitedAt?: string;

    /**
     * id
     */
    id?: Array<number>;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * dicId
     */
    dicId?: Array<number>;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndPatientNameContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByConsultationDoctorInClinicIdInAndPatientPhoneContainingAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByIdAndPatientHistoryTypePatientHistoryUsingGET
   */
  export interface FindByIdAndPatientHistoryTypePatientHistoryUsingGETParams {

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByPatientIdPatientHistoryUsingGET
   */
  export interface FindByPatientIdPatientHistoryUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientId
     */
    patientId?: number;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';

    /**
     * endDate
     */
    endDate?: string;

    /**
     * dicId
     */
    dicId?: number;
  }

  /**
   * Parameters for findByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdAndConsultationDoctorInClinicIdAndPatientHistoryTypeAndCreatedAtGreaterThanEqualAndCreatedAtLessThanEqualOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientId
     */
    patientId?: number;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';

    /**
     * endDate
     */
    endDate?: string;

    /**
     * dicId
     */
    dicId?: number;
  }

  /**
   * Parameters for findByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * id
     */
    id?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdAndPatientHistoryTypeNotInOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * type
     */
    type?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdInAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * id
     */
    id?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdInAndPatientHistoryTypeAndCreatedAtBetweenOrderByCreatedAtDescPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * aLong
     */
    aLong?: Array<number>;
  }

  /**
   * Parameters for findByPatientIdOrderByCreatedAtDescPatientHistoryUsingGET
   */
  export interface FindByPatientIdOrderByCreatedAtDescPatientHistoryUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findRecentCareForPatientPatientHistoryUsingGET
   */
  export interface FindRecentCareForPatientPatientHistoryUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientHistoryType
     */
    patientHistoryType?: 'REPORT' | 'CONSULTATION' | 'UPLOAD_DOCUMENT';

    /**
     * endDate
     */
    endDate?: string;

    /**
     * aLong
     */
    aLong?: Array<number>;
  }

  /**
   * Parameters for savePatientHistoryUsingPUT_1
   */
  export interface SavePatientHistoryUsingPUT_1Params {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientHistory;
  }

  /**
   * Parameters for savePatientHistoryUsingPATCH_1
   */
  export interface SavePatientHistoryUsingPATCH_1Params {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PatientHistory;
  }

  /**
   * Parameters for patientHistoryCommentOnDocumentUsingPOST
   */
  export interface PatientHistoryCommentOnDocumentUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryCommentOnDocumentUsingPUT
   */
  export interface PatientHistoryCommentOnDocumentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryCommentOnDocumentUsingPATCH
   */
  export interface PatientHistoryCommentOnDocumentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryCommentsForPatientHistoryUsingPOST
   */
  export interface PatientHistoryCommentsForPatientHistoryUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientHistoryCommentsForPatientHistoryUsingPUT
   */
  export interface PatientHistoryCommentsForPatientHistoryUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientHistoryCommentsForPatientHistoryUsingPATCH
   */
  export interface PatientHistoryCommentsForPatientHistoryUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientHistoryCommentsForPatientHistoryUsingGET
   */
  export interface PatientHistoryCommentsForPatientHistoryUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * commentId
     */
    commentId: string;
  }

  /**
   * Parameters for patientHistoryCommentsForPatientHistoryUsingDELETE
   */
  export interface PatientHistoryCommentsForPatientHistoryUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * commentId
     */
    commentId: string;
  }

  /**
   * Parameters for patientHistoryConsultationUsingPOST
   */
  export interface PatientHistoryConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryConsultationUsingPUT
   */
  export interface PatientHistoryConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryConsultationUsingPATCH
   */
  export interface PatientHistoryConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryDiagnosticUsingPOST
   */
  export interface PatientHistoryDiagnosticUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryDiagnosticUsingPUT
   */
  export interface PatientHistoryDiagnosticUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryDiagnosticUsingPATCH
   */
  export interface PatientHistoryDiagnosticUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryDiagnosticAndPatientHistoriesUsingPOST
   */
  export interface PatientHistoryDiagnosticAndPatientHistoriesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientHistoryDiagnosticAndPatientHistoriesUsingPUT
   */
  export interface PatientHistoryDiagnosticAndPatientHistoriesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientHistoryDiagnosticAndPatientHistoriesUsingPATCH
   */
  export interface PatientHistoryDiagnosticAndPatientHistoriesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientHistoryDiagnosticAndPatientHistoriesUsingGET
   */
  export interface PatientHistoryDiagnosticAndPatientHistoriesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticandpatienthistoryId
     */
    diagnosticandpatienthistoryId: string;
  }

  /**
   * Parameters for patientHistoryDiagnosticAndPatientHistoriesUsingDELETE
   */
  export interface PatientHistoryDiagnosticAndPatientHistoriesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticandpatienthistoryId
     */
    diagnosticandpatienthistoryId: string;
  }

  /**
   * Parameters for patientHistoryPatientUsingPOST
   */
  export interface PatientHistoryPatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryPatientUsingPUT
   */
  export interface PatientHistoryPatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientHistoryPatientUsingPATCH
   */
  export interface PatientHistoryPatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { PatientHistoryEntityService }
