import { ApiConfiguration } from './api-configuration';
import { HttpClient, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranscriberService extends BaseService{

  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

    taskData;

    fetchCount(){
      return this.http.get(this.config.rootUrl + 'task/fetch/all');
    };

    fetchIssueReportGet(params){
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      if (params.start != null) __params = __params.set('start', params.start.toString());
      if (params.end != null) __params = __params.set('end', params.end.toString());
      if (params.size != null) __params = __params.set('size', params.size.toString());
      if (params.page != null) __params = __params.set('page', params.page.toString());

      let req = new HttpRequest<any>(
        'GET',
        this.rootUrl + `api/v1/transcriber/task/fetch/issues/all`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        filter(_r => _r instanceof HttpResponse),
        map(_r => {
          let _resp = _r as HttpResponse<any>;
          let _body = null;
          _body = _resp.body;
          return _resp.clone({body: _body}) as HttpResponse<any>;
        })
      );
    };

    fetchIssueReport(params): Observable<any> {
      return this.fetchIssueReportGet(params).pipe(
        map(_r => _r.body)
      );
    };

    fetchReportGet(params){
      let __params = this.newParams();
      let __headers = new HttpHeaders();
      let __body: any = null;
      if (params.start != null) __params = __params.set('start', params.start.toString());
      if (params.end != null) __params = __params.set('end', params.end.toString());
      if (params.taskType != null) __params = __params.set('taskTypeState', params.taskType.toString());
      if (params.size != null) __params = __params.set('size', params.size.toString());
      if (params.page != null) __params = __params.set('page', params.page.toString());

      let req = new HttpRequest<any>(
        'GET',
        this.rootUrl + `api/v1/transcriber/task/fetch/employee/task/summary`,
        __body,
        {
          headers: __headers,
          params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        filter(_r => _r instanceof HttpResponse),
        map(_r => {
          let _resp = _r as HttpResponse<any>;
          let _body = null;
          _body = _resp.body;
          return _resp.clone({body: _body}) as HttpResponse<any>;
        })
      );
    };

    fetchReport(params): Observable<any> {
      return this.fetchReportGet(params).pipe(
        map(_r => _r.body)
      );
    }

    fetchTask(params){
      return this.http.get(this.config.rootUrl + 'api/v1/transcriber/task/filter' , { params });
    }
}
