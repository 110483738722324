/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosticReportValue } from '../models/resources-diagnostic-report-value-';
import { ResourceDiagnosticReportValue } from '../models/resource-diagnostic-report-value-';
import { DiagnosticReportValue } from '../models/diagnostic-report-value';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';

/**
 * $ Proxy 204
 */
@Injectable()
class DiagnosticReportValueEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.FindAllDiagnosticReportValueUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticReportValueUsingGETResponse(params: DiagnosticReportValueEntityService.FindAllDiagnosticReportValueUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticReportValue = null;
        _body = _resp.body as ResourcesDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.FindAllDiagnosticReportValueUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticReportValueUsingGET(params: DiagnosticReportValueEntityService.FindAllDiagnosticReportValueUsingGETParams): Observable<ResourcesDiagnosticReportValue> {
    return this.findAllDiagnosticReportValueUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticReportValueUsingPOSTResponse(body: DiagnosticReportValue): Observable<HttpResponse<ResourceDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticReportValue = null;
        _body = _resp.body as ResourceDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticReportValueUsingPOST(body: DiagnosticReportValue): Observable<ResourceDiagnosticReportValue> {
    return this.saveDiagnosticReportValueUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticReportValueUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticReportValue = null;
        _body = _resp.body as ResourceDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticReportValueUsingGET(id: number): Observable<ResourceDiagnosticReportValue> {
    return this.findByIdDiagnosticReportValueUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticReportValueUsingPUTResponse(params: DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticReportValue = null;
        _body = _resp.body as ResourceDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticReportValueUsingPUT(params: DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPUTParams): Observable<ResourceDiagnosticReportValue> {
    return this.saveDiagnosticReportValueUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticReportValueUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticReportValueUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticReportValueUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticReportValueUsingPATCHResponse(params: DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticReportValue = null;
        _body = _resp.body as ResourceDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticReportValueUsingPATCH(params: DiagnosticReportValueEntityService.SaveDiagnosticReportValueUsingPATCHParams): Observable<ResourceDiagnosticReportValue> {
    return this.saveDiagnosticReportValueUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingGET(id: number): Observable<ResourceDiagnostic> {
    return this.diagnosticReportValueDiagnosticUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingPOSTResponse(params: DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPOSTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingPOST(params: DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPOSTParams): Observable<ResourceDiagnostic> {
    return this.diagnosticReportValueDiagnosticUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingPUTResponse(params: DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPUTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingPUT(params: DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPUTParams): Observable<ResourceDiagnostic> {
    return this.diagnosticReportValueDiagnosticUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticReportValueDiagnosticUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticReportValueDiagnosticUsingDELETE(id: number): Observable<void> {
    return this.diagnosticReportValueDiagnosticUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingPATCHResponse(params: DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPATCHParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticReportValue/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticReportValueDiagnosticUsingPATCH(params: DiagnosticReportValueEntityService.DiagnosticReportValueDiagnosticUsingPATCHParams): Observable<ResourceDiagnostic> {
    return this.diagnosticReportValueDiagnosticUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticReportValueEntityService {

  /**
   * Parameters for findAllDiagnosticReportValueUsingGET
   */
  export interface FindAllDiagnosticReportValueUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDiagnosticReportValueUsingPUT
   */
  export interface SaveDiagnosticReportValueUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticReportValue;
  }

  /**
   * Parameters for saveDiagnosticReportValueUsingPATCH
   */
  export interface SaveDiagnosticReportValueUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticReportValue;
  }

  /**
   * Parameters for diagnosticReportValueDiagnosticUsingPOST
   */
  export interface DiagnosticReportValueDiagnosticUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticReportValueDiagnosticUsingPUT
   */
  export interface DiagnosticReportValueDiagnosticUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticReportValueDiagnosticUsingPATCH
   */
  export interface DiagnosticReportValueDiagnosticUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { DiagnosticReportValueEntityService }
