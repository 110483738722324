import { Component, OnInit, Inject } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { ConstantsService } from "src/app/services/constants.service";
import { FormControl } from "@angular/forms";
import { NewReasonDialogComponent } from "src/app/shared/dialog/new-reason-dialog/new-reason-dialog.component";
import * as async from "async";
import { HttpClient } from "@angular/common/http";
import { ApiConfiguration } from "src/app/api/emr/api-configuration";

@Component({
  selector: "app-order-reason",
  templateUrl: "./order-reason.component.html",
  styleUrls: ["./order-reason.component.scss"],
})
export class OrderReasonComponent implements OnInit {
  reasons = [];
  orderReasons = [];
  diagnosticIds;
  loadingReason: boolean = false;
  savingReason: boolean = false;
  searchingReason: boolean = false;
  pathologyReason = new FormControl();
  medicineReason = new FormControl();
  subscriptionReason = new FormControl();
  pathologyReasons = [];
  medicineReasons = [];
  subscriptionReasons = [];
  pathology: any;
  medicine: any;
  subscription: any;
  pathologyReq: any;
  medicineReq: any;
  subscriptionReq: any;
  pathoId: any;
  medId: any;
  subId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private constantsService: ConstantsService,
    private MatDialogRef: MatDialogRef<OrderReasonComponent>,
    private HttpClient: HttpClient,
    private MatDialog: MatDialog,
    private EmrApiConfiguration: ApiConfiguration
  ) {
    this.pathology = data.pathology;
    this.medicine = data.medicine;
    this.subscription = data.subscription;
    this.pathoId = data.pathologyReasonId;
    this.medId = data.medicineReasonId;
    this.subId = data.subscriptionReasonId;
    this.pathologyReq = data.pathologyReq;
    this.medicineReq = data.medicineReq;
    this.subscriptionReq = data.subscriptionReq;
  }

  getReasons(reasonType) {
    let declineReasonType = [
      this.constantsService.issueType.pathologyOrder,
      this.constantsService.issueType.medicineOrder,
      this.constantsService.issueType.subscriptionNotPurchased,
    ];
    this.HttpClient.get(
      this.EmrApiConfiguration.rootUrl +
        "/api/v1/emr/decline-reason/fetch-decline-reason",
      {
        params: {
          declineReasonType,
        },
      }
    ).subscribe((res: any) => {
      if (res) {
        this.orderReasons = res;
        this.pathologyReasons = res.filter(
          (reason) =>
            reason.declineReasonType ==
            this.constantsService.issueType.pathologyOrder
        );
        this.medicineReasons = res.filter(
          (reason) =>
            reason.declineReasonType ==
            this.constantsService.issueType.medicineOrder
        );
        this.subscriptionReasons = res.filter(
          (reason) =>
            reason.declineReasonType ===
            this.constantsService.issueType.subscriptionNotPurchased
        );
        this.pathologyReason.setValue(
          this.pathologyReasons.find((reason) => reason.id === this.pathoId)
        );
        this.medicineReason.setValue(
          this.medicineReasons.find((reason) => reason.id === this.medId)
        );
        this.subscriptionReason.setValue(
          this.subscriptionReasons.find((reason) => reason.id === this.subId)
        );
        this.searchingReason = false;
      } else {
        this.orderReasons = [];
        this.searchingReason = false;
      }
    });
  }

  save() {
    let reason = {
      pathoReason: this.pathologyReason ? this.pathologyReason.value : null,
      medicineReason: this.medicineReason ? this.medicineReason.value : null,
      subscriptionReason: this.subscriptionReason
        ? this.subscriptionReason.value
        : null,
    };
    this.MatDialogRef.close(reason);
  }

  ngOnInit() {
    this.getReasons("abcd");
  }
}
