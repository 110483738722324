/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesMedicine } from '../models/resources-medicine-';
import { ResourceMedicine } from '../models/resource-medicine-';
import { Medicine } from '../models/medicine';
import { ResourceMedicineGeneral } from '../models/resource-medicine-general-';
import { ResourcesMedicineInClinic } from '../models/resources-medicine-in-clinic-';
import { ResourceMedicineInClinic } from '../models/resource-medicine-in-clinic-';
import { ResourcesPrescription } from '../models/resources-prescription-';
import { ResourcePrescription } from '../models/resource-prescription-';

/**
 * $ Proxy 184
 */
@Injectable()
class MedicineEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MedicineEntityService.FindAllMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineUsingGETResponse(params: MedicineEntityService.FindAllMedicineUsingGETParams): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.FindAllMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineUsingGET(params: MedicineEntityService.FindAllMedicineUsingGETParams): Observable<ResourcesMedicine> {
    return this.findAllMedicineUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineUsingPOSTResponse(body: Medicine): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/secondary-medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineUsingPOST(body: Medicine): Observable<ResourceMedicine> {
    return this.saveMedicineUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.FindByMedicineNameMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `medicineName`: medicineName
   *
   * @return OK
   */
  findByMedicineNameMedicineUsingGETResponse(params: MedicineEntityService.FindByMedicineNameMedicineUsingGETParams): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.medicineName != null) __params = __params.set('medicineName', params.medicineName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/search/findByMedicineName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.FindByMedicineNameMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `medicineName`: medicineName
   *
   * @return OK
   */
  findByMedicineNameMedicineUsingGET(params: MedicineEntityService.FindByMedicineNameMedicineUsingGETParams): Observable<ResourcesMedicine> {
    return this.findByMedicineNameMedicineUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.FindByNameContainingAndIsActiveTrueMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndIsActiveTrueMedicineUsingGETResponse(params: MedicineEntityService.FindByNameContainingAndIsActiveTrueMedicineUsingGETParams): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/search/findByNameContainingAndIsActiveTrue?projection=medicineForTranscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.FindByNameContainingAndIsActiveTrueMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndIsActiveTrueMedicineUsingGET(params: MedicineEntityService.FindByNameContainingAndIsActiveTrueMedicineUsingGETParams): Observable<ResourcesMedicine> {
    return this.findByNameContainingAndIsActiveTrueMedicineUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineUsingGET(id: number): Observable<ResourceMedicine> {
    return this.findByIdMedicineUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.SaveMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineUsingPUTResponse(params: MedicineEntityService.SaveMedicineUsingPUTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.SaveMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineUsingPUT(params: MedicineEntityService.SaveMedicineUsingPUTParams): Observable<ResourceMedicine> {
    return this.saveMedicineUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteMedicineUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteMedicineUsingDELETE(id: number): Observable<void> {
    return this.deleteMedicineUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.SaveMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineUsingPATCHResponse(params: MedicineEntityService.SaveMedicineUsingPATCHParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.SaveMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineUsingPATCH(params: MedicineEntityService.SaveMedicineUsingPATCHParams): Observable<ResourceMedicine> {
    return this.saveMedicineUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/${id}/medicineGeneralForMedicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingGET(id: number): Observable<ResourceMedicineGeneral> {
    return this.medicineMedicineGeneralForMedicineUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingPOSTResponse(params: MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPOSTParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineGeneralForMedicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingPOST(params: MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPOSTParams): Observable<ResourceMedicineGeneral> {
    return this.medicineMedicineGeneralForMedicineUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingPUTResponse(params: MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPUTParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineGeneralForMedicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingPUT(params: MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPUTParams): Observable<ResourceMedicineGeneral> {
    return this.medicineMedicineGeneralForMedicineUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineMedicineGeneralForMedicineUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/${id}/medicineGeneralForMedicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineMedicineGeneralForMedicineUsingDELETE(id: number): Observable<void> {
    return this.medicineMedicineGeneralForMedicineUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingPATCHResponse(params: MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPATCHParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineGeneralForMedicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineGeneralForMedicineUsingPATCH(params: MedicineEntityService.MedicineMedicineGeneralForMedicineUsingPATCHParams): Observable<ResourceMedicineGeneral> {
    return this.medicineMedicineGeneralForMedicineUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineMedicineInClinicsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/${id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineMedicineInClinicsUsingGET_1(id: number): Observable<ResourcesMedicineInClinic> {
    return this.medicineMedicineInClinicsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingPOSTResponse(params: MedicineEntityService.MedicineMedicineInClinicsUsingPOSTParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingPOST(params: MedicineEntityService.MedicineMedicineInClinicsUsingPOSTParams): Observable<ResourcesMedicineInClinic> {
    return this.medicineMedicineInClinicsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingPUTResponse(params: MedicineEntityService.MedicineMedicineInClinicsUsingPUTParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingPUT(params: MedicineEntityService.MedicineMedicineInClinicsUsingPUTParams): Observable<ResourcesMedicineInClinic> {
    return this.medicineMedicineInClinicsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineMedicineInClinicsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/${id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineMedicineInClinicsUsingDELETE_1(id: number): Observable<void> {
    return this.medicineMedicineInClinicsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingPATCHResponse(params: MedicineEntityService.MedicineMedicineInClinicsUsingPATCHParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingPATCH(params: MedicineEntityService.MedicineMedicineInClinicsUsingPATCHParams): Observable<ResourcesMedicineInClinic> {
    return this.medicineMedicineInClinicsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingGETParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingGETResponse(params: MedicineEntityService.MedicineMedicineInClinicsUsingGETParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineInClinics/${params.medicineinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingGETParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineMedicineInClinicsUsingGET(params: MedicineEntityService.MedicineMedicineInClinicsUsingGETParams): Observable<ResourceMedicineInClinic> {
    return this.medicineMedicineInClinicsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   */
  medicineMedicineInClinicsUsingDELETEResponse(params: MedicineEntityService.MedicineMedicineInClinicsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/medicineInClinics/${params.medicineinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicineMedicineInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   */
  medicineMedicineInClinicsUsingDELETE(params: MedicineEntityService.MedicineMedicineInClinicsUsingDELETEParams): Observable<void> {
    return this.medicineMedicineInClinicsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicinePrescriptionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicinePrescriptionsUsingGET_1(id: number): Observable<ResourcesPrescription> {
    return this.medicinePrescriptionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicinePrescriptionsUsingPOSTResponse(params: MedicineEntityService.MedicinePrescriptionsUsingPOSTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicinePrescriptionsUsingPOST(params: MedicineEntityService.MedicinePrescriptionsUsingPOSTParams): Observable<ResourcesPrescription> {
    return this.medicinePrescriptionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicinePrescriptionsUsingPUTResponse(params: MedicineEntityService.MedicinePrescriptionsUsingPUTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicinePrescriptionsUsingPUT(params: MedicineEntityService.MedicinePrescriptionsUsingPUTParams): Observable<ResourcesPrescription> {
    return this.medicinePrescriptionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicinePrescriptionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicinePrescriptionsUsingDELETE_1(id: number): Observable<void> {
    return this.medicinePrescriptionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicinePrescriptionsUsingPATCHResponse(params: MedicineEntityService.MedicinePrescriptionsUsingPATCHParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicinePrescriptionsUsingPATCH(params: MedicineEntityService.MedicinePrescriptionsUsingPATCHParams): Observable<ResourcesPrescription> {
    return this.medicinePrescriptionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicinePrescriptionsUsingGETResponse(params: MedicineEntityService.MedicinePrescriptionsUsingGETParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicinePrescriptionsUsingGET(params: MedicineEntityService.MedicinePrescriptionsUsingGETParams): Observable<ResourcePrescription> {
    return this.medicinePrescriptionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  medicinePrescriptionsUsingDELETEResponse(params: MedicineEntityService.MedicinePrescriptionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicines/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `MedicineEntityService.MedicinePrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  medicinePrescriptionsUsingDELETE(params: MedicineEntityService.MedicinePrescriptionsUsingDELETEParams): Observable<void> {
    return this.medicinePrescriptionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module MedicineEntityService {

  /**
   * Parameters for findAllMedicineUsingGET
   */
  export interface FindAllMedicineUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByMedicineNameMedicineUsingGET
   */
  export interface FindByMedicineNameMedicineUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * medicineName
     */
    medicineName?: string;
  }

  /**
   * Parameters for findByNameContainingAndIsActiveTrueMedicineUsingGET
   */
  export interface FindByNameContainingAndIsActiveTrueMedicineUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveMedicineUsingPUT
   */
  export interface SaveMedicineUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Medicine;
  }

  /**
   * Parameters for saveMedicineUsingPATCH
   */
  export interface SaveMedicineUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Medicine;
  }

  /**
   * Parameters for medicineMedicineGeneralForMedicineUsingPOST
   */
  export interface MedicineMedicineGeneralForMedicineUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineMedicineGeneralForMedicineUsingPUT
   */
  export interface MedicineMedicineGeneralForMedicineUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineMedicineGeneralForMedicineUsingPATCH
   */
  export interface MedicineMedicineGeneralForMedicineUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineMedicineInClinicsUsingPOST
   */
  export interface MedicineMedicineInClinicsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineMedicineInClinicsUsingPUT
   */
  export interface MedicineMedicineInClinicsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineMedicineInClinicsUsingPATCH
   */
  export interface MedicineMedicineInClinicsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineMedicineInClinicsUsingGET
   */
  export interface MedicineMedicineInClinicsUsingGETParams {

    /**
     * medicineinclinicId
     */
    medicineinclinicId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicineMedicineInClinicsUsingDELETE
   */
  export interface MedicineMedicineInClinicsUsingDELETEParams {

    /**
     * medicineinclinicId
     */
    medicineinclinicId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicinePrescriptionsUsingPOST
   */
  export interface MedicinePrescriptionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicinePrescriptionsUsingPUT
   */
  export interface MedicinePrescriptionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicinePrescriptionsUsingPATCH
   */
  export interface MedicinePrescriptionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicinePrescriptionsUsingGET
   */
  export interface MedicinePrescriptionsUsingGETParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicinePrescriptionsUsingDELETE
   */
  export interface MedicinePrescriptionsUsingDELETEParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }
}

export { MedicineEntityService }
