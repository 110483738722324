import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from './../../api/emr/api-configuration';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-pending-transcribing',
  templateUrl: './pending-transcribing.component.html',
  styleUrls: ['./pending-transcribing.component.scss']
})
export class PendingTranscribingComponent implements OnInit {

  constructor(
    private LocalStorage: LocalStorage,
    private ApiConfiguration: ApiConfiguration,
    private HttpClient: HttpClient
    
    ) { }

  transcribingPage;
  doctorInClinics;
  callSubscription: Subscription;
  pageInfo;
  loadingTranscribing: boolean = true;
  transcribing = [];
  interval;

  findPendingTranscribing(page) {
    this.loadingTranscribing = true;
    if (this.callSubscription) {
      this.callSubscription.unsubscribe();
    }
    let params: any = {
      projection: 'consultationForTranscription',
      status: 'TRANSCRIBING',
      doctorInClinics: this.doctorInClinics,
      isTranscribed: false,
      page
    };

    this.callSubscription = this.HttpClient.get(
      this.ApiConfiguration.rootUrl + '/api/v1/emr/consultations/search/findByDoctorInClinicIdInAndStatusAndIsTranscribedOrderByAppointmentTimeDesc',
      {
        params
      }
    ).subscribe((data: any) => {
      this.transcribing = this.transcribing.concat(data._embedded.consultations);
      this.pageInfo = data.page;
      this.loadingTranscribing = false;
    }, err => {
      this.loadingTranscribing = false;
      alert('Error while loading transcribing consultations');
    });
  }

  fetchPendingConsultation(event){
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      if (!this.loadingTranscribing) {
        event.preventDefault();
        if(this.pageInfo.number + 1 < this.pageInfo.totalPages){
          this.findPendingTranscribing(this.pageInfo.number + 1);
        }
      }
    }
  }

  getTime(time){
    let minutediff = moment(new Date()).diff(time , 'minutes');
    if(minutediff < 10) {
      return 'Within 10 minutes..';
    }
    else if(minutediff < 20){
      return 'Within 20 minutes..';
    }else{
      return 'More than 20 minutes..'
    }
  }

  ngOnInit() {
    this.LocalStorage.getItem('token').subscribe(token => {
      if(token && token.authorities.includes('TRANSCRIBER_ADMIN')){
        this.doctorInClinics = token.doctorInClinicIds.join(',');
        this.findPendingTranscribing(0);
        this.interval = setInterval(()=>{
          this.transcribing=[];
          this.findPendingTranscribing(0);
        },60000);
      }
    })
  }
  ngOnDestroy(){
    if(this.interval){
      clearInterval(this.interval);
    }
  }

}
