/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsultationForPatientAddToQueues } from '../models/consultation-for-patient-add-to-queues';
import { PatientAddToQueue } from '../models/patient-add-to-queue';
import { PatientsForClinics } from '../models/patients-for-clinics';
import { Patients } from '../models/patients';
import { CommentOnDocuments } from '../models/comment-on-documents';
import { UploadStatus } from '../models/upload-status';

/**
 * Patient App Controller
 */
@Injectable()
class PatientAppControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param patientBody patientBody
   * @return OK
   */
  patientAddToQueueUsingPOSTResponse(patientBody: PatientAddToQueue): Observable<HttpResponse<ConsultationForPatientAddToQueues>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patientBody;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/add-to-queue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ConsultationForPatientAddToQueues = null;
        _body = _resp.body as ConsultationForPatientAddToQueues;
        return _resp.clone({body: _body}) as HttpResponse<ConsultationForPatientAddToQueues>;
      })
    );
  }

  /**
   * @param patientBody patientBody
   * @return OK
   */
  patientAddToQueueUsingPOST(patientBody: PatientAddToQueue): Observable<ConsultationForPatientAddToQueues> {
    return this.patientAddToQueueUsingPOSTResponse(patientBody).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetSpecialityWiseDoctorsUsingGETParams` containing the following parameters:
   *
   * - `speciality`: speciality
   *
   * - `sortBy`: sortBy
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `search`: search
   *
   * - `page`:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `currentDate`: currentDate
   *
   * - `cluster`: cluster
   *
   * - `city`: city
   *
   * - `available`: available
   *
   * - `asc`: asc
   *
   * @return OK
   */
  getSpecialityWiseDoctorsUsingGETResponse(params: PatientAppControllerService.GetSpecialityWiseDoctorsUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.speciality || []).forEach((val, index) => {if (val != null) __params = __params.append('speciality', val.toString())});
    if (params.sortBy != null) __params = __params.set('sortBy', params.sortBy.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.currentDate != null) __params = __params.set('currentDate', params.currentDate.toString());
    (params.cluster || []).forEach((val, index) => {if (val != null) __params = __params.append('cluster', val.toString())});
    (params.city || []).forEach((val, index) => {if (val != null) __params = __params.append('city', val.toString())});
    if (params.available != null) __params = __params.set('available', params.available.toString());
    if (params.asc != null) __params = __params.set('asc', params.asc.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/all-doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetSpecialityWiseDoctorsUsingGETParams` containing the following parameters:
   *
   * - `speciality`: speciality
   *
   * - `sortBy`: sortBy
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `search`: search
   *
   * - `page`:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `currentDate`: currentDate
   *
   * - `cluster`: cluster
   *
   * - `city`: city
   *
   * - `available`: available
   *
   * - `asc`: asc
   *
   * @return OK
   */
  getSpecialityWiseDoctorsUsingGET(params: PatientAppControllerService.GetSpecialityWiseDoctorsUsingGETParams): Observable<{}> {
    return this.getSpecialityWiseDoctorsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.FetchPatientsByClinicUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `name`: name
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  fetchPatientsByClinicUsingGETResponse(params: PatientAppControllerService.FetchPatientsByClinicUsingGETParams): Observable<HttpResponse<Array<PatientsForClinics>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.ids || []).forEach((val, index) => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/by-clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PatientsForClinics> = null;
        _body = _resp.body as Array<PatientsForClinics>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PatientsForClinics>>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.FetchPatientsByClinicUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `name`: name
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  fetchPatientsByClinicUsingGET(params: PatientAppControllerService.FetchPatientsByClinicUsingGETParams): Observable<Array<PatientsForClinics>> {
    return this.fetchPatientsByClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingGETResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingGET(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingGETParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingHEADParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingHEADResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingHEADParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingHEAD(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingHEADParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingPOSTResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingPOST(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingPOSTParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingPUTResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingPUT(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingPUTParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingDELETEResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingDELETE(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingDELETEParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingOPTIONSParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingOPTIONSResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingOPTIONSParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingOPTIONS(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingOPTIONSParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingPATCHResponse(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/doctor/base/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetDoctorBasedSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorBasedSpecialityUsingPATCH(params: PatientAppControllerService.GetDoctorBasedSpecialityUsingPATCHParams): Observable<{}> {
    return this.getDoctorBasedSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingGETResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingGET(params: PatientAppControllerService.GetPatientPrescriptionUsingGETParams): Observable<{}> {
    return this.getPatientPrescriptionUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingHEADResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingHEAD(params: PatientAppControllerService.GetPatientPrescriptionUsingHEADParams): Observable<{}> {
    return this.getPatientPrescriptionUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPOSTResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPOST(params: PatientAppControllerService.GetPatientPrescriptionUsingPOSTParams): Observable<{}> {
    return this.getPatientPrescriptionUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPUTResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPUT(params: PatientAppControllerService.GetPatientPrescriptionUsingPUTParams): Observable<{}> {
    return this.getPatientPrescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingDELETEResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingDELETE(params: PatientAppControllerService.GetPatientPrescriptionUsingDELETEParams): Observable<{}> {
    return this.getPatientPrescriptionUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingOPTIONSResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingOPTIONS(params: PatientAppControllerService.GetPatientPrescriptionUsingOPTIONSParams): Observable<{}> {
    return this.getPatientPrescriptionUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPATCHResponse(params: PatientAppControllerService.GetPatientPrescriptionUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/family/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPATCH(params: PatientAppControllerService.GetPatientPrescriptionUsingPATCHParams): Observable<{}> {
    return this.getPatientPrescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingGETResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingGET(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingHEADResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingHEAD(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingHEADResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingPOSTResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingPOST(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingPOSTResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingPUTResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingPUT(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingPUTResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingDELETEResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingDELETE(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingDELETEResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingOPTIONSResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingOPTIONS(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingOPTIONSResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingPATCH_1Response(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getOngoingMedicationPercentageUsingPATCH_1(patientId: number): Observable<{}> {
    return this.getOngoingMedicationPercentageUsingPATCH_1Response(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingGETResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingGET(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingHEADResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingHEAD(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingHEADResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingPOSTResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingPOST(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingPOSTResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingPUTResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingPUT(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingPUTResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingDELETEResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingDELETE(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingDELETEResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingOPTIONSResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingOPTIONS(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingOPTIONSResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingPATCHResponse(patientId: number): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/previous/medication`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPreviousMedicationUsingPATCH(patientId: number): Observable<{}> {
    return this.getPreviousMedicationUsingPATCHResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingGETResponse(params: PatientAppControllerService.GetRecentCareProviderUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingGET(params: PatientAppControllerService.GetRecentCareProviderUsingGETParams): Observable<{}> {
    return this.getRecentCareProviderUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingHEADResponse(params: PatientAppControllerService.GetRecentCareProviderUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingHEAD(params: PatientAppControllerService.GetRecentCareProviderUsingHEADParams): Observable<{}> {
    return this.getRecentCareProviderUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingPOSTResponse(params: PatientAppControllerService.GetRecentCareProviderUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingPOST(params: PatientAppControllerService.GetRecentCareProviderUsingPOSTParams): Observable<{}> {
    return this.getRecentCareProviderUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingPUTResponse(params: PatientAppControllerService.GetRecentCareProviderUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingPUT(params: PatientAppControllerService.GetRecentCareProviderUsingPUTParams): Observable<{}> {
    return this.getRecentCareProviderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingDELETEResponse(params: PatientAppControllerService.GetRecentCareProviderUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingDELETE(params: PatientAppControllerService.GetRecentCareProviderUsingDELETEParams): Observable<{}> {
    return this.getRecentCareProviderUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingOPTIONSResponse(params: PatientAppControllerService.GetRecentCareProviderUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingOPTIONS(params: PatientAppControllerService.GetRecentCareProviderUsingOPTIONSParams): Observable<{}> {
    return this.getRecentCareProviderUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingPATCHResponse(params: PatientAppControllerService.GetRecentCareProviderUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/recent/care/provider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetRecentCareProviderUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentCareProviderUsingPATCH(params: PatientAppControllerService.GetRecentCareProviderUsingPATCHParams): Observable<{}> {
    return this.getRecentCareProviderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  removeFamilyMemberUsingPATCHResponse(patientId: number): Observable<HttpResponse<Patients>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/remove-family-member`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Patients = null;
        _body = _resp.body as Patients;
        return _resp.clone({body: _body}) as HttpResponse<Patients>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  removeFamilyMemberUsingPATCH(patientId: number): Observable<Patients> {
    return this.removeFamilyMemberUsingPATCHResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetSpecialityWiseDoctorsUsingGET_1Params` containing the following parameters:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `speciality`: speciality
   *
   * @return OK
   */
  getSpecialityWiseDoctorsUsingGET_1Response(params: PatientAppControllerService.GetSpecialityWiseDoctorsUsingGET_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.speciality != null) __params = __params.set('speciality', params.speciality.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/speciality/Doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetSpecialityWiseDoctorsUsingGET_1Params` containing the following parameters:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `speciality`: speciality
   *
   * @return OK
   */
  getSpecialityWiseDoctorsUsingGET_1(params: PatientAppControllerService.GetSpecialityWiseDoctorsUsingGET_1Params): Observable<{}> {
    return this.getSpecialityWiseDoctorsUsingGET_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingGET_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingGET_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingGET_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingGET_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingGET_1(params: PatientAppControllerService.GetPatientPrescriptionUsingGET_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingGET_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingHEAD_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingHEAD_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingHEAD_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingHEAD_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingHEAD_1(params: PatientAppControllerService.GetPatientPrescriptionUsingHEAD_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingHEAD_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPOST_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPOST_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingPOST_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPOST_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPOST_1(params: PatientAppControllerService.GetPatientPrescriptionUsingPOST_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingPOST_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPUT_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPUT_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingPUT_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPUT_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPUT_1(params: PatientAppControllerService.GetPatientPrescriptionUsingPUT_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingPUT_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingDELETE_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingDELETE_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingDELETE_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingDELETE_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingDELETE_1(params: PatientAppControllerService.GetPatientPrescriptionUsingDELETE_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingDELETE_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingOPTIONS_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingOPTIONS_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingOPTIONS_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingOPTIONS_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingOPTIONS_1(params: PatientAppControllerService.GetPatientPrescriptionUsingOPTIONS_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingOPTIONS_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPATCH_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPATCH_1Response(params: PatientAppControllerService.GetPatientPrescriptionUsingPATCH_1Params): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/today/medication/for-all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.GetPatientPrescriptionUsingPATCH_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * @return OK
   */
  getPatientPrescriptionUsingPATCH_1(params: PatientAppControllerService.GetPatientPrescriptionUsingPATCH_1Params): Observable<{}> {
    return this.getPatientPrescriptionUsingPATCH_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.PatchPatientProfilePicUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `file`: file
   *
   * @return OK
   */
  patchPatientProfilePicUsingPATCHResponse(params: PatientAppControllerService.PatchPatientProfilePicUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    __formData.append('file', params.file);
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/update/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.PatchPatientProfilePicUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `file`: file
   *
   * @return OK
   */
  patchPatientProfilePicUsingPATCH(params: PatientAppControllerService.PatchPatientProfilePicUsingPATCHParams): Observable<{}> {
    return this.patchPatientProfilePicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.UploadImageUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `patientDocumentType`: patientDocumentType
   *
   * - `file`: file
   *
   * - `documentCreatedDate`: documentCreatedDate
   *
   * - `comment`: comment
   *
   * @return OK
   */
  uploadImageUsingPOSTResponse(params: PatientAppControllerService.UploadImageUsingPOSTParams): Observable<HttpResponse<CommentOnDocuments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.patientDocumentType != null) __params = __params.set('patientDocumentType', params.patientDocumentType.toString());
    __formData.append('file', JSON.stringify(params.file));
    if (params.documentCreatedDate != null) __params = __params.set('documentCreatedDate', params.documentCreatedDate.toString());
    if (params.comment != null) __params = __params.set('comment', params.comment.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/upload/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: CommentOnDocuments = null;
        _body = _resp.body as CommentOnDocuments;
        return _resp.clone({body: _body}) as HttpResponse<CommentOnDocuments>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.UploadImageUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `patientDocumentType`: patientDocumentType
   *
   * - `file`: file
   *
   * - `documentCreatedDate`: documentCreatedDate
   *
   * - `comment`: comment
   *
   * @return OK
   */
  uploadImageUsingPOST(params: PatientAppControllerService.UploadImageUsingPOSTParams): Observable<CommentOnDocuments> {
    return this.uploadImageUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.SavePatientProfilePicUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `file`: file
   *
   * @return OK
   */
  savePatientProfilePicUsingPOSTResponse(params: PatientAppControllerService.SavePatientProfilePicUsingPOSTParams): Observable<HttpResponse<UploadStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    __formData.append('file', params.file);
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/upload/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: UploadStatus = null;
        _body = _resp.body as UploadStatus;
        return _resp.clone({body: _body}) as HttpResponse<UploadStatus>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.SavePatientProfilePicUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `file`: file
   *
   * @return OK
   */
  savePatientProfilePicUsingPOST(params: PatientAppControllerService.SavePatientProfilePicUsingPOSTParams): Observable<UploadStatus> {
    return this.savePatientProfilePicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingGETResponse(params: PatientAppControllerService.ViewPrescriptionUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingGET(params: PatientAppControllerService.ViewPrescriptionUsingGETParams): Observable<{}> {
    return this.viewPrescriptionUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingHEADResponse(params: PatientAppControllerService.ViewPrescriptionUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingHEAD(params: PatientAppControllerService.ViewPrescriptionUsingHEADParams): Observable<{}> {
    return this.viewPrescriptionUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingPOSTResponse(params: PatientAppControllerService.ViewPrescriptionUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingPOST(params: PatientAppControllerService.ViewPrescriptionUsingPOSTParams): Observable<{}> {
    return this.viewPrescriptionUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingPUTResponse(params: PatientAppControllerService.ViewPrescriptionUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingPUT(params: PatientAppControllerService.ViewPrescriptionUsingPUTParams): Observable<{}> {
    return this.viewPrescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingDELETEResponse(params: PatientAppControllerService.ViewPrescriptionUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingDELETE(params: PatientAppControllerService.ViewPrescriptionUsingDELETEParams): Observable<{}> {
    return this.viewPrescriptionUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingOPTIONSResponse(params: PatientAppControllerService.ViewPrescriptionUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingOPTIONS(params: PatientAppControllerService.ViewPrescriptionUsingOPTIONSParams): Observable<{}> {
    return this.viewPrescriptionUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingPATCHResponse(params: PatientAppControllerService.ViewPrescriptionUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patient/view/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppControllerService.ViewPrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  viewPrescriptionUsingPATCH(params: PatientAppControllerService.ViewPrescriptionUsingPATCHParams): Observable<{}> {
    return this.viewPrescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientAppControllerService {

  /**
   * Parameters for getSpecialityWiseDoctorsUsingGET
   */
  export interface GetSpecialityWiseDoctorsUsingGETParams {

    /**
     * speciality
     */
    speciality?: Array<number>;

    /**
     * sortBy
     */
    sortBy?: string;
    sort?: string;
    size?: number;

    /**
     * search
     */
    search?: string;
    page?: number;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;

    /**
     * currentDate
     */
    currentDate?: string;

    /**
     * cluster
     */
    cluster?: Array<string>;

    /**
     * city
     */
    city?: Array<string>;

    /**
     * available
     */
    available?: string;

    /**
     * asc
     */
    asc?: boolean;
  }

  /**
   * Parameters for fetchPatientsByClinicUsingGET
   */
  export interface FetchPatientsByClinicUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * name
     */
    name?: string;

    /**
     * ids
     */
    ids?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingGET
   */
  export interface GetDoctorBasedSpecialityUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingHEAD
   */
  export interface GetDoctorBasedSpecialityUsingHEADParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingPOST
   */
  export interface GetDoctorBasedSpecialityUsingPOSTParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingPUT
   */
  export interface GetDoctorBasedSpecialityUsingPUTParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingDELETE
   */
  export interface GetDoctorBasedSpecialityUsingDELETEParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingOPTIONS
   */
  export interface GetDoctorBasedSpecialityUsingOPTIONSParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorBasedSpecialityUsingPATCH
   */
  export interface GetDoctorBasedSpecialityUsingPATCHParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientPrescriptionUsingGET
   */
  export interface GetPatientPrescriptionUsingGETParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingHEAD
   */
  export interface GetPatientPrescriptionUsingHEADParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingPOST
   */
  export interface GetPatientPrescriptionUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingPUT
   */
  export interface GetPatientPrescriptionUsingPUTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingDELETE
   */
  export interface GetPatientPrescriptionUsingDELETEParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingOPTIONS
   */
  export interface GetPatientPrescriptionUsingOPTIONSParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingPATCH
   */
  export interface GetPatientPrescriptionUsingPATCHParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getRecentCareProviderUsingGET
   */
  export interface GetRecentCareProviderUsingGETParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentCareProviderUsingHEAD
   */
  export interface GetRecentCareProviderUsingHEADParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentCareProviderUsingPOST
   */
  export interface GetRecentCareProviderUsingPOSTParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentCareProviderUsingPUT
   */
  export interface GetRecentCareProviderUsingPUTParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentCareProviderUsingDELETE
   */
  export interface GetRecentCareProviderUsingDELETEParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentCareProviderUsingOPTIONS
   */
  export interface GetRecentCareProviderUsingOPTIONSParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentCareProviderUsingPATCH
   */
  export interface GetRecentCareProviderUsingPATCHParams {

    /**
     * patientId
     */
    patientId: Array<number>;

    /**
     * basedOn
     */
    basedOn: string;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getSpecialityWiseDoctorsUsingGET_1
   */
  export interface GetSpecialityWiseDoctorsUsingGET_1Params {

    /**
     * longitude
     */
    longitude: number;

    /**
     * latitude
     */
    latitude: number;

    /**
     * speciality
     */
    speciality?: number;
  }

  /**
   * Parameters for getPatientPrescriptionUsingGET_1
   */
  export interface GetPatientPrescriptionUsingGET_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingHEAD_1
   */
  export interface GetPatientPrescriptionUsingHEAD_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingPOST_1
   */
  export interface GetPatientPrescriptionUsingPOST_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingPUT_1
   */
  export interface GetPatientPrescriptionUsingPUT_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingDELETE_1
   */
  export interface GetPatientPrescriptionUsingDELETE_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingOPTIONS_1
   */
  export interface GetPatientPrescriptionUsingOPTIONS_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for getPatientPrescriptionUsingPATCH_1
   */
  export interface GetPatientPrescriptionUsingPATCH_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;
  }

  /**
   * Parameters for patchPatientProfilePicUsingPATCH
   */
  export interface PatchPatientProfilePicUsingPATCHParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * file
     */
    file: Blob;
  }

  /**
   * Parameters for uploadImageUsingPOST
   */
  export interface UploadImageUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * patientDocumentType
     */
    patientDocumentType: 'PRESCRIPTION' | 'DIAGNOSTIC_REPORT' | 'NONE';

    /**
     * file
     */
    file: Array<Blob>;

    /**
     * documentCreatedDate
     */
    documentCreatedDate: string;

    /**
     * comment
     */
    comment: string;
  }

  /**
   * Parameters for savePatientProfilePicUsingPOST
   */
  export interface SavePatientProfilePicUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * file
     */
    file: Blob;
  }

  /**
   * Parameters for viewPrescriptionUsingGET
   */
  export interface ViewPrescriptionUsingGETParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }

  /**
   * Parameters for viewPrescriptionUsingHEAD
   */
  export interface ViewPrescriptionUsingHEADParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }

  /**
   * Parameters for viewPrescriptionUsingPOST
   */
  export interface ViewPrescriptionUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }

  /**
   * Parameters for viewPrescriptionUsingPUT
   */
  export interface ViewPrescriptionUsingPUTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }

  /**
   * Parameters for viewPrescriptionUsingDELETE
   */
  export interface ViewPrescriptionUsingDELETEParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }

  /**
   * Parameters for viewPrescriptionUsingOPTIONS
   */
  export interface ViewPrescriptionUsingOPTIONSParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }

  /**
   * Parameters for viewPrescriptionUsingPATCH
   */
  export interface ViewPrescriptionUsingPATCHParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
  }
}

export { PatientAppControllerService }
