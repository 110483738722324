/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesExistingCondition } from '../models/resources-existing-condition-';
import { ResourceExistingCondition } from '../models/resource-existing-condition-';
import { ExistingCondition } from '../models/existing-condition';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceExistingConditionType } from '../models/resource-existing-condition-type-';

/**
 * $ Proxy 209
 */
@Injectable()
class ExistingConditionEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ExistingConditionEntityService.FindAllExistingConditionUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllExistingConditionUsingGETResponse(params: ExistingConditionEntityService.FindAllExistingConditionUsingGETParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingCondition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.FindAllExistingConditionUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllExistingConditionUsingGET(params: ExistingConditionEntityService.FindAllExistingConditionUsingGETParams): Observable<ResourcesExistingCondition> {
    return this.findAllExistingConditionUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveExistingConditionUsingPOSTResponse(body: ExistingCondition): Observable<HttpResponse<ResourceExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/existingCondition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingCondition = null;
        _body = _resp.body as ResourceExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingCondition>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveExistingConditionUsingPOST(body: ExistingCondition): Observable<ResourceExistingCondition> {
    return this.saveExistingConditionUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdExistingConditionUsingGETResponse(id: number): Observable<HttpResponse<ResourceExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingCondition/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingCondition = null;
        _body = _resp.body as ResourceExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingCondition>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdExistingConditionUsingGET(id: number): Observable<ResourceExistingCondition> {
    return this.findByIdExistingConditionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.SaveExistingConditionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionUsingPUTResponse(params: ExistingConditionEntityService.SaveExistingConditionUsingPUTParams): Observable<HttpResponse<ResourceExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingCondition = null;
        _body = _resp.body as ResourceExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.SaveExistingConditionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionUsingPUT(params: ExistingConditionEntityService.SaveExistingConditionUsingPUTParams): Observable<ResourceExistingCondition> {
    return this.saveExistingConditionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteExistingConditionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/existingCondition/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteExistingConditionUsingDELETE(id: number): Observable<void> {
    return this.deleteExistingConditionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.SaveExistingConditionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionUsingPATCHResponse(params: ExistingConditionEntityService.SaveExistingConditionUsingPATCHParams): Observable<HttpResponse<ResourceExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingCondition = null;
        _body = _resp.body as ResourceExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.SaveExistingConditionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionUsingPATCH(params: ExistingConditionEntityService.SaveExistingConditionUsingPATCHParams): Observable<ResourceExistingCondition> {
    return this.saveExistingConditionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  existingConditionConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingCondition/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  existingConditionConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.existingConditionConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionConsultationUsingPOSTResponse(params: ExistingConditionEntityService.ExistingConditionConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionConsultationUsingPOST(params: ExistingConditionEntityService.ExistingConditionConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.existingConditionConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionConsultationUsingPUTResponse(params: ExistingConditionEntityService.ExistingConditionConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionConsultationUsingPUT(params: ExistingConditionEntityService.ExistingConditionConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.existingConditionConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  existingConditionConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/existingCondition/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  existingConditionConsultationUsingDELETE(id: number): Observable<void> {
    return this.existingConditionConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionConsultationUsingPATCHResponse(params: ExistingConditionEntityService.ExistingConditionConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionConsultationUsingPATCH(params: ExistingConditionEntityService.ExistingConditionConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.existingConditionConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  existingConditionExistingConditionTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingCondition/${id}/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  existingConditionExistingConditionTypeUsingGET(id: number): Observable<ResourceExistingConditionType> {
    return this.existingConditionExistingConditionTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionExistingConditionTypeUsingPOSTResponse(params: ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPOSTParams): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionExistingConditionTypeUsingPOST(params: ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPOSTParams): Observable<ResourceExistingConditionType> {
    return this.existingConditionExistingConditionTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionExistingConditionTypeUsingPUTResponse(params: ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPUTParams): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionExistingConditionTypeUsingPUT(params: ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPUTParams): Observable<ResourceExistingConditionType> {
    return this.existingConditionExistingConditionTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  existingConditionExistingConditionTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/existingCondition/${id}/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  existingConditionExistingConditionTypeUsingDELETE(id: number): Observable<void> {
    return this.existingConditionExistingConditionTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionExistingConditionTypeUsingPATCHResponse(params: ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPATCHParams): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/existingCondition/${params.id}/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionExistingConditionTypeUsingPATCH(params: ExistingConditionEntityService.ExistingConditionExistingConditionTypeUsingPATCHParams): Observable<ResourceExistingConditionType> {
    return this.existingConditionExistingConditionTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ExistingConditionEntityService {

  /**
   * Parameters for findAllExistingConditionUsingGET
   */
  export interface FindAllExistingConditionUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveExistingConditionUsingPUT
   */
  export interface SaveExistingConditionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ExistingCondition;
  }

  /**
   * Parameters for saveExistingConditionUsingPATCH
   */
  export interface SaveExistingConditionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ExistingCondition;
  }

  /**
   * Parameters for existingConditionConsultationUsingPOST
   */
  export interface ExistingConditionConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for existingConditionConsultationUsingPUT
   */
  export interface ExistingConditionConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for existingConditionConsultationUsingPATCH
   */
  export interface ExistingConditionConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for existingConditionExistingConditionTypeUsingPOST
   */
  export interface ExistingConditionExistingConditionTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for existingConditionExistingConditionTypeUsingPUT
   */
  export interface ExistingConditionExistingConditionTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for existingConditionExistingConditionTypeUsingPATCH
   */
  export interface ExistingConditionExistingConditionTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { ExistingConditionEntityService }
