/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceDiagnostics } from '../models/resource-diagnostics-';

/**
 * Diagnostic Controller
 */
@Injectable()
class MeddoLiteControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  getMeddoLeadUsingGETResponse(id): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/meddo-lite/leads/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @param id string
   * @return leads array
   */
  getMeddoLeadGET(id): Observable<ResourceDiagnostics> {
    return this.getMeddoLeadUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  getDeclineReasonUsingGETResponse(params): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.declineReasonType != null) __params = __params.set('declineReasonType', params.declineReasonType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/decline-reason/search/type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @return leads array
   */
  getDeclineReasonGET(params): Observable<ResourceDiagnostics> {
    return this.getDeclineReasonUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  getMeddoLeadsUsingGETResponse(params): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.duration != null) __params = __params.set('duration', params.duration.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/meddo-lite/leads`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @return leads array
   */
  getMeddoLeadsGET(params): Observable<ResourceDiagnostics> {
    return this.getMeddoLeadsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  getMeddoLeadsUsingStatusGETResponse(params): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.duration != null) __params = __params.set('duration', params.duration.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/meddo-lite/leads/search/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @return leads array
   */
  getMeddoLeadsUsingStatusGET(params): Observable<ResourceDiagnostics> {
    return this.getMeddoLeadsUsingStatusGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }


  getMeddoLeadsWithIssueUsingStatusGETResponse(params): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.duration != null) __params = __params.set('duration', params.duration.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.issueId != null) __params = __params.set('issueId', params.issueId.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/meddo-lite/leads/search/status/issue-id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @return leads array
   */
  getMeddoLeadsWithIssueUsingStatusGET(params): Observable<ResourceDiagnostics> {
    return this.getMeddoLeadsWithIssueUsingStatusGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  patchLiteUsingPATCHResponse(body): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/meddo-lite/leads/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @return leads array
   */
  patchLiteusringPATCH(body): Observable<ResourceDiagnostics> {
    return this.patchLiteUsingPATCHResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  addConsultationPOSTResponse(body): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/meddo-lite/consultation/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @return leads array
   */
  addConsultationPOST(body): Observable<ResourceDiagnostics> {
    return this.addConsultationPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

}

export { MeddoLiteControllerService }
