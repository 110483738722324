/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPayment } from '../models/resources-payment-';
import { ResourcePayment } from '../models/resource-payment-';
import { Payment } from '../models/payment';
import { ResourceOrder } from '../models/resource-order-';
import { ResourcePaymentMode } from '../models/resource-payment-mode-';

/**
 * $ Proxy 173
 */
@Injectable()
class PaymentEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PaymentEntityService.FindAllPaymentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPaymentUsingGETResponse(params: PaymentEntityService.FindAllPaymentUsingGETParams): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.FindAllPaymentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPaymentUsingGET(params: PaymentEntityService.FindAllPaymentUsingGETParams): Observable<ResourcesPayment> {
    return this.findAllPaymentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePaymentUsingPOSTResponse(body: Payment): Observable<HttpResponse<ResourcePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePayment = null;
        _body = _resp.body as ResourcePayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePayment>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePaymentUsingPOST(body: Payment): Observable<ResourcePayment> {
    return this.savePaymentUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.FindByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `status`: status
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  findByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETResponse(params: PaymentEntityService.FindByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETParams): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.year != null) __params = __params.set('year', params.year.toString());
    if (params.year != null) __params = __params.set('year', params.year.toString());
    if (params.timezoneOffset != null) __params = __params.set('timezoneOffset', params.timezoneOffset.toString());
    if (params.timezoneOffset != null) __params = __params.set('timezoneOffset', params.timezoneOffset.toString());
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.seconds != null) __params = __params.set('seconds', params.seconds.toString());
    if (params.seconds != null) __params = __params.set('seconds', params.seconds.toString());
    if (params.nanos != null) __params = __params.set('nanos', params.nanos.toString());
    if (params.nanos != null) __params = __params.set('nanos', params.nanos.toString());
    if (params.month != null) __params = __params.set('month', params.month.toString());
    if (params.month != null) __params = __params.set('month', params.month.toString());
    if (params.minutes != null) __params = __params.set('minutes', params.minutes.toString());
    if (params.minutes != null) __params = __params.set('minutes', params.minutes.toString());
    if (params.hours != null) __params = __params.set('hours', params.hours.toString());
    if (params.hours != null) __params = __params.set('hours', params.hours.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.day != null) __params = __params.set('day', params.day.toString());
    if (params.day != null) __params = __params.set('day', params.day.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payments/search/findByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.FindByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `status`: status
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  findByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGET(params: PaymentEntityService.FindByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETParams): Observable<ResourcesPayment> {
    return this.findByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.FindByOrderDoctorInClinicIdInPaymentUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * @return OK
   */
  findByOrderDoctorInClinicIdInPaymentUsingGETResponse(params: PaymentEntityService.FindByOrderDoctorInClinicIdInPaymentUsingGETParams): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payments/search/findByOrderDoctorInClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.FindByOrderDoctorInClinicIdInPaymentUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * @return OK
   */
  findByOrderDoctorInClinicIdInPaymentUsingGET(params: PaymentEntityService.FindByOrderDoctorInClinicIdInPaymentUsingGETParams): Observable<ResourcesPayment> {
    return this.findByOrderDoctorInClinicIdInPaymentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPaymentUsingGETResponse(id: number): Observable<HttpResponse<ResourcePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePayment = null;
        _body = _resp.body as ResourcePayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePayment>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPaymentUsingGET(id: number): Observable<ResourcePayment> {
    return this.findByIdPaymentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.SavePaymentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentUsingPUTResponse(params: PaymentEntityService.SavePaymentUsingPUTParams): Observable<HttpResponse<ResourcePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/payments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePayment = null;
        _body = _resp.body as ResourcePayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePayment>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.SavePaymentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentUsingPUT(params: PaymentEntityService.SavePaymentUsingPUTParams): Observable<ResourcePayment> {
    return this.savePaymentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePaymentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/payments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePaymentUsingDELETE(id: number): Observable<void> {
    return this.deletePaymentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.SavePaymentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentUsingPATCHResponse(params: PaymentEntityService.SavePaymentUsingPATCHParams): Observable<HttpResponse<ResourcePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/payments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePayment = null;
        _body = _resp.body as ResourcePayment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePayment>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.SavePaymentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentUsingPATCH(params: PaymentEntityService.SavePaymentUsingPATCHParams): Observable<ResourcePayment> {
    return this.savePaymentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  paymentOrderUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payments/${id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  paymentOrderUsingGET(id: number): Observable<ResourceOrder> {
    return this.paymentOrderUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentOrderUsingPOSTResponse(params: PaymentEntityService.PaymentOrderUsingPOSTParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/payments/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentOrderUsingPOST(params: PaymentEntityService.PaymentOrderUsingPOSTParams): Observable<ResourceOrder> {
    return this.paymentOrderUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentOrderUsingPUTResponse(params: PaymentEntityService.PaymentOrderUsingPUTParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/payments/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentOrderUsingPUT(params: PaymentEntityService.PaymentOrderUsingPUTParams): Observable<ResourceOrder> {
    return this.paymentOrderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  paymentOrderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/payments/${id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  paymentOrderUsingDELETE(id: number): Observable<void> {
    return this.paymentOrderUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentOrderUsingPATCHResponse(params: PaymentEntityService.PaymentOrderUsingPATCHParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/payments/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentOrderUsingPATCH(params: PaymentEntityService.PaymentOrderUsingPATCHParams): Observable<ResourceOrder> {
    return this.paymentOrderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  paymentPaymentModeUsingGETResponse(id: number): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/payments/${id}/paymentMode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  paymentPaymentModeUsingGET(id: number): Observable<ResourcePaymentMode> {
    return this.paymentPaymentModeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentPaymentModeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentPaymentModeUsingPOSTResponse(params: PaymentEntityService.PaymentPaymentModeUsingPOSTParams): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/payments/${params.id}/paymentMode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentPaymentModeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentPaymentModeUsingPOST(params: PaymentEntityService.PaymentPaymentModeUsingPOSTParams): Observable<ResourcePaymentMode> {
    return this.paymentPaymentModeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentPaymentModeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentPaymentModeUsingPUTResponse(params: PaymentEntityService.PaymentPaymentModeUsingPUTParams): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/payments/${params.id}/paymentMode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentPaymentModeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentPaymentModeUsingPUT(params: PaymentEntityService.PaymentPaymentModeUsingPUTParams): Observable<ResourcePaymentMode> {
    return this.paymentPaymentModeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  paymentPaymentModeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/payments/${id}/paymentMode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  paymentPaymentModeUsingDELETE(id: number): Observable<void> {
    return this.paymentPaymentModeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentPaymentModeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentPaymentModeUsingPATCHResponse(params: PaymentEntityService.PaymentPaymentModeUsingPATCHParams): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/payments/${params.id}/paymentMode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentEntityService.PaymentPaymentModeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  paymentPaymentModeUsingPATCH(params: PaymentEntityService.PaymentPaymentModeUsingPATCHParams): Observable<ResourcePaymentMode> {
    return this.paymentPaymentModeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PaymentEntityService {

  /**
   * Parameters for findAllPaymentUsingGET
   */
  export interface FindAllPaymentUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGET
   */
  export interface FindByOrderDoctorInClinicIdAndOrderStatusAndCreatedAtGreaterThanEqualAndCreatedAtLessThanPaymentUsingGETParams {
    year?: number;
    timezoneOffset?: number;
    time?: number;

    /**
     * status
     */
    status?: 'PENDING' | 'PARTIALLY_PAID' | 'PAID' | 'PAY_LATER' | 'PROCESSING' | 'PROCESSED' | 'COMPLETED' | 'CANCELLED' | 'REFUNDED';
    seconds?: number;
    nanos?: number;
    month?: number;
    minutes?: number;
    hours?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
    day?: number;
    date?: number;
  }

  /**
   * Parameters for findByOrderDoctorInClinicIdInPaymentUsingGET
   */
  export interface FindByOrderDoctorInClinicIdInPaymentUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinicIds
     */
    doctorInClinicIds?: Array<number>;
  }

  /**
   * Parameters for savePaymentUsingPUT
   */
  export interface SavePaymentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Payment;
  }

  /**
   * Parameters for savePaymentUsingPATCH
   */
  export interface SavePaymentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Payment;
  }

  /**
   * Parameters for paymentOrderUsingPOST
   */
  export interface PaymentOrderUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for paymentOrderUsingPUT
   */
  export interface PaymentOrderUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for paymentOrderUsingPATCH
   */
  export interface PaymentOrderUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for paymentPaymentModeUsingPOST
   */
  export interface PaymentPaymentModeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for paymentPaymentModeUsingPUT
   */
  export interface PaymentPaymentModeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for paymentPaymentModeUsingPATCH
   */
  export interface PaymentPaymentModeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { PaymentEntityService }
