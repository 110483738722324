import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-cart-summary',
  templateUrl: './order-cart-summary.component.html',
  styleUrls: ['./order-cart-summary.component.scss']
})
export class OrderCartSummaryComponent implements OnInit {
  accordionPanelOpenState = false
  @Input() order;

  constructor() { }

  getOrder() {
    if (this.order) {
      return this.order;
    }
  }

  ngOnInit() { }

}
