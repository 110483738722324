/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Patient App My Care Controller
 */
@Injectable()
class PatientAppMyCareControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingGETResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingGET(params: PatientAppMyCareControllerService.TodayAppointmentUsingGETParams): Observable<{}> {
    return this.todayAppointmentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingHEADResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingHEAD(params: PatientAppMyCareControllerService.TodayAppointmentUsingHEADParams): Observable<{}> {
    return this.todayAppointmentUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingPOSTResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingPOST(params: PatientAppMyCareControllerService.TodayAppointmentUsingPOSTParams): Observable<{}> {
    return this.todayAppointmentUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingPUTResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingPUT(params: PatientAppMyCareControllerService.TodayAppointmentUsingPUTParams): Observable<{}> {
    return this.todayAppointmentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingDELETEResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingDELETE(params: PatientAppMyCareControllerService.TodayAppointmentUsingDELETEParams): Observable<{}> {
    return this.todayAppointmentUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingOPTIONSResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingOPTIONS(params: PatientAppMyCareControllerService.TodayAppointmentUsingOPTIONSParams): Observable<{}> {
    return this.todayAppointmentUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingPATCHResponse(params: PatientAppMyCareControllerService.TodayAppointmentUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.basedOn != null) __params = __params.set('basedOn', params.basedOn.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/mycare/patient/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.TodayAppointmentUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `basedOn`: basedOn
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * @return OK
   */
  todayAppointmentUsingPATCH(params: PatientAppMyCareControllerService.TodayAppointmentUsingPATCHParams): Observable<{}> {
    return this.todayAppointmentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingGETResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingGET(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingGETParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingHEADResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingHEAD(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingHEADParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingPOSTResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingPOST(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPOSTParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingPUTResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingPUT(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPUTParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingDELETEResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingDELETE(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingDELETEParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingOPTIONSResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingOPTIONS(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingOPTIONSParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingPATCHResponse(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.patientId || []).forEach((val, index) => {if (val != null) __params = __params.append('patientId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/mycare/patient/diagnostic/test`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDiagnosticForPatientUsingPATCH(params: PatientAppMyCareControllerService.GetDiagnosticForPatientUsingPATCHParams): Observable<{}> {
    return this.getDiagnosticForPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingGETResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingGET(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingGETParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingHEADResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingHEADParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingHEAD(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingHEADParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingPOSTResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingPOST(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPOSTParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingPUTResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPUTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingPUT(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPUTParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingDELETEResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingDELETE(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingDELETEParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingOPTIONSResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingOPTIONS(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingOPTIONSParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingPATCHResponse(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/mycare/patient/recent/activity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPATCHParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getRecentActivityOfPatientUsingPATCH(params: PatientAppMyCareControllerService.GetRecentActivityOfPatientUsingPATCHParams): Observable<{}> {
    return this.getRecentActivityOfPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientAppMyCareControllerService {

  /**
   * Parameters for todayAppointmentUsingGET
   */
  export interface TodayAppointmentUsingGETParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for todayAppointmentUsingHEAD
   */
  export interface TodayAppointmentUsingHEADParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for todayAppointmentUsingPOST
   */
  export interface TodayAppointmentUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for todayAppointmentUsingPUT
   */
  export interface TodayAppointmentUsingPUTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for todayAppointmentUsingDELETE
   */
  export interface TodayAppointmentUsingDELETEParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for todayAppointmentUsingOPTIONS
   */
  export interface TodayAppointmentUsingOPTIONSParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for todayAppointmentUsingPATCH
   */
  export interface TodayAppointmentUsingPATCHParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * basedOn
     */
    basedOn: string;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingGET
   */
  export interface GetDiagnosticForPatientUsingGETParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingHEAD
   */
  export interface GetDiagnosticForPatientUsingHEADParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingPOST
   */
  export interface GetDiagnosticForPatientUsingPOSTParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingPUT
   */
  export interface GetDiagnosticForPatientUsingPUTParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingDELETE
   */
  export interface GetDiagnosticForPatientUsingDELETEParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingOPTIONS
   */
  export interface GetDiagnosticForPatientUsingOPTIONSParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDiagnosticForPatientUsingPATCH
   */
  export interface GetDiagnosticForPatientUsingPATCHParams {

    /**
     * patientId
     */
    patientId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingGET
   */
  export interface GetRecentActivityOfPatientUsingGETParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingHEAD
   */
  export interface GetRecentActivityOfPatientUsingHEADParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingPOST
   */
  export interface GetRecentActivityOfPatientUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingPUT
   */
  export interface GetRecentActivityOfPatientUsingPUTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingDELETE
   */
  export interface GetRecentActivityOfPatientUsingDELETEParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingOPTIONS
   */
  export interface GetRecentActivityOfPatientUsingOPTIONSParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getRecentActivityOfPatientUsingPATCH
   */
  export interface GetRecentActivityOfPatientUsingPATCHParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }
}

export { PatientAppMyCareControllerService }
