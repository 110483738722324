import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor() { }

  paymentModeIds: any = {
    'CASHID': 2, //cash payment ID
    'CREDIT_ID': 3, //credit card /debit card id
    'WALLET': 5, // wallet id
    'PAID_TO_DOCTOR': 6,
    'MEDDO_WALLET': 8,
    'ONLINE': 9,
    'INSURANCE': 11
  };

  issueType = {
    'meddoLite' : 'LITE_CONSULTATION',
    'consultation': 'CONSULTATION',
    'pathologyOrder': 'PATHOLOGY_ORDER',
    'medicineOrder': 'MEDICINE_ORDER',
    'otherOrder': 'OTHER_ORDER',
    'subscriptionNotPurchased': 'SUBSCRIPTION_NOT_PURCHASED'
  }

  pathologyConstant = {
    inClinic: 'now',
    atHome: 'later',
    deferred: 'deferred'
  }

  diagnosticTypeStatus = {
    radiology: "RADIOLOGY",
    pathology: "PATHOLOGY"
  }

  liteConsultation: any = null;
}
