/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesSymptomType } from '../models/resources-symptom-type-';
import { ResourceSymptomType } from '../models/resource-symptom-type-';
import { SymptomType } from '../models/symptom-type';
import { ResourcesSymptom } from '../models/resources-symptom-';
import { ResourceSymptom } from '../models/resource-symptom-';

/**
 * $ Proxy 196
 */
@Injectable()
class SymptomTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SymptomTypeEntityService.FindAllSymptomTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSymptomTypeUsingGETResponse(params: SymptomTypeEntityService.FindAllSymptomTypeUsingGETParams): Observable<HttpResponse<ResourcesSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptomTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomType = null;
        _body = _resp.body as ResourcesSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomType>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.FindAllSymptomTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSymptomTypeUsingGET(params: SymptomTypeEntityService.FindAllSymptomTypeUsingGETParams): Observable<ResourcesSymptomType> {
    return this.findAllSymptomTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSymptomTypeUsingPOSTResponse(body: SymptomType): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptomTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSymptomTypeUsingPOST(body: SymptomType): Observable<ResourceSymptomType> {
    return this.saveSymptomTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptoms symptoms
   * @return OK
   */
  findSymptomTypeByNameSymptomTypeUsingGETResponse(symptoms?: string): Observable<HttpResponse<ResourcesSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptoms != null) __params = __params.set('symptoms', symptoms.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptomTypes/search/findSymptomTypeByName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptomType = null;
        _body = _resp.body as ResourcesSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptomType>;
      })
    );
  }

  /**
   * @param symptoms symptoms
   * @return OK
   */
  findSymptomTypeByNameSymptomTypeUsingGET(symptoms?: string): Observable<ResourcesSymptomType> {
    return this.findSymptomTypeByNameSymptomTypeUsingGETResponse(symptoms).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSymptomTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptomTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSymptomTypeUsingGET(id: number): Observable<ResourceSymptomType> {
    return this.findByIdSymptomTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SaveSymptomTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomTypeUsingPUTResponse(params: SymptomTypeEntityService.SaveSymptomTypeUsingPUTParams): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SaveSymptomTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomTypeUsingPUT(params: SymptomTypeEntityService.SaveSymptomTypeUsingPUTParams): Observable<ResourceSymptomType> {
    return this.saveSymptomTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteSymptomTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptomTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteSymptomTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteSymptomTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SaveSymptomTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomTypeUsingPATCHResponse(params: SymptomTypeEntityService.SaveSymptomTypeUsingPATCHParams): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SaveSymptomTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomTypeUsingPATCH(params: SymptomTypeEntityService.SaveSymptomTypeUsingPATCHParams): Observable<ResourceSymptomType> {
    return this.saveSymptomTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  symptomTypeSymptomsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptomTypes/${id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  symptomTypeSymptomsUsingGET_1(id: number): Observable<ResourcesSymptom> {
    return this.symptomTypeSymptomsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomTypeSymptomsUsingPOSTResponse(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingPOSTParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomTypeSymptomsUsingPOST(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingPOSTParams): Observable<ResourcesSymptom> {
    return this.symptomTypeSymptomsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomTypeSymptomsUsingPUTResponse(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingPUTParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomTypeSymptomsUsingPUT(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingPUTParams): Observable<ResourcesSymptom> {
    return this.symptomTypeSymptomsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  symptomTypeSymptomsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptomTypes/${id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  symptomTypeSymptomsUsingDELETE_1(id: number): Observable<void> {
    return this.symptomTypeSymptomsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomTypeSymptomsUsingPATCHResponse(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingPATCHParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomTypeSymptomsUsingPATCH(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingPATCHParams): Observable<ResourcesSymptom> {
    return this.symptomTypeSymptomsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingGETParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  symptomTypeSymptomsUsingGETResponse(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingGETParams): Observable<HttpResponse<ResourceSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}/symptoms/${params.symptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptom = null;
        _body = _resp.body as ResourceSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingGETParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  symptomTypeSymptomsUsingGET(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingGETParams): Observable<ResourceSymptom> {
    return this.symptomTypeSymptomsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingDELETEParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   */
  symptomTypeSymptomsUsingDELETEResponse(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptomTypes/${params.id}/symptoms/${params.symptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `SymptomTypeEntityService.SymptomTypeSymptomsUsingDELETEParams` containing the following parameters:
   *
   * - `symptomId`: symptomId
   *
   * - `id`: id
   */
  symptomTypeSymptomsUsingDELETE(params: SymptomTypeEntityService.SymptomTypeSymptomsUsingDELETEParams): Observable<void> {
    return this.symptomTypeSymptomsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SymptomTypeEntityService {

  /**
   * Parameters for findAllSymptomTypeUsingGET
   */
  export interface FindAllSymptomTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveSymptomTypeUsingPUT
   */
  export interface SaveSymptomTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: SymptomType;
  }

  /**
   * Parameters for saveSymptomTypeUsingPATCH
   */
  export interface SaveSymptomTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: SymptomType;
  }

  /**
   * Parameters for symptomTypeSymptomsUsingPOST
   */
  export interface SymptomTypeSymptomsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for symptomTypeSymptomsUsingPUT
   */
  export interface SymptomTypeSymptomsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for symptomTypeSymptomsUsingPATCH
   */
  export interface SymptomTypeSymptomsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for symptomTypeSymptomsUsingGET
   */
  export interface SymptomTypeSymptomsUsingGETParams {

    /**
     * symptomId
     */
    symptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for symptomTypeSymptomsUsingDELETE
   */
  export interface SymptomTypeSymptomsUsingDELETEParams {

    /**
     * symptomId
     */
    symptomId: string;

    /**
     * id
     */
    id: number;
  }
}

export { SymptomTypeEntityService }
