/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Resourcesobject } from '../models/resources-object-';
import { ResourcesPatientListDetail } from '../models/resources-patient-list-detail-';
import { ResourcesPatientForSearchPatient } from '../models/resources-patient-for-search-patient-';
import { ResourcesConsultationForPatientSearch } from '../models/resources-consultation-for-patient-search-';

/**
 * Patient Medical History Controller
 */
@Injectable()
class PatientMedicalHistoryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingGET_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingGET_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingGET_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingGET_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingGET_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingGET_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingGET_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingHEAD_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingHEAD_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingHEAD_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingHEAD_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingHEAD_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingHEAD_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingHEAD_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPOST_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPOST_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPOST_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPOST_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPOST_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPOST_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingPOST_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPUT_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPUT_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPUT_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPUT_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPUT_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPUT_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingPUT_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingDELETE_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingDELETE_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingDELETE_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingDELETE_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingDELETE_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingDELETE_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingDELETE_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingOPTIONS_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingOPTIONS_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingOPTIONS_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingOPTIONS_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingOPTIONS_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingOPTIONS_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingOPTIONS_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPATCH_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPATCH_1Response(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPATCH_1Params): Observable<HttpResponse<Resourcesobject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesobject = null;
        _body = _resp.body as Resourcesobject;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesobject>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPATCH_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getDoctorPatientListBetweenDateUsingPATCH_1(params: PatientMedicalHistoryControllerService.GetDoctorPatientListBetweenDateUsingPATCH_1Params): Observable<Resourcesobject> {
    return this.getDoctorPatientListBetweenDateUsingPATCH_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingGET_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingGET_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingGET_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingGET_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingGET_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingGET_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingGET_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingHEAD_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingHEAD_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingHEAD_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingHEAD_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingHEAD_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingHEAD_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingHEAD_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPOST_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingPOST_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPOST_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPOST_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingPOST_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPOST_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingPOST_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPUT_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingPUT_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPUT_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPUT_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingPUT_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPUT_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingPUT_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingDELETE_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingDELETE_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingDELETE_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingDELETE_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingDELETE_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingDELETE_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingDELETE_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingOPTIONS_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingOPTIONS_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingOPTIONS_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingOPTIONS_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingOPTIONS_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingOPTIONS_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingOPTIONS_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPATCH_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingPATCH_1Response(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPATCH_1Params): Observable<HttpResponse<ResourcesPatientListDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/patient/visit/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientListDetail = null;
        _body = _resp.body as ResourcesPatientListDetail;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientListDetail>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPATCH_1Params` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * - `condition`: condition
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getPatientOnFilterUsingPATCH_1(params: PatientMedicalHistoryControllerService.GetPatientOnFilterUsingPATCH_1Params): Observable<ResourcesPatientListDetail> {
    return this.getPatientOnFilterUsingPATCH_1Response(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingGETParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingGETResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingGETParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingGETParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingGET(params: PatientMedicalHistoryControllerService.SearchPatientUsingGETParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingHEADParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingHEADResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingHEADParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingHEADParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingHEAD(params: PatientMedicalHistoryControllerService.SearchPatientUsingHEADParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingPOSTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingPOSTResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingPOSTParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingPOSTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingPOST(params: PatientMedicalHistoryControllerService.SearchPatientUsingPOSTParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingPUTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingPUTResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingPUTParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingPUTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingPUT(params: PatientMedicalHistoryControllerService.SearchPatientUsingPUTParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingDELETEParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingDELETEResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingDELETEParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingDELETEParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingDELETE(params: PatientMedicalHistoryControllerService.SearchPatientUsingDELETEParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingOPTIONSResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingOPTIONSParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingOPTIONS(params: PatientMedicalHistoryControllerService.SearchPatientUsingOPTIONSParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingPATCHParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingPATCHResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingPATCHParams): Observable<HttpResponse<ResourcesPatientForSearchPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/search/consultation/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientForSearchPatient = null;
        _body = _resp.body as ResourcesPatientForSearchPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientForSearchPatient>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingPATCHParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingPATCH(params: PatientMedicalHistoryControllerService.SearchPatientUsingPATCHParams): Observable<ResourcesPatientForSearchPatient> {
    return this.searchPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingGETResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingGET(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingGETParams): Observable<{}> {
    return this.searchApiForPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingHEADResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingHEADParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingHEAD(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingHEADParams): Observable<{}> {
    return this.searchApiForPatientUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingPOSTResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingPOSTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingPOST(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingPOSTParams): Observable<{}> {
    return this.searchApiForPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingPUTResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingPUTParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingPUT(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingPUTParams): Observable<{}> {
    return this.searchApiForPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingDELETEResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingDELETEParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingDELETE(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingDELETEParams): Observable<{}> {
    return this.searchApiForPatientUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingOPTIONSResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingOPTIONSParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingOPTIONS(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingOPTIONSParams): Observable<{}> {
    return this.searchApiForPatientUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingPATCHResponse(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorInclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInclinic', val.toString())});
    (params.condition || []).forEach((val, index) => {if (val != null) __params = __params.append('condition', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/search/patient/for`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchApiForPatientUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `searchQuery`: searchQuery
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * - `doctorInclinic`: doctorInclinic
   *
   * - `condition`: condition
   *
   * @return OK
   */
  searchApiForPatientUsingPATCH(params: PatientMedicalHistoryControllerService.SearchApiForPatientUsingPATCHParams): Observable<{}> {
    return this.searchApiForPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingGETParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingGETResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingGETParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingGETParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingGET(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingGETParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingHEADParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingHEADResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingHEADParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingHEADParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingHEAD(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingHEADParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingPOSTResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPOSTParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingPOST(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPOSTParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPUTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingPUTResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPUTParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPUTParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingPUT(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPUTParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingDELETEParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingDELETEResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingDELETEParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingDELETEParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingDELETE(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingDELETEParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingOPTIONSParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingOPTIONSResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingOPTIONSParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingOPTIONSParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingOPTIONS(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingOPTIONSParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingPATCHResponse(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPATCHParams): Observable<HttpResponse<ResourcesConsultationForPatientSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchQuery != null) __params = __params.set('searchQuery', params.searchQuery.toString());
    (params.dicId || []).forEach((val, index) => {if (val != null) __params = __params.append('dicId', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/search/patient/using/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultationForPatientSearch = null;
        _body = _resp.body as ResourcesConsultationForPatientSearch;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultationForPatientSearch>;
      })
    );
  }

  /**
   * @param params The `PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `searchQuery`: searchQuery
   *
   * - `dicId`: dicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  searchPatientUsingConsultationUsingPATCH(params: PatientMedicalHistoryControllerService.SearchPatientUsingConsultationUsingPATCHParams): Observable<ResourcesConsultationForPatientSearch> {
    return this.searchPatientUsingConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientMedicalHistoryControllerService {

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingGET_1
   */
  export interface GetDoctorPatientListBetweenDateUsingGET_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingHEAD_1
   */
  export interface GetDoctorPatientListBetweenDateUsingHEAD_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingPOST_1
   */
  export interface GetDoctorPatientListBetweenDateUsingPOST_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingPUT_1
   */
  export interface GetDoctorPatientListBetweenDateUsingPUT_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingDELETE_1
   */
  export interface GetDoctorPatientListBetweenDateUsingDELETE_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingOPTIONS_1
   */
  export interface GetDoctorPatientListBetweenDateUsingOPTIONS_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getDoctorPatientListBetweenDateUsingPATCH_1
   */
  export interface GetDoctorPatientListBetweenDateUsingPATCH_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingGET_1
   */
  export interface GetPatientOnFilterUsingGET_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingHEAD_1
   */
  export interface GetPatientOnFilterUsingHEAD_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingPOST_1
   */
  export interface GetPatientOnFilterUsingPOST_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingPUT_1
   */
  export interface GetPatientOnFilterUsingPUT_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingDELETE_1
   */
  export interface GetPatientOnFilterUsingDELETE_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingOPTIONS_1
   */
  export interface GetPatientOnFilterUsingOPTIONS_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for getPatientOnFilterUsingPATCH_1
   */
  export interface GetPatientOnFilterUsingPATCH_1Params {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;

    /**
     * condition
     */
    condition: Array<string>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingGET
   */
  export interface SearchPatientUsingGETParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingHEAD
   */
  export interface SearchPatientUsingHEADParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingPOST
   */
  export interface SearchPatientUsingPOSTParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingPUT
   */
  export interface SearchPatientUsingPUTParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingDELETE
   */
  export interface SearchPatientUsingDELETEParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingOPTIONS
   */
  export interface SearchPatientUsingOPTIONSParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingPATCH
   */
  export interface SearchPatientUsingPATCHParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchApiForPatientUsingGET
   */
  export interface SearchApiForPatientUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchApiForPatientUsingHEAD
   */
  export interface SearchApiForPatientUsingHEADParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchApiForPatientUsingPOST
   */
  export interface SearchApiForPatientUsingPOSTParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchApiForPatientUsingPUT
   */
  export interface SearchApiForPatientUsingPUTParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchApiForPatientUsingDELETE
   */
  export interface SearchApiForPatientUsingDELETEParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchApiForPatientUsingOPTIONS
   */
  export interface SearchApiForPatientUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchApiForPatientUsingPATCH
   */
  export interface SearchApiForPatientUsingPATCHParams {

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;

    /**
     * searchQuery
     */
    searchQuery?: string;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInclinic
     */
    doctorInclinic?: Array<number>;

    /**
     * condition
     */
    condition?: Array<string>;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingGET
   */
  export interface SearchPatientUsingConsultationUsingGETParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingHEAD
   */
  export interface SearchPatientUsingConsultationUsingHEADParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingPOST
   */
  export interface SearchPatientUsingConsultationUsingPOSTParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingPUT
   */
  export interface SearchPatientUsingConsultationUsingPUTParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingDELETE
   */
  export interface SearchPatientUsingConsultationUsingDELETEParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingOPTIONS
   */
  export interface SearchPatientUsingConsultationUsingOPTIONSParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for searchPatientUsingConsultationUsingPATCH
   */
  export interface SearchPatientUsingConsultationUsingPATCHParams {

    /**
     * searchQuery
     */
    searchQuery: string;

    /**
     * dicId
     */
    dicId: Array<number>;
    sort?: string;
    size?: number;
    page?: number;
  }
}

export { PatientMedicalHistoryControllerService }
