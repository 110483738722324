/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesFamily } from '../models/resources-family-';
import { ResourceFamily } from '../models/resource-family-';
import { Family } from '../models/family';
import { ResourcesPatient } from '../models/resources-patient-';
import { ResourcePatient } from '../models/resource-patient-';

/**
 * Simple Jpa Repository
 */
@Injectable()
class FamilyEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FamilyEntityService.FindAllFamilyUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllFamilyUsingGETResponse(params: FamilyEntityService.FindAllFamilyUsingGETParams): Observable<HttpResponse<ResourcesFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/families`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFamily = null;
        _body = _resp.body as ResourcesFamily;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFamily>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.FindAllFamilyUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllFamilyUsingGET(params: FamilyEntityService.FindAllFamilyUsingGETParams): Observable<ResourcesFamily> {
    return this.findAllFamilyUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveFamilyUsingPOSTResponse(body: Family): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/families`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveFamilyUsingPOST(body: Family): Observable<ResourceFamily> {
    return this.saveFamilyUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdFamilyUsingGETResponse(id: number): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/families/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdFamilyUsingGET(id: number): Observable<ResourceFamily> {
    return this.findByIdFamilyUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.SaveFamilyUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFamilyUsingPUTResponse(params: FamilyEntityService.SaveFamilyUsingPUTParams): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/families/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.SaveFamilyUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFamilyUsingPUT(params: FamilyEntityService.SaveFamilyUsingPUTParams): Observable<ResourceFamily> {
    return this.saveFamilyUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteFamilyUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/families/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteFamilyUsingDELETE(id: number): Observable<void> {
    return this.deleteFamilyUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.SaveFamilyUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFamilyUsingPATCHResponse(params: FamilyEntityService.SaveFamilyUsingPATCHParams): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/families/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.SaveFamilyUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFamilyUsingPATCH(params: FamilyEntityService.SaveFamilyUsingPATCHParams): Observable<ResourceFamily> {
    return this.saveFamilyUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  familyPatientUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/families/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  familyPatientUsingGET_1(id: number): Observable<ResourcesPatient> {
    return this.familyPatientUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  familyPatientUsingPOSTResponse(params: FamilyEntityService.FamilyPatientUsingPOSTParams): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/families/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  familyPatientUsingPOST(params: FamilyEntityService.FamilyPatientUsingPOSTParams): Observable<ResourcesPatient> {
    return this.familyPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  familyPatientUsingPUTResponse(params: FamilyEntityService.FamilyPatientUsingPUTParams): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/families/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  familyPatientUsingPUT(params: FamilyEntityService.FamilyPatientUsingPUTParams): Observable<ResourcesPatient> {
    return this.familyPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  familyPatientUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/families/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  familyPatientUsingDELETE_1(id: number): Observable<void> {
    return this.familyPatientUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  familyPatientUsingPATCHResponse(params: FamilyEntityService.FamilyPatientUsingPATCHParams): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/families/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  familyPatientUsingPATCH(params: FamilyEntityService.FamilyPatientUsingPATCHParams): Observable<ResourcesPatient> {
    return this.familyPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `id`: id
   *
   * @return OK
   */
  familyPatientUsingGETResponse(params: FamilyEntityService.FamilyPatientUsingGETParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/families/${params.id}/patient/${params.patientId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `id`: id
   *
   * @return OK
   */
  familyPatientUsingGET(params: FamilyEntityService.FamilyPatientUsingGETParams): Observable<ResourcePatient> {
    return this.familyPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `id`: id
   */
  familyPatientUsingDELETEResponse(params: FamilyEntityService.FamilyPatientUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/families/${params.id}/patient/${params.patientId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `FamilyEntityService.FamilyPatientUsingDELETEParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `id`: id
   */
  familyPatientUsingDELETE(params: FamilyEntityService.FamilyPatientUsingDELETEParams): Observable<void> {
    return this.familyPatientUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module FamilyEntityService {

  /**
   * Parameters for findAllFamilyUsingGET
   */
  export interface FindAllFamilyUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveFamilyUsingPUT
   */
  export interface SaveFamilyUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Family;
  }

  /**
   * Parameters for saveFamilyUsingPATCH
   */
  export interface SaveFamilyUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Family;
  }

  /**
   * Parameters for familyPatientUsingPOST
   */
  export interface FamilyPatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for familyPatientUsingPUT
   */
  export interface FamilyPatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for familyPatientUsingPATCH
   */
  export interface FamilyPatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for familyPatientUsingGET
   */
  export interface FamilyPatientUsingGETParams {

    /**
     * patientId
     */
    patientId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for familyPatientUsingDELETE
   */
  export interface FamilyPatientUsingDELETEParams {

    /**
     * patientId
     */
    patientId: string;

    /**
     * id
     */
    id: number;
  }
}

export { FamilyEntityService }
