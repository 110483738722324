/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPrescription } from '../models/resources-prescription-';
import { ResourcePrescription } from '../models/resource-prescription-';
import { Prescription } from '../models/prescription';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceDeclineReason } from '../models/resource-decline-reason-';
import { ResourceMedicine } from '../models/resource-medicine-';
import { ResourceMedicineInClinic } from '../models/resource-medicine-in-clinic-';
import { ResourcesPrescriptionByDate } from '../models/resources-prescription-by-date-';
import { ResourcePrescriptionByDate } from '../models/resource-prescription-by-date-';
import { ResourcesPrescriptionDescription } from '../models/resources-prescription-description-';
import { ResourcePrescriptionDescription } from '../models/resource-prescription-description-';

/**
 * $ Proxy 222
 */
@Injectable()
class PrescriptionEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PrescriptionEntityService.FindAllPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPrescriptionUsingGETResponse(params: PrescriptionEntityService.FindAllPrescriptionUsingGETParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.FindAllPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPrescriptionUsingGET(params: PrescriptionEntityService.FindAllPrescriptionUsingGETParams): Observable<ResourcesPrescription> {
    return this.findAllPrescriptionUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePrescriptionUsingPOSTResponse(body: Prescription): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePrescriptionUsingPOST(body: Prescription): Observable<ResourcePrescription> {
    return this.savePrescriptionUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.FindByConsultationIdPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdPrescriptionUsingGETResponse(params: PrescriptionEntityService.FindByConsultationIdPrescriptionUsingGETParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.FindByConsultationIdPrescriptionUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdPrescriptionUsingGET(params: PrescriptionEntityService.FindByConsultationIdPrescriptionUsingGETParams): Observable<ResourcesPrescription> {
    return this.findByConsultationIdPrescriptionUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPrescriptionUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPrescriptionUsingGET(id: number): Observable<ResourcePrescription> {
    return this.findByIdPrescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.SavePrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionUsingPUTResponse(params: PrescriptionEntityService.SavePrescriptionUsingPUTParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.SavePrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionUsingPUT(params: PrescriptionEntityService.SavePrescriptionUsingPUTParams): Observable<ResourcePrescription> {
    return this.savePrescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePrescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePrescriptionUsingDELETE(id: number): Observable<void> {
    return this.deletePrescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.SavePrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionUsingPATCHResponse(params: PrescriptionEntityService.SavePrescriptionUsingPATCHParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.SavePrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionUsingPATCH(params: PrescriptionEntityService.SavePrescriptionUsingPATCHParams): Observable<ResourcePrescription> {
    return this.savePrescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.prescriptionConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionConsultationUsingPOSTResponse(params: PrescriptionEntityService.PrescriptionConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionConsultationUsingPOST(params: PrescriptionEntityService.PrescriptionConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.prescriptionConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionConsultationUsingPUTResponse(params: PrescriptionEntityService.PrescriptionConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionConsultationUsingPUT(params: PrescriptionEntityService.PrescriptionConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.prescriptionConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionConsultationUsingDELETE(id: number): Observable<void> {
    return this.prescriptionConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionConsultationUsingPATCHResponse(params: PrescriptionEntityService.PrescriptionConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionConsultationUsingPATCH(params: PrescriptionEntityService.PrescriptionConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.prescriptionConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDeclineReasonUsingGETResponse(id: number): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDeclineReasonUsingGET(id: number): Observable<ResourceDeclineReason> {
    return this.prescriptionDeclineReasonUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionDeclineReasonUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDeclineReasonUsingPOSTResponse(params: PrescriptionEntityService.PrescriptionDeclineReasonUsingPOSTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionDeclineReasonUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDeclineReasonUsingPOST(params: PrescriptionEntityService.PrescriptionDeclineReasonUsingPOSTParams): Observable<ResourceDeclineReason> {
    return this.prescriptionDeclineReasonUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDeclineReasonUsingPUTResponse(params: PrescriptionEntityService.PrescriptionDeclineReasonUsingPUTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDeclineReasonUsingPUT(params: PrescriptionEntityService.PrescriptionDeclineReasonUsingPUTParams): Observable<ResourceDeclineReason> {
    return this.prescriptionDeclineReasonUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionDeclineReasonUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionDeclineReasonUsingDELETE(id: number): Observable<void> {
    return this.prescriptionDeclineReasonUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDeclineReasonUsingPATCHResponse(params: PrescriptionEntityService.PrescriptionDeclineReasonUsingPATCHParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDeclineReasonUsingPATCH(params: PrescriptionEntityService.PrescriptionDeclineReasonUsingPATCHParams): Observable<ResourceDeclineReason> {
    return this.prescriptionDeclineReasonUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionMedicineUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionMedicineUsingGET(id: number): Observable<ResourceMedicine> {
    return this.prescriptionMedicineUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineUsingPOSTResponse(params: PrescriptionEntityService.PrescriptionMedicineUsingPOSTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineUsingPOST(params: PrescriptionEntityService.PrescriptionMedicineUsingPOSTParams): Observable<ResourceMedicine> {
    return this.prescriptionMedicineUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineUsingPUTResponse(params: PrescriptionEntityService.PrescriptionMedicineUsingPUTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineUsingPUT(params: PrescriptionEntityService.PrescriptionMedicineUsingPUTParams): Observable<ResourceMedicine> {
    return this.prescriptionMedicineUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionMedicineUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionMedicineUsingDELETE(id: number): Observable<void> {
    return this.prescriptionMedicineUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineUsingPATCHResponse(params: PrescriptionEntityService.PrescriptionMedicineUsingPATCHParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineUsingPATCH(params: PrescriptionEntityService.PrescriptionMedicineUsingPATCHParams): Observable<ResourceMedicine> {
    return this.prescriptionMedicineUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionMedicineInClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionMedicineInClinicUsingGET(id: number): Observable<ResourceMedicineInClinic> {
    return this.prescriptionMedicineInClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineInClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineInClinicUsingPOSTResponse(params: PrescriptionEntityService.PrescriptionMedicineInClinicUsingPOSTParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineInClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineInClinicUsingPOST(params: PrescriptionEntityService.PrescriptionMedicineInClinicUsingPOSTParams): Observable<ResourceMedicineInClinic> {
    return this.prescriptionMedicineInClinicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineInClinicUsingPUTResponse(params: PrescriptionEntityService.PrescriptionMedicineInClinicUsingPUTParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineInClinicUsingPUT(params: PrescriptionEntityService.PrescriptionMedicineInClinicUsingPUTParams): Observable<ResourceMedicineInClinic> {
    return this.prescriptionMedicineInClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionMedicineInClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionMedicineInClinicUsingDELETE(id: number): Observable<void> {
    return this.prescriptionMedicineInClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineInClinicUsingPATCHResponse(params: PrescriptionEntityService.PrescriptionMedicineInClinicUsingPATCHParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/medicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionMedicineInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionMedicineInClinicUsingPATCH(params: PrescriptionEntityService.PrescriptionMedicineInClinicUsingPATCHParams): Observable<ResourceMedicineInClinic> {
    return this.prescriptionMedicineInClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingGET_1(id: number): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionPrescriptionByDatesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingPOSTResponse(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPOSTParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingPOST(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPOSTParams): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionPrescriptionByDatesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingPUTResponse(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPUTParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingPUT(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPUTParams): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionPrescriptionByDatesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionPrescriptionByDatesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionPrescriptionByDatesUsingDELETE_1(id: number): Observable<void> {
    return this.prescriptionPrescriptionByDatesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingPATCHResponse(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPATCHParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingPATCH(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingPATCHParams): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionPrescriptionByDatesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingGETResponse(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingGETParams): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionByDates/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionPrescriptionByDatesUsingGET(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingGETParams): Observable<ResourcePrescriptionByDate> {
    return this.prescriptionPrescriptionByDatesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  prescriptionPrescriptionByDatesUsingDELETEResponse(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionByDates/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  prescriptionPrescriptionByDatesUsingDELETE(params: PrescriptionEntityService.PrescriptionPrescriptionByDatesUsingDELETEParams): Observable<void> {
    return this.prescriptionPrescriptionByDatesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionDescription = null;
        _body = _resp.body as ResourcesPrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionDescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingGET_1(id: number): Observable<ResourcesPrescriptionDescription> {
    return this.prescriptionPrescriptionDescriptionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingPOSTResponse(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPOSTParams): Observable<HttpResponse<ResourcesPrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionDescription = null;
        _body = _resp.body as ResourcesPrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingPOST(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPOSTParams): Observable<ResourcesPrescriptionDescription> {
    return this.prescriptionPrescriptionDescriptionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingPUTResponse(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPUTParams): Observable<HttpResponse<ResourcesPrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionDescription = null;
        _body = _resp.body as ResourcesPrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingPUT(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPUTParams): Observable<ResourcesPrescriptionDescription> {
    return this.prescriptionPrescriptionDescriptionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionPrescriptionDescriptionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${id}/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionPrescriptionDescriptionsUsingDELETE_1(id: number): Observable<void> {
    return this.prescriptionPrescriptionDescriptionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingPATCHResponse(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPATCHParams): Observable<HttpResponse<ResourcesPrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionDescription = null;
        _body = _resp.body as ResourcesPrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingPATCH(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingPATCHParams): Observable<ResourcesPrescriptionDescription> {
    return this.prescriptionPrescriptionDescriptionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptiondescriptionId`: prescriptiondescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingGETResponse(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingGETParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionDescriptions/${params.prescriptiondescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptiondescriptionId`: prescriptiondescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionPrescriptionDescriptionsUsingGET(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingGETParams): Observable<ResourcePrescriptionDescription> {
    return this.prescriptionPrescriptionDescriptionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptiondescriptionId`: prescriptiondescriptionId
   *
   * - `id`: id
   */
  prescriptionPrescriptionDescriptionsUsingDELETEResponse(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptions/${params.id}/prescriptionDescriptions/${params.prescriptiondescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptiondescriptionId`: prescriptiondescriptionId
   *
   * - `id`: id
   */
  prescriptionPrescriptionDescriptionsUsingDELETE(params: PrescriptionEntityService.PrescriptionPrescriptionDescriptionsUsingDELETEParams): Observable<void> {
    return this.prescriptionPrescriptionDescriptionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PrescriptionEntityService {

  /**
   * Parameters for findAllPrescriptionUsingGET
   */
  export interface FindAllPrescriptionUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdPrescriptionUsingGET
   */
  export interface FindByConsultationIdPrescriptionUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for savePrescriptionUsingPUT
   */
  export interface SavePrescriptionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Prescription;
  }

  /**
   * Parameters for savePrescriptionUsingPATCH
   */
  export interface SavePrescriptionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Prescription;
  }

  /**
   * Parameters for prescriptionConsultationUsingPOST
   */
  export interface PrescriptionConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionConsultationUsingPUT
   */
  export interface PrescriptionConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionConsultationUsingPATCH
   */
  export interface PrescriptionConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDeclineReasonUsingPOST
   */
  export interface PrescriptionDeclineReasonUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDeclineReasonUsingPUT
   */
  export interface PrescriptionDeclineReasonUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDeclineReasonUsingPATCH
   */
  export interface PrescriptionDeclineReasonUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionMedicineUsingPOST
   */
  export interface PrescriptionMedicineUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionMedicineUsingPUT
   */
  export interface PrescriptionMedicineUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionMedicineUsingPATCH
   */
  export interface PrescriptionMedicineUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionMedicineInClinicUsingPOST
   */
  export interface PrescriptionMedicineInClinicUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionMedicineInClinicUsingPUT
   */
  export interface PrescriptionMedicineInClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionMedicineInClinicUsingPATCH
   */
  export interface PrescriptionMedicineInClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionPrescriptionByDatesUsingPOST
   */
  export interface PrescriptionPrescriptionByDatesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionPrescriptionByDatesUsingPUT
   */
  export interface PrescriptionPrescriptionByDatesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionPrescriptionByDatesUsingPATCH
   */
  export interface PrescriptionPrescriptionByDatesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionPrescriptionByDatesUsingGET
   */
  export interface PrescriptionPrescriptionByDatesUsingGETParams {

    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for prescriptionPrescriptionByDatesUsingDELETE
   */
  export interface PrescriptionPrescriptionByDatesUsingDELETEParams {

    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for prescriptionPrescriptionDescriptionsUsingPOST
   */
  export interface PrescriptionPrescriptionDescriptionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionPrescriptionDescriptionsUsingPUT
   */
  export interface PrescriptionPrescriptionDescriptionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionPrescriptionDescriptionsUsingPATCH
   */
  export interface PrescriptionPrescriptionDescriptionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionPrescriptionDescriptionsUsingGET
   */
  export interface PrescriptionPrescriptionDescriptionsUsingGETParams {

    /**
     * prescriptiondescriptionId
     */
    prescriptiondescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for prescriptionPrescriptionDescriptionsUsingDELETE
   */
  export interface PrescriptionPrescriptionDescriptionsUsingDELETEParams {

    /**
     * prescriptiondescriptionId
     */
    prescriptiondescriptionId: string;

    /**
     * id
     */
    id: number;
  }
}

export { PrescriptionEntityService }
