/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesSymptom } from '../models/resources-symptom-';
import { ResourceSymptom } from '../models/resource-symptom-';
import { Symptom } from '../models/symptom';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceSymptomType } from '../models/resource-symptom-type-';

/**
 * $ Proxy 229
 */
@Injectable()
class SymptomEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SymptomEntityService.FindAllSymptomUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSymptomUsingGETResponse(params: SymptomEntityService.FindAllSymptomUsingGETParams): Observable<HttpResponse<ResourcesSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSymptom = null;
        _body = _resp.body as ResourcesSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.FindAllSymptomUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSymptomUsingGET(params: SymptomEntityService.FindAllSymptomUsingGETParams): Observable<ResourcesSymptom> {
    return this.findAllSymptomUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSymptomUsingPOSTResponse(body: Symptom): Observable<HttpResponse<ResourceSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptoms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptom = null;
        _body = _resp.body as ResourceSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptom>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSymptomUsingPOST(body: Symptom): Observable<ResourceSymptom> {
    return this.saveSymptomUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSymptomUsingGETResponse(id: number): Observable<HttpResponse<ResourceSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptoms/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptom = null;
        _body = _resp.body as ResourceSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptom>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSymptomUsingGET(id: number): Observable<ResourceSymptom> {
    return this.findByIdSymptomUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SaveSymptomUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomUsingPUTResponse(params: SymptomEntityService.SaveSymptomUsingPUTParams): Observable<HttpResponse<ResourceSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptom = null;
        _body = _resp.body as ResourceSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SaveSymptomUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomUsingPUT(params: SymptomEntityService.SaveSymptomUsingPUTParams): Observable<ResourceSymptom> {
    return this.saveSymptomUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteSymptomUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptoms/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteSymptomUsingDELETE(id: number): Observable<void> {
    return this.deleteSymptomUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SaveSymptomUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomUsingPATCHResponse(params: SymptomEntityService.SaveSymptomUsingPATCHParams): Observable<HttpResponse<ResourceSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptom = null;
        _body = _resp.body as ResourceSymptom;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptom>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SaveSymptomUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSymptomUsingPATCH(params: SymptomEntityService.SaveSymptomUsingPATCHParams): Observable<ResourceSymptom> {
    return this.saveSymptomUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  symptomConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptoms/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  symptomConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.symptomConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomConsultationUsingPOSTResponse(params: SymptomEntityService.SymptomConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomConsultationUsingPOST(params: SymptomEntityService.SymptomConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.symptomConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomConsultationUsingPUTResponse(params: SymptomEntityService.SymptomConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomConsultationUsingPUT(params: SymptomEntityService.SymptomConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.symptomConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  symptomConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptoms/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  symptomConsultationUsingDELETE(id: number): Observable<void> {
    return this.symptomConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomConsultationUsingPATCHResponse(params: SymptomEntityService.SymptomConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomConsultationUsingPATCH(params: SymptomEntityService.SymptomConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.symptomConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  symptomSymptomTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/symptoms/${id}/symptomType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  symptomSymptomTypeUsingGET(id: number): Observable<ResourceSymptomType> {
    return this.symptomSymptomTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomSymptomTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomSymptomTypeUsingPOSTResponse(params: SymptomEntityService.SymptomSymptomTypeUsingPOSTParams): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}/symptomType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomSymptomTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomSymptomTypeUsingPOST(params: SymptomEntityService.SymptomSymptomTypeUsingPOSTParams): Observable<ResourceSymptomType> {
    return this.symptomSymptomTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomSymptomTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomSymptomTypeUsingPUTResponse(params: SymptomEntityService.SymptomSymptomTypeUsingPUTParams): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}/symptomType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomSymptomTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomSymptomTypeUsingPUT(params: SymptomEntityService.SymptomSymptomTypeUsingPUTParams): Observable<ResourceSymptomType> {
    return this.symptomSymptomTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  symptomSymptomTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/symptoms/${id}/symptomType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  symptomSymptomTypeUsingDELETE(id: number): Observable<void> {
    return this.symptomSymptomTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomSymptomTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomSymptomTypeUsingPATCHResponse(params: SymptomEntityService.SymptomSymptomTypeUsingPATCHParams): Observable<HttpResponse<ResourceSymptomType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/symptoms/${params.id}/symptomType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSymptomType = null;
        _body = _resp.body as ResourceSymptomType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSymptomType>;
      })
    );
  }

  /**
   * @param params The `SymptomEntityService.SymptomSymptomTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  symptomSymptomTypeUsingPATCH(params: SymptomEntityService.SymptomSymptomTypeUsingPATCHParams): Observable<ResourceSymptomType> {
    return this.symptomSymptomTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SymptomEntityService {

  /**
   * Parameters for findAllSymptomUsingGET
   */
  export interface FindAllSymptomUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveSymptomUsingPUT
   */
  export interface SaveSymptomUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Symptom;
  }

  /**
   * Parameters for saveSymptomUsingPATCH
   */
  export interface SaveSymptomUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Symptom;
  }

  /**
   * Parameters for symptomConsultationUsingPOST
   */
  export interface SymptomConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for symptomConsultationUsingPUT
   */
  export interface SymptomConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for symptomConsultationUsingPATCH
   */
  export interface SymptomConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for symptomSymptomTypeUsingPOST
   */
  export interface SymptomSymptomTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for symptomSymptomTypeUsingPUT
   */
  export interface SymptomSymptomTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for symptomSymptomTypeUsingPATCH
   */
  export interface SymptomSymptomTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { SymptomEntityService }
