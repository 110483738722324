/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceConsultationForScribbleUpload } from '../models/resource-consultation-for-scribble-upload-';

/**
 * Scribble Upload Controller
 */
@Injectable()
class ScribbleUploadControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScribbleUploadControllerService.UploadFileAndSetScribbleUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  uploadFileAndSetScribbleUsingPOSTResponse(params: ScribbleUploadControllerService.UploadFileAndSetScribbleUsingPOSTParams): Observable<HttpResponse<ResourceConsultationForScribbleUpload>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    __formData.append('file', params.file);
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/storage/set-scribble`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationForScribbleUpload = null;
        _body = _resp.body as ResourceConsultationForScribbleUpload;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationForScribbleUpload>;
      })
    );
  }

  /**
   * @param params The `ScribbleUploadControllerService.UploadFileAndSetScribbleUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  uploadFileAndSetScribbleUsingPOST(params: ScribbleUploadControllerService.UploadFileAndSetScribbleUsingPOSTParams): Observable<ResourceConsultationForScribbleUpload> {
    return this.uploadFileAndSetScribbleUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ScribbleUploadControllerService {

  /**
   * Parameters for uploadFileAndSetScribbleUsingPOST
   */
  export interface UploadFileAndSetScribbleUsingPOSTParams {

    /**
     * file
     */
    file: Blob;

    /**
     * consultationId
     */
    consultationId: number;
  }
}

export { ScribbleUploadControllerService }
