/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderItems } from '../models/order-items';

/**
 * Order Item Controller
 */
@Injectable()
class OrderItemControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderItemControllerService.GetRadiologyOrderItemUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `productTypeId`: productTypeId
   *
   * @return OK
   */
  getRadiologyOrderItemUsingGETResponse(params: OrderItemControllerService.GetRadiologyOrderItemUsingGETParams): Observable<HttpResponse<Array<OrderItems>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/search-radiology-orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderItems> = null;
        _body = _resp.body as Array<OrderItems>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderItems>>;
      })
    );
  }

  /**
   * @param params The `OrderItemControllerService.GetRadiologyOrderItemUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `productTypeId`: productTypeId
   *
   * @return OK
   */
  getRadiologyOrderItemUsingGET(params: OrderItemControllerService.GetRadiologyOrderItemUsingGETParams): Observable<Array<OrderItems>> {
    return this.getRadiologyOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderItemControllerService {

  /**
   * Parameters for getRadiologyOrderItemUsingGET
   */
  export interface GetRadiologyOrderItemUsingGETParams {

    /**
     * status
     */
    status: 'PENDING' | 'PARTIALLY_PAID' | 'PAID' | 'PAY_LATER' | 'PROCESSING' | 'PROCESSED' | 'COMPLETED' | 'CANCELLED' | 'REFUNDED';

    /**
     * productTypeId
     */
    productTypeId: number;
  }
}

export { OrderItemControllerService }
