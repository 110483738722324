/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Invoice Controller
 */
@Injectable()
class InvoiceControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `InvoiceControllerService.GenerateInvoiceUsingGETParams` containing the following parameters:
   *
   * - `orderId`: orderId
   *
   * - `consultationId`: consultationId
   *
   * - `woItem`: woItem
   *
   * - `payLater`: payLater
   *
   * - `download`: download
   */
  generateInvoiceUsingGETResponse(params: InvoiceControllerService.GenerateInvoiceUsingGETParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderId != null) __params = __params.set('orderId', params.orderId.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    if (params.woItem != null) __params = __params.set('woItem', params.woItem.toString());
    if (params.payLater != null) __params = __params.set('payLater', params.payLater.toString());
    if (params.download != null) __params = __params.set('download', params.download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `InvoiceControllerService.GenerateInvoiceUsingGETParams` containing the following parameters:
   *
   * - `orderId`: orderId
   *
   * - `consultationId`: consultationId
   *
   * - `woItem`: woItem
   *
   * - `payLater`: payLater
   *
   * - `download`: download
   */
  generateInvoiceUsingGET(params: InvoiceControllerService.GenerateInvoiceUsingGETParams): Observable<void> {
    return this.generateInvoiceUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module InvoiceControllerService {

  /**
   * Parameters for generateInvoiceUsingGET
   */
  export interface GenerateInvoiceUsingGETParams {

    /**
     * orderId
     */
    orderId: number;

    /**
     * consultationId
     */
    consultationId: number;

    /**
     * woItem
     */
    woItem?: boolean;

    /**
     * payLater
     */
    payLater?: boolean;

    /**
     * download
     */
    download?: boolean;
  }
}

export { InvoiceControllerService }
