import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-appointment',
  templateUrl: './confirmation-appointment.component.html',
  styleUrls: ['./confirmation-appointment.component.scss']
})
export class ConfirmationAppointmentComponent implements OnInit {
  consultation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.consultation = data;
  }

  ngOnInit() {
  }

}
