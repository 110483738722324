/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { ResourcesDiagnosticOrder } from "../models/resources-diagnostic-order-";
import { ResourceDiagnosticOrder } from "../models/resource-diagnostic-order-";
import { DiagnosticOrder } from "../models/diagnostic-order";
import { ResourcesOrderItem } from "../models/resources-order-item-";
import { ResourceOrderItem } from "../models/resource-order-item-";

/**
 * $ Proxy 177
 */
@Injectable()
class DiagnosticOrderEntityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindAllDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindAllDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnosticOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindAllDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindAllDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findAllDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticOrderUsingPOSTResponse(
    body: DiagnosticOrder
  ): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/diagnosticOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticOrderUsingPOST(
    body: DiagnosticOrder
  ): Observable<ResourceDiagnosticOrder> {
    return this.saveDiagnosticOrderUsingPOSTResponse(body).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  markCollectedDiagnosticOrderUsingPOSTResponse(
    body: any
  ): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/diagnostic/acknowledgement`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<String>;
        let _body: string = null;
        console.log(_resp);
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  markCollectedDiagnosticOrderUsingPOST(body: any): Observable<any> {
    return this.markCollectedDiagnosticOrderUsingPOSTResponse(body).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByAddressIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `addressId`: addressId
   *
   * @return OK
   */
  findByAddressIdDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByAddressIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.addressId != null)
      __params = __params.set("addressId", params.addressId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnosticOrders/search/findByAddressId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByAddressIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `addressId`: addressId
   *
   * @return OK
   */
  findByAddressIdDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByAddressIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByAddressIdDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByBarCodeDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `barCode`: barCode
   *
   * @return OK
   */
  findByBarCodeDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByBarCodeDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.barCode != null)
      __params = __params.set("barCode", params.barCode.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnosticOrders/search/findByBarCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  findByBarCodeDiagnosticOrderUsingGETResponseForAuth(
    params: DiagnosticOrderEntityService.FindByBarCodeDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.barCode != null)
      __params = __params.set("barcode", params.barCode.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/find-diagnostic-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByBarCodeDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `barCode`: barCode
   *
   * @return OK
   */
  findByBarCodeDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByBarCodeDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByBarCodeDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByBarCodeDiagnosticOrderUsingGETForAuth(
    params: DiagnosticOrderEntityService.FindByBarCodeDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByBarCodeDiagnosticOrderUsingGETResponseForAuth(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByDoctorInClinicIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByDoctorInClinicIdDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByDoctorInClinicIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.dicId != null)
      __params = __params.set("dicId", params.dicId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/search/findByDoctorInClinicId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  findByDoctorInClinicIdDiagnosticOrderUsingGETResponseForAuth(
    params: DiagnosticOrderEntityService.FindByDoctorInClinicIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.dicId != null)
      __params = __params.set("doctorInClinicId", params.dicId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/find-diagnostic-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByDoctorInClinicIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `dicId`: dicId
   *
   * @return OK
   */
  findByDoctorInClinicIdDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByDoctorInClinicIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByDoctorInClinicIdDiagnosticOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  findByDoctorInClinicIdDiagnosticOrderUsingGETForAuth(
    params: DiagnosticOrderEntityService.FindByDoctorInClinicIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByDoctorInClinicIdDiagnosticOrderUsingGETResponseForAuth(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.patientId != null)
      __params = __params.set("patientId", params.patientId.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.doctorInClinicId != null)
      __params = __params.set(
        "doctorInClinicId",
        params.doctorInClinicId.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/search/findByDoctorInClinicIdAndPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `labId`: labId
   *
   * @return OK
   */
  findByLabIdDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.labId != null)
      __params = __params.set("labId", params.labId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnosticOrders/search/findByLabId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  findByLabIdDiagnosticOrderUsingGETResponseForAuth(
    params: DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.labId != null)
      __params = __params.set("labId", params.labId.toString());
    if (params.isCovid != null)
      __params = __params.set("isCovid", params.isCovid.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/find-diagnostic-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `size`:
   *
   * - `page`:
   * @return OK
   */
  findByRecentDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnostic/findByDic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `size`:
   *
   * - `page`:
   * @return OK
   */
  findByInClinicDiagnosticOrderUsingGETResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.itemState != null)
      __params = __params.set("itemState", params.itemState.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnostic/for-acknowledge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body = null;
        _body = _resp.body;
        return _resp.clone({ body: _body }) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.findByOrderIdDiagnosticOrderUsingGETResponse` containing the following parameters:
   *
   * - `size`:
   *https://order.uat.meddo.tech/api/v1/order/diagnosticOrders/27046?projection=diagnosticOrders%27
   * - `page`:
   * @return OK
   */
  findByDiagnosticOrderIdDiagnosticOrderUsingGETResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.projection != null)
      __params = __params.set("projection", params.projection.toString());
    // if (params.orderId != null) __params = __params.append('orderId', params.orderId.toString());
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/${params.orderId.toString()}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );
    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  findDiagnosticOrderIdDiagnosticOrderUsingGET(params: any): Observable<any> {
    return this.findByDiagnosticOrderIdDiagnosticOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  findByOrderIdDiagnosticOrderUsingGETResponse(params) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderId != null)
      __params = __params.set("orderId", params.orderId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnostic/fetch-diagnostic-order/`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );
    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  findOrderIdDiagnosticOrderUsingGET(params: any): Observable<any> {
    return this.findByOrderIdDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `labId`: labId
   *
   * @return OK
   */
  findByLabIdDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByLabIdDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByLabIdDiagnosticOrderUsingGETForAuth(
    params: DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByLabIdDiagnosticOrderUsingGETResponseForAuth(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  findRecentDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByRecentDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByLabIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  findInClinicDiagnosticOrderUsingGET(params: any): Observable<any> {
    return this.findByInClinicDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByPatientIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByPatientIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.patientId != null)
      __params = __params.set("patientId", params.patientId.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnosticOrders/search/findByPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  findByPatientIdDiagnosticOrderUsingGETResponseForAuth(
    params: DiagnosticOrderEntityService.FindByPatientIdDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.patientId != null)
      __params = __params.set("patientId", params.patientId.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/find-diagnostic-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByPatientIdDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByPatientIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByPatientIdDiagnosticOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByPatientIdDiagnosticOrderUsingGETForAuth(
    params: DiagnosticOrderEntityService.FindByPatientIdDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByPatientIdDiagnosticOrderUsingGETResponseForAuth(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByPatientNameContainingDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByPatientNameContainingDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByPatientNameContainingDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/search/findByPatientNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByPatientNameContainingDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByPatientNameContainingDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByPatientNameContainingDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByPatientNameContainingDiagnosticOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByPatientPhoneContainingDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientPhoneContainingDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindByPatientPhoneContainingDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.phone != null)
      __params = __params.set("phone", params.phone.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/search/findByPatientPhoneContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindByPatientPhoneContainingDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientPhoneContainingDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindByPatientPhoneContainingDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findByPatientPhoneContainingDiagnosticOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETResponse(
    params: DiagnosticOrderEntityService.FindDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.phone != null)
      __params = __params.set("phone", params.phone.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/search/findDistinctByPatientNameContainingOrPatientPhoneContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticOrder = null;
        _body = _resp.body as ResourcesDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.FindDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGET(
    params: DiagnosticOrderEntityService.FindDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETParams
  ): Observable<ResourcesDiagnosticOrder> {
    return this.findDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticOrderUsingGETResponse(
    id: number
  ): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/${id}?projection=diagnosticOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticOrderUsingGET(
    id: number
  ): Observable<ResourceDiagnosticOrder> {
    return this.findByIdDiagnosticOrderUsingGETResponse(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticOrderUsingPUTResponse(
    params: DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPUTParams
  ): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticOrderUsingPUT(
    params: DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPUTParams
  ): Observable<ResourceDiagnosticOrder> {
    return this.saveDiagnosticOrderUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticOrderUsingDELETEResponse(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticOrderUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticOrderUsingDELETEResponse(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticOrderUsingPATCHResponse(
    params: DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPATCHParams
  ): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticOrderUsingPATCH(
    params: DiagnosticOrderEntityService.SaveDiagnosticOrderUsingPATCHParams
  ): Observable<ResourceDiagnosticOrder> {
    return this.saveDiagnosticOrderUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticOrderOrderItemsUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticOrderOrderItemsUsingGET_1(
    id: number
  ): Observable<ResourcesOrderItem> {
    return this.diagnosticOrderOrderItemsUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingPOSTResponse(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPOSTParams
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${params.id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingPOST(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPOSTParams
  ): Observable<ResourcesOrderItem> {
    return this.diagnosticOrderOrderItemsUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingPUTResponse(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPUTParams
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${params.id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingPUT(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPUTParams
  ): Observable<ResourcesOrderItem> {
    return this.diagnosticOrderOrderItemsUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticOrderOrderItemsUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticOrderOrderItemsUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticOrderOrderItemsUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingPATCHResponse(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPATCHParams
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/order/diagnosticOrders/${params.id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingPATCH(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingPATCHParams
  ): Observable<ResourcesOrderItem> {
    return this.diagnosticOrderOrderItemsUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingGETParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingGETResponse(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingGETParams
  ): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/${params.id}/orderItems/${params.orderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingGETParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  diagnosticOrderOrderItemsUsingGET(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingGETParams
  ): Observable<ResourceOrderItem> {
    return this.diagnosticOrderOrderItemsUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingDELETEParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   */
  diagnosticOrderOrderItemsUsingDELETEResponse(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/order/diagnosticOrders/${params.id}/orderItems/${params.orderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingDELETEParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   */
  diagnosticOrderOrderItemsUsingDELETE(
    params: DiagnosticOrderEntityService.DiagnosticOrderOrderItemsUsingDELETEParams
  ): Observable<void> {
    return this.diagnosticOrderOrderItemsUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }
}

module DiagnosticOrderEntityService {
  /**
   * Parameters for findAllDiagnosticOrderUsingGET
   */
  export interface FindAllDiagnosticOrderUsingGETParams {
    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByAddressIdDiagnosticOrderUsingGET
   */
  export interface FindByAddressIdDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * addressId
     */
    addressId?: number;
  }

  /**
   * Parameters for findByBarCodeDiagnosticOrderUsingGET
   */
  export interface FindByBarCodeDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * barCode
     */
    barCode?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdDiagnosticOrderUsingGET
   */
  export interface FindByDoctorInClinicIdDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * dicId
     */
    dicId?: number;
  }

  /**
   * Parameters for findByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGET
   */
  export interface FindByDoctorInClinicIdAndPatientIdDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
  }

  /**
   * Parameters for findByLabIdDiagnosticOrderUsingGET
   */
  export interface FindByLabIdDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;
    isCovid?: boolean;

    /**
     * labId
     */
    labId?: number;
  }

  /**
   * Parameters for findByPatientIdDiagnosticOrderUsingGET
   */
  export interface FindByPatientIdDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findByPatientNameContainingDiagnosticOrderUsingGET
   */
  export interface FindByPatientNameContainingDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for findByPatientPhoneContainingDiagnosticOrderUsingGET
   */
  export interface FindByPatientPhoneContainingDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;
  }

  /**
   * Parameters for findDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGET
   */
  export interface FindDistinctByPatientNameContainingOrPatientPhoneContainingDiagnosticOrderUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveDiagnosticOrderUsingPUT
   */
  export interface SaveDiagnosticOrderUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticOrder;
  }

  /**
   * Parameters for saveDiagnosticOrderUsingPATCH
   */
  export interface SaveDiagnosticOrderUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticOrder;
  }

  /**
   * Parameters for diagnosticOrderOrderItemsUsingPOST
   */
  export interface DiagnosticOrderOrderItemsUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticOrderOrderItemsUsingPUT
   */
  export interface DiagnosticOrderOrderItemsUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticOrderOrderItemsUsingPATCH
   */
  export interface DiagnosticOrderOrderItemsUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticOrderOrderItemsUsingGET
   */
  export interface DiagnosticOrderOrderItemsUsingGETParams {
    /**
     * orderitemId
     */
    orderitemId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for diagnosticOrderOrderItemsUsingDELETE
   */
  export interface DiagnosticOrderOrderItemsUsingDELETEParams {
    /**
     * orderitemId
     */
    orderitemId: string;

    /**
     * id
     */
    id: number;
  }
}

export { DiagnosticOrderEntityService };
