/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesMedicineGeneral } from '../models/resources-medicine-general-';
import { ResourceMedicineGeneral } from '../models/resource-medicine-general-';
import { MedicineGeneral } from '../models/medicine-general';
import { ResourcesMedicineSalt } from '../models/resources-medicine-salt-';
import { ResourceMedicineSalt } from '../models/resource-medicine-salt-';
import { ResourcesMedicine } from '../models/resources-medicine-';
import { ResourceMedicine } from '../models/resource-medicine-';

/**
 * $ Proxy 210
 */
@Injectable()
class MedicineGeneralEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MedicineGeneralEntityService.FindAllMedicineGeneralUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineGeneralUsingGETResponse(params: MedicineGeneralEntityService.FindAllMedicineGeneralUsingGETParams): Observable<HttpResponse<ResourcesMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineGeneral = null;
        _body = _resp.body as ResourcesMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.FindAllMedicineGeneralUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineGeneralUsingGET(params: MedicineGeneralEntityService.FindAllMedicineGeneralUsingGETParams): Observable<ResourcesMedicineGeneral> {
    return this.findAllMedicineGeneralUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineGeneralUsingPOSTResponse(body: MedicineGeneral): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicine-generals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineGeneralUsingPOST(body: MedicineGeneral): Observable<ResourceMedicineGeneral> {
    return this.saveMedicineGeneralUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param medicineGeneralIds medicineGeneralIds
   * @return OK
   */
  findAllByIdInMedicineGeneralUsingGETResponse(medicineGeneralIds?: Array<number>): Observable<HttpResponse<ResourcesMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (medicineGeneralIds || []).forEach((val, index) => {if (val != null) __params = __params.append('medicineGeneralIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals/search/findAllByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineGeneral = null;
        _body = _resp.body as ResourcesMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineGeneral>;
      })
    );
  }

  /**
   * @param medicineGeneralIds medicineGeneralIds
   * @return OK
   */
  findAllByIdInMedicineGeneralUsingGET(medicineGeneralIds?: Array<number>): Observable<ResourcesMedicineGeneral> {
    return this.findAllByIdInMedicineGeneralUsingGETResponse(medicineGeneralIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineGeneralUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineGeneralUsingGET(id: number): Observable<ResourceMedicineGeneral> {
    return this.findByIdMedicineGeneralUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.SaveMedicineGeneralUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineGeneralUsingPUTResponse(params: MedicineGeneralEntityService.SaveMedicineGeneralUsingPUTParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.SaveMedicineGeneralUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineGeneralUsingPUT(params: MedicineGeneralEntityService.SaveMedicineGeneralUsingPUTParams): Observable<ResourceMedicineGeneral> {
    return this.saveMedicineGeneralUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteMedicineGeneralUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-generals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteMedicineGeneralUsingDELETE(id: number): Observable<void> {
    return this.deleteMedicineGeneralUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.SaveMedicineGeneralUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineGeneralUsingPATCHResponse(params: MedicineGeneralEntityService.SaveMedicineGeneralUsingPATCHParams): Observable<HttpResponse<ResourceMedicineGeneral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineGeneral = null;
        _body = _resp.body as ResourceMedicineGeneral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineGeneral>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.SaveMedicineGeneralUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineGeneralUsingPATCH(params: MedicineGeneralEntityService.SaveMedicineGeneralUsingPATCHParams): Observable<ResourceMedicineGeneral> {
    return this.saveMedicineGeneralUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals/${id}/medicineSalts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSalt = null;
        _body = _resp.body as ResourcesMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSalt>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingGET_1(id: number): Observable<ResourcesMedicineSalt> {
    return this.medicineGeneralMedicineSaltsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingPOSTResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPOSTParams): Observable<HttpResponse<ResourcesMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicineSalts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSalt = null;
        _body = _resp.body as ResourcesMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingPOST(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPOSTParams): Observable<ResourcesMedicineSalt> {
    return this.medicineGeneralMedicineSaltsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingPUTResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPUTParams): Observable<HttpResponse<ResourcesMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicineSalts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSalt = null;
        _body = _resp.body as ResourcesMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingPUT(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPUTParams): Observable<ResourcesMedicineSalt> {
    return this.medicineGeneralMedicineSaltsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineGeneralMedicineSaltsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-generals/${id}/medicineSalts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineGeneralMedicineSaltsUsingDELETE_1(id: number): Observable<void> {
    return this.medicineGeneralMedicineSaltsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingPATCHResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPATCHParams): Observable<HttpResponse<ResourcesMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicineSalts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineSalt = null;
        _body = _resp.body as ResourcesMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingPATCH(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingPATCHParams): Observable<ResourcesMedicineSalt> {
    return this.medicineGeneralMedicineSaltsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingGETParams` containing the following parameters:
   *
   * - `medicinesaltId`: medicinesaltId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingGETResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingGETParams): Observable<HttpResponse<ResourceMedicineSalt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicineSalts/${params.medicinesaltId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineSalt = null;
        _body = _resp.body as ResourceMedicineSalt;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineSalt>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingGETParams` containing the following parameters:
   *
   * - `medicinesaltId`: medicinesaltId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineGeneralMedicineSaltsUsingGET(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingGETParams): Observable<ResourceMedicineSalt> {
    return this.medicineGeneralMedicineSaltsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingDELETEParams` containing the following parameters:
   *
   * - `medicinesaltId`: medicinesaltId
   *
   * - `id`: id
   */
  medicineGeneralMedicineSaltsUsingDELETEResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicineSalts/${params.medicinesaltId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingDELETEParams` containing the following parameters:
   *
   * - `medicinesaltId`: medicinesaltId
   *
   * - `id`: id
   */
  medicineGeneralMedicineSaltsUsingDELETE(params: MedicineGeneralEntityService.MedicineGeneralMedicineSaltsUsingDELETEParams): Observable<void> {
    return this.medicineGeneralMedicineSaltsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineGeneralMedicinesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals/${id}/medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineGeneralMedicinesUsingGET_1(id: number): Observable<ResourcesMedicine> {
    return this.medicineGeneralMedicinesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingPOSTResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPOSTParams): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingPOST(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPOSTParams): Observable<ResourcesMedicine> {
    return this.medicineGeneralMedicinesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingPUTResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPUTParams): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingPUT(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPUTParams): Observable<ResourcesMedicine> {
    return this.medicineGeneralMedicinesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineGeneralMedicinesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-generals/${id}/medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineGeneralMedicinesUsingDELETE_1(id: number): Observable<void> {
    return this.medicineGeneralMedicinesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingPATCHResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPATCHParams): Observable<HttpResponse<ResourcesMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicine = null;
        _body = _resp.body as ResourcesMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingPATCH(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingPATCHParams): Observable<ResourcesMedicine> {
    return this.medicineGeneralMedicinesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingGETParams` containing the following parameters:
   *
   * - `medicineId`: medicineId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingGETResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingGETParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicines/${params.medicineId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingGETParams` containing the following parameters:
   *
   * - `medicineId`: medicineId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineGeneralMedicinesUsingGET(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingGETParams): Observable<ResourceMedicine> {
    return this.medicineGeneralMedicinesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingDELETEParams` containing the following parameters:
   *
   * - `medicineId`: medicineId
   *
   * - `id`: id
   */
  medicineGeneralMedicinesUsingDELETEResponse(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicine-generals/${params.id}/medicines/${params.medicineId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `MedicineGeneralEntityService.MedicineGeneralMedicinesUsingDELETEParams` containing the following parameters:
   *
   * - `medicineId`: medicineId
   *
   * - `id`: id
   */
  medicineGeneralMedicinesUsingDELETE(params: MedicineGeneralEntityService.MedicineGeneralMedicinesUsingDELETEParams): Observable<void> {
    return this.medicineGeneralMedicinesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module MedicineGeneralEntityService {

  /**
   * Parameters for findAllMedicineGeneralUsingGET
   */
  export interface FindAllMedicineGeneralUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveMedicineGeneralUsingPUT
   */
  export interface SaveMedicineGeneralUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicineGeneral;
  }

  /**
   * Parameters for saveMedicineGeneralUsingPATCH
   */
  export interface SaveMedicineGeneralUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicineGeneral;
  }

  /**
   * Parameters for medicineGeneralMedicineSaltsUsingPOST
   */
  export interface MedicineGeneralMedicineSaltsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineGeneralMedicineSaltsUsingPUT
   */
  export interface MedicineGeneralMedicineSaltsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineGeneralMedicineSaltsUsingPATCH
   */
  export interface MedicineGeneralMedicineSaltsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineGeneralMedicineSaltsUsingGET
   */
  export interface MedicineGeneralMedicineSaltsUsingGETParams {

    /**
     * medicinesaltId
     */
    medicinesaltId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicineGeneralMedicineSaltsUsingDELETE
   */
  export interface MedicineGeneralMedicineSaltsUsingDELETEParams {

    /**
     * medicinesaltId
     */
    medicinesaltId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicineGeneralMedicinesUsingPOST
   */
  export interface MedicineGeneralMedicinesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineGeneralMedicinesUsingPUT
   */
  export interface MedicineGeneralMedicinesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineGeneralMedicinesUsingPATCH
   */
  export interface MedicineGeneralMedicinesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineGeneralMedicinesUsingGET
   */
  export interface MedicineGeneralMedicinesUsingGETParams {

    /**
     * medicineId
     */
    medicineId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicineGeneralMedicinesUsingDELETE
   */
  export interface MedicineGeneralMedicinesUsingDELETEParams {

    /**
     * medicineId
     */
    medicineId: string;

    /**
     * id
     */
    id: number;
  }
}

export { MedicineGeneralEntityService }
