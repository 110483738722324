import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import { AddressEntityService } from 'src/app/api/emr/services';
import { MatSnackBar } from '@angular/material/snack-bar'
import { PincodeCheckComponent } from '../pincode-check/pincode-check.component';
import { Observable } from 'rxjs';
import { PincodeValidateControllerService } from 'src/app/api/emr/services'
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.component.html',
  styleUrls: ['./select-address.component.scss']
})
export class SelectAddressComponent implements OnInit {

  addressFormGroup: FormGroup;
  showNewAddressForm = false;
  savingNewAddress = false;
  allAddresses;
  validatingPincode;
  patient;
  filteredStates: Observable<string[]>;
  serviceType;
  isuncover:boolean=false;

  addressTags: any = [
    { value: 'HOME', viewValue: 'Home' },
    { value: 'OFFICE', viewValue: 'Office' },
    { value: 'OTHERS', viewValue: 'Others' }
  ];

  states: any = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Punjab",
    "Pondicherry",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ];
  pinInvalid: boolean;
  dialogRef: MatDialogRef<unknown, any>;


  constructor(
    private FormBuilder: FormBuilder,
    private MatSnackBar: MatSnackBar,
    private PincodeValidateControllerService : PincodeValidateControllerService,
    @Inject(MAT_DIALOG_DATA) private data,
    private MatDialog: MatDialog,
    private MatDialogRef: MatDialogRef<SelectAddressComponent>,
    private AddressEntityService: AddressEntityService
  ) {
    this.patient = data.patient;
    this.serviceType = data.serviceType;
    this.allAddresses = data.patient.addresses || [];
  }

  filterStates(val: string): string[] {
    return this.states.filter(option => option.toLowerCase().includes(val.toLowerCase()));
  }

  getCompleteAddress(address) {
    return `${address.apartment} ${address.area} ${address.locality} ${address.city} ${address.state} ${address.pincode}, ${address.landmark}, Ph: ${address.mobile}`;
  }

  removeNewAddress() {
    this.showNewAddressForm = false;
    this.addressFormGroup.get('newAddress').reset({
      name: this.patient.name,
      mobile: this.patient.mobile,
      addressTag: '',
      apartment: '',
      area: '',
      locality: '',
      landmark: '',
      city: '',
      state: '',
      pincode: '',
      specialInstruction: ''
    })
    this.addressFormGroup.get('newAddress').disable();
  }

  saveThisAddress() {
    this.savingNewAddress = true;
    let address = this.addressFormGroup.get('newAddress').value
    address.pincode = this.addressFormGroup.get('newAddress.pincode').value;
    address.patient = this.patient._links.self.href.replace(/{.*}/, '');

    this.AddressEntityService.saveAddressUsingPOST(address).subscribe(data => {

      this.allAddresses.push(data);
      this.showNewAddressForm = false;
      this.savingNewAddress = false;
      this.addressFormGroup.get('address').setValue(data);
      this.addressFormGroup.get('newAddress').reset({
        name: this.patient.name,
        mobile: this.patient.phone,
        addressTag: '',
        apartment: '',
        area: '',
        locality: '',
        landmark: '',
        city: '',
        state: '',
        pincode: '',
        specialInstruction: '',
        isActive: true
      });
      this.addressFormGroup.get('newAddress').disable();
    }, err => {
      alert('Error while saving patient');
      this.savingNewAddress = false;
    })
  }

  save() {
    this.MatDialogRef.close(this.addressFormGroup.get('address').value)
  }

  enableNewAddress() {
    if(this.serviceType){
      this.addressFormGroup.get('newAddress').enable();
      this.showNewAddressForm = true;
    }else{
      let dialogRef = this.MatDialog.open(PincodeCheckComponent,{
        maxHeight: "120vh",
        minWidth: "80vw",
        disableClose: true,
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.addressFormGroup.get('newAddress').enable();
          this.addressFormGroup.get('newAddress.pincode').setValue(res);
          this.addressFormGroup.get('newAddress.pincode').disable();
          this.showNewAddressForm = true;
        }
      })
    }
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
    this.addressFormGroup = this.FormBuilder.group({
      address: ['', Validators.required],
      newAddress: this.FormBuilder.group({
        name: [this.patient.name, Validators.required],
        mobile: [this.patient.phone, [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
        addressTag: ["", Validators.required],
        apartment: ["", Validators.required],
        area: ["", Validators.required],
        locality: ["", Validators.required],
        landmark: ["", Validators.required],
        city: ["", Validators.required],
        state: ["", Validators.required],
        pincode: ["", [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
        isActive: [true],
        specialInstruction: ''
      })
    })

    this.addressFormGroup.get('newAddress').disable();
    if (!this.allAddresses.length && !this.serviceType) {
      let dialogRef = this.MatDialog.open(PincodeCheckComponent,{
        maxHeight: "120vh",
        minWidth: "80vw",
        disableClose: true,
      })
      dialogRef.afterClosed().subscribe(res => {
        if(res) {
          this.addressFormGroup.get('newAddress').enable();
          this.addressFormGroup.get('newAddress.pincode').setValue(res);
          this.addressFormGroup.get('newAddress.pincode').disable();
          this.showNewAddressForm = true;
        }
      })
    }

    this.addressFormGroup.get('newAddress.pincode').valueChanges.subscribe(value => {
      if(value.length > 5) {
        let pincode ={
          value
        }
        this.validatingPincode = true;
        this.PincodeValidateControllerService.pincodeValidateUsingGET(pincode).subscribe(
          res => {
            if(res === 'true') {
              this.pinInvalid = false;
            } else {
              this.pinInvalid = true;
              this.addressFormGroup.get('newAddress.pincode').setErrors({notUnique : true});
              this.MatSnackBar.open('Pincode non-serviceable','OK',{duration: 2000,
                verticalPosition: 'top'})
            }
            this.validatingPincode = false;
          },
          err => {
            this.pinInvalid = true;
            this.validatingPincode = false;
            this.addressFormGroup.get('newAddress.pincode').setErrors({notUnique : true});
            this.MatSnackBar.open('Invalid Pincode','OK',{duration: 2000,
              verticalPosition: 'top'})
          }
        )
      }
    })

    this.filteredStates = this.addressFormGroup.get('newAddress.state').valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterStates(val))
      );
  }

}
