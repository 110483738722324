/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ModelAndView } from '../models/model-and-view';

/**
 * Basic Error Controller
 */
@Injectable()
class BasicErrorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  errorHtmlUsingGETResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingGET(): Observable<ModelAndView> {
    return this.errorHtmlUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingHEADResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingHEAD(): Observable<ModelAndView> {
    return this.errorHtmlUsingHEADResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingPOSTResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingPOST(): Observable<ModelAndView> {
    return this.errorHtmlUsingPOSTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingPUTResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingPUT(): Observable<ModelAndView> {
    return this.errorHtmlUsingPUTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingDELETEResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingDELETE(): Observable<ModelAndView> {
    return this.errorHtmlUsingDELETEResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingOPTIONSResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingOPTIONS(): Observable<ModelAndView> {
    return this.errorHtmlUsingOPTIONSResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingPATCHResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  errorHtmlUsingPATCH(): Observable<ModelAndView> {
    return this.errorHtmlUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module BasicErrorControllerService {
}

export { BasicErrorControllerService }
