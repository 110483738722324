/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { AuthenticationService } from '../../../login/service/authentication.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


/**
 * Forgot Pass Controller
 */
@Injectable()
class SendOtpControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient,
    private AuthenticationService : AuthenticationService
  ) {
    super(config, http);
  }

  /**
   * @param mobile
   */
  getSendOtpUsingPOSTResponse(mobile: String): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let AuthorizationToken = environment.otpToken;
    __headers = __headers.set('Authorization', AuthorizationToken);
    if (mobile != null) __params = __params.set('patientId', mobile.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/walletOtp/generate-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param mobile
   * @param otp
   */
  getSendOtpUsingPOST(mobile: String): Observable<void> {
    return this.getSendOtpUsingPOSTResponse(mobile).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param mobile
   * @param otp
   */
  postVarifyOtpUsingGETResponse(params: SendOtpControllerService.VerifyOtpGETParams): Observable<HttpResponse<String>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let AuthorizationToken = environment.otpToken;
    __headers = __headers.set('Authorization', AuthorizationToken);
    if (params.mobile != null) __params = __params.set('patientId', params.mobile.toString());
    if (params.otp != null) __params = __params.set('otp', params.otp.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/walletOtp/verify-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<String>;
        let _body: String = null;
        _body = _resp.body;

        return _resp.clone({body: _body}) as HttpResponse<String>;
      })
    );
  }

  /**
   * @param mobile
   * @param otp
   */
  postVerifyOtpUsingGET(params: SendOtpControllerService.VerifyOtpGETParams): Observable<any> {
    return this.postVarifyOtpUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SendOtpControllerService {
  
  /**
   * Parameters for findAllUserUsingGET
   */
  export interface VerifyOtpGETParams {

    /**
     * mobile
     */
    mobile?: String;

    /**
     * otp
     */
    otp?: String;

  }
}

export { SendOtpControllerService }
