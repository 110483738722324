/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesProductType } from '../models/resources-product-type-';
import { ResourceProductType } from '../models/resource-product-type-';
import { ProductType } from '../models/product-type';

/**
 * $ Proxy 174
 */
@Injectable()
class ProductTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProductTypeEntityService.FindAllProductTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllProductTypeUsingGETResponse(params: ProductTypeEntityService.FindAllProductTypeUsingGETParams): Observable<HttpResponse<ResourcesProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/productTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProductType = null;
        _body = _resp.body as ResourcesProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProductType>;
      })
    );
  }

  /**
   * @param params The `ProductTypeEntityService.FindAllProductTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllProductTypeUsingGET(params: ProductTypeEntityService.FindAllProductTypeUsingGETParams): Observable<ResourcesProductType> {
    return this.findAllProductTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveProductTypeUsingPOSTResponse(body: ProductType): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/productTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveProductTypeUsingPOST(body: ProductType): Observable<ResourceProductType> {
    return this.saveProductTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdProductTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/productTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdProductTypeUsingGET(id: number): Observable<ResourceProductType> {
    return this.findByIdProductTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProductTypeEntityService.SaveProductTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProductTypeUsingPUTResponse(params: ProductTypeEntityService.SaveProductTypeUsingPUTParams): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/productTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param params The `ProductTypeEntityService.SaveProductTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProductTypeUsingPUT(params: ProductTypeEntityService.SaveProductTypeUsingPUTParams): Observable<ResourceProductType> {
    return this.saveProductTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteProductTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/productTypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteProductTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteProductTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProductTypeEntityService.SaveProductTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProductTypeUsingPATCHResponse(params: ProductTypeEntityService.SaveProductTypeUsingPATCHParams): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/productTypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param params The `ProductTypeEntityService.SaveProductTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveProductTypeUsingPATCH(params: ProductTypeEntityService.SaveProductTypeUsingPATCHParams): Observable<ResourceProductType> {
    return this.saveProductTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ProductTypeEntityService {

  /**
   * Parameters for findAllProductTypeUsingGET
   */
  export interface FindAllProductTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveProductTypeUsingPUT
   */
  export interface SaveProductTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ProductType;
  }

  /**
   * Parameters for saveProductTypeUsingPATCH
   */
  export interface SaveProductTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ProductType;
  }
}

export { ProductTypeEntityService }
