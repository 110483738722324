/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesReferral } from '../models/resources-referral-';
import { ResourceReferral } from '../models/resource-referral-';
import { Referral } from '../models/referral';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 226
 */
@Injectable()
class ReferralEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReferralEntityService.FindAllReferralUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllReferralUsingGETResponse(params: ReferralEntityService.FindAllReferralUsingGETParams): Observable<HttpResponse<ResourcesReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesReferral = null;
        _body = _resp.body as ResourcesReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesReferral>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.FindAllReferralUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllReferralUsingGET(params: ReferralEntityService.FindAllReferralUsingGETParams): Observable<ResourcesReferral> {
    return this.findAllReferralUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveReferralUsingPOSTResponse(body: Referral): Observable<HttpResponse<ResourceReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceReferral = null;
        _body = _resp.body as ResourceReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceReferral>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveReferralUsingPOST(body: Referral): Observable<ResourceReferral> {
    return this.saveReferralUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ReferralEntityService.FindByConsultationIdReferralUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdReferralUsingGETResponse(params: ReferralEntityService.FindByConsultationIdReferralUsingGETParams): Observable<HttpResponse<ResourcesReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/referrals/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesReferral = null;
        _body = _resp.body as ResourcesReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesReferral>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.FindByConsultationIdReferralUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdReferralUsingGET(params: ReferralEntityService.FindByConsultationIdReferralUsingGETParams): Observable<ResourcesReferral> {
    return this.findByConsultationIdReferralUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdReferralUsingGETResponse(id: number): Observable<HttpResponse<ResourceReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/referrals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceReferral = null;
        _body = _resp.body as ResourceReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceReferral>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdReferralUsingGET(id: number): Observable<ResourceReferral> {
    return this.findByIdReferralUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ReferralEntityService.SaveReferralUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveReferralUsingPUTResponse(params: ReferralEntityService.SaveReferralUsingPUTParams): Observable<HttpResponse<ResourceReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/referrals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceReferral = null;
        _body = _resp.body as ResourceReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceReferral>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.SaveReferralUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveReferralUsingPUT(params: ReferralEntityService.SaveReferralUsingPUTParams): Observable<ResourceReferral> {
    return this.saveReferralUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteReferralUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/referrals/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteReferralUsingDELETE(id: number): Observable<void> {
    return this.deleteReferralUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ReferralEntityService.SaveReferralUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveReferralUsingPATCHResponse(params: ReferralEntityService.SaveReferralUsingPATCHParams): Observable<HttpResponse<ResourceReferral>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/referrals/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceReferral = null;
        _body = _resp.body as ResourceReferral;
        return _resp.clone({body: _body}) as HttpResponse<ResourceReferral>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.SaveReferralUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveReferralUsingPATCH(params: ReferralEntityService.SaveReferralUsingPATCHParams): Observable<ResourceReferral> {
    return this.saveReferralUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  referralConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/referrals/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  referralConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.referralConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ReferralEntityService.ReferralConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  referralConsultationUsingPOSTResponse(params: ReferralEntityService.ReferralConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/referrals/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.ReferralConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  referralConsultationUsingPOST(params: ReferralEntityService.ReferralConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.referralConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ReferralEntityService.ReferralConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  referralConsultationUsingPUTResponse(params: ReferralEntityService.ReferralConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/referrals/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.ReferralConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  referralConsultationUsingPUT(params: ReferralEntityService.ReferralConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.referralConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  referralConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/referrals/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  referralConsultationUsingDELETE(id: number): Observable<void> {
    return this.referralConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ReferralEntityService.ReferralConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  referralConsultationUsingPATCHResponse(params: ReferralEntityService.ReferralConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/referrals/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `ReferralEntityService.ReferralConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  referralConsultationUsingPATCH(params: ReferralEntityService.ReferralConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.referralConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ReferralEntityService {

  /**
   * Parameters for findAllReferralUsingGET
   */
  export interface FindAllReferralUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdReferralUsingGET
   */
  export interface FindByConsultationIdReferralUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for saveReferralUsingPUT
   */
  export interface SaveReferralUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Referral;
  }

  /**
   * Parameters for saveReferralUsingPATCH
   */
  export interface SaveReferralUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Referral;
  }

  /**
   * Parameters for referralConsultationUsingPOST
   */
  export interface ReferralConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for referralConsultationUsingPUT
   */
  export interface ReferralConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for referralConsultationUsingPATCH
   */
  export interface ReferralConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { ReferralEntityService }
