/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProcedureTypes } from '../models/procedure-types';

/**
 * Procedure Type Controller
 */
@Injectable()
class ProcedureTypeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingGETParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingGETResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingGETParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingGETParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingGET(params: ProcedureTypeControllerService.ProceduresByClinicUsingGETParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingHEADParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingHEADResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingHEADParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingHEADParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingHEAD(params: ProcedureTypeControllerService.ProceduresByClinicUsingHEADParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingPOSTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingPOSTResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingPOSTParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingPOSTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingPOST(params: ProcedureTypeControllerService.ProceduresByClinicUsingPOSTParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingPUTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingPUTResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingPUTParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingPUTParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingPUT(params: ProcedureTypeControllerService.ProceduresByClinicUsingPUTParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingDELETEParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingDELETEResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingDELETEParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingDELETEParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingDELETE(params: ProcedureTypeControllerService.ProceduresByClinicUsingDELETEParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingOPTIONSParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingOPTIONSResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingOPTIONSParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingOPTIONSParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingOPTIONS(params: ProcedureTypeControllerService.ProceduresByClinicUsingOPTIONSParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingPATCHParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingPATCHResponse(params: ProcedureTypeControllerService.ProceduresByClinicUsingPATCHParams): Observable<HttpResponse<Array<ProcedureTypes>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/clinic/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ProcedureTypes> = null;
        _body = _resp.body as Array<ProcedureTypes>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ProcedureTypes>>;
      })
    );
  }

  /**
   * @param params The `ProcedureTypeControllerService.ProceduresByClinicUsingPATCHParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  proceduresByClinicUsingPATCH(params: ProcedureTypeControllerService.ProceduresByClinicUsingPATCHParams): Observable<Array<ProcedureTypes>> {
    return this.proceduresByClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ProcedureTypeControllerService {

  /**
   * Parameters for proceduresByClinicUsingGET
   */
  export interface ProceduresByClinicUsingGETParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for proceduresByClinicUsingHEAD
   */
  export interface ProceduresByClinicUsingHEADParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for proceduresByClinicUsingPOST
   */
  export interface ProceduresByClinicUsingPOSTParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for proceduresByClinicUsingPUT
   */
  export interface ProceduresByClinicUsingPUTParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for proceduresByClinicUsingDELETE
   */
  export interface ProceduresByClinicUsingDELETEParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for proceduresByClinicUsingOPTIONS
   */
  export interface ProceduresByClinicUsingOPTIONSParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for proceduresByClinicUsingPATCH
   */
  export interface ProceduresByClinicUsingPATCHParams {

    /**
     * name
     */
    name: string;

    /**
     * clinicId
     */
    clinicId: number;
    sort?: string;
    size?: number;
    page?: number;
  }
}

export { ProcedureTypeControllerService }
