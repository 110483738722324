export class OrderCartPriceContext {
    orderSummary: any = {};
    collectPayLaterAmount = false;
    payLaterForHomeCollectionOnTests: any;

    constructor(private order: any) {
        if (order) {
            this.collectPayLaterAmount = order.collectPayLaterAmount;
            this.payLaterForHomeCollectionOnTests = order.payLaterForHomeCollectionOnTests;
            this.orderSummary.totalConsultationPrice = this.calculateConsultationPrice(order.orderItems);
            this.orderSummary.totalMedicinePrice = this.calculateMedicinePrice(order.orderItems);
            this.orderSummary.totalMedicineDisplayPrice = this.calculateMedicineDisplayPrice(order.orderItems);
            this.orderSummary.totalNutritionPrice = this.calculateNutritionPrice(order.orderItems);
            this.orderSummary.totalNutritionDisplayPrice = this.calculateNutritionDisplayPrice(order.orderItems);
            this.orderSummary.totalPhysiotherapyPrice = this.calculatePhysiotherapyPrice(order.orderItems);
            this.orderSummary.totalPhysiotherapyDisplayPrice = this.calculatePhysiotherapyDisplayPrice(order.orderItems);
            this.orderSummary.totalPsychologyPrice = this.calculatePsychologyPrice(order.orderItems);
            this.orderSummary.totalPsychologyDisplayPrice = this.calculatePsychologyDisplayPrice(order.orderItems);
            this.orderSummary.totalSubscriptionPrice = this.calculateSubscriptionPrice(order.orderItems);
            this.orderSummary.totalSubscriptionDisplayPrice = this.calculateSubscriptionDisplayPrice(order.orderItems);
            this.orderSummary.totalMedicinePricePayLater = this.calculateMedicinePrice(order.orderItems.filter(oi => oi.payOnDelivery));
            this.orderSummary.totalMedicineDisplayPricePayLater = this.calculateMedicineDisplayPrice(order.orderItems.filter(oi => oi.payOnDelivery));
            this.orderSummary.totalDiagnosticsPrice = this.calculateDiagnosticsPrice(order.orderItems);
            this.orderSummary.totalDiagnosticsDisplayPrice = this.calculateDiagnosticsDisplayPrice(order.orderItems);
            this.orderSummary.totalProceduresDisplayPrice = this.calculateProceduresDisplayPrice(order.orderItems);
            this.orderSummary.totalProceduresPrice = this.calculateProceduresPrice(order.orderItems);

            this.orderSummary.totalLHRTretmentDisplayPrice = this.calculateLHRTretmentDisplayPrice(order.orderItems);
            this.orderSummary.totalLHRTretment = this.calculateLHRTretmentPrice(order.orderItems);

            this.orderSummary.totalMediFacialDisplayPrice = this.calculateMediFacialDisplayPrice(order.orderItems);
            this.orderSummary.totalMediFacialPrice = this.calculateMediFacialPrice(order.orderItems);

            this.orderSummary.totalChemicalPeelDisplayPrice = this.calculateChemicalPeelDisplayPrice(order.orderItems);
            this.orderSummary.totalChemicalPeelPrice = this.calculateChemicalPeelPrice(order.orderItems);

            this.orderSummary.totalPRPTreatmentDisplayPrice = this.calculatePRPTreatmentDisplayPrice(order.orderItems);
            this.orderSummary.totalPRPTreatmentPrice = this.calculatePRPTreatmentPrice(order.orderItems);

            this.orderSummary.totalSkinPigmentationTreatmentDisplayPrice = this.calculateSkinPigmentationTreatmentDisplayPrice(order.orderItems);
            this.orderSummary.totalSkinPigmentationTreatmentPrice = this.calculateSkinPigmentationTreatmentPrice(order.orderItems);

            this.orderSummary.totalScarReductionDisplayPrice = this.calculateScarReductionTreatmentDisplayPrice(order.orderItems);
            this.orderSummary.totalScarReductionTreatmentPrice = this.calculateScarReductionTreatmentPrice(order.orderItems);

            this.orderSummary.totalHifuTreatmentDisplayPrice = this.calculateHifuTreatmentDisplayPrice(order.orderItems);
            this.orderSummary.totalHifuTreatmentPrice = this.calculateHifuTreatmentPrice(order.orderItems);

            this.orderSummary.totalBotoxFillersTreatmentDisplayPrice = this.calculateBotoxFillersTreatmentDisplayPrice(order.orderItems);
            this.orderSummary.totalBotoxFillersTreatmentPrice = this.calculateBotoxFillersTreatmentPrice(order.orderItems);

            this.orderSummary.totalAntiAgeingTreatmentDisplayPrice = this.calculateAntiAgeingTreatmentDisplayPrice(order.orderItems);
            this.orderSummary.totalAntiAgeingTreatmentPrice = this.calculateAntiAgeingTreatmentPrice(order.orderItems);

            this.orderSummary.totalOtherServicesDisplayPrice = this.calculateOtherServicesDisplayPrice(order.orderItems);
            this.orderSummary.totalOtherServicesPrice = this.calculateOtherServicesPrice(order.orderItems);

            this.orderSummary.totalOtherUpgradeCharges = this.calculateOtherUpgradeCharges(order);


            this.orderSummary.totalDeliveryCharges = 0;
            this.orderSummary.totalOtherCharges = this.calculateOtherCharges(order);
            this.orderSummary.payLaterAmount =
                this.calculatePayLaterAmountForMedicines(order.orderItems)
                + this.calculatePayLaterAmountForDiagnostics(order.orderItems);
        }
    }

    private calculatePayLaterAmountForMedicines(orderItems) {

        if (!orderItems.length) {
            return 0;
        }

        let medicineItems = orderItems.filter(item => item.productTypeId === 1 || item.productTypeId === 2)
        if (this.collectPayLaterAmount) {
            medicineItems = medicineItems.filter(item => !item.payOnDelivery);
        } else {
            medicineItems = medicineItems.filter(item => item.payOnDelivery);
        }
        if (medicineItems.length) {
            return medicineItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePayLaterAmountForMedicinesDisplayPrice(orderItems) {

        if (!orderItems.length) {
            return 0;
        }

        let medicineItems = orderItems.filter(item => item.productTypeId === 1 || item.productTypeId === 2)
        if (this.collectPayLaterAmount) {
            medicineItems = medicineItems.filter(item => !item.payOnDelivery);
        } else {
            medicineItems = medicineItems.filter(item => item.payOnDelivery);
        }
        if (medicineItems.length) {
            return medicineItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePayLaterAmountForDiagnostics(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let diagnosticItems = orderItems.filter(item => item.productTypeId === 5 || item.productTypeId === 4 || item.productTypeId === 3)
        if (this.payLaterForHomeCollectionOnTests) {
            diagnosticItems = diagnosticItems.filter(item => item.payOnDelivery && !item.deferred);
        } else {
            diagnosticItems = diagnosticItems.filter(item => item.payOnDelivery && !item.deferred && item.productTypeId === 5);
        }
        if (diagnosticItems.length) {
            return diagnosticItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePayLaterAmountForDiagnosticsDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let diagnosticItems = orderItems
            .filter(item => item.productTypeId === 5 || item.productTypeId === 4 || item.productTypeId === 3)
            .filter(item => !item.deferred);
        if (this.collectPayLaterAmount) {
            diagnosticItems = diagnosticItems.filter(item => !item.payOnDelivery && !item.deferred);
        } else {
            diagnosticItems = diagnosticItems.filter(item => item.payOnDelivery && !item.deferred);
        }
        if (diagnosticItems.length) {
            return diagnosticItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateConsultationPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let consultationItem = orderItems.filter(item => item && item.productTypeId === 7);
        if (consultationItem.length) {
            return consultationItem[0].actualFee;
        }

        return 0;
    }

    private calculateMedicinePrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let medicineItems = orderItems.filter(item => item.productTypeId === 1 || item.productTypeId === 2);
        if (medicineItems.length) {
            return medicineItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateMedicineDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let medicineItems = orderItems.filter(item => item.productTypeId === 1 || item.productTypeId === 2);
        if (medicineItems.length) {
            return medicineItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }
    private calculateNutritionPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let NutritionItems = orderItems.filter(item => item.productTypeId === 13);
        if (NutritionItems.length) {
            return NutritionItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateNutritionDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let NutritionItems = orderItems.filter(item => item.productTypeId === 13);
        if (NutritionItems.length) {
            return NutritionItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePsychologyPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let PsychologyItems = orderItems.filter(item => item.productTypeId === 11 || item.productTypeId === 12 || item.productTypeId === 10);
        if (PsychologyItems.length) {
            return PsychologyItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateSubscriptionPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let Subscription = orderItems.filter(item => item.productTypeId === 16);
        if (Subscription.length) {
            return Subscription.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateSubscriptionDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let Subscription = orderItems.filter(item => item.productTypeId === 16);
        if (Subscription.length) {
            return Subscription.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePsychologyDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let PsychologyItems = orderItems.filter(item => item.productTypeId === 10 || item.productTypeId === 12 || item.productTypeId === 11);
        if (PsychologyItems.length) {
            return PsychologyItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePhysiotherapyPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let PhysiotherapyItems = orderItems.filter(item => item.productTypeId === 9 || item.productTypeId === 8);
        if (PhysiotherapyItems.length) {
            return PhysiotherapyItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePhysiotherapyDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let PhysiotherapyItems = orderItems.filter(item => item.productTypeId === 8 || item.productTypeId === 9);
        if (PhysiotherapyItems.length) {
            return PhysiotherapyItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateProceduresPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 6);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateLHRTretmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let lhrtItems = orderItems.filter(item => item.productTypeId === 17 || item.productTypeId === 18);
        if (lhrtItems.length) {
            return lhrtItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateLHRTretmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let lhrtItems = orderItems.filter(item => item.productTypeId === 17 || item.productTypeId === 18);
        if (lhrtItems.length) {
            return lhrtItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateMediFacialDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let mediFacialItems = orderItems.filter(item => item.productTypeId === 19 || item.productTypeId === 20);
        if (mediFacialItems.length) {
            return mediFacialItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateMediFacialPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 19 || item.productTypeId === 20);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateChemicalPeelDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let chemicalPeelItems = orderItems.filter(item => item.productTypeId === 21 || item.productTypeId === 22);
        if (chemicalPeelItems.length) {
            return chemicalPeelItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateChemicalPeelPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 21 || item.productTypeId === 22);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculatePRPTreatmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let prpItems = orderItems.filter(item => item.productTypeId === 25);
        if (prpItems.length) {
            return prpItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculatePRPTreatmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 25);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateSkinPigmentationTreatmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let skinPigmentationItems = orderItems.filter(item => item.productTypeId === 24);
        if (skinPigmentationItems.length) {
            return skinPigmentationItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateSkinPigmentationTreatmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 24);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateScarReductionTreatmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let scarReductionItems = orderItems.filter(item => item.productTypeId === 23);
        if (scarReductionItems.length) {
            return scarReductionItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateScarReductionTreatmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 23);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateHifuTreatmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let hifuItems = orderItems.filter(item => item.productTypeId === 28);
        if (hifuItems.length) {
            return hifuItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateHifuTreatmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 28);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateBotoxFillersTreatmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let botoxFillersItems = orderItems.filter(item => item.productTypeId === 29);
        if (botoxFillersItems.length) {
            return botoxFillersItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateBotoxFillersTreatmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 29);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateAntiAgeingTreatmentDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let antiAgeingItems = orderItems.filter(item => item.productTypeId === 30);
        if (antiAgeingItems.length) {
            return antiAgeingItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateAntiAgeingTreatmentPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 30);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateOtherServicesDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }
        let lhrtItems = orderItems.filter(item => item.productTypeId === 26 || item.productTypeId === 27);
        if (lhrtItems.length) {
            return lhrtItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }
        return 0;
    }

    private calculateOtherServicesPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 26 || item.productTypeId === 27);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateOtherCharges(order) {
        if (order.otherCharges === 0) {
            return order.otherCharges;
        }
        if (!order.orderItems.length) {
            return 0;
        }
        let diagnosticItems = order.orderItems
            .filter(item => item.productTypeId === 5 || item.productTypeId === 4 || item.productTypeId === 3)
            .filter(item => !item.deferred);
        if (diagnosticItems.length) {
            return diagnosticItems.reduce((sum, item) => sum + (item.otherCharges * item.unitQuantity), 0);
        }
        return 0;
    }
    private calculateOtherUpgradeCharges(order) {
        if (order.otherCharges === 0) {
            return order.otherCharges;
        }
        if (!order.orderItems.length) {
            return 0;
        }
        return order.otherCharges
    }
    private calculateProceduresDisplayPrice(orderItems) {
        if (!orderItems.length) {
            return 0;
        }

        let procedureItems = orderItems.filter(item => item.productTypeId === 6);
        if (procedureItems.length) {
            return procedureItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateDiagnosticsPrice(orderItems: any): number {
        if (!orderItems.length) {
            return 0;
        }

        let diagnosticItems = orderItems
            .filter(item => item.productTypeId === 5 || item.productTypeId === 4 || item.productTypeId === 3)
            .filter(item => !item.deferred);
        if (diagnosticItems.length) {
            return diagnosticItems.reduce((sum, item) => sum + (item.actualFee * item.unitQuantity), 0);
        }

        return 0;
    }

    private calculateDiagnosticsDisplayPrice(orderItems: any): number {
        if (!orderItems.length) {
            return 0;
        }

        let diagnosticItems = orderItems
            .filter(item => item.productTypeId === 5 || item.productTypeId === 4 || item.productTypeId === 3)
            .filter(item => !item.deferred);
        if (diagnosticItems.length) {
            return diagnosticItems.reduce((sum, item) => sum + (item.fee * item.unitQuantity), 0);
        }

        return 0;
    }
}
