import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from './../api-configuration';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranscriberService {

  constructor(
    private AUTH : ApiConfiguration,
    private HttpClient: HttpClient
  ) { }

  fetchAllTranscriber(params){
    return this.HttpClient.get(this.AUTH.rootUrl + 'api/v1/auth/transcribe' , {params})
  }

  editTranscriberDetails(params){
    let body = {...params};
    delete body['id'];
    return this.HttpClient.patch(this.AUTH.rootUrl + 'api/v1/auth/transcribe/edit/' + params.id ,body)
  }

  addTranscriberDetails(body){
    return this.HttpClient.post(this.AUTH.rootUrl + 'api/v1/auth/transcribe/add' , body);
  }

  deleteTranscriber(id){
    return this.HttpClient.patch(this.AUTH.rootUrl + `api/v1/auth/transcribe/delete/${id}?delete=1`,{});
  }

  toggleTranscriberActive(params){
    return this.HttpClient.patch(this.AUTH.rootUrl + `api/v1/auth/transcribe/active/${params.id}?active=${params.isActive}` ,{})
  }
}
