/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Doctor } from '../models/doctor';

/**
 * Doctor Controller
 */
@Injectable()
class DoctorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingGETResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingGET(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingHEADResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingHEAD(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingHEADResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingPOSTResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingPOST(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingPOSTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingPUTResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingPUT(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingPUTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingDELETEResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingDELETE(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingDELETEResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingOPTIONSResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingOPTIONS(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingOPTIONSResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingPATCHResponse(): Observable<HttpResponse<Array<Doctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctor/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<Doctor> = null;
        _body = _resp.body as Array<Doctor>;
        return _resp.clone({body: _body}) as HttpResponse<Array<Doctor>>;
      })
    );
  }

  /**
   * @return OK
   */
  findAllDoctorsUsingPATCH(): Observable<Array<Doctor>> {
    return this.findAllDoctorsUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingGETResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingGET(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingGETResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingHEADResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingHEAD(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingHEADResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingPOSTResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingPOST(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingPOSTResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingPUTResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingPUT(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingPUTResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingDELETEResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingDELETE(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingDELETEResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingOPTIONSResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingOPTIONS(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingOPTIONSResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingPATCHResponse(code: string): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctor/code/${code}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findDoctorByIdUsingPATCH(code: string): Observable<Doctor> {
    return this.findDoctorByIdUsingPATCHResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorControllerService.GetSearchWiseDoctorsUsingGETParams` containing the following parameters:
   *
   * - `speciality`: speciality
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `search`: search
   *
   * - `page`:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `available`: available
   *
   * @return OK
   */
  getSearchWiseDoctorsUsingGETResponse(params: DoctorControllerService.GetSearchWiseDoctorsUsingGETParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.speciality || []).forEach((val, index) => {if (val != null) __params = __params.append('speciality', val.toString())});
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.longitude != null) __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null) __params = __params.set('latitude', params.latitude.toString());
    if (params.available != null) __params = __params.set('available', params.available.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor/searches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `DoctorControllerService.GetSearchWiseDoctorsUsingGETParams` containing the following parameters:
   *
   * - `speciality`: speciality
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `search`: search
   *
   * - `page`:
   *
   * - `longitude`: longitude
   *
   * - `latitude`: latitude
   *
   * - `available`: available
   *
   * @return OK
   */
  getSearchWiseDoctorsUsingGET(params: DoctorControllerService.GetSearchWiseDoctorsUsingGETParams): Observable<{}> {
    return this.getSearchWiseDoctorsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingGET_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingGET_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingHEAD_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingHEAD_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingHEAD_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingPOST_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingPOST_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingPOST_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingPUT_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingPUT_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingPUT_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingDELETE_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingDELETE_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingOPTIONS_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingOPTIONS_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingOPTIONS_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingPATCH_1Response(id: number): Observable<HttpResponse<Doctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Doctor = null;
        _body = _resp.body as Doctor;
        return _resp.clone({body: _body}) as HttpResponse<Doctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findDoctorByIdUsingPATCH_1(id: number): Observable<Doctor> {
    return this.findDoctorByIdUsingPATCH_1Response(id).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorControllerService {

  /**
   * Parameters for getSearchWiseDoctorsUsingGET
   */
  export interface GetSearchWiseDoctorsUsingGETParams {

    /**
     * speciality
     */
    speciality?: Array<number>;
    sort?: string;
    size?: number;

    /**
     * search
     */
    search?: string;
    page?: number;

    /**
     * longitude
     */
    longitude?: number;

    /**
     * latitude
     */
    latitude?: number;

    /**
     * available
     */
    available?: string;
  }
}

export { DoctorControllerService }
