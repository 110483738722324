import { Component, OnInit, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { ClinicEntityService, SymptomEntityService, DiagnosisTypeControllerService, VitalEntityService, AllergyTypeEntityService, ExistingConditionTypeEntityService, ConsultationEntityService, VitalTypeEntityService } from '../../api/emr/services';
import { Clinic, DoctorInClinic, Symptom, SymptomTypes, ResourceConsultation } from '../../api/emr/models';
import { BehaviorSubject } from 'rxjs';
import { EmbeddedApiService } from '../../services/embedded-api.service';

@Component({
  selector: 'app-medical-information',
  templateUrl: './medical-information.component.html',
  styleUrls: ['./medical-information.component.scss'],
  outputs: ['showGeneralInformation', 'addPatientToQueue'],
  inputs: ['doctorInClinicIds', 'consultationId', 'usedBy', 'isSameDaySlot', 'addingToQueue']
})
export class MedicalInformationComponent implements OnInit {

  showGeneralInformation = new EventEmitter();
  addPatientToQueue = new EventEmitter();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredSymptoms: BehaviorSubject<SymptomTypes[]> = new BehaviorSubject([]);
  filteredpreExistingConditions: BehaviorSubject<SymptomTypes[]> = new BehaviorSubject([]);
  filteredallergies: BehaviorSubject<SymptomTypes[]> = new BehaviorSubject([]);

  selectedClinic = new FormControl();
  allClinics: Array<Clinic>;
  allDoctorsInClinic: Array<DoctorInClinic>;
  doctorInClinicIds;

  symptoms = [];
  preExistingConditions = [];
  allergies = [];

  medicalInformationForm: FormGroup;
  loadingClinics: boolean = true;
  addingToQueue: boolean = false;
  consultationId: number;
  consultation;
  loadingVitals: boolean = true;
  allVitalTypes;
  selectVital = new FormControl();
  usedBy;
  isSameDaySlot;

  @ViewChild('symptomSearchInput') symptomSearchInput: ElementRef;
  @ViewChild('preExistingConditionSearchInput') preExistingConditionSearchInput: ElementRef;
  @ViewChild('allergySearchInput') allergySearchInput: ElementRef;

  constructor(
    private clinicEntityService: ClinicEntityService,
    private diagnosisTypeControllerService: DiagnosisTypeControllerService,
    private VitalTypeEntityService: VitalTypeEntityService,
    private allergyTypeEntityService: AllergyTypeEntityService,
    private existingConditionTypeEntityService: ExistingConditionTypeEntityService,
    private ConsultationEntityService: ConsultationEntityService,
    private consultationEmbeddedApiService: EmbeddedApiService<ResourceConsultation>,
    private formBuilder: FormBuilder
  ) { }

  addVital(event) {
    let vitalsArray = this.medicalInformationForm.get('vitals') as FormArray;

    let selectedVitalType = event.value;
    let newVital = {
      title: selectedVitalType.name,
      value: '',
      unit: selectedVitalType.unit,
      vitalType: selectedVitalType
    }

    let formGroup = this.formBuilder.group(newVital);

    for(let key in formGroup.controls){
      formGroup.get(key).setValidators(Validators.required);
      formGroup.get(key).updateValueAndValidity();
      if(key != 'value'){
        formGroup.get(key).disable();
      }
    }
    vitalsArray.push(formGroup);

    this.selectVital.setValue('');
  }

  filterSymptoms(val: any): void {
    const that = this;
    const search = (val.name || val).toLowerCase();
    if (search.length < 3) {
      return;
    }

    this.diagnosisTypeControllerService.findInSymptomsTypeUsingGET(search)
      .subscribe((response: any) => {
        let symptoms = response && response._embedded && response._embedded.symptomTypes;
        this.filteredSymptoms.next(symptoms);
      });
  }

  filterPreExistingConditions(val:any): void {
    const that = this;
    const search = (val.name || val).toLowerCase();
    if (search.length < 3) {
      return;
    }

    this.existingConditionTypeEntityService.findByNameContainingAndIsActiveTrueExistingConditionTypeUsingGET({
      name: search
    })
      .subscribe((response) => {
        let preExistingConditions = response && response._embedded && response._embedded.existingConditionType;
        this.filteredpreExistingConditions.next(preExistingConditions);
      });
  }

  filterAllergies(val: any):void {
    const that = this;
    const search = (val.name || val).toLowerCase();
    if (search.length < 3) {
      return;
    }

    this.allergyTypeEntityService.findByNameContainingAndIsActiveTrueAllergyTypeUsingGET({
      name: search
    })
      .subscribe((response) => {
        let allergies = response && response._embedded && response._embedded.allergiesType;
        this.filteredallergies.next(allergies);
      });
  }

  goBackToGeneralInformation(){
    if(confirm('Medical information filled will be lost.')){
      this.showGeneralInformation.emit()
    }
  }

  getAllVitals(doctorInClinic){
    this.loadingVitals = true;
    if(!doctorInClinic){
      return;
    }

    let allVitals = [];
    doctorInClinic.doctor.speciality && doctorInClinic.doctor.speciality.forEach(speciality => {
      allVitals.concat(...speciality.specialityVitalTypes);
    });
    this.VitalTypeEntityService.findAllVitalTypeUsingGET({}).subscribe(res => {
      this.allVitalTypes = res && res._embedded && res._embedded.vitaltypes;
    })
    this.loadingVitals = false;
    this.allVitalTypes = allVitals;
  }

  getAllClinics() {
    this.clinicEntityService.findDistinctByDoctorInClinicsIdInClinicUsingGET(this.doctorInClinicIds).subscribe(clinics => {
      this.allClinics = clinics._embedded.clinics;

      if(this.consultationId){
        this.ConsultationEntityService.findByIdConsultationUsingGET(this.consultationId).subscribe(consultation => {
          this.consultation = this.consultationEmbeddedApiService.parse(consultation);

          let selectedClinic = this.allClinics.filter(clinic => clinic.id === this.consultation.doctorInClinic.clinic.id)[0];
          this.medicalInformationForm.get('clinic').setValue(selectedClinic);
          this.loadingClinics = false;
        }, err => {
          this.loadingClinics = false;
        })
      } else{

        if (this.allClinics.length === 1) {
  
          this.allDoctorsInClinic = this.allClinics[0].doctorInClinics;
          this.medicalInformationForm.get('clinic').setValue(this.allClinics[0]);
        } else {
          if(this.usedBy != 'bookAppointment'){
            this.medicalInformationForm.get('clinic').enable();
          }
        }
        this.loadingClinics = false;
      }
    }, err => {
    })
  }

  isVitalUsed(vitalType){
    let vitalsArrayForm = this.medicalInformationForm.get('vitals') as FormGroup;

    let filteredArray =  vitalsArrayForm.getRawValue().filter(vital => vital.vitalType.id === vitalType.id);

    if(filteredArray.length){
      return true;
    } else {
      return false;
    }
  }

  onSymptomSelect(event: MatAutocompleteSelectedEvent): void {
    this.symptoms.push(event.option.value);
    this.medicalInformationForm.get('symptoms').setValue('');
    this.symptomSearchInput.nativeElement.value = '';
  }

  onPreExistingConditionSelect(event: MatAutocompleteSelectedEvent): void {
    this.preExistingConditions.push(event.option.value);
    this.medicalInformationForm.get('preExistingConditions').setValue('');
    this.preExistingConditionSearchInput.nativeElement.value = '';
  }

  onAllergySelect(event: MatAutocompleteSelectedEvent): void {
    this.allergies.push(event.option.value);
    this.medicalInformationForm.get('allergies').setValue('');
    this.allergySearchInput.nativeElement.value = '';
  }

  removeSymptom(symptom: string): void {
    const index = this.symptoms.indexOf(symptom);

    if (index >= 0) {
      this.symptoms.splice(index, 1);
    }

    this.symptomSearchInput.nativeElement.focus();
  }

  removePreExistingCondition(preExistingCondition: string): void {
    const index = this.preExistingConditions.indexOf(preExistingCondition);

    if (index >= 0) {
      this.preExistingConditions.splice(index, 1);
    }

    this.preExistingConditionSearchInput.nativeElement.focus();
  }

  removeAllergy(allergy: string): void {
    const index = this.allergies.indexOf(allergy);

    if (index >= 0) {
      this.allergies.splice(index, 1);
    }

    this.allergySearchInput.nativeElement.focus();
  }

  removeVital(vitalIndex) {
    let vitalsArray = this.medicalInformationForm.get('vitals') as FormArray;
    vitalsArray.removeAt(vitalIndex);
  }

  saveAndAddToQueue(){
    let formValue = this.medicalInformationForm.getRawValue();
    formValue.preExistingConditions = this.preExistingConditions;
    formValue.symptoms = this.symptoms;
    formValue.allergies = this.allergies;
    this.addPatientToQueue.emit(formValue);
  }

  ngOnInit() {
    this.medicalInformationForm = this.formBuilder.group({
      clinic: [{ value: '', disabled: true }, Validators.required],
      doctorInClinic: [{ value: '', disabled: true }, Validators.required],
      symptoms: [],
      vitals: this.formBuilder.array([]),
      preExistingConditions: [],
      allergies: []
    })

    this.medicalInformationForm.get('symptoms').valueChanges.subscribe(value => {
      this.filterSymptoms(value);
    })

    this.medicalInformationForm.get('preExistingConditions').valueChanges.subscribe(value => {
      this.filterPreExistingConditions(value);
    })

    this.medicalInformationForm.get('allergies').valueChanges.subscribe(value => {
      this.filterAllergies(value);
    })

    this.medicalInformationForm.get('clinic').valueChanges.subscribe(clinic => {
      this.allDoctorsInClinic = clinic.doctorInClinics;
      if(this.consultationId){

        let selectedDoctor = this.allDoctorsInClinic.filter(doctorInClinic => doctorInClinic.id === this.consultation.doctorInClinic.id)[0];
        this.medicalInformationForm.get('doctorInClinic').setValue(selectedDoctor);
        if(this.usedBy != 'bookAppointment'){
          this.medicalInformationForm.get('doctorInClinic').enable();
        }
      } else{

        if (clinic && clinic.doctorInClinics.length === 1) {
          this.medicalInformationForm.get('doctorInClinic').setValue(clinic.doctorInClinics[0])
        } else {
          if(this.usedBy != 'bookAppointment'){
            this.medicalInformationForm.get('doctorInClinic').enable();
          }
        }
      }
    })

    this.medicalInformationForm.get('doctorInClinic').valueChanges.subscribe(doctorInClinic => {
      this.getAllVitals(doctorInClinic);
    })

    this.getAllClinics();
  }

}
