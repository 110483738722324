/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDoctorInClinic } from '../models/resources-doctor-in-clinic-';
import { ResourceDoctorInClinic } from '../models/resource-doctor-in-clinic-';
import { DoctorInClinic } from '../models/doctor-in-clinic';
import { ResourceClinic } from '../models/resource-clinic-';
import { ResourcesConsultation } from '../models/resources-consultation-';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceDoctor } from '../models/resource-doctor-';
import { ResourcesTimeSlot } from '../models/resources-time-slot-';
import { ResourceTimeSlot } from '../models/resource-time-slot-';

/**
 * $ Proxy 193
 */
@Injectable()
class DoctorInClinicEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoctorInClinicEntityService.FindAllDoctorInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorInClinicUsingGETResponse(params: DoctorInClinicEntityService.FindAllDoctorInClinicUsingGETParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.FindAllDoctorInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDoctorInClinicUsingGET(params: DoctorInClinicEntityService.FindAllDoctorInClinicUsingGETParams): Observable<ResourcesDoctorInClinic> {
    return this.findAllDoctorInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorInClinicUsingPOSTResponse(body: DoctorInClinic): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorsinclinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDoctorInClinicUsingPOST(body: DoctorInClinic): Observable<ResourceDoctorInClinic> {
    return this.saveDoctorInClinicUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param clinicIds clinicIds
   * @return OK
   */
  findAllByClinicIdInDoctorInClinicUsingGETResponse(clinicIds?: Array<number>): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (clinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('clinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findAllByClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param clinicIds clinicIds
   * @return OK
   */
  findAllByClinicIdInDoctorInClinicUsingGET(clinicIds?: Array<number>): Observable<ResourcesDoctorInClinic> {
    return this.findAllByClinicIdInDoctorInClinicUsingGETResponse(clinicIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param IDs IDs
   * @return OK
   */
  findAllByIdInDoctorInClinicUsingGETResponse(IDs?: Array<number>): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (IDs || []).forEach((val, index) => {if (val != null) __params = __params.append('IDs', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findAllByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param IDs IDs
   * @return OK
   */
  findAllByIdInDoctorInClinicUsingGET(IDs?: Array<number>): Observable<ResourcesDoctorInClinic> {
    return this.findAllByIdInDoctorInClinicUsingGETResponse(IDs).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param clinicId clinicId
   * @return OK
   */
  findByClinicIdDoctorInClinicUsingGETResponse(clinicId?: number): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (clinicId != null) __params = __params.set('clinicId', clinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByClinicId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param clinicId clinicId
   * @return OK
   */
  findByClinicIdDoctorInClinicUsingGET(clinicId?: number): Observable<ResourcesDoctorInClinic> {
    return this.findByClinicIdDoctorInClinicUsingGETResponse(clinicId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.FindByClinicIdInDoctorInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findByClinicIdInDoctorInClinicUsingGETResponse(params: DoctorInClinicEntityService.FindByClinicIdInDoctorInClinicUsingGETParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.clinicId != null) __params = __params.set('clinicId', params.clinicId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.FindByClinicIdInDoctorInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findByClinicIdInDoctorInClinicUsingGET(params: DoctorInClinicEntityService.FindByClinicIdInDoctorInClinicUsingGETParams): Observable<ResourcesDoctorInClinic> {
    return this.findByClinicIdInDoctorInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param name name
   * @return OK
   */
  findByClinicNameContainingDoctorInClinicUsingGETResponse(name?: string): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByClinicNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param name name
   * @return OK
   */
  findByClinicNameContainingDoctorInClinicUsingGET(name?: string): Observable<ResourcesDoctorInClinic> {
    return this.findByClinicNameContainingDoctorInClinicUsingGETResponse(name).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorId doctorId
   * @return OK
   */
  findByDoctorIdDoctorInClinicUsingGETResponse(doctorId?: number): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (doctorId != null) __params = __params.set('doctorId', doctorId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByDoctorId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param doctorId doctorId
   * @return OK
   */
  findByDoctorIdDoctorInClinicUsingGET(doctorId?: number): Observable<ResourceDoctorInClinic> {
    return this.findByDoctorIdDoctorInClinicUsingGETResponse(doctorId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param name name
   * @return OK
   */
  findByDoctorNameContainingDoctorInClinicUsingGETResponse(name?: string): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByDoctorNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param name name
   * @return OK
   */
  findByDoctorNameContainingDoctorInClinicUsingGET(name?: string): Observable<ResourcesDoctorInClinic> {
    return this.findByDoctorNameContainingDoctorInClinicUsingGETResponse(name).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param IDs IDs
   * @return OK
   */
  findByIdInDoctorInClinicUsingGETResponse(IDs?: Array<number>): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    //(IDs || []).forEach((val, index) => {if (val != null) __params = __params.append('IDs', val.toString())});
    if (IDs != null && IDs.length) __params = __params.set('IDs', IDs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByIdIn?projection=doctorInClinicForMedicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param IDs IDs
   * @return OK
   */
  findByIdInDoctorInClinicUsingGET(IDs?: Array<number>): Observable<ResourcesDoctorInClinic> {
    return this.findByIdInDoctorInClinicUsingGETResponse(IDs).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.FindDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETParams` containing the following parameters:
   *
   * - `name1`: name1
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETResponse(params: DoctorInClinicEntityService.FindDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name1 != null) __params = __params.set('name1', params.name1.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findDistinctByDoctorNameContainingOrClinicNameContaining?projection=doctorInClinicForMedicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.FindDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETParams` containing the following parameters:
   *
   * - `name1`: name1
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGET(params: DoctorInClinicEntityService.FindDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETParams): Observable<ResourcesDoctorInClinic> {
    return this.findDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorInClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}?projection=doctorInClinicForMedicineInClinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDoctorInClinicUsingGET(id: number): Observable<ResourceDoctorInClinic> {
    return this.findByIdDoctorInClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.SaveDoctorInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorInClinicUsingPUTResponse(params: DoctorInClinicEntityService.SaveDoctorInClinicUsingPUTParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.SaveDoctorInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorInClinicUsingPUT(params: DoctorInClinicEntityService.SaveDoctorInClinicUsingPUTParams): Observable<ResourceDoctorInClinic> {
    return this.saveDoctorInClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDoctorInClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDoctorInClinicUsingDELETE(id: number): Observable<void> {
    return this.deleteDoctorInClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.SaveDoctorInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorInClinicUsingPATCHResponse(params: DoctorInClinicEntityService.SaveDoctorInClinicUsingPATCHParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.SaveDoctorInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDoctorInClinicUsingPATCH(params: DoctorInClinicEntityService.SaveDoctorInClinicUsingPATCHParams): Observable<ResourceDoctorInClinic> {
    return this.saveDoctorInClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicClinicUsingGET(id: number): Observable<ResourceClinic> {
    return this.doctorInClinicClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicClinicUsingPOSTResponse(params: DoctorInClinicEntityService.DoctorInClinicClinicUsingPOSTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicClinicUsingPOST(params: DoctorInClinicEntityService.DoctorInClinicClinicUsingPOSTParams): Observable<ResourceClinic> {
    return this.doctorInClinicClinicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicClinicUsingPUTResponse(params: DoctorInClinicEntityService.DoctorInClinicClinicUsingPUTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicClinicUsingPUT(params: DoctorInClinicEntityService.DoctorInClinicClinicUsingPUTParams): Observable<ResourceClinic> {
    return this.doctorInClinicClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorInClinicClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorInClinicClinicUsingDELETE(id: number): Observable<void> {
    return this.doctorInClinicClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicClinicUsingPATCHResponse(params: DoctorInClinicEntityService.DoctorInClinicClinicUsingPATCHParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicClinicUsingPATCH(params: DoctorInClinicEntityService.DoctorInClinicClinicUsingPATCHParams): Observable<ResourceClinic> {
    return this.doctorInClinicClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicConsultationsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicConsultationsUsingGET_1(id: number): Observable<ResourcesConsultation> {
    return this.doctorInClinicConsultationsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingPOSTResponse(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPOSTParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingPOST(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPOSTParams): Observable<ResourcesConsultation> {
    return this.doctorInClinicConsultationsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingPUTResponse(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPUTParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingPUT(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPUTParams): Observable<ResourcesConsultation> {
    return this.doctorInClinicConsultationsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorInClinicConsultationsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorInClinicConsultationsUsingDELETE_1(id: number): Observable<void> {
    return this.doctorInClinicConsultationsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingPATCHResponse(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPATCHParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingPATCH(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingPATCHParams): Observable<ResourcesConsultation> {
    return this.doctorInClinicConsultationsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingGETResponse(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingGETParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/consultations/${params.consultationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  doctorInClinicConsultationsUsingGET(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingGETParams): Observable<ResourceConsultation> {
    return this.doctorInClinicConsultationsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   */
  doctorInClinicConsultationsUsingDELETEResponse(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/consultations/${params.consultationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicConsultationsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   */
  doctorInClinicConsultationsUsingDELETE(params: DoctorInClinicEntityService.DoctorInClinicConsultationsUsingDELETEParams): Observable<void> {
    return this.doctorInClinicConsultationsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicDoctorUsingGETResponse(id: number): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicDoctorUsingGET(id: number): Observable<ResourceDoctor> {
    return this.doctorInClinicDoctorUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicDoctorUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicDoctorUsingPOSTResponse(params: DoctorInClinicEntityService.DoctorInClinicDoctorUsingPOSTParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicDoctorUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicDoctorUsingPOST(params: DoctorInClinicEntityService.DoctorInClinicDoctorUsingPOSTParams): Observable<ResourceDoctor> {
    return this.doctorInClinicDoctorUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicDoctorUsingPUTResponse(params: DoctorInClinicEntityService.DoctorInClinicDoctorUsingPUTParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicDoctorUsingPUT(params: DoctorInClinicEntityService.DoctorInClinicDoctorUsingPUTParams): Observable<ResourceDoctor> {
    return this.doctorInClinicDoctorUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorInClinicDoctorUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorInClinicDoctorUsingDELETE(id: number): Observable<void> {
    return this.doctorInClinicDoctorUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicDoctorUsingPATCHResponse(params: DoctorInClinicEntityService.DoctorInClinicDoctorUsingPATCHParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/doctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicDoctorUsingPATCH(params: DoctorInClinicEntityService.DoctorInClinicDoctorUsingPATCHParams): Observable<ResourceDoctor> {
    return this.doctorInClinicDoctorUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicTimeSlotsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  doctorInClinicTimeSlotsUsingGET_1(id: number): Observable<ResourcesTimeSlot> {
    return this.doctorInClinicTimeSlotsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingPOSTResponse(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPOSTParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingPOST(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPOSTParams): Observable<ResourcesTimeSlot> {
    return this.doctorInClinicTimeSlotsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingPUTResponse(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPUTParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingPUT(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPUTParams): Observable<ResourcesTimeSlot> {
    return this.doctorInClinicTimeSlotsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  doctorInClinicTimeSlotsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  doctorInClinicTimeSlotsUsingDELETE_1(id: number): Observable<void> {
    return this.doctorInClinicTimeSlotsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingPATCHResponse(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPATCHParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingPATCH(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingPATCHParams): Observable<ResourcesTimeSlot> {
    return this.doctorInClinicTimeSlotsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingGETParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingGETResponse(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingGETParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/timeSlots/${params.timeslotId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingGETParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   *
   * @return OK
   */
  doctorInClinicTimeSlotsUsingGET(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingGETParams): Observable<ResourceTimeSlot> {
    return this.doctorInClinicTimeSlotsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingDELETEParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   */
  doctorInClinicTimeSlotsUsingDELETEResponse(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/${params.id}/timeSlots/${params.timeslotId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingDELETEParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   */
  doctorInClinicTimeSlotsUsingDELETE(params: DoctorInClinicEntityService.DoctorInClinicTimeSlotsUsingDELETEParams): Observable<void> {
    return this.doctorInClinicTimeSlotsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorInClinicEntityService {

  /**
   * Parameters for findAllDoctorInClinicUsingGET
   */
  export interface FindAllDoctorInClinicUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByClinicIdInDoctorInClinicUsingGET
   */
  export interface FindByClinicIdInDoctorInClinicUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * clinicId
     */
    clinicId?: number;
  }

  /**
   * Parameters for findDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGET
   */
  export interface FindDistinctByDoctorNameContainingOrClinicNameContainingDoctorInClinicUsingGETParams {

    /**
     * name1
     */
    name1?: string;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveDoctorInClinicUsingPUT
   */
  export interface SaveDoctorInClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DoctorInClinic;
  }

  /**
   * Parameters for saveDoctorInClinicUsingPATCH
   */
  export interface SaveDoctorInClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DoctorInClinic;
  }

  /**
   * Parameters for doctorInClinicClinicUsingPOST
   */
  export interface DoctorInClinicClinicUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorInClinicClinicUsingPUT
   */
  export interface DoctorInClinicClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorInClinicClinicUsingPATCH
   */
  export interface DoctorInClinicClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorInClinicConsultationsUsingPOST
   */
  export interface DoctorInClinicConsultationsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorInClinicConsultationsUsingPUT
   */
  export interface DoctorInClinicConsultationsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorInClinicConsultationsUsingPATCH
   */
  export interface DoctorInClinicConsultationsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorInClinicConsultationsUsingGET
   */
  export interface DoctorInClinicConsultationsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * consultationId
     */
    consultationId: string;
  }

  /**
   * Parameters for doctorInClinicConsultationsUsingDELETE
   */
  export interface DoctorInClinicConsultationsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * consultationId
     */
    consultationId: string;
  }

  /**
   * Parameters for doctorInClinicDoctorUsingPOST
   */
  export interface DoctorInClinicDoctorUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorInClinicDoctorUsingPUT
   */
  export interface DoctorInClinicDoctorUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorInClinicDoctorUsingPATCH
   */
  export interface DoctorInClinicDoctorUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for doctorInClinicTimeSlotsUsingPOST
   */
  export interface DoctorInClinicTimeSlotsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorInClinicTimeSlotsUsingPUT
   */
  export interface DoctorInClinicTimeSlotsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorInClinicTimeSlotsUsingPATCH
   */
  export interface DoctorInClinicTimeSlotsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for doctorInClinicTimeSlotsUsingGET
   */
  export interface DoctorInClinicTimeSlotsUsingGETParams {

    /**
     * timeslotId
     */
    timeslotId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for doctorInClinicTimeSlotsUsingDELETE
   */
  export interface DoctorInClinicTimeSlotsUsingDELETEParams {

    /**
     * timeslotId
     */
    timeslotId: string;

    /**
     * id
     */
    id: number;
  }
}

export { DoctorInClinicEntityService }
