/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesConsultation } from '../models/resources-consultation-';
import { ResourcesPatientInQueue } from '../models/resources-patient-in-queue-';
import { ResourcesSummeryData } from '../models/resources-summery-data-';

/**
 * Dashboard Summary Controller
 */
@Injectable()
class DashboardSummaryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingGETParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingGETResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingGETParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingGETParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingGET(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingGETParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingHEADParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingHEADResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingHEADParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingHEADParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingHEAD(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingHEADParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPOSTParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingPOSTResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPOSTParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPOSTParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingPOST(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPOSTParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPUTParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingPUTResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPUTParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPUTParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingPUT(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPUTParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingDELETEParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingDELETEResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingDELETEParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingDELETEParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingDELETE(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingDELETEParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingOPTIONSParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingOPTIONSResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingOPTIONSParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingOPTIONSParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingOPTIONS(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingOPTIONSParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPATCHParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingPATCHResponse(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPATCHParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.priorityQueue != null) __params = __params.set('priorityQueue', params.priorityQueue.toString());
    (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/movePatientToAnotherQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPATCHParams` containing the following parameters:
   *
   * - `priorityQueue`: priorityQueue
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  movePatientToAnotherQueueUsingPATCH(params: DashboardSummaryControllerService.MovePatientToAnotherQueueUsingPATCHParams): Observable<ResourcesConsultation> {
    return this.movePatientToAnotherQueueUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingGETResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingGET(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingGETResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingHEADResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingHEAD(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingHEADResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingPOSTResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingPOST(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingPOSTResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingPUTResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingPUT(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingPUTResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingDELETEResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingDELETE(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingDELETEResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingOPTIONSResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingOPTIONS(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingOPTIONSResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingPATCHResponse(doctorInClinics: Array<number>): Observable<HttpResponse<ResourcesPatientInQueue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/patientInQueue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientInQueue = null;
        _body = _resp.body as ResourcesPatientInQueue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientInQueue>;
      })
    );
  }

  /**
   * @param doctorInClinics doctorInClinics
   * @return OK
   */
  getPatientQueuesUsingPATCH(doctorInClinics: Array<number>): Observable<ResourcesPatientInQueue> {
    return this.getPatientQueuesUsingPATCHResponse(doctorInClinics).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorInClinic doctorInClinic
   * @return OK
   */
  getSummeryDetailUsingGETResponse(doctorInClinic: Array<number>): Observable<HttpResponse<ResourcesSummeryData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (doctorInClinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/queueSummery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSummeryData = null;
        _body = _resp.body as ResourcesSummeryData;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSummeryData>;
      })
    );
  }

  /**
   * @param doctorInClinic doctorInClinic
   * @return OK
   */
  getSummeryDetailUsingGET(doctorInClinic: Array<number>): Observable<ResourcesSummeryData> {
    return this.getSummeryDetailUsingGETResponse(doctorInClinic).pipe(
      map(_r => _r.body)
    );
  }
}

module DashboardSummaryControllerService {

  /**
   * Parameters for movePatientToAnotherQueueUsingGET
   */
  export interface MovePatientToAnotherQueueUsingGETParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }

  /**
   * Parameters for movePatientToAnotherQueueUsingHEAD
   */
  export interface MovePatientToAnotherQueueUsingHEADParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }

  /**
   * Parameters for movePatientToAnotherQueueUsingPOST
   */
  export interface MovePatientToAnotherQueueUsingPOSTParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }

  /**
   * Parameters for movePatientToAnotherQueueUsingPUT
   */
  export interface MovePatientToAnotherQueueUsingPUTParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }

  /**
   * Parameters for movePatientToAnotherQueueUsingDELETE
   */
  export interface MovePatientToAnotherQueueUsingDELETEParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }

  /**
   * Parameters for movePatientToAnotherQueueUsingOPTIONS
   */
  export interface MovePatientToAnotherQueueUsingOPTIONSParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }

  /**
   * Parameters for movePatientToAnotherQueueUsingPATCH
   */
  export interface MovePatientToAnotherQueueUsingPATCHParams {

    /**
     * priorityQueue
     */
    priorityQueue: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
  }
}

export { DashboardSummaryControllerService }
