/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceSupport } from '../models/resource-support';

/**
 * Profile Controller
 */
@Injectable()
class ProfileControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  listAllFormsOfMetadataUsingGETResponse(): Observable<HttpResponse<ResourceSupport>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/profile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSupport = null;
        _body = _resp.body as ResourceSupport;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSupport>;
      })
    );
  }

  /**
   * @return OK
   */
  listAllFormsOfMetadataUsingGET(): Observable<ResourceSupport> {
    return this.listAllFormsOfMetadataUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  profileOptionsUsingOPTIONSResponse(): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/order/profile`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @return OK
   */
  profileOptionsUsingOPTIONS(): Observable<{}> {
    return this.profileOptionsUsingOPTIONSResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module ProfileControllerService {
}

export { ProfileControllerService }
