import { Component, OnInit} from '@angular/core';
import { FullScreenLoaderService } from './full-screen-loader/full-screen-loader-service';
import { SplashScreenService } from './services/splash-screen.service';
import { VersionCheckService } from './services/version-check.service';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  title = 'app';
  showLoader : boolean;
  public fontFamily: Object = {
    default: 'Poppins-Regular', // to define default font-family
  };

  constructor(
    private fullScreenLoaderService: FullScreenLoaderService,
    private splashScreenService: SplashScreenService,
    private versionCheckService: VersionCheckService
  ) {
    this.fullScreenLoaderService.FullScreenLoaderState.subscribe(state => {
      this.showLoader = state;
    });
  }

  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }

}
