import { Component, OnInit } from '@angular/core';
import { DeclineReasonEntityService } from 'src/app/api/emr/services';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-new-reason-dialog',
  templateUrl: './new-reason-dialog.component.html',
  styleUrls: ['./new-reason-dialog.component.scss']
})
export class NewReasonDialogComponent implements OnInit {

  savingReason: boolean = false;
  reason = '';

  constructor(
    private DeclineReasonEntityService: DeclineReasonEntityService,
    private MatDialogRef: MatDialogRef<NewReasonDialogComponent>
  ) { }

  saveReason() {
    this.savingReason = true;
    this.DeclineReasonEntityService.saveDeclineReasonUsingPOST({
      reason: this.reason
    }).subscribe(
      reason => this.MatDialogRef.close(true),
      err => {
        alert('Error while saving reason');
        this.savingReason = false;
      }
    )
  }

  ngOnInit() {
  }

}
