/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPrescriptionByDate } from '../models/resources-prescription-by-date-';
import { ResourcePrescriptionByDate } from '../models/resource-prescription-by-date-';
import { PrescriptionByDate } from '../models/prescription-by-date';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourcePatient } from '../models/resource-patient-';
import { ResourcePrescription } from '../models/resource-prescription-';
import { ResourcePrescriptionDescription } from '../models/resource-prescription-description-';

/**
 * $ Proxy 166
 */
@Injectable()
class PrescriptionByDateEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindAllPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPrescriptionByDateUsingGETResponse(params: PrescriptionByDateEntityService.FindAllPrescriptionByDateUsingGETParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindAllPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPrescriptionByDateUsingGET(params: PrescriptionByDateEntityService.FindAllPrescriptionByDateUsingGETParams): Observable<ResourcesPrescriptionByDate> {
    return this.findAllPrescriptionByDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePrescriptionByDateUsingPOSTResponse(body: PrescriptionByDate): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePrescriptionByDateUsingPOST(body: PrescriptionByDate): Observable<ResourcePrescriptionByDate> {
    return this.savePrescriptionByDateUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindAllByPrescriptionIdAndDatePrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `date`: date
   *
   * @return OK
   */
  findAllByPrescriptionIdAndDatePrescriptionByDateUsingGETResponse(params: PrescriptionByDateEntityService.FindAllByPrescriptionIdAndDatePrescriptionByDateUsingGETParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findAllByPrescriptionIdAndDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindAllByPrescriptionIdAndDatePrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `date`: date
   *
   * @return OK
   */
  findAllByPrescriptionIdAndDatePrescriptionByDateUsingGET(params: PrescriptionByDateEntityService.FindAllByPrescriptionIdAndDatePrescriptionByDateUsingGETParams): Observable<ResourcesPrescriptionByDate> {
    return this.findAllByPrescriptionIdAndDatePrescriptionByDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindAllWithDateAfterPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `date`: date
   *
   * @return OK
   */
  findAllWithDateAfterPrescriptionByDateUsingGETResponse(params: PrescriptionByDateEntityService.FindAllWithDateAfterPrescriptionByDateUsingGETParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findAllWithDateAfter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindAllWithDateAfterPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `date`: date
   *
   * @return OK
   */
  findAllWithDateAfterPrescriptionByDateUsingGET(params: PrescriptionByDateEntityService.FindAllWithDateAfterPrescriptionByDateUsingGETParams): Observable<ResourcesPrescriptionByDate> {
    return this.findAllWithDateAfterPrescriptionByDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationIdInPrescriptionByDateUsingGETResponse(id?: Array<number>): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findByConsultationIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationIdInPrescriptionByDateUsingGET(id?: Array<number>): Observable<ResourcesPrescriptionByDate> {
    return this.findByConsultationIdInPrescriptionByDateUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param date date
   * @return OK
   */
  findByDatePrescriptionByDateUsingGETResponse(date?: string): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findByDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param date date
   * @return OK
   */
  findByDatePrescriptionByDateUsingGET(date?: string): Observable<ResourcesPrescriptionByDate> {
    return this.findByDatePrescriptionByDateUsingGETResponse(date).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETResponse(params: PrescriptionByDateEntityService.FindByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.consultationId || []).forEach((val, index) => {if (val != null) __params = __params.append('consultationId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGET(params: PrescriptionByDateEntityService.FindByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams): Observable<ResourcesPrescriptionByDate> {
    return this.findByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETResponse(params: PrescriptionByDateEntityService.FindByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findByPatientIdInAndDateBetweenOrderByDateDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.FindByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGET(params: PrescriptionByDateEntityService.FindByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams): Observable<ResourcesPrescriptionByDate> {
    return this.findByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByPrescriptionDescriptionIdPrescriptionByDateUsingGETResponse(id?: number): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/search/findByPrescriptionDescriptionId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByPrescriptionDescriptionIdPrescriptionByDateUsingGET(id?: number): Observable<ResourcesPrescriptionByDate> {
    return this.findByPrescriptionDescriptionIdPrescriptionByDateUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPrescriptionByDateUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPrescriptionByDateUsingGET(id: number): Observable<ResourcePrescriptionByDate> {
    return this.findByIdPrescriptionByDateUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.SavePrescriptionByDateUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionByDateUsingPUTResponse(params: PrescriptionByDateEntityService.SavePrescriptionByDateUsingPUTParams): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.SavePrescriptionByDateUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionByDateUsingPUT(params: PrescriptionByDateEntityService.SavePrescriptionByDateUsingPUTParams): Observable<ResourcePrescriptionByDate> {
    return this.savePrescriptionByDateUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePrescriptionByDateUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePrescriptionByDateUsingDELETE(id: number): Observable<void> {
    return this.deletePrescriptionByDateUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.SavePrescriptionByDateUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionByDateUsingPATCHResponse(params: PrescriptionByDateEntityService.SavePrescriptionByDateUsingPATCHParams): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.SavePrescriptionByDateUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionByDateUsingPATCH(params: PrescriptionByDateEntityService.SavePrescriptionByDateUsingPATCHParams): Observable<ResourcePrescriptionByDate> {
    return this.savePrescriptionByDateUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDateConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDateConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.prescriptionByDateConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDateConsultationUsingPOSTResponse(params: PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDateConsultationUsingPOST(params: PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.prescriptionByDateConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDateConsultationUsingPUTResponse(params: PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDateConsultationUsingPUT(params: PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.prescriptionByDateConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionByDateConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionByDateConsultationUsingDELETE(id: number): Observable<void> {
    return this.prescriptionByDateConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDateConsultationUsingPATCHResponse(params: PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDateConsultationUsingPATCH(params: PrescriptionByDateEntityService.PrescriptionByDateConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.prescriptionByDateConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDatePatientUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDatePatientUsingGET(id: number): Observable<ResourcePatient> {
    return this.prescriptionByDatePatientUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePatientUsingPOSTResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPOSTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePatientUsingPOST(params: PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPOSTParams): Observable<ResourcePatient> {
    return this.prescriptionByDatePatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePatientUsingPUTResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPUTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePatientUsingPUT(params: PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPUTParams): Observable<ResourcePatient> {
    return this.prescriptionByDatePatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionByDatePatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionByDatePatientUsingDELETE(id: number): Observable<void> {
    return this.prescriptionByDatePatientUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePatientUsingPATCHResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPATCHParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePatientUsingPATCH(params: PrescriptionByDateEntityService.PrescriptionByDatePatientUsingPATCHParams): Observable<ResourcePatient> {
    return this.prescriptionByDatePatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDatePrescriptionUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDatePrescriptionUsingGET(id: number): Observable<ResourcePrescription> {
    return this.prescriptionByDatePrescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionUsingPOSTResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPOSTParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionUsingPOST(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPOSTParams): Observable<ResourcePrescription> {
    return this.prescriptionByDatePrescriptionUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionUsingPUTResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPUTParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionUsingPUT(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPUTParams): Observable<ResourcePrescription> {
    return this.prescriptionByDatePrescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionByDatePrescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionByDatePrescriptionUsingDELETE(id: number): Observable<void> {
    return this.prescriptionByDatePrescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionUsingPATCHResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPATCHParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionUsingPATCH(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionUsingPATCHParams): Observable<ResourcePrescription> {
    return this.prescriptionByDatePrescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingGET(id: number): Observable<ResourcePrescriptionDescription> {
    return this.prescriptionByDatePrescriptionDescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingPOSTResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPOSTParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingPOST(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPOSTParams): Observable<ResourcePrescriptionDescription> {
    return this.prescriptionByDatePrescriptionDescriptionUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingPUTResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPUTParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingPUT(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPUTParams): Observable<ResourcePrescriptionDescription> {
    return this.prescriptionByDatePrescriptionDescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionByDatePrescriptionDescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionByDatePrescriptionDescriptionUsingDELETE(id: number): Observable<void> {
    return this.prescriptionByDatePrescriptionDescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingPATCHResponse(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPATCHParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionByDates/${params.id}/prescriptionDescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionByDatePrescriptionDescriptionUsingPATCH(params: PrescriptionByDateEntityService.PrescriptionByDatePrescriptionDescriptionUsingPATCHParams): Observable<ResourcePrescriptionDescription> {
    return this.prescriptionByDatePrescriptionDescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PrescriptionByDateEntityService {

  /**
   * Parameters for findAllPrescriptionByDateUsingGET
   */
  export interface FindAllPrescriptionByDateUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findAllByPrescriptionIdAndDatePrescriptionByDateUsingGET
   */
  export interface FindAllByPrescriptionIdAndDatePrescriptionByDateUsingGETParams {

    /**
     * id
     */
    id?: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for findAllWithDateAfterPrescriptionByDateUsingGET
   */
  export interface FindAllWithDateAfterPrescriptionByDateUsingGETParams {

    /**
     * patientId
     */
    patientId?: number;

    /**
     * date
     */
    date?: string;
  }

  /**
   * Parameters for findByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGET
   */
  export interface FindByPatientIdAndConsultationIdNotInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientId
     */
    patientId?: number;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * consultationId
     */
    consultationId?: Array<number>;
  }

  /**
   * Parameters for findByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGET
   */
  export interface FindByPatientIdInAndDateBetweenOrderByDateDescPrescriptionByDateUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientId
     */
    patientId?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for savePrescriptionByDateUsingPUT
   */
  export interface SavePrescriptionByDateUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PrescriptionByDate;
  }

  /**
   * Parameters for savePrescriptionByDateUsingPATCH
   */
  export interface SavePrescriptionByDateUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PrescriptionByDate;
  }

  /**
   * Parameters for prescriptionByDateConsultationUsingPOST
   */
  export interface PrescriptionByDateConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDateConsultationUsingPUT
   */
  export interface PrescriptionByDateConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDateConsultationUsingPATCH
   */
  export interface PrescriptionByDateConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePatientUsingPOST
   */
  export interface PrescriptionByDatePatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePatientUsingPUT
   */
  export interface PrescriptionByDatePatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePatientUsingPATCH
   */
  export interface PrescriptionByDatePatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePrescriptionUsingPOST
   */
  export interface PrescriptionByDatePrescriptionUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePrescriptionUsingPUT
   */
  export interface PrescriptionByDatePrescriptionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePrescriptionUsingPATCH
   */
  export interface PrescriptionByDatePrescriptionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePrescriptionDescriptionUsingPOST
   */
  export interface PrescriptionByDatePrescriptionDescriptionUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePrescriptionDescriptionUsingPUT
   */
  export interface PrescriptionByDatePrescriptionDescriptionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionByDatePrescriptionDescriptionUsingPATCH
   */
  export interface PrescriptionByDatePrescriptionDescriptionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { PrescriptionByDateEntityService }
