/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesExistingConditionType } from '../models/resources-existing-condition-type-';
import { ResourceExistingConditionType } from '../models/resource-existing-condition-type-';
import { ExistingConditionType } from '../models/existing-condition-type';
import { ResourcesExistingCondition } from '../models/resources-existing-condition-';
import { ResourceExistingCondition } from '../models/resource-existing-condition-';

/**
 * $ Proxy 224
 */
@Injectable()
class ExistingConditionTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.FindAllExistingConditionTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllExistingConditionTypeUsingGETResponse(params: ExistingConditionTypeEntityService.FindAllExistingConditionTypeUsingGETParams): Observable<HttpResponse<ResourcesExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingConditionType = null;
        _body = _resp.body as ResourcesExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.FindAllExistingConditionTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllExistingConditionTypeUsingGET(params: ExistingConditionTypeEntityService.FindAllExistingConditionTypeUsingGETParams): Observable<ResourcesExistingConditionType> {
    return this.findAllExistingConditionTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveExistingConditionTypeUsingPOSTResponse(body: ExistingConditionType): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/existingConditionType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveExistingConditionTypeUsingPOST(body: ExistingConditionType): Observable<ResourceExistingConditionType> {
    return this.saveExistingConditionTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.FindByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETResponse(params: ExistingConditionTypeEntityService.FindByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETParams): Observable<HttpResponse<ResourcesExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingConditionType/search/findByNameContainingAndIsActiveTrue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingConditionType = null;
        _body = _resp.body as ResourcesExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.FindByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndIsActiveTrueExistingConditionTypeUsingGET(params: ExistingConditionTypeEntityService.FindByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETParams): Observable<ResourcesExistingConditionType> {
    return this.findByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdExistingConditionTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingConditionType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdExistingConditionTypeUsingGET(id: number): Observable<ResourceExistingConditionType> {
    return this.findByIdExistingConditionTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionTypeUsingPUTResponse(params: ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPUTParams): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionTypeUsingPUT(params: ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPUTParams): Observable<ResourceExistingConditionType> {
    return this.saveExistingConditionTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteExistingConditionTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/existingConditionType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteExistingConditionTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteExistingConditionTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionTypeUsingPATCHResponse(params: ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPATCHParams): Observable<HttpResponse<ResourceExistingConditionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingConditionType = null;
        _body = _resp.body as ResourceExistingConditionType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingConditionType>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveExistingConditionTypeUsingPATCH(params: ExistingConditionTypeEntityService.SaveExistingConditionTypeUsingPATCHParams): Observable<ResourceExistingConditionType> {
    return this.saveExistingConditionTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingConditionType/${id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingGET_1(id: number): Observable<ResourcesExistingCondition> {
    return this.existingConditionTypeExistingConditionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingPOSTResponse(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPOSTParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingPOST(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPOSTParams): Observable<ResourcesExistingCondition> {
    return this.existingConditionTypeExistingConditionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingPUTResponse(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPUTParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingPUT(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPUTParams): Observable<ResourcesExistingCondition> {
    return this.existingConditionTypeExistingConditionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  existingConditionTypeExistingConditionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/existingConditionType/${id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  existingConditionTypeExistingConditionsUsingDELETE_1(id: number): Observable<void> {
    return this.existingConditionTypeExistingConditionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingPATCHResponse(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPATCHParams): Observable<HttpResponse<ResourcesExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}/existingConditions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesExistingCondition = null;
        _body = _resp.body as ResourcesExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingPATCH(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingPATCHParams): Observable<ResourcesExistingCondition> {
    return this.existingConditionTypeExistingConditionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingGETResponse(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingGETParams): Observable<HttpResponse<ResourceExistingCondition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}/existingConditions/${params.existingconditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceExistingCondition = null;
        _body = _resp.body as ResourceExistingCondition;
        return _resp.clone({body: _body}) as HttpResponse<ResourceExistingCondition>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   *
   * @return OK
   */
  existingConditionTypeExistingConditionsUsingGET(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingGETParams): Observable<ResourceExistingCondition> {
    return this.existingConditionTypeExistingConditionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   */
  existingConditionTypeExistingConditionsUsingDELETEResponse(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/existingConditionType/${params.id}/existingConditions/${params.existingconditionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `existingconditionId`: existingconditionId
   */
  existingConditionTypeExistingConditionsUsingDELETE(params: ExistingConditionTypeEntityService.ExistingConditionTypeExistingConditionsUsingDELETEParams): Observable<void> {
    return this.existingConditionTypeExistingConditionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ExistingConditionTypeEntityService {

  /**
   * Parameters for findAllExistingConditionTypeUsingGET
   */
  export interface FindAllExistingConditionTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByNameContainingAndIsActiveTrueExistingConditionTypeUsingGET
   */
  export interface FindByNameContainingAndIsActiveTrueExistingConditionTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveExistingConditionTypeUsingPUT
   */
  export interface SaveExistingConditionTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ExistingConditionType;
  }

  /**
   * Parameters for saveExistingConditionTypeUsingPATCH
   */
  export interface SaveExistingConditionTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: ExistingConditionType;
  }

  /**
   * Parameters for existingConditionTypeExistingConditionsUsingPOST
   */
  export interface ExistingConditionTypeExistingConditionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for existingConditionTypeExistingConditionsUsingPUT
   */
  export interface ExistingConditionTypeExistingConditionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for existingConditionTypeExistingConditionsUsingPATCH
   */
  export interface ExistingConditionTypeExistingConditionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for existingConditionTypeExistingConditionsUsingGET
   */
  export interface ExistingConditionTypeExistingConditionsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * existingconditionId
     */
    existingconditionId: string;
  }

  /**
   * Parameters for existingConditionTypeExistingConditionsUsingDELETE
   */
  export interface ExistingConditionTypeExistingConditionsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * existingconditionId
     */
    existingconditionId: string;
  }
}

export { ExistingConditionTypeEntityService }
