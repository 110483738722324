/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesSpeciality } from '../models/resources-speciality-';
import { ResourceSpeciality } from '../models/resource-speciality-';
import { Speciality } from '../models/speciality';
import { ResourcesDoctor } from '../models/resources-doctor-';
import { ResourceDoctor } from '../models/resource-doctor-';
import { ResourcesHealthProblemTagsForSpeciality } from '../models/resources-health-problem-tags-for-speciality-';
import { ResourceHealthProblemTagsForSpeciality } from '../models/resource-health-problem-tags-for-speciality-';
import { ResourcesSpecialityDiagnosisType } from '../models/resources-speciality-diagnosis-type-';
import { ResourceSpecialityDiagnosisType } from '../models/resource-speciality-diagnosis-type-';
import { ResourcesSpecialityVitalType } from '../models/resources-speciality-vital-type-';
import { ResourceSpecialityVitalType } from '../models/resource-speciality-vital-type-';

/**
 * Simple Jpa Repository
 */
@Injectable()
class SpecialityEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SpecialityEntityService.FindAllSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSpecialityUsingGETResponse(params: SpecialityEntityService.FindAllSpecialityUsingGETParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.FindAllSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSpecialityUsingGET(params: SpecialityEntityService.FindAllSpecialityUsingGETParams): Observable<ResourcesSpeciality> {
    return this.findAllSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSpecialityUsingPOSTResponse(body: Speciality): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSpecialityUsingPOST(body: Speciality): Observable<ResourceSpeciality> {
    return this.saveSpecialityUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.FindByDoctorsNotNullSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  findByDoctorsNotNullSpecialityUsingGETResponse(params: SpecialityEntityService.FindByDoctorsNotNullSpecialityUsingGETParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/search/findByDoctorsNotNull`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.FindByDoctorsNotNullSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  findByDoctorsNotNullSpecialityUsingGET(params: SpecialityEntityService.FindByDoctorsNotNullSpecialityUsingGETParams): Observable<ResourcesSpeciality> {
    return this.findByDoctorsNotNullSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.FindByNameContainingSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingSpecialityUsingGETResponse(params: SpecialityEntityService.FindByNameContainingSpecialityUsingGETParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/search/findByNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.FindByNameContainingSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingSpecialityUsingGET(params: SpecialityEntityService.FindByNameContainingSpecialityUsingGETParams): Observable<ResourcesSpeciality> {
    return this.findByNameContainingSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param specialityName specialityName
   * @return OK
   */
  searchWithNameSpecialityUsingGETResponse(specialityName?: string): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (specialityName != null) __params = __params.set('specialityName', specialityName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/search/searchWithName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param specialityName specialityName
   * @return OK
   */
  searchWithNameSpecialityUsingGET(specialityName?: string): Observable<ResourcesSpeciality> {
    return this.searchWithNameSpecialityUsingGETResponse(specialityName).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSpecialityUsingGETResponse(id: number): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSpecialityUsingGET(id: number): Observable<ResourceSpeciality> {
    return this.findByIdSpecialityUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SaveSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityUsingPUTResponse(params: SpecialityEntityService.SaveSpecialityUsingPUTParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SaveSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityUsingPUT(params: SpecialityEntityService.SaveSpecialityUsingPUTParams): Observable<ResourceSpeciality> {
    return this.saveSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteSpecialityUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteSpecialityUsingDELETE(id: number): Observable<void> {
    return this.deleteSpecialityUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SaveSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityUsingPATCHResponse(params: SpecialityEntityService.SaveSpecialityUsingPATCHParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SaveSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityUsingPATCH(params: SpecialityEntityService.SaveSpecialityUsingPATCHParams): Observable<ResourceSpeciality> {
    return this.saveSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityDoctorsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityDoctorsUsingGET_1(id: number): Observable<ResourcesDoctor> {
    return this.specialityDoctorsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDoctorsUsingPOSTResponse(params: SpecialityEntityService.SpecialityDoctorsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDoctorsUsingPOST(params: SpecialityEntityService.SpecialityDoctorsUsingPOSTParams): Observable<ResourcesDoctor> {
    return this.specialityDoctorsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDoctorsUsingPUTResponse(params: SpecialityEntityService.SpecialityDoctorsUsingPUTParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDoctorsUsingPUT(params: SpecialityEntityService.SpecialityDoctorsUsingPUTParams): Observable<ResourcesDoctor> {
    return this.specialityDoctorsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialityDoctorsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialityDoctorsUsingDELETE_1(id: number): Observable<void> {
    return this.specialityDoctorsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDoctorsUsingPATCHResponse(params: SpecialityEntityService.SpecialityDoctorsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDoctorsUsingPATCH(params: SpecialityEntityService.SpecialityDoctorsUsingPATCHParams): Observable<ResourcesDoctor> {
    return this.specialityDoctorsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   *
   * @return OK
   */
  specialityDoctorsUsingGETResponse(params: SpecialityEntityService.SpecialityDoctorsUsingGETParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/doctors/${params.doctorId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   *
   * @return OK
   */
  specialityDoctorsUsingGET(params: SpecialityEntityService.SpecialityDoctorsUsingGETParams): Observable<ResourceDoctor> {
    return this.specialityDoctorsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   */
  specialityDoctorsUsingDELETEResponse(params: SpecialityEntityService.SpecialityDoctorsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/doctors/${params.doctorId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityDoctorsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   */
  specialityDoctorsUsingDELETE(params: SpecialityEntityService.SpecialityDoctorsUsingDELETEParams): Observable<void> {
    return this.specialityDoctorsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${id}/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourcesHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingGET_1(id: number): Observable<ResourcesHealthProblemTagsForSpeciality> {
    return this.specialityHealthProblemTagsForSpecialityUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingPOSTResponse(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPOSTParams): Observable<HttpResponse<ResourcesHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourcesHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingPOST(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPOSTParams): Observable<ResourcesHealthProblemTagsForSpeciality> {
    return this.specialityHealthProblemTagsForSpecialityUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingPUTResponse(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPUTParams): Observable<HttpResponse<ResourcesHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourcesHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingPUT(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPUTParams): Observable<ResourcesHealthProblemTagsForSpeciality> {
    return this.specialityHealthProblemTagsForSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialityHealthProblemTagsForSpecialityUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${id}/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialityHealthProblemTagsForSpecialityUsingDELETE_1(id: number): Observable<void> {
    return this.specialityHealthProblemTagsForSpecialityUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingPATCHResponse(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPATCHParams): Observable<HttpResponse<ResourcesHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourcesHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingPATCH(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingPATCHParams): Observable<ResourcesHealthProblemTagsForSpeciality> {
    return this.specialityHealthProblemTagsForSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsforspecialityId`: healthproblemtagsforspecialityId
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingGETResponse(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingGETParams): Observable<HttpResponse<ResourceHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/healthProblemTagsForSpeciality/${params.healthproblemtagsforspecialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourceHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsforspecialityId`: healthproblemtagsforspecialityId
   *
   * @return OK
   */
  specialityHealthProblemTagsForSpecialityUsingGET(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingGETParams): Observable<ResourceHealthProblemTagsForSpeciality> {
    return this.specialityHealthProblemTagsForSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsforspecialityId`: healthproblemtagsforspecialityId
   */
  specialityHealthProblemTagsForSpecialityUsingDELETEResponse(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/healthProblemTagsForSpeciality/${params.healthproblemtagsforspecialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `healthproblemtagsforspecialityId`: healthproblemtagsforspecialityId
   */
  specialityHealthProblemTagsForSpecialityUsingDELETE(params: SpecialityEntityService.SpecialityHealthProblemTagsForSpecialityUsingDELETEParams): Observable<void> {
    return this.specialityHealthProblemTagsForSpecialityUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${id}/specialityDiagnosisTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityDiagnosisType = null;
        _body = _resp.body as ResourcesSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingGET_1(id: number): Observable<ResourcesSpecialityDiagnosisType> {
    return this.specialitySpecialityDiagnosisTypesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingPOSTResponse(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPOSTParams): Observable<HttpResponse<ResourcesSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityDiagnosisTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityDiagnosisType = null;
        _body = _resp.body as ResourcesSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingPOST(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPOSTParams): Observable<ResourcesSpecialityDiagnosisType> {
    return this.specialitySpecialityDiagnosisTypesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingPUTResponse(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPUTParams): Observable<HttpResponse<ResourcesSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityDiagnosisTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityDiagnosisType = null;
        _body = _resp.body as ResourcesSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingPUT(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPUTParams): Observable<ResourcesSpecialityDiagnosisType> {
    return this.specialitySpecialityDiagnosisTypesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialitySpecialityDiagnosisTypesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${id}/specialityDiagnosisTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialitySpecialityDiagnosisTypesUsingDELETE_1(id: number): Observable<void> {
    return this.specialitySpecialityDiagnosisTypesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingPATCHResponse(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPATCHParams): Observable<HttpResponse<ResourcesSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityDiagnosisTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityDiagnosisType = null;
        _body = _resp.body as ResourcesSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingPATCH(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingPATCHParams): Observable<ResourcesSpecialityDiagnosisType> {
    return this.specialitySpecialityDiagnosisTypesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingGETParams` containing the following parameters:
   *
   * - `specialitydiagnosistypeId`: specialitydiagnosistypeId
   *
   * - `id`: id
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingGETResponse(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingGETParams): Observable<HttpResponse<ResourceSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityDiagnosisTypes/${params.specialitydiagnosistypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityDiagnosisType = null;
        _body = _resp.body as ResourceSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingGETParams` containing the following parameters:
   *
   * - `specialitydiagnosistypeId`: specialitydiagnosistypeId
   *
   * - `id`: id
   *
   * @return OK
   */
  specialitySpecialityDiagnosisTypesUsingGET(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingGETParams): Observable<ResourceSpecialityDiagnosisType> {
    return this.specialitySpecialityDiagnosisTypesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingDELETEParams` containing the following parameters:
   *
   * - `specialitydiagnosistypeId`: specialitydiagnosistypeId
   *
   * - `id`: id
   */
  specialitySpecialityDiagnosisTypesUsingDELETEResponse(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityDiagnosisTypes/${params.specialitydiagnosistypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingDELETEParams` containing the following parameters:
   *
   * - `specialitydiagnosistypeId`: specialitydiagnosistypeId
   *
   * - `id`: id
   */
  specialitySpecialityDiagnosisTypesUsingDELETE(params: SpecialityEntityService.SpecialitySpecialityDiagnosisTypesUsingDELETEParams): Observable<void> {
    return this.specialitySpecialityDiagnosisTypesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialitySpecialityVitalTypesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${id}/specialityVitalTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityVitalType = null;
        _body = _resp.body as ResourcesSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityVitalType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialitySpecialityVitalTypesUsingGET_1(id: number): Observable<ResourcesSpecialityVitalType> {
    return this.specialitySpecialityVitalTypesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingPOSTResponse(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPOSTParams): Observable<HttpResponse<ResourcesSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityVitalTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityVitalType = null;
        _body = _resp.body as ResourcesSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingPOST(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPOSTParams): Observable<ResourcesSpecialityVitalType> {
    return this.specialitySpecialityVitalTypesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingPUTResponse(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPUTParams): Observable<HttpResponse<ResourcesSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityVitalTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityVitalType = null;
        _body = _resp.body as ResourcesSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingPUT(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPUTParams): Observable<ResourcesSpecialityVitalType> {
    return this.specialitySpecialityVitalTypesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialitySpecialityVitalTypesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${id}/specialityVitalTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialitySpecialityVitalTypesUsingDELETE_1(id: number): Observable<void> {
    return this.specialitySpecialityVitalTypesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingPATCHResponse(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPATCHParams): Observable<HttpResponse<ResourcesSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityVitalTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityVitalType = null;
        _body = _resp.body as ResourcesSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingPATCH(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingPATCHParams): Observable<ResourcesSpecialityVitalType> {
    return this.specialitySpecialityVitalTypesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingGETParams` containing the following parameters:
   *
   * - `specialityvitaltypeId`: specialityvitaltypeId
   *
   * - `id`: id
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingGETResponse(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingGETParams): Observable<HttpResponse<ResourceSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityVitalTypes/${params.specialityvitaltypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityVitalType = null;
        _body = _resp.body as ResourceSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingGETParams` containing the following parameters:
   *
   * - `specialityvitaltypeId`: specialityvitaltypeId
   *
   * - `id`: id
   *
   * @return OK
   */
  specialitySpecialityVitalTypesUsingGET(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingGETParams): Observable<ResourceSpecialityVitalType> {
    return this.specialitySpecialityVitalTypesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingDELETEParams` containing the following parameters:
   *
   * - `specialityvitaltypeId`: specialityvitaltypeId
   *
   * - `id`: id
   */
  specialitySpecialityVitalTypesUsingDELETEResponse(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/speciality/${params.id}/specialityVitalTypes/${params.specialityvitaltypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `SpecialityEntityService.SpecialitySpecialityVitalTypesUsingDELETEParams` containing the following parameters:
   *
   * - `specialityvitaltypeId`: specialityvitaltypeId
   *
   * - `id`: id
   */
  specialitySpecialityVitalTypesUsingDELETE(params: SpecialityEntityService.SpecialitySpecialityVitalTypesUsingDELETEParams): Observable<void> {
    return this.specialitySpecialityVitalTypesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SpecialityEntityService {

  /**
   * Parameters for findAllSpecialityUsingGET
   */
  export interface FindAllSpecialityUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByDoctorsNotNullSpecialityUsingGET
   */
  export interface FindByDoctorsNotNullSpecialityUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;
  }

  /**
   * Parameters for findByNameContainingSpecialityUsingGET
   */
  export interface FindByNameContainingSpecialityUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveSpecialityUsingPUT
   */
  export interface SaveSpecialityUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Speciality;
  }

  /**
   * Parameters for saveSpecialityUsingPATCH
   */
  export interface SaveSpecialityUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Speciality;
  }

  /**
   * Parameters for specialityDoctorsUsingPOST
   */
  export interface SpecialityDoctorsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialityDoctorsUsingPUT
   */
  export interface SpecialityDoctorsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialityDoctorsUsingPATCH
   */
  export interface SpecialityDoctorsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialityDoctorsUsingGET
   */
  export interface SpecialityDoctorsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorId
     */
    doctorId: string;
  }

  /**
   * Parameters for specialityDoctorsUsingDELETE
   */
  export interface SpecialityDoctorsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorId
     */
    doctorId: string;
  }

  /**
   * Parameters for specialityHealthProblemTagsForSpecialityUsingPOST
   */
  export interface SpecialityHealthProblemTagsForSpecialityUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialityHealthProblemTagsForSpecialityUsingPUT
   */
  export interface SpecialityHealthProblemTagsForSpecialityUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialityHealthProblemTagsForSpecialityUsingPATCH
   */
  export interface SpecialityHealthProblemTagsForSpecialityUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialityHealthProblemTagsForSpecialityUsingGET
   */
  export interface SpecialityHealthProblemTagsForSpecialityUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * healthproblemtagsforspecialityId
     */
    healthproblemtagsforspecialityId: string;
  }

  /**
   * Parameters for specialityHealthProblemTagsForSpecialityUsingDELETE
   */
  export interface SpecialityHealthProblemTagsForSpecialityUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * healthproblemtagsforspecialityId
     */
    healthproblemtagsforspecialityId: string;
  }

  /**
   * Parameters for specialitySpecialityDiagnosisTypesUsingPOST
   */
  export interface SpecialitySpecialityDiagnosisTypesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialitySpecialityDiagnosisTypesUsingPUT
   */
  export interface SpecialitySpecialityDiagnosisTypesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialitySpecialityDiagnosisTypesUsingPATCH
   */
  export interface SpecialitySpecialityDiagnosisTypesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialitySpecialityDiagnosisTypesUsingGET
   */
  export interface SpecialitySpecialityDiagnosisTypesUsingGETParams {

    /**
     * specialitydiagnosistypeId
     */
    specialitydiagnosistypeId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for specialitySpecialityDiagnosisTypesUsingDELETE
   */
  export interface SpecialitySpecialityDiagnosisTypesUsingDELETEParams {

    /**
     * specialitydiagnosistypeId
     */
    specialitydiagnosistypeId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for specialitySpecialityVitalTypesUsingPOST
   */
  export interface SpecialitySpecialityVitalTypesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialitySpecialityVitalTypesUsingPUT
   */
  export interface SpecialitySpecialityVitalTypesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialitySpecialityVitalTypesUsingPATCH
   */
  export interface SpecialitySpecialityVitalTypesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for specialitySpecialityVitalTypesUsingGET
   */
  export interface SpecialitySpecialityVitalTypesUsingGETParams {

    /**
     * specialityvitaltypeId
     */
    specialityvitaltypeId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for specialitySpecialityVitalTypesUsingDELETE
   */
  export interface SpecialitySpecialityVitalTypesUsingDELETEParams {

    /**
     * specialityvitaltypeId
     */
    specialityvitaltypeId: string;

    /**
     * id
     */
    id: number;
  }
}

export { SpecialityEntityService }
