/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesHealthProblemTagsForDoctor } from '../models/resources-health-problem-tags-for-doctor-';
import { ResourceHealthProblemTagsForDoctor } from '../models/resource-health-problem-tags-for-doctor-';
import { HealthProblemTagsForDoctor } from '../models/health-problem-tags-for-doctor';
import { ResourcesDoctor } from '../models/resources-doctor-';
import { ResourceDoctor } from '../models/resource-doctor-';

/**
 * $ Proxy 230
 */
@Injectable()
class HealthProblemTagsForDoctorEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.FindAllHealthProblemTagsForDoctorUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllHealthProblemTagsForDoctorUsingGETResponse(params: HealthProblemTagsForDoctorEntityService.FindAllHealthProblemTagsForDoctorUsingGETParams): Observable<HttpResponse<ResourcesHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourcesHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.FindAllHealthProblemTagsForDoctorUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllHealthProblemTagsForDoctorUsingGET(params: HealthProblemTagsForDoctorEntityService.FindAllHealthProblemTagsForDoctorUsingGETParams): Observable<ResourcesHealthProblemTagsForDoctor> {
    return this.findAllHealthProblemTagsForDoctorUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveHealthProblemTagsForDoctorUsingPOSTResponse(body: HealthProblemTagsForDoctor): Observable<HttpResponse<ResourceHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourceHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveHealthProblemTagsForDoctorUsingPOST(body: HealthProblemTagsForDoctor): Observable<ResourceHealthProblemTagsForDoctor> {
    return this.saveHealthProblemTagsForDoctorUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param healthTags healthTags
   * @return OK
   */
  searchWithNameHealthProblemTagsForDoctorUsingGETResponse(healthTags?: string): Observable<HttpResponse<ResourcesHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (healthTags != null) __params = __params.set('healthTags', healthTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/search/searchWithName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourcesHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param healthTags healthTags
   * @return OK
   */
  searchWithNameHealthProblemTagsForDoctorUsingGET(healthTags?: string): Observable<ResourcesHealthProblemTagsForDoctor> {
    return this.searchWithNameHealthProblemTagsForDoctorUsingGETResponse(healthTags).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdHealthProblemTagsForDoctorUsingGETResponse(id: number): Observable<HttpResponse<ResourceHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourceHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdHealthProblemTagsForDoctorUsingGET(id: number): Observable<ResourceHealthProblemTagsForDoctor> {
    return this.findByIdHealthProblemTagsForDoctorUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForDoctorUsingPUTResponse(params: HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPUTParams): Observable<HttpResponse<ResourceHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourceHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForDoctorUsingPUT(params: HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPUTParams): Observable<ResourceHealthProblemTagsForDoctor> {
    return this.saveHealthProblemTagsForDoctorUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteHealthProblemTagsForDoctorUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteHealthProblemTagsForDoctorUsingDELETE(id: number): Observable<void> {
    return this.deleteHealthProblemTagsForDoctorUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForDoctorUsingPATCHResponse(params: HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPATCHParams): Observable<HttpResponse<ResourceHealthProblemTagsForDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForDoctor = null;
        _body = _resp.body as ResourceHealthProblemTagsForDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForDoctorUsingPATCH(params: HealthProblemTagsForDoctorEntityService.SaveHealthProblemTagsForDoctorUsingPATCHParams): Observable<ResourceHealthProblemTagsForDoctor> {
    return this.saveHealthProblemTagsForDoctorUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingGET_1(id: number): Observable<ResourcesDoctor> {
    return this.healthProblemTagsForDoctorDoctorsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingPOSTResponse(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingPOST(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPOSTParams): Observable<ResourcesDoctor> {
    return this.healthProblemTagsForDoctorDoctorsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingPUTResponse(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPUTParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingPUT(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPUTParams): Observable<ResourcesDoctor> {
    return this.healthProblemTagsForDoctorDoctorsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  healthProblemTagsForDoctorDoctorsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  healthProblemTagsForDoctorDoctorsUsingDELETE_1(id: number): Observable<void> {
    return this.healthProblemTagsForDoctorDoctorsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingPATCHResponse(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctor = null;
        _body = _resp.body as ResourcesDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingPATCH(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingPATCHParams): Observable<ResourcesDoctor> {
    return this.healthProblemTagsForDoctorDoctorsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingGETResponse(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingGETParams): Observable<HttpResponse<ResourceDoctor>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}/doctors/${params.doctorId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctor = null;
        _body = _resp.body as ResourceDoctor;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctor>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   *
   * @return OK
   */
  healthProblemTagsForDoctorDoctorsUsingGET(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingGETParams): Observable<ResourceDoctor> {
    return this.healthProblemTagsForDoctorDoctorsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   */
  healthProblemTagsForDoctorDoctorsUsingDELETEResponse(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForDoctor/${params.id}/doctors/${params.doctorId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorId`: doctorId
   */
  healthProblemTagsForDoctorDoctorsUsingDELETE(params: HealthProblemTagsForDoctorEntityService.HealthProblemTagsForDoctorDoctorsUsingDELETEParams): Observable<void> {
    return this.healthProblemTagsForDoctorDoctorsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module HealthProblemTagsForDoctorEntityService {

  /**
   * Parameters for findAllHealthProblemTagsForDoctorUsingGET
   */
  export interface FindAllHealthProblemTagsForDoctorUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveHealthProblemTagsForDoctorUsingPUT
   */
  export interface SaveHealthProblemTagsForDoctorUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: HealthProblemTagsForDoctor;
  }

  /**
   * Parameters for saveHealthProblemTagsForDoctorUsingPATCH
   */
  export interface SaveHealthProblemTagsForDoctorUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: HealthProblemTagsForDoctor;
  }

  /**
   * Parameters for healthProblemTagsForDoctorDoctorsUsingPOST
   */
  export interface HealthProblemTagsForDoctorDoctorsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for healthProblemTagsForDoctorDoctorsUsingPUT
   */
  export interface HealthProblemTagsForDoctorDoctorsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for healthProblemTagsForDoctorDoctorsUsingPATCH
   */
  export interface HealthProblemTagsForDoctorDoctorsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for healthProblemTagsForDoctorDoctorsUsingGET
   */
  export interface HealthProblemTagsForDoctorDoctorsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorId
     */
    doctorId: string;
  }

  /**
   * Parameters for healthProblemTagsForDoctorDoctorsUsingDELETE
   */
  export interface HealthProblemTagsForDoctorDoctorsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorId
     */
    doctorId: string;
  }
}

export { HealthProblemTagsForDoctorEntityService }
