import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import { ConsultationEntityService } from 'src/app/api/emr/services';
import { Subscription } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-consultation-selection',
  templateUrl: './consultation-selection.component.html',
  styleUrls: ['./consultation-selection.component.scss']
})
export class ConsultationSelectionComponent implements OnInit {

  patientId;
  consultationId;
  searchingConsultation = true;
  consultationsPageInfo;
  consultationsPageNumber;
  consultations = [];
  consultationSubscription: Subscription;
  token;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private ConsultationEntityService: ConsultationEntityService,
    private LocalStorage: LocalStorage,
    private MatSnackBar: MatSnackBar,
    private MatDialogRef: MatDialogRef<ConsultationSelectionComponent>
  ) {
    this.patientId = data.patientId;
    this.consultationId = data.consultationId;
  }

  changeAllConsultationsPage(option) {
    switch (option) {
      case 'dec':
        if (this.consultationsPageNumber === 0) {
          this.searchConsultations(this.patientId, this.consultationsPageInfo.totalPages - 1)
        } else {
          this.searchConsultations(this.patientId, this.consultationsPageNumber - 1)
        }
        break;
      case 'inc':
        if (this.consultationsPageNumber === this.consultationsPageInfo.totalPages - 1) {
          this.searchConsultations(this.patientId, 0)
        } else {
          this.searchConsultations(this.patientId, this.consultationsPageNumber + 1)
        }
        break;
    }
  }

  searchConsultations(patientId, page = 0) {
    this.consultationsPageNumber = page;
    if (this.consultationSubscription) {
      this.consultationSubscription.unsubscribe();
    }

    this.consultationSubscription = this.ConsultationEntityService.findByDoctorInClinicIdInAndPatientIdConsultationUsingGET({
      patientId: patientId,
      doctorinclinic: this.token.doctorInClinicIds,
      page, sort: 'createdAt,desc'
    }).subscribe((res: any) => {
      if (res && res._embedded) {
        this.consultations = res._embedded.consultations;
        this.consultationsPageInfo = res.page;
        if (this.consultations && this.consultations.length) {
          if (this.consultations.length == 1) {
            this.MatSnackBar.open('Only one consultation available for this patient, hence automatically attached', '', { duration: 2000 });
            this.MatDialogRef.close(this.consultations[0]);
          }
        } else {
          this.MatSnackBar.open('No consultations available for this patient.', '', { duration: 2000 });
          this.MatDialogRef.close();
        }
        this.searchingConsultation = false;
      } else {
        this.MatSnackBar.open('No consultations available for this patient.', '', { duration: 2000 });
        this.MatDialogRef.close();
      }
    }, err => {
      alert('Error while searching consultations');
      this.MatDialogRef.close();
    })
  }

  selectConsultation(consultationId) {
    let consultation = this.consultations.find(consultation => consultation.id == consultationId);
    this.MatDialogRef.close(consultation);
  }

  ngOnInit() {
    this.LocalStorage.getItem('token').subscribe(token => {
      this.token = token;
      this.searchConsultations(this.patientId)
    })
  }

}
