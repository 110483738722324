import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable, observable } from "rxjs";
import { ProductTypeEntityService } from "./api/orders/services";
import { AuthenticationService } from "./login/service/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.validate().pipe((observable) => {
      observable.subscribe((res) => {
        if (!res) {
          this.router.navigate(["/login", { returnUrl: state.url }]);
        }
      });
      return observable;
    });
  }
}
