/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesClinic } from '../models/resources-clinic-';
import { ResourceClinic } from '../models/resource-clinic-';
import { Clinic } from '../models/clinic';
import { ResourcesDoctorInClinic } from '../models/resources-doctor-in-clinic-';
import { ResourceDoctorInClinic } from '../models/resource-doctor-in-clinic-';
import { ResourcesMedicineInClinic } from '../models/resources-medicine-in-clinic-';
import { ResourceMedicineInClinic } from '../models/resource-medicine-in-clinic-';
import { ResourcesProcedureType } from '../models/resources-procedure-type-';
import { ResourceProcedureType } from '../models/resource-procedure-type-';

/**
 * $ Proxy 152
 */
@Injectable()
class ClinicEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ClinicEntityService.FindAllClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllClinicUsingGETResponse(params: ClinicEntityService.FindAllClinicUsingGETParams): Observable<HttpResponse<ResourcesClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinic = null;
        _body = _resp.body as ResourcesClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.FindAllClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllClinicUsingGET(params: ClinicEntityService.FindAllClinicUsingGETParams): Observable<ResourcesClinic> {
    return this.findAllClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveClinicUsingPOSTResponse(body: Clinic): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/clinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveClinicUsingPOST(body: Clinic): Observable<ResourceClinic> {
    return this.saveClinicUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param clinicIDs clinicIDs
   * @return OK
   */
  findAllByIdInClinicUsingGETResponse(clinicIDs?: Array<number>): Observable<HttpResponse<ResourcesClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (clinicIDs || []).forEach((val, index) => {if (val != null) __params = __params.append('clinicIDs', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/search/findAllByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinic = null;
        _body = _resp.body as ResourcesClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinic>;
      })
    );
  }

  /**
   * @param clinicIDs clinicIDs
   * @return OK
   */
  findAllByIdInClinicUsingGET(clinicIDs?: Array<number>): Observable<ResourcesClinic> {
    return this.findAllByIdInClinicUsingGETResponse(clinicIDs).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinic doctorinclinic
   * @return OK
   */
  findByDoctorInClinicsIdInClinicUsingGETResponse(doctorinclinic?: Array<number>): Observable<HttpResponse<ResourcesClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    //(doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (doctorinclinic != null && doctorinclinic.length) __params = __params.set('doctorinclinic', doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/search/findByDoctorInClinicsIdIn?projection=clinicForTranscriptionOnly`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinic = null;
        _body = _resp.body as ResourcesClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinic>;
      })
    );
  }

  /**
   * @param doctorinclinic doctorinclinic
   * @return OK
   */
  findByDoctorInClinicsIdInClinicUsingGET(doctorinclinic?: Array<number>): Observable<ResourcesClinic> {
    return this.findByDoctorInClinicsIdInClinicUsingGETResponse(doctorinclinic).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.FindByNameContainingClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingClinicUsingGETResponse(params: ClinicEntityService.FindByNameContainingClinicUsingGETParams): Observable<HttpResponse<ResourcesClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/search/findByNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinic = null;
        _body = _resp.body as ResourcesClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.FindByNameContainingClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingClinicUsingGET(params: ClinicEntityService.FindByNameContainingClinicUsingGETParams): Observable<ResourcesClinic> {
    return this.findByNameContainingClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param doctorinclinic doctorinclinic
   * @return OK
   */
  findDistinctByDoctorInClinicsIdInClinicUsingGETResponse(doctorinclinic?: Array<number>): Observable<HttpResponse<ResourcesClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    //(doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    //if (doctorinclinic != null && doctorinclinic.length) __params = __params.set('doctorinclinic', doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinic/distinct/by/ids`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinic = null;
        _body = _resp.body as ResourcesClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinic>;
      })
    );
  }

  findDistinctByDoctorInClinicsIdInClinicUsingGETResponseProjection(doctorinclinic?: Array<number>): Observable<HttpResponse<ResourcesClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    //(doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    if (doctorinclinic != null && doctorinclinic.length) __params = __params.set('IDs', doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctorsinclinic/search/findByIdIn?&projection=doctorInClinicsForBroadcast`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesClinic = null;
        _body = _resp.body as ResourcesClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesClinic>;
      })
    );
  }

  /**
   * @param doctorinclinic doctorinclinic
   * @return OK
   */
  findDistinctByDoctorInClinicsIdInClinicUsingGET(doctorinclinic?: Array<number>): Observable<ResourcesClinic> {
    return this.findDistinctByDoctorInClinicsIdInClinicUsingGETResponse(doctorinclinic).pipe(
      map(_r => _r.body)
    );
  }

  findDistinctByDoctorInClinicsIdInClinicUsingGETProjection(doctorinclinic?: Array<number>): Observable<ResourcesClinic> {
    return this.findDistinctByDoctorInClinicsIdInClinicUsingGETResponseProjection(doctorinclinic).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdClinicUsingGET(id: number): Observable<ResourceClinic> {
    return this.findByIdClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.SaveClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveClinicUsingPUTResponse(params: ClinicEntityService.SaveClinicUsingPUTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.SaveClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveClinicUsingPUT(params: ClinicEntityService.SaveClinicUsingPUTParams): Observable<ResourceClinic> {
    return this.saveClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteClinicUsingDELETE(id: number): Observable<void> {
    return this.deleteClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.SaveClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveClinicUsingPATCHResponse(params: ClinicEntityService.SaveClinicUsingPATCHParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.SaveClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveClinicUsingPATCH(params: ClinicEntityService.SaveClinicUsingPATCHParams): Observable<ResourceClinic> {
    return this.saveClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  clinicDoctorInClinicsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  clinicDoctorInClinicsUsingGET_1(id: number): Observable<ResourcesDoctorInClinic> {
    return this.clinicDoctorInClinicsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingPOSTResponse(params: ClinicEntityService.ClinicDoctorInClinicsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingPOST(params: ClinicEntityService.ClinicDoctorInClinicsUsingPOSTParams): Observable<ResourcesDoctorInClinic> {
    return this.clinicDoctorInClinicsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingPUTResponse(params: ClinicEntityService.ClinicDoctorInClinicsUsingPUTParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingPUT(params: ClinicEntityService.ClinicDoctorInClinicsUsingPUTParams): Observable<ResourcesDoctorInClinic> {
    return this.clinicDoctorInClinicsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  clinicDoctorInClinicsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  clinicDoctorInClinicsUsingDELETE_1(id: number): Observable<void> {
    return this.clinicDoctorInClinicsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingPATCHResponse(params: ClinicEntityService.ClinicDoctorInClinicsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingPATCH(params: ClinicEntityService.ClinicDoctorInClinicsUsingPATCHParams): Observable<ResourcesDoctorInClinic> {
    return this.clinicDoctorInClinicsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingGETResponse(params: ClinicEntityService.ClinicDoctorInClinicsUsingGETParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/doctorInClinics/${params.doctorinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  clinicDoctorInClinicsUsingGET(params: ClinicEntityService.ClinicDoctorInClinicsUsingGETParams): Observable<ResourceDoctorInClinic> {
    return this.clinicDoctorInClinicsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   */
  clinicDoctorInClinicsUsingDELETEResponse(params: ClinicEntityService.ClinicDoctorInClinicsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/doctorInClinics/${params.doctorinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicDoctorInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   */
  clinicDoctorInClinicsUsingDELETE(params: ClinicEntityService.ClinicDoctorInClinicsUsingDELETEParams): Observable<void> {
    return this.clinicDoctorInClinicsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  clinicMedicineInClinicsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  clinicMedicineInClinicsUsingGET_1(id: number): Observable<ResourcesMedicineInClinic> {
    return this.clinicMedicineInClinicsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingPOSTResponse(params: ClinicEntityService.ClinicMedicineInClinicsUsingPOSTParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingPOST(params: ClinicEntityService.ClinicMedicineInClinicsUsingPOSTParams): Observable<ResourcesMedicineInClinic> {
    return this.clinicMedicineInClinicsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingPUTResponse(params: ClinicEntityService.ClinicMedicineInClinicsUsingPUTParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingPUT(params: ClinicEntityService.ClinicMedicineInClinicsUsingPUTParams): Observable<ResourcesMedicineInClinic> {
    return this.clinicMedicineInClinicsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  clinicMedicineInClinicsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  clinicMedicineInClinicsUsingDELETE_1(id: number): Observable<void> {
    return this.clinicMedicineInClinicsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingPATCHResponse(params: ClinicEntityService.ClinicMedicineInClinicsUsingPATCHParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/medicineInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingPATCH(params: ClinicEntityService.ClinicMedicineInClinicsUsingPATCHParams): Observable<ResourcesMedicineInClinic> {
    return this.clinicMedicineInClinicsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingGETParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingGETResponse(params: ClinicEntityService.ClinicMedicineInClinicsUsingGETParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/medicineInClinics/${params.medicineinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingGETParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   *
   * @return OK
   */
  clinicMedicineInClinicsUsingGET(params: ClinicEntityService.ClinicMedicineInClinicsUsingGETParams): Observable<ResourceMedicineInClinic> {
    return this.clinicMedicineInClinicsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   */
  clinicMedicineInClinicsUsingDELETEResponse(params: ClinicEntityService.ClinicMedicineInClinicsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/medicineInClinics/${params.medicineinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicMedicineInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `medicineinclinicId`: medicineinclinicId
   *
   * - `id`: id
   */
  clinicMedicineInClinicsUsingDELETE(params: ClinicEntityService.ClinicMedicineInClinicsUsingDELETEParams): Observable<void> {
    return this.clinicMedicineInClinicsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  clinicProcedureTypesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${id}/procedureTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  clinicProcedureTypesUsingGET_1(id: number): Observable<ResourcesProcedureType> {
    return this.clinicProcedureTypesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicProcedureTypesUsingPOSTResponse(params: ClinicEntityService.ClinicProcedureTypesUsingPOSTParams): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/procedureTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicProcedureTypesUsingPOST(params: ClinicEntityService.ClinicProcedureTypesUsingPOSTParams): Observable<ResourcesProcedureType> {
    return this.clinicProcedureTypesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicProcedureTypesUsingPUTResponse(params: ClinicEntityService.ClinicProcedureTypesUsingPUTParams): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/procedureTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicProcedureTypesUsingPUT(params: ClinicEntityService.ClinicProcedureTypesUsingPUTParams): Observable<ResourcesProcedureType> {
    return this.clinicProcedureTypesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  clinicProcedureTypesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${id}/procedureTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  clinicProcedureTypesUsingDELETE_1(id: number): Observable<void> {
    return this.clinicProcedureTypesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicProcedureTypesUsingPATCHResponse(params: ClinicEntityService.ClinicProcedureTypesUsingPATCHParams): Observable<HttpResponse<ResourcesProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/procedureTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesProcedureType = null;
        _body = _resp.body as ResourcesProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesProcedureType>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  clinicProcedureTypesUsingPATCH(params: ClinicEntityService.ClinicProcedureTypesUsingPATCHParams): Observable<ResourcesProcedureType> {
    return this.clinicProcedureTypesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingGETParams` containing the following parameters:
   *
   * - `proceduretypeId`: proceduretypeId
   *
   * - `id`: id
   *
   * @return OK
   */
  clinicProcedureTypesUsingGETResponse(params: ClinicEntityService.ClinicProcedureTypesUsingGETParams): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/procedureTypes/${params.proceduretypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingGETParams` containing the following parameters:
   *
   * - `proceduretypeId`: proceduretypeId
   *
   * - `id`: id
   *
   * @return OK
   */
  clinicProcedureTypesUsingGET(params: ClinicEntityService.ClinicProcedureTypesUsingGETParams): Observable<ResourceProcedureType> {
    return this.clinicProcedureTypesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingDELETEParams` containing the following parameters:
   *
   * - `proceduretypeId`: proceduretypeId
   *
   * - `id`: id
   */
  clinicProcedureTypesUsingDELETEResponse(params: ClinicEntityService.ClinicProcedureTypesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/clinics/${params.id}/procedureTypes/${params.proceduretypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `ClinicEntityService.ClinicProcedureTypesUsingDELETEParams` containing the following parameters:
   *
   * - `proceduretypeId`: proceduretypeId
   *
   * - `id`: id
   */
  clinicProcedureTypesUsingDELETE(params: ClinicEntityService.ClinicProcedureTypesUsingDELETEParams): Observable<void> {
    return this.clinicProcedureTypesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module ClinicEntityService {

  /**
   * Parameters for findAllClinicUsingGET
   */
  export interface FindAllClinicUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByNameContainingClinicUsingGET
   */
  export interface FindByNameContainingClinicUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveClinicUsingPUT
   */
  export interface SaveClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Clinic;
  }

  /**
   * Parameters for saveClinicUsingPATCH
   */
  export interface SaveClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Clinic;
  }

  /**
   * Parameters for clinicDoctorInClinicsUsingPOST
   */
  export interface ClinicDoctorInClinicsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicDoctorInClinicsUsingPUT
   */
  export interface ClinicDoctorInClinicsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicDoctorInClinicsUsingPATCH
   */
  export interface ClinicDoctorInClinicsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicDoctorInClinicsUsingGET
   */
  export interface ClinicDoctorInClinicsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId: string;
  }

  /**
   * Parameters for clinicDoctorInClinicsUsingDELETE
   */
  export interface ClinicDoctorInClinicsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId: string;
  }

  /**
   * Parameters for clinicMedicineInClinicsUsingPOST
   */
  export interface ClinicMedicineInClinicsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicMedicineInClinicsUsingPUT
   */
  export interface ClinicMedicineInClinicsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicMedicineInClinicsUsingPATCH
   */
  export interface ClinicMedicineInClinicsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicMedicineInClinicsUsingGET
   */
  export interface ClinicMedicineInClinicsUsingGETParams {

    /**
     * medicineinclinicId
     */
    medicineinclinicId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for clinicMedicineInClinicsUsingDELETE
   */
  export interface ClinicMedicineInClinicsUsingDELETEParams {

    /**
     * medicineinclinicId
     */
    medicineinclinicId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for clinicProcedureTypesUsingPOST
   */
  export interface ClinicProcedureTypesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicProcedureTypesUsingPUT
   */
  export interface ClinicProcedureTypesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicProcedureTypesUsingPATCH
   */
  export interface ClinicProcedureTypesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for clinicProcedureTypesUsingGET
   */
  export interface ClinicProcedureTypesUsingGETParams {

    /**
     * proceduretypeId
     */
    proceduretypeId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for clinicProcedureTypesUsingDELETE
   */
  export interface ClinicProcedureTypesUsingDELETEParams {

    /**
     * proceduretypeId
     */
    proceduretypeId: string;

    /**
     * id
     */
    id: number;
  }
}

export { ClinicEntityService }
