import { IssueService } from './services/issue.service';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { WebMvcEndpointHandlerMappingService } from './services/web-mvc-endpoint-handler-mapping.service';
import { OperationHandlerService } from './services/operation-handler.service';
import { MedicationDateEntityService } from './services/medication-date-entity.service';
import { AddressEntityService } from './services/address-entity.service';
import { AdviceEntityService } from './services/advice-entity.service';
import { PincodeValidateControllerService } from './services/pincode-validate-controller.service';
import { MeddoLiteControllerService } from './services/meddo-lite.service';
import { AfterOrderPaidStatusEntityService } from './services/after-order-paid-status-entity.service';
import { AllergyEntityService } from './services/allergy-entity.service';
import { AllergyTypeEntityService } from './services/allergy-type-entity.service';
import { PatientHistorySearchControllerService } from './services/patient-history-search-controller.service';
import { ProcedureTypeControllerService } from './services/procedure-type-controller.service';
import { ClinicEntityService } from './services/clinic-entity.service';
import { DoctorsAssistantDashboardControllerService } from './services/doctors-assistant-dashboard-controller.service';
import { CommentForPatientHistoryControllerService } from './services/comment-for-patient-history-controller.service';
import { CommentOnDocumentEntityService } from './services/comment-on-document-entity.service';
import { CommentEntityService } from './services/comment-entity.service';
import { ConsultationControllerService } from './services/consultation-controller.service';
import { ReasonCodingControllerService } from './services/reason-coding-controller.service';
import { ConsultationHistoryEntityService } from './services/consultation-history-entity.service';
import { ConsultationEntityService } from './services/consultation-entity.service';
import { DeclineReasonEntityService } from './services/decline-reason-entity.service';
import { PrescriptionControllerService } from './services/prescription-controller.service';
import { DiagnosisEntityService } from './services/diagnosis-entity.service';
import { DiagnosisTypeEntityService } from './services/diagnosis-type-entity.service';
import { DiagnosisTypeControllerService } from './services/diagnosis-type-controller.service';
import { BulkUploadControllerService } from './services/bulk-upload-controller.service';
import { DiagnosticControllerService } from './services/diagnostic-controller.service';
import { DiagnosticAndPatientHistoryEntityService } from './services/diagnostic-and-patient-history-entity.service';
import { DiagnosticReportValueEntityService } from './services/diagnostic-report-value-entity.service';
import { DiagnosticLabEntityService } from './services/diagnostic-lab-entity.service';
import { DiagnosticEntityService } from './services/diagnostic-entity.service';
import { DiagnosticTypeEntityService } from './services/diagnostic-type-entity.service';
import { DiagnosticTypeInLabEntityService } from './services/diagnostic-type-in-lab-entity.service';
import { DoctorEntityService } from './services/doctor-entity.service';
import { DoctorControllerService } from './services/doctor-controller.service';
import { DoctorRatingControllerService } from './services/doctor-rating-controller.service';
import { DoctorTagTypeEntityService } from './services/doctor-tag-type-entity.service';
import { DoctorTagsEntityService } from './services/doctor-tags-entity.service';
import { DoctorsAppointmentDashboardControllerService } from './services/doctors-appointment-dashboard-controller.service';
import { CommonSymptomControllerService } from './services/common-symptom-controller.service';
import { AnalysisReportDashboardService } from './services/analysis-report-dashboard.service';
import { DoctorDashboardControllerService } from './services/doctor-dashboard-controller.service';
import { PatientMedicalHistoryControllerService } from './services/patient-medical-history-controller.service';
import { DoctorInClinicEntityService } from './services/doctor-in-clinic-entity.service';
import { ExistingConditionEntityService } from './services/existing-condition-entity.service';
import { ExistingConditionTypeEntityService } from './services/existing-condition-type-entity.service';
import { FamilyEntityService } from './services/family-entity.service';
import { FollowUpControllerService } from './services/follow-up-controller.service';
import { FollowupEntityService } from './services/followup-entity.service';
import { HealthProblemTagsForDoctorEntityService } from './services/health-problem-tags-for-doctor-entity.service';
import { HealthProblemTagsForSpecialityEntityService } from './services/health-problem-tags-for-speciality-entity.service';
import { PatientAppConsolidateControllerService } from './services/patient-app-consolidate-controller.service';
import { SwaggergenControllerService } from './services/swaggergen-controller.service';
import { MedicineGeneralEntityService } from './services/medicine-general-entity.service';
import { MedicineSaltEntityService } from './services/medicine-salt-entity.service';
import { MedicineControllerService } from './services/medicine-controller.service';
import { MedicineInClinicEntityService } from './services/medicine-in-clinic-entity.service';
import { MedicineEntityService } from './services/medicine-entity.service';
import { DashboardSummaryControllerService } from './services/dashboard-summary-controller.service';
import { PatientAppMyCareControllerService } from './services/patient-app-my-care-controller.service';
import { PatientAppControllerService } from './services/patient-app-controller.service';
import { PatientAndOrderControllerService } from './services/patient-and-order-controller.service';
import { ElasticsearchControllerService } from './services/elasticsearch-controller.service';
import { PrescriptionByDateControllerService } from './services/prescription-by-date-controller.service';
import { PatientHistoryEntityService } from './services/patient-history-entity.service';
import { PatientPreviousDocumentEntityService } from './services/patient-previous-document-entity.service';
import { PatientSymptomEntityService } from './services/patient-symptom-entity.service';
import { PatientEntityService } from './services/patient-entity.service';
import { PatientRepositoryControllerService } from './services/patient-repository-controller.service';
import { PrescriptionByDateEntityService } from './services/prescription-by-date-entity.service';
import { PrescriptionDescriptionEntityService } from './services/prescription-description-entity.service';
import { PrescriptionEntityService } from './services/prescription-entity.service';
import { MedicalProcedureEntityService } from './services/medical-procedure-entity.service';
import { ProcedureTypeEntityService } from './services/procedure-type-entity.service';
import { ProfileControllerService } from './services/profile-controller.service';
import { ReferralEntityService } from './services/referral-entity.service';
import { ScribbleEntityService } from './services/scribble-entity.service';
import { DoctorAndClinicControllerService } from './services/doctor-and-clinic-controller.service';
import { SpecialityDiagnosisTypeEntityService } from './services/speciality-diagnosis-type-entity.service';
import { SpecialityVitalTypeEntityService } from './services/speciality-vital-type-entity.service';
import { SpecialityEntityService } from './services/speciality-entity.service';
import { BucketControllerService } from './services/bucket-controller.service';
import { ScribbleUploadControllerService } from './services/scribble-upload-controller.service';
import { SymptomTypeEntityService } from './services/symptom-type-entity.service';
import { SymptomEntityService } from './services/symptom-entity.service';
import { TakenMedicineEntityService } from './services/taken-medicine-entity.service';
import { TaxesEntityService } from './services/taxes-entity.service';
import { TestParameterMasterEntityService } from './services/test-parameter-master-entity.service';
import { TimeSlotGroupEntityService } from './services/time-slot-group-entity.service';
import { TimeSlotEntityService } from './services/time-slot-entity.service';
import { VisitTypeEntityService } from './services/visit-type-entity.service';
import { VitalEntityService } from './services/vital-entity.service';
import { VitalTypeEntityService } from './services/vital-type-entity.service';
import { PatientOpenApiControllerService } from './services/patient-open-api-controller.service';
import { BasicErrorControllerService } from './services/basic-error-controller.service';

/**
 * Module that provides instances for all API services
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
   WebMvcEndpointHandlerMappingService,
   OperationHandlerService,
   MedicationDateEntityService,
   AddressEntityService,
   AdviceEntityService,
   AfterOrderPaidStatusEntityService,
   AllergyEntityService,
   MeddoLiteControllerService,
   AllergyTypeEntityService,
   PatientHistorySearchControllerService,
   ProcedureTypeControllerService,
   PincodeValidateControllerService,
   ClinicEntityService,
   DoctorsAssistantDashboardControllerService,
   CommentForPatientHistoryControllerService,
   CommentOnDocumentEntityService,
   CommentEntityService,
   ConsultationControllerService,
   ReasonCodingControllerService,
   ConsultationHistoryEntityService,
   ConsultationEntityService,
   DeclineReasonEntityService,
   PrescriptionControllerService,
   DiagnosisEntityService,
   DiagnosisTypeEntityService,
   DiagnosisTypeControllerService,
   BulkUploadControllerService,
   DiagnosticControllerService,
   DiagnosticAndPatientHistoryEntityService,
   DiagnosticReportValueEntityService,
   DiagnosticLabEntityService,
   DiagnosticEntityService,
   DiagnosticTypeEntityService,
   DiagnosticTypeInLabEntityService,
   DoctorEntityService,
   DoctorControllerService,
   DoctorRatingControllerService,
   DoctorTagTypeEntityService,
   DoctorTagsEntityService,
   DoctorsAppointmentDashboardControllerService,
   CommonSymptomControllerService,
   AnalysisReportDashboardService,
   DoctorDashboardControllerService,
   PatientMedicalHistoryControllerService,
   DoctorInClinicEntityService,
   ExistingConditionEntityService,
   ExistingConditionTypeEntityService,
   FamilyEntityService,
   FollowUpControllerService,
   FollowupEntityService,
   HealthProblemTagsForDoctorEntityService,
   HealthProblemTagsForSpecialityEntityService,
   PatientAppConsolidateControllerService,
   SwaggergenControllerService,
   MedicineGeneralEntityService,
   MedicineSaltEntityService,
   MedicineControllerService,
   MedicineInClinicEntityService,
   MedicineEntityService,
   DashboardSummaryControllerService,
   PatientAppMyCareControllerService,
   PatientAppControllerService,
   PatientAndOrderControllerService,
   ElasticsearchControllerService,
   PrescriptionByDateControllerService,
   PatientHistoryEntityService,
   PatientPreviousDocumentEntityService,
   PatientSymptomEntityService,
   PatientEntityService,
   PatientRepositoryControllerService,
   PrescriptionByDateEntityService,
   PrescriptionDescriptionEntityService,
   PrescriptionEntityService,
   MedicalProcedureEntityService,
   ProcedureTypeEntityService,
   ProfileControllerService,
   ReferralEntityService,
   ScribbleEntityService,
   DoctorAndClinicControllerService,
   SpecialityDiagnosisTypeEntityService,
   SpecialityVitalTypeEntityService,
   SpecialityEntityService,
   BucketControllerService,
   ScribbleUploadControllerService,
   SymptomTypeEntityService,
   SymptomEntityService,
   TakenMedicineEntityService,
   TaxesEntityService,
   TestParameterMasterEntityService,
   TimeSlotGroupEntityService,
   TimeSlotEntityService,
   VisitTypeEntityService,
   VitalEntityService,
   VitalTypeEntityService,
   PatientOpenApiControllerService,
   BasicErrorControllerService,
   IssueService
  ],
})
export class ApiModule { }
