/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PagedResourcesResourceOrder } from '../models/paged-resources-resource-order-';
import {ClinicPaymentSummary} from '../models/clinic-payment-summary';

/**
 * Order Controller
 */
@Injectable()
class OrderControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderControllerService.FetchOrdersByPatientUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `dicIds`: dicIds
   *
   * - `date`: date
   *
   * @return OK
   */
  fetchOrdersByPatientUsingGETResponse(params: OrderControllerService.FetchOrdersByPatientUsingGETParams): Observable<HttpResponse<PagedResourcesResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.dicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('dicIds', val.toString())});
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/search-by-type`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PagedResourcesResourceOrder = null;
        _body = _resp.body as PagedResourcesResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<PagedResourcesResourceOrder>;
      })
    );
  }


  fetchOrdersTimeSlotUsingGETResponse(params: OrderControllerService.FetchOrderTimeSlotUsingGETParams): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    if (params.endTime != null) __params = __params.set('endTime', params.endTime.toString());
    if (params.interval != null) __params = __params.set('interval', params.interval.toString());
    if (params.duration != null) __params = __params.set('duration', params.duration.toString());
    if (params.pincode != null) __params = __params.set('pincode', params.pincode.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/order/items/timeslot-availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body = _resp.body;
        return _resp.clone({body: _body}) as HttpResponse<PagedResourcesResourceOrder>;
      })
    );
  }
  fetchPendingEmrGETResponse(params): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    //(params.doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/consultation/by/dic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body = _resp.body;
        return _resp.clone({body: _body}) as HttpResponse<PagedResourcesResourceOrder>;
      })
    );
  }

  getClinicPaymentSummaryGETResponse(params: OrderControllerService.GetClinicPaymentSummaryUsingGETParams): Observable<HttpResponse<ClinicPaymentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.platform != null) __params = __params.set('platform', params.platform.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.dicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('dicIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/clinic-payment-summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ClinicPaymentSummary = null;
        _body = _resp.body as ClinicPaymentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ClinicPaymentSummary>;
      })
    );
  }
  /**
   * @param params The `OrderControllerService.FetchOrdersByPatientUsingGETParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `dicIds`: dicIds
   *
   * - `date`: date
   *
   * @return OK
   */
  fetchOrdersByPatientUsingGET(params: OrderControllerService.FetchOrdersByPatientUsingGETParams): Observable<PagedResourcesResourceOrder> {
    return this.fetchOrdersByPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  fetchOrderTimeSlotsUsingGET(params: OrderControllerService.FetchOrderTimeSlotUsingGETParams): Observable<any> {
    return this.fetchOrdersTimeSlotUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  fetchPendingEmrGET(params): Observable<any> {
    return this.fetchPendingEmrGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  getClinicPaymentSummaryUsingGET(params: OrderControllerService.GetClinicPaymentSummaryUsingGETParams): Observable<ClinicPaymentSummary> {
    return this.getClinicPaymentSummaryGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderControllerService {

  /**
   * Parameters for fetchOrdersByPatientUsingGET
   */
  export interface FetchOrdersByPatientUsingGETParams {

    /**
     * type
     */
    type: string;
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * dicIds
     */
    dicIds?: Array<number>;

    /**
     * date
     */
    date?: string;
  }

  export interface FetchOrderTimeSlotUsingGETParams {

    /**
     * type
     */
    startTime: string;
    duration?: string;
    endTime?: string;
    interval?: string;
    pincode?: string;
  }


  export interface GetClinicPaymentSummaryUsingGETParams {

    /**
     * plateform:"POS"
     */
    platform?: string;
    startDate?: string;
    endDate?: string;
    /**
     * dicIds
     */
    dicIds?: Array<number>;
  }
}

export { OrderControllerService }
