import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MedicineEntityService } from '../../../api/emr/services';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-new-medicine',
  templateUrl: './new-medicine.component.html',
  styleUrls: ['./new-medicine.component.scss']
})
export class NewMedicineComponent implements OnInit {

  addMedicineForm: FormGroup;
  medicineId: number;
  loading = true;
  savingMedicine: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private LocalStorage: LocalStorage,
    private medicineEntityService: MedicineEntityService,
    private MatDialogRef: MatDialogRef<NewMedicineComponent>
  ) { }

  saveNewMedicine() {
    this.savingMedicine = true;
    let medicineToSave = this.addMedicineForm.getRawValue();

    this.medicineEntityService.saveMedicineUsingPOST(medicineToSave).subscribe(
      medicine => {
        this.MatDialogRef.close(true);
      },
      err => {
        alert('Error while saving Medicine');
        this.savingMedicine = false;
      })
  }

  ngOnInit() {
    this.loading = true;
    this.LocalStorage.getItem('token').subscribe(token => {
      this.addMedicineForm = this.formBuilder.group({
        isDeliverable: [true, Validators.required],
        isActive: [true, Validators.required],
        name: ['', Validators.required],
        cgst: [0, Validators.required],
        sgst: [0, Validators.required],
        price: [null, Validators.required],
        displayPrice: [null, Validators.required],
        version: [1, Validators.required],
        origin: ['TRANSCRIPTION'],
        createdBy: [token.userID.toString()]
      });
      this.addMedicineForm.get('price').valueChanges.subscribe(value => this.addMedicineForm.get('displayPrice').setValue(value));
      this.loading = false;
    })
  }
}
