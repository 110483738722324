/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OAuth2AccessToken } from '../models/oauth-2access-token';

/**
 * Token Endpoint
 */
@Injectable()
class TokenEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TokenEndpointService.GetAccessTokenUsingGETParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * @return OK
   */
  getAccessTokenUsingGETResponse(params: TokenEndpointService.GetAccessTokenUsingGETParams): Observable<HttpResponse<OAuth2AccessToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oauth/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: OAuth2AccessToken = null;
        _body = _resp.body as OAuth2AccessToken;
        return _resp.clone({body: _body}) as HttpResponse<OAuth2AccessToken>;
      })
    );
  }

  /**
   * @param params The `TokenEndpointService.GetAccessTokenUsingGETParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * @return OK
   */
  getAccessTokenUsingGET(params: TokenEndpointService.GetAccessTokenUsingGETParams): Observable<OAuth2AccessToken> {
    return this.getAccessTokenUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TokenEndpointService.PostAccessTokenUsingPOSTParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * @return OK
   */
  postAccessTokenUsingPOSTResponse(params: TokenEndpointService.PostAccessTokenUsingPOSTParams): Observable<HttpResponse<OAuth2AccessToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.parameters != null) __params = __params.set('parameters', params.parameters.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/oauth/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: OAuth2AccessToken = null;
        _body = _resp.body as OAuth2AccessToken;
        return _resp.clone({body: _body}) as HttpResponse<OAuth2AccessToken>;
      })
    );
  }

  /**
   * @param params The `TokenEndpointService.PostAccessTokenUsingPOSTParams` containing the following parameters:
   *
   * - `parameters`: parameters
   *
   * - `name`:
   *
   * @return OK
   */
  postAccessTokenUsingPOST(params: TokenEndpointService.PostAccessTokenUsingPOSTParams): Observable<OAuth2AccessToken> {
    return this.postAccessTokenUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module TokenEndpointService {

  /**
   * Parameters for getAccessTokenUsingGET
   */
  export interface GetAccessTokenUsingGETParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;
  }

  /**
   * Parameters for postAccessTokenUsingPOST
   */
  export interface PostAccessTokenUsingPOSTParams {

    /**
     * parameters
     */
    parameters: any;
    name?: string;
  }
}

export { TokenEndpointService }
