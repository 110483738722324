import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ProcedureTypeEntityService } from 'src/app/api/emr/services';

@Component({
  selector: 'app-new-procedure-type-dialog',
  templateUrl: './new-procedure-type-dialog.component.html',
  styleUrls: ['./new-procedure-type-dialog.component.scss']
})
export class NewProcedureTypeDialogComponent implements OnInit {

  procedureTypeFormGroup: FormGroup;
  clinicUrl;
  savingProcedureType = false;

  constructor(
    private FormBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data,
    private ProcedureTypeEntityService: ProcedureTypeEntityService,
    private MatDialogRef: MatDialogRef<NewProcedureTypeDialogComponent>,
    private MatSnackBar: MatSnackBar
  ) { this.clinicUrl = data.clinicUrl }

  save(procedureType) {
    this.savingProcedureType = true;
    this.ProcedureTypeEntityService.saveProcedureTypeUsingPOST(procedureType).subscribe(
      res => this.MatDialogRef.close(res),
      err => {
        this.MatSnackBar.open('Error while saving procedure type', '', {
          duration: 2000, horizontalPosition: 'right', verticalPosition: 'top'
        })
        this.savingProcedureType = false;
      }
    )
  }

  ngOnInit() {
    this.procedureTypeFormGroup = this.FormBuilder.group({
      sgst: [0],
      cgst: [0],
      name: ['', Validators.required],
      price: [null, Validators.required],
      clinic: [this.clinicUrl, Validators.required],
    })
  }

}
