/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { PatientWithFamilyAndAddress } from "../models/patient-with-family-and-address";

/**
 * Patient Repository Controller
 */
@Injectable()
class PatientRepositoryControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param id id
   * @return OK
   */
  getPatientUsingGETResponse(
    id: number
  ): Observable<HttpResponse<PatientWithFamilyAndAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: PatientWithFamilyAndAddress = null;
        _body = _resp.body as PatientWithFamilyAndAddress;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<PatientWithFamilyAndAddress>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  getPatientUsingGET(id: number): Observable<PatientWithFamilyAndAddress> {
    return this.getPatientUsingGETResponse(id).pipe(map((_r) => _r.body));
  }
}

module PatientRepositoryControllerService {}

export { PatientRepositoryControllerService };
