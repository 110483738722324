/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPaymentMode } from '../models/resources-payment-mode-';
import { ResourcePaymentMode } from '../models/resource-payment-mode-';
import { PaymentMode } from '../models/payment-mode';

/**
 * $ Proxy 178
 */
@Injectable()
class PaymentModeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PaymentModeEntityService.FindAllPaymentModeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPaymentModeUsingGETResponse(params: PaymentModeEntityService.FindAllPaymentModeUsingGETParams): Observable<HttpResponse<ResourcesPaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/paymentModes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPaymentMode = null;
        _body = _resp.body as ResourcesPaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentModeEntityService.FindAllPaymentModeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPaymentModeUsingGET(params: PaymentModeEntityService.FindAllPaymentModeUsingGETParams): Observable<ResourcesPaymentMode> {
    return this.findAllPaymentModeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePaymentModeUsingPOSTResponse(body: PaymentMode): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/paymentModes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePaymentModeUsingPOST(body: PaymentMode): Observable<ResourcePaymentMode> {
    return this.savePaymentModeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPaymentModeUsingGETResponse(id: number): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/paymentModes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPaymentModeUsingGET(id: number): Observable<ResourcePaymentMode> {
    return this.findByIdPaymentModeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentModeEntityService.SavePaymentModeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentModeUsingPUTResponse(params: PaymentModeEntityService.SavePaymentModeUsingPUTParams): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/paymentModes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentModeEntityService.SavePaymentModeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentModeUsingPUT(params: PaymentModeEntityService.SavePaymentModeUsingPUTParams): Observable<ResourcePaymentMode> {
    return this.savePaymentModeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deletePaymentModeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/paymentModes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePaymentModeUsingDELETE(id: number): Observable<void> {
    return this.deletePaymentModeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PaymentModeEntityService.SavePaymentModeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentModeUsingPATCHResponse(params: PaymentModeEntityService.SavePaymentModeUsingPATCHParams): Observable<HttpResponse<ResourcePaymentMode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/paymentModes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePaymentMode = null;
        _body = _resp.body as ResourcePaymentMode;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePaymentMode>;
      })
    );
  }

  /**
   * @param params The `PaymentModeEntityService.SavePaymentModeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePaymentModeUsingPATCH(params: PaymentModeEntityService.SavePaymentModeUsingPATCHParams): Observable<ResourcePaymentMode> {
    return this.savePaymentModeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PaymentModeEntityService {

  /**
   * Parameters for findAllPaymentModeUsingGET
   */
  export interface FindAllPaymentModeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for savePaymentModeUsingPUT
   */
  export interface SavePaymentModeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PaymentMode;
  }

  /**
   * Parameters for savePaymentModeUsingPATCH
   */
  export interface SavePaymentModeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PaymentMode;
  }
}

export { PaymentModeEntityService }
