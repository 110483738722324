/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Check Token Endpoint
 */
@Injectable()
class CheckTokenEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingGETResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingGET(token: string): Observable<{}> {
    return this.checkTokenUsingGETResponse(token).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingHEADResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingHEAD(token: string): Observable<{}> {
    return this.checkTokenUsingHEADResponse(token).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingPOSTResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingPOST(token: string): Observable<{}> {
    return this.checkTokenUsingPOSTResponse(token).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingPUTResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingPUT(token: string): Observable<{}> {
    return this.checkTokenUsingPUTResponse(token).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingDELETEResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingDELETE(token: string): Observable<{}> {
    return this.checkTokenUsingDELETEResponse(token).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingOPTIONSResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingOPTIONS(token: string): Observable<{}> {
    return this.checkTokenUsingOPTIONSResponse(token).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingPATCHResponse(token: string): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (token != null) __params = __params.set('token', token.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/oauth/check_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param token token
   * @return OK
   */
  checkTokenUsingPATCH(token: string): Observable<{}> {
    return this.checkTokenUsingPATCHResponse(token).pipe(
      map(_r => _r.body)
    );
  }
}

module CheckTokenEndpointService {
}

export { CheckTokenEndpointService }
