import { LocalStorage } from '@ngx-pwa/local-storage';
import { ApiConfiguration } from './../../api/auth/api-configuration';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditTranscriberComponent } from 'src/app/shared/dialog/edit-transcriber/edit-transcriber.component';
import { TranscriberService } from 'src/app/api/auth/services/transcriber.service';

@Component({
  selector: 'app-transcriber-list',
  templateUrl: './transcriber-list.component.html',
  styleUrls: ['./transcriber-list.component.scss'],
  providers:[TranscriberService , ApiConfiguration]
})
export class TranscriberListComponent implements OnInit {

  constructor(
    private MatDialog: MatDialog,
    private TranscriberService: TranscriberService,
    private LocalStorage: LocalStorage
  ) { }

  displayedColumns: string[] = ['name', 'isActive','task','action'];
  dataSource;
  loadingData: boolean = true;
  doctorInClinics;
  pageInfo;

  editUser(data){
    if(!data){
      data = { doctorInClinics: this.doctorInClinics}
    }else{
      data['doctorInClinics'] = this.doctorInClinics
    }
    const dialog = this.MatDialog.open(EditTranscriberComponent ,{
      width: '70vh',
      data
    });
    dialog.afterClosed().subscribe(res => {
      if(res){
        this.getAllTranscriber(0,20);
      }
    })
  }

  getAllTranscriber(page , size){
    this.loadingData = true;
    this.TranscriberService.fetchAllTranscriber({page ,size}).subscribe((res: any)=> {
      this.loadingData = false;
      if(res && res.content){
        this.dataSource = res.content;
        this.pageInfo = res;
      }else{
        this.dataSource = [];
      }
    },err=>{
      this.loadingData = false;
      if(err && err.error && err.error.message){
        alert(err.error.message);
      }else{
        alert('Something went wrong');
      }
    })

  }

  deleteTranscriber(id){
    if(window['confirm']('Are you sure you want to delete user')){
      this.TranscriberService.deleteTranscriber(id).subscribe(res => {
        this.getAllTranscriber(this.pageInfo.number , this.pageInfo.size);
      },err=>{
        alert('Something went wrong');
      })
    }
  }

  changePage(event){
    this.getAllTranscriber(event.pageIndex , event.pageSize)
  }

  toggleActive(event ,id){
    if(!this.loadingData){
      this.loadingData = true;
      let params = {id , isActive: event.checked ? 1 : 0}
      this.TranscriberService.toggleTranscriberActive(params).subscribe(res => {
        if(res){
          this.getAllTranscriber(this.pageInfo.number , this.pageInfo.size);
        }
      },err=>{
        alert('Something went wrong');
      })
    }
  }

  ngOnInit() {
    this.getAllTranscriber(0,20);
    this.LocalStorage.getItem('token').subscribe(token => {
      this.doctorInClinics = token.doctorInClinicIds.join(',');
    })

    this.loadingData = false;
  }

}
