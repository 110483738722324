import { Router } from '@angular/router';
import { PageRegistrationService } from './../../api/emr/services/page-registration.service';
import { Component, OnInit } from '@angular/core';
import { MeddoTitleService } from '../../services/meddo-title.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { PatientEntityService, ConsultationEntityService, AllergyEntityService, SymptomEntityService, ExistingConditionEntityService, VitalEntityService, VisitTypeEntityService } from '../../api/emr/services';
import * as async from 'async';
import { FullScreenLoaderService } from '../../full-screen-loader/full-screen-loader-service';
import { EmbeddedApiService } from '../../services/embedded-api.service';
import { ResourceConsultation } from '../../api/emr/models';
import moment from 'moment';
import { MatDialog } from '@angular/material';
import { ScanBarcodeDialogComponent } from 'src/app/shared/dialog/scan-barcode-dialog/scan-barcode-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';


export class generalInfo {
  name: string = '';
  alternateNumber: string = '';
  gender: string = '';
  dateofbirth: string = '';
  bloodGroup: string = '';
  weight: string = '';
  height: string = '';
  phone: string = '';
  existingPatient: boolean = false;
  id?: number;
  ageType?: string = '';
  age?: number = null;
}

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit {

  showMedicalInformation: boolean = false;
  showConfirmationPage: boolean = false;
  doctorInClinicIds;
  generalInformationDataLoaded: boolean = false;
  generalInformation: generalInfo = new generalInfo();
  patientSelfLink;
  patient;
  priorityQueues;
  visitTypeLink;
  consultationId: number;
  confirmationPageInfo = {
    patientName: '',
    priorityQueues: '',
    patientPhone: ''
  };
  email: any;

  printpageData;
  patientId: any;
  reqConsultationId: any;
  isuncover: boolean = false;
  reqPatientDetails: any;
  constructor(
    private meddoTitleService: MeddoTitleService,
    private patientEntityService: PatientEntityService,
    private ConsultationEntityService: ConsultationEntityService,
    private AllergyEntityService: AllergyEntityService,
    private SymptomEntityService: SymptomEntityService,
    private ExistingConditionEntityService: ExistingConditionEntityService,
    private VitalEntityService: VitalEntityService,
    private VisitTypeEntityService: VisitTypeEntityService,
    private loader: FullScreenLoaderService,
    private consultationEmbeddedApiService: EmbeddedApiService<ResourceConsultation>,
    private localStorage: LocalStorage,
    private PageRegistrationService: PageRegistrationService,
    private Router: Router,
    private MatDialog: MatDialog,
    private HttpClient: HttpClient,
    private ApiConfiguration: ApiConfiguration,
  ) { }

  backToGeneralInfo() {
    this.showMedicalInformation = false;
  }

  proceedToMedicalInformation(data) {
    this.generalInformation = data.generalInformation;
    this.patientSelfLink = data.patientLink;
    this.patientId = data.patientId;
    this.patient = data.patient;
    this.consultationId = data.consultationId;
    this.generalInformationDataLoaded = true;
    this.showMedicalInformation = true;
  }

  addAnotherPatient() {
    this.showMedicalInformation = false;
    this.showConfirmationPage = false;
    this.generalInformationDataLoaded = false;
    this.generalInformation = new generalInfo();
    this.patientSelfLink = null;
    this.patientId = null;
    this.patient = null;
    this.priorityQueues = null;
    this.consultationId = null;
    this.confirmationPageInfo = {
      patientName: '',
      priorityQueues: '',
      patientPhone: ''
    };
  }

  saveRemainingDetails(consultationLink, medicalInfoData) {

    let parallelCalls = medicalInfoData.allergies.map(allergy =>
      callback => {
        this.AllergyEntityService.saveAllergyUsingPOST({
          consultation: consultationLink,
          isCritical: allergy.isCritical,
          allergyType: allergy._links.self.href
        })
          .subscribe(
            savedAllergy => callback(null, savedAllergy),
            err => callback(true, err)
          )
      }
    ).concat(medicalInfoData.symptoms.map(symptom =>
      callback => {
        this.SymptomEntityService.saveSymptomUsingPOST({
          consultation: consultationLink,
          isCritical: symptom.critical,
          symptomType: symptom._links.self.href
        })
          .subscribe(
            savedSymptom => callback(null, savedSymptom),
            err => callback(true, err)
          )
      }
    )).concat(medicalInfoData.preExistingConditions.map(preExistingCondition =>
      callback => {
        this.ExistingConditionEntityService.saveExistingConditionUsingPOST({
          consultation: consultationLink,
          isCritical: false,
          isActive: true,
          existingConditionType: preExistingCondition._links.self.href
        })
          .subscribe(
            savedPreExistingCondition => callback(null, savedPreExistingCondition),
            err => callback(true, err)
          )
      }
    )).concat(medicalInfoData.vitals.map(vital =>
      callback => {
        this.VitalEntityService.saveVitalUsingPOST({
          consultation: consultationLink,
          title: vital.title,
          value: vital.value,
          unit: vital.unit,
          vitalType: vital.vitalType._links.self.href
        })
          .subscribe(
            savedVital => callback(null, savedVital),
            err => callback(true, err)
          )
      }
    ));

    async.parallel(parallelCalls, (err, data) => {
      this.loader.hideLoader();
      if (!err) {
        this.confirmationPageInfo = {
          patientName: this.patient.name,
          priorityQueues: this.priorityQueues,
          patientPhone: this.patient.phone
        }

      } else {
        this.confirmationPageInfo = {
          patientName: this.patient.name,
          priorityQueues: this.priorityQueues,
          patientPhone: this.patient.phone
        }
        alert('Error while saving details, Allergies or Existing conditions or Symptoms Or Vitals may not be saved.');
      }
      this.showConfirmationPage = true;
      this.getDocPad();
    })
  }

  addPatientToQueue(medicalInfoData) {
    this.loader.showLoader();
    this.printpageData = medicalInfoData;
    if (this.consultationId) {

      this.ConsultationEntityService.saveConsultationUsingPATCH({
        id: this.consultationId,
        body: {
          status: 'CHECKED_IN',
          doctorInClinic: medicalInfoData.doctorInClinic._links.self.href
        }
      }).subscribe(updatedConsultation => {
        this.ConsultationEntityService.findByIdConsultationUsingGET(this.consultationId).subscribe(data => {
          let newConsultation = this.consultationEmbeddedApiService.parse(data);
          this.priorityQueues = newConsultation.priorityQueues;
          this.saveRemainingDetails(newConsultation._links.self.href, medicalInfoData);
        }, err => {
          if (err && err.error && err.error.message) {
            alert(err.error.message);
          } else {
            alert('Error while adding patient to queue');
          }
          this.Router.navigate(['/patient-queue']);
          alert('Error while adding patient to queue');
        })
      })
    } else {
      let isCovidSuspect = false;
      let bodyTemperature = medicalInfoData.bodyTemperature ? medicalInfoData.bodyTemperature : 'N/A';
      medicalInfoData.symptoms.some(element => {
        if ([163, 63, 1153, 355].includes(element.id)) {
          isCovidSuspect = true;
          return true;
        };
        return false;
      });
      let consultationBody: any = {
        appointmentTime: moment(new Date()).add(2, 'minutes').toISOString(), //medicalInfoData.appointmentTime?medicalInfoData.appointmentTime:
        dicId: medicalInfoData.doctorInClinic.id, //_links.self.href,
        // patient: this.patientSelfLink?this.patientSelfLink:medicalInfoData.patientSelfLink,
        patientId: medicalInfoData.patientId,
        visitType: this.visitTypeLink,
        status: 'CHECKED_IN',
        visitTypeId: 1,
        isVisited: true,
        isWalkingCheckedIn: true,
        platform: "POS",
        isOnline: false,
        clinicManagerNote: medicalInfoData.note,
        isSubscriptionApplied: medicalInfoData.applySubscription,
        isUsedFreeFollowUp: medicalInfoData.isFollowUp,
        payLaterForDeliveryMedicines: false,
        payLaterForHomeCollectionOnTests: false,
        diagnosticsDiscountPercentage: 0,
        medicinesDiscountPercentage: 0,
        bodyTemperature,
        isOtpSkipped: medicalInfoData.isOtpSkipped,
        'isCovidSuspect': (isCovidSuspect && (medicalInfoData.covidTravelHistoryState || medicalInfoData.covidContactState)),
        covidTravelHistoryState: medicalInfoData.covidTravelHistoryState,
        covidContactState: medicalInfoData.covidContactState,
        throughEprescription: false,
        createdBy: this.email,
        "createEventClinicDto": {
          "eventId": null,
          "resourceIds": null,
          "purchasePackageId": null,
          "startDateTime": moment(new Date()).add(2, 'minutes').toISOString(),
          "endDateTime": new Date(medicalInfoData.endTime).toISOString(),
          "subject": "CONSULTATION",
          "status": "CHECKED_IN",
          "eventType": "CONSULTATION"
        }
      }
      this.ConsultationEntityService.saveConsultationUsingPOSTData(consultationBody, this.email).subscribe((newConsultation: any) => {
        this.priorityQueues = newConsultation.priorityQueues;
        this.patient = medicalInfoData.patient;
        this.saveRemainingDetails(newConsultation._links.self.href, medicalInfoData);
        this.reqConsultationId = newConsultation.id;
        this.reqPatientDetails = newConsultation.patient
      }, err => {
        if (err && err.error && err.error.message) {
          alert(err.error.message);
        } else {
          alert('Error while adding patient to queue');
        }
        this.Router.navigate(['/patient-queue']);
      })
    }
  }

  getDocPad() {
    let body = {
      "doctorName": this.printpageData.doctorInClinic.doctor.name,
      "patientName": this.printpageData.patient.patientName,
      "patientAge": moment().diff(new Date(this.printpageData.patient.dob), 'years', false),
      "date": moment(this.printpageData.scheduleDate).format('DD-MM-YYYY'),
      "mobile": this.printpageData.patient.patientPhone,
      "gender": this.printpageData.patient.gender,
      "dicId": 0 || this.printpageData.doctorInClinic.id
    }
    this.PageRegistrationService.getDoctorPadPrintOutPage(body).subscribe(res => {
      //this.printPage(res);
    }, err => {
      alert('something went wrong')
    })
  }

  printPage(base64) {
    var winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
      'resizable,screenX=50,screenY=50,width=850,height=1050';

    var htmlPop = '<iframe width=100% height=100%'
      + ' type="application/pdf"'
      + ' src="data:application/pdf;base64,'
      + base64
      + '"></iframe>';
    var printWindow = window['open']("", '_blank');
    printWindow.focus();
    printWindow['document']['write'](htmlPop);
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if (isDermaHost > -1) {
      this.isuncover = true;
    }
    this.meddoTitleService.changeTitle('Add to Queue');
    this.localStorage.getItem('token').subscribe(token => {
      this.doctorInClinicIds = token && token.doctorInClinicIds;
      this.email = token.email;
    })

    this.VisitTypeEntityService.findByIdVisitTypeUsingGET(1).subscribe((visitType: any) => {
      this.visitTypeLink = visitType._links.self.href;
    })
  }

  calculateAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age);

    return age;
  }

  printPdfOfAppointment() {
    let dialogRef = this.MatDialog.open(ScanBarcodeDialogComponent, {
      maxHeight: "120vh",
      minWidth: "40vw",
      disableClose: true,
      data: {
        data: this.printpageData,
        consultationId: this.reqConsultationId,
        barcodePatientName: this.printpageData.patient.patientName,
        reqPatientDetails: this.reqPatientDetails
      },
    });

    let body = {
      "dic_id": 0 || this.printpageData.doctorInClinic.id,
      "patient_id": this.printpageData.patientId,
      "doctor_name": this.printpageData.doctorInClinic.doctor.name,
      "appointment_date": new Date(this.printpageData.appointmentTime).toLocaleString(undefined, { timeZone: 'Asia/Kolkata' }),
      "patient_name": this.printpageData.patient.patientName,
      "mobile": this.printpageData.patient.patientPhone,
      "age_gender": this.reqPatientDetails.age ? this.reqPatientDetails.age + "/" + this.printpageData.patient.gender : this.calculateAge(this.reqPatientDetails.dateofbirth) + "/" + this.printpageData.patient.gender,
      "age": this.reqPatientDetails.age,
      "gender": this.printpageData.patient.gender
    }
    this.HttpClient.post(this.ApiConfiguration.rootUrl + '/api/v1/emr/fetch/consultation/doxper/prescription-pad', body, { responseType: 'blob' }).subscribe(res => {

      var blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    }, err => {
      alert('something went wrong')
    })
  }

}
