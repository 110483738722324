/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesComment } from '../models/resources-comment-';
import { ResourceComment } from '../models/resource-comment-';
import { Comment } from '../models/comment';
import { ResourcePatientHistory } from '../models/resource-patient-history-';

/**
 * $ Proxy 182
 */
@Injectable()
class CommentEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CommentEntityService.FindAllCommentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllCommentUsingGETResponse(params: CommentEntityService.FindAllCommentUsingGETParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.FindAllCommentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllCommentUsingGET(params: CommentEntityService.FindAllCommentUsingGETParams): Observable<ResourcesComment> {
    return this.findAllCommentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveCommentUsingPOSTResponse(body: Comment): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveCommentUsingPOST(body: Comment): Observable<ResourceComment> {
    return this.saveCommentUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientHistoryId patientHistoryId
   * @return OK
   */
  countByPatientHistoryForCommentIdCommentUsingGETResponse(patientHistoryId?: number): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientHistoryId != null) __params = __params.set('patientHistoryId', patientHistoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/search/countByPatientHistoryForCommentId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({body: _body}) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param patientHistoryId patientHistoryId
   * @return OK
   */
  countByPatientHistoryForCommentIdCommentUsingGET(patientHistoryId?: number): Observable<number> {
    return this.countByPatientHistoryForCommentIdCommentUsingGETResponse(patientHistoryId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param commentIds commentIds
   * @return OK
   */
  findAllByIdInCommentUsingGETResponse(commentIds?: Array<number>): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (commentIds || []).forEach((val, index) => {if (val != null) __params = __params.append('commentIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/search/findAllByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param commentIds commentIds
   * @return OK
   */
  findAllByIdInCommentUsingGET(commentIds?: Array<number>): Observable<ResourcesComment> {
    return this.findAllByIdInCommentUsingGETResponse(commentIds).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientHistoryId patientHistoryId
   * @return OK
   */
  findByPatientHistoryForCommentIdCommentUsingGETResponse(patientHistoryId?: number): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientHistoryId != null) __params = __params.set('patientHistoryId', patientHistoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/search/findByPatientHistoryForCommentId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param patientHistoryId patientHistoryId
   * @return OK
   */
  findByPatientHistoryForCommentIdCommentUsingGET(patientHistoryId?: number): Observable<ResourcesComment> {
    return this.findByPatientHistoryForCommentIdCommentUsingGETResponse(patientHistoryId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  findByPatientHistoryForCommentPatientIdCommentUsingGETResponse(patientId?: number): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/search/findByPatientHistoryForCommentPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  findByPatientHistoryForCommentPatientIdCommentUsingGET(patientId?: number): Observable<ResourcesComment> {
    return this.findByPatientHistoryForCommentPatientIdCommentUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdCommentUsingGETResponse(id: number): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdCommentUsingGET(id: number): Observable<ResourceComment> {
    return this.findByIdCommentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.SaveCommentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentUsingPUTResponse(params: CommentEntityService.SaveCommentUsingPUTParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/comments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.SaveCommentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentUsingPUT(params: CommentEntityService.SaveCommentUsingPUTParams): Observable<ResourceComment> {
    return this.saveCommentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteCommentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/comments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteCommentUsingDELETE(id: number): Observable<void> {
    return this.deleteCommentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.SaveCommentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentUsingPATCHResponse(params: CommentEntityService.SaveCommentUsingPATCHParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/comments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.SaveCommentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentUsingPATCH(params: CommentEntityService.SaveCommentUsingPATCHParams): Observable<ResourceComment> {
    return this.saveCommentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentCommentsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/${id}/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentCommentsUsingGET_1(id: number): Observable<ResourcesComment> {
    return this.commentCommentsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentCommentsUsingPOSTResponse(params: CommentEntityService.CommentCommentsUsingPOSTParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentCommentsUsingPOST(params: CommentEntityService.CommentCommentsUsingPOSTParams): Observable<ResourcesComment> {
    return this.commentCommentsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentCommentsUsingPUTResponse(params: CommentEntityService.CommentCommentsUsingPUTParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentCommentsUsingPUT(params: CommentEntityService.CommentCommentsUsingPUTParams): Observable<ResourcesComment> {
    return this.commentCommentsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  commentCommentsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/comments/${id}/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  commentCommentsUsingDELETE_1(id: number): Observable<void> {
    return this.commentCommentsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentCommentsUsingPATCHResponse(params: CommentEntityService.CommentCommentsUsingPATCHParams): Observable<HttpResponse<ResourcesComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/comments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesComment = null;
        _body = _resp.body as ResourcesComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentCommentsUsingPATCH(params: CommentEntityService.CommentCommentsUsingPATCHParams): Observable<ResourcesComment> {
    return this.commentCommentsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   *
   * @return OK
   */
  commentCommentsUsingGETResponse(params: CommentEntityService.CommentCommentsUsingGETParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/comments/${params.commentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   *
   * @return OK
   */
  commentCommentsUsingGET(params: CommentEntityService.CommentCommentsUsingGETParams): Observable<ResourceComment> {
    return this.commentCommentsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   */
  commentCommentsUsingDELETEResponse(params: CommentEntityService.CommentCommentsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/comments/${params.commentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentCommentsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentId`: commentId
   */
  commentCommentsUsingDELETE(params: CommentEntityService.CommentCommentsUsingDELETEParams): Observable<void> {
    return this.commentCommentsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentParentCommentUsingGETResponse(id: number): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/${id}/parentComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentParentCommentUsingGET(id: number): Observable<ResourceComment> {
    return this.commentParentCommentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentParentCommentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentParentCommentUsingPOSTResponse(params: CommentEntityService.CommentParentCommentUsingPOSTParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/parentComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentParentCommentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentParentCommentUsingPOST(params: CommentEntityService.CommentParentCommentUsingPOSTParams): Observable<ResourceComment> {
    return this.commentParentCommentUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentParentCommentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentParentCommentUsingPUTResponse(params: CommentEntityService.CommentParentCommentUsingPUTParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/parentComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentParentCommentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentParentCommentUsingPUT(params: CommentEntityService.CommentParentCommentUsingPUTParams): Observable<ResourceComment> {
    return this.commentParentCommentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  commentParentCommentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/comments/${id}/parentComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  commentParentCommentUsingDELETE(id: number): Observable<void> {
    return this.commentParentCommentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentParentCommentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentParentCommentUsingPATCHResponse(params: CommentEntityService.CommentParentCommentUsingPATCHParams): Observable<HttpResponse<ResourceComment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/parentComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceComment = null;
        _body = _resp.body as ResourceComment;
        return _resp.clone({body: _body}) as HttpResponse<ResourceComment>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentParentCommentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentParentCommentUsingPATCH(params: CommentEntityService.CommentParentCommentUsingPATCHParams): Observable<ResourceComment> {
    return this.commentParentCommentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentPatientHistoryForCommentUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comments/${id}/patientHistoryForComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentPatientHistoryForCommentUsingGET(id: number): Observable<ResourcePatientHistory> {
    return this.commentPatientHistoryForCommentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentPatientHistoryForCommentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentPatientHistoryForCommentUsingPOSTResponse(params: CommentEntityService.CommentPatientHistoryForCommentUsingPOSTParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/patientHistoryForComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentPatientHistoryForCommentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentPatientHistoryForCommentUsingPOST(params: CommentEntityService.CommentPatientHistoryForCommentUsingPOSTParams): Observable<ResourcePatientHistory> {
    return this.commentPatientHistoryForCommentUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentPatientHistoryForCommentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentPatientHistoryForCommentUsingPUTResponse(params: CommentEntityService.CommentPatientHistoryForCommentUsingPUTParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/patientHistoryForComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentPatientHistoryForCommentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentPatientHistoryForCommentUsingPUT(params: CommentEntityService.CommentPatientHistoryForCommentUsingPUTParams): Observable<ResourcePatientHistory> {
    return this.commentPatientHistoryForCommentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  commentPatientHistoryForCommentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/comments/${id}/patientHistoryForComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  commentPatientHistoryForCommentUsingDELETE(id: number): Observable<void> {
    return this.commentPatientHistoryForCommentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentEntityService.CommentPatientHistoryForCommentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentPatientHistoryForCommentUsingPATCHResponse(params: CommentEntityService.CommentPatientHistoryForCommentUsingPATCHParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/comments/${params.id}/patientHistoryForComment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentEntityService.CommentPatientHistoryForCommentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentPatientHistoryForCommentUsingPATCH(params: CommentEntityService.CommentPatientHistoryForCommentUsingPATCHParams): Observable<ResourcePatientHistory> {
    return this.commentPatientHistoryForCommentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module CommentEntityService {

  /**
   * Parameters for findAllCommentUsingGET
   */
  export interface FindAllCommentUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveCommentUsingPUT
   */
  export interface SaveCommentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Comment;
  }

  /**
   * Parameters for saveCommentUsingPATCH
   */
  export interface SaveCommentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Comment;
  }

  /**
   * Parameters for commentCommentsUsingPOST
   */
  export interface CommentCommentsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentCommentsUsingPUT
   */
  export interface CommentCommentsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentCommentsUsingPATCH
   */
  export interface CommentCommentsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentCommentsUsingGET
   */
  export interface CommentCommentsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * commentId
     */
    commentId: string;
  }

  /**
   * Parameters for commentCommentsUsingDELETE
   */
  export interface CommentCommentsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * commentId
     */
    commentId: string;
  }

  /**
   * Parameters for commentParentCommentUsingPOST
   */
  export interface CommentParentCommentUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentParentCommentUsingPUT
   */
  export interface CommentParentCommentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentParentCommentUsingPATCH
   */
  export interface CommentParentCommentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentPatientHistoryForCommentUsingPOST
   */
  export interface CommentPatientHistoryForCommentUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentPatientHistoryForCommentUsingPUT
   */
  export interface CommentPatientHistoryForCommentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentPatientHistoryForCommentUsingPATCH
   */
  export interface CommentPatientHistoryForCommentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { CommentEntityService }
