import { ApiConfiguration } from './api-configuration';
import { TranscriberService } from './transcriber.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';

@NgModule({
  imports: [
    CommonModule,
    HttpModule
  ],
  exports: [HttpModule],
  declarations: [],
  providers:[
    ApiConfiguration,
    TranscriberService
  ]
})
export class ApiModule { }
