/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { ResourcesPatient } from "../models/resources-patient-";
import { ResourcePatient } from "../models/resource-patient-";
import { Patient } from "../models/patient";
import { Resourcesstring } from "../models/resources-string-";
import { ResourcesAddress } from "../models/resources-address-";
import { ResourceAddress } from "../models/resource-address-";
import { ResourcesCommentOnDocument } from "../models/resources-comment-on-document-";
import { ResourceCommentOnDocument } from "../models/resource-comment-on-document-";
import { ResourcesConsultation } from "../models/resources-consultation-";
import { ResourceConsultation } from "../models/resource-consultation-";
import { ResourceFamily } from "../models/resource-family-";
import { ResourcesPatientHistory } from "../models/resources-patient-history-";
import { ResourcePatientHistory } from "../models/resource-patient-history-";
import { ResourcesPatientSymptom } from "../models/resources-patient-symptom-";
import { ResourcePatientSymptom } from "../models/resource-patient-symptom-";
import { ResourcesPrescriptionByDate } from "../models/resources-prescription-by-date-";
import { ResourcePrescriptionByDate } from "../models/resource-prescription-by-date-";

/**
 * $ Proxy 161
 */
@Injectable()
class PatientEntityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `PatientEntityService.FindAllPatientUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */

  findAllPatientUsingGETResponse(
    params: PatientEntityService.FindAllPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindAllPatientUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPatientUsingGET(
    params: PatientEntityService.FindAllPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findAllPatientUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientUsingPOSTResponse(
    body: Patient
  ): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patient/add-family-member`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePatientUsingPOST(body: Patient): Observable<ResourcePatient> {
    return this.savePatientUsingPOSTResponse(body).pipe(map((_r) => _r.body));
  }

  /**
   * @param code code
   * @return OK
   */
  findByCodeInPatientUsingGETResponse(
    code?: Array<string>
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (code || []).forEach((val, index) => {
      if (val != null) __params = __params.append("code", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/search/findByCodeIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param code code
   * @return OK
   */
  findByCodeInPatientUsingGET(
    code?: Array<string>
  ): Observable<ResourcesPatient> {
    return this.findByCodeInPatientUsingGETResponse(code).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationDoctorInClinicIdInPatientUsingGETResponse(
    id?: number
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set("id", id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findByConsultationDoctorInClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByConsultationDoctorInClinicIdInPatientUsingGET(
    id?: number
  ): Observable<ResourcesPatient> {
    return this.findByConsultationDoctorInClinicIdInPatientUsingGETResponse(
      id
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   * @return OK
   */
  findByFamilyIdPatientUsingGETResponse(
    id?: number
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set("id", id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/search/findByFamilyId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByFamilyIdPatientUsingGET(id?: number): Observable<ResourcesPatient> {
    return this.findByFamilyIdPatientUsingGETResponse(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param ids ids
   * @return OK
   */
  findByFamilyIdInPatientUsingGETResponse(
    ids?: Array<number>
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/search/findByFamilyIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param ids ids
   * @return OK
   */
  findByFamilyIdInPatientUsingGET(
    ids?: Array<number>
  ): Observable<ResourcesPatient> {
    return this.findByFamilyIdInPatientUsingGETResponse(ids).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdInPatientUsingGETResponse(
    id?: Array<number>
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (id || []).forEach((val, index) => {
      if (val != null) __params = __params.append("id", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/search/findByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdInPatientUsingGET(id?: Array<number>): Observable<ResourcesPatient> {
    return this.findByIdInPatientUsingGETResponse(id).pipe(
      map((_r) => _r.body)
    );
  }

  findByIdPatientUsingGETResponse(
    id?: any
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   * https://emr.uat.meddo.tech/api/v1/emr/consultations/334242?projection=consultations%27
   */
  findByIdPatientUsingGET(id?: any): Observable<ResourcesPatient> {
    return this.findByIdPatientUsingGETResponse(id).pipe(map((_r) => _r.body));
  }

  findByIdConsultationUsingGETResponse(
    id?: any
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    if (id != null) __params = __params.set("projection", "consultations");
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/consultations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdConsultationUsingGET(id?: any): Observable<ResourcesPatient> {
    return this.findByIdConsultationUsingGETResponse(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.FindByNamePatientUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNamePatientUsingGETResponse(
    params: PatientEntityService.FindByNamePatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    let req = new HttpRequest<any>(
      "GET",

      this.rootUrl + `/api/v1/emr/patients/search/findByName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindByNamePatientUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNamePatientUsingGET(
    params: PatientEntityService.FindByNamePatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findByNamePatientUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param ids ids
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInPatientUsingGETResponse(
    ids?: Array<number>
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param ids ids
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInPatientUsingGET(
    ids?: Array<number>
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicIdInPatientUsingGETResponse(
      ids
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGET(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGET(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `ids`: ids
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGET(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param name name
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingPatientUsingGETResponse(
    name?: string
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set("name", name.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param name name
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingPatientUsingGET(
    name?: string
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicNameContainingPatientUsingGETResponse(
      name
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGET(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `name`: name
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `name`: name
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGET(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqual`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams` containing the following parameters:
   *
   * - `name`: name
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGET(
    params: PatientEntityService.FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByNameContainingOrPhoneContainingPatientUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByNameContainingOrPhoneContainingPatientUsingGETResponse(
    params: PatientEntityService.FindDistinctByNameContainingOrPhoneContainingPatientUsingGETParams
  ): Observable<HttpResponse<ResourcesPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.phone != null)
      __params = __params.set("phone", params.phone.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());

    let req = new HttpRequest<any>(
      "GET",

      this.rootUrl +
        `/api/v1/emr/patients/search/findDistinctByNameContainingOrPhoneContaining?projection=patientForNameSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatient = null;
        _body = _resp.body as ResourcesPatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.FindDistinctByNameContainingOrPhoneContainingPatientUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findDistinctByNameContainingOrPhoneContainingPatientUsingGET(
    params: PatientEntityService.FindDistinctByNameContainingOrPhoneContainingPatientUsingGETParams
  ): Observable<ResourcesPatient> {
    return this.findDistinctByNameContainingOrPhoneContainingPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @return OK
   */
  getAllCodesPatientUsingGETResponse(): Observable<
    HttpResponse<Resourcesstring>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/search/getAllCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesstring = null;
        _body = _resp.body as Resourcesstring;
        return _resp.clone({ body: _body }) as HttpResponse<Resourcesstring>;
      })
    );
  }

  /**
   * @return OK
   */
  getAllCodesPatientUsingGET(): Observable<Resourcesstring> {
    return this.getAllCodesPatientUsingGETResponse().pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.SavePatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientUsingPUTResponse(
    params: PatientEntityService.SavePatientUsingPUTParams
  ): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.SavePatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientUsingPUT(
    params: PatientEntityService.SavePatientUsingPUTParams
  ): Observable<ResourcePatient> {
    return this.savePatientUsingPUTResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   */
  deletePatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePatientUsingDELETE(id: number): Observable<void> {
    return this.deletePatientUsingDELETEResponse(id).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.SavePatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientUsingPATCHResponse(
    params: PatientEntityService.SavePatientUsingPATCHParams
  ): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.SavePatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePatientUsingPATCH(
    params: PatientEntityService.SavePatientUsingPATCHParams
  ): Observable<ResourcePatient> {
    return this.savePatientUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  editPatientPATCH(params) {
    return this.http.patch(
      this.rootUrl + `/api/v1/emr/patient/edit/${params.id}`,
      params.body
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientAddressesUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientAddressesUsingGET_1(id: number): Observable<ResourcesAddress> {
    return this.patientAddressesUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientAddressesUsingPOSTResponse(
    params: PatientEntityService.PatientAddressesUsingPOSTParams
  ): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientAddressesUsingPOST(
    params: PatientEntityService.PatientAddressesUsingPOSTParams
  ): Observable<ResourcesAddress> {
    return this.patientAddressesUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientAddressesUsingPUTResponse(
    params: PatientEntityService.PatientAddressesUsingPUTParams
  ): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientAddressesUsingPUT(
    params: PatientEntityService.PatientAddressesUsingPUTParams
  ): Observable<ResourcesAddress> {
    return this.patientAddressesUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientAddressesUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientAddressesUsingDELETE_1(id: number): Observable<void> {
    return this.patientAddressesUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientAddressesUsingPATCHResponse(
    params: PatientEntityService.PatientAddressesUsingPATCHParams
  ): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientAddressesUsingPATCH(
    params: PatientEntityService.PatientAddressesUsingPATCHParams
  ): Observable<ResourcesAddress> {
    return this.patientAddressesUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `addressId`: addressId
   *
   * @return OK
   */
  patientAddressesUsingGETResponse(
    params: PatientEntityService.PatientAddressesUsingGETParams
  ): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/addresses/${params.addressId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `addressId`: addressId
   *
   * @return OK
   */
  patientAddressesUsingGET(
    params: PatientEntityService.PatientAddressesUsingGETParams
  ): Observable<ResourceAddress> {
    return this.patientAddressesUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `addressId`: addressId
   */
  patientAddressesUsingDELETEResponse(
    params: PatientEntityService.PatientAddressesUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/addresses/${params.addressId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientAddressesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `addressId`: addressId
   */
  patientAddressesUsingDELETE(
    params: PatientEntityService.PatientAddressesUsingDELETEParams
  ): Observable<void> {
    return this.patientAddressesUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientCommentOnDocumentsUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCommentOnDocument = null;
        _body = _resp.body as ResourcesCommentOnDocument;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesCommentOnDocument>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientCommentOnDocumentsUsingGET_1(
    id: number
  ): Observable<ResourcesCommentOnDocument> {
    return this.patientCommentOnDocumentsUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingPOSTResponse(
    params: PatientEntityService.PatientCommentOnDocumentsUsingPOSTParams
  ): Observable<HttpResponse<ResourcesCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCommentOnDocument = null;
        _body = _resp.body as ResourcesCommentOnDocument;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingPOST(
    params: PatientEntityService.PatientCommentOnDocumentsUsingPOSTParams
  ): Observable<ResourcesCommentOnDocument> {
    return this.patientCommentOnDocumentsUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingPUTResponse(
    params: PatientEntityService.PatientCommentOnDocumentsUsingPUTParams
  ): Observable<HttpResponse<ResourcesCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCommentOnDocument = null;
        _body = _resp.body as ResourcesCommentOnDocument;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingPUT(
    params: PatientEntityService.PatientCommentOnDocumentsUsingPUTParams
  ): Observable<ResourcesCommentOnDocument> {
    return this.patientCommentOnDocumentsUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientCommentOnDocumentsUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientCommentOnDocumentsUsingDELETE_1(id: number): Observable<void> {
    return this.patientCommentOnDocumentsUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingPATCHResponse(
    params: PatientEntityService.PatientCommentOnDocumentsUsingPATCHParams
  ): Observable<HttpResponse<ResourcesCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCommentOnDocument = null;
        _body = _resp.body as ResourcesCommentOnDocument;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingPATCH(
    params: PatientEntityService.PatientCommentOnDocumentsUsingPATCHParams
  ): Observable<ResourcesCommentOnDocument> {
    return this.patientCommentOnDocumentsUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentondocumentId`: commentondocumentId
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingGETResponse(
    params: PatientEntityService.PatientCommentOnDocumentsUsingGETParams
  ): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/commentOnDocuments/${params.commentondocumentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentondocumentId`: commentondocumentId
   *
   * @return OK
   */
  patientCommentOnDocumentsUsingGET(
    params: PatientEntityService.PatientCommentOnDocumentsUsingGETParams
  ): Observable<ResourceCommentOnDocument> {
    return this.patientCommentOnDocumentsUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentondocumentId`: commentondocumentId
   */
  patientCommentOnDocumentsUsingDELETEResponse(
    params: PatientEntityService.PatientCommentOnDocumentsUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/commentOnDocuments/${params.commentondocumentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientCommentOnDocumentsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `commentondocumentId`: commentondocumentId
   */
  patientCommentOnDocumentsUsingDELETE(
    params: PatientEntityService.PatientCommentOnDocumentsUsingDELETEParams
  ): Observable<void> {
    return this.patientCommentOnDocumentsUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientConsultationUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientConsultationUsingGET_1(id: number): Observable<ResourcesConsultation> {
    return this.patientConsultationUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientConsultationUsingPOSTResponse(
    params: PatientEntityService.PatientConsultationUsingPOSTParams
  ): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientConsultationUsingPOST(
    params: PatientEntityService.PatientConsultationUsingPOSTParams
  ): Observable<ResourcesConsultation> {
    return this.patientConsultationUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientConsultationUsingPUTResponse(
    params: PatientEntityService.PatientConsultationUsingPUTParams
  ): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientConsultationUsingPUT(
    params: PatientEntityService.PatientConsultationUsingPUTParams
  ): Observable<ResourcesConsultation> {
    return this.patientConsultationUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientConsultationUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientConsultationUsingDELETE_1(id: number): Observable<void> {
    return this.patientConsultationUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientConsultationUsingPATCHResponse(
    params: PatientEntityService.PatientConsultationUsingPATCHParams
  ): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientConsultationUsingPATCH(
    params: PatientEntityService.PatientConsultationUsingPATCHParams
  ): Observable<ResourcesConsultation> {
    return this.patientConsultationUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  patientConsultationUsingGETResponse(
    params: PatientEntityService.PatientConsultationUsingGETParams
  ): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/consultation/${params.consultationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  patientConsultationUsingGET(
    params: PatientEntityService.PatientConsultationUsingGETParams
  ): Observable<ResourceConsultation> {
    return this.patientConsultationUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   */
  patientConsultationUsingDELETEResponse(
    params: PatientEntityService.PatientConsultationUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/consultation/${params.consultationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientConsultationUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   */
  patientConsultationUsingDELETE(
    params: PatientEntityService.PatientConsultationUsingDELETEParams
  ): Observable<void> {
    return this.patientConsultationUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientFamilyUsingGETResponse(
    id: number
  ): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientFamilyUsingGET(id: number): Observable<ResourceFamily> {
    return this.patientFamilyUsingGETResponse(id).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.PatientFamilyUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientFamilyUsingPOSTResponse(
    params: PatientEntityService.PatientFamilyUsingPOSTParams
  ): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientFamilyUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientFamilyUsingPOST(
    params: PatientEntityService.PatientFamilyUsingPOSTParams
  ): Observable<ResourceFamily> {
    return this.patientFamilyUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientFamilyUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientFamilyUsingPUTResponse(
    params: PatientEntityService.PatientFamilyUsingPUTParams
  ): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientFamilyUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientFamilyUsingPUT(
    params: PatientEntityService.PatientFamilyUsingPUTParams
  ): Observable<ResourceFamily> {
    return this.patientFamilyUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientFamilyUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientFamilyUsingDELETE(id: number): Observable<void> {
    return this.patientFamilyUsingDELETEResponse(id).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `PatientEntityService.PatientFamilyUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientFamilyUsingPATCHResponse(
    params: PatientEntityService.PatientFamilyUsingPATCHParams
  ): Observable<HttpResponse<ResourceFamily>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFamily = null;
        _body = _resp.body as ResourceFamily;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceFamily>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientFamilyUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientFamilyUsingPATCH(
    params: PatientEntityService.PatientFamilyUsingPATCHParams
  ): Observable<ResourceFamily> {
    return this.patientFamilyUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPatientHistoriesUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPatientHistoriesUsingGET_1(
    id: number
  ): Observable<ResourcesPatientHistory> {
    return this.patientPatientHistoriesUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientHistoriesUsingPOSTResponse(
    params: PatientEntityService.PatientPatientHistoriesUsingPOSTParams
  ): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientHistoriesUsingPOST(
    params: PatientEntityService.PatientPatientHistoriesUsingPOSTParams
  ): Observable<ResourcesPatientHistory> {
    return this.patientPatientHistoriesUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientHistoriesUsingPUTResponse(
    params: PatientEntityService.PatientPatientHistoriesUsingPUTParams
  ): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientHistoriesUsingPUT(
    params: PatientEntityService.PatientPatientHistoriesUsingPUTParams
  ): Observable<ResourcesPatientHistory> {
    return this.patientPatientHistoriesUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientPatientHistoriesUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientPatientHistoriesUsingDELETE_1(id: number): Observable<void> {
    return this.patientPatientHistoriesUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientHistoriesUsingPATCHResponse(
    params: PatientEntityService.PatientPatientHistoriesUsingPATCHParams
  ): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientHistoriesUsingPATCH(
    params: PatientEntityService.PatientPatientHistoriesUsingPATCHParams
  ): Observable<ResourcesPatientHistory> {
    return this.patientPatientHistoriesUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   *
   * @return OK
   */
  patientPatientHistoriesUsingGETResponse(
    params: PatientEntityService.PatientPatientHistoriesUsingGETParams
  ): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/patientHistories/${params.patienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   *
   * @return OK
   */
  patientPatientHistoriesUsingGET(
    params: PatientEntityService.PatientPatientHistoriesUsingGETParams
  ): Observable<ResourcePatientHistory> {
    return this.patientPatientHistoriesUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   */
  patientPatientHistoriesUsingDELETEResponse(
    params: PatientEntityService.PatientPatientHistoriesUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/patientHistories/${params.patienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   */
  patientPatientHistoriesUsingDELETE(
    params: PatientEntityService.PatientPatientHistoriesUsingDELETEParams
  ): Observable<void> {
    return this.patientPatientHistoriesUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPatientSymptomUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/patientSymptom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPatientSymptomUsingGET_1(
    id: number
  ): Observable<ResourcesPatientSymptom> {
    return this.patientPatientSymptomUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientSymptomUsingPOSTResponse(
    params: PatientEntityService.PatientPatientSymptomUsingPOSTParams
  ): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/patientSymptom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientSymptomUsingPOST(
    params: PatientEntityService.PatientPatientSymptomUsingPOSTParams
  ): Observable<ResourcesPatientSymptom> {
    return this.patientPatientSymptomUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientSymptomUsingPUTResponse(
    params: PatientEntityService.PatientPatientSymptomUsingPUTParams
  ): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/patientSymptom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientSymptomUsingPUT(
    params: PatientEntityService.PatientPatientSymptomUsingPUTParams
  ): Observable<ResourcesPatientSymptom> {
    return this.patientPatientSymptomUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientPatientSymptomUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/patientSymptom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientPatientSymptomUsingDELETE_1(id: number): Observable<void> {
    return this.patientPatientSymptomUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientSymptomUsingPATCHResponse(
    params: PatientEntityService.PatientPatientSymptomUsingPATCHParams
  ): Observable<HttpResponse<ResourcesPatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/patientSymptom`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientSymptom = null;
        _body = _resp.body as ResourcesPatientSymptom;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPatientSymptomUsingPATCH(
    params: PatientEntityService.PatientPatientSymptomUsingPATCHParams
  ): Observable<ResourcesPatientSymptom> {
    return this.patientPatientSymptomUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingGETParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  patientPatientSymptomUsingGETResponse(
    params: PatientEntityService.PatientPatientSymptomUsingGETParams
  ): Observable<HttpResponse<ResourcePatientSymptom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/patientSymptom/${params.patientsymptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientSymptom = null;
        _body = _resp.body as ResourcePatientSymptom;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcePatientSymptom>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingGETParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   *
   * @return OK
   */
  patientPatientSymptomUsingGET(
    params: PatientEntityService.PatientPatientSymptomUsingGETParams
  ): Observable<ResourcePatientSymptom> {
    return this.patientPatientSymptomUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingDELETEParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   */
  patientPatientSymptomUsingDELETEResponse(
    params: PatientEntityService.PatientPatientSymptomUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/patientSymptom/${params.patientsymptomId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPatientSymptomUsingDELETEParams` containing the following parameters:
   *
   * - `patientsymptomId`: patientsymptomId
   *
   * - `id`: id
   */
  patientPatientSymptomUsingDELETE(
    params: PatientEntityService.PatientPatientSymptomUsingDELETEParams
  ): Observable<void> {
    return this.patientPatientSymptomUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPrescriptionByDatesUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/emr/patients/${id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  patientPrescriptionByDatesUsingGET_1(
    id: number
  ): Observable<ResourcesPrescriptionByDate> {
    return this.patientPrescriptionByDatesUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingPOSTResponse(
    params: PatientEntityService.PatientPrescriptionByDatesUsingPOSTParams
  ): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingPOST(
    params: PatientEntityService.PatientPrescriptionByDatesUsingPOSTParams
  ): Observable<ResourcesPrescriptionByDate> {
    return this.patientPrescriptionByDatesUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingPUTResponse(
    params: PatientEntityService.PatientPrescriptionByDatesUsingPUTParams
  ): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingPUT(
    params: PatientEntityService.PatientPrescriptionByDatesUsingPUTParams
  ): Observable<ResourcesPrescriptionByDate> {
    return this.patientPrescriptionByDatesUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  patientPrescriptionByDatesUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/emr/patients/${id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  patientPrescriptionByDatesUsingDELETE_1(id: number): Observable<void> {
    return this.patientPrescriptionByDatesUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingPATCHResponse(
    params: PatientEntityService.PatientPrescriptionByDatesUsingPATCHParams
  ): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/emr/patients/${params.id}/prescriptionByDates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingPATCH(
    params: PatientEntityService.PatientPrescriptionByDatesUsingPATCHParams
  ): Observable<ResourcesPrescriptionByDate> {
    return this.patientPrescriptionByDatesUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingGETResponse(
    params: PatientEntityService.PatientPrescriptionByDatesUsingGETParams
  ): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/prescriptionByDates/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({
          body: _body,
        }) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  patientPrescriptionByDatesUsingGET(
    params: PatientEntityService.PatientPrescriptionByDatesUsingGETParams
  ): Observable<ResourcePrescriptionByDate> {
    return this.patientPrescriptionByDatesUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  patientPrescriptionByDatesUsingDELETEResponse(
    params: PatientEntityService.PatientPrescriptionByDatesUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
        `/api/v1/emr/patients/${params.id}/prescriptionByDates/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PatientEntityService.PatientPrescriptionByDatesUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  patientPrescriptionByDatesUsingDELETE(
    params: PatientEntityService.PatientPrescriptionByDatesUsingDELETEParams
  ): Observable<void> {
    return this.patientPrescriptionByDatesUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }
}

module PatientEntityService {
  /**
   * Parameters for findAllPatientUsingGET
   */
  export interface FindAllPatientUsingGETParams {
    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByNamePatientUsingGET
   */
  export interface FindByNamePatientUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGET
   */
  export interface FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams {
    /**
     * startDate
     */
    startDate?: string;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGET
   */
  export interface FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams {
    /**
     * startDate
     */
    startDate?: string;

    /**
     * ids
     */
    ids?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGET
   */
  export interface FindDistinctByConsultationDoctorInClinicClinicIdInAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams {
    /**
     * ids
     */
    ids?: Array<number>;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGET
   */
  export interface FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualPatientUsingGETParams {
    /**
     * startDate
     */
    startDate?: string;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGET
   */
  export interface FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeGreaterThanEqualAndConsultationAppointmentTimeLessThanPatientUsingGETParams {
    /**
     * startDate
     */
    startDate?: string;

    /**
     * name
     */
    name?: string;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGET
   */
  export interface FindDistinctByConsultationDoctorInClinicClinicNameContainingAndConsultationAppointmentTimeLessThanEqualPatientUsingGETParams {
    /**
     * name
     */
    name?: string;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findDistinctByNameContainingOrPhoneContainingPatientUsingGET
   */
  export interface FindDistinctByNameContainingOrPhoneContainingPatientUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for savePatientUsingPUT
   */
  export interface SavePatientUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Patient;
  }

  /**
   * Parameters for savePatientUsingPATCH
   */
  export interface SavePatientUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Patient;
  }

  /**
   * Parameters for patientAddressesUsingPOST
   */
  export interface PatientAddressesUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientAddressesUsingPUT
   */
  export interface PatientAddressesUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientAddressesUsingPATCH
   */
  export interface PatientAddressesUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientAddressesUsingGET
   */
  export interface PatientAddressesUsingGETParams {
    /**
     * id
     */
    id: number;

    /**
     * addressId
     */
    addressId: string;
  }

  /**
   * Parameters for patientAddressesUsingDELETE
   */
  export interface PatientAddressesUsingDELETEParams {
    /**
     * id
     */
    id: number;

    /**
     * addressId
     */
    addressId: string;
  }

  /**
   * Parameters for patientCommentOnDocumentsUsingPOST
   */
  export interface PatientCommentOnDocumentsUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientCommentOnDocumentsUsingPUT
   */
  export interface PatientCommentOnDocumentsUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientCommentOnDocumentsUsingPATCH
   */
  export interface PatientCommentOnDocumentsUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientCommentOnDocumentsUsingGET
   */
  export interface PatientCommentOnDocumentsUsingGETParams {
    /**
     * id
     */
    id: number;

    /**
     * commentondocumentId
     */
    commentondocumentId: string;
  }

  /**
   * Parameters for patientCommentOnDocumentsUsingDELETE
   */
  export interface PatientCommentOnDocumentsUsingDELETEParams {
    /**
     * id
     */
    id: number;

    /**
     * commentondocumentId
     */
    commentondocumentId: string;
  }

  /**
   * Parameters for patientConsultationUsingPOST
   */
  export interface PatientConsultationUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientConsultationUsingPUT
   */
  export interface PatientConsultationUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientConsultationUsingPATCH
   */
  export interface PatientConsultationUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientConsultationUsingGET
   */
  export interface PatientConsultationUsingGETParams {
    /**
     * id
     */
    id: number;

    /**
     * consultationId
     */
    consultationId: string;
  }

  /**
   * Parameters for patientConsultationUsingDELETE
   */
  export interface PatientConsultationUsingDELETEParams {
    /**
     * id
     */
    id: number;

    /**
     * consultationId
     */
    consultationId: string;
  }

  /**
   * Parameters for patientFamilyUsingPOST
   */
  export interface PatientFamilyUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientFamilyUsingPUT
   */
  export interface PatientFamilyUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientFamilyUsingPATCH
   */
  export interface PatientFamilyUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for patientPatientHistoriesUsingPOST
   */
  export interface PatientPatientHistoriesUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPatientHistoriesUsingPUT
   */
  export interface PatientPatientHistoriesUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPatientHistoriesUsingPATCH
   */
  export interface PatientPatientHistoriesUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPatientHistoriesUsingGET
   */
  export interface PatientPatientHistoriesUsingGETParams {
    /**
     * patienthistoryId
     */
    patienthistoryId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for patientPatientHistoriesUsingDELETE
   */
  export interface PatientPatientHistoriesUsingDELETEParams {
    /**
     * patienthistoryId
     */
    patienthistoryId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for patientPatientSymptomUsingPOST
   */
  export interface PatientPatientSymptomUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPatientSymptomUsingPUT
   */
  export interface PatientPatientSymptomUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPatientSymptomUsingPATCH
   */
  export interface PatientPatientSymptomUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPatientSymptomUsingGET
   */
  export interface PatientPatientSymptomUsingGETParams {
    /**
     * patientsymptomId
     */
    patientsymptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for patientPatientSymptomUsingDELETE
   */
  export interface PatientPatientSymptomUsingDELETEParams {
    /**
     * patientsymptomId
     */
    patientsymptomId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for patientPrescriptionByDatesUsingPOST
   */
  export interface PatientPrescriptionByDatesUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPrescriptionByDatesUsingPUT
   */
  export interface PatientPrescriptionByDatesUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPrescriptionByDatesUsingPATCH
   */
  export interface PatientPrescriptionByDatesUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for patientPrescriptionByDatesUsingGET
   */
  export interface PatientPrescriptionByDatesUsingGETParams {
    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for patientPrescriptionByDatesUsingDELETE
   */
  export interface PatientPrescriptionByDatesUsingDELETEParams {
    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }
}

export { PatientEntityService };
