import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageRegistrationService {

  constructor(
    private HttpClient: HttpClient,
    private ApiConfiguration: ApiConfiguration
  ) { }

  setDoctorPadPagePadding(body) {
    return this.HttpClient.post(this.ApiConfiguration.rootUrl + '/api/v1/emr/configuration/padding-configuration', body);
  }

  getDoctorPadPrintOutPage(body) {
    return this.HttpClient.post(environment.templateAPI + '/doctor-pad/download/pdf', body, { responseType: 'text' });
  }

  getPageDimension(params) {
    return this.HttpClient.get(this.ApiConfiguration.rootUrl + '/api/v1/emr/configuration/pad/by/dicId', { params })
  }

  getIsPineLabAvailable(params) {
    return this.HttpClient.get(this.ApiConfiguration.rootUrl + '/api/v1/emr/configuration/lab', { params });
  }

  getIsPaytmAvailable(params) {
    return this.HttpClient.get(this.ApiConfiguration.rootUrl + '/api/v1/emr/configuration/bydic/keyname', { params });
  }

  getPdfOfAppointment(body) {
    return this.HttpClient.post(this.ApiConfiguration.rootUrl + '/api/v1/emr/fetch/consultation/doxper/prescription-pad', body);
  }
}
