/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesFollowup } from '../models/resources-followup-';
import { ResourceFollowup } from '../models/resource-followup-';
import { Followup } from '../models/followup';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 194
 */
@Injectable()
class FollowupEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `FollowupEntityService.FindAllFollowupUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllFollowupUsingGETResponse(params: FollowupEntityService.FindAllFollowupUsingGETParams): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.FindAllFollowupUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllFollowupUsingGET(params: FollowupEntityService.FindAllFollowupUsingGETParams): Observable<ResourcesFollowup> {
    return this.findAllFollowupUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveFollowupUsingPOSTResponse(body: Followup): Observable<HttpResponse<ResourceFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/followups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFollowup = null;
        _body = _resp.body as ResourceFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFollowup>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveFollowupUsingPOST(body: Followup): Observable<ResourceFollowup> {
    return this.saveFollowupUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.FindByConsultationIdFollowupUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdFollowupUsingGETResponse(params: FollowupEntityService.FindByConsultationIdFollowupUsingGETParams): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/followups/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.FindByConsultationIdFollowupUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdFollowupUsingGET(params: FollowupEntityService.FindByConsultationIdFollowupUsingGETParams): Observable<ResourcesFollowup> {
    return this.findByConsultationIdFollowupUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.FindByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETResponse(params: FollowupEntityService.FindByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETParams): Observable<HttpResponse<ResourcesFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/followups/search/findByFollowupDateGreaterThanEqualAndFollowupDateLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowup = null;
        _body = _resp.body as ResourcesFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowup>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.FindByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGET(params: FollowupEntityService.FindByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETParams): Observable<ResourcesFollowup> {
    return this.findByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdFollowupUsingGETResponse(id: number): Observable<HttpResponse<ResourceFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/followups/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFollowup = null;
        _body = _resp.body as ResourceFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFollowup>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdFollowupUsingGET(id: number): Observable<ResourceFollowup> {
    return this.findByIdFollowupUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.SaveFollowupUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFollowupUsingPUTResponse(params: FollowupEntityService.SaveFollowupUsingPUTParams): Observable<HttpResponse<ResourceFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/followups/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFollowup = null;
        _body = _resp.body as ResourceFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFollowup>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.SaveFollowupUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFollowupUsingPUT(params: FollowupEntityService.SaveFollowupUsingPUTParams): Observable<ResourceFollowup> {
    return this.saveFollowupUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteFollowupUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/followups/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteFollowupUsingDELETE(id: number): Observable<void> {
    return this.deleteFollowupUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.SaveFollowupUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFollowupUsingPATCHResponse(params: FollowupEntityService.SaveFollowupUsingPATCHParams): Observable<HttpResponse<ResourceFollowup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/followups/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceFollowup = null;
        _body = _resp.body as ResourceFollowup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceFollowup>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.SaveFollowupUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveFollowupUsingPATCH(params: FollowupEntityService.SaveFollowupUsingPATCHParams): Observable<ResourceFollowup> {
    return this.saveFollowupUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  followupConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/followups/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  followupConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.followupConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.FollowupConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  followupConsultationUsingPOSTResponse(params: FollowupEntityService.FollowupConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/followups/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.FollowupConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  followupConsultationUsingPOST(params: FollowupEntityService.FollowupConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.followupConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.FollowupConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  followupConsultationUsingPUTResponse(params: FollowupEntityService.FollowupConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/followups/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.FollowupConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  followupConsultationUsingPUT(params: FollowupEntityService.FollowupConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.followupConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  followupConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/followups/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  followupConsultationUsingDELETE(id: number): Observable<void> {
    return this.followupConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `FollowupEntityService.FollowupConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  followupConsultationUsingPATCHResponse(params: FollowupEntityService.FollowupConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/followups/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `FollowupEntityService.FollowupConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  followupConsultationUsingPATCH(params: FollowupEntityService.FollowupConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.followupConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module FollowupEntityService {

  /**
   * Parameters for findAllFollowupUsingGET
   */
  export interface FindAllFollowupUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdFollowupUsingGET
   */
  export interface FindByConsultationIdFollowupUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for findByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGET
   */
  export interface FindByFollowupDateGreaterThanEqualAndFollowupDateLessThanFollowupUsingGETParams {

    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for saveFollowupUsingPUT
   */
  export interface SaveFollowupUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Followup;
  }

  /**
   * Parameters for saveFollowupUsingPATCH
   */
  export interface SaveFollowupUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Followup;
  }

  /**
   * Parameters for followupConsultationUsingPOST
   */
  export interface FollowupConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for followupConsultationUsingPUT
   */
  export interface FollowupConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for followupConsultationUsingPATCH
   */
  export interface FollowupConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { FollowupEntityService }
