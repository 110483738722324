import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../login/service/authentication.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { FullScreenLoaderService } from "../../full-screen-loader/full-screen-loader-service";
import { Router } from "@angular/router";
import { LocalStorage } from "@ngx-pwa/local-storage";
import {MatSnackBar, MatDialogRef, MatBottomSheetRef, MatBottomSheet} from '@angular/material';
import {GoogleLoginProvider} from 'angularx-social-login';
import {AuthService} from 'angularx-social-login';
import {LoginOpt} from 'angularx-social-login/src/auth.service';
import {CustomSnackbarComponent} from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent implements OnInit {
  returnUrl: any;
  username: any;
  password: any;
  showSpinner: Boolean = false;
  loginForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private authenticationService: AuthenticationService,
    private loader: FullScreenLoaderService,
    private router: Router,
    private localStorage: LocalStorage,
    private matSnackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
     // code: ["", Validators.required],
    });
  }

  login(userData) {
    this.showSpinner = true;
    this.dialogRef.close("loggged in");
    this.authenticationService
      .login(
        this.loginForm.get("username").value,
        this.loginForm.get("password").value
      )
      .subscribe(
        (response: any) => {
          this.localStorage.getItem("token").subscribe((token) => {
            if (token) {
              let allowedRoles = [
                "ASSISTANT",
                "ROLE_ADMIN",
                "BACK_OFFICE",
                "CAMP_ASSISTANT",
              ];
              if (token.authorities.includes("ROLE_API")) {
                this.localStorage.clear().subscribe(() => {
                  this.matSnackBar.open("This user is not allowed", "Cancel", {
                    duration: 2000,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                  });
                  this.router.navigateByUrl("/login");
                });
              }
              if (
                !allowedRoles.filter((role) => token.authorities.includes(role))
                  .length
              ) {
                alert("This user is not allowed in POS");
                this.localStorage.clear().subscribe(() => {
                  this.router.navigateByUrl("/login");
                });
              } else {
                token.loginTime = new Date();
                if (token.authorities.includes("ASSISTANT")) {
                  this.authenticationService.fetchClinicId(token).subscribe((responseClinic) => {
                    if (responseClinic) {
                      token.clinicId = responseClinic.json();
                  this.authenticationService
                    .verifyUserV1(userData, token.userID, token.clinicId)
                    .subscribe(
                      (response: any) => {
                        if (response) {
                          token.userEmail = token.email;
                          token.email = response.gmailId;
                          if (response.loginInfoId) {
                            token.loginInfoId = response.loginInfoId;
                          }
                          this.localStorage.setItem("token", token).subscribe({
                            complete: () => this.dialogRef.close("logged in"),
                          });
                          this.bottomSheet.dismiss();
                          this.matSnackBar.dismiss();
                        } else {
                          this.authService.signOut(true);
                          this.dialogRef.close("logout");
                          this.router.navigateByUrl("/login");
                        }
                      },
                      (err) => {
                        this.bottomSheet.dismiss();
                        this.authService.signOut(true);
                        this.dialogRef.close("logout");
                        this.localStorage.clear().subscribe(() => {
                          alert("Invalid Authentication.");
                          this.router.navigateByUrl("/login");
                        });
                      }
                    );
                }
              });
                } else {
                  this.dialogRef.close("logged in");
                }
              }
            } else {
              this.dialogRef.close("logged in");
            }
          });
          this.showSpinner = false;
        },
        (err) => {
          let error = err.json();
          this.showSpinner = false;
          if (error.error === "invalid_grant") {
            window.alert("Invalid username or password");
          }
          this.loader.hideLoader();
        }
      );
  }

  googleSignIn() {
    this.authService.signOut(true);
    const socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.authService.signIn(socialPlatformProvider, {prompt: 'consent'} as LoginOpt).then((userData) => {
      if (userData.idToken) {
        this.login(userData);
      } else {
        alert('Not able login in google.');
        return;
      }
    } );
  }
}
