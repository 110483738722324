/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs";
import { map, filter } from "rxjs/operators";

import { ResourcesOrder } from "../models/resources-order-";
import { ResourceOrder } from "../models/resource-order-";
import { Order } from "../models/order";
import { Resourcesstring } from "../models/resources-string-";
import { ResourcesOrderItem } from "../models/resources-order-item-";
import { ResourceOrderItem } from "../models/resource-order-item-";
import { ResourcesPayment } from "../models/resources-payment-";
import { ResourcePayment } from "../models/resource-payment-";

/**
 * $ Proxy 172
 */
@Injectable()
class OrderEntityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `OrderEntityService.FindAllOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderUsingGETResponse(
    params: OrderEntityService.FindAllOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindAllOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderUsingGET(
    params: OrderEntityService.FindAllOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findAllOrderUsingGETResponse(params).pipe(map((_r) => _r.body));
  }

  cancelOrderUsingGETResponse(
    params
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __params = __params.set("orderId", params.orderId.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/refund-order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `params` containing the following parameters:
   *
   * - `orderId`: orderId
   *
   * @return OK
   */
  cancelOrderUsingGET(params): Observable<ResourcesOrder> {
    return this.cancelOrderUsingGETResponse(params).pipe(map((_r) => _r.body));
  }

  cancelConsultationUsingGETResponse(
    params
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.consultationId != null)
      __params = __params.set(
        "consultationId",
        params.consultationId.toString()
      );
    if (params.orderId != null)
      __params = __params.set("orderId", params.orderId.toString());
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/cancel-consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `params` containing the following parameters:
   *
   * - `orderId`: orderId
   *
   * @return OK
   */
  cancelConsultationUsingGET(params): Observable<ResourcesOrder> {
    return this.cancelConsultationUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderUsingPOSTResponse(
    body: Order
  ): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderUsingPOST(body: Order): Observable<ResourceOrder> {
    return this.saveOrderUsingPOSTResponse(body).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.CountDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  countDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETResponse(
    params: OrderEntityService.CountDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETParams
  ): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.year != null)
      __params = __params.set("year", params.year.toString());
    if (params.year != null)
      __params = __params.set("year", params.year.toString());
    if (params.timezoneOffset != null)
      __params = __params.set(
        "timezoneOffset",
        params.timezoneOffset.toString()
      );
    if (params.timezoneOffset != null)
      __params = __params.set(
        "timezoneOffset",
        params.timezoneOffset.toString()
      );
    if (params.time != null)
      __params = __params.set("time", params.time.toString());
    if (params.time != null)
      __params = __params.set("time", params.time.toString());
    if (params.seconds != null)
      __params = __params.set("seconds", params.seconds.toString());
    if (params.seconds != null)
      __params = __params.set("seconds", params.seconds.toString());
    if (params.nanos != null)
      __params = __params.set("nanos", params.nanos.toString());
    if (params.nanos != null)
      __params = __params.set("nanos", params.nanos.toString());
    if (params.month != null)
      __params = __params.set("month", params.month.toString());
    if (params.month != null)
      __params = __params.set("month", params.month.toString());
    if (params.minutes != null)
      __params = __params.set("minutes", params.minutes.toString());
    if (params.minutes != null)
      __params = __params.set("minutes", params.minutes.toString());
    if (params.hours != null)
      __params = __params.set("hours", params.hours.toString());
    if (params.hours != null)
      __params = __params.set("hours", params.hours.toString());
    if (params.doctorInClinicId != null)
      __params = __params.set(
        "doctorInClinicId",
        params.doctorInClinicId.toString()
      );
    if (params.day != null)
      __params = __params.set("day", params.day.toString());
    if (params.day != null)
      __params = __params.set("day", params.day.toString());
    if (params.date != null)
      __params = __params.set("date", params.date.toString());
    if (params.date != null)
      __params = __params.set("date", params.date.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/countDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({ body: _body }) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.CountDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  countDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGET(
    params: OrderEntityService.CountDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETParams
  ): Observable<number> {
    return this.countDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FetchAntiFilteredConsultationOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchAntiFilteredConsultationOrdersOrderUsingGETResponse(
    params: OrderEntityService.FetchAntiFilteredConsultationOrdersOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach((val, index) => {
      if (val != null) __params = __params.append("statuses", val.toString());
    });
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.patientId != null)
      __params = __params.set("patientId", params.patientId.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/fetchAntiFilteredConsultationOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FetchAntiFilteredConsultationOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchAntiFilteredConsultationOrdersOrderUsingGET(
    params: OrderEntityService.FetchAntiFilteredConsultationOrdersOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.fetchAntiFilteredConsultationOrdersOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FetchAntiFilteredOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientIds`: patientIds
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchAntiFilteredOrdersOrderUsingGETResponse(
    params: OrderEntityService.FetchAntiFilteredOrdersOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach((val, index) => {
      if (val != null) __params = __params.append("statuses", val.toString());
    });
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    (params.patientIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append("patientIds", val.toString());
    });
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/search/fetchAntiFilteredOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FetchAntiFilteredOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientIds`: patientIds
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchAntiFilteredOrdersOrderUsingGET(
    params: OrderEntityService.FetchAntiFilteredOrdersOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.fetchAntiFilteredOrdersOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.FetchFilteredConsultationOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchFilteredConsultationOrdersOrderUsingGETResponse(
    params: OrderEntityService.FetchFilteredConsultationOrdersOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach((val, index) => {
      if (val != null) __params = __params.append("statuses", val.toString());
    });
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.patientId != null)
      __params = __params.set("patientId", params.patientId.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/fetchFilteredConsultationOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FetchFilteredConsultationOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchFilteredConsultationOrdersOrderUsingGET(
    params: OrderEntityService.FetchFilteredConsultationOrdersOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.fetchFilteredConsultationOrdersOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FetchFilteredOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientIds`: patientIds
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchFilteredOrdersOrderUsingGETResponse(
    params: OrderEntityService.FetchFilteredOrdersOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach((val, index) => {
      if (val != null) __params = __params.append("statuses", val.toString());
    });
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    (params.patientIds || []).forEach((val, index) => {
      if (val != null) __params = __params.append("patientIds", val.toString());
    });
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    (params.ids || []).forEach((val, index) => {
      if (val != null) __params = __params.append("ids", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/search/fetchFilteredOrders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FetchFilteredOrdersOrderUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientIds`: patientIds
   *
   * - `page`:
   *
   * - `ids`: ids
   *
   * @return OK
   */
  fetchFilteredOrdersOrderUsingGET(
    params: OrderEntityService.FetchFilteredOrdersOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.fetchFilteredOrdersOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.FindByConsultationIdOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdOrderUsingGETResponse(
    params: OrderEntityService.FindByConsultationIdOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.consultationId != null)
      __params = __params.set(
        "consultationId",
        params.consultationId.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByConsultationId?projection=orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  findByConsultationIdOrderUsingGETResponseFindOrder(
    params: OrderEntityService.FindByConsultationIdOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.consultationId != null)
      __params = __params.set(
        "consultationId",
        params.consultationId.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/find-order?projection=orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByConsultationIdOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdOrderUsingGET(
    params: OrderEntityService.FindByConsultationIdOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByConsultationIdOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByConsultationIdOrderUsingGETFindOrder(
    params: OrderEntityService.FindByConsultationIdOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByConsultationIdOrderUsingGETResponseFindOrder(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.FindByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETResponse(
    params: OrderEntityService.FindByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGET(
    params: OrderEntityService.FindByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdOrderUsingGETResponse(
    params: OrderEntityService.FindByDoctorInClinicIdOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.doctorInClinicId != null)
      __params = __params.set(
        "doctorInClinicId",
        params.doctorInClinicId.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/search/findByDoctorInClinicId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdOrderUsingGET(
    params: OrderEntityService.FindByDoctorInClinicIdOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByDoctorInClinicIdOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdInOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderUsingGETResponse(
    params: OrderEntityService.FindByDoctorInClinicIdInOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    //(params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    //if (params.doctorInClinicId != null && params.doctorInClinicId.length) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/by/dic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdInOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderUsingGET(
    params: OrderEntityService.FindByDoctorInClinicIdInOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByDoctorInClinicIdInOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETResponse(
    params: OrderEntityService.FindByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toString());
    //(params.doctorInClinics || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinics', val.toString())});
    if (params.doctorInClinics != null && params.doctorInClinics.length)
      __params = __params.set(
        "doctorInClinics",
        params.doctorInClinics.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByDoctorInClinicIdInAndCreatedAtBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorInClinics`: doctorInClinics
   *
   * @return OK
   */
  findByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGET(
    params: OrderEntityService.FindByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETResponse(
    params: OrderEntityService.FindByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    // (params.doctorInClinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicId', val.toString())});
    if (params.doctorInClinicId != null && params.doctorInClinicId.length)
      __params = __params.set(
        "doctorInClinicId",
        params.doctorInClinicId.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByDoctorInClinicIdInOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * @return OK
   */
  findByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGET(
    params: OrderEntityService.FindByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FindByMobileNoContainingOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * @return OK
   */
  findByMobileNoContainingOrderByCreatedAtDescOrderUsingGETResponse(
    params: OrderEntityService.FindByMobileNoContainingOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.phone != null)
      __params = __params.set("phone", params.phone.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByMobileNoContainingOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByMobileNoContainingOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `phone`: phone
   *
   * - `page`:
   *
   * @return OK
   */
  findByMobileNoContainingOrderByCreatedAtDescOrderUsingGET(
    params: OrderEntityService.FindByMobileNoContainingOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByMobileNoContainingOrderByCreatedAtDescOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FindByMobileNoInOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `mob`: mob
   *
   * @return OK
   */
  findByMobileNoInOrderUsingGETResponse(
    params: OrderEntityService.FindByMobileNoInOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.mob != null)
      __params = __params.set("mob", params.mob.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByMobileNoIn?projection=ordersForSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  findByMobileNoInOrderUsingGETResponseFindOrder(
    params: OrderEntityService.FindByMobileNoInOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.mob != null)
      __params = __params.set("phoneNumber", params.mob.toString());
    if (params.patientId != null)
      __params = __params.set("patientId", params.patientId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/fetch-orders-for-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByMobileNoInOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `mob`: mob
   *
   * @return OK
   */
  findByMobileNoInOrderUsingGET(
    params: OrderEntityService.FindByMobileNoInOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByMobileNoInOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByMobileNoInOrderUsingGETFindOrder(
    params: OrderEntityService.FindByMobileNoInOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByMobileNoInOrderUsingGETResponseFindOrder(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.FindDistinctByPhoneContainingPatientUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `phoneNumber`: phoneNumber
   *
   * @return OK
   */
  findDistinctByPhoneContainingPatientUsingGETResponse(
    params: OrderEntityService.FindDistinctByPhoneContainingPatientUsingGETParams
  ): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.phone != null)
      __params = __params.set("phoneNumber", params.phone.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/fetch-patients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );
    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }
  /**
   * @param params The `OrderEntityService.FindDistinctByPhoneContainingPatientUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `phoneNumber`: phoneNumber
   *
   * @return OK
   */
  findDistinctByPhoneContainingPatientUsingGET(
    params: OrderEntityService.FindDistinctByPhoneContainingPatientUsingGETParams
  ): Observable<any> {
    return this.findDistinctByPhoneContainingPatientUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FindByMobileNoInOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `mob`: mob
   *
   * @return OK
   */
  findByMobileNoInOrderByCreatedAtDescOrderUsingGETResponse(
    params: OrderEntityService.FindByMobileNoInOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.mob != null)
      __params = __params.set("mob", params.mob.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findByMobileNoInOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByMobileNoInOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `mob`: mob
   *
   * @return OK
   */
  findByMobileNoInOrderByCreatedAtDescOrderUsingGET(
    params: OrderEntityService.FindByMobileNoInOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByMobileNoInOrderByCreatedAtDescOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.FindByPatientNameContainingOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByPatientNameContainingOrderByCreatedAtDescOrderUsingGETResponse(
    params: OrderEntityService.FindByPatientNameContainingOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.name != null)
      __params = __params.set("name", params.name.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/find-order?projection=ordersForSearch&sort=createdAt%2Cdesc&page=${params.page}&patientName=${params.name}`,
      // this.rootUrl + `/api/v1/order/orders/search/findByPatientNameContainingOrderByCreatedAtDesc`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  CancelOrderItemsWithOrderIdAndReasonId(
    orderItemCancellationData: any
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();

    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/cancel-order-item`,

      orderItemCancellationData,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.FindByPatientNameContainingOrderByCreatedAtDescOrderUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByPatientNameContainingOrderByCreatedAtDescOrderUsingGET(
    params: OrderEntityService.FindByPatientNameContainingOrderByCreatedAtDescOrderUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findByPatientNameContainingOrderByCreatedAtDescOrderUsingGETResponse(
      params
    ).pipe(map((_r) => _r.body));
  }

  findAllOrderIdsByConsultationIdUsingGETResponse(
    params: OrderEntityService.FindAllOrderIdsUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.consultationId != null)
      __params = __params.set(
        "consultationId",
        params.consultationId.toString()
      );

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/fetch-order-id?consultationId=${params.consultationId}`,

      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }
  findConsultationIdByOrderIdUsingGETResponse(
    params: OrderEntityService.FindConsultationIdUsingGETParams
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.OrderId != null)
      __params = __params.set("consultationId", params.OrderId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/get-order-details?orderId=${params.OrderId}`,

      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  findAllOrderIdsUsingGET(
    params: OrderEntityService.FindAllOrderIdsUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findAllOrderIdsByConsultationIdUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }
  findConsulatationIdByOrderIdUsingGET(
    params: OrderEntityService.FindConsultationIdUsingGETParams
  ): Observable<ResourcesOrder> {
    return this.findConsultationIdByOrderIdUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByStarAndEndDateByUsingGETResponse(
    params
  ): Observable<HttpResponse<ResourcesOrder>> {
    const offset = params.startDate.getTimezoneOffset();
    params.startDate = new Date(
      params.startDate.getTime() - offset * 60 * 1000
    );
    params.endDate = new Date(params.endDate.getTime() - offset * 60 * 1000);

    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/find-order-within-dates?startDate=${params.startDate.toISOString().split("T")[0]
      }&endDate=${params.endDate.toISOString().split("T")[0]}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  findByStarEndDateByUsingGET(params): Observable<ResourcesOrder> {
    return this.findByStarAndEndDateByUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param consultationId consultationId
   * @return OK
   */
  findDistinctByConsultationIdOrderUsingGETResponse(
    consultationId?: number
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (consultationId != null)
      __params = __params.set("consultationId", consultationId.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/search/findDistinctByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param consultationId consultationId
   * @return OK
   */
  findDistinctByConsultationIdOrderUsingGET(
    consultationId?: number
  ): Observable<ResourcesOrder> {
    return this.findDistinctByConsultationIdOrderUsingGETResponse(
      consultationId
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param consultationIds consultationIds
   * @return OK
   */
  findDistinctByConsultationIdInOrderUsingGETResponse(
    consultationIds?: Array<number>
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (consultationIds || []).forEach((val, index) => {
      if (val != null)
        __params = __params.append("consultationIds", val.toString());
    });
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/search/findDistinctByConsultationIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }
  /**
   * @param orderItemCancellationData orderItemCancellationData
   * @return OK
   */
  CancelOrderItem(orderItemCancellationData: any): Observable<ResourcesOrder> {
    return this.CancelOrderItemsWithOrderIdAndReasonId(
      orderItemCancellationData
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param consultationIds consultationIds
   * @return OK
   */
  findDistinctByConsultationIdInOrderUsingGET(
    consultationIds?: Array<number>
  ): Observable<ResourcesOrder> {
    return this.findDistinctByConsultationIdInOrderUsingGETResponse(
      consultationIds
    ).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   * @return OK
   */
  findOrdersThroughIdOrderUsingGETResponse(
    id?: number
  ): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set("id", id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/search/findOrdersThroughId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findOrdersThroughIdOrderUsingGET(id?: number): Observable<ResourceOrder> {
    return this.findOrdersThroughIdOrderUsingGETResponse(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @return OK
   */
  getAllCodesOrderUsingGETResponse(): Observable<
    HttpResponse<Resourcesstring>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/search/getAllCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesstring = null;
        _body = _resp.body as Resourcesstring;
        return _resp.clone({ body: _body }) as HttpResponse<Resourcesstring>;
      })
    );
  }

  /**
   * @return OK
   */
  getAllCodesOrderUsingGET(): Observable<Resourcesstring> {
    return this.getAllCodesOrderUsingGETResponse().pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderUsingGETResponse(
    id: number
  ): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/${id}?projection=orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderUsingGET(id: number): Observable<ResourceOrder> {
    return this.findByIdOrderUsingGETResponse(id).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.SaveOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderUsingPUTResponse(
    params: OrderEntityService.SaveOrderUsingPUTParams
  ): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/order/orders/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.SaveOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderUsingPUT(
    params: OrderEntityService.SaveOrderUsingPUTParams
  ): Observable<ResourceOrder> {
    return this.saveOrderUsingPUTResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   */
  deleteOrderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/order/orders/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteOrderUsingDELETE(id: number): Observable<void> {
    return this.deleteOrderUsingDELETEResponse(id).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.SaveOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderUsingPATCHResponse(
    params: OrderEntityService.SaveOrderUsingPATCHParams
  ): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/order/orders/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.SaveOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderUsingPATCH(
    params: OrderEntityService.SaveOrderUsingPATCHParams
  ): Observable<ResourceOrder> {
    return this.saveOrderUsingPATCHResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   * @return OK
   */
  orderOrderItemsUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/${id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderOrderItemsUsingGET_1(id: number): Observable<ResourcesOrderItem> {
    return this.orderOrderItemsUsingGET_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderOrderItemsUsingPOSTResponse(
    params: OrderEntityService.OrderOrderItemsUsingPOSTParams
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/orders/${params.id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderOrderItemsUsingPOST(
    params: OrderEntityService.OrderOrderItemsUsingPOSTParams
  ): Observable<ResourcesOrderItem> {
    return this.orderOrderItemsUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderOrderItemsUsingPUTResponse(
    params: OrderEntityService.OrderOrderItemsUsingPUTParams
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/order/orders/${params.id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderOrderItemsUsingPUT(
    params: OrderEntityService.OrderOrderItemsUsingPUTParams
  ): Observable<ResourcesOrderItem> {
    return this.orderOrderItemsUsingPUTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderOrderItemsUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/order/orders/${id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderOrderItemsUsingDELETE_1(id: number): Observable<void> {
    return this.orderOrderItemsUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderOrderItemsUsingPATCHResponse(
    params: OrderEntityService.OrderOrderItemsUsingPATCHParams
  ): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/order/orders/${params.id}/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderOrderItemsUsingPATCH(
    params: OrderEntityService.OrderOrderItemsUsingPATCHParams
  ): Observable<ResourcesOrderItem> {
    return this.orderOrderItemsUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingGETParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderOrderItemsUsingGETResponse(
    params: OrderEntityService.OrderOrderItemsUsingGETParams
  ): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/${params.id}/orderItems/${params.orderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingGETParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderOrderItemsUsingGET(
    params: OrderEntityService.OrderOrderItemsUsingGETParams
  ): Observable<ResourceOrderItem> {
    return this.orderOrderItemsUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingDELETEParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   */
  orderOrderItemsUsingDELETEResponse(
    params: OrderEntityService.OrderOrderItemsUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
      `/api/v1/order/orders/${params.id}/orderItems/${params.orderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderOrderItemsUsingDELETEParams` containing the following parameters:
   *
   * - `orderitemId`: orderitemId
   *
   * - `id`: id
   */
  orderOrderItemsUsingDELETE(
    params: OrderEntityService.OrderOrderItemsUsingDELETEParams
  ): Observable<void> {
    return this.orderOrderItemsUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderPaymentUsingGET_1Response(
    id: number
  ): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/orders/${id}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderPaymentUsingGET_1(id: number): Observable<ResourcesPayment> {
    return this.orderPaymentUsingGET_1Response(id).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderPaymentUsingPOSTResponse(
    params: OrderEntityService.OrderPaymentUsingPOSTParams
  ): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/api/v1/order/orders/${params.id}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderPaymentUsingPOST(
    params: OrderEntityService.OrderPaymentUsingPOSTParams
  ): Observable<ResourcesPayment> {
    return this.orderPaymentUsingPOSTResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderPaymentUsingPUTResponse(
    params: OrderEntityService.OrderPaymentUsingPUTParams
  ): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PUT",
      this.rootUrl + `/api/v1/order/orders/${params.id}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderPaymentUsingPUT(
    params: OrderEntityService.OrderPaymentUsingPUTParams
  ): Observable<ResourcesPayment> {
    return this.orderPaymentUsingPUTResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * @param id id
   */
  orderPaymentUsingDELETE_1Response(
    id: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/api/v1/order/orders/${id}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderPaymentUsingDELETE_1(id: number): Observable<void> {
    return this.orderPaymentUsingDELETE_1Response(id).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderPaymentUsingPATCHResponse(
    params: OrderEntityService.OrderPaymentUsingPATCHParams
  ): Observable<HttpResponse<ResourcesPayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/api/v1/order/orders/${params.id}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPayment = null;
        _body = _resp.body as ResourcesPayment;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesPayment>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderPaymentUsingPATCH(
    params: OrderEntityService.OrderPaymentUsingPATCHParams
  ): Observable<ResourcesPayment> {
    return this.orderPaymentUsingPATCHResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingGETParams` containing the following parameters:
   *
   * - `paymentId`: paymentId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderPaymentUsingGETResponse(
    params: OrderEntityService.OrderPaymentUsingGETParams
  ): Observable<HttpResponse<ResourcePayment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
      `/api/v1/order/orders/${params.id}/payment/${params.paymentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePayment = null;
        _body = _resp.body as ResourcePayment;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcePayment>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingGETParams` containing the following parameters:
   *
   * - `paymentId`: paymentId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderPaymentUsingGET(
    params: OrderEntityService.OrderPaymentUsingGETParams
  ): Observable<ResourcePayment> {
    return this.orderPaymentUsingGETResponse(params).pipe(map((_r) => _r.body));
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingDELETEParams` containing the following parameters:
   *
   * - `paymentId`: paymentId
   *
   * - `id`: id
   */
  orderPaymentUsingDELETEResponse(
    params: OrderEntityService.OrderPaymentUsingDELETEParams
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl +
      `/api/v1/order/orders/${params.id}/payment/${params.paymentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `OrderEntityService.OrderPaymentUsingDELETEParams` containing the following parameters:
   *
   * - `paymentId`: paymentId
   *
   * - `id`: id
   */
  orderPaymentUsingDELETE(
    params: OrderEntityService.OrderPaymentUsingDELETEParams
  ): Observable<void> {
    return this.orderPaymentUsingDELETEResponse(params).pipe(
      map((_r) => _r.body)
    );
  }
}

module OrderEntityService {
  /**
   * Parameters for findAllOrderUsingGET
   */
  export interface FindAllOrderUsingGETParams {
    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for countDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGET
   */
  export interface CountDistinctByCreatedAtGreaterThanEqualAndCreatedAtLessThanAndDoctorInClinicIdOrderUsingGETParams {
    year?: number;
    timezoneOffset?: number;
    time?: number;
    seconds?: number;
    nanos?: number;
    month?: number;
    minutes?: number;
    hours?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
    day?: number;
    date?: number;
  }

  /**
   * Parameters for fetchAntiFilteredConsultationOrdersOrderUsingGET
   */
  export interface FetchAntiFilteredConsultationOrdersOrderUsingGETParams {
    /**
     * statuses
     */
    statuses?: Array<
      | "PENDING"
      | "PARTIALLY_PAID"
      | "PAID"
      | "PAY_LATER"
      | "PROCESSING"
      | "PROCESSED"
      | "COMPLETED"
      | "CANCELLED"
      | "REFUNDED"
    >;
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for fetchAntiFilteredOrdersOrderUsingGET
   */
  export interface FetchAntiFilteredOrdersOrderUsingGETParams {
    /**
     * statuses
     */
    statuses?: Array<
      | "PENDING"
      | "PARTIALLY_PAID"
      | "PAID"
      | "PAY_LATER"
      | "PROCESSING"
      | "PROCESSED"
      | "COMPLETED"
      | "CANCELLED"
      | "REFUNDED"
    >;
    sort?: string;
    size?: number;

    /**
     * patientIds
     */
    patientIds?: Array<number>;
    page?: number;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for fetchFilteredConsultationOrdersOrderUsingGET
   */
  export interface FetchFilteredConsultationOrdersOrderUsingGETParams {
    /**
     * statuses
     */
    statuses?: Array<
      | "PENDING"
      | "PARTIALLY_PAID"
      | "PAID"
      | "PAY_LATER"
      | "PROCESSING"
      | "PROCESSED"
      | "COMPLETED"
      | "CANCELLED"
      | "REFUNDED"
    >;
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for fetchFilteredOrdersOrderUsingGET
   */
  export interface FetchFilteredOrdersOrderUsingGETParams {
    /**
     * statuses
     */
    statuses?: Array<
      | "PENDING"
      | "PARTIALLY_PAID"
      | "PAID"
      | "PAY_LATER"
      | "PROCESSING"
      | "PROCESSED"
      | "COMPLETED"
      | "CANCELLED"
      | "REFUNDED"
    >;
    sort?: string;
    size?: number;

    /**
     * patientIds
     */
    patientIds?: Array<number>;
    page?: number;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for findByConsultationIdOrderUsingGET
   */
  export interface FindByConsultationIdOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for findByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGET
   */
  export interface FindByCreatedAtGreaterThanEqualAndCreatedAtLessThanOrderByCreatedAtDescOrderUsingGETParams {
    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for findByDoctorInClinicIdOrderUsingGET
   */
  export interface FindByDoctorInClinicIdOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
  }

  /**
   * Parameters for findByDoctorInClinicIdInOrderUsingGET
   */
  export interface FindByDoctorInClinicIdInOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGET
   */
  export interface FindByDoctorInClinicIdInAndCreatedAtBetweenOrderUsingGETParams {
    /**
     * startDate
     */
    startDate?: string;

    /**
     * endDate
     */
    endDate?: string;

    /**
     * doctorInClinics
     */
    doctorInClinics?: Array<number>;
  }

  /**
   * Parameters for findByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGET
   */
  export interface FindByDoctorInClinicIdInOrderByCreatedAtDescOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: Array<number>;
  }

  /**
   * Parameters for findByMobileNoContainingOrderByCreatedAtDescOrderUsingGET
   */
  export interface FindByMobileNoContainingOrderByCreatedAtDescOrderUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * phone
     */
    phone?: string;
    page?: number;
  }

  /**
   * Parameters for findByMobileNoInOrderUsingGET
   */
  export interface FindByMobileNoInOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;
    patientId?: string;
    /**
     * mob
     */
    mob?: string;
  }

  /**
   * Parameters for findByMobileNoInOrderByCreatedAtDescOrderUsingGET
   */
  export interface FindByMobileNoInOrderByCreatedAtDescOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * mob
     */
    mob?: string;
  }

  /**
   * Parameters for findByPatientNameContainingOrderByCreatedAtDescOrderUsingGET
   */
  export interface FindByPatientNameContainingOrderByCreatedAtDescOrderUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }
  export interface FindAllOrderIdsUsingGETParams {
    consultationId?: string;
  }
  export interface FindConsultationIdUsingGETParams {
    OrderId?: string;
  }

  /**
   * Parameters for saveOrderUsingPUT
   */
  export interface SaveOrderUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Order;
  }

  /**
   * Parameters for saveOrderUsingPATCH
   */
  export interface SaveOrderUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Order;
  }

  /**
   * Parameters for orderOrderItemsUsingPOST
   */
  export interface OrderOrderItemsUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderOrderItemsUsingPUT
   */
  export interface OrderOrderItemsUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderOrderItemsUsingPATCH
   */
  export interface OrderOrderItemsUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderOrderItemsUsingGET
   */
  export interface OrderOrderItemsUsingGETParams {
    /**
     * orderitemId
     */
    orderitemId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for orderOrderItemsUsingDELETE
   */
  export interface OrderOrderItemsUsingDELETEParams {
    /**
     * orderitemId
     */
    orderitemId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for orderPaymentUsingPOST
   */
  export interface OrderPaymentUsingPOSTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderPaymentUsingPUT
   */
  export interface OrderPaymentUsingPUTParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderPaymentUsingPATCH
   */
  export interface OrderPaymentUsingPATCHParams {
    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderPaymentUsingGET
   */
  export interface OrderPaymentUsingGETParams {
    /**
     * paymentId
     */
    paymentId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for orderPaymentUsingDELETE
   */
  export interface OrderPaymentUsingDELETEParams {
    /**
     * paymentId
     */
    paymentId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for FindDistinctByPhoneContainingPatientUsingGET
   */
  export interface FindDistinctByPhoneContainingPatientUsingGETParams {
    sort?: string;
    size?: number;
    /**
     * phone
     */
    phone?: string;
    page?: number;
  }
}

export { OrderEntityService };
