import { Component, OnInit, EventEmitter } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import moment from "moment";
import { MatSnackBar, MatDialog } from "@angular/material";
import { generalInfo } from "src/app/layout/add-patient/add-patient.component";
import {
  PatientEntityService,
  CommonSymptomControllerService,
} from "src/app/api/emr/services";
import { MultiplePatientDialogComponent } from "../multiple-patient-dialog/multiple-patient-dialog.component";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-general-information",
  templateUrl: "./general-information.component.html",
  styleUrls: ["./general-information.component.scss"],
  outputs: ["showMedicalInformation"],
  inputs: [
    "doctorInClinicIds",
    "generalInformation",
    "dataLoaded",
    "usedBy",
    "fromAppointment",
    "consultationId",
    "usedFor"
  ],
})
export class GeneralInformationComponent implements OnInit {
  patient = new FormGroup({
    patientPhone: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]{10}$"),
    ]),
  });
  dataLoaded: boolean = false;
  fromAppointment: boolean = false;
  searchingPatientUsingPhone: boolean = false;
  doctorInClinicIds;
  generalInformation: generalInfo;
  generalInformationForm: FormGroup;
  showGeneralInformationForm: boolean = false;
  foundPatient;
  allPatientsFound = [];
  savingPatient: boolean = false;
  consultationId: number;
  usedBy;
  isuncover: boolean = false;

  titles = [
    {
      name: "Mr.",
    },
    {
      name: "Mrs.",
    },
    {
      name: "Miss",
    },
    {
      name: "Ms.",
    },
    {
      name: "Dr.",
    },
  ];

  bloodGroups = [
    {
      name: "O+ (positive)",
      value: "O+",
    },
    {
      name: "O- (negative)",
      value: "O-",
    },
    {
      name: "A+ (positive)",
      value: "A+",
    },
    {
      name: "A- (negative)",
      value: "A-",
    },
    {
      name: "B+ (positive)",
      value: "B+",
    },
    {
      name: "B- (negative)",
      value: "B-",
    },
    {
      name: "AB+ (positive)",
      value: "AB+",
    },
    {
      name: "AB- (negative)",
      value: "AB-",
    },
  ];

  showMedicalInformation = new EventEmitter();

  constructor(
    private patientEntityService: PatientEntityService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private CommonSymptomControllerService: CommonSymptomControllerService,
    private MatDialog: MatDialog
  ) { }

  getDisplayStart(start) {
    return moment(start, "HH:mm:ss").format("hh:mm A");
  }

  checkInPatient(element) {
    // if (element.status === "CHECKED_IN") {
    //   alert("This patient already in queue..!");
    // } 
    // else {
    let generalInfoFormData: generalInfo = {
      name: element.patients.name,
      alternateNumber: element.patients.alternateNumber,
      gender: element.patients.gender,
      dateofbirth: element.patients.dateofbirth,
      bloodGroup: element.patients.bloodGroup,
      weight: element.patients.weight,
      height: element.patients.height,
      phone: element.patients.phone,
      existingPatient: true,
      id: element.patients.id,
      ageType: element.patients.ageType,
      age: element.patients.age,
    };

    this.patient.get("patientPhone").setValue(element.patients.phone);

    this.generalInformationForm = this.formBuilder.group({
      name: [element.patients.name, Validators.required],
      alternateNumber: [element.patients.alternateNumber],
      gender: [element.patients.gender, Validators.required],
      dateofbirth: [element.patients.dateofbirth, Validators.required],
      bloodGroup: [element.patients.bloodGroup],
      weight: [element.patients.weight, Validators.max(999)],
      height: [element.patients.height, Validators.max(999)],
      phone: [element.patients.phone, Validators.required],
      existingPatient: [true, Validators.required],
      id: [element.patients.id, Validators.required],
      ageType: [element.patients.ageType, Validators.required],
      age: [element.patients.age, Validators.required],
      ageNumber: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(/^[0-9]{1,3}$/),
        ],
      ],
      ageSpan: ["years", Validators.required],
      dicId: [null],
    });

    this.setAge(generalInfoFormData.dateofbirth);

    this.generalInformationForm
      .get("ageNumber")
      .valueChanges.subscribe((value) => {
        this.setDateOfBirth(
          value,
          this.generalInformationForm.get("ageSpan").value
        );
      });

    this.dataLoaded = false;
    this.showGeneralInformationForm = true;
    if (element.status === "OPEN" && element.consultationId) {
      this.consultationId = element.consultationId;
    }
    //}
  }

  proceedToMedicineInformation() {
    let patientToSaveResponse;
    this.savingPatient = true;
    let generalInformation = this.generalInformationForm.getRawValue();
    if (this.generalInformationForm.get("existingPatient").value) {
      patientToSaveResponse = this.patientEntityService.savePatientUsingPATCH({
        id: generalInformation.id,
        body: generalInformation,
      });
    } else {
      this.generalInformationForm
        .get("dicId")
        .setValue(this.doctorInClinicIds[0]);
      generalInformation = this.generalInformationForm.getRawValue();
      patientToSaveResponse =
        this.patientEntityService.savePatientUsingPOST(generalInformation);
    }

    patientToSaveResponse.subscribe(
      (patient) => {
        this.showMedicalInformation.emit({
          patientLink: patient._links.self.href,
          generalInformation: generalInformation,
          id: patient.id,
          consultationId: this.consultationId,
          patient: patient,
        });
      },
      (err) => {
        if (err && err.error && err.error.message) {
          this.snackBar.open(err.error.message, "", {
            duration: 5000,
            verticalPosition: "top",
          });
          this.savingPatient = false;
        }
      }
    );
  }

  setDateOfBirth(ageNumber, ageSpan) {
    let date = moment().subtract(ageNumber, ageSpan).format("YYYY-MM-DD");
    this.generalInformationForm.get("dateofbirth").setValue(date);
    this.generalInformationForm.get("age").setValue(ageNumber);
    this.generalInformationForm.get("ageType").setValue(ageSpan);
  }

  setAge(date) {
    if (date) {
      let ageArr = moment(date).toNow(true).split(" ");

      let age = ageArr[0] === "a" ? 1 : ageArr[0];
      let ageType = ageArr[0] === "a" ? `${ageArr[1]}s` : ageArr[1];
      this.generalInformationForm.get("ageNumber").setValue(age);
      this.generalInformationForm.get("ageSpan").setValue(ageType);
    } else {
      this.generalInformationForm.get("ageNumber").reset();
      this.generalInformationForm.get("ageSpan").setValue("years");
    }
  }

  searchPatientUsingPhone() {
    this.searchingPatientUsingPhone = true;
    this.showGeneralInformationForm = false;

    this.generalInformation = new generalInfo();
    this.setFormData();
    this.setAge("");

    let patientPhone = this.patient.get("patientPhone").value;
    this.generalInformationForm.get("phone").setValue(patientPhone);

    this.CommonSymptomControllerService.patientInfoUsingGET({
      moblieno: patientPhone,
      doctorinclinic: this.doctorInClinicIds,
    }).subscribe((data: any) => {
      this.allPatientsFound = data._embedded
        ? data._embedded.patientAppointments
        : [];
      this.searchingPatientUsingPhone = false;
      this.dataLoaded = true;
    });
  }

  setFormData() {
    let patient = this.generalInformation;

    this.generalInformationForm = this.formBuilder.group({
      name: [patient.name, Validators.required],
      alternateNumber: [patient.alternateNumber],
      gender: [patient.gender, Validators.required],
      dateofbirth: [patient.dateofbirth, Validators.required],
      bloodGroup: [patient.bloodGroup],
      weight: [patient.weight, Validators.max(999)],
      height: [patient.height, Validators.max(999)],
      phone: [patient.phone, Validators.required],
      id: [patient.id],
      existingPatient: [patient.id ? true : false, Validators.required],
      ageType: [patient.ageType, Validators.required],
      age: [patient.age, Validators.required],
      ageNumber: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern(/^[0-9]{1,3}$/),
        ],
      ],
      ageSpan: ["years", Validators.required],
      dicId: [null],
    });

    this.generalInformationForm
      .get("ageNumber")
      .valueChanges.subscribe((value) => {
        this.setDateOfBirth(
          value,
          this.generalInformationForm.get("ageSpan").value
        );
      });
  }

  openMemberModal() {
    const mobileNumber = this.patient.get("patientPhone").value;
    const patients = new BehaviorSubject(
      this.allPatientsFound.map((patient) => ({
        ...patient,
        name: patient.patients.name,
        gender: patient.patients.gender,
        phone: patient.patients.phone,
        age: patient.patients.age,
      }))
    );
    let dialogRef = this.MatDialog.open(MultiplePatientDialogComponent, {
      disableClose: true,
      minWidth: "800px",
      data: {
        mobileNumber: mobileNumber,
        patients: patients,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.selectedPatient.patients) {
          this.checkInPatient(result.selectedPatient);
        } else {
          this.showGeneralInformationForm = true;
          this.dataLoaded = false;
        }
      }
    });
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if (isDermaHost > -1) {
      this.isuncover = true;
    }
    this.setFormData();
    this.patient
      .get("patientPhone")
      .setValue(this.generalInformationForm.get("phone").value);
    this.patient.get("patientPhone").valueChanges.subscribe((value) => {
      this.dataLoaded = false;
      this.showGeneralInformationForm = false;
    });

    if (this.fromAppointment) {
      this.patient.disable();
    }

    if (this.generalInformation.phone) {
      this.setAge(this.generalInformation.dateofbirth);
      this.showGeneralInformationForm = true;
      this.dataLoaded = false;
    }
  }
}
