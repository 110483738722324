import { MatDialogRef } from '@angular/material/dialog';
import { ApiConfiguration } from './../../../api/auth/api-configuration';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material';
import { TranscriberService } from 'src/app/api/auth/services/transcriber.service';

@Component({
  selector: 'app-edit-transcriber',
  templateUrl: './edit-transcriber.component.html',
  styleUrls: ['./edit-transcriber.component.scss'],
  providers: [TranscriberService , ApiConfiguration]
})
export class EditTranscriberComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FormBuilder: FormBuilder,
    private TranscriberService: TranscriberService,
    private MatDialogRef: MatDialogRef<EditTranscriberComponent>
  ) { }

  editTransciberForm: FormGroup;
  loading: Boolean = false;
  addTransciberForm: FormGroup;

  editTranscriber() {
    this.loading = true;
    let body = this.editTransciberForm.value;
    body['id'] = this.data.id;
    body['doctorInClinics'] = this.data.doctorInClinics;
    body.roles = [body.roles];
    this.TranscriberService.editTranscriberDetails(body).subscribe(res => {
      if(res){
        this.MatDialogRef.close(true);
      }
      this.loading = false;
    }, err => {
      alert('Something went wrong');
    })
  }

  addTranscriber() {
    this.loading = true;
    let body = this.addTransciberForm.value;
    body['doctorInClinics'] = this.data.doctorInClinics;
    body.roles = [body.roles];
    body['isAvailable'] = true;
    body['isDeleted'] = false;
    body['isAvailable'] = true;
    this.TranscriberService.addTranscriberDetails(body).subscribe(res => {
      this.loading = false;
      this.MatDialogRef.close(true);
    } ,err => {
      alert('Something went wrong')
    })
  }



  ngOnInit() {
    if (this.data && this.data.id) {
      this.editTransciberForm = this.FormBuilder.group({
        name: [this.data.name, Validators.required],
        roles: [this.data.roles[0].id.toString(), Validators.required],
        isActive: [this.data.isActive, Validators.required],
      });
    } else {
      this.addTransciberForm = this.FormBuilder.group({
        name: ['', Validators.required],
        email: ['', Validators.email],
        username: ['', Validators.required],
        password: ['', Validators.required],
        roles: ['', Validators.required],
        isActive: [true, Validators.required]
      })
    }
  }

}
