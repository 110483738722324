/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosticAndPatientHistory } from '../models/resources-diagnostic-and-patient-history-';
import { ResourceDiagnosticAndPatientHistory } from '../models/resource-diagnostic-and-patient-history-';
import { DiagnosticAndPatientHistory } from '../models/diagnostic-and-patient-history';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';
import { ResourcePatientHistory } from '../models/resource-patient-history-';

/**
 * $ Proxy 198
 */
@Injectable()
class DiagnosticAndPatientHistoryEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.FindAllDiagnosticAndPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticAndPatientHistoryUsingGETResponse(params: DiagnosticAndPatientHistoryEntityService.FindAllDiagnosticAndPatientHistoryUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.FindAllDiagnosticAndPatientHistoryUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticAndPatientHistoryUsingGET(params: DiagnosticAndPatientHistoryEntityService.FindAllDiagnosticAndPatientHistoryUsingGETParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.findAllDiagnosticAndPatientHistoryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticAndPatientHistoryUsingPOSTResponse(body: DiagnosticAndPatientHistory): Observable<HttpResponse<ResourceDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourceDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticAndPatientHistoryUsingPOST(body: DiagnosticAndPatientHistory): Observable<ResourceDiagnosticAndPatientHistory> {
    return this.saveDiagnosticAndPatientHistoryUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticAndPatientHistoryUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourceDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticAndPatientHistoryUsingGET(id: number): Observable<ResourceDiagnosticAndPatientHistory> {
    return this.findByIdDiagnosticAndPatientHistoryUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticAndPatientHistoryUsingPUTResponse(params: DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourceDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticAndPatientHistoryUsingPUT(params: DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPUTParams): Observable<ResourceDiagnosticAndPatientHistory> {
    return this.saveDiagnosticAndPatientHistoryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticAndPatientHistoryUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticAndPatientHistoryUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticAndPatientHistoryUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticAndPatientHistoryUsingPATCHResponse(params: DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourceDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticAndPatientHistoryUsingPATCH(params: DiagnosticAndPatientHistoryEntityService.SaveDiagnosticAndPatientHistoryUsingPATCHParams): Observable<ResourceDiagnosticAndPatientHistory> {
    return this.saveDiagnosticAndPatientHistoryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingGET(id: number): Observable<ResourceDiagnostic> {
    return this.diagnosticAndPatientHistoryDiagnosticUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingPOSTResponse(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPOSTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingPOST(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPOSTParams): Observable<ResourceDiagnostic> {
    return this.diagnosticAndPatientHistoryDiagnosticUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingPUTResponse(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPUTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingPUT(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPUTParams): Observable<ResourceDiagnostic> {
    return this.diagnosticAndPatientHistoryDiagnosticUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticAndPatientHistoryDiagnosticUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticAndPatientHistoryDiagnosticUsingDELETE(id: number): Observable<void> {
    return this.diagnosticAndPatientHistoryDiagnosticUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingPATCHResponse(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPATCHParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}/diagnostic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryDiagnosticUsingPATCH(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryDiagnosticUsingPATCHParams): Observable<ResourceDiagnostic> {
    return this.diagnosticAndPatientHistoryDiagnosticUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${id}/patientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingGET(id: number): Observable<ResourcePatientHistory> {
    return this.diagnosticAndPatientHistoryPatientHistoryUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingPOSTResponse(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPOSTParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}/patientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingPOST(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPOSTParams): Observable<ResourcePatientHistory> {
    return this.diagnosticAndPatientHistoryPatientHistoryUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingPUTResponse(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPUTParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}/patientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingPUT(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPUTParams): Observable<ResourcePatientHistory> {
    return this.diagnosticAndPatientHistoryPatientHistoryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticAndPatientHistoryPatientHistoryUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${id}/patientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticAndPatientHistoryPatientHistoryUsingDELETE(id: number): Observable<void> {
    return this.diagnosticAndPatientHistoryPatientHistoryUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingPATCHResponse(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPATCHParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosticAndPatientHistories/${params.id}/patientHistory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticAndPatientHistoryPatientHistoryUsingPATCH(params: DiagnosticAndPatientHistoryEntityService.DiagnosticAndPatientHistoryPatientHistoryUsingPATCHParams): Observable<ResourcePatientHistory> {
    return this.diagnosticAndPatientHistoryPatientHistoryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticAndPatientHistoryEntityService {

  /**
   * Parameters for findAllDiagnosticAndPatientHistoryUsingGET
   */
  export interface FindAllDiagnosticAndPatientHistoryUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDiagnosticAndPatientHistoryUsingPUT
   */
  export interface SaveDiagnosticAndPatientHistoryUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticAndPatientHistory;
  }

  /**
   * Parameters for saveDiagnosticAndPatientHistoryUsingPATCH
   */
  export interface SaveDiagnosticAndPatientHistoryUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticAndPatientHistory;
  }

  /**
   * Parameters for diagnosticAndPatientHistoryDiagnosticUsingPOST
   */
  export interface DiagnosticAndPatientHistoryDiagnosticUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticAndPatientHistoryDiagnosticUsingPUT
   */
  export interface DiagnosticAndPatientHistoryDiagnosticUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticAndPatientHistoryDiagnosticUsingPATCH
   */
  export interface DiagnosticAndPatientHistoryDiagnosticUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticAndPatientHistoryPatientHistoryUsingPOST
   */
  export interface DiagnosticAndPatientHistoryPatientHistoryUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticAndPatientHistoryPatientHistoryUsingPUT
   */
  export interface DiagnosticAndPatientHistoryPatientHistoryUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticAndPatientHistoryPatientHistoryUsingPATCH
   */
  export interface DiagnosticAndPatientHistoryPatientHistoryUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { DiagnosticAndPatientHistoryEntityService }
