/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Patient } from '../models/patient';
import { Families } from '../models/families';
import { PatientWoAddress } from '../models/patient-wo-address';

/**
 * Patient Open Api Controller
 */
@Injectable()
class PatientOpenApiControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param patient patient
   * @return OK
   */
  onCreatePatientUsingPOSTResponse(patient: Patient): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = patient;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/patient/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param patient patient
   * @return OK
   */
  onCreatePatientUsingPOST(patient: Patient): Observable<{}> {
    return this.onCreatePatientUsingPOSTResponse(patient).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param familyId familyId
   * @return OK
   */
  getFamilyDataUsingGETResponse(familyId: number): Observable<HttpResponse<Families>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (familyId != null) __params = __params.set('familyId', familyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/patient/family/data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Families = null;
        _body = _resp.body as Families;
        return _resp.clone({body: _body}) as HttpResponse<Families>;
      })
    );
  }

  /**
   * @param familyId familyId
   * @return OK
   */
  getFamilyDataUsingGET(familyId: number): Observable<Families> {
    return this.getFamilyDataUsingGETResponse(familyId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPatientDetailUsingGETResponse(patientId: number): Observable<HttpResponse<PatientWoAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/patient/find/by/id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PatientWoAddress = null;
        _body = _resp.body as PatientWoAddress;
        return _resp.clone({body: _body}) as HttpResponse<PatientWoAddress>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPatientDetailUsingGET(patientId: number): Observable<PatientWoAddress> {
    return this.getPatientDetailUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param mob mob
   * @return OK
   */
  getFamilyByPhoneUsingGETResponse(mob: string): Observable<HttpResponse<Families>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (mob != null) __params = __params.set('mob', mob.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/patient/find/family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Families = null;
        _body = _resp.body as Families;
        return _resp.clone({body: _body}) as HttpResponse<Families>;
      })
    );
  }

  /**
   * @param mob mob
   * @return OK
   */
  getFamilyByPhoneUsingGET(mob: string): Observable<Families> {
    return this.getFamilyByPhoneUsingGETResponse(mob).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param mobile mobile
   * @return OK
   */
  getPatientByPhoneUsingGETResponse(mobile: string): Observable<HttpResponse<Array<PatientWoAddress>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (mobile != null) __params = __params.set('mobile', mobile.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/patient/find/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PatientWoAddress> = null;
        _body = _resp.body as Array<PatientWoAddress>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PatientWoAddress>>;
      })
    );
  }

  /**
   * @param mobile mobile
   * @return OK
   */
  getPatientByPhoneUsingGET(mobile: string): Observable<Array<PatientWoAddress>> {
    return this.getPatientByPhoneUsingGETResponse(mobile).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PatientOpenApiControllerService.OnPatchUsingPATCHParams` containing the following parameters:
   *
   * - `patient`: patient
   *
   * - `mob`: mob
   *
   * - `id`: id
   *
   * @return OK
   */
  onPatchUsingPATCHResponse(params: PatientOpenApiControllerService.OnPatchUsingPATCHParams): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.patient;
    if (params.mob != null) __params = __params.set('mob', params.mob.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/patient/update/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param params The `PatientOpenApiControllerService.OnPatchUsingPATCHParams` containing the following parameters:
   *
   * - `patient`: patient
   *
   * - `mob`: mob
   *
   * - `id`: id
   *
   * @return OK
   */
  onPatchUsingPATCH(params: PatientOpenApiControllerService.OnPatchUsingPATCHParams): Observable<{}> {
    return this.onPatchUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PatientOpenApiControllerService {

  /**
   * Parameters for onPatchUsingPATCH
   */
  export interface OnPatchUsingPATCHParams {

    /**
     * patient
     */
    patient: Patient;

    /**
     * mob
     */
    mob: string;

    /**
     * id
     */
    id: number;
  }
}

export { PatientOpenApiControllerService }
