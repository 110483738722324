import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { HttpClient } from '@angular/common/http';
import { LocalStorage } from "@ngx-pwa/local-storage";
import moment from 'moment';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';
import { Timestamp } from '../../../api/emr/models';

@Component({
  selector: 'app-radiology',
  templateUrl: './radiology.component.html',
  styleUrls: ['./radiology.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }
  ]
})
export class RadiologyDialog implements OnInit {

  labSearchText;
  cityOrLocalitySearchText;
  diagnosticType;
  minDateForMatDateTimePicker;
  selectedIndex = 0;
  deferredDueBy;
  laterDueBy;
  consultation;
  loadingDiagnosticTypeInLabs = true;
  clinicId;
  diagnosticLabId: any;
  clinicPincode: any;

  constructor(
    public dialogRef: MatDialogRef<RadiologyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private LocalStorage: LocalStorage,
    private HttpClient: HttpClient,
    private EmrApiConfiguration: ApiConfiguration
  ) {
    this.diagnosticType = data.data.diagnosticType;
    this.consultation = data.data.consultation || {};
    this.minDateForMatDateTimePicker = new Date();
    this.clinicId = data.data.clinicId;
    this.diagnosticLabId = data.data.diagnosticLabId;
    this.diagnosticType.transcribing = data.data.transcribing;
    this.diagnosticType.dueBy = this.diagnosticType.dueBy?new Date(this.diagnosticType.dueBy):null;
    this.laterDueBy = this.diagnosticType.dueBy;
    if (data.data.transcribing) {
      this.deferredDueBy = this.diagnosticType.dueBy;
    }
  }

  loadDiagnosticTypeInLabs() {
    this.HttpClient.get(
      this.EmrApiConfiguration.rootUrl + '/api/v1/emr/diagnostic/revamp/labs-in-clinic', {
        params: {
          testId: this.diagnosticType.id,
          clinicId: this.clinicId
        }
      }
    ).subscribe(
      (res: any) => {
        if (res && res._embedded) {
          this.diagnosticType.diagnosticTypeInLabs = res._embedded.diagonstictypeinlab;
          if(!this.diagnosticType.diagnosticTypeInLab){
            this.diagnosticType.diagnosticTypeInLab = res._embedded.diagonstictypeinlab.find(diagnosticTypeInLab => {
              return diagnosticTypeInLab.diagnosticLab.id === this.diagnosticLabId;
            })
          }
        } else {
          this.diagnosticType.diagnosticTypeInLabs = [];
        }

        if (this.diagnosticType.diagnosticTypeInLabs.length == 1) {
          this.diagnosticType.diagnosticTypeInLab = this.diagnosticType.diagnosticTypeInLabs[0];
        } else if (this.diagnosticType.diagnosticTypeInLabs.length == 0) {
          alert('No labs available on this test');
          this.dialogRef.close(false);
        }

        this.loadingDiagnosticTypeInLabs = false;
      },
      err => {
        if(err && err.error && err.error.message){
          alert(err.error.message);
          this.dialogRef.close(false);
        }else{
          alert('Error while loading diagnostic labs');
          this.dialogRef.close(false);
        }
      }
    )
  }

  selectLab(diagnosticTypeInLab) {
    this.diagnosticType.diagnosticTypeInLab = diagnosticTypeInLab;
  }

  isSubmitButtonDisabled() {
    if (!this.diagnosticType.dueBy) {
      return true;
    } else if (!this.diagnosticType.diagnosticTypeInLab) {
      return true;
    } else {
      return false;
    }
  }

  onOkClick(): void {
    if (this.diagnosticType.transcribing) {
      this.diagnosticType.scheduleType = 'deffered';
      this.diagnosticType.deferred = true;
    } else if (this.selectedIndex == 0) {
      this.diagnosticType.scheduleType = 'later';
      this.diagnosticType.dueBy = this.laterDueBy;
      this.diagnosticType.expectedArrivalTime = moment(this.laterDueBy).format('YYYY-MM-DDTHH:mm:ssZ');
      this.diagnosticType.deferred = false;
    } else {
      this.diagnosticType.scheduleType = 'deffered'
      this.diagnosticType.deferred = true;
      this.diagnosticType.dueBy = this.deferredDueBy;
    }

    this.dialogRef.close(this.diagnosticType);
  }

  scheduleTypeChanged() {
    if (this.selectedIndex == 0 && !this.diagnosticType.transcribing) {
      this.diagnosticType.scheduleType = 'later';
      this.diagnosticType.dueBy = this.laterDueBy;
      this.diagnosticType.deferred = false;
    } else {
      this.diagnosticType.scheduleType = 'deffered'
      this.diagnosticType.deferred = true;
      this.diagnosticType.dueBy = this.deferredDueBy;
    }
  }

  ngOnInit() {
    this.LocalStorage.getItem("cartOrder").subscribe(order => {
      this.clinicPincode = order.doctorInClinic.clinic.pincode;
      this.loadDiagnosticTypeInLabs();
    })
  }
}
