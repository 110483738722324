/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesCoverage } from '../models/resources-coverage-';
import { ResourcesNewVsReturningPatient } from '../models/resources-new-vs-returning-patient-';
import { ResourcesYourPatient } from '../models/resources-your-patient-';

/**
 * Analysis Report Dashboard
 */
@Injectable()
class AnalysisReportDashboardService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingGETResponse(params: AnalysisReportDashboardService.CoverageUsingGETParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingGET(params: AnalysisReportDashboardService.CoverageUsingGETParams): Observable<ResourcesCoverage> {
    return this.coverageUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingHEADResponse(params: AnalysisReportDashboardService.CoverageUsingHEADParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingHEAD(params: AnalysisReportDashboardService.CoverageUsingHEADParams): Observable<ResourcesCoverage> {
    return this.coverageUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingPOSTResponse(params: AnalysisReportDashboardService.CoverageUsingPOSTParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingPOST(params: AnalysisReportDashboardService.CoverageUsingPOSTParams): Observable<ResourcesCoverage> {
    return this.coverageUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingPUTResponse(params: AnalysisReportDashboardService.CoverageUsingPUTParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingPUT(params: AnalysisReportDashboardService.CoverageUsingPUTParams): Observable<ResourcesCoverage> {
    return this.coverageUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingDELETEResponse(params: AnalysisReportDashboardService.CoverageUsingDELETEParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingDELETE(params: AnalysisReportDashboardService.CoverageUsingDELETEParams): Observable<ResourcesCoverage> {
    return this.coverageUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingOPTIONSResponse(params: AnalysisReportDashboardService.CoverageUsingOPTIONSParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingOPTIONS(params: AnalysisReportDashboardService.CoverageUsingOPTIONSParams): Observable<ResourcesCoverage> {
    return this.coverageUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingPATCHResponse(params: AnalysisReportDashboardService.CoverageUsingPATCHParams): Observable<HttpResponse<ResourcesCoverage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/coverage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCoverage = null;
        _body = _resp.body as ResourcesCoverage;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCoverage>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.CoverageUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  coverageUsingPATCH(params: AnalysisReportDashboardService.CoverageUsingPATCHParams): Observable<ResourcesCoverage> {
    return this.coverageUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingGETResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingGETParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingGET(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingGETParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingHEADResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingHEADParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingHEAD(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingHEADParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingPOSTResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPOSTParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingPOST(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPOSTParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingPUTResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPUTParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingPUT(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPUTParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingDELETEResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingDELETEParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingDELETE(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingDELETEParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingOPTIONSResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingOPTIONSParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingOPTIONS(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingOPTIONSParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingPATCHResponse(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPATCHParams): Observable<HttpResponse<ResourcesNewVsReturningPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/newVsReturningPatientDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesNewVsReturningPatient = null;
        _body = _resp.body as ResourcesNewVsReturningPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesNewVsReturningPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  newVsReturingPatientDateWiseUsingPATCH(params: AnalysisReportDashboardService.NewVsReturingPatientDateWiseUsingPATCHParams): Observable<ResourcesNewVsReturningPatient> {
    return this.newVsReturingPatientDateWiseUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingGETResponse(params: AnalysisReportDashboardService.YourPatientUsingGETParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingGET(params: AnalysisReportDashboardService.YourPatientUsingGETParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingHEADResponse(params: AnalysisReportDashboardService.YourPatientUsingHEADParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingHEAD(params: AnalysisReportDashboardService.YourPatientUsingHEADParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingPOSTResponse(params: AnalysisReportDashboardService.YourPatientUsingPOSTParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingPOST(params: AnalysisReportDashboardService.YourPatientUsingPOSTParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingPUTResponse(params: AnalysisReportDashboardService.YourPatientUsingPUTParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingPUT(params: AnalysisReportDashboardService.YourPatientUsingPUTParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingDELETEResponse(params: AnalysisReportDashboardService.YourPatientUsingDELETEParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingDELETE(params: AnalysisReportDashboardService.YourPatientUsingDELETEParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingOPTIONSResponse(params: AnalysisReportDashboardService.YourPatientUsingOPTIONSParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingOPTIONS(params: AnalysisReportDashboardService.YourPatientUsingOPTIONSParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingPATCHResponse(params: AnalysisReportDashboardService.YourPatientUsingPATCHParams): Observable<HttpResponse<ResourcesYourPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    (params.doctorinclinic || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorinclinic', val.toString())});
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/yourPatient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesYourPatient = null;
        _body = _resp.body as ResourcesYourPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesYourPatient>;
      })
    );
  }

  /**
   * @param params The `AnalysisReportDashboardService.YourPatientUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  yourPatientUsingPATCH(params: AnalysisReportDashboardService.YourPatientUsingPATCHParams): Observable<ResourcesYourPatient> {
    return this.yourPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AnalysisReportDashboardService {

  /**
   * Parameters for coverageUsingGET
   */
  export interface CoverageUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for coverageUsingHEAD
   */
  export interface CoverageUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for coverageUsingPOST
   */
  export interface CoverageUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for coverageUsingPUT
   */
  export interface CoverageUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for coverageUsingDELETE
   */
  export interface CoverageUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for coverageUsingOPTIONS
   */
  export interface CoverageUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for coverageUsingPATCH
   */
  export interface CoverageUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingGET
   */
  export interface NewVsReturingPatientDateWiseUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingHEAD
   */
  export interface NewVsReturingPatientDateWiseUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingPOST
   */
  export interface NewVsReturingPatientDateWiseUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingPUT
   */
  export interface NewVsReturingPatientDateWiseUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingDELETE
   */
  export interface NewVsReturingPatientDateWiseUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingOPTIONS
   */
  export interface NewVsReturingPatientDateWiseUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for newVsReturingPatientDateWiseUsingPATCH
   */
  export interface NewVsReturingPatientDateWiseUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingGET
   */
  export interface YourPatientUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingHEAD
   */
  export interface YourPatientUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingPOST
   */
  export interface YourPatientUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingPUT
   */
  export interface YourPatientUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingDELETE
   */
  export interface YourPatientUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingOPTIONS
   */
  export interface YourPatientUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }

  /**
   * Parameters for yourPatientUsingPATCH
   */
  export interface YourPatientUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: Array<number>;
  }
}

export { AnalysisReportDashboardService }
