/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesOrderItemStatusflow } from '../models/resources-order-item-statusflow-';
import { ResourceOrderItemStatusflow } from '../models/resource-order-item-statusflow-';
import { OrderItemStatusflow } from '../models/order-item-statusflow';
import { ResourceOrderItem } from '../models/resource-order-item-';

/**
 * $ Proxy 179
 */
@Injectable()
class OrderItemStatusflowEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.FindAllOrderItemStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderItemStatusflowUsingGETResponse(params: OrderItemStatusflowEntityService.FindAllOrderItemStatusflowUsingGETParams): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.FindAllOrderItemStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderItemStatusflowUsingGET(params: OrderItemStatusflowEntityService.FindAllOrderItemStatusflowUsingGETParams): Observable<ResourcesOrderItemStatusflow> {
    return this.findAllOrderItemStatusflowUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderItemStatusflowUsingPOSTResponse(body: OrderItemStatusflow): Observable<HttpResponse<ResourceOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItemStatusflow = null;
        _body = _resp.body as ResourceOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderItemStatusflowUsingPOST(body: OrderItemStatusflow): Observable<ResourceOrderItemStatusflow> {
    return this.saveOrderItemStatusflowUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param orderItemId orderItemId
   * @return OK
   */
  fetchOrderItemStatusFlowsThroughOrderItemIdOrderItemStatusflowUsingGETResponse(orderItemId?: number): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orderItemId != null) __params = __params.set('orderItemId', orderItemId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/search/fetchOrderItemStatusFlowsThroughOrderItemId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param orderItemId orderItemId
   * @return OK
   */
  fetchOrderItemStatusFlowsThroughOrderItemIdOrderItemStatusflowUsingGET(orderItemId?: number): Observable<ResourcesOrderItemStatusflow> {
    return this.fetchOrderItemStatusFlowsThroughOrderItemIdOrderItemStatusflowUsingGETResponse(orderItemId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.FindByOrderItemIdOrderItemStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `orderItemId`: orderItemId
   *
   * @return OK
   */
  findByOrderItemIdOrderItemStatusflowUsingGETResponse(params: OrderItemStatusflowEntityService.FindByOrderItemIdOrderItemStatusflowUsingGETParams): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.orderItemId != null) __params = __params.set('orderItemId', params.orderItemId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/search/findByOrderItemId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.FindByOrderItemIdOrderItemStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `orderItemId`: orderItemId
   *
   * @return OK
   */
  findByOrderItemIdOrderItemStatusflowUsingGET(params: OrderItemStatusflowEntityService.FindByOrderItemIdOrderItemStatusflowUsingGETParams): Observable<ResourcesOrderItemStatusflow> {
    return this.findByOrderItemIdOrderItemStatusflowUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderItemStatusflowUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItemStatusflow = null;
        _body = _resp.body as ResourceOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderItemStatusflowUsingGET(id: number): Observable<ResourceOrderItemStatusflow> {
    return this.findByIdOrderItemStatusflowUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemStatusflowUsingPUTResponse(params: OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPUTParams): Observable<HttpResponse<ResourceOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItemStatusflow = null;
        _body = _resp.body as ResourceOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemStatusflowUsingPUT(params: OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPUTParams): Observable<ResourceOrderItemStatusflow> {
    return this.saveOrderItemStatusflowUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteOrderItemStatusflowUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteOrderItemStatusflowUsingDELETE(id: number): Observable<void> {
    return this.deleteOrderItemStatusflowUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemStatusflowUsingPATCHResponse(params: OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPATCHParams): Observable<HttpResponse<ResourceOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItemStatusflow = null;
        _body = _resp.body as ResourceOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemStatusflowUsingPATCH(params: OrderItemStatusflowEntityService.SaveOrderItemStatusflowUsingPATCHParams): Observable<ResourceOrderItemStatusflow> {
    return this.saveOrderItemStatusflowUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemStatusflowOrderItemUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemStatusflowOrderItemUsingGET(id: number): Observable<ResourceOrderItem> {
    return this.orderItemStatusflowOrderItemUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemStatusflowOrderItemUsingPOSTResponse(params: OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPOSTParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${params.id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemStatusflowOrderItemUsingPOST(params: OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPOSTParams): Observable<ResourceOrderItem> {
    return this.orderItemStatusflowOrderItemUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemStatusflowOrderItemUsingPUTResponse(params: OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPUTParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${params.id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemStatusflowOrderItemUsingPUT(params: OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPUTParams): Observable<ResourceOrderItem> {
    return this.orderItemStatusflowOrderItemUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderItemStatusflowOrderItemUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderItemStatusflowOrderItemUsingDELETE(id: number): Observable<void> {
    return this.orderItemStatusflowOrderItemUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemStatusflowOrderItemUsingPATCHResponse(params: OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPATCHParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ordersItemStatusflow/${params.id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemStatusflowOrderItemUsingPATCH(params: OrderItemStatusflowEntityService.OrderItemStatusflowOrderItemUsingPATCHParams): Observable<ResourceOrderItem> {
    return this.orderItemStatusflowOrderItemUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderItemStatusflowEntityService {

  /**
   * Parameters for findAllOrderItemStatusflowUsingGET
   */
  export interface FindAllOrderItemStatusflowUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByOrderItemIdOrderItemStatusflowUsingGET
   */
  export interface FindByOrderItemIdOrderItemStatusflowUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * orderItemId
     */
    orderItemId?: number;
  }

  /**
   * Parameters for saveOrderItemStatusflowUsingPUT
   */
  export interface SaveOrderItemStatusflowUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: OrderItemStatusflow;
  }

  /**
   * Parameters for saveOrderItemStatusflowUsingPATCH
   */
  export interface SaveOrderItemStatusflowUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: OrderItemStatusflow;
  }

  /**
   * Parameters for orderItemStatusflowOrderItemUsingPOST
   */
  export interface OrderItemStatusflowOrderItemUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemStatusflowOrderItemUsingPUT
   */
  export interface OrderItemStatusflowOrderItemUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemStatusflowOrderItemUsingPATCH
   */
  export interface OrderItemStatusflowOrderItemUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { OrderItemStatusflowEntityService }
