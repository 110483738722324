/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesUser } from '../models/resources-user-';
import { ResourceUser } from '../models/resource-user-';
import { User } from '../models/user';
import { ResourcesRole } from '../models/resources-role-';
import { ResourceRole } from '../models/resource-role-';

/**
 * $ Proxy 158
 */
@Injectable()
class UserEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `UserEntityService.FindAllUserUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllUserUsingGETResponse(params: UserEntityService.FindAllUserUsingGETParams): Observable<HttpResponse<ResourcesUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesUser = null;
        _body = _resp.body as ResourcesUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesUser>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.FindAllUserUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllUserUsingGET(params: UserEntityService.FindAllUserUsingGETParams): Observable<ResourcesUser> {
    return this.findAllUserUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveUserUsingPOSTResponse(body: User): Observable<HttpResponse<ResourceUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rest/api/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceUser = null;
        _body = _resp.body as ResourceUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourceUser>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveUserUsingPOST(body: User): Observable<ResourceUser> {
    return this.saveUserUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param email email
   * @return OK
   */
  findByEmailUserUsingGETResponse(email?: string): Observable<HttpResponse<ResourceUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (email != null) __params = __params.set('email', email.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/users/search/findByEmail`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceUser = null;
        _body = _resp.body as ResourceUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourceUser>;
      })
    );
  }

  /**
   * @param email email
   * @return OK
   */
  findByEmailUserUsingGET(email?: string): Observable<ResourceUser> {
    return this.findByEmailUserUsingGETResponse(email).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param username username
   * @return OK
   */
  findByUsernameUserUsingGETResponse(username?: string): Observable<HttpResponse<ResourceUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (username != null) __params = __params.set('username', username.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/users/search/findByUsername`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceUser = null;
        _body = _resp.body as ResourceUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourceUser>;
      })
    );
  }

  /**
   * @param username username
   * @return OK
   */
  findByUsernameUserUsingGET(username?: string): Observable<ResourceUser> {
    return this.findByUsernameUserUsingGETResponse(username).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdUserUsingGETResponse(id: number): Observable<HttpResponse<ResourceUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceUser = null;
        _body = _resp.body as ResourceUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourceUser>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdUserUsingGET(id: number): Observable<ResourceUser> {
    return this.findByIdUserUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.SaveUserUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveUserUsingPUTResponse(params: UserEntityService.SaveUserUsingPUTParams): Observable<HttpResponse<ResourceUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rest/api/users/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceUser = null;
        _body = _resp.body as ResourceUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourceUser>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.SaveUserUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveUserUsingPUT(params: UserEntityService.SaveUserUsingPUTParams): Observable<ResourceUser> {
    return this.saveUserUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteUserUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rest/api/users/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteUserUsingDELETE(id: number): Observable<void> {
    return this.deleteUserUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.SaveUserUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveUserUsingPATCHResponse(params: UserEntityService.SaveUserUsingPATCHParams): Observable<HttpResponse<ResourceUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/rest/api/users/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceUser = null;
        _body = _resp.body as ResourceUser;
        return _resp.clone({body: _body}) as HttpResponse<ResourceUser>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.SaveUserUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveUserUsingPATCH(params: UserEntityService.SaveUserUsingPATCHParams): Observable<ResourceUser> {
    return this.saveUserUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  userRolesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/users/${id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRole = null;
        _body = _resp.body as ResourcesRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRole>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  userRolesUsingGET_1(id: number): Observable<ResourcesRole> {
    return this.userRolesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  userRolesUsingPOSTResponse(params: UserEntityService.UserRolesUsingPOSTParams): Observable<HttpResponse<ResourcesRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rest/api/users/${params.id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRole = null;
        _body = _resp.body as ResourcesRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRole>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  userRolesUsingPOST(params: UserEntityService.UserRolesUsingPOSTParams): Observable<ResourcesRole> {
    return this.userRolesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  userRolesUsingPUTResponse(params: UserEntityService.UserRolesUsingPUTParams): Observable<HttpResponse<ResourcesRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rest/api/users/${params.id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRole = null;
        _body = _resp.body as ResourcesRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRole>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  userRolesUsingPUT(params: UserEntityService.UserRolesUsingPUTParams): Observable<ResourcesRole> {
    return this.userRolesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  userRolesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rest/api/users/${id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  userRolesUsingDELETE_1(id: number): Observable<void> {
    return this.userRolesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  userRolesUsingPATCHResponse(params: UserEntityService.UserRolesUsingPATCHParams): Observable<HttpResponse<ResourcesRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/rest/api/users/${params.id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRole = null;
        _body = _resp.body as ResourcesRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRole>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  userRolesUsingPATCH(params: UserEntityService.UserRolesUsingPATCHParams): Observable<ResourcesRole> {
    return this.userRolesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingGETParams` containing the following parameters:
   *
   * - `roleId`: roleId
   *
   * - `id`: id
   *
   * @return OK
   */
  userRolesUsingGETResponse(params: UserEntityService.UserRolesUsingGETParams): Observable<HttpResponse<ResourceRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rest/api/users/${params.id}/roles/${params.roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRole = null;
        _body = _resp.body as ResourceRole;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRole>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingGETParams` containing the following parameters:
   *
   * - `roleId`: roleId
   *
   * - `id`: id
   *
   * @return OK
   */
  userRolesUsingGET(params: UserEntityService.UserRolesUsingGETParams): Observable<ResourceRole> {
    return this.userRolesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingDELETEParams` containing the following parameters:
   *
   * - `roleId`: roleId
   *
   * - `id`: id
   */
  userRolesUsingDELETEResponse(params: UserEntityService.UserRolesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rest/api/users/${params.id}/roles/${params.roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `UserEntityService.UserRolesUsingDELETEParams` containing the following parameters:
   *
   * - `roleId`: roleId
   *
   * - `id`: id
   */
  userRolesUsingDELETE(params: UserEntityService.UserRolesUsingDELETEParams): Observable<void> {
    return this.userRolesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module UserEntityService {

  /**
   * Parameters for findAllUserUsingGET
   */
  export interface FindAllUserUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveUserUsingPUT
   */
  export interface SaveUserUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: User;
  }

  /**
   * Parameters for saveUserUsingPATCH
   */
  export interface SaveUserUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: User;
  }

  /**
   * Parameters for userRolesUsingPOST
   */
  export interface UserRolesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for userRolesUsingPUT
   */
  export interface UserRolesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for userRolesUsingPATCH
   */
  export interface UserRolesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for userRolesUsingGET
   */
  export interface UserRolesUsingGETParams {

    /**
     * roleId
     */
    roleId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for userRolesUsingDELETE
   */
  export interface UserRolesUsingDELETEParams {

    /**
     * roleId
     */
    roleId: string;

    /**
     * id
     */
    id: number;
  }
}

export { UserEntityService }
