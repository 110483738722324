import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

@Injectable({
    providedIn:'root'
})
export class FullScreenLoaderService{
    FullScreenLoaderState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    LoaderText: BehaviorSubject<any> = new BehaviorSubject('');
    showLoader(){
        this.FullScreenLoaderState.next(true);
    }
    hideLoader(){
        this.FullScreenLoaderState.next(false);
    }
}