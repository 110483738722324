/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesSpecialityVitalType } from '../models/resources-speciality-vital-type-';
import { ResourceSpecialityVitalType } from '../models/resource-speciality-vital-type-';
import { SpecialityVitalType } from '../models/speciality-vital-type';
import { ResourceSpeciality } from '../models/resource-speciality-';
import { ResourceVitalType } from '../models/resource-vital-type-';

/**
 * $ Proxy 228
 */
@Injectable()
class SpecialityVitalTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.FindAllSpecialityVitalTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSpecialityVitalTypeUsingGETResponse(params: SpecialityVitalTypeEntityService.FindAllSpecialityVitalTypeUsingGETParams): Observable<HttpResponse<ResourcesSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityVitalType = null;
        _body = _resp.body as ResourcesSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.FindAllSpecialityVitalTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSpecialityVitalTypeUsingGET(params: SpecialityVitalTypeEntityService.FindAllSpecialityVitalTypeUsingGETParams): Observable<ResourcesSpecialityVitalType> {
    return this.findAllSpecialityVitalTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSpecialityVitalTypeUsingPOSTResponse(body: SpecialityVitalType): Observable<HttpResponse<ResourceSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityVitalType = null;
        _body = _resp.body as ResourceSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityVitalType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSpecialityVitalTypeUsingPOST(body: SpecialityVitalType): Observable<ResourceSpecialityVitalType> {
    return this.saveSpecialityVitalTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSpecialityVitalTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityVitalType = null;
        _body = _resp.body as ResourceSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityVitalType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSpecialityVitalTypeUsingGET(id: number): Observable<ResourceSpecialityVitalType> {
    return this.findByIdSpecialityVitalTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityVitalTypeUsingPUTResponse(params: SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPUTParams): Observable<HttpResponse<ResourceSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityVitalType = null;
        _body = _resp.body as ResourceSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityVitalTypeUsingPUT(params: SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPUTParams): Observable<ResourceSpecialityVitalType> {
    return this.saveSpecialityVitalTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteSpecialityVitalTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteSpecialityVitalTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteSpecialityVitalTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityVitalTypeUsingPATCHResponse(params: SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPATCHParams): Observable<HttpResponse<ResourceSpecialityVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityVitalType = null;
        _body = _resp.body as ResourceSpecialityVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityVitalTypeUsingPATCH(params: SpecialityVitalTypeEntityService.SaveSpecialityVitalTypeUsingPATCHParams): Observable<ResourceSpecialityVitalType> {
    return this.saveSpecialityVitalTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityVitalTypeSpecialityUsingGETResponse(id: number): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityVitalTypeSpecialityUsingGET(id: number): Observable<ResourceSpeciality> {
    return this.specialityVitalTypeSpecialityUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeSpecialityUsingPOSTResponse(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPOSTParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeSpecialityUsingPOST(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPOSTParams): Observable<ResourceSpeciality> {
    return this.specialityVitalTypeSpecialityUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeSpecialityUsingPUTResponse(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPUTParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeSpecialityUsingPUT(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPUTParams): Observable<ResourceSpeciality> {
    return this.specialityVitalTypeSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialityVitalTypeSpecialityUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialityVitalTypeSpecialityUsingDELETE(id: number): Observable<void> {
    return this.specialityVitalTypeSpecialityUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeSpecialityUsingPATCHResponse(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPATCHParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeSpecialityUsingPATCH(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeSpecialityUsingPATCHParams): Observable<ResourceSpeciality> {
    return this.specialityVitalTypeSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingGET(id: number): Observable<ResourceVitalType> {
    return this.specialityVitalTypeVitalTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingPOSTResponse(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPOSTParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingPOST(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPOSTParams): Observable<ResourceVitalType> {
    return this.specialityVitalTypeVitalTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingPUTResponse(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPUTParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingPUT(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPUTParams): Observable<ResourceVitalType> {
    return this.specialityVitalTypeVitalTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialityVitalTypeVitalTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialityVitalTypeVitalTypeUsingDELETE(id: number): Observable<void> {
    return this.specialityVitalTypeVitalTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingPATCHResponse(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPATCHParams): Observable<HttpResponse<ResourceVitalType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/specialitiesVitalType/${params.id}/vitalType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceVitalType = null;
        _body = _resp.body as ResourceVitalType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceVitalType>;
      })
    );
  }

  /**
   * @param params The `SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityVitalTypeVitalTypeUsingPATCH(params: SpecialityVitalTypeEntityService.SpecialityVitalTypeVitalTypeUsingPATCHParams): Observable<ResourceVitalType> {
    return this.specialityVitalTypeVitalTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SpecialityVitalTypeEntityService {

  /**
   * Parameters for findAllSpecialityVitalTypeUsingGET
   */
  export interface FindAllSpecialityVitalTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveSpecialityVitalTypeUsingPUT
   */
  export interface SaveSpecialityVitalTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: SpecialityVitalType;
  }

  /**
   * Parameters for saveSpecialityVitalTypeUsingPATCH
   */
  export interface SaveSpecialityVitalTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: SpecialityVitalType;
  }

  /**
   * Parameters for specialityVitalTypeSpecialityUsingPOST
   */
  export interface SpecialityVitalTypeSpecialityUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityVitalTypeSpecialityUsingPUT
   */
  export interface SpecialityVitalTypeSpecialityUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityVitalTypeSpecialityUsingPATCH
   */
  export interface SpecialityVitalTypeSpecialityUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityVitalTypeVitalTypeUsingPOST
   */
  export interface SpecialityVitalTypeVitalTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityVitalTypeVitalTypeUsingPUT
   */
  export interface SpecialityVitalTypeVitalTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityVitalTypeVitalTypeUsingPATCH
   */
  export interface SpecialityVitalTypeVitalTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { SpecialityVitalTypeEntityService }
