/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesTimeSlotGroup } from '../models/resources-time-slot-group-';
import { ResourceTimeSlotGroup } from '../models/resource-time-slot-group-';
import { TimeSlotGroup } from '../models/time-slot-group';
import { ResourcesTimeSlot } from '../models/resources-time-slot-';
import { ResourceTimeSlot } from '../models/resource-time-slot-';

/**
 * $ Proxy 211
 */
@Injectable()
class TimeSlotGroupEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TimeSlotGroupEntityService.FindAllTimeSlotGroupUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTimeSlotGroupUsingGETResponse(params: TimeSlotGroupEntityService.FindAllTimeSlotGroupUsingGETParams): Observable<HttpResponse<ResourcesTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslotgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotGroup = null;
        _body = _resp.body as ResourcesTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotGroup>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.FindAllTimeSlotGroupUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTimeSlotGroupUsingGET(params: TimeSlotGroupEntityService.FindAllTimeSlotGroupUsingGETParams): Observable<ResourcesTimeSlotGroup> {
    return this.findAllTimeSlotGroupUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTimeSlotGroupUsingPOSTResponse(body: TimeSlotGroup): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/timeslotgroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTimeSlotGroupUsingPOST(body: TimeSlotGroup): Observable<ResourceTimeSlotGroup> {
    return this.saveTimeSlotGroupUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTimeSlotGroupUsingGETResponse(id: number): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTimeSlotGroupUsingGET(id: number): Observable<ResourceTimeSlotGroup> {
    return this.findByIdTimeSlotGroupUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotGroupUsingPUTResponse(params: TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPUTParams): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotGroupUsingPUT(params: TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPUTParams): Observable<ResourceTimeSlotGroup> {
    return this.saveTimeSlotGroupUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteTimeSlotGroupUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteTimeSlotGroupUsingDELETE(id: number): Observable<void> {
    return this.deleteTimeSlotGroupUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotGroupUsingPATCHResponse(params: TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPATCHParams): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotGroupUsingPATCH(params: TimeSlotGroupEntityService.SaveTimeSlotGroupUsingPATCHParams): Observable<ResourceTimeSlotGroup> {
    return this.saveTimeSlotGroupUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingGET_1(id: number): Observable<ResourcesTimeSlot> {
    return this.timeSlotGroupTimeSlotsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingPOSTResponse(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPOSTParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingPOST(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPOSTParams): Observable<ResourcesTimeSlot> {
    return this.timeSlotGroupTimeSlotsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingPUTResponse(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPUTParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingPUT(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPUTParams): Observable<ResourcesTimeSlot> {
    return this.timeSlotGroupTimeSlotsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  timeSlotGroupTimeSlotsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  timeSlotGroupTimeSlotsUsingDELETE_1(id: number): Observable<void> {
    return this.timeSlotGroupTimeSlotsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingPATCHResponse(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPATCHParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}/timeSlots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingPATCH(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingPATCHParams): Observable<ResourcesTimeSlot> {
    return this.timeSlotGroupTimeSlotsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingGETParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingGETResponse(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingGETParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}/timeSlots/${params.timeslotId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingGETParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   *
   * @return OK
   */
  timeSlotGroupTimeSlotsUsingGET(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingGETParams): Observable<ResourceTimeSlot> {
    return this.timeSlotGroupTimeSlotsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingDELETEParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   */
  timeSlotGroupTimeSlotsUsingDELETEResponse(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslotgroup/${params.id}/timeSlots/${params.timeslotId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingDELETEParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `id`: id
   */
  timeSlotGroupTimeSlotsUsingDELETE(params: TimeSlotGroupEntityService.TimeSlotGroupTimeSlotsUsingDELETEParams): Observable<void> {
    return this.timeSlotGroupTimeSlotsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module TimeSlotGroupEntityService {

  /**
   * Parameters for findAllTimeSlotGroupUsingGET
   */
  export interface FindAllTimeSlotGroupUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveTimeSlotGroupUsingPUT
   */
  export interface SaveTimeSlotGroupUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TimeSlotGroup;
  }

  /**
   * Parameters for saveTimeSlotGroupUsingPATCH
   */
  export interface SaveTimeSlotGroupUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TimeSlotGroup;
  }

  /**
   * Parameters for timeSlotGroupTimeSlotsUsingPOST
   */
  export interface TimeSlotGroupTimeSlotsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotGroupTimeSlotsUsingPUT
   */
  export interface TimeSlotGroupTimeSlotsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotGroupTimeSlotsUsingPATCH
   */
  export interface TimeSlotGroupTimeSlotsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotGroupTimeSlotsUsingGET
   */
  export interface TimeSlotGroupTimeSlotsUsingGETParams {

    /**
     * timeslotId
     */
    timeslotId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for timeSlotGroupTimeSlotsUsingDELETE
   */
  export interface TimeSlotGroupTimeSlotsUsingDELETEParams {

    /**
     * timeslotId
     */
    timeslotId: string;

    /**
     * id
     */
    id: number;
  }
}

export { TimeSlotGroupEntityService }
