/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OrderItem } from '../models/order-item';
import { DiagnosticOrderDto } from '../models/diagnostic-order-dto';

/**
 * Diagnostic In Clinic Controller
 */
@Injectable()
class DiagnosticInClinicControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param diagnosticOrder diagnosticOrder
   * @return OK
   */
  partialBarcodeOrderUsingPOSTResponse(diagnosticOrder: DiagnosticOrderDto): Observable<HttpResponse<Array<OrderItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = diagnosticOrder;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/create-diagnostic/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderItem> = null;
        _body = _resp.body as Array<OrderItem>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderItem>>;
      })
    );
  }

  /**
   * @param diagnosticOrder diagnosticOrder
   * @return OK
   */
  partialBarcodeOrderUsingPOST(diagnosticOrder: DiagnosticOrderDto): Observable<Array<OrderItem>> {
    return this.partialBarcodeOrderUsingPOSTResponse(diagnosticOrder).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param diagnosticOrder diagnosticOrder
   * @return OK
   */
  partialOrderUsingPOSTResponse(diagnosticOrder: DiagnosticOrderDto): Observable<HttpResponse<Array<OrderItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = diagnosticOrder;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/diagnostic/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<OrderItem> = null;
        _body = _resp.body as Array<OrderItem>;
        return _resp.clone({body: _body}) as HttpResponse<Array<OrderItem>>;
      })
    );
  }

  /**
   * @param diagnosticOrder diagnosticOrder
   * @return OK
   */
  partialOrderUsingPOST(diagnosticOrder: DiagnosticOrderDto): Observable<Array<OrderItem>> {
    return this.partialOrderUsingPOSTResponse(diagnosticOrder).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticInClinicControllerService {
}

export { DiagnosticInClinicControllerService }
