/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesTimeSlot } from '../models/resources-time-slot-';
import { ResourceTimeSlot } from '../models/resource-time-slot-';
import { TimeSlot } from '../models/time-slot';
import { ResourcesConsultation } from '../models/resources-consultation-';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourcesDoctorInClinic } from '../models/resources-doctor-in-clinic-';
import { ResourceDoctorInClinic } from '../models/resource-doctor-in-clinic-';
import { ResourceTimeSlotGroup } from '../models/resource-time-slot-group-';

/**
 * $ Proxy 187
 */
@Injectable()
class TimeSlotEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TimeSlotEntityService.FindAllTimeSlotUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTimeSlotUsingGETResponse(params: TimeSlotEntityService.FindAllTimeSlotUsingGETParams): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.FindAllTimeSlotUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTimeSlotUsingGET(params: TimeSlotEntityService.FindAllTimeSlotUsingGETParams): Observable<ResourcesTimeSlot> {
    return this.findAllTimeSlotUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTimeSlotUsingPOSTResponse(body: TimeSlot): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/timeslots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTimeSlotUsingPOST(body: TimeSlot): Observable<ResourceTimeSlot> {
    return this.saveTimeSlotUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByDoctorInClinicsIdTimeSlotUsingGETResponse(id?: number): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/search/findByDoctorInClinicsId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByDoctorInClinicsIdTimeSlotUsingGET(id?: number): Observable<ResourcesTimeSlot> {
    return this.findByDoctorInClinicsIdTimeSlotUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByDoctorInClinicsIdInTimeSlotUsingGETResponse(id?: Array<number>): Observable<HttpResponse<ResourcesTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (id || []).forEach((val, index) => {if (val != null) __params = __params.append('id', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/search/findByDoctorInClinicsIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlot = null;
        _body = _resp.body as ResourcesTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlot>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByDoctorInClinicsIdInTimeSlotUsingGET(id?: Array<number>): Observable<ResourcesTimeSlot> {
    return this.findByDoctorInClinicsIdInTimeSlotUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTimeSlotUsingGETResponse(id: number): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTimeSlotUsingGET(id: number): Observable<ResourceTimeSlot> {
    return this.findByIdTimeSlotUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.SaveTimeSlotUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotUsingPUTResponse(params: TimeSlotEntityService.SaveTimeSlotUsingPUTParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.SaveTimeSlotUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotUsingPUT(params: TimeSlotEntityService.SaveTimeSlotUsingPUTParams): Observable<ResourceTimeSlot> {
    return this.saveTimeSlotUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteTimeSlotUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslots/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteTimeSlotUsingDELETE(id: number): Observable<void> {
    return this.deleteTimeSlotUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.SaveTimeSlotUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotUsingPATCHResponse(params: TimeSlotEntityService.SaveTimeSlotUsingPATCHParams): Observable<HttpResponse<ResourceTimeSlot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlot = null;
        _body = _resp.body as ResourceTimeSlot;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlot>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.SaveTimeSlotUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTimeSlotUsingPATCH(params: TimeSlotEntityService.SaveTimeSlotUsingPATCHParams): Observable<ResourceTimeSlot> {
    return this.saveTimeSlotUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotConsultationsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/${id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotConsultationsUsingGET_1(id: number): Observable<ResourcesConsultation> {
    return this.timeSlotConsultationsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotConsultationsUsingPOSTResponse(params: TimeSlotEntityService.TimeSlotConsultationsUsingPOSTParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotConsultationsUsingPOST(params: TimeSlotEntityService.TimeSlotConsultationsUsingPOSTParams): Observable<ResourcesConsultation> {
    return this.timeSlotConsultationsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotConsultationsUsingPUTResponse(params: TimeSlotEntityService.TimeSlotConsultationsUsingPUTParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotConsultationsUsingPUT(params: TimeSlotEntityService.TimeSlotConsultationsUsingPUTParams): Observable<ResourcesConsultation> {
    return this.timeSlotConsultationsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  timeSlotConsultationsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslots/${id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  timeSlotConsultationsUsingDELETE_1(id: number): Observable<void> {
    return this.timeSlotConsultationsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotConsultationsUsingPATCHResponse(params: TimeSlotEntityService.TimeSlotConsultationsUsingPATCHParams): Observable<HttpResponse<ResourcesConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesConsultation = null;
        _body = _resp.body as ResourcesConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesConsultation>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotConsultationsUsingPATCH(params: TimeSlotEntityService.TimeSlotConsultationsUsingPATCHParams): Observable<ResourcesConsultation> {
    return this.timeSlotConsultationsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  timeSlotConsultationsUsingGETResponse(params: TimeSlotEntityService.TimeSlotConsultationsUsingGETParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/consultations/${params.consultationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  timeSlotConsultationsUsingGET(params: TimeSlotEntityService.TimeSlotConsultationsUsingGETParams): Observable<ResourceConsultation> {
    return this.timeSlotConsultationsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   */
  timeSlotConsultationsUsingDELETEResponse(params: TimeSlotEntityService.TimeSlotConsultationsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/consultations/${params.consultationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotConsultationsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `consultationId`: consultationId
   */
  timeSlotConsultationsUsingDELETE(params: TimeSlotEntityService.TimeSlotConsultationsUsingDELETEParams): Observable<void> {
    return this.timeSlotConsultationsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotDoctorInClinicsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/${id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotDoctorInClinicsUsingGET_1(id: number): Observable<ResourcesDoctorInClinic> {
    return this.timeSlotDoctorInClinicsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingPOSTResponse(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPOSTParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingPOST(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPOSTParams): Observable<ResourcesDoctorInClinic> {
    return this.timeSlotDoctorInClinicsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingPUTResponse(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPUTParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingPUT(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPUTParams): Observable<ResourcesDoctorInClinic> {
    return this.timeSlotDoctorInClinicsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  timeSlotDoctorInClinicsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslots/${id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  timeSlotDoctorInClinicsUsingDELETE_1(id: number): Observable<void> {
    return this.timeSlotDoctorInClinicsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingPATCHResponse(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPATCHParams): Observable<HttpResponse<ResourcesDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/doctorInClinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDoctorInClinic = null;
        _body = _resp.body as ResourcesDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingPATCH(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingPATCHParams): Observable<ResourcesDoctorInClinic> {
    return this.timeSlotDoctorInClinicsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingGETResponse(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingGETParams): Observable<HttpResponse<ResourceDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/doctorInClinics/${params.doctorinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDoctorInClinic = null;
        _body = _resp.body as ResourceDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDoctorInClinic>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   *
   * @return OK
   */
  timeSlotDoctorInClinicsUsingGET(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingGETParams): Observable<ResourceDoctorInClinic> {
    return this.timeSlotDoctorInClinicsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   */
  timeSlotDoctorInClinicsUsingDELETEResponse(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/doctorInClinics/${params.doctorinclinicId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotDoctorInClinicsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `doctorinclinicId`: doctorinclinicId
   */
  timeSlotDoctorInClinicsUsingDELETE(params: TimeSlotEntityService.TimeSlotDoctorInClinicsUsingDELETEParams): Observable<void> {
    return this.timeSlotDoctorInClinicsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotTimeSlotGroupUsingGETResponse(id: number): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/timeslots/${id}/timeSlotGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  timeSlotTimeSlotGroupUsingGET(id: number): Observable<ResourceTimeSlotGroup> {
    return this.timeSlotTimeSlotGroupUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotTimeSlotGroupUsingPOSTResponse(params: TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPOSTParams): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/timeSlotGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotTimeSlotGroupUsingPOST(params: TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPOSTParams): Observable<ResourceTimeSlotGroup> {
    return this.timeSlotTimeSlotGroupUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotTimeSlotGroupUsingPUTResponse(params: TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPUTParams): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/timeSlotGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotTimeSlotGroupUsingPUT(params: TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPUTParams): Observable<ResourceTimeSlotGroup> {
    return this.timeSlotTimeSlotGroupUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  timeSlotTimeSlotGroupUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/timeslots/${id}/timeSlotGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  timeSlotTimeSlotGroupUsingDELETE(id: number): Observable<void> {
    return this.timeSlotTimeSlotGroupUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotTimeSlotGroupUsingPATCHResponse(params: TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPATCHParams): Observable<HttpResponse<ResourceTimeSlotGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/timeslots/${params.id}/timeSlotGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTimeSlotGroup = null;
        _body = _resp.body as ResourceTimeSlotGroup;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTimeSlotGroup>;
      })
    );
  }

  /**
   * @param params The `TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  timeSlotTimeSlotGroupUsingPATCH(params: TimeSlotEntityService.TimeSlotTimeSlotGroupUsingPATCHParams): Observable<ResourceTimeSlotGroup> {
    return this.timeSlotTimeSlotGroupUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module TimeSlotEntityService {

  /**
   * Parameters for findAllTimeSlotUsingGET
   */
  export interface FindAllTimeSlotUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveTimeSlotUsingPUT
   */
  export interface SaveTimeSlotUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TimeSlot;
  }

  /**
   * Parameters for saveTimeSlotUsingPATCH
   */
  export interface SaveTimeSlotUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TimeSlot;
  }

  /**
   * Parameters for timeSlotConsultationsUsingPOST
   */
  export interface TimeSlotConsultationsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotConsultationsUsingPUT
   */
  export interface TimeSlotConsultationsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotConsultationsUsingPATCH
   */
  export interface TimeSlotConsultationsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotConsultationsUsingGET
   */
  export interface TimeSlotConsultationsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * consultationId
     */
    consultationId: string;
  }

  /**
   * Parameters for timeSlotConsultationsUsingDELETE
   */
  export interface TimeSlotConsultationsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * consultationId
     */
    consultationId: string;
  }

  /**
   * Parameters for timeSlotDoctorInClinicsUsingPOST
   */
  export interface TimeSlotDoctorInClinicsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotDoctorInClinicsUsingPUT
   */
  export interface TimeSlotDoctorInClinicsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotDoctorInClinicsUsingPATCH
   */
  export interface TimeSlotDoctorInClinicsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for timeSlotDoctorInClinicsUsingGET
   */
  export interface TimeSlotDoctorInClinicsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId: string;
  }

  /**
   * Parameters for timeSlotDoctorInClinicsUsingDELETE
   */
  export interface TimeSlotDoctorInClinicsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * doctorinclinicId
     */
    doctorinclinicId: string;
  }

  /**
   * Parameters for timeSlotTimeSlotGroupUsingPOST
   */
  export interface TimeSlotTimeSlotGroupUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for timeSlotTimeSlotGroupUsingPUT
   */
  export interface TimeSlotTimeSlotGroupUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for timeSlotTimeSlotGroupUsingPATCH
   */
  export interface TimeSlotTimeSlotGroupUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { TimeSlotEntityService }
