import { ApiConfiguration } from './../../api/orders/api-configuration';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import moment from 'moment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { OrderControllerService } from 'src/app/api/orders/services';

@Component({
  selector: 'app-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss'],
  outputs: ['setSlot']
})
export class TimeSlotsComponent implements OnInit {

  constructor(
    private HttpClient: HttpClient,
    private ApiConfiguration: ApiConfiguration,
    private LocalStorage: LocalStorage,
    private OrderControllerService: OrderControllerService
  ) { }

  setSlot = new EventEmitter();
  timeSlots = [];
  @Input() selectedDate;
  @Input() data;
  homePincode;
  loading = false;
  slotIndex;

  getTime(slot) {
    return moment(slot.start).format('hh:mm A') + '-' + moment(slot.end).format('hh:mm A')
  }

  fetchTimeSlots(date) {
    const fDate = moment(date).format('YYYY-MM-DD');
    let startTime = moment(fDate + 'T06:00:00').format('YYYY-MM-DDTHH:mm:ssZ').toString();
    let endTime = moment(fDate + 'T16:00:00').format('YYYY-MM-DDTHH:mm:ssZ').toString();
    this.loading = true;
    let params = {
      startTime,
      endTime: null,
      duration: '1',
      interval: '60',
      pincode: this.homePincode
    }

    this.OrderControllerService.fetchOrderTimeSlotsUsingGET(params).subscribe((res: any) => {
      this.timeSlots = res;
      this.loading = false;
    }, err => {
      this.timeSlots = [];
      alert('Error in fetching slots')
    })
  }

  selectSlot(slot) {
    this.slotIndex = slot.start
    this.setSlot.emit(slot);
  }

  // disableBtn(slot){
  //   if(slot.numberOfProviders === slot.orderNumber){
  //     return true
  //   }
  // }

  getSelected(slot) {
    if (this.slotIndex) {
      let date1 = moment(this.slotIndex).format('YYYY-MM-DDHH:mm').toString();
      let date2 = moment(slot.start).format("YYYY-MM-DDHH:mm").toString();
      if (date1 == date2) {
        this.setSlot.emit(slot);
        return true;
      }
    }
  }

  ngOnInit() {
    if (this.data && this.data.diagnosticType && this.data.diagnosticType.startTime) {
      this.slotIndex = this.data.diagnosticType.startTime;
    }
    this.LocalStorage.getItem('cartOrder').subscribe((order: any) => {
      if (order.addressPin) {
        this.homePincode = order.addressPin
        if (this.homePincode) {
          this.fetchTimeSlots(this.selectedDate);
        }
      }
    })
  }

}
