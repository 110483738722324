import { Component, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DeclineReasonEntityService, DiagnosticEntityService } from 'src/app/api/emr/services';
import { FormControl } from '@angular/forms';
import { NewReasonDialogComponent } from 'src/app/shared/dialog/new-reason-dialog/new-reason-dialog.component';
import * as async from "async";
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent implements OnInit {

  reasons = [];
  filteredReasons: BehaviorSubject<any> = new BehaviorSubject([]);
  filterSubscription: Subscription;
  diagnosticIds;
  loadingReason: boolean = false;
  savingReason: boolean = false;
  searchingReason: boolean = false;
  selectedReason = new FormControl();

  constructor(
    private MatDialogRef: MatDialogRef<ReasonComponent>,
    private HttpClient: HttpClient,
    private MatDialog: MatDialog,
    private EmrApiConfiguration: ApiConfiguration
  ) {
  }

  addNewReason() {
    let dialogRef = this.MatDialog.open(NewReasonDialogComponent, {
      minWidth: '25vw'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedReason.setValue('');
        this.filteredReasons.next([]);
      }
    })
  }

  displayReason(reason) {
    return reason ? reason.reason : reason;
  }

  filterReasons(value) {
    const reason = (value && value.reason) ? value.reason : value;
    this.searchingReason = true;
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    this.filterSubscription = this.HttpClient.get(
      this.EmrApiConfiguration.rootUrl + '/api/v1/emr/decline-reason/find', {
        params: {
          reason
        }
      }
    ).subscribe(
      (res: any) => {
        if (res && res.content) {
          this.filteredReasons.next(res.content);
          this.searchingReason = false;
        } else {
          this.filteredReasons.next([]);
          this.searchingReason = false;
        }
      }
    )
  }

  save() {
    this.MatDialogRef.close(this.selectedReason.value);
  }

  ngOnInit() {
    this.selectedReason.valueChanges.subscribe(value => this.filterReasons(value));
  }

}
