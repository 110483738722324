/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesMedicineInClinic } from '../models/resources-medicine-in-clinic-';
import { ResourceMedicineInClinic } from '../models/resource-medicine-in-clinic-';
import { MedicineInClinic } from '../models/medicine-in-clinic';
import { ResourceClinic } from '../models/resource-clinic-';
import { ResourceMedicine } from '../models/resource-medicine-';
import { ResourcesPrescription } from '../models/resources-prescription-';
import { ResourcePrescription } from '../models/resource-prescription-';

/**
 * $ Proxy 183
 */
@Injectable()
class MedicineInClinicEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindAllMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineInClinicUsingGETResponse(params: MedicineInClinicEntityService.FindAllMedicineInClinicUsingGETParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindAllMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicineInClinicUsingGET(params: MedicineInClinicEntityService.FindAllMedicineInClinicUsingGETParams): Observable<ResourcesMedicineInClinic> {
    return this.findAllMedicineInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineInClinicUsingPOSTResponse(body: MedicineInClinic): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicineinclinics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicineInClinicUsingPOST(body: MedicineInClinic): Observable<ResourceMedicineInClinic> {
    return this.saveMedicineInClinicUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param batchNumber batchNumber
   * @return OK
   */
  countDistinctByBatchNumberMedicineInClinicUsingGETResponse(batchNumber?: string): Observable<HttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (batchNumber != null) __params = __params.set('batchNumber', batchNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/search/countDistinctByBatchNumber`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: number = null;
        _body = parseFloat(_resp.body as string);
        return _resp.clone({body: _body}) as HttpResponse<number>;
      })
    );
  }

  /**
   * @param batchNumber batchNumber
   * @return OK
   */
  countDistinctByBatchNumberMedicineInClinicUsingGET(batchNumber?: string): Observable<number> {
    return this.countDistinctByBatchNumberMedicineInClinicUsingGETResponse(batchNumber).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindByClinicIdAndMedicineIdMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `ids`: ids
   *
   * - `id`: id
   *
   * @return OK
   */
  findByClinicIdAndMedicineIdMedicineInClinicUsingGETResponse(params: MedicineInClinicEntityService.FindByClinicIdAndMedicineIdMedicineInClinicUsingGETParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/search/findByClinicIdAndMedicineId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindByClinicIdAndMedicineIdMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `ids`: ids
   *
   * - `id`: id
   *
   * @return OK
   */
  findByClinicIdAndMedicineIdMedicineInClinicUsingGET(params: MedicineInClinicEntityService.FindByClinicIdAndMedicineIdMedicineInClinicUsingGETParams): Observable<ResourceMedicineInClinic> {
    return this.findByClinicIdAndMedicineIdMedicineInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETResponse(params: MedicineInClinicEntityService.FindByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    (params.clinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('clinicId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/search/findByClinicIdInAndIsActiveTrue?projection=medicineInClinicForPharmacy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findByClinicIdInAndIsActiveTrueMedicineInClinicUsingGET(params: MedicineInClinicEntityService.FindByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETParams): Observable<ResourcesMedicineInClinic> {
    return this.findByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETResponse(params: MedicineInClinicEntityService.FindByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETParams): Observable<HttpResponse<ResourcesMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.clinicId || []).forEach((val, index) => {if (val != null) __params = __params.append('clinicId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/search/findByClinicIdInAndMedicineNameContainingAndIsActiveTrueAndMedicineIsActiveTrue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicineInClinic = null;
        _body = _resp.body as ResourcesMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.FindByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `clinicId`: clinicId
   *
   * @return OK
   */
  findByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGET(params: MedicineInClinicEntityService.FindByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETParams): Observable<ResourcesMedicineInClinic> {
    return this.findByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByMedicineIdMedicineInClinicUsingGETResponse(id?: number): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/search/findByMedicineId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByMedicineIdMedicineInClinicUsingGET(id?: number): Observable<ResourceMedicineInClinic> {
    return this.findByMedicineIdMedicineInClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineInClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}?projection=medicineInClinicForPharmacyComplete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicineInClinicUsingGET(id: number): Observable<ResourceMedicineInClinic> {
    return this.findByIdMedicineInClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.SaveMedicineInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineInClinicUsingPUTResponse(params: MedicineInClinicEntityService.SaveMedicineInClinicUsingPUTParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.SaveMedicineInClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineInClinicUsingPUT(params: MedicineInClinicEntityService.SaveMedicineInClinicUsingPUTParams): Observable<ResourceMedicineInClinic> {
    return this.saveMedicineInClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteMedicineInClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteMedicineInClinicUsingDELETE(id: number): Observable<void> {
    return this.deleteMedicineInClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.SaveMedicineInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineInClinicUsingPATCHResponse(params: MedicineInClinicEntityService.SaveMedicineInClinicUsingPATCHParams): Observable<HttpResponse<ResourceMedicineInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicineInClinic = null;
        _body = _resp.body as ResourceMedicineInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicineInClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.SaveMedicineInClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicineInClinicUsingPATCH(params: MedicineInClinicEntityService.SaveMedicineInClinicUsingPATCHParams): Observable<ResourceMedicineInClinic> {
    return this.saveMedicineInClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineInClinicClinicUsingGETResponse(id: number): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineInClinicClinicUsingGET(id: number): Observable<ResourceClinic> {
    return this.medicineInClinicClinicUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicClinicUsingPOSTResponse(params: MedicineInClinicEntityService.MedicineInClinicClinicUsingPOSTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicClinicUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicClinicUsingPOST(params: MedicineInClinicEntityService.MedicineInClinicClinicUsingPOSTParams): Observable<ResourceClinic> {
    return this.medicineInClinicClinicUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicClinicUsingPUTResponse(params: MedicineInClinicEntityService.MedicineInClinicClinicUsingPUTParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicClinicUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicClinicUsingPUT(params: MedicineInClinicEntityService.MedicineInClinicClinicUsingPUTParams): Observable<ResourceClinic> {
    return this.medicineInClinicClinicUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineInClinicClinicUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineInClinicClinicUsingDELETE(id: number): Observable<void> {
    return this.medicineInClinicClinicUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicClinicUsingPATCHResponse(params: MedicineInClinicEntityService.MedicineInClinicClinicUsingPATCHParams): Observable<HttpResponse<ResourceClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceClinic = null;
        _body = _resp.body as ResourceClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceClinic>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicClinicUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicClinicUsingPATCH(params: MedicineInClinicEntityService.MedicineInClinicClinicUsingPATCHParams): Observable<ResourceClinic> {
    return this.medicineInClinicClinicUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineInClinicMedicineUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineInClinicMedicineUsingGET(id: number): Observable<ResourceMedicine> {
    return this.medicineInClinicMedicineUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicMedicineUsingPOSTResponse(params: MedicineInClinicEntityService.MedicineInClinicMedicineUsingPOSTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicMedicineUsingPOST(params: MedicineInClinicEntityService.MedicineInClinicMedicineUsingPOSTParams): Observable<ResourceMedicine> {
    return this.medicineInClinicMedicineUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicMedicineUsingPUTResponse(params: MedicineInClinicEntityService.MedicineInClinicMedicineUsingPUTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicMedicineUsingPUT(params: MedicineInClinicEntityService.MedicineInClinicMedicineUsingPUTParams): Observable<ResourceMedicine> {
    return this.medicineInClinicMedicineUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineInClinicMedicineUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineInClinicMedicineUsingDELETE(id: number): Observable<void> {
    return this.medicineInClinicMedicineUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicMedicineUsingPATCHResponse(params: MedicineInClinicEntityService.MedicineInClinicMedicineUsingPATCHParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicMedicineUsingPATCH(params: MedicineInClinicEntityService.MedicineInClinicMedicineUsingPATCHParams): Observable<ResourceMedicine> {
    return this.medicineInClinicMedicineUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineInClinicPrescriptionsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicineInClinicPrescriptionsUsingGET_1(id: number): Observable<ResourcesPrescription> {
    return this.medicineInClinicPrescriptionsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingPOSTResponse(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPOSTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingPOST(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPOSTParams): Observable<ResourcesPrescription> {
    return this.medicineInClinicPrescriptionsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingPUTResponse(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPUTParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingPUT(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPUTParams): Observable<ResourcesPrescription> {
    return this.medicineInClinicPrescriptionsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicineInClinicPrescriptionsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicineInClinicPrescriptionsUsingDELETE_1(id: number): Observable<void> {
    return this.medicineInClinicPrescriptionsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingPATCHResponse(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPATCHParams): Observable<HttpResponse<ResourcesPrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescription = null;
        _body = _resp.body as ResourcesPrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingPATCH(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingPATCHParams): Observable<ResourcesPrescription> {
    return this.medicineInClinicPrescriptionsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingGETResponse(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingGETParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingGETParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   *
   * @return OK
   */
  medicineInClinicPrescriptionsUsingGET(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingGETParams): Observable<ResourcePrescription> {
    return this.medicineInClinicPrescriptionsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  medicineInClinicPrescriptionsUsingDELETEResponse(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/medicineinclinics/${params.id}/prescriptions/${params.prescriptionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionId`: prescriptionId
   *
   * - `id`: id
   */
  medicineInClinicPrescriptionsUsingDELETE(params: MedicineInClinicEntityService.MedicineInClinicPrescriptionsUsingDELETEParams): Observable<void> {
    return this.medicineInClinicPrescriptionsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module MedicineInClinicEntityService {

  /**
   * Parameters for findAllMedicineInClinicUsingGET
   */
  export interface FindAllMedicineInClinicUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByClinicIdAndMedicineIdMedicineInClinicUsingGET
   */
  export interface FindByClinicIdAndMedicineIdMedicineInClinicUsingGETParams {

    /**
     * ids
     */
    ids?: number;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByClinicIdInAndIsActiveTrueMedicineInClinicUsingGET
   */
  export interface FindByClinicIdInAndIsActiveTrueMedicineInClinicUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * clinicId
     */
    clinicId?: Array<number>;
  }

  /**
   * Parameters for findByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGET
   */
  export interface FindByClinicIdInAndMedicineNameContainingAndIsActiveTrueMedicineInClinicUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * clinicId
     */
    clinicId?: Array<number>;
  }

  /**
   * Parameters for saveMedicineInClinicUsingPUT
   */
  export interface SaveMedicineInClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicineInClinic;
  }

  /**
   * Parameters for saveMedicineInClinicUsingPATCH
   */
  export interface SaveMedicineInClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicineInClinic;
  }

  /**
   * Parameters for medicineInClinicClinicUsingPOST
   */
  export interface MedicineInClinicClinicUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineInClinicClinicUsingPUT
   */
  export interface MedicineInClinicClinicUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineInClinicClinicUsingPATCH
   */
  export interface MedicineInClinicClinicUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineInClinicMedicineUsingPOST
   */
  export interface MedicineInClinicMedicineUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineInClinicMedicineUsingPUT
   */
  export interface MedicineInClinicMedicineUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineInClinicMedicineUsingPATCH
   */
  export interface MedicineInClinicMedicineUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicineInClinicPrescriptionsUsingPOST
   */
  export interface MedicineInClinicPrescriptionsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineInClinicPrescriptionsUsingPUT
   */
  export interface MedicineInClinicPrescriptionsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineInClinicPrescriptionsUsingPATCH
   */
  export interface MedicineInClinicPrescriptionsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for medicineInClinicPrescriptionsUsingGET
   */
  export interface MedicineInClinicPrescriptionsUsingGETParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for medicineInClinicPrescriptionsUsingDELETE
   */
  export interface MedicineInClinicPrescriptionsUsingDELETEParams {

    /**
     * prescriptionId
     */
    prescriptionId: string;

    /**
     * id
     */
    id: number;
  }
}

export { MedicineInClinicEntityService }
