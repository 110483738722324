import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-skip-barcode-dialog',
  templateUrl: './skip-barcode-dialog.component.html',
  styleUrls: ['./skip-barcode-dialog.component.scss']
})
export class SkipBarcodeDialogComponent implements OnInit {
  @Output() closeScanBarcode = new EventEmitter<string>();
  isuncover:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<SkipBarcodeDialogComponent>,
  ) { }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
  }

  enterManually(){
    this.dialogRef.close();
  }

  closeDialog(){
    this.dialogRef.close();
    this.closeScanBarcode.emit();
  }
}
