import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import {
  PatientEntityService,
  ClinicEntityService,
  ConsultationEntityService,
  ConsultationControllerService,
} from "src/app/api/emr/services";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  MatAutocompleteSelectedEvent,
} from "@angular/material";
import {
  SymptomTypes,
  DoctorInClinic,
  ResourceConsultation,
} from "../../api/emr/models";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { HttpClient } from "@angular/common/http";
import { ApiConfiguration } from "src/app/api/emr/api-configuration";
import { MatDialog, MatSnackBar } from "@angular/material";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
} from "@angular/material-moment-adapter";
import { DiagnosisTypeControllerService } from "../../api/emr/services";
import { BehaviorSubject, Subscription } from "rxjs";
import { SendOtpDialogComponent } from "../dialog/send-otp-dialog/send-otp-dialog.component";
import { MultiplePatientDialogComponent } from "../multiple-patient-dialog/multiple-patient-dialog.component";
import { EmbeddedApiService } from "../../services/embedded-api.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { PATTERN_VALIDATOR } from "@angular/forms/src/directives/validators";
import moment from "moment";
import { ScanBarcodeDialogComponent } from "../dialog/scan-barcode-dialog/scan-barcode-dialog.component";

@Component({
  selector: "app-add-patient-information",
  templateUrl: "./add-patient-information.component.html",
  styleUrls: ["./add-patient-information.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-IN" },
  ],
  outputs: ["showGeneralInformation", "addPatientToQueue"],
  inputs: [
    "doctorInClinicIds",
    "consultationId",
    "usedBy",
    "isSameDaySlot",
    "addingToQueue",
  ],
})
export class AddPatientInformationComponent implements OnInit {
  searchingPatient: boolean;
  addPatientToQueue = new EventEmitter();
  filteredPatients = new BehaviorSubject([]);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allDoctorsInClinic: Array<DoctorInClinic>;
  filteredSymptoms: BehaviorSubject<SymptomTypes[]> = new BehaviorSubject([]);
  filterSubscription: Subscription;
  maxDate = new Date();
  patientSearchForm: FormGroup;
  medicalInformationForm: FormGroup;
  symptoms = [];
  consultationId;
  minDateForMatDateTimePicker: Date;
  usedBy;
  doctorInClinicIds;
  isSameDaySlot;
  isOtpSkipped: boolean;
  addNewClicked: boolean = false;

  @ViewChild("symptomSearchInput") symptomSearchInput: ElementRef;
  allClinics = [];
  consultation: any;
  loadingClinics: boolean;
  patientId: any;
  doctorName: any;
  appointmentTime: any;
  isLabTestTaken: any;
  isMedicineTaken: any;
  showLastConsultationDetails: boolean;
  showSubscriptionDetails: boolean;
  savingPatient: boolean;
  patientLink: any;
  symptomsForRecommendation = [];
  canApplyVisitSubscription: boolean;
  canApplyOnlineSubscription: boolean;
  patientSubscriptionId: any;
  isFollowUp: boolean;
  isUsedSubscription = false;
  clinicName: any;
  subscriptionValidFrom: any;
  subscriptionValidTo: any;
  subscriptionIsActive: any;
  printpageData: any;
  isuncover: boolean = false;

  constructor(
    private FormBuilder: FormBuilder,
    private MatDialog: MatDialog,
    private snackBar: MatSnackBar,
    private ConsultationControllerService: ConsultationControllerService,
    private clinicEntityService: ClinicEntityService,
    private PatientEntityService: PatientEntityService,
    private ApiConfiguration: ApiConfiguration,
    private ConsultationEntityService: ConsultationEntityService,
    private HttpClient: HttpClient,
    private consultationEmbeddedApiService: EmbeddedApiService<ResourceConsultation>,
    private diagnosisTypeControllerService: DiagnosisTypeControllerService
  ) {
    this.minDateForMatDateTimePicker = new Date();
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if (isDermaHost > -1) {
      this.isuncover = true;
    }
    this.patientSearchForm = this.FormBuilder.group({
      name: ["", Validators.required],
      phone: [
        "",
        [Validators.required, Validators.pattern("^[6-9]{1}[0-9]{9}$")],
      ],
      email: ["", Validators.email],
      dateofbirth: ["", Validators.required],
      age: ["", [Validators.required, Validators.max(120), Validators.min(0)]],
      ageType: "years",
      gender: ["", Validators.required],
      existingPatient: false,
      dicId: null,
    });

    this.medicalInformationForm = this.FormBuilder.group({
      clinic: [{ value: "", disabled: true }, Validators.required],
      patientSelfLink: "",
      patientId: "",
      appointmentTime: null,

      doctorInClinic: [{ value: "", disabled: true }, Validators.required],
      symptoms: [],
      note: "",
      isFollowUp: [null, Validators.required],
      applySubscription: [false],
      scheduleDate: [new Date(), Validators.required],
      scheduleTime: [new Date(), Validators.required],
      vitals: this.FormBuilder.array([]),
      cough: [null],
      fever: [null],
      bodyTemperature: [null],
      shortnessOfBreath: [null],
      diffBreathing: [null],
      preExistingConditions: [],
      allergies: [],
      isCovidSuspect: null,
      covidTravelHistoryState: [null],
      covidContactState: [null],
      patient: null,
      isOtpSkipped: null,
      endTime: [new Date(new Date().getTime() + 30 * 60000), Validators.required],
    });

    this.patientSearchForm
      .get("name")
      .valueChanges.subscribe((value) => this.filterPatients(value, "name"));
    this.patientSearchForm
      .get("phone")
      .valueChanges.subscribe((value) => this.filterPatients(value, "phone"));
    // this.medicalInformationForm.get('fever').valueChanges.subscribe(value => {
    //   if(value) {
    //     this.medicalInformationForm.get('bodyTemperature').setValidators(Validators.required);
    //   } else {
    //     this.medicalInformationForm.get('bodyTemperature').clearValidators();
    //   }
    // })
    this.medicalInformationForm.get("scheduleDate").disable();
    this.medicalInformationForm.get("endTime").disable();
    this.medicalInformationForm.get("scheduleTime").disable();
    this.medicalInformationForm
      .get("symptoms")
      .valueChanges.subscribe((value) => {
        this.filterSymptoms(value);
      });

    this.patientSearchForm.get("age").valueChanges.subscribe((value) => {
      this.setDateOfBirth(value, "years");
    });

    this.patientSearchForm
      .get("dateofbirth")
      .valueChanges.subscribe((value) => {
        if (
          this.patientSearchForm.get("age").value !=
          moment().diff(value, "years")
        ) {
          this.patientSearchForm
            .get("age")
            .setValue(moment().diff(value, "years"));
        }
      });

    this.medicalInformationForm
      .get("clinic")
      .valueChanges.subscribe((clinic) => {
        this.allDoctorsInClinic = clinic.doctorInClinics;
        if (this.consultationId) {
          let selectedDoctor = this.allDoctorsInClinic.filter(
            (doctorInClinic) =>
              doctorInClinic.id === this.consultation.doctorInClinic.id
          )[0];
          this.medicalInformationForm
            .get("doctorInClinic")
            .setValue(selectedDoctor);
          if (this.usedBy != "bookAppointment") {
            this.medicalInformationForm.get("doctorInClinic").enable();
            if (clinic && clinic.doctorInClinics.length && !selectedDoctor) {
              this.medicalInformationForm
                .get("doctorInClinic")
                .setValue(clinic.doctorInClinics[0]);
            }
          }
        } else {
          if (clinic && clinic.doctorInClinics.length === 1) {
            this.medicalInformationForm
              .get("doctorInClinic")
              .setValue(clinic.doctorInClinics[0]);
          } else {
            if (this.usedBy != "bookAppointment") {
              this.medicalInformationForm.get("doctorInClinic").enable();
              if (clinic && clinic.doctorInClinics.length) {
                this.medicalInformationForm
                  .get("doctorInClinic")
                  .setValue(clinic.doctorInClinics[0]);
              }
            }
          }
        }
        this.clinicName = clinic.name;
      });

    this.medicalInformationForm
      .get("doctorInClinic")
      .valueChanges.subscribe((doctor) => {
        this.getPatientInfo();
      });

    this.getAllClinics();
  }

  getAllClinics() {
    this.clinicEntityService
      .findDistinctByDoctorInClinicsIdInClinicUsingGET(this.doctorInClinicIds)
      .subscribe(
        (clinics) => {
          this.allClinics = clinics._embedded.clinics;
          this.allClinics.forEach((clinic) => {
            clinic.doctorInClinics = clinic.doctorInClinics.filter(
              (doctorInClinicIds) => {
                return doctorInClinicIds.isActive;
              }
            );
          });

          if (this.consultationId) {
            this.ConsultationEntityService.findByIdConsultationUsingGET(
              this.consultationId
            ).subscribe(
              (consultation) => {
                this.consultation =
                  this.consultationEmbeddedApiService.parse(consultation);

                let selectedClinic = this.allClinics.filter(
                  (clinic) =>
                    clinic.id === this.consultation.doctorInClinic.clinic.id
                )[0];
                this.medicalInformationForm
                  .get("clinic")
                  .setValue(selectedClinic);
              },
              (err) => { }
            );
          } else {
            if (this.allClinics.length === 1) {
              this.allDoctorsInClinic = this.allClinics[0].doctorInClinics;
              this.medicalInformationForm
                .get("clinic")
                .setValue(this.allClinics[0]);
            } else {
              if (this.usedBy != "bookAppointment") {
                this.medicalInformationForm.get("clinic").enable();
                this.medicalInformationForm
                  .get("clinic")
                  .setValue(this.allClinics[0]);
              }
            }
          }
          this.loadingClinics = false;
        },
        (err) => { }
      );
  }

  sendOtp() {
    this.isUsedSubscription =
      this.medicalInformationForm.get("applySubscription").value;
    let dialogRef = this.MatDialog.open(SendOtpDialogComponent, {
      maxHeight: "120vh",
      minWidth: "40vw",
      disableClose: true,
      data: {
        mobile: this.patientSearchForm.get("phone").value,
        clinicName: this.clinicName,
        patientId: this.patientId,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.data) {
        this.isOtpSkipped = res.isOtpSkipped;
        this.savePatientDetails();
      }
    });
  }

  setDateOfBirth(ageNumber, ageSpan) {
    let date = moment().subtract(ageNumber, ageSpan).format("YYYY-MM-DD");
    this.patientSearchForm.get("dateofbirth").setValue(date);
  }

  checkIfPatientExists() {
    const mobileNumber = this.patientSearchForm.get("phone").value;
    const name = this.patientSearchForm.get("name").value;
    if (this.filteredPatients.value.length < 1) {
      return;
    }
    let dialogRef = this.MatDialog.open(MultiplePatientDialogComponent, {
      disableClose: true,
      minWidth: "800px",
      data: {
        mobileNumber: mobileNumber,
        name: name,
        patients: this.filteredPatients,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectPatient(result.selectedPatient);
      }
    });
  }

  filterPatients(val: any, type) {
    const search = (val && val.name) || val;
    this.patientSearchForm.get("email").reset();
    this.patientSearchForm.get("dateofbirth").reset();
    this.patientSearchForm.get("gender").reset();
    this.patientSearchForm.get("existingPatient").reset();
    this.medicalInformationForm.get("isFollowUp").setValue(false);
    this.isFollowUp = false;
    this.canApplyVisitSubscription = false;
    this.showLastConsultationDetails = false;
    this.showSubscriptionDetails = false;

    if (!search || search.length < 3) {
      return;
    }

    this.searchingPatient = true;
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    this.filterSubscription =
      this.PatientEntityService.findDistinctByNameContainingOrPhoneContainingPatientUsingGET(
        {
          name: search,
          phone: search,
        }
      ).subscribe(
        (res) => {
          if (res._embedded && res._embedded.patients) {
            this.filteredPatients.next(res._embedded.patients);
          } else {
            this.filteredPatients.next([]);
          }
          this.searchingPatient = false;
        },
        (err) => {
          this.filteredPatients.next([]);
          this.searchingPatient = false;
        }
      );
  }

  filterSymptoms(val: any): void {
    const that = this;
    const search = (val.name || val).toLowerCase();
    if (search.length < 3) {
      return;
    }

    this.diagnosisTypeControllerService
      .findInSymptomsTypeUsingGET(search)
      .subscribe((response: any) => {
        let symptoms =
          response && response._embedded && response._embedded.symptomTypes;
        this.filteredSymptoms.next(symptoms);
      });
  }

  selectPatient(patient) {
    this.addNewClicked = true;
    this.patientSearchForm.get("name").setValue(patient.name);
    this.patientSearchForm.get("phone").setValue(patient.phone);
    this.patientSearchForm
      .get("gender")
      .setValue(
        patient.gender
          ? patient.gender[0].toUpperCase() +
          patient.gender.substr(1).toLowerCase()
          : ""
      );
    this.patientSearchForm.get("dateofbirth").reset();
    this.patientSearchForm.get("email").reset();
    this.patientId = patient.id;
    if (this.patientId) {
      this.patientSearchForm.get("existingPatient").setValue(true);
    } else {
      this.patientSearchForm.get("existingPatient").setValue(false);
    }
    this.getPatientInfo();
  }

  getPatientInfo() {
    let doctor = this.medicalInformationForm.get("doctorInClinic").value;
    this.showLastConsultationDetails = false;
    this.showSubscriptionDetails = false;
    this.checkFollowUp();
    this.checkSubscription();
    this.patientLastSubscriptions();
    if (doctor && this.patientId) {
      this.ConsultationEntityService.findByDoctorIdAndPatientIdConsultationInfoUsingGET(
        {
          patientId: this.patientId,
          doctorId: doctor && doctor.doctor.id,
        }
      ).subscribe(
        (res: any) => {
          if (res.consultationId) {
            this.showLastConsultationDetails = true;
            this.showLastConsultationDetails = true;
            this.doctorName = res.doctorInClinic
              ? res.doctorInClinic.doctor.name
              : (this.showLastConsultationDetails = false);
            this.appointmentTime = moment(new Date(res.appointmentTime))
              .add(2, "minute")
              .toISOString();
            this.isLabTestTaken = res.isLabTestTaken;
            this.isMedicineTaken = res.isMedicineTaken;
          } else {
            this.showLastConsultationDetails = false;
          }
          this.symptomsForRecommendation = res.symptomsForRecommendation;
          this.patientSearchForm.get("email").setValue(res.patient.email);
          this.patientSearchForm
            .get("age")
            .setValue(moment().diff(res.patient.dateofbirth, "years"));
          this.patientSearchForm
            .get("dateofbirth")
            .setValue(new Date(res.patient.dateofbirth));
        },
        (err) => {
          this.showLastConsultationDetails = false;
        }
      );
    }
  }

  checkFollowUp() {
    let body = {
      appointmentTime: new Date(
        this.medicalInformationForm.get("scheduleDate").value.toDateString() +
        " " +
        this.medicalInformationForm.get("scheduleTime").value.toTimeString()
      ),
      dicId:
        this.medicalInformationForm.get("doctorInClinic").value &&
        this.medicalInformationForm.get("doctorInClinic").value.id,
      patientId: this.patientId,
      isOnline: false,
    };
    this.HttpClient.post(
      this.ApiConfiguration.rootUrl + "/api/v1/emr/consultation/fee",
      body
    ).subscribe(
      (response: any) => {
        if (response && response.isFollowUp) {
          this.medicalInformationForm.get("isFollowUp").setValue(true);
          this.isFollowUp = true;
          return;
        }
        this.medicalInformationForm.get("isFollowUp").setValue(false);
        this.isFollowUp = false;
      },
      (err) => {
        this.medicalInformationForm.get("isFollowUp").setValue(false);
        this.isFollowUp = false;
        if (err && err.error && err.error.message) {
          alert(err.error.message);
        }
      }
    );
  }

  patientLastSubscriptions() {
    if (!this.patientId) return;
    this.ConsultationControllerService.getPatientLastSubscriptionsUsingGET(this.patientId).subscribe(
      (resposne: any) => {
        this.showSubscriptionDetails = false;
        if (resposne && resposne.validTill && resposne.validFrom) {
          this.showSubscriptionDetails = true;
          this.subscriptionValidFrom = resposne.validFrom;
          this.subscriptionValidTo = resposne.validTill;
          this.subscriptionIsActive = (resposne.isActive) ? "Active" : "InActive";
        }
      },
      (err) => {
        if (err && err.error && err.error.message) {
          alert(err.error.message);
        }
        this.showSubscriptionDetails = false;
      });
  }

  checkSubscription() {
    let body = {
      patientId: this.patientId,
    };
    this.canApplyVisitSubscription = false;
    this.ConsultationControllerService.subscriptionValidityUsingPOST(
      body
    ).subscribe(
      (response: any) => {
        if (response) {
          if (response.isApplicableForVisitConsultation) {
            this.canApplyVisitSubscription =
              response.isApplicableForVisitConsultation;
            this.medicalInformationForm.get("applySubscription").setValue(true);
            return;
          }
        }
        this.medicalInformationForm.get("applySubscription").setValue(false);
      },
      (err) => {
        if (err && err.error && err.error.message) {
          alert(err.error.message);
        }
        this.medicalInformationForm.get("applySubscription").setValue(false);
      }
    );
  }

  savePatientDetails() {
    let patientToSaveResponse;
    this.savingPatient = true;
    this.patientSearchForm
      .get("dateofbirth")
      .setValue(
        moment(this.patientSearchForm.get("dateofbirth").value).format(
          "YYYY-MM-DD"
        )
      );
    if (this.medicalInformationForm.get("doctorInClinic").value) {
      this.patientSearchForm
        .get("dicId")
        .setValue(this.medicalInformationForm.get("doctorInClinic").value.id);
    }
    let generalInformation = this.patientSearchForm.getRawValue();
    if (generalInformation.existingPatient) {
      patientToSaveResponse = this.PatientEntityService.savePatientUsingPATCH({
        id: this.patientId,
        body: generalInformation,
      });
    } else {
      patientToSaveResponse =
        this.PatientEntityService.savePatientUsingPOST(generalInformation);
    }
    patientToSaveResponse.subscribe(
      (patient) => {
        this.medicalInformationForm
          .get("patientSelfLink")
          .setValue(patient._links.self.href);
        this.medicalInformationForm.get("patientId").setValue(patient.id);
        this.medicalInformationForm
          .get("isOtpSkipped")
          .setValue(this.isOtpSkipped);
        this.medicalInformationForm.get("isFollowUp").setValue(this.isFollowUp);
        this.medicalInformationForm
          .get("applySubscription")
          .setValue(this.isUsedSubscription);
        this.medicalInformationForm.get("patient").setValue({
          patientName: patient.name,
          patientPhone: patient.phone,
          gender: patient.gender,
          dob: patient.dateofbirth,
        });
        this.patientId = patient.id;
        this.medicalInformationForm.get("appointmentTime").setValue(
          moment(
            new Date(
              this.medicalInformationForm
                .get("scheduleDate")
                .value.toDateString() +
              " " +
              this.medicalInformationForm
                .get("scheduleTime")
                .value.toTimeString()
            )
          )
            .add(2, "minute")
            .toISOString()
        );
        this.addToQueue();
      },
      (err) => {
        if (err && err.error && err.error.message) {
          this.snackBar.open(err.error.message, "", {
            duration: 5000,
            verticalPosition: "top",
          });
          this.savingPatient = false;
        }
      }
    );
  }

  addToQueue() {
    let formValue = this.medicalInformationForm.getRawValue();
    formValue.cough ? this.addSymptom(1) : null;
    formValue.fever ? this.addSymptom(0) : null;
    formValue.shortnessOfBreath ? this.addSymptom(3) : null;
    formValue.diffBreathing ? this.addSymptom(2) : null;
    formValue.preExistingConditions = [];
    formValue.symptoms = this.symptoms;
    formValue.allergies = [];
    this.addPatientToQueue.emit(formValue);
  }

  selectClinic(clinic) {
    this.medicalInformationForm.get("clinic").setValue(clinic);
  }

  selectDoctor(doctor) {
    this.medicalInformationForm.get("doctorInClinic").setValue(doctor);
  }

  compareDoctorClinic(v1, v2) {
    return v1 === v2;
  }

  addSymptom(value) {
    let symp = [
      {
        name: "Fever",
        id: 163,
        lastModified: "2018-08-01T20:38:00.000+0000",
        code: "5d5ef712-1556-11e9-a197-0256ba811156",
        isActive: true,
        critical: false,
      },
      {
        lastModified: "2018-08-01T20:38:39.000+0000",
        name: "Cough",
        id: 63,
        code: "5d5eaccd-1556-11e9-a197-0256ba811156",
        isActive: false,
        critical: false,
      },
      {
        name: "Difficulty In Breathing",
        id: 1153,
        lastModified: "2018-11-30T10:37:00.000+0000",
        code: "5d632532-1556-11e9-a197-0256ba811156",
        isActive: true,
        critical: false,
      },
      {
        lastModified: "2018-08-01T20:38:40.000+0000",
        code: "5d5f963f-1556-11e9-a197-0256ba811156",
        critical: false,
        isActive: false,
        name: "Shortness of breath",
        id: 355,
      },
    ];
    this.symptoms.push(symp[value]);
  }

  onSymptomSelect(event: MatAutocompleteSelectedEvent): void {
    if (event.option && event.option.value.id) {
      this.symptoms.push(event.option.value);
      this.medicalInformationForm.get("symptoms").setValue("");
      this.symptomSearchInput.nativeElement.value = "";
    } else {
      let name = event.option.value;
      let symptoms = {
        name,
      };
      this.diagnosisTypeControllerService
        .addSymptomsUsingPOST(symptoms)
        .subscribe((response: any) => {
          if (response) {
            let symptoms = response;
            this.symptoms.push(symptoms);
            this.medicalInformationForm.get("symptoms").setValue("");
            this.symptomSearchInput.nativeElement.value = "";
          }
        });
    }
  }

  removeSymptom(symptom: string): void {
    const index = this.symptoms.indexOf(symptom);

    if (index >= 0) {
      this.symptoms.splice(index, 1);
    }

    this.symptomSearchInput.nativeElement.focus();
  }
  printPdfOfAppointment() {
    let dialogRef = this.MatDialog.open(ScanBarcodeDialogComponent, {
      maxHeight: "120vh",
      minWidth: "40vw",
      disableClose: true,
      data: {
        data: this.printpageData
      },
    });
  }
}
