export class ConsultationPriceContext {
  orderSummary: any = {};

  constructor(private consultation: any) {
    if (consultation) {
      this.orderSummary.totalConsultationPrice = this.calculateConsultationPrice(consultation.isPrepaid ? 0 : consultation.offerPrice, consultation.doctorInClinic);
      this.orderSummary.totalMedicinePrice = this.calculateMedicinePrice(consultation.prescriptions);
      this.orderSummary.totalMedicineDisplayPrice = this.calculateMedicineDisplayPrice(consultation.prescriptions);
      this.orderSummary.totalDiagnosticsPrice = this.calculateDiagnosticsPrice(consultation.diagnostics);
      this.orderSummary.totalDiagnosticsDisplayPrice = this.calculateDiagnosticsDisplayPrice(consultation.diagnostics);
      this.orderSummary.totalProceduresDisplayPrice = this.calculateProceduresDisplayPrice(consultation.procedures);
      this.orderSummary.totalProceduresPrice = this.calculateProceduresPrice(consultation.procedures);
      this.orderSummary.totalDeliveryCharges = 0;
      this.orderSummary.totalOtherCharges = this.calculateOtherCharges(consultation.diagnostics);
      this.orderSummary.payLaterAmount =
        this.calculatePayLaterAmountForMedicines(consultation.payLaterForDeliveryMedicines, consultation.prescriptions)
        + this.calculatePayLaterAmountForDiagnostics(consultation.payLaterForHomeCollectionOnTests, consultation.diagnostics);
    }
  }

  private calculatePayLaterAmountForMedicines(payLater, prescriptions) {
    if (!payLater) {
      return 0;
    }

    return prescriptions.reduce(function (accumulator, currentPrescription, currentIndex, array) {
      const currentValue = currentPrescription.medicine;
      if (!currentPrescription.isInvoiced) {
        return accumulator;
      } else if (currentPrescription.isDeliverable) {
        if (!currentPrescription.unitQuantity) {
          return accumulator + 1 * currentValue.price;
        } else {
          return accumulator + currentPrescription.unitQuantity * currentValue.price;
        }
      } else {
        return accumulator;
      }
    }, 0);
  }

  private calculatePayLaterAmountForMedicinesDisplayPrice(payLater, prescriptions) {
    if (!payLater) {
      return 0;
    }

    return prescriptions.reduce(function (accumulator, currentPrescription, currentIndex, array) {
      const currentValue = currentPrescription.medicine;
      if (!currentPrescription.isInvoiced) {
        return accumulator;
      } else if (currentPrescription.isDeliverable) {
        if (!currentPrescription.unitQuantity) {
          return accumulator + 1 * currentValue.displayPrice;
        } else {
          return accumulator + currentPrescription.unitQuantity * currentValue.displayPrice;
        }
      } else {
        return accumulator;
      }
    }, 0);
  }

  private calculatePayLaterAmountForDiagnostics(payLater, diagnostics) {
    return diagnostics.filter(diagnostic => diagnostic.diagnosticTypeInLab).reduce(function (accumulator, currentDiagnostics, currentIndex, array) {
      if (!currentDiagnostics.isInvoiced) {
        return accumulator;
      } else if (currentDiagnostics.deferred) {
        return accumulator;
      } else if (currentDiagnostics.isPayLater) {
        return accumulator + currentDiagnostics.diagnosticTypeInLab.price;
      } else {
        return accumulator;
      }
    }, 0);
  }

  private calculatePayLaterAmountForDiagnosticsDisplayPrice(payLater, diagnostics) {
    return diagnostics.filter(diagnostic => diagnostic.diagnosticTypeInLab).reduce(function (accumulator, currentDiagnostics, currentIndex, array) {
      if (!currentDiagnostics.isInvoiced) {
        return accumulator;
      } else if (currentDiagnostics.deferred) {
        return accumulator;
      } else if (currentDiagnostics.isPayLater) {
        return accumulator + currentDiagnostics.diagnosticTypeInLab.displayPrice;
      } else {
        return accumulator;
      }
    }, 0);
  }

  private calculateConsultationPrice(offerPrice: any, doctorInClinic: any) {
    if (!doctorInClinic) {
      return 0;
    }

    if (offerPrice === undefined || offerPrice === '' || offerPrice === null) {
      return doctorInClinic.consultationFee;
    }

    return offerPrice;
  }

  private calculateMedicinePrice(prescriptions) {
    return prescriptions.reduce(function (accumulator, currentPrescription, currentIndex, array) {
      const currentValue = currentPrescription.medicine;
      if (!currentPrescription.isInvoiced) {
        return accumulator;
      } else if (!currentPrescription.isDeliverable && currentPrescription.medicineInventories.length) {
        return accumulator + currentPrescription.medicineInventories.reduce((sum, mi) => sum + (mi.unitQuantity * mi.price), 0)
      } else if (!currentPrescription.unitQuantity) {
        return accumulator + (1 * (currentValue.price ? currentValue.price :0));
      } else {
        return accumulator + (currentPrescription.unitQuantity *(currentValue.price ? currentValue.price :0));
      }
    }, 0);
  }

  private calculateMedicineDisplayPrice(prescriptions) {
    return prescriptions.reduce(function (accumulator, currentPrescription, currentIndex, array) {
      const currentValue = currentPrescription.medicine;
      if (!currentPrescription.isInvoiced) {
        return accumulator;
      } else if (!currentPrescription.isDeliverable && currentPrescription.medicineInventories.length) {
        return accumulator + currentPrescription.medicineInventories.reduce((sum, mi) => sum + (mi.unitQuantity * mi.displayPrice), 0)
      } else if (!currentPrescription.unitQuantity) {
        return accumulator + (1 * (currentValue.displayPrice ? currentValue.displayPrice : 0));
      } else {
        return accumulator + (currentPrescription.unitQuantity * (currentValue.displayPrice ? currentValue.displayPrice : 0));
      }
    }, 0);
  }

  private calculateProceduresPrice(medicalProcedures) {
    return medicalProcedures.reduce(function (accumulator, currentMedicalProcedure, currentIndex, array) {
      const currentValue = currentMedicalProcedure.procedureType;
      if (!currentMedicalProcedure.isInvoiced) {
        return accumulator;
      } else if (!currentMedicalProcedure.unitQuantity) {
        return accumulator + (1 * (currentValue.price ? currentValue.price : 0));
      } else {
        return accumulator + (currentMedicalProcedure.unitQuantity * (currentValue.price ? currentValue.price : 0));
      }
    }, 0);
  }

  private calculateProceduresDisplayPrice(medicalProcedures) {
    return medicalProcedures.reduce(function (accumulator, currentMedicalProcedure, currentIndex, array) {
      const currentValue = currentMedicalProcedure.procedureType;
      if (!currentMedicalProcedure.isInvoiced) {
        return accumulator;
      } else if (!currentMedicalProcedure.unitQuantity) {
        return accumulator + (1 * (currentValue.displayPrice ? currentValue.displayPrice : 0));
      } else {
        return accumulator + (currentMedicalProcedure.unitQuantity * (currentValue.displayPrice ? currentValue.displayPrice : 0));
      }
    }, 0);
  }

  private calculateDiagnosticsPrice(diagnostics: any): number {
    return diagnostics.filter(diagnostic => diagnostic.diagnosticTypeInLab).reduce(function (accumulator, currentDiagnostics, currentIndex, array) {
      if (!currentDiagnostics.isInvoiced) {
        return accumulator;
      } else if (currentDiagnostics.deferred) {
        return accumulator;
      } else {
        return accumulator + currentDiagnostics.diagnosticTypeInLab.price;
      }
    }, 0);
  }

  private calculateOtherCharges(diagnostics: any): number {
    return diagnostics.filter(diagnostic => diagnostic.diagnosticTypeInLab).reduce(function (accumulator, currentDiagnostics, currentIndex, array) {
      if (!currentDiagnostics.isInvoiced) {
        return accumulator;
      } else if (currentDiagnostics.deferred) {
        return accumulator;
      } else {
        return accumulator + currentDiagnostics.diagnosticTypeInLab.otherCharges;
      }
    }, 0);
  }

  private calculateDiagnosticsDisplayPrice(diagnostics: any): number {
    return diagnostics.filter(diagnostic => diagnostic.diagnosticTypeInLab).reduce(function (accumulator, currentDiagnostics, currentIndex, array) {
      if (!currentDiagnostics.isInvoiced) {
        return accumulator;
      } else if (currentDiagnostics.deferred) {
        return accumulator;
      } else {
        return accumulator + currentDiagnostics.diagnosticTypeInLab.displayPrice;
      }
    }, 0);
  }
}