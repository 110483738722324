/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PagedResourcesResourceConsultationsForDeclineReasons } from '../models/paged-resources-resource-consultations-for-decline-reasons-';
import { ResourceConsultationsForDeclineReasonsWithPatientAndDoctorInClinic } from '../models/resource-consultations-for-decline-reasons-with-patient-and-doctor-in-clinic-';

/**
 * Reason Coding Controller
 */
@Injectable()
class ReasonCodingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReasonCodingControllerService.GetConsultationsForDeclineReasonsUsingGETParams` containing the following parameters:
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  getConsultationsForDeclineReasonsUsingGETResponse(params: ReasonCodingControllerService.GetConsultationsForDeclineReasonsUsingGETParams): Observable<HttpResponse<PagedResourcesResourceConsultationsForDeclineReasons>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.doctorInClinicIds || []).forEach((val, index) => {if (val != null) __params = __params.append('doctorInClinicIds', val.toString())});
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/reason-coding`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PagedResourcesResourceConsultationsForDeclineReasons = null;
        _body = _resp.body as PagedResourcesResourceConsultationsForDeclineReasons;
        return _resp.clone({body: _body}) as HttpResponse<PagedResourcesResourceConsultationsForDeclineReasons>;
      })
    );
  }

  /**
   * @param params The `ReasonCodingControllerService.GetConsultationsForDeclineReasonsUsingGETParams` containing the following parameters:
   *
   * - `doctorInClinicIds`: doctorInClinicIds
   *
   * - `startDate`: startDate
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  getConsultationsForDeclineReasonsUsingGET(params: ReasonCodingControllerService.GetConsultationsForDeclineReasonsUsingGETParams): Observable<PagedResourcesResourceConsultationsForDeclineReasons> {
    return this.getConsultationsForDeclineReasonsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param consultationId consultationId
   * @return OK
   */
  getConsultationForReasonCodingUsingGETResponse(consultationId: number): Observable<HttpResponse<ResourceConsultationsForDeclineReasonsWithPatientAndDoctorInClinic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (consultationId != null) __params = __params.set('consultationId', consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/consultation/reason-coding/fetch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultationsForDeclineReasonsWithPatientAndDoctorInClinic = null;
        _body = _resp.body as ResourceConsultationsForDeclineReasonsWithPatientAndDoctorInClinic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultationsForDeclineReasonsWithPatientAndDoctorInClinic>;
      })
    );
  }

  /**
   * @param consultationId consultationId
   * @return OK
   */
  getConsultationForReasonCodingUsingGET(consultationId: number): Observable<ResourceConsultationsForDeclineReasonsWithPatientAndDoctorInClinic> {
    return this.getConsultationForReasonCodingUsingGETResponse(consultationId).pipe(
      map(_r => _r.body)
    );
  }
}

module ReasonCodingControllerService {

  /**
   * Parameters for getConsultationsForDeclineReasonsUsingGET
   */
  export interface GetConsultationsForDeclineReasonsUsingGETParams {

    /**
     * doctorInClinicIds
     */
    doctorInClinicIds: Array<number>;

    /**
     * startDate
     */
    startDate?: string;
    sort?: string;
    size?: number;
    page?: number;

    /**
     * endDate
     */
    endDate?: string;
  }
}

export { ReasonCodingControllerService }
