import { Directive, Input, TemplateRef, ViewContainerRef, ElementRef, Renderer, DoCheck } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsultationPriceContext } from './consultation-price.context';
import { OrderPriceContext } from './order-price.context';

@Directive({
  selector: '[appConsultationPrice]'
})
export class ConsultationPriceDirective implements DoCheck {
  option;
  consultation;
  order;
  private hasView = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input() set appConsultationPrice(value: any) {
    this.option = value && value.option;
    if (value && value.option === 'consultation') {

      this.consultation = value.consultation;
      if (this.consultation) {
        this.viewContainer.remove(0);
        let view = this.viewContainer.createEmbeddedView(this.templateRef, new ConsultationPriceContext(this.consultation));
        this.hasView = true;
      }
    } else if (value && value.option === 'order') {

      this.order = value.order;
      if (this.order) {
        this.viewContainer.remove(0);
        let view = this.viewContainer.createEmbeddedView(this.templateRef, new OrderPriceContext(this.order));
        this.hasView = true;
      }
    }
  }

  ngDoCheck(): void {

    if (this.option === 'consultation') {

      if (this.consultation) {
        this.viewContainer.remove(0);
        let view = this.viewContainer.createEmbeddedView(this.templateRef, new ConsultationPriceContext(this.consultation));
        this.hasView = true;
      }
    } else if (this.option === 'order') {

      if (this.order) {
        this.viewContainer.remove(0);
        let view = this.viewContainer.createEmbeddedView(this.templateRef, new OrderPriceContext(this.order));
        this.hasView = true;
      }
    }
  }
}
