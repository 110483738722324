import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { map, filter } from "rxjs/operators";

import { PaymentSummaryModel } from "../models";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";
import { Observable } from "rxjs";
import { ResourcesOrder } from "../models/resources-order-";
import { PagedOrderDto } from "../models/paged-order-dto";

@Injectable()
class PaymentSummaryService extends BaseService {
  OrderIds: any = [];
  consultationId
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  findByDoctorInClinicIdInOrderUsingGET(
    params: PaymentSummaryService.findPaymentSummaryByStartEndDate
  ): Observable<ResourcesOrder> {
    return this.findByDoctorInClinicIdInOrderUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  findByDoctorInClinicIdInOrderUsingGETResponse(
    params: PaymentSummaryService.findPaymentSummaryByStartEndDate
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null)
      __params = __params.set("startDate", params.startDate.toISOString());
    if (params.endDate != null)
      __params = __params.set("endDate", params.endDate.toISOString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/fetch-orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }

  /**
   * @param params The `PaymentSummaryService.GetFetchSubscriptionStatusUsingGETUsingGETParams` containing the following parameters:
   *
   *  - `date`:
   *
   *  - `dicId`:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getFetchOrderUsingGET(
    params: PaymentSummaryService.GetFetchOrderUsingDateAndDicId
  ): Observable<PagedOrderDto> {
    return this.getFetchOrderUsingGETUsingGETResponse(params).pipe(
      map((_r) => _r.body)
    );
  }

  /**
   * @param params The `PaymentSummaryService.GetFetchSubscriptionStatusUsingGETParam` containing the following parameters:
   *
   *  - `date`:
   *
   *  - `dicId`:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * @return OK
   */
  getFetchOrderUsingGETUsingGETResponse(
    params: PaymentSummaryService.GetFetchOrderUsingDateAndDicId
  ): Observable<HttpResponse<PagedOrderDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.date != null)
      __params = __params.set("date", params.date.toString());
    if (params.dicId != null)
      __params = __params.set("dicId", params.dicId.toString());
    if (params.sort != null)
      __params = __params.set("sort", params.sort.toString());
    if (params.size != null)
      __params = __params.set("size", params.size.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/filter-orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        const _resp = _r as HttpResponse<any>;
        let _body: PagedOrderDto = null;
        _body = _resp.body as PagedOrderDto;
        return _resp.clone({ body: _body }) as HttpResponse<PagedOrderDto>;
      })
    );
  }

  findByDicId(
    params: PaymentSummaryService.findPaymentByDicId
  ): Observable<HttpResponse<ResourcesOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.dicId != null)
      __params = __params.set("dicId", params.dicId.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/api/v1/order/filter-orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json",
      }
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrder = null;
        _body = _resp.body as ResourcesOrder;
        return _resp.clone({ body: _body }) as HttpResponse<ResourcesOrder>;
      })
    );
  }
}
module PaymentSummaryService {
  export interface findPaymentSummaryByStartEndDate {
    startDate?: Date;
    endDate?: Date;
  }

  export interface findPaymentByDicId {
    dicId?: number;
  }

  export interface GetFetchOrderUsingDateAndDicId {
    date?: string;
    dicId?: number;
    sort?: string;
    size?: number;
    page?: number;
  }
}

export { PaymentSummaryService };
