import { Component } from "@angular/core";
import { FullScreenLoaderService } from "./full-screen-loader-service";

@Component({
    selector: 'full-screen-loader',
    template: `
        <div id="full-screen-loader">
            <div class="flex-column flex-center">
                <mat-spinner diameter="50" strokeWidth="2"></mat-spinner>
                <div class="mt-3 highlight-color">
                    {{loaderText}}
                </div>
            </div>
        </div>
    `,
    styleUrls:['./full-screen-loader.scss']
})
export class FullScreenLoader{

    loaderText;
    constructor(
        private FullScreenLoaderService: FullScreenLoaderService
    ){
        FullScreenLoaderService.LoaderText.subscribe(res=> this.loaderText = res);
    }
}