/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesOrderItem } from '../models/resources-order-item-';
import { ResourceOrderItem } from '../models/resource-order-item-';
import { OrderItem } from '../models/order-item';
import { Resourcesstring } from '../models/resources-string-';
import { ResourceDiagnosticOrder } from '../models/resource-diagnostic-order-';
import { ResourceOrder } from '../models/resource-order-';
import { ResourcesOrderItemStatusflow } from '../models/resources-order-item-statusflow-';
import { ResourceOrderItemStatusflow } from '../models/resource-order-item-statusflow-';
import { ResourceProductType } from '../models/resource-product-type-';
import { ResourcesRiderWithOrderItem } from '../models/resources-rider-with-order-item-';
import { ResourceRiderWithOrderItem } from '../models/resource-rider-with-order-item-';

/**
 * $ Proxy 175
 */
@Injectable()
class OrderItemEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderItemEntityService.FindAllOrderItemUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderItemUsingGETResponse(params: OrderItemEntityService.FindAllOrderItemUsingGETParams): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindAllOrderItemUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderItemUsingGET(params: OrderItemEntityService.FindAllOrderItemUsingGETParams): Observable<ResourcesOrderItem> {
    return this.findAllOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderItemUsingPOSTResponse(body: OrderItem): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/orderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderItemUsingPOST(body: OrderItem): Observable<ResourceOrderItem> {
    return this.saveOrderItemUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param IDs IDs
   * @return OK
   */
  findByIdInOrderItemUsingGETResponse(IDs?: Array<number>): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (IDs || []).forEach((val, index) => {if (val != null) __params = __params.append('IDs', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/findByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param IDs IDs
   * @return OK
   */
  findByIdInOrderItemUsingGET(IDs?: Array<number>): Observable<ResourcesOrderItem> {
    return this.findByIdInOrderItemUsingGETResponse(IDs).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByIdInAndProviderIdOrderItemUsingGETParams` containing the following parameters:
   *
   * - `providerId`: providerId
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findByIdInAndProviderIdOrderItemUsingGETResponse(params: OrderItemEntityService.FindByIdInAndProviderIdOrderItemUsingGETParams): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.providerId != null) __params = __params.set('providerId', params.providerId.toString());
    (params.ids || []).forEach((val, index) => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/findByIdInAndProviderId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  ancilaryPricingUsingGET(type?: any): Observable<ResourcesOrderItem> {
    return this.ancilaryPricingUsingGETResponse(type).pipe(
      map(_r => _r.body)
    );
  }

  ancilaryPricingUsingGETResponse(params: any): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/get-ancillary-pricing-details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = null;
        _body = _resp.body as any;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByIdInAndProviderIdOrderItemUsingGETParams` containing the following parameters:
   *
   * - `providerId`: providerId
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findByIdInAndProviderIdOrderItemUsingGET(params: OrderItemEntityService.FindByIdInAndProviderIdOrderItemUsingGETParams): Observable<ResourcesOrderItem> {
    return this.findByIdInAndProviderIdOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `providerId`: providerId
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETResponse(params: OrderItemEntityService.FindByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETParams): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach((val, index) => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.providerId != null) __params = __params.set('providerId', params.providerId.toString());
    (params.ids || []).forEach((val, index) => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/findByIdInAndProviderIdAndProductTypeSkuIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETParams` containing the following parameters:
   *
   * - `statuses`: statuses
   *
   * - `providerId`: providerId
   *
   * - `ids`: ids
   *
   * @return OK
   */
  findByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGET(params: OrderItemEntityService.FindByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETParams): Observable<ResourcesOrderItem> {
    return this.findByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `status`: status
   *
   * - `settleAmount`: settleAmount
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `id`: id
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  findByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETResponse(params: OrderItemEntityService.FindByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETParams): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.year != null) __params = __params.set('year', params.year.toString());
    if (params.year != null) __params = __params.set('year', params.year.toString());
    if (params.timezoneOffset != null) __params = __params.set('timezoneOffset', params.timezoneOffset.toString());
    if (params.timezoneOffset != null) __params = __params.set('timezoneOffset', params.timezoneOffset.toString());
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.settleAmount != null) __params = __params.set('settleAmount', params.settleAmount.toString());
    if (params.seconds != null) __params = __params.set('seconds', params.seconds.toString());
    if (params.seconds != null) __params = __params.set('seconds', params.seconds.toString());
    if (params.nanos != null) __params = __params.set('nanos', params.nanos.toString());
    if (params.nanos != null) __params = __params.set('nanos', params.nanos.toString());
    if (params.month != null) __params = __params.set('month', params.month.toString());
    if (params.month != null) __params = __params.set('month', params.month.toString());
    if (params.minutes != null) __params = __params.set('minutes', params.minutes.toString());
    if (params.minutes != null) __params = __params.set('minutes', params.minutes.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.hours != null) __params = __params.set('hours', params.hours.toString());
    if (params.hours != null) __params = __params.set('hours', params.hours.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    if (params.day != null) __params = __params.set('day', params.day.toString());
    if (params.day != null) __params = __params.set('day', params.day.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/findByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `status`: status
   *
   * - `settleAmount`: settleAmount
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `id`: id
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `doctorInClinicId`: doctorInClinicId
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  findByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGET(params: OrderItemEntityService.FindByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETParams): Observable<ResourcesOrderItem> {
    return this.findByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param orderId orderId
   * @return OK
   */
  findByOrderIdOrderItemUsingGETResponse(orderId?: number): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orderId != null) __params = __params.set('orderId', orderId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/findByOrderId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param orderId orderId
   * @return OK
   */
  findByOrderIdOrderItemUsingGET(orderId?: number): Observable<ResourcesOrderItem> {
    return this.findByOrderIdOrderItemUsingGETResponse(orderId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `status`: status
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `productTypeId`: productTypeId
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  findByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETResponse(params: OrderItemEntityService.FindByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETParams): Observable<HttpResponse<ResourcesOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.year != null) __params = __params.set('year', params.year.toString());
    if (params.year != null) __params = __params.set('year', params.year.toString());
    if (params.timezoneOffset != null) __params = __params.set('timezoneOffset', params.timezoneOffset.toString());
    if (params.timezoneOffset != null) __params = __params.set('timezoneOffset', params.timezoneOffset.toString());
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.time != null) __params = __params.set('time', params.time.toString());
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.seconds != null) __params = __params.set('seconds', params.seconds.toString());
    if (params.seconds != null) __params = __params.set('seconds', params.seconds.toString());
    if (params.productTypeId != null) __params = __params.set('productTypeId', params.productTypeId.toString());
    if (params.nanos != null) __params = __params.set('nanos', params.nanos.toString());
    if (params.nanos != null) __params = __params.set('nanos', params.nanos.toString());
    if (params.month != null) __params = __params.set('month', params.month.toString());
    if (params.month != null) __params = __params.set('month', params.month.toString());
    if (params.minutes != null) __params = __params.set('minutes', params.minutes.toString());
    if (params.minutes != null) __params = __params.set('minutes', params.minutes.toString());
    if (params.hours != null) __params = __params.set('hours', params.hours.toString());
    if (params.hours != null) __params = __params.set('hours', params.hours.toString());
    if (params.day != null) __params = __params.set('day', params.day.toString());
    if (params.day != null) __params = __params.set('day', params.day.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/findByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItem = null;
        _body = _resp.body as ResourcesOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.FindByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETParams` containing the following parameters:
   *
   * - `year`:
   *
   * - `year`:
   *
   * - `timezoneOffset`:
   *
   * - `timezoneOffset`:
   *
   * - `time`:
   *
   * - `time`:
   *
   * - `status`: status
   *
   * - `seconds`:
   *
   * - `seconds`:
   *
   * - `productTypeId`: productTypeId
   *
   * - `nanos`:
   *
   * - `nanos`:
   *
   * - `month`:
   *
   * - `month`:
   *
   * - `minutes`:
   *
   * - `minutes`:
   *
   * - `hours`:
   *
   * - `hours`:
   *
   * - `day`:
   *
   * - `day`:
   *
   * - `date`:
   *
   * - `date`:
   *
   * @return OK
   */
  findByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGET(params: OrderItemEntityService.FindByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETParams): Observable<ResourcesOrderItem> {
    return this.findByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  getAllCodesOrderItemUsingGETResponse(): Observable<HttpResponse<Resourcesstring>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/search/getAllCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesstring = null;
        _body = _resp.body as Resourcesstring;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesstring>;
      })
    );
  }

  /**
   * @return OK
   */
  getAllCodesOrderItemUsingGET(): Observable<Resourcesstring> {
    return this.getAllCodesOrderItemUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderItemUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderItemUsingGET(id: number): Observable<ResourceOrderItem> {
    return this.findByIdOrderItemUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.SaveOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemUsingPUTResponse(params: OrderItemEntityService.SaveOrderItemUsingPUTParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.SaveOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemUsingPUT(params: OrderItemEntityService.SaveOrderItemUsingPUTParams): Observable<ResourceOrderItem> {
    return this.saveOrderItemUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteOrderItemUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteOrderItemUsingDELETE(id: number): Observable<void> {
    return this.deleteOrderItemUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.SaveOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemUsingPATCHResponse(params: OrderItemEntityService.SaveOrderItemUsingPATCHParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.SaveOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderItemUsingPATCH(params: OrderItemEntityService.SaveOrderItemUsingPATCHParams): Observable<ResourceOrderItem> {
    return this.saveOrderItemUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemDiagnosticOrderUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${id}/diagnosticOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemDiagnosticOrderUsingGET(id: number): Observable<ResourceDiagnosticOrder> {
    return this.orderItemDiagnosticOrderUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemDiagnosticOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemDiagnosticOrderUsingPOSTResponse(params: OrderItemEntityService.OrderItemDiagnosticOrderUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/diagnosticOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemDiagnosticOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemDiagnosticOrderUsingPOST(params: OrderItemEntityService.OrderItemDiagnosticOrderUsingPOSTParams): Observable<ResourceDiagnosticOrder> {
    return this.orderItemDiagnosticOrderUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemDiagnosticOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemDiagnosticOrderUsingPUTResponse(params: OrderItemEntityService.OrderItemDiagnosticOrderUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/diagnosticOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemDiagnosticOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemDiagnosticOrderUsingPUT(params: OrderItemEntityService.OrderItemDiagnosticOrderUsingPUTParams): Observable<ResourceDiagnosticOrder> {
    return this.orderItemDiagnosticOrderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderItemDiagnosticOrderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${id}/diagnosticOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderItemDiagnosticOrderUsingDELETE(id: number): Observable<void> {
    return this.orderItemDiagnosticOrderUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemDiagnosticOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemDiagnosticOrderUsingPATCHResponse(params: OrderItemEntityService.OrderItemDiagnosticOrderUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/diagnosticOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticOrder = null;
        _body = _resp.body as ResourceDiagnosticOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticOrder>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemDiagnosticOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemDiagnosticOrderUsingPATCH(params: OrderItemEntityService.OrderItemDiagnosticOrderUsingPATCHParams): Observable<ResourceDiagnosticOrder> {
    return this.orderItemDiagnosticOrderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemOrderUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemOrderUsingGET(id: number): Observable<ResourceOrder> {
    return this.orderItemOrderUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderUsingPOSTResponse(params: OrderItemEntityService.OrderItemOrderUsingPOSTParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderUsingPOST(params: OrderItemEntityService.OrderItemOrderUsingPOSTParams): Observable<ResourceOrder> {
    return this.orderItemOrderUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderUsingPUTResponse(params: OrderItemEntityService.OrderItemOrderUsingPUTParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderUsingPUT(params: OrderItemEntityService.OrderItemOrderUsingPUTParams): Observable<ResourceOrder> {
    return this.orderItemOrderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderItemOrderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderItemOrderUsingDELETE(id: number): Observable<void> {
    return this.orderItemOrderUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderUsingPATCHResponse(params: OrderItemEntityService.OrderItemOrderUsingPATCHParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderUsingPATCH(params: OrderItemEntityService.OrderItemOrderUsingPATCHParams): Observable<ResourceOrder> {
    return this.orderItemOrderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${id}/orderItemStatusFlows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingGET_1(id: number): Observable<ResourcesOrderItemStatusflow> {
    return this.orderItemOrderItemStatusFlowsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingPOSTResponse(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPOSTParams): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/orderItemStatusFlows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingPOST(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPOSTParams): Observable<ResourcesOrderItemStatusflow> {
    return this.orderItemOrderItemStatusFlowsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingPUTResponse(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPUTParams): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/orderItemStatusFlows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingPUT(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPUTParams): Observable<ResourcesOrderItemStatusflow> {
    return this.orderItemOrderItemStatusFlowsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderItemOrderItemStatusFlowsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${id}/orderItemStatusFlows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderItemOrderItemStatusFlowsUsingDELETE_1(id: number): Observable<void> {
    return this.orderItemOrderItemStatusFlowsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingPATCHResponse(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPATCHParams): Observable<HttpResponse<ResourcesOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/orderItemStatusFlows`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderItemStatusflow = null;
        _body = _resp.body as ResourcesOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingPATCH(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingPATCHParams): Observable<ResourcesOrderItemStatusflow> {
    return this.orderItemOrderItemStatusFlowsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingGETParams` containing the following parameters:
   *
   * - `orderitemstatusflowId`: orderitemstatusflowId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingGETResponse(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingGETParams): Observable<HttpResponse<ResourceOrderItemStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/orderItemStatusFlows/${params.orderitemstatusflowId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItemStatusflow = null;
        _body = _resp.body as ResourceOrderItemStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItemStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingGETParams` containing the following parameters:
   *
   * - `orderitemstatusflowId`: orderitemstatusflowId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderItemOrderItemStatusFlowsUsingGET(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingGETParams): Observable<ResourceOrderItemStatusflow> {
    return this.orderItemOrderItemStatusFlowsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingDELETEParams` containing the following parameters:
   *
   * - `orderitemstatusflowId`: orderitemstatusflowId
   *
   * - `id`: id
   */
  orderItemOrderItemStatusFlowsUsingDELETEResponse(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/orderItemStatusFlows/${params.orderitemstatusflowId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingDELETEParams` containing the following parameters:
   *
   * - `orderitemstatusflowId`: orderitemstatusflowId
   *
   * - `id`: id
   */
  orderItemOrderItemStatusFlowsUsingDELETE(params: OrderItemEntityService.OrderItemOrderItemStatusFlowsUsingDELETEParams): Observable<void> {
    return this.orderItemOrderItemStatusFlowsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemProductTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${id}/productType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemProductTypeUsingGET(id: number): Observable<ResourceProductType> {
    return this.orderItemProductTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemProductTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemProductTypeUsingPOSTResponse(params: OrderItemEntityService.OrderItemProductTypeUsingPOSTParams): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/productType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemProductTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemProductTypeUsingPOST(params: OrderItemEntityService.OrderItemProductTypeUsingPOSTParams): Observable<ResourceProductType> {
    return this.orderItemProductTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemProductTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemProductTypeUsingPUTResponse(params: OrderItemEntityService.OrderItemProductTypeUsingPUTParams): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/productType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemProductTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemProductTypeUsingPUT(params: OrderItemEntityService.OrderItemProductTypeUsingPUTParams): Observable<ResourceProductType> {
    return this.orderItemProductTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderItemProductTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${id}/productType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderItemProductTypeUsingDELETE(id: number): Observable<void> {
    return this.orderItemProductTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemProductTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemProductTypeUsingPATCHResponse(params: OrderItemEntityService.OrderItemProductTypeUsingPATCHParams): Observable<HttpResponse<ResourceProductType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/productType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProductType = null;
        _body = _resp.body as ResourceProductType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProductType>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemProductTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemProductTypeUsingPATCH(params: OrderItemEntityService.OrderItemProductTypeUsingPATCHParams): Observable<ResourceProductType> {
    return this.orderItemProductTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${id}/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingGET_1(id: number): Observable<ResourcesRiderWithOrderItem> {
    return this.orderItemRidersWithOrderItemsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingPOSTResponse(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPOSTParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingPOST(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPOSTParams): Observable<ResourcesRiderWithOrderItem> {
    return this.orderItemRidersWithOrderItemsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingPUTResponse(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPUTParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingPUT(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPUTParams): Observable<ResourcesRiderWithOrderItem> {
    return this.orderItemRidersWithOrderItemsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderItemRidersWithOrderItemsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${id}/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderItemRidersWithOrderItemsUsingDELETE_1(id: number): Observable<void> {
    return this.orderItemRidersWithOrderItemsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingPATCHResponse(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPATCHParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingPATCH(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingPATCHParams): Observable<ResourcesRiderWithOrderItem> {
    return this.orderItemRidersWithOrderItemsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingGETParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingGETResponse(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingGETParams): Observable<HttpResponse<ResourceRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/ridersWithOrderItems/${params.riderwithorderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRiderWithOrderItem = null;
        _body = _resp.body as ResourceRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingGETParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   *
   * @return OK
   */
  orderItemRidersWithOrderItemsUsingGET(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingGETParams): Observable<ResourceRiderWithOrderItem> {
    return this.orderItemRidersWithOrderItemsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingDELETEParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   */
  orderItemRidersWithOrderItemsUsingDELETEResponse(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/orderItems/${params.id}/ridersWithOrderItems/${params.riderwithorderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `OrderItemEntityService.OrderItemRidersWithOrderItemsUsingDELETEParams` containing the following parameters:
   *
   * - `riderwithorderitemId`: riderwithorderitemId
   *
   * - `id`: id
   */
  orderItemRidersWithOrderItemsUsingDELETE(params: OrderItemEntityService.OrderItemRidersWithOrderItemsUsingDELETEParams): Observable<void> {
    return this.orderItemRidersWithOrderItemsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderItemEntityService {

  /**
   * Parameters for findAllOrderItemUsingGET
   */
  export interface FindAllOrderItemUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByIdInAndProviderIdOrderItemUsingGET
   */
  export interface FindByIdInAndProviderIdOrderItemUsingGETParams {

    /**
     * providerId
     */
    providerId?: number;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for findByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGET
   */
  export interface FindByIdInAndProviderIdAndProductTypeSkuInOrderItemUsingGETParams {

    /**
     * statuses
     */
    statuses?: Array<string>;

    /**
     * providerId
     */
    providerId?: number;

    /**
     * ids
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for findByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGET
   */
  export interface FindByOrderDoctorInClinicIdAndSettleAmountAndOrderStatusAndProductTypeIdAndOrderCreatedAtGreaterThanEqualAndOrderCreatedAtLessThanOrderItemUsingGETParams {
    year?: number;
    timezoneOffset?: number;
    time?: number;

    /**
     * status
     */
    status?: 'PENDING' | 'PARTIALLY_PAID' | 'PAID' | 'PAY_LATER' | 'PROCESSING' | 'PROCESSED' | 'COMPLETED' | 'CANCELLED' | 'REFUNDED';

    /**
     * settleAmount
     */
    settleAmount?: boolean;
    seconds?: number;
    nanos?: number;
    month?: number;
    minutes?: number;

    /**
     * id
     */
    id?: number;
    hours?: number;

    /**
     * doctorInClinicId
     */
    doctorInClinicId?: number;
    day?: number;
    date?: number;
  }

  /**
   * Parameters for findByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGET
   */
  export interface FindByOrderStatusAndProductTypeIdAndExpectedArrivalTimeBetweenOrderItemUsingGETParams {
    year?: number;
    timezoneOffset?: number;
    time?: number;

    /**
     * status
     */
    status?: 'PENDING' | 'PARTIALLY_PAID' | 'PAID' | 'PAY_LATER' | 'PROCESSING' | 'PROCESSED' | 'COMPLETED' | 'CANCELLED' | 'REFUNDED';
    seconds?: number;

    /**
     * productTypeId
     */
    productTypeId?: number;
    nanos?: number;
    month?: number;
    minutes?: number;
    hours?: number;
    day?: number;
    date?: number;
  }

  /**
   * Parameters for saveOrderItemUsingPUT
   */
  export interface SaveOrderItemUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: OrderItem;
  }

  /**
   * Parameters for saveOrderItemUsingPATCH
   */
  export interface SaveOrderItemUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: OrderItem;
  }

  /**
   * Parameters for orderItemDiagnosticOrderUsingPOST
   */
  export interface OrderItemDiagnosticOrderUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemDiagnosticOrderUsingPUT
   */
  export interface OrderItemDiagnosticOrderUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemDiagnosticOrderUsingPATCH
   */
  export interface OrderItemDiagnosticOrderUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemOrderUsingPOST
   */
  export interface OrderItemOrderUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemOrderUsingPUT
   */
  export interface OrderItemOrderUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemOrderUsingPATCH
   */
  export interface OrderItemOrderUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemOrderItemStatusFlowsUsingPOST
   */
  export interface OrderItemOrderItemStatusFlowsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderItemOrderItemStatusFlowsUsingPUT
   */
  export interface OrderItemOrderItemStatusFlowsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderItemOrderItemStatusFlowsUsingPATCH
   */
  export interface OrderItemOrderItemStatusFlowsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderItemOrderItemStatusFlowsUsingGET
   */
  export interface OrderItemOrderItemStatusFlowsUsingGETParams {

    /**
     * orderitemstatusflowId
     */
    orderitemstatusflowId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for orderItemOrderItemStatusFlowsUsingDELETE
   */
  export interface OrderItemOrderItemStatusFlowsUsingDELETEParams {

    /**
     * orderitemstatusflowId
     */
    orderitemstatusflowId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for orderItemProductTypeUsingPOST
   */
  export interface OrderItemProductTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemProductTypeUsingPUT
   */
  export interface OrderItemProductTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemProductTypeUsingPATCH
   */
  export interface OrderItemProductTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderItemRidersWithOrderItemsUsingPOST
   */
  export interface OrderItemRidersWithOrderItemsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderItemRidersWithOrderItemsUsingPUT
   */
  export interface OrderItemRidersWithOrderItemsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderItemRidersWithOrderItemsUsingPATCH
   */
  export interface OrderItemRidersWithOrderItemsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for orderItemRidersWithOrderItemsUsingGET
   */
  export interface OrderItemRidersWithOrderItemsUsingGETParams {

    /**
     * riderwithorderitemId
     */
    riderwithorderitemId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for orderItemRidersWithOrderItemsUsingDELETE
   */
  export interface OrderItemRidersWithOrderItemsUsingDELETEParams {

    /**
     * riderwithorderitemId
     */
    riderwithorderitemId: string;

    /**
     * id
     */
    id: number;
  }
}

export { OrderItemEntityService }
