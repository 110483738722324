import { Component, Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { SentryErrorHandler } from "../services/error-handler.service";
import { AuthenticationService } from "../../app/login/service/authentication.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { OAuth2AccessToken } from "../api/auth/models";
import "rxjs/add/operator/mergeMap";
import { MatBottomSheet, MatDialog } from "@angular/material";
import { CustomSnackbarComponent } from "../shared/custom-snackbar/custom-snackbar.component";
import { LoginDialogComponent } from "../shared/login-dialog/login-dialog.component";
import {AuthService} from 'angularx-social-login';
@Injectable({
  providedIn: "root",
})
export class ApiInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    protected localStorage: LocalStorage,
    private sentryService: SentryErrorHandler,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private  authService: AuthService,
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Apply the headers

    return this.localStorage
      .getItem("token")
      .flatMap((token: any, index: number) => {
        return Observable.create((observer) => {
          setTimeout(() => {
            if (!token) {
              observer.next(req);
            } else {
              this.deleteObsoleteTokens(token);
              this.logoutAfter4hours(token);
              if (
                req.url.includes("web.uat.meddo") ||
                req.url.includes("web.staging.meddo") ||
                req.url.includes("web.api.meddo")
              ) {
                observer.next(
                  req.clone({
                    setHeaders: {
                      Authorization: "Bearer " + token.access_token,
                    },
                  })
                );
              } else {
                observer.next(
                  req.clone({
                    setHeaders: {
                      Authorization: "Bearer " + token.access_token,
                      meddoApp: "POS",
                      appPlatform: "Web",
                    },
                  })
                );
              }
            }

            observer.complete();
          }, 300);
        });
      })
      .flatMap((req: HttpRequest<any>) => {
        return next.handle(req).pipe(
          tap(
            (x) => x,
            (err) => {
              // Handle this err
              if (err.status === 401) {
                let url = "/login";
                this.router.navigateByUrl(url);
              }
              this.sentryService.handleError(err, "Api");
            }
          )
        );
      });
  }

  // check if user token has loginTime property in their token
  deleteObsoleteTokens = (token) => {
    if (!token.loginTime) {
      this.logout();
    }
  };

  logoutAfter4hours = (token) => {
    let currentTime: any = new Date();
    let delta = Math.abs(currentTime - token.loginTime) / 1000;
    let hoursPassed = Math.floor(delta / 3600) % 24;
    let minutesPassed = Math.floor(delta / 60);
    let minutesLeft = 240 - minutesPassed;
    if (hoursPassed >= 4) {
      this.bottomSheet.open(CustomSnackbarComponent, {
        data: { time: 0 },
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: false,
      });

      this.logout();
      return;
    }
    if (minutesLeft < 11) {
      this.openSnackBar(minutesLeft);
    }
  };

  logout = () => {
    this.localStorage.getItem('token').subscribe((token) => {
      if (token && token.authorities.includes('ASSISTANT')) {
        this.authenticationService.posLogout(token.userID, token.clinicId).subscribe((data) => {
        });
      }
    });
    this.authService.signOut(true);
    this.localStorage.clear().subscribe(() => {
      this.router.navigateByUrl("/login");
    });
  };

  openSnackBar = (time) => {
    let sheetRef = this.bottomSheet.open(CustomSnackbarComponent, {
      data: { time: time },
      closeOnNavigation: true,
      disableClose: true,
      hasBackdrop: false,
    });

    sheetRef.afterDismissed().subscribe((data) => {
      this.localStorage.getItem("token").subscribe((token) => {
        if (token === null) {
          // check if user has already pressed logout in another tab
          this.logout();
        }
        let currentTime: any = new Date();
        let delta = Math.abs(currentTime - token.loginTime) / 1000;
        let hoursPassed = Math.floor(delta / 3600) % 24;
        let minutesPassed = Math.floor(delta / 60);
        let minutesLeft = 240 - minutesPassed;;
        if (minutesLeft > 11) {
          // check if token was modified in another tab and dismiss snackbar without doing anything
          return;
        }
        if (data && data.message == "login again") {
          this.authService.signOut(true);
          this.openDialog();
        }
        if (data && data.message == "logout") {
          this.logout();
        }
      });
    });
  };

  openDialog = () => {
    let dialogRef = this.dialog.open(LoginDialogComponent, {
      height: "400px",
      width: "600px",
    });
  };
}
