/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PrescriptionWithDescription } from '../models/prescription-with-description';
import { PrescriptionMetadataRequestDto } from '../models/prescription-metadata-request-dto';

/**
 * Prescription Controller
 */
@Injectable()
class PrescriptionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id id
   */
  deletePrescriptionDescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/delete-prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deletePrescriptionDescriptionUsingDELETE(id: number): Observable<void> {
    return this.deletePrescriptionDescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingGETResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingGET(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingGETResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingHEADResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingHEAD(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingHEADResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingPOSTResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingPOST(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingPOSTResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingPUTResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingPUT(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingPUTResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingDELETEResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingDELETE(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingDELETEResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingOPTIONSResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingOPTIONS(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingOPTIONSResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingPATCHResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  populatePrescriptionsUsingPATCH(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.populatePrescriptionsUsingPATCHResponse(request).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param request request
   * @return OK
   */
  updatePrescriptionsUsingPATCHResponse(request: Array<PrescriptionMetadataRequestDto>): Observable<HttpResponse<Array<PrescriptionWithDescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/update-prescription-description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<PrescriptionWithDescription> = null;
        _body = _resp.body as Array<PrescriptionWithDescription>;
        return _resp.clone({body: _body}) as HttpResponse<Array<PrescriptionWithDescription>>;
      })
    );
  }

  /**
   * @param request request
   * @return OK
   */
  updatePrescriptionsUsingPATCH(request: Array<PrescriptionMetadataRequestDto>): Observable<Array<PrescriptionWithDescription>> {
    return this.updatePrescriptionsUsingPATCHResponse(request).pipe(
      map(_r => _r.body)
    );
  }
}

module PrescriptionControllerService {
}

export { PrescriptionControllerService }
