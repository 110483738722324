/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesAdvice } from '../models/resources-advice-';
import { ResourceAdvice } from '../models/resource-advice-';
import { Advice } from '../models/advice';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 212
 */
@Injectable()
class AdviceEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AdviceEntityService.FindAllAdviceUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAdviceUsingGETResponse(params: AdviceEntityService.FindAllAdviceUsingGETParams): Observable<HttpResponse<ResourcesAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAdvice = null;
        _body = _resp.body as ResourcesAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAdvice>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.FindAllAdviceUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAdviceUsingGET(params: AdviceEntityService.FindAllAdviceUsingGETParams): Observable<ResourcesAdvice> {
    return this.findAllAdviceUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAdviceUsingPOSTResponse(body: Advice): Observable<HttpResponse<ResourceAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/advices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAdvice = null;
        _body = _resp.body as ResourceAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAdvice>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAdviceUsingPOST(body: Advice): Observable<ResourceAdvice> {
    return this.saveAdviceUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AdviceEntityService.FindByConsultationIdAdviceUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdAdviceUsingGETResponse(params: AdviceEntityService.FindByConsultationIdAdviceUsingGETParams): Observable<HttpResponse<ResourcesAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/advices/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAdvice = null;
        _body = _resp.body as ResourcesAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAdvice>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.FindByConsultationIdAdviceUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdAdviceUsingGET(params: AdviceEntityService.FindByConsultationIdAdviceUsingGETParams): Observable<ResourcesAdvice> {
    return this.findByConsultationIdAdviceUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAdviceUsingGETResponse(id: number): Observable<HttpResponse<ResourceAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/advices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAdvice = null;
        _body = _resp.body as ResourceAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAdvice>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAdviceUsingGET(id: number): Observable<ResourceAdvice> {
    return this.findByIdAdviceUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AdviceEntityService.SaveAdviceUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAdviceUsingPUTResponse(params: AdviceEntityService.SaveAdviceUsingPUTParams): Observable<HttpResponse<ResourceAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/advices/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAdvice = null;
        _body = _resp.body as ResourceAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAdvice>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.SaveAdviceUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAdviceUsingPUT(params: AdviceEntityService.SaveAdviceUsingPUTParams): Observable<ResourceAdvice> {
    return this.saveAdviceUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteAdviceUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/advices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteAdviceUsingDELETE(id: number): Observable<void> {
    return this.deleteAdviceUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AdviceEntityService.SaveAdviceUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAdviceUsingPATCHResponse(params: AdviceEntityService.SaveAdviceUsingPATCHParams): Observable<HttpResponse<ResourceAdvice>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/advices/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAdvice = null;
        _body = _resp.body as ResourceAdvice;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAdvice>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.SaveAdviceUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAdviceUsingPATCH(params: AdviceEntityService.SaveAdviceUsingPATCHParams): Observable<ResourceAdvice> {
    return this.saveAdviceUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  adviceConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/advices/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  adviceConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.adviceConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AdviceEntityService.AdviceConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  adviceConsultationUsingPOSTResponse(params: AdviceEntityService.AdviceConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/advices/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.AdviceConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  adviceConsultationUsingPOST(params: AdviceEntityService.AdviceConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.adviceConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AdviceEntityService.AdviceConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  adviceConsultationUsingPUTResponse(params: AdviceEntityService.AdviceConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/advices/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.AdviceConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  adviceConsultationUsingPUT(params: AdviceEntityService.AdviceConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.adviceConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  adviceConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/advices/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  adviceConsultationUsingDELETE(id: number): Observable<void> {
    return this.adviceConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AdviceEntityService.AdviceConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  adviceConsultationUsingPATCHResponse(params: AdviceEntityService.AdviceConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/advices/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `AdviceEntityService.AdviceConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  adviceConsultationUsingPATCH(params: AdviceEntityService.AdviceConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.adviceConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AdviceEntityService {

  /**
   * Parameters for findAllAdviceUsingGET
   */
  export interface FindAllAdviceUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdAdviceUsingGET
   */
  export interface FindByConsultationIdAdviceUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for saveAdviceUsingPUT
   */
  export interface SaveAdviceUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Advice;
  }

  /**
   * Parameters for saveAdviceUsingPATCH
   */
  export interface SaveAdviceUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Advice;
  }

  /**
   * Parameters for adviceConsultationUsingPOST
   */
  export interface AdviceConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for adviceConsultationUsingPUT
   */
  export interface AdviceConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for adviceConsultationUsingPATCH
   */
  export interface AdviceConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { AdviceEntityService }
