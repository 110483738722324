/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesRiderWithOrderItem } from '../models/resources-rider-with-order-item-';
import { ResourceRiderWithOrderItem } from '../models/resource-rider-with-order-item-';
import { RiderWithOrderItem } from '../models/rider-with-order-item';
import { ResourceOrderItem } from '../models/resource-order-item-';
import { ResourceRider } from '../models/resource-rider-';

/**
 * $ Proxy 176
 */
@Injectable()
class RiderWithOrderItemEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.FindAllRiderWithOrderItemUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllRiderWithOrderItemUsingGETResponse(params: RiderWithOrderItemEntityService.FindAllRiderWithOrderItemUsingGETParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.FindAllRiderWithOrderItemUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllRiderWithOrderItemUsingGET(params: RiderWithOrderItemEntityService.FindAllRiderWithOrderItemUsingGETParams): Observable<ResourcesRiderWithOrderItem> {
    return this.findAllRiderWithOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveRiderWithOrderItemUsingPOSTResponse(body: RiderWithOrderItem): Observable<HttpResponse<ResourceRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRiderWithOrderItem = null;
        _body = _resp.body as ResourceRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveRiderWithOrderItemUsingPOST(body: RiderWithOrderItem): Observable<ResourceRiderWithOrderItem> {
    return this.saveRiderWithOrderItemUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.FetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETParams` containing the following parameters:
   *
   * - `riderStatus`: riderStatus
   *
   * - `orderItemId`: orderItemId
   *
   * @return OK
   */
  fetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETResponse(params: RiderWithOrderItemEntityService.FetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETParams): Observable<HttpResponse<ResourcesRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.riderStatus != null) __params = __params.set('riderStatus', params.riderStatus.toString());
    if (params.orderItemId != null) __params = __params.set('orderItemId', params.orderItemId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/search/fetchRidersThroughOrderItemId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesRiderWithOrderItem = null;
        _body = _resp.body as ResourcesRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.FetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETParams` containing the following parameters:
   *
   * - `riderStatus`: riderStatus
   *
   * - `orderItemId`: orderItemId
   *
   * @return OK
   */
  fetchRidersThroughOrderItemIdRiderWithOrderItemUsingGET(params: RiderWithOrderItemEntityService.FetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETParams): Observable<ResourcesRiderWithOrderItem> {
    return this.fetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdRiderWithOrderItemUsingGETResponse(id: number): Observable<HttpResponse<ResourceRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRiderWithOrderItem = null;
        _body = _resp.body as ResourceRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdRiderWithOrderItemUsingGET(id: number): Observable<ResourceRiderWithOrderItem> {
    return this.findByIdRiderWithOrderItemUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderWithOrderItemUsingPUTResponse(params: RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPUTParams): Observable<HttpResponse<ResourceRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRiderWithOrderItem = null;
        _body = _resp.body as ResourceRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderWithOrderItemUsingPUT(params: RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPUTParams): Observable<ResourceRiderWithOrderItem> {
    return this.saveRiderWithOrderItemUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteRiderWithOrderItemUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteRiderWithOrderItemUsingDELETE(id: number): Observable<void> {
    return this.deleteRiderWithOrderItemUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderWithOrderItemUsingPATCHResponse(params: RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPATCHParams): Observable<HttpResponse<ResourceRiderWithOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRiderWithOrderItem = null;
        _body = _resp.body as ResourceRiderWithOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRiderWithOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveRiderWithOrderItemUsingPATCH(params: RiderWithOrderItemEntityService.SaveRiderWithOrderItemUsingPATCHParams): Observable<ResourceRiderWithOrderItem> {
    return this.saveRiderWithOrderItemUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  riderWithOrderItemOrderItemUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  riderWithOrderItemOrderItemUsingGET(id: number): Observable<ResourceOrderItem> {
    return this.riderWithOrderItemOrderItemUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemOrderItemUsingPOSTResponse(params: RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPOSTParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemOrderItemUsingPOST(params: RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPOSTParams): Observable<ResourceOrderItem> {
    return this.riderWithOrderItemOrderItemUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemOrderItemUsingPUTResponse(params: RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPUTParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemOrderItemUsingPUT(params: RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPUTParams): Observable<ResourceOrderItem> {
    return this.riderWithOrderItemOrderItemUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  riderWithOrderItemOrderItemUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  riderWithOrderItemOrderItemUsingDELETE(id: number): Observable<void> {
    return this.riderWithOrderItemOrderItemUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemOrderItemUsingPATCHResponse(params: RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPATCHParams): Observable<HttpResponse<ResourceOrderItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}/orderItem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderItem = null;
        _body = _resp.body as ResourceOrderItem;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderItem>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemOrderItemUsingPATCH(params: RiderWithOrderItemEntityService.RiderWithOrderItemOrderItemUsingPATCHParams): Observable<ResourceOrderItem> {
    return this.riderWithOrderItemOrderItemUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  riderWithOrderItemRiderUsingGETResponse(id: number): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${id}/rider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  riderWithOrderItemRiderUsingGET(id: number): Observable<ResourceRider> {
    return this.riderWithOrderItemRiderUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemRiderUsingPOSTResponse(params: RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPOSTParams): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}/rider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemRiderUsingPOST(params: RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPOSTParams): Observable<ResourceRider> {
    return this.riderWithOrderItemRiderUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemRiderUsingPUTResponse(params: RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPUTParams): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}/rider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemRiderUsingPUT(params: RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPUTParams): Observable<ResourceRider> {
    return this.riderWithOrderItemRiderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  riderWithOrderItemRiderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${id}/rider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  riderWithOrderItemRiderUsingDELETE(id: number): Observable<void> {
    return this.riderWithOrderItemRiderUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemRiderUsingPATCHResponse(params: RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPATCHParams): Observable<HttpResponse<ResourceRider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ridersWithOrderItems/${params.id}/rider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceRider = null;
        _body = _resp.body as ResourceRider;
        return _resp.clone({body: _body}) as HttpResponse<ResourceRider>;
      })
    );
  }

  /**
   * @param params The `RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  riderWithOrderItemRiderUsingPATCH(params: RiderWithOrderItemEntityService.RiderWithOrderItemRiderUsingPATCHParams): Observable<ResourceRider> {
    return this.riderWithOrderItemRiderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module RiderWithOrderItemEntityService {

  /**
   * Parameters for findAllRiderWithOrderItemUsingGET
   */
  export interface FindAllRiderWithOrderItemUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for fetchRidersThroughOrderItemIdRiderWithOrderItemUsingGET
   */
  export interface FetchRidersThroughOrderItemIdRiderWithOrderItemUsingGETParams {

    /**
     * riderStatus
     */
    riderStatus?: 'RUNNING' | 'NONE';

    /**
     * orderItemId
     */
    orderItemId?: number;
  }

  /**
   * Parameters for saveRiderWithOrderItemUsingPUT
   */
  export interface SaveRiderWithOrderItemUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: RiderWithOrderItem;
  }

  /**
   * Parameters for saveRiderWithOrderItemUsingPATCH
   */
  export interface SaveRiderWithOrderItemUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: RiderWithOrderItem;
  }

  /**
   * Parameters for riderWithOrderItemOrderItemUsingPOST
   */
  export interface RiderWithOrderItemOrderItemUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for riderWithOrderItemOrderItemUsingPUT
   */
  export interface RiderWithOrderItemOrderItemUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for riderWithOrderItemOrderItemUsingPATCH
   */
  export interface RiderWithOrderItemOrderItemUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for riderWithOrderItemRiderUsingPOST
   */
  export interface RiderWithOrderItemRiderUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for riderWithOrderItemRiderUsingPUT
   */
  export interface RiderWithOrderItemRiderUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for riderWithOrderItemRiderUsingPATCH
   */
  export interface RiderWithOrderItemRiderUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { RiderWithOrderItemEntityService }
