import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { EventEmitter } from '@angular/core';
import {PageRegistrationService} from '../../../api/emr/services/page-registration.service';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';
import { Router } from '@angular/router';
import { SkipBarcodeDialogComponent } from '../skip-barcode-dialog/skip-barcode-dialog.component';
import { ConsultationControllerService } from 'src/app/api/emr/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scan-barcode-dialog',
  templateUrl: './scan-barcode-dialog.component.html',
  styleUrls: ['./scan-barcode-dialog.component.scss'],
  inputs: ['patientInformation','data','reqConsultationId','barcodePatientName', 'reqPatientDetails']
})
export class ScanBarcodeDialogComponent implements OnInit {

  patientInformation:any;
  barcode:any;
  paperBarcode:any='';
  patientBarcode:any='';
  isBarcode:boolean=false;
  snackBar: any;
  validBarcode:any;
  dicId:number;
  reqObj:any;
  showErrorMessage:boolean=false;
  patientName:any;
  isuncover:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ScanBarcodeDialogComponent>,
    private PageRegistrationService: PageRegistrationService,
    private HttpClient: HttpClient,
    private ApiConfiguration: ApiConfiguration,
    private router:Router,
    private MatSnackBar: MatSnackBar,
    private MatDialog: MatDialog,
    private ConsultationControllerService: ConsultationControllerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public reqConsultationId: any,
    @Inject(MAT_DIALOG_DATA) public barcodePatientName: any,
    @Inject(MAT_DIALOG_DATA) public reqPatientDetails: any,

  ) { }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
    this.dicId = this.data.data.doctorInClinic.id;
    this.patientName = this.barcodePatientName.barcodePatientName
    let patientFocus = document.getElementById("patientBarcode");
    patientFocus.focus();


  }

  calculateAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }    
    return age;
  }

  printPdfOfAppointment(){
    let patientInformation = this.data;
    let body = {
      "dic_id":0|| patientInformation.data.doctorInClinic.id,
      "patient_id": patientInformation.data.patientId?patientInformation.data.patientId:this.data.data.patient.id,
      "doctor_name":patientInformation.data.doctorInClinic.doctor.name,
      "appointment_date":new Date(patientInformation.data.appointmentTime).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'}),
      "patient_name": patientInformation.data.patient.patientName?patientInformation.data.patient.patientName:this.data.data.patient.name,
      "mobile": patientInformation.data.patient.patientPhone?patientInformation.data.patient.patientPhone:this.data.data.patient.phone,
      "age_gender": this.reqPatientDetails.reqPatientDetails && this.reqPatientDetails.reqPatientDetails.age ? this.reqPatientDetails.reqPatientDetails.age  + "/" + patientInformation.data.patient.gender : this.calculateAge(patientInformation.data.patient.dob) + "/" + patientInformation.data.patient.gender,
      "age": this.reqPatientDetails.reqPatientDetails && this.reqPatientDetails.reqPatientDetails.age ? this.reqPatientDetails.reqPatientDetails.age:this.calculateAge(patientInformation.data.patient.dob),
      "gender": patientInformation.data.patient.gender
      }
    this.HttpClient.post(this.ApiConfiguration.rootUrl + '/api/v1/emr/fetch/consultation/doxper/prescription-pad' ,body, {responseType: 'blob'} ).subscribe(res=>{
      var blob = new Blob([res], {type: 'application/pdf'});
      const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
    },err=>{
      alert('Something went wrong')
    })
  }

  skipBarcode(){
    let dialogRef = this.MatDialog.open(SkipBarcodeDialogComponent, {
      maxHeight: "120vh",
      minWidth: "39vw",
      disableClose: true
    });
    const sub = dialogRef.componentInstance.closeScanBarcode.subscribe(() => {
      this.dialogRef.close();
      this.router.navigateByUrl('/dashboard');
    });
  }

  enterBarcode(event: any,barcodeOf:number){
    // if(barcodeOf===0){
    //   if(this.paperBarcode.length===8 && this.paperBarcode.split('')[7]==1){
    //     this.isBarcode= true;
    //   }else{
    //     this.isBarcode= false;
    //   }
    // }else {
    //   if(this.patientBarcode.length===8 || this.paperBarcode.length===8){
    //     this.isBarcode= true;
    //   }
    //   else if(this.patientBarcode.length===8 && this.patientBarcode.split('')[7]==0){
    //     this.isBarcode= true;
    //   }else{
    //     this.isBarcode= false;
    //   }
    // }
    if((this.paperBarcode.length===8 && this.paperBarcode.split('')[7]==1)&&(this.patientBarcode.length===8 && this.patientBarcode.split('')[7]==0)){
      this.isBarcode= true;
      this.showErrorMessage = false;
    }else if(this.paperBarcode.length===0 && this.patientBarcode.length===0){
      this.isBarcode= false;
      this.showErrorMessage = false;
    }else if((this.paperBarcode.length===8 && this.paperBarcode.split('')[7]==1)&&(this.patientBarcode.length===0)){
      this.showErrorMessage = false;
      this.isBarcode= false;
    }else if((this.patientBarcode.length===8 && this.patientBarcode.split('')[7]==0)&&(this.paperBarcode.length===0)){
      this.showErrorMessage = false;
      this.isBarcode= false;
    }else{
      this.isBarcode= false;
      this.showErrorMessage = true;
    }
    //this.checkErrorMsg()
  }

  checkErrorMsg(){
    if((this.paperBarcode.length===8 && this.paperBarcode.split('')[7]==1)&&(this.patientBarcode.length===8 && this.patientBarcode.split('')[7]==0)){
      this.showErrorMessage = false;
    }else if((this.paperBarcode.length===8 && this.paperBarcode.split('')[7]==1)&&(this.patientBarcode.length===0)){
      this.showErrorMessage = true;
    }else if((this.patientBarcode.length===8 && this.patientBarcode.split('')[7]==0) && (this.paperBarcode.length===0)){
      this.showErrorMessage = true;
    }
  }

  keytab(event, maxLength){
    if((this.patientBarcode.length===8 && this.patientBarcode.split('')[7]==0)){
      let focusPaperBarcode = document.getElementById('paperBarcode');
      focusPaperBarcode.focus();
    }else if((this.paperBarcode.length===8 && this.paperBarcode.split('')[7]==1) && (this.patientBarcode.length!=8 && this.patientBarcode.split('')[7]!=0)){
      let focusPatientBarcode = document.getElementById('patientBarcode');
      focusPatientBarcode.focus();
    }
  }

  valideBarcode(message){
    this.MatSnackBar.open(message, "", {
      duration: 2000,
      verticalPosition: 'top',
      panelClass: ['valid-prescription-snackbar']
    });
  }
  invalideBarcode(){
    this.MatSnackBar.open("Prescription is already attached with other patient", "", {
      duration: 2000,
      verticalPosition: 'top',
      panelClass: ['invalid-prescription-snackbar']
    });
  }

  checkBarcode(){
    let barcode:any='';
    if(this.paperBarcode && this.patientBarcode){
       barcode = this.patientBarcode + '-' +this.paperBarcode;
    }else if(this.paperBarcode && this.patientBarcode===''){
      barcode = this.paperBarcode;
    }else if(this.paperBarcode ==='' && this.patientBarcode){
      barcode = this.patientBarcode;
    }
     this.validBarcode = barcode
     this.reqObj={
      "barcode":barcode,
      "dicId":this.dicId
     }
  }

  submitBarcode(){
    this.checkBarcode()
    this.ConsultationControllerService.scanbarcodeUpdateUsingPOST(this.reqObj).subscribe((response:any)=>{
      if(response){
        if(response.guid && response.qrcode){
          alert('successfull');
          this.dialogRef.close();
          let successMessage = "Barcode attached successfully"
          this.valideBarcode(successMessage);
          this.attachPrescription(response.guid,response.qrcode);
        }
        else if(response.error){
          alert(response.error.error.message);
          this.dialogRef.close();
          this.router.navigateByUrl('/dashboard');
          return
        }
        this.dialogRef.close();
      }else{
        alert('something went wrong');
      }
    }, err => {
      alert(err.error.message);
      this.invalideBarcode()
    })
  }

  attachPrescription(guids,Qrcode){
    this.HttpClient.post(
      `${environment.emrBaseAPI}/api/v1/emr/consultation/doxper/qrcode/v1`,
      {
        consultationId: this.reqConsultationId.consultationId,
        qrCode: [Qrcode],
        qrcodeAndGuidDtoList: [{
            "qrcode": Qrcode,
            "guid": guids,
            "mimeType": "application/pdf"
        }]
      }
    ).subscribe(
      (res) => {
        let successMessage = "Prescription attached successfully";
        this.valideBarcode(successMessage);
        this.dialogRef.close();
        this.router.navigateByUrl('/dashboard');
      },
      (err) => {
        alert(err && err.error && err.error.message);
        this.dialogRef.close();
        this.router.navigateByUrl('/dashboard');
      }
    );
  }
}
