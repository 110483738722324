/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPrescriptionByDateAndPatient } from '../models/resources-prescription-by-date-and-patient-';

/**
 * Prescription By Date Controller
 */
@Injectable()
class PrescriptionByDateControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param date date
   * @return OK
   */
  getOngoingMedicationPercentageUsingGET_1Response(date?: string): Observable<HttpResponse<ResourcesPrescriptionByDateAndPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/ongoing/medication-by-patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDateAndPatient = null;
        _body = _resp.body as ResourcesPrescriptionByDateAndPatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDateAndPatient>;
      })
    );
  }

  /**
   * @param date date
   * @return OK
   */
  getOngoingMedicationPercentageUsingGET_1(date?: string): Observable<ResourcesPrescriptionByDateAndPatient> {
    return this.getOngoingMedicationPercentageUsingGET_1Response(date).pipe(
      map(_r => _r.body)
    );
  }
}

module PrescriptionByDateControllerService {
}

export { PrescriptionByDateControllerService }
