import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from "@angular/material/dialog";
import { Subject, Subscription } from "rxjs";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-consultation-dialog",
  templateUrl: "./consultation-dialog.component.html",
  styleUrls: ["./consultation-dialog.component.scss"]
})
export class ConsultationDialogComponent implements OnInit {
  consultationOfferPrice = new FormControl();
  filteringProcedures = false;
  filterSubscription: Subscription;
  filteredProcedures: Subject<any[]> = new Subject();
  activeProcedureSearchIndex;
  settleAmount;
  procedures = [];
  clinicId;
  consultation: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialogRef: MatDialogRef<ConsultationDialogComponent>
  ) {
    this.consultation = this.data && this.data[0];
    this.consultationOfferPrice.setValue(
      (this.consultation.actualFee === undefined || this.consultation.actualFee === null ||
        Number.isNaN(this.consultation.actualFee))
        ? this.consultation.fee
        : this.consultation.actualFee
    );
  }

  confirmConsultaionSettlement(event) {
    if (event.checked) {
      if (
        confirm(
          "Are you sure want to settle this consultation amount with doctor ?"
        )
      ) {
        this.consultation.settleAmount = true;
      }
     } else {
        this.consultation.settleAmount = false;
      }
    
  }

  consultationContinue() {
    this.dialogRef.close(this.consultation);
  }

  ngOnInit() {
    this.consultation.actualFee = this.consultationOfferPrice.value;
    this.consultationOfferPrice.valueChanges.subscribe(
      value => {
        if(!value || value < 0) {
          value = 0;
        }
        this.consultation.actualFee = parseFloat(value);
        this.consultation.fee = parseFloat(value);
      }
    );
  }
}
