import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-multiple-patient-dialog",
  templateUrl: "./multiple-patient-dialog.component.html",
  styleUrls: ["./multiple-patient-dialog.component.scss"],
})
export class MultiplePatientDialogComponent implements OnInit {
  selectedPatient;
  isuncover:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MatDialogRef: MatDialogRef<MultiplePatientDialogComponent>
  ) {}

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
  }

  onContinue() {
    this.MatDialogRef.close({ selectedPatient: this.selectedPatient });
  }
}
