/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsultationForPatientFamily } from '../models/consultation-for-patient-family';

/**
 * Patient And Order Controller
 */
@Injectable()
class PatientAndOrderControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getAllPatientsUsingGETResponse(): Observable<HttpResponse<Array<ConsultationForPatientFamily>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/patient/all-family`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ConsultationForPatientFamily> = null;
        _body = _resp.body as Array<ConsultationForPatientFamily>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ConsultationForPatientFamily>>;
      })
    );
  }

  /**
   * @return OK
   */
  getAllPatientsUsingGET(): Observable<Array<ConsultationForPatientFamily>> {
    return this.getAllPatientsUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  subscriptionUsingGET(): Observable<any> {
    return this.subscriptionUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  subscriptionUsingGETResponse(): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __params = __params.set('active', 'true');
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/subscription/fetch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = null;
        _body = _resp.body as any;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

}

module PatientAndOrderControllerService {
}

export { PatientAndOrderControllerService }
