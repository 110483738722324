/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesAllergyType } from '../models/resources-allergy-type-';
import { ResourceAllergyType } from '../models/resource-allergy-type-';
import { AllergyType } from '../models/allergy-type';
import { ResourcesAllergy } from '../models/resources-allergy-';
import { ResourceAllergy } from '../models/resource-allergy-';

/**
 * $ Proxy 218
 */
@Injectable()
class AllergyTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AllergyTypeEntityService.FindAllAllergyTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAllergyTypeUsingGETResponse(params: AllergyTypeEntityService.FindAllAllergyTypeUsingGETParams): Observable<HttpResponse<ResourcesAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergiesType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergyType = null;
        _body = _resp.body as ResourcesAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.FindAllAllergyTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAllergyTypeUsingGET(params: AllergyTypeEntityService.FindAllAllergyTypeUsingGETParams): Observable<ResourcesAllergyType> {
    return this.findAllAllergyTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAllergyTypeUsingPOSTResponse(body: AllergyType): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/allergiesType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAllergyTypeUsingPOST(body: AllergyType): Observable<ResourceAllergyType> {
    return this.saveAllergyTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.FindByNameContainingAndIsActiveTrueAllergyTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndIsActiveTrueAllergyTypeUsingGETResponse(params: AllergyTypeEntityService.FindByNameContainingAndIsActiveTrueAllergyTypeUsingGETParams): Observable<HttpResponse<ResourcesAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergiesType/search/findByNameContainingAndIsActiveTrue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergyType = null;
        _body = _resp.body as ResourcesAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.FindByNameContainingAndIsActiveTrueAllergyTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndIsActiveTrueAllergyTypeUsingGET(params: AllergyTypeEntityService.FindByNameContainingAndIsActiveTrueAllergyTypeUsingGETParams): Observable<ResourcesAllergyType> {
    return this.findByNameContainingAndIsActiveTrueAllergyTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAllergyTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergiesType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAllergyTypeUsingGET(id: number): Observable<ResourceAllergyType> {
    return this.findByIdAllergyTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.SaveAllergyTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyTypeUsingPUTResponse(params: AllergyTypeEntityService.SaveAllergyTypeUsingPUTParams): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.SaveAllergyTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyTypeUsingPUT(params: AllergyTypeEntityService.SaveAllergyTypeUsingPUTParams): Observable<ResourceAllergyType> {
    return this.saveAllergyTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteAllergyTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/allergiesType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteAllergyTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteAllergyTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.SaveAllergyTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyTypeUsingPATCHResponse(params: AllergyTypeEntityService.SaveAllergyTypeUsingPATCHParams): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.SaveAllergyTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyTypeUsingPATCH(params: AllergyTypeEntityService.SaveAllergyTypeUsingPATCHParams): Observable<ResourceAllergyType> {
    return this.saveAllergyTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  allergyTypeAllergiesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergiesType/${id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  allergyTypeAllergiesUsingGET_1(id: number): Observable<ResourcesAllergy> {
    return this.allergyTypeAllergiesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyTypeAllergiesUsingPOSTResponse(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingPOSTParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyTypeAllergiesUsingPOST(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingPOSTParams): Observable<ResourcesAllergy> {
    return this.allergyTypeAllergiesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyTypeAllergiesUsingPUTResponse(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingPUTParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyTypeAllergiesUsingPUT(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingPUTParams): Observable<ResourcesAllergy> {
    return this.allergyTypeAllergiesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  allergyTypeAllergiesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/allergiesType/${id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  allergyTypeAllergiesUsingDELETE_1(id: number): Observable<void> {
    return this.allergyTypeAllergiesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyTypeAllergiesUsingPATCHResponse(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingPATCHParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyTypeAllergiesUsingPATCH(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingPATCHParams): Observable<ResourcesAllergy> {
    return this.allergyTypeAllergiesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   *
   * @return OK
   */
  allergyTypeAllergiesUsingGETResponse(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingGETParams): Observable<HttpResponse<ResourceAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}/allergies/${params.allergyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergy = null;
        _body = _resp.body as ResourceAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   *
   * @return OK
   */
  allergyTypeAllergiesUsingGET(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingGETParams): Observable<ResourceAllergy> {
    return this.allergyTypeAllergiesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   */
  allergyTypeAllergiesUsingDELETEResponse(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/allergiesType/${params.id}/allergies/${params.allergyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `AllergyTypeEntityService.AllergyTypeAllergiesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `allergyId`: allergyId
   */
  allergyTypeAllergiesUsingDELETE(params: AllergyTypeEntityService.AllergyTypeAllergiesUsingDELETEParams): Observable<void> {
    return this.allergyTypeAllergiesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AllergyTypeEntityService {

  /**
   * Parameters for findAllAllergyTypeUsingGET
   */
  export interface FindAllAllergyTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByNameContainingAndIsActiveTrueAllergyTypeUsingGET
   */
  export interface FindByNameContainingAndIsActiveTrueAllergyTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveAllergyTypeUsingPUT
   */
  export interface SaveAllergyTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: AllergyType;
  }

  /**
   * Parameters for saveAllergyTypeUsingPATCH
   */
  export interface SaveAllergyTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: AllergyType;
  }

  /**
   * Parameters for allergyTypeAllergiesUsingPOST
   */
  export interface AllergyTypeAllergiesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for allergyTypeAllergiesUsingPUT
   */
  export interface AllergyTypeAllergiesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for allergyTypeAllergiesUsingPATCH
   */
  export interface AllergyTypeAllergiesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for allergyTypeAllergiesUsingGET
   */
  export interface AllergyTypeAllergiesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * allergyId
     */
    allergyId: string;
  }

  /**
   * Parameters for allergyTypeAllergiesUsingDELETE
   */
  export interface AllergyTypeAllergiesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * allergyId
     */
    allergyId: string;
  }
}

export { AllergyTypeEntityService }
