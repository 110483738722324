/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesTakenMedicine } from '../models/resources-taken-medicine-';
import { ResourceTakenMedicine } from '../models/resource-taken-medicine-';
import { TakenMedicine } from '../models/taken-medicine';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 207
 */
@Injectable()
class TakenMedicineEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TakenMedicineEntityService.FindAllTakenMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTakenMedicineUsingGETResponse(params: TakenMedicineEntityService.FindAllTakenMedicineUsingGETParams): Observable<HttpResponse<ResourcesTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTakenMedicine = null;
        _body = _resp.body as ResourcesTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.FindAllTakenMedicineUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTakenMedicineUsingGET(params: TakenMedicineEntityService.FindAllTakenMedicineUsingGETParams): Observable<ResourcesTakenMedicine> {
    return this.findAllTakenMedicineUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTakenMedicineUsingPOSTResponse(body: TakenMedicine): Observable<HttpResponse<ResourceTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/takenMedicines`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTakenMedicine = null;
        _body = _resp.body as ResourceTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTakenMedicine>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTakenMedicineUsingPOST(body: TakenMedicine): Observable<ResourceTakenMedicine> {
    return this.saveTakenMedicineUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTakenMedicineUsingGETResponse(id: number): Observable<HttpResponse<ResourceTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/takenMedicines/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTakenMedicine = null;
        _body = _resp.body as ResourceTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTakenMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTakenMedicineUsingGET(id: number): Observable<ResourceTakenMedicine> {
    return this.findByIdTakenMedicineUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.SaveTakenMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTakenMedicineUsingPUTResponse(params: TakenMedicineEntityService.SaveTakenMedicineUsingPUTParams): Observable<HttpResponse<ResourceTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/takenMedicines/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTakenMedicine = null;
        _body = _resp.body as ResourceTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.SaveTakenMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTakenMedicineUsingPUT(params: TakenMedicineEntityService.SaveTakenMedicineUsingPUTParams): Observable<ResourceTakenMedicine> {
    return this.saveTakenMedicineUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteTakenMedicineUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/takenMedicines/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteTakenMedicineUsingDELETE(id: number): Observable<void> {
    return this.deleteTakenMedicineUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.SaveTakenMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTakenMedicineUsingPATCHResponse(params: TakenMedicineEntityService.SaveTakenMedicineUsingPATCHParams): Observable<HttpResponse<ResourceTakenMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/takenMedicines/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTakenMedicine = null;
        _body = _resp.body as ResourceTakenMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTakenMedicine>;
      })
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.SaveTakenMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTakenMedicineUsingPATCH(params: TakenMedicineEntityService.SaveTakenMedicineUsingPATCHParams): Observable<ResourceTakenMedicine> {
    return this.saveTakenMedicineUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  takenMedicineConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/takenMedicines/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  takenMedicineConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.takenMedicineConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.TakenMedicineConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  takenMedicineConsultationUsingPOSTResponse(params: TakenMedicineEntityService.TakenMedicineConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/takenMedicines/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.TakenMedicineConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  takenMedicineConsultationUsingPOST(params: TakenMedicineEntityService.TakenMedicineConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.takenMedicineConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.TakenMedicineConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  takenMedicineConsultationUsingPUTResponse(params: TakenMedicineEntityService.TakenMedicineConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/takenMedicines/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.TakenMedicineConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  takenMedicineConsultationUsingPUT(params: TakenMedicineEntityService.TakenMedicineConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.takenMedicineConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  takenMedicineConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/takenMedicines/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  takenMedicineConsultationUsingDELETE(id: number): Observable<void> {
    return this.takenMedicineConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.TakenMedicineConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  takenMedicineConsultationUsingPATCHResponse(params: TakenMedicineEntityService.TakenMedicineConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/takenMedicines/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `TakenMedicineEntityService.TakenMedicineConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  takenMedicineConsultationUsingPATCH(params: TakenMedicineEntityService.TakenMedicineConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.takenMedicineConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module TakenMedicineEntityService {

  /**
   * Parameters for findAllTakenMedicineUsingGET
   */
  export interface FindAllTakenMedicineUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveTakenMedicineUsingPUT
   */
  export interface SaveTakenMedicineUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TakenMedicine;
  }

  /**
   * Parameters for saveTakenMedicineUsingPATCH
   */
  export interface SaveTakenMedicineUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: TakenMedicine;
  }

  /**
   * Parameters for takenMedicineConsultationUsingPOST
   */
  export interface TakenMedicineConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for takenMedicineConsultationUsingPUT
   */
  export interface TakenMedicineConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for takenMedicineConsultationUsingPATCH
   */
  export interface TakenMedicineConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { TakenMedicineEntityService }
