/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResponseEntity } from '../models/response-entity';

/**
 * Swaggergen Controller
 */
@Injectable()
class SwaggergenControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  initDocsUsingGETResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingGET(): Observable<ResponseEntity> {
    return this.initDocsUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  initDocsUsingHEADResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingHEAD(): Observable<ResponseEntity> {
    return this.initDocsUsingHEADResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  initDocsUsingPOSTResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingPOST(): Observable<ResponseEntity> {
    return this.initDocsUsingPOSTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  initDocsUsingPUTResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingPUT(): Observable<ResponseEntity> {
    return this.initDocsUsingPUTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  initDocsUsingDELETEResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingDELETE(): Observable<ResponseEntity> {
    return this.initDocsUsingDELETEResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  initDocsUsingOPTIONSResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingOPTIONS(): Observable<ResponseEntity> {
    return this.initDocsUsingOPTIONSResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  initDocsUsingPATCHResponse(): Observable<HttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/initialize/documentation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResponseEntity = null;
        _body = _resp.body as ResponseEntity;
        return _resp.clone({body: _body}) as HttpResponse<ResponseEntity>;
      })
    );
  }

  /**
   * @return OK
   */
  initDocsUsingPATCH(): Observable<ResponseEntity> {
    return this.initDocsUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module SwaggergenControllerService {
}

export { SwaggergenControllerService }
