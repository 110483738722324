/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesOrderStatusflow } from '../models/resources-order-statusflow-';
import { ResourceOrderStatusflow } from '../models/resource-order-statusflow-';
import { OrderStatusflow } from '../models/order-statusflow';
import { ResourceOrder } from '../models/resource-order-';

/**
 * $ Proxy 180
 */
@Injectable()
class OrderStatusflowEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrderStatusflowEntityService.FindAllOrderStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderStatusflowUsingGETResponse(params: OrderStatusflowEntityService.FindAllOrderStatusflowUsingGETParams): Observable<HttpResponse<ResourcesOrderStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersStatusflow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderStatusflow = null;
        _body = _resp.body as ResourcesOrderStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.FindAllOrderStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllOrderStatusflowUsingGET(params: OrderStatusflowEntityService.FindAllOrderStatusflowUsingGETParams): Observable<ResourcesOrderStatusflow> {
    return this.findAllOrderStatusflowUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderStatusflowUsingPOSTResponse(body: OrderStatusflow): Observable<HttpResponse<ResourceOrderStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ordersStatusflow`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderStatusflow = null;
        _body = _resp.body as ResourceOrderStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderStatusflow>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveOrderStatusflowUsingPOST(body: OrderStatusflow): Observable<ResourceOrderStatusflow> {
    return this.saveOrderStatusflowUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.FindByOrderIdOrderStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `orderId`: orderId
   *
   * @return OK
   */
  findByOrderIdOrderStatusflowUsingGETResponse(params: OrderStatusflowEntityService.FindByOrderIdOrderStatusflowUsingGETParams): Observable<HttpResponse<ResourcesOrderStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.orderId != null) __params = __params.set('orderId', params.orderId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersStatusflow/search/findByOrderId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesOrderStatusflow = null;
        _body = _resp.body as ResourcesOrderStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesOrderStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.FindByOrderIdOrderStatusflowUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `orderId`: orderId
   *
   * @return OK
   */
  findByOrderIdOrderStatusflowUsingGET(params: OrderStatusflowEntityService.FindByOrderIdOrderStatusflowUsingGETParams): Observable<ResourcesOrderStatusflow> {
    return this.findByOrderIdOrderStatusflowUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderStatusflowUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrderStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderStatusflow = null;
        _body = _resp.body as ResourceOrderStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderStatusflow>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdOrderStatusflowUsingGET(id: number): Observable<ResourceOrderStatusflow> {
    return this.findByIdOrderStatusflowUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.SaveOrderStatusflowUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderStatusflowUsingPUTResponse(params: OrderStatusflowEntityService.SaveOrderStatusflowUsingPUTParams): Observable<HttpResponse<ResourceOrderStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderStatusflow = null;
        _body = _resp.body as ResourceOrderStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.SaveOrderStatusflowUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderStatusflowUsingPUT(params: OrderStatusflowEntityService.SaveOrderStatusflowUsingPUTParams): Observable<ResourceOrderStatusflow> {
    return this.saveOrderStatusflowUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteOrderStatusflowUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteOrderStatusflowUsingDELETE(id: number): Observable<void> {
    return this.deleteOrderStatusflowUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.SaveOrderStatusflowUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderStatusflowUsingPATCHResponse(params: OrderStatusflowEntityService.SaveOrderStatusflowUsingPATCHParams): Observable<HttpResponse<ResourceOrderStatusflow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrderStatusflow = null;
        _body = _resp.body as ResourceOrderStatusflow;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrderStatusflow>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.SaveOrderStatusflowUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveOrderStatusflowUsingPATCH(params: OrderStatusflowEntityService.SaveOrderStatusflowUsingPATCHParams): Observable<ResourceOrderStatusflow> {
    return this.saveOrderStatusflowUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderStatusflowOrderUsingGETResponse(id: number): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  orderStatusflowOrderUsingGET(id: number): Observable<ResourceOrder> {
    return this.orderStatusflowOrderUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.OrderStatusflowOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderStatusflowOrderUsingPOSTResponse(params: OrderStatusflowEntityService.OrderStatusflowOrderUsingPOSTParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.OrderStatusflowOrderUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderStatusflowOrderUsingPOST(params: OrderStatusflowEntityService.OrderStatusflowOrderUsingPOSTParams): Observable<ResourceOrder> {
    return this.orderStatusflowOrderUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.OrderStatusflowOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderStatusflowOrderUsingPUTResponse(params: OrderStatusflowEntityService.OrderStatusflowOrderUsingPUTParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.OrderStatusflowOrderUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderStatusflowOrderUsingPUT(params: OrderStatusflowEntityService.OrderStatusflowOrderUsingPUTParams): Observable<ResourceOrder> {
    return this.orderStatusflowOrderUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  orderStatusflowOrderUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  orderStatusflowOrderUsingDELETE(id: number): Observable<void> {
    return this.orderStatusflowOrderUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.OrderStatusflowOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderStatusflowOrderUsingPATCHResponse(params: OrderStatusflowEntityService.OrderStatusflowOrderUsingPATCHParams): Observable<HttpResponse<ResourceOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/order/ordersStatusflow/${params.id}/order`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceOrder = null;
        _body = _resp.body as ResourceOrder;
        return _resp.clone({body: _body}) as HttpResponse<ResourceOrder>;
      })
    );
  }

  /**
   * @param params The `OrderStatusflowEntityService.OrderStatusflowOrderUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  orderStatusflowOrderUsingPATCH(params: OrderStatusflowEntityService.OrderStatusflowOrderUsingPATCHParams): Observable<ResourceOrder> {
    return this.orderStatusflowOrderUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module OrderStatusflowEntityService {

  /**
   * Parameters for findAllOrderStatusflowUsingGET
   */
  export interface FindAllOrderStatusflowUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByOrderIdOrderStatusflowUsingGET
   */
  export interface FindByOrderIdOrderStatusflowUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * orderId
     */
    orderId?: number;
  }

  /**
   * Parameters for saveOrderStatusflowUsingPUT
   */
  export interface SaveOrderStatusflowUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: OrderStatusflow;
  }

  /**
   * Parameters for saveOrderStatusflowUsingPATCH
   */
  export interface SaveOrderStatusflowUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: OrderStatusflow;
  }

  /**
   * Parameters for orderStatusflowOrderUsingPOST
   */
  export interface OrderStatusflowOrderUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderStatusflowOrderUsingPUT
   */
  export interface OrderStatusflowOrderUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for orderStatusflowOrderUsingPATCH
   */
  export interface OrderStatusflowOrderUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { OrderStatusflowEntityService }
