import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {GoogleLoginProvider} from 'angularx-social-login';
import {AuthService} from 'angularx-social-login';
import {AuthenticationService} from '../login/service/authentication.service';

@Component({
  selector: 'app-timer-popup',
  templateUrl: './timer-popup.component.html',
  styleUrls: ['./timer-popup.component.scss']
})
export class TimerPopupComponent implements OnInit {
  public getTime: Boolean = false;
  constructor(
    public snackBarRef: MatSnackBarRef<TimerPopupComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private ren: Renderer2,
    protected localStorage: LocalStorage,
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.data.getTime >= 0) {
      this.getTime = true;
    } else {
      this.getTime = false;
    }
  }


  logout() {
    this.localStorage.getItem('token').subscribe((token) => {
      if (token  && token.authorities.includes('ASSISTANT')) {
        this.authenticationService.posLogout(token.userID, token.clinicId).subscribe((data) => {
        });
      }
    });
    this.authService.signOut(true);
    this.localStorage.clear().subscribe(() => {
      window.open(
        "https://accounts.google.com/logout", "_blank");
      this.router.navigateByUrl('/login');
      this.snackBarRef.dismiss();
    });
  }

}
