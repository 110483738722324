/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConsultationsForFollowupsForToday } from '../models/consultations-for-followups-for-today';
import { ConsultationAndFollowupDto } from '../models/consultation-and-followup-dto';
import { Consultations } from '../models/consultations';
import {ResourcesSpecialities} from '../models/resources-specialities-';
import {PatientSubscriptionsWoFlow} from '../models/patient-subscription-wo-flow';
import {DoctorInClinicSearch} from '../models/doctor-in-clinic-search';
import {PageConsultationsForAppointmentDetails} from '../models/page-consultations-for-appointment-details';
import {ConsultationDto} from '../models/consultation-dto';
import { ScanbarcodeDto } from '../models';
import { ScanbarcodeResponseDto } from '../models';
/**
 * Consultation Controller
 */
@Injectable()
class ConsultationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param consultationAndFollowupDto consultationAndFollowupDto
   * @return OK
   */
  patchConsultationAndAddFollowupUsingPATCHResponse(consultationAndFollowupDto: ConsultationAndFollowupDto): Observable<HttpResponse<ConsultationsForFollowupsForToday>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = consultationAndFollowupDto;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultation/follow-up`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ConsultationsForFollowupsForToday = null;
        _body = _resp.body as ConsultationsForFollowupsForToday;
        return _resp.clone({body: _body}) as HttpResponse<ConsultationsForFollowupsForToday>;
      })
    );
  }

  /**
   * @param consultationAndFollowupDto consultationAndFollowupDto
   * @return OK
   */
  patchConsultationAndAddFollowupUsingPATCH(consultationAndFollowupDto: ConsultationAndFollowupDto): Observable<ConsultationsForFollowupsForToday> {
    return this.patchConsultationAndAddFollowupUsingPATCHResponse(consultationAndFollowupDto).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  patchPendingConsultationUsingPATCHResponse(): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/consultation/pending`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({body: _body}) as HttpResponse<string>;
      })
    );
  }

  /**
   * @return OK
   */
  patchPendingConsultationUsingPATCH(): Observable<string> {
    return this.patchPendingConsultationUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**

   */
  subscriptionValidityUsingPOSTResponse(body: any): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = body;
    // if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    // if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/subscription/patient/validity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = null;
        _body = _resp.body as any;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getPatientLastSubscriptionsUsingGETResponse(patientId: number): Observable<HttpResponse<PatientSubscriptionsWoFlow>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/subscription/patient/last/subscription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PatientSubscriptionsWoFlow = null;
        _body = _resp.body as PatientSubscriptionsWoFlow;
        return _resp.clone({body: _body}) as HttpResponse<PatientSubscriptionsWoFlow>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getDoctorDisplayInQueueUsingGETResponse(startDate:string, endDate:string): Observable<HttpResponse<DoctorInClinicSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (startDate != null) __params = __params.set('startDate', startDate);
    if (endDate != null) __params = __params.set('endDate', endDate);
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctor-display-in-queue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: DoctorInClinicSearch = null;
        _body = _resp.body as DoctorInClinicSearch;
        return _resp.clone({body: _body}) as HttpResponse<DoctorInClinicSearch>;
      })
    );
  }

  /**
   * @return OK
   * @param startDate
   * @param endDate
   * @param ids
   * @param page
   * @param size
   * @param sort
   */
  fetchAppointmentBetweenDatesV1UsingGetResponse(startDate:string, endDate:string, ids:Array<number>, page:number, size:number, sort:string): Observable<HttpResponse<PageConsultationsForAppointmentDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (startDate != null) __params = __params.set('startDate', startDate);
    if (endDate != null) __params = __params.set('endDate', endDate);
    if (page != null) __params = __params.set('page', page.toString());
    if (size != null) __params = __params.set('size', size.toString());
    if (sort != null) __params = __params.set('sort', sort);
    (ids || []).forEach((val, index) => {if (val != null) __params = __params.append('ids', val.toString())});

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/fetch-appointment/between-dates/v1`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: PageConsultationsForAppointmentDetails = null;
        _body = _resp.body as PageConsultationsForAppointmentDetails;
        return _resp.clone({body: _body}) as HttpResponse<PageConsultationsForAppointmentDetails>;
      })
    );
  }
  /**
   * @param patientId patientId
   * @return OK
   */
  getPatientLastSubscriptionsUsingGET(patientId: number): Observable<PatientSubscriptionsWoFlow> {
    return this.getPatientLastSubscriptionsUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }


  /**

   */
  subscriptionValidityUsingPOST(body: any): Observable<any> {
    return this.subscriptionValidityUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**

   */
  consultationUpdateUsingPOST(consultationDto: ConsultationDto): Observable<ConsultationDto> {
    return this.consultationUpdateUsingPOSTResponse(consultationDto).pipe(
      map(_r => _r.body)
    );
  }

  scanbarcodeUpdateUsingPOST(ScanbarcodeDto: ScanbarcodeDto): Observable<ScanbarcodeResponseDto> {
    return this.scanbarcodeUpdateUsingPOSTResponse(ScanbarcodeDto).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   * @param startDate
   * @param endDate
   */
  getDoctorDisplayInQueueUsingGET(startDate:string, endDate:string): Observable<DoctorInClinicSearch> {
    return this.getDoctorDisplayInQueueUsingGETResponse(startDate, endDate).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   * @param startDate
   * @param endDate
   * @param ids
   * @param page
   * @param size
   * @param sort
   */
  fetchAppointmentBetweenDatesV1UsingGet(startDate:string, endDate:string, ids:Array<number>, page:number, size:number, sort:string): Observable<PageConsultationsForAppointmentDetails> {
    return this.fetchAppointmentBetweenDatesV1UsingGetResponse(startDate, endDate, ids, page, size, sort).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param params The `ConsultationControllerService.RescheduleAppointmentUsingPATCHParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `consultationId`: consultationId
   *
   * - `appointmentTime`: appointmentTime
   *
   * @return OK
   */
  rescheduleAppointmentUsingPATCHResponse(params: ConsultationControllerService.RescheduleAppointmentUsingPATCHParams): Observable<HttpResponse<Consultations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timeslotId != null) __params = __params.set('timeslotId', params.timeslotId.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    if (params.appointmentTime != null) __params = __params.set('appointmentTime', params.appointmentTime.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/reschedule-appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Consultations = null;
        _body = _resp.body as Consultations;
        return _resp.clone({body: _body}) as HttpResponse<Consultations>;
      })
    );
  }

  /**
   * @param params The `ConsultationControllerService.RescheduleAppointmentUsingPATCHParams` containing the following parameters:
   *
   * - `timeslotId`: timeslotId
   *
   * - `consultationId`: consultationId
   *
   * - `appointmentTime`: appointmentTime
   *
   * @return OK
   */
  rescheduleAppointmentUsingPATCH(params: ConsultationControllerService.RescheduleAppointmentUsingPATCHParams): Observable<Consultations> {
    return this.rescheduleAppointmentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  private consultationUpdateUsingPOSTResponse(consultationDto: ConsultationDto): Observable<HttpResponse<ConsultationDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = consultationDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/consultation/update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ConsultationDto = null;
        _body = _resp.body as ConsultationDto;
        return _resp.clone({body: _body}) as HttpResponse<ConsultationDto>;
      })
    );
  }
  

  private scanbarcodeUpdateUsingPOSTResponse(ScanbarcodeDto: ScanbarcodeDto): Observable<HttpResponse<ScanbarcodeResponseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = ScanbarcodeDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/attach/barcode/doxper`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ScanbarcodeDto = null;
        _body = _resp.body as ScanbarcodeDto;
        return _resp.clone({body: _body}) as HttpResponse<ScanbarcodeResponseDto>;
      })
    );
  }
}


module ConsultationControllerService {

  /**
   * Parameters for rescheduleAppointmentUsingPATCH
   */
  export interface RescheduleAppointmentUsingPATCHParams {

    /**
     * timeslotId
     */
    timeslotId: number;

    /**
     * consultationId
     */
    consultationId: number;

    /**
     * appointmentTime
     */
    appointmentTime: string;
  }
}

export { ConsultationControllerService }
