/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesTimeSlotsGrouping } from '../models/resources-time-slots-grouping-';
import { ResourcesDateWiseAppointment } from '../models/resources-date-wise-appointment-';
import { ResourcesTimeSlotsState } from '../models/resources-time-slots-state-';
import { ResourcesAppointmentSummary } from '../models/resources-appointment-summary-';
import { ResourcesTimeSlotsWBooking } from '../models/resources-time-slots-wbooking-';

/**
 * Doctors Appointment Dashboard Controller
 */
@Injectable()
class DoctorsAppointmentDashboardControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingGETResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingGETParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingGET(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingGETParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingHEADResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingHEADParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingHEAD(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingHEADParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingPOSTResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPOSTParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingPOST(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPOSTParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingPUTResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPUTParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingPUT(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPUTParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingDELETEResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingDELETEParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingDELETE(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingDELETEParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingOPTIONSResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingOPTIONSParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingOPTIONS(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingOPTIONSParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingPATCHResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPATCHParams): Observable<HttpResponse<ResourcesTimeSlotsGrouping>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/appointment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsGrouping = null;
        _body = _resp.body as ResourcesTimeSlotsGrouping;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsGrouping>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentDetailUsingPATCH(params: DoctorsAppointmentDashboardControllerService.GetAppointmentDetailUsingPATCHParams): Observable<ResourcesTimeSlotsGrouping> {
    return this.getAppointmentDetailUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingGETResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingGETParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingGET(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingGETParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingHEADResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingHEADParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingHEAD(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingHEADParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingPOSTResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPOSTParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingPOST(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPOSTParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingPUTResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPUTParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingPUT(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPUTParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingDELETEResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingDELETEParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingDELETE(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingDELETEParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingOPTIONSResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingOPTIONSParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingOPTIONS(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingOPTIONSParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingPATCHResponse(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPATCHParams): Observable<HttpResponse<ResourcesDateWiseAppointment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/appointmentDateWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDateWiseAppointment = null;
        _body = _resp.body as ResourcesDateWiseAppointment;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDateWiseAppointment>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getDateWiseAppointmentCountUsingPATCH(params: DoctorsAppointmentDashboardControllerService.GetDateWiseAppointmentCountUsingPATCHParams): Observable<ResourcesDateWiseAppointment> {
    return this.getDateWiseAppointmentCountUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingGETResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingGETParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.set('source', 'web');
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingGET(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingGETParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingHEADResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingHEADParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.set('source', 'web');
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingHEAD(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingHEADParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingPOSTResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPOSTParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.set('source', 'web');
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingPOST(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPOSTParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingPUTResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPUTParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.set('source', 'web');
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingPUT(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPUTParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingDELETEResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingDELETEParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.set('source', 'web');
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingDELETE(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingDELETEParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingOPTIONSResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingOPTIONSParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.set('source', 'web');
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingOPTIONS(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingOPTIONSParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingPATCHResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPATCHParams): Observable<HttpResponse<ResourcesTimeSlotsState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    __headers.set('source', 'web');
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/appointmentForDesktop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsState = null;
        _body = _resp.body as ResourcesTimeSlotsState;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsState>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentInformationUsingPATCH(params: DoctorsAppointmentDashboardControllerService.GetAppointmentInformationUsingPATCHParams): Observable<ResourcesTimeSlotsState> {
    return this.getAppointmentInformationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingGETResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingGETParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingGET(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingGETParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingHEADResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingHEADParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingHEAD(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingHEADParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingPOSTResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPOSTParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingPOST(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPOSTParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingPUTResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPUTParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingPUT(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPUTParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingDELETEResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingDELETEParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingDELETE(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingDELETEParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingOPTIONSResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingOPTIONSParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingOPTIONS(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingOPTIONSParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingPATCHResponse(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPATCHParams): Observable<HttpResponse<ResourcesAppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/appointmentSummary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAppointmentSummary = null;
        _body = _resp.body as ResourcesAppointmentSummary;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAppointmentSummary>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `endDate`: endDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getAppointmentSummaryUsingPATCH(params: DoctorsAppointmentDashboardControllerService.GetAppointmentSummaryUsingPATCHParams): Observable<ResourcesAppointmentSummary> {
    return this.getAppointmentSummaryUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingGETResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingGETParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingGETParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingGET(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingGETParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingHEADResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingHEADParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingHEADParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingHEAD(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingHEADParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingHEADResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingPOSTResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPOSTParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPOSTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingPOST(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPOSTParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingPUTResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPUTParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPUTParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingPUT(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPUTParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingDELETEResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingDELETEParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingDELETEParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingDELETE(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingDELETEParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingOPTIONSResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingOPTIONSParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingOPTIONSParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingOPTIONS(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingOPTIONSParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingOPTIONSResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingPATCHResponse(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPATCHParams): Observable<HttpResponse<ResourcesTimeSlotsWBooking>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.doctorinclinic != null) __params = __params.set('doctorinclinic', params.doctorinclinic.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/doctors/appointmentTimeWise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTimeSlotsWBooking = null;
        _body = _resp.body as ResourcesTimeSlotsWBooking;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTimeSlotsWBooking>;
      })
    );
  }

  /**
   * @param params The `DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPATCHParams` containing the following parameters:
   *
   * - `startDate`: startDate
   *
   * - `doctorinclinic`: doctorinclinic
   *
   * @return OK
   */
  getTimeWiseAppointmentCountUsingPATCH(params: DoctorsAppointmentDashboardControllerService.GetTimeWiseAppointmentCountUsingPATCHParams): Observable<ResourcesTimeSlotsWBooking> {
    return this.getTimeWiseAppointmentCountUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorsAppointmentDashboardControllerService {

  /**
   * Parameters for getAppointmentDetailUsingGET
   */
  export interface GetAppointmentDetailUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentDetailUsingHEAD
   */
  export interface GetAppointmentDetailUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentDetailUsingPOST
   */
  export interface GetAppointmentDetailUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentDetailUsingPUT
   */
  export interface GetAppointmentDetailUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentDetailUsingDELETE
   */
  export interface GetAppointmentDetailUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentDetailUsingOPTIONS
   */
  export interface GetAppointmentDetailUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentDetailUsingPATCH
   */
  export interface GetAppointmentDetailUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingGET
   */
  export interface GetDateWiseAppointmentCountUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingHEAD
   */
  export interface GetDateWiseAppointmentCountUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingPOST
   */
  export interface GetDateWiseAppointmentCountUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingPUT
   */
  export interface GetDateWiseAppointmentCountUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingDELETE
   */
  export interface GetDateWiseAppointmentCountUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingOPTIONS
   */
  export interface GetDateWiseAppointmentCountUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getDateWiseAppointmentCountUsingPATCH
   */
  export interface GetDateWiseAppointmentCountUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingGET
   */
  export interface GetAppointmentInformationUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingHEAD
   */
  export interface GetAppointmentInformationUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingPOST
   */
  export interface GetAppointmentInformationUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingPUT
   */
  export interface GetAppointmentInformationUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingDELETE
   */
  export interface GetAppointmentInformationUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingOPTIONS
   */
  export interface GetAppointmentInformationUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentInformationUsingPATCH
   */
  export interface GetAppointmentInformationUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingGET
   */
  export interface GetAppointmentSummaryUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingHEAD
   */
  export interface GetAppointmentSummaryUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingPOST
   */
  export interface GetAppointmentSummaryUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingPUT
   */
  export interface GetAppointmentSummaryUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingDELETE
   */
  export interface GetAppointmentSummaryUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingOPTIONS
   */
  export interface GetAppointmentSummaryUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getAppointmentSummaryUsingPATCH
   */
  export interface GetAppointmentSummaryUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * endDate
     */
    endDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingGET
   */
  export interface GetTimeWiseAppointmentCountUsingGETParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingHEAD
   */
  export interface GetTimeWiseAppointmentCountUsingHEADParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingPOST
   */
  export interface GetTimeWiseAppointmentCountUsingPOSTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingPUT
   */
  export interface GetTimeWiseAppointmentCountUsingPUTParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingDELETE
   */
  export interface GetTimeWiseAppointmentCountUsingDELETEParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingOPTIONS
   */
  export interface GetTimeWiseAppointmentCountUsingOPTIONSParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }

  /**
   * Parameters for getTimeWiseAppointmentCountUsingPATCH
   */
  export interface GetTimeWiseAppointmentCountUsingPATCHParams {

    /**
     * startDate
     */
    startDate: string;

    /**
     * doctorinclinic
     */
    doctorinclinic: number;
  }
}

export { DoctorsAppointmentDashboardControllerService }
