/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesFollowUpsForToday } from '../models/resources-follow-ups-for-today-';

/**
 * Follow Up Controller
 */
@Injectable()
class FollowUpControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param date date
   * @return OK
   */
  getFollowUpByDateUsingGETResponse(date?: string): Observable<HttpResponse<ResourcesFollowUpsForToday>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (date != null) __params = __params.set('date', date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/follow-up/bydate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesFollowUpsForToday = null;
        _body = _resp.body as ResourcesFollowUpsForToday;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesFollowUpsForToday>;
      })
    );
  }

  /**
   * @param date date
   * @return OK
   */
  getFollowUpByDateUsingGET(date?: string): Observable<ResourcesFollowUpsForToday> {
    return this.getFollowUpByDateUsingGETResponse(date).pipe(
      map(_r => _r.body)
    );
  }
}

module FollowUpControllerService {
}

export { FollowUpControllerService }
