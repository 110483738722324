/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosticTypeInLab } from '../models/resources-diagnostic-type-in-lab-';
import { ResourceDiagnosticTypeInLab } from '../models/resource-diagnostic-type-in-lab-';
import { DiagnosticTypeInLab } from '../models/diagnostic-type-in-lab';
import { ResourceDiagnosticLab } from '../models/resource-diagnostic-lab-';
import { ResourceDiagnosticType } from '../models/resource-diagnostic-type-';
import { ResourcesDiagnostic } from '../models/resources-diagnostic-';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';
import { ResourceTaxes } from '../models/resource-taxes-';

/**
 * $ Proxy 177
 */
@Injectable()
class DiagnosticTypeInLabEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindAllDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticTypeInLabUsingGETResponse(params: DiagnosticTypeInLabEntityService.FindAllDiagnosticTypeInLabUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindAllDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticTypeInLabUsingGET(params: DiagnosticTypeInLabEntityService.FindAllDiagnosticTypeInLabUsingGETParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.findAllDiagnosticTypeInLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticTypeInLabUsingPOSTResponse(body: DiagnosticTypeInLab): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticTypeInLabUsingPOST(body: DiagnosticTypeInLab): Observable<ResourceDiagnosticTypeInLab> {
    return this.saveDiagnosticTypeInLabUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticLabIdDiagnosticTypeInLabUsingGETResponse(params: DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdDiagnosticTypeInLabUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/search/findByDiagnosticLabId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticLabIdDiagnosticTypeInLabUsingGET(params: DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdDiagnosticTypeInLabUsingGETParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.findByDiagnosticLabIdDiagnosticTypeInLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETResponse(params: DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/search/findByDiagnosticLabIdAndDiagnosticTypeNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGET(params: DiagnosticTypeInLabEntityService.FindByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.findByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETResponse(params: DiagnosticTypeInLabEntityService.FindByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/search/findByDiagnosticTypeNameContaining`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.FindByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGET(params: DiagnosticTypeInLabEntityService.FindByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.findByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticTypeInLabUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticTypeInLabUsingGET(id: number): Observable<ResourceDiagnosticTypeInLab> {
    return this.findByIdDiagnosticTypeInLabUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeInLabUsingPUTResponse(params: DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeInLabUsingPUT(params: DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPUTParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.saveDiagnosticTypeInLabUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticTypeInLabUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticTypeInLabUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticTypeInLabUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeInLabUsingPATCHResponse(params: DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeInLabUsingPATCH(params: DiagnosticTypeInLabEntityService.SaveDiagnosticTypeInLabUsingPATCHParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.saveDiagnosticTypeInLabUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/diagnosticLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingGET(id: number): Observable<ResourceDiagnosticLab> {
    return this.diagnosticTypeInLabDiagnosticLabUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingPOSTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnosticLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingPOST(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPOSTParams): Observable<ResourceDiagnosticLab> {
    return this.diagnosticTypeInLabDiagnosticLabUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingPUTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnosticLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingPUT(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPUTParams): Observable<ResourceDiagnosticLab> {
    return this.diagnosticTypeInLabDiagnosticLabUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabDiagnosticLabUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/diagnosticLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabDiagnosticLabUsingDELETE(id: number): Observable<void> {
    return this.diagnosticTypeInLabDiagnosticLabUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingPATCHResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnosticLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticLab = null;
        _body = _resp.body as ResourceDiagnosticLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticLabUsingPATCH(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticLabUsingPATCHParams): Observable<ResourceDiagnosticLab> {
    return this.diagnosticTypeInLabDiagnosticLabUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/diagnosticType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingGET(id: number): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeInLabDiagnosticTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingPOSTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnosticType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingPOST(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPOSTParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeInLabDiagnosticTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingPUTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnosticType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingPUT(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPUTParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeInLabDiagnosticTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabDiagnosticTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/diagnosticType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabDiagnosticTypeUsingDELETE(id: number): Observable<void> {
    return this.diagnosticTypeInLabDiagnosticTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingPATCHResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnosticType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticTypeUsingPATCH(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticTypeUsingPATCHParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeInLabDiagnosticTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingGET_1(id: number): Observable<ResourcesDiagnostic> {
    return this.diagnosticTypeInLabDiagnosticsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingPOSTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingPOST(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPOSTParams): Observable<ResourcesDiagnostic> {
    return this.diagnosticTypeInLabDiagnosticsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingPUTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPUTParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingPUT(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPUTParams): Observable<ResourcesDiagnostic> {
    return this.diagnosticTypeInLabDiagnosticsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabDiagnosticsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabDiagnosticsUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticTypeInLabDiagnosticsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingPATCHResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingPATCH(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingPATCHParams): Observable<ResourcesDiagnostic> {
    return this.diagnosticTypeInLabDiagnosticsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingGETResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingGETParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnostics/${params.diagnosticId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  diagnosticTypeInLabDiagnosticsUsingGET(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingGETParams): Observable<ResourceDiagnostic> {
    return this.diagnosticTypeInLabDiagnosticsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   */
  diagnosticTypeInLabDiagnosticsUsingDELETEResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/diagnostics/${params.diagnosticId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticId`: diagnosticId
   */
  diagnosticTypeInLabDiagnosticsUsingDELETE(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabDiagnosticsUsingDELETEParams): Observable<void> {
    return this.diagnosticTypeInLabDiagnosticsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingGETResponse(id: number): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingGET(id: number): Observable<ResourceTaxes> {
    return this.diagnosticTypeInLabTaxesUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingPOSTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPOSTParams): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingPOST(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPOSTParams): Observable<ResourceTaxes> {
    return this.diagnosticTypeInLabTaxesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingPUTResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPUTParams): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingPUT(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPUTParams): Observable<ResourceTaxes> {
    return this.diagnosticTypeInLabTaxesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabTaxesUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${id}/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeInLabTaxesUsingDELETE(id: number): Observable<void> {
    return this.diagnosticTypeInLabTaxesUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingPATCHResponse(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPATCHParams): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagonstictypeinlab/${params.id}/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeInLabTaxesUsingPATCH(params: DiagnosticTypeInLabEntityService.DiagnosticTypeInLabTaxesUsingPATCHParams): Observable<ResourceTaxes> {
    return this.diagnosticTypeInLabTaxesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticTypeInLabEntityService {

  /**
   * Parameters for findAllDiagnosticTypeInLabUsingGET
   */
  export interface FindAllDiagnosticTypeInLabUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByDiagnosticLabIdDiagnosticTypeInLabUsingGET
   */
  export interface FindByDiagnosticLabIdDiagnosticTypeInLabUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGET
   */
  export interface FindByDiagnosticLabIdAndDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGET
   */
  export interface FindByDiagnosticTypeNameContainingDiagnosticTypeInLabUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveDiagnosticTypeInLabUsingPUT
   */
  export interface SaveDiagnosticTypeInLabUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticTypeInLab;
  }

  /**
   * Parameters for saveDiagnosticTypeInLabUsingPATCH
   */
  export interface SaveDiagnosticTypeInLabUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticTypeInLab;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticLabUsingPOST
   */
  export interface DiagnosticTypeInLabDiagnosticLabUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticLabUsingPUT
   */
  export interface DiagnosticTypeInLabDiagnosticLabUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticLabUsingPATCH
   */
  export interface DiagnosticTypeInLabDiagnosticLabUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticTypeUsingPOST
   */
  export interface DiagnosticTypeInLabDiagnosticTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticTypeUsingPUT
   */
  export interface DiagnosticTypeInLabDiagnosticTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticTypeUsingPATCH
   */
  export interface DiagnosticTypeInLabDiagnosticTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticsUsingPOST
   */
  export interface DiagnosticTypeInLabDiagnosticsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticsUsingPUT
   */
  export interface DiagnosticTypeInLabDiagnosticsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticsUsingPATCH
   */
  export interface DiagnosticTypeInLabDiagnosticsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticsUsingGET
   */
  export interface DiagnosticTypeInLabDiagnosticsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticId
     */
    diagnosticId: string;
  }

  /**
   * Parameters for diagnosticTypeInLabDiagnosticsUsingDELETE
   */
  export interface DiagnosticTypeInLabDiagnosticsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticId
     */
    diagnosticId: string;
  }

  /**
   * Parameters for diagnosticTypeInLabTaxesUsingPOST
   */
  export interface DiagnosticTypeInLabTaxesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabTaxesUsingPUT
   */
  export interface DiagnosticTypeInLabTaxesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeInLabTaxesUsingPATCH
   */
  export interface DiagnosticTypeInLabTaxesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { DiagnosticTypeInLabEntityService }
