import { Component, Input, OnInit } from '@angular/core';
import { ResourceConsultation } from "../../api/emr/models/resource-consultation-";

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {
  isuncover: boolean = false;

  @Input() consultation: ResourceConsultation;
  @Input() order;

  constructor() { }

  getConsultation() {
    if (this.consultation) {
      if (this.order && this.order.payLaterForHomeCollectionOnTests) {
        return {
          option: 'order',
          order: this.order
        }
      } else {
        return {
          option: 'consultation',
          consultation: this.consultation
        };
      }
    } else if (this.order) {
      return {
        option: 'order',
        order: this.order
      }
    }
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if (isDermaHost > -1) {
      this.isuncover = true;
    }
  }

}
