import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';

@Component({
  selector: 'app-create-new-batch',
  templateUrl: './create-new-batch.component.html',
  styleUrls: ['./create-new-batch.component.scss']
})
export class CreateNewBatchComponent implements OnInit {

  addBatchForm: FormGroup;
  medicine;
  savingBatch: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private MatDialogRef: MatDialogRef<CreateNewBatchComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private HttpClient: HttpClient,
    private EmrApiConfiguration: ApiConfiguration
  ) {
    this.medicine = data.medicine;
  }

  saveNewBatch() {
    this.savingBatch = true;
    let { sku, expiryDate, mrp, batchNumber, dateOfManufacture, procurementPrice, sourceFrom, medicine, medicineName, isActive } = this.addBatchForm.getRawValue();

    this.HttpClient.post(
      this.EmrApiConfiguration.rootUrl + '/api/v1/emr/batches',
      { sku, expiryDate, mrp: parseFloat(mrp), batchNumber, dateOfManufacture, procurementPrice: parseFloat(procurementPrice), sourceFrom, medicine, medicineName, isActive }
    ).subscribe(
      batch => this.MatDialogRef.close(batch),
      err => {
        alert('Error while saving batch');
        this.savingBatch = false;

      }
    )
  }

  ngOnInit() {

    this.addBatchForm = this.formBuilder.group({
      sku: ['', Validators.required],
      expiryDate: ['', Validators.required],
      mrp: ['', Validators.required],
      batchNumber: ['', Validators.required],
      dateOfManufacture: ['', Validators.required],
      procurementPrice: ['', Validators.required],
      sourceFrom: ['', Validators.required],
      medicine: [this.medicine._links.self.href, Validators.required],
      medicineName: [this.medicine.name],
      isActive: [true, Validators.required]
    });

    this.addBatchForm.get('batchNumber').valueChanges.subscribe(value => this.addBatchForm.get('sku').setValue(value));
  }

}
