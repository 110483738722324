/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesAllergy } from '../models/resources-allergy-';
import { ResourceAllergy } from '../models/resource-allergy-';
import { Allergy } from '../models/allergy';
import { ResourceAllergyType } from '../models/resource-allergy-type-';
import { ResourceConsultation } from '../models/resource-consultation-';

/**
 * $ Proxy 220
 */
@Injectable()
class AllergyEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AllergyEntityService.FindAllAllergyUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAllergyUsingGETResponse(params: AllergyEntityService.FindAllAllergyUsingGETParams): Observable<HttpResponse<ResourcesAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAllergy = null;
        _body = _resp.body as ResourcesAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.FindAllAllergyUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAllergyUsingGET(params: AllergyEntityService.FindAllAllergyUsingGETParams): Observable<ResourcesAllergy> {
    return this.findAllAllergyUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAllergyUsingPOSTResponse(body: Allergy): Observable<HttpResponse<ResourceAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/allergies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergy = null;
        _body = _resp.body as ResourceAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergy>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAllergyUsingPOST(body: Allergy): Observable<ResourceAllergy> {
    return this.saveAllergyUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAllergyUsingGETResponse(id: number): Observable<HttpResponse<ResourceAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergies/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergy = null;
        _body = _resp.body as ResourceAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergy>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAllergyUsingGET(id: number): Observable<ResourceAllergy> {
    return this.findByIdAllergyUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.SaveAllergyUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyUsingPUTResponse(params: AllergyEntityService.SaveAllergyUsingPUTParams): Observable<HttpResponse<ResourceAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergy = null;
        _body = _resp.body as ResourceAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.SaveAllergyUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyUsingPUT(params: AllergyEntityService.SaveAllergyUsingPUTParams): Observable<ResourceAllergy> {
    return this.saveAllergyUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteAllergyUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/allergies/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteAllergyUsingDELETE(id: number): Observable<void> {
    return this.deleteAllergyUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.SaveAllergyUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyUsingPATCHResponse(params: AllergyEntityService.SaveAllergyUsingPATCHParams): Observable<HttpResponse<ResourceAllergy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergy = null;
        _body = _resp.body as ResourceAllergy;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergy>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.SaveAllergyUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAllergyUsingPATCH(params: AllergyEntityService.SaveAllergyUsingPATCHParams): Observable<ResourceAllergy> {
    return this.saveAllergyUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  allergyAllergyTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergies/${id}/allergyType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  allergyAllergyTypeUsingGET(id: number): Observable<ResourceAllergyType> {
    return this.allergyAllergyTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyAllergyTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyAllergyTypeUsingPOSTResponse(params: AllergyEntityService.AllergyAllergyTypeUsingPOSTParams): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}/allergyType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyAllergyTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyAllergyTypeUsingPOST(params: AllergyEntityService.AllergyAllergyTypeUsingPOSTParams): Observable<ResourceAllergyType> {
    return this.allergyAllergyTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyAllergyTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyAllergyTypeUsingPUTResponse(params: AllergyEntityService.AllergyAllergyTypeUsingPUTParams): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}/allergyType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyAllergyTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyAllergyTypeUsingPUT(params: AllergyEntityService.AllergyAllergyTypeUsingPUTParams): Observable<ResourceAllergyType> {
    return this.allergyAllergyTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  allergyAllergyTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/allergies/${id}/allergyType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  allergyAllergyTypeUsingDELETE(id: number): Observable<void> {
    return this.allergyAllergyTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyAllergyTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyAllergyTypeUsingPATCHResponse(params: AllergyEntityService.AllergyAllergyTypeUsingPATCHParams): Observable<HttpResponse<ResourceAllergyType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}/allergyType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAllergyType = null;
        _body = _resp.body as ResourceAllergyType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAllergyType>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyAllergyTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyAllergyTypeUsingPATCH(params: AllergyEntityService.AllergyAllergyTypeUsingPATCHParams): Observable<ResourceAllergyType> {
    return this.allergyAllergyTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  allergyConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/allergies/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  allergyConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.allergyConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyConsultationUsingPOSTResponse(params: AllergyEntityService.AllergyConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyConsultationUsingPOST(params: AllergyEntityService.AllergyConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.allergyConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyConsultationUsingPUTResponse(params: AllergyEntityService.AllergyConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyConsultationUsingPUT(params: AllergyEntityService.AllergyConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.allergyConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  allergyConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/allergies/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  allergyConsultationUsingDELETE(id: number): Observable<void> {
    return this.allergyConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyConsultationUsingPATCHResponse(params: AllergyEntityService.AllergyConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/allergies/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `AllergyEntityService.AllergyConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  allergyConsultationUsingPATCH(params: AllergyEntityService.AllergyConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.allergyConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AllergyEntityService {

  /**
   * Parameters for findAllAllergyUsingGET
   */
  export interface FindAllAllergyUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveAllergyUsingPUT
   */
  export interface SaveAllergyUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Allergy;
  }

  /**
   * Parameters for saveAllergyUsingPATCH
   */
  export interface SaveAllergyUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Allergy;
  }

  /**
   * Parameters for allergyAllergyTypeUsingPOST
   */
  export interface AllergyAllergyTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for allergyAllergyTypeUsingPUT
   */
  export interface AllergyAllergyTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for allergyAllergyTypeUsingPATCH
   */
  export interface AllergyAllergyTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for allergyConsultationUsingPOST
   */
  export interface AllergyConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for allergyConsultationUsingPUT
   */
  export interface AllergyConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for allergyConsultationUsingPATCH
   */
  export interface AllergyConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { AllergyEntityService }
