import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { SelectAddressComponent } from "../select-address/select-address.component";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Timestamp } from '../../../api/emr/models';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';
import { TimeSlotsComponent } from '../../time-slots/time-slots.component';
import moment from 'moment';

@Component({
  selector: 'app-dialog-transcription-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' }
  ]
})
export class TranscriptionDiagnosticsDialog implements OnInit {
  consultation
  diagnosticType;
  clinicId;
  minDateForMatDateTimePicker;
  loadingDiagnosticTypeInLabs = true;
  throughEprescription = false;
  diagnosticLabId: any;
  homePincode: any;
  clinicPincode: any;
  order: any;
  @ViewChild(TimeSlotsComponent) timeSlot: TimeSlotsComponent;
  selectedSlot;
  HAS_LITE_PATHOLOGY: any;

  constructor(
    public dialogRef: MatDialogRef<TranscriptionDiagnosticsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private MatDialog: MatDialog,
    private HttpClient: HttpClient,
    private LocalStorage: LocalStorage,
    private EmrApiConfiguration: ApiConfiguration
  ) {
    this.diagnosticType = data.data.diagnosticType;
    this.diagnosticLabId = data.data.diagnosticLabId;
    this.diagnosticType.transcribing = data.data.transcribing;
    this.diagnosticType.dueBy = this.diagnosticType.dueBy ? this.diagnosticType.dueBy as Timestamp : new Date() as Timestamp;
    this.consultation = data.data.consultation || {};
    this.throughEprescription = data.throughEprescription;
    this.minDateForMatDateTimePicker = new Date();
    this.HAS_LITE_PATHOLOGY = data.data.HAS_LITE_PATHOLOGY ? true : false;
    this.clinicId = data.data.clinicId;
  }

  getSelectedDiagnosticTypeInLab(v1, v2) {
    return v1.id === (v2 && v2.id);
  }

  ngOnInit() {
    this.loadingDiagnosticTypeInLabs = true;
    this.LocalStorage.getItem("cartOrder").subscribe(order => {
      this.order = order;
      this.clinicPincode = order.doctorInClinic.clinic.pincode;
      this.homePincode = order.addressPin;
      this.diagnosticType.scheduleType == 'now' ? this.isNow(false) : (this.diagnosticType.scheduleType == 'later' ? this.isDeferred(false) : this.isDeferred(true))
      if (!(this.diagnosticType.diagnosticTypeInLabs && this.diagnosticType.diagnosticTypeInLabs.length) || !this.throughEprescription) {
        //this.loadDiagnosticTypeInLabs();
      } else {
        if (!this.diagnosticType.diagnosticTypeInLab) {
          this.diagnosticType.diagnosticTypeInLab = this.diagnosticType.diagnosticTypeInLabs.find(diagnosticTypeInLab => {
            return diagnosticTypeInLab.diagnosticLab.id === this.diagnosticLabId;
          })
        }
        this.loadingDiagnosticTypeInLabs = false;
      }

      if (this.diagnosticType.transcribing) {
        this.isDeferred(true)
      }
    })
  }

  loadDiagnosticTypeInLabs() {
    this.loadingDiagnosticTypeInLabs = true;
    if (this.diagnosticType.scheduleType && (this.homePincode || this.clinicPincode)) {
      this.HttpClient.get(
        this.EmrApiConfiguration.rootUrl + '/api/v1/emr/diagnostic/revamp/labs-in-clinic/v2', {
          params: {
            testId: this.diagnosticType.id,
            pincode: this.diagnosticType.scheduleType == 'later' ? this.homePincode : this.clinicPincode
          }
        }
      ).subscribe(
        (res: any) => {
          if (res && res._embedded) {
            this.diagnosticType.diagnosticTypeInLabs = res._embedded.diagonstictypeinlab;
            if (this.diagnosticLabId) {
              this.diagnosticType.diagnosticTypeInLabs = this.diagnosticType.diagnosticTypeInLabs.filter(diagnosticTypeInLab => {
                return diagnosticTypeInLab.diagnosticLab.id === this.diagnosticLabId;
              })
            }
            if (!this.diagnosticType.diagnosticTypeInLab) {
              this.diagnosticType.diagnosticTypeInLab = res._embedded.diagonstictypeinlab.find(diagnosticTypeInLab => {
                return diagnosticTypeInLab.diagnosticLab.id === this.diagnosticLabId;
              })
            }
          }
          else {
            this.diagnosticType.diagnosticTypeInLabs = [];
          }

          if (this.diagnosticType.diagnosticTypeInLabs.length == 0) {
            if (res && res._embedded && res._embedded.diagonstictypeinlab.length) {
              alert('Sorry, This test is not available with the selected lab.');
            } else {
              alert('Sorry, Pathology Collection services are not available at the selected pin code');
            }
            this.diagnosticType.diagnosticTypeInLab = null;
          } else if (this.diagnosticType.diagnosticTypeInLabs.length == 1) {
              this.diagnosticType.diagnosticTypeInLab = this.diagnosticType.diagnosticTypeInLabs[0];
          }

          this.loadingDiagnosticTypeInLabs = false;
        },
        err => {
          let error = err.error;
          if (error && (error.status == 404)) {
            alert('Sorry, Pathology Collection services are not available at the selected pin code or selected lab.');
          }
          else if (error && error.message) {
            alert(error.message);
          } else {
            alert('Error while loading diagnostic labs');
          }
        }
      )
    } else {
      alert((this.clinicPincode ? "Patient " : "Clinic ") + "pincode not available");
      if (this.clinicPincode) {
        this.addressDialog()
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if (this.diagnosticType.dueBy == null) { this.isNow(false) }
    let diagnostic;
    if (this.diagnosticType.scheduleType === "later") {
      diagnostic = { ...this.diagnosticType, "startTime": this.selectedSlot.start, "endTime": this.selectedSlot.end, "expectedArrivalTime": this.selectedSlot.start }
    } else {
      diagnostic = this.diagnosticType
    }
    this.dialogRef.close(diagnostic);
  }

  isDeferred(state: boolean): void {
    this.selectedSlot = null;
    this.diagnosticType.deferred = state;
    if (state && this.order) {
      this.diagnosticType.scheduleType = 'deferred';
      this.diagnosticType.isPickUp = false;
      this.loadDiagnosticTypeInLabs();
    } else if (this.order && this.order.addressPin) {
      this.diagnosticType.scheduleType = 'later';
      this.diagnosticType.dueBy = new Date(this.diagnosticType.dueBy) as Timestamp;
      this.diagnosticType.isPickUp = true;
      this.loadDiagnosticTypeInLabs();
    } else {
      this.addressDialog();
    }
  }

  addressDialog(state?) {
    if (this.order) {
      let dialogRef = this.MatDialog.open(SelectAddressComponent, {
        maxHeight: "120vh",
        minWidth: "80vw",
        disableClose: true,
        data: {
          patient: this.order.searchPatient
        }
      });

      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.order.addressId = res.id;
          this.order.addressPin = res.pincode;
          this.LocalStorage.setItem("cartOrder", this.order).subscribe(
            order => {
              this.homePincode = this.order.addressPin;
              this.isDeferred(state);
            }
          );
        }
        else {
          if (this.HAS_LITE_PATHOLOGY) {
            this.dialogRef.close();
            return;
          }
          this.isNow(false);
        }
      });
    }
  }

  isNow(state: boolean): void {
    this.diagnosticType.deferred = state;
    this.diagnosticType.scheduleType = 'now';
    if (this.HAS_LITE_PATHOLOGY) {
      this.isDeferred(false);
    }
    this.diagnosticType.isPickUp = false;
    this.diagnosticType.dueBy = new Date() as Timestamp;
    this.loadDiagnosticTypeInLabs();
  }

  isSubmitButtonDisabled() {
    if (this.diagnosticType.scheduleType && this.diagnosticType.diagnosticTypeInLab && !this.loadingDiagnosticTypeInLabs) {
      if (this.diagnosticType.scheduleType === 'now') {
        return false;
      } else {
        let validDate = Date.parse(this.diagnosticType.dueBy);
        if (validDate) {
          if (this.diagnosticType.scheduleType === "deferred") {
            return false
          }
          if (this.selectedSlot) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  setSlot(event) {
    this.selectedSlot = event;
  }
  changeDate(event) {
    if (moment(event.target.value).isValid()) {
      this.timeSlot.fetchTimeSlots(event.target.value);
    }
  }
}
