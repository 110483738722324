/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesHealthProblemTagsForSpeciality } from '../models/resources-health-problem-tags-for-speciality-';
import { ResourceHealthProblemTagsForSpeciality } from '../models/resource-health-problem-tags-for-speciality-';
import { HealthProblemTagsForSpeciality } from '../models/health-problem-tags-for-speciality';
import { ResourcesSpeciality } from '../models/resources-speciality-';
import { ResourceSpeciality } from '../models/resource-speciality-';

/**
 * $ Proxy 208
 */
@Injectable()
class HealthProblemTagsForSpecialityEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.FindAllHealthProblemTagsForSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllHealthProblemTagsForSpecialityUsingGETResponse(params: HealthProblemTagsForSpecialityEntityService.FindAllHealthProblemTagsForSpecialityUsingGETParams): Observable<HttpResponse<ResourcesHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourcesHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.FindAllHealthProblemTagsForSpecialityUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllHealthProblemTagsForSpecialityUsingGET(params: HealthProblemTagsForSpecialityEntityService.FindAllHealthProblemTagsForSpecialityUsingGETParams): Observable<ResourcesHealthProblemTagsForSpeciality> {
    return this.findAllHealthProblemTagsForSpecialityUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveHealthProblemTagsForSpecialityUsingPOSTResponse(body: HealthProblemTagsForSpeciality): Observable<HttpResponse<ResourceHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourceHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveHealthProblemTagsForSpecialityUsingPOST(body: HealthProblemTagsForSpeciality): Observable<ResourceHealthProblemTagsForSpeciality> {
    return this.saveHealthProblemTagsForSpecialityUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param healthTags healthTags
   * @return OK
   */
  searchWithNameHealthProblemTagsForSpecialityUsingGETResponse(healthTags?: string): Observable<HttpResponse<ResourcesHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (healthTags != null) __params = __params.set('healthTags', healthTags.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/search/searchWithName`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourcesHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param healthTags healthTags
   * @return OK
   */
  searchWithNameHealthProblemTagsForSpecialityUsingGET(healthTags?: string): Observable<ResourcesHealthProblemTagsForSpeciality> {
    return this.searchWithNameHealthProblemTagsForSpecialityUsingGETResponse(healthTags).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdHealthProblemTagsForSpecialityUsingGETResponse(id: number): Observable<HttpResponse<ResourceHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourceHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdHealthProblemTagsForSpecialityUsingGET(id: number): Observable<ResourceHealthProblemTagsForSpeciality> {
    return this.findByIdHealthProblemTagsForSpecialityUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForSpecialityUsingPUTResponse(params: HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPUTParams): Observable<HttpResponse<ResourceHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourceHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForSpecialityUsingPUT(params: HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPUTParams): Observable<ResourceHealthProblemTagsForSpeciality> {
    return this.saveHealthProblemTagsForSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteHealthProblemTagsForSpecialityUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteHealthProblemTagsForSpecialityUsingDELETE(id: number): Observable<void> {
    return this.deleteHealthProblemTagsForSpecialityUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForSpecialityUsingPATCHResponse(params: HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPATCHParams): Observable<HttpResponse<ResourceHealthProblemTagsForSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceHealthProblemTagsForSpeciality = null;
        _body = _resp.body as ResourceHealthProblemTagsForSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceHealthProblemTagsForSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveHealthProblemTagsForSpecialityUsingPATCH(params: HealthProblemTagsForSpecialityEntityService.SaveHealthProblemTagsForSpecialityUsingPATCHParams): Observable<ResourceHealthProblemTagsForSpeciality> {
    return this.saveHealthProblemTagsForSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingGET_1(id: number): Observable<ResourcesSpeciality> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingPOSTResponse(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPOSTParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingPOST(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPOSTParams): Observable<ResourcesSpeciality> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingPUTResponse(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPUTParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingPUT(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPUTParams): Observable<ResourcesSpeciality> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  healthProblemTagsForSpecialitySpecialitiesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  healthProblemTagsForSpecialitySpecialitiesUsingDELETE_1(id: number): Observable<void> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingPATCHResponse(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPATCHParams): Observable<HttpResponse<ResourcesSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpeciality = null;
        _body = _resp.body as ResourcesSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingPATCH(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingPATCHParams): Observable<ResourcesSpeciality> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingGETParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingGETResponse(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingGETParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}/specialities/${params.specialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingGETParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   *
   * @return OK
   */
  healthProblemTagsForSpecialitySpecialitiesUsingGET(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingGETParams): Observable<ResourceSpeciality> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingDELETEParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   */
  healthProblemTagsForSpecialitySpecialitiesUsingDELETEResponse(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/healthProblemTagsForSpeciality/${params.id}/specialities/${params.specialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingDELETEParams` containing the following parameters:
   *
   * - `specialityId`: specialityId
   *
   * - `id`: id
   */
  healthProblemTagsForSpecialitySpecialitiesUsingDELETE(params: HealthProblemTagsForSpecialityEntityService.HealthProblemTagsForSpecialitySpecialitiesUsingDELETEParams): Observable<void> {
    return this.healthProblemTagsForSpecialitySpecialitiesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module HealthProblemTagsForSpecialityEntityService {

  /**
   * Parameters for findAllHealthProblemTagsForSpecialityUsingGET
   */
  export interface FindAllHealthProblemTagsForSpecialityUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveHealthProblemTagsForSpecialityUsingPUT
   */
  export interface SaveHealthProblemTagsForSpecialityUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: HealthProblemTagsForSpeciality;
  }

  /**
   * Parameters for saveHealthProblemTagsForSpecialityUsingPATCH
   */
  export interface SaveHealthProblemTagsForSpecialityUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: HealthProblemTagsForSpeciality;
  }

  /**
   * Parameters for healthProblemTagsForSpecialitySpecialitiesUsingPOST
   */
  export interface HealthProblemTagsForSpecialitySpecialitiesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for healthProblemTagsForSpecialitySpecialitiesUsingPUT
   */
  export interface HealthProblemTagsForSpecialitySpecialitiesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for healthProblemTagsForSpecialitySpecialitiesUsingPATCH
   */
  export interface HealthProblemTagsForSpecialitySpecialitiesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for healthProblemTagsForSpecialitySpecialitiesUsingGET
   */
  export interface HealthProblemTagsForSpecialitySpecialitiesUsingGETParams {

    /**
     * specialityId
     */
    specialityId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for healthProblemTagsForSpecialitySpecialitiesUsingDELETE
   */
  export interface HealthProblemTagsForSpecialitySpecialitiesUsingDELETEParams {

    /**
     * specialityId
     */
    specialityId: string;

    /**
     * id
     */
    id: number;
  }
}

export { HealthProblemTagsForSpecialityEntityService }
