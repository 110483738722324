import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardSearch'
})
export class DashboardSearch implements PipeTransform {

  transform(value: any, args?: any, option?: string): any {
    switch (option) {
      case 'emr':
        if (args) {
          return value.filter(emr =>
            (
              (
                emr.patient.name.toLowerCase().indexOf(args.toLowerCase()) != -1
              ) || emr.patient.phone.indexOf(args) != -1)
          )
        } else {
          return value;
        }

      case 'invoice':
        if (args) {
          return value.filter(invoice =>
            (
              (
                invoice.order.patientName.toLowerCase().indexOf(args.toLowerCase()) != -1
              ) || (
                invoice.order.mobileNo.indexOf(args) != -1
              ) || (
                invoice.id.toString().indexOf(args) != -1
              )
            )
          )
        } else {
          return value;
        }

      case 'order':
        if (args) {
          return value.filter(order =>
            (
              (
                order.patientName.toLowerCase().indexOf(args.toLowerCase()) != -1
              ) || (
                order.mobileNo.indexOf(args) != -1
              ) || (
                order.status.toLowerCase().indexOf(args.toLowerCase()) != -1
              )
            )
          )
        } else {
          return value;
        }
      
      case 'queue':
        if (args) {
          return value.filter(invoice =>
            (
              (
                invoice.order.patientName.toLowerCase().indexOf(args.toLowerCase()) != -1
              ) || (
                invoice.order.mobileNo.indexOf(args) != -1
              ) || (
                invoice.id.toString().indexOf(args) != -1
              )
            )
          )
        } else {
          return value;
        }

      default:
        return value;
    }
  }

}
