/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Operation Handler
 */
@Injectable()
class OperationHandlerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param body body
   * @return OK
   */
  handleUsingGETResponse(body?: {[key: string]: string}): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/actuator/health`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  handleUsingGET(body?: {[key: string]: string}): Observable<{}> {
    return this.handleUsingGETResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  handleUsingGET_1Response(body?: {[key: string]: string}): Observable<HttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/actuator/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {} = null;

        return _resp.clone({body: _body}) as HttpResponse<{}>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  handleUsingGET_1(body?: {[key: string]: string}): Observable<{}> {
    return this.handleUsingGET_1Response(body).pipe(
      map(_r => _r.body)
    );
  }
}

module OperationHandlerService {
}

export { OperationHandlerService }
