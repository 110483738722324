/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesPrescriptionDescription } from '../models/resources-prescription-description-';
import { ResourcePrescriptionDescription } from '../models/resource-prescription-description-';
import { PrescriptionDescription } from '../models/prescription-description';
import { ResourcesMedicationDate } from '../models/resources-medication-date-';
import { ResourceMedicationDate } from '../models/resource-medication-date-';
import { ResourceMedicine } from '../models/resource-medicine-';
import { ResourcePrescription } from '../models/resource-prescription-';
import { ResourcesPrescriptionByDate } from '../models/resources-prescription-by-date-';
import { ResourcePrescriptionByDate } from '../models/resource-prescription-by-date-';

/**
 * $ Proxy 213
 */
@Injectable()
class PrescriptionDescriptionEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.FindAllPrescriptionDescriptionUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPrescriptionDescriptionUsingGETResponse(params: PrescriptionDescriptionEntityService.FindAllPrescriptionDescriptionUsingGETParams): Observable<HttpResponse<ResourcesPrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionDescription = null;
        _body = _resp.body as ResourcesPrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.FindAllPrescriptionDescriptionUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllPrescriptionDescriptionUsingGET(params: PrescriptionDescriptionEntityService.FindAllPrescriptionDescriptionUsingGETParams): Observable<ResourcesPrescriptionDescription> {
    return this.findAllPrescriptionDescriptionUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePrescriptionDescriptionUsingPOSTResponse(body: PrescriptionDescription): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  savePrescriptionDescriptionUsingPOST(body: PrescriptionDescription): Observable<ResourcePrescriptionDescription> {
    return this.savePrescriptionDescriptionUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param code code
   */
  deleteByCodePrescriptionDescriptionUsingGETResponse(code?: string): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (code != null) __params = __params.set('code', code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/search/deleteByCode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param code code
   */
  deleteByCodePrescriptionDescriptionUsingGET(code?: string): Observable<void> {
    return this.deleteByCodePrescriptionDescriptionUsingGETResponse(code).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findAllByPrescriptionIdPrescriptionDescriptionUsingGETResponse(id?: number): Observable<HttpResponse<ResourcesPrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/search/findAllByPrescriptionId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionDescription = null;
        _body = _resp.body as ResourcesPrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionDescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findAllByPrescriptionIdPrescriptionDescriptionUsingGET(id?: number): Observable<ResourcesPrescriptionDescription> {
    return this.findAllByPrescriptionIdPrescriptionDescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPrescriptionDescriptionUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdPrescriptionDescriptionUsingGET(id: number): Observable<ResourcePrescriptionDescription> {
    return this.findByIdPrescriptionDescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionDescriptionUsingPUTResponse(params: PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPUTParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionDescriptionUsingPUT(params: PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPUTParams): Observable<ResourcePrescriptionDescription> {
    return this.savePrescriptionDescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteByIdPrescriptionDescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteByIdPrescriptionDescriptionUsingDELETE(id: number): Observable<void> {
    return this.deleteByIdPrescriptionDescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionDescriptionUsingPATCHResponse(params: PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPATCHParams): Observable<HttpResponse<ResourcePrescriptionDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionDescription = null;
        _body = _resp.body as ResourcePrescriptionDescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionDescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  savePrescriptionDescriptionUsingPATCH(params: PrescriptionDescriptionEntityService.SavePrescriptionDescriptionUsingPATCHParams): Observable<ResourcePrescriptionDescription> {
    return this.savePrescriptionDescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionDatesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionDatesUsingGET_1(id: number): Observable<ResourcesMedicationDate> {
    return this.prescriptionDescriptionDatesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingPOSTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPOSTParams): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingPOST(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPOSTParams): Observable<ResourcesMedicationDate> {
    return this.prescriptionDescriptionDatesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingPUTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPUTParams): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingPUT(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPUTParams): Observable<ResourcesMedicationDate> {
    return this.prescriptionDescriptionDatesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionDatesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionDatesUsingDELETE_1(id: number): Observable<void> {
    return this.prescriptionDescriptionDatesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingPATCHResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPATCHParams): Observable<HttpResponse<ResourcesMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/dates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicationDate = null;
        _body = _resp.body as ResourcesMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicationDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingPATCH(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingPATCHParams): Observable<ResourcesMedicationDate> {
    return this.prescriptionDescriptionDatesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingGETParams` containing the following parameters:
   *
   * - `medicationdateId`: medicationdateId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingGETResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingGETParams): Observable<HttpResponse<ResourceMedicationDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/dates/${params.medicationdateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicationDate = null;
        _body = _resp.body as ResourceMedicationDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicationDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingGETParams` containing the following parameters:
   *
   * - `medicationdateId`: medicationdateId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionDescriptionDatesUsingGET(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingGETParams): Observable<ResourceMedicationDate> {
    return this.prescriptionDescriptionDatesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingDELETEParams` containing the following parameters:
   *
   * - `medicationdateId`: medicationdateId
   *
   * - `id`: id
   */
  prescriptionDescriptionDatesUsingDELETEResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/dates/${params.medicationdateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingDELETEParams` containing the following parameters:
   *
   * - `medicationdateId`: medicationdateId
   *
   * - `id`: id
   */
  prescriptionDescriptionDatesUsingDELETE(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionDatesUsingDELETEParams): Observable<void> {
    return this.prescriptionDescriptionDatesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionMedicineUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionMedicineUsingGET(id: number): Observable<ResourceMedicine> {
    return this.prescriptionDescriptionMedicineUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionMedicineUsingPOSTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPOSTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionMedicineUsingPOST(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPOSTParams): Observable<ResourceMedicine> {
    return this.prescriptionDescriptionMedicineUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionMedicineUsingPUTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPUTParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionMedicineUsingPUT(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPUTParams): Observable<ResourceMedicine> {
    return this.prescriptionDescriptionMedicineUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionMedicineUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionMedicineUsingDELETE(id: number): Observable<void> {
    return this.prescriptionDescriptionMedicineUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionMedicineUsingPATCHResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPATCHParams): Observable<HttpResponse<ResourceMedicine>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/medicine`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicine = null;
        _body = _resp.body as ResourceMedicine;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicine>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionMedicineUsingPATCH(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionMedicineUsingPATCHParams): Observable<ResourceMedicine> {
    return this.prescriptionDescriptionMedicineUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingGETResponse(id: number): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingGET(id: number): Observable<ResourcePrescription> {
    return this.prescriptionDescriptionPrescriptionUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingPOSTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPOSTParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingPOST(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPOSTParams): Observable<ResourcePrescription> {
    return this.prescriptionDescriptionPrescriptionUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingPUTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPUTParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingPUT(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPUTParams): Observable<ResourcePrescription> {
    return this.prescriptionDescriptionPrescriptionUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionPrescriptionUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionPrescriptionUsingDELETE(id: number): Observable<void> {
    return this.prescriptionDescriptionPrescriptionUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingPATCHResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPATCHParams): Observable<HttpResponse<ResourcePrescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescription`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescription = null;
        _body = _resp.body as ResourcePrescription;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescription>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionUsingPATCH(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionUsingPATCHParams): Observable<ResourcePrescription> {
    return this.prescriptionDescriptionPrescriptionUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/prescriptionByDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingGET_1(id: number): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionDescriptionPrescriptionByDateUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingPOSTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPOSTParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescriptionByDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingPOST(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPOSTParams): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionDescriptionPrescriptionByDateUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingPUTResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPUTParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescriptionByDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingPUT(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPUTParams): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionDescriptionPrescriptionByDateUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionPrescriptionByDateUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${id}/prescriptionByDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  prescriptionDescriptionPrescriptionByDateUsingDELETE_1(id: number): Observable<void> {
    return this.prescriptionDescriptionPrescriptionByDateUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingPATCHResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPATCHParams): Observable<HttpResponse<ResourcesPrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescriptionByDate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPrescriptionByDate = null;
        _body = _resp.body as ResourcesPrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingPATCH(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingPATCHParams): Observable<ResourcesPrescriptionByDate> {
    return this.prescriptionDescriptionPrescriptionByDateUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingGETResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingGETParams): Observable<HttpResponse<ResourcePrescriptionByDate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescriptionByDate/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePrescriptionByDate = null;
        _body = _resp.body as ResourcePrescriptionByDate;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePrescriptionByDate>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingGETParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   *
   * @return OK
   */
  prescriptionDescriptionPrescriptionByDateUsingGET(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingGETParams): Observable<ResourcePrescriptionByDate> {
    return this.prescriptionDescriptionPrescriptionByDateUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  prescriptionDescriptionPrescriptionByDateUsingDELETEResponse(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/prescriptionDescriptions/${params.id}/prescriptionByDate/${params.prescriptionbydateId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingDELETEParams` containing the following parameters:
   *
   * - `prescriptionbydateId`: prescriptionbydateId
   *
   * - `id`: id
   */
  prescriptionDescriptionPrescriptionByDateUsingDELETE(params: PrescriptionDescriptionEntityService.PrescriptionDescriptionPrescriptionByDateUsingDELETEParams): Observable<void> {
    return this.prescriptionDescriptionPrescriptionByDateUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module PrescriptionDescriptionEntityService {

  /**
   * Parameters for findAllPrescriptionDescriptionUsingGET
   */
  export interface FindAllPrescriptionDescriptionUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for savePrescriptionDescriptionUsingPUT
   */
  export interface SavePrescriptionDescriptionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PrescriptionDescription;
  }

  /**
   * Parameters for savePrescriptionDescriptionUsingPATCH
   */
  export interface SavePrescriptionDescriptionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: PrescriptionDescription;
  }

  /**
   * Parameters for prescriptionDescriptionDatesUsingPOST
   */
  export interface PrescriptionDescriptionDatesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionDescriptionDatesUsingPUT
   */
  export interface PrescriptionDescriptionDatesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionDescriptionDatesUsingPATCH
   */
  export interface PrescriptionDescriptionDatesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionDescriptionDatesUsingGET
   */
  export interface PrescriptionDescriptionDatesUsingGETParams {

    /**
     * medicationdateId
     */
    medicationdateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for prescriptionDescriptionDatesUsingDELETE
   */
  export interface PrescriptionDescriptionDatesUsingDELETEParams {

    /**
     * medicationdateId
     */
    medicationdateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for prescriptionDescriptionMedicineUsingPOST
   */
  export interface PrescriptionDescriptionMedicineUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDescriptionMedicineUsingPUT
   */
  export interface PrescriptionDescriptionMedicineUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDescriptionMedicineUsingPATCH
   */
  export interface PrescriptionDescriptionMedicineUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionUsingPOST
   */
  export interface PrescriptionDescriptionPrescriptionUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionUsingPUT
   */
  export interface PrescriptionDescriptionPrescriptionUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionUsingPATCH
   */
  export interface PrescriptionDescriptionPrescriptionUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionByDateUsingPOST
   */
  export interface PrescriptionDescriptionPrescriptionByDateUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionByDateUsingPUT
   */
  export interface PrescriptionDescriptionPrescriptionByDateUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionByDateUsingPATCH
   */
  export interface PrescriptionDescriptionPrescriptionByDateUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionByDateUsingGET
   */
  export interface PrescriptionDescriptionPrescriptionByDateUsingGETParams {

    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for prescriptionDescriptionPrescriptionByDateUsingDELETE
   */
  export interface PrescriptionDescriptionPrescriptionByDateUsingDELETEParams {

    /**
     * prescriptionbydateId
     */
    prescriptionbydateId: string;

    /**
     * id
     */
    id: number;
  }
}

export { PrescriptionDescriptionEntityService }
