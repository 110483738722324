/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceCommentsByPatientDetails } from '../models/resource-comments-by-patient-details-';
import { Comments } from '../models/comments';
import { CommentDto } from '../models/comment-dto';

/**
 * Comment For Patient History Controller
 */
@Injectable()
class CommentForPatientHistoryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getCommentsByPatientUsingGETResponse(patientId: number): Observable<HttpResponse<ResourceCommentsByPatientDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patientId != null) __params = __params.set('patientId', patientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentsByPatientDetails = null;
        _body = _resp.body as ResourceCommentsByPatientDetails;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentsByPatientDetails>;
      })
    );
  }

  /**
   * @param patientId patientId
   * @return OK
   */
  getCommentsByPatientUsingGET(patientId: number): Observable<ResourceCommentsByPatientDetails> {
    return this.getCommentsByPatientUsingGETResponse(patientId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param commentBody commentBody
   * @return OK
   */
  postCommentUsingPOSTResponse(commentBody: CommentDto): Observable<HttpResponse<Comments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = commentBody;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/comment-rating`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Comments = null;
        _body = _resp.body as Comments;
        return _resp.clone({body: _body}) as HttpResponse<Comments>;
      })
    );
  }

  /**
   * @param commentBody commentBody
   * @return OK
   */
  postCommentUsingPOST(commentBody: CommentDto): Observable<Comments> {
    return this.postCommentUsingPOSTResponse(commentBody).pipe(
      map(_r => _r.body)
    );
  }
}

module CommentForPatientHistoryControllerService {
}

export { CommentForPatientHistoryControllerService }
