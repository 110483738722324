import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { AuthenticationService } from "../login/service/authentication.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MeddoTitleService {
  meddoTitle: BehaviorSubject<string> = new BehaviorSubject("Dashboard");
  sidenavOpenedChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
  scribbleSidenavOpenedStatus: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  consultation = {
    id: "",
    scribbles: [],
  };
  doctorInClinic = {
    id: "",
    name: "",
  };
  scribblesChanged: Subject<any> = new Subject();
  prescriptionSidenavMode: BehaviorSubject<string> = new BehaviorSubject(
    "side"
  );

  constructor(
    private AuthenticationService: AuthenticationService,
    private HttpClient: HttpClient
  ) {
    this.scribblesChanged.subscribe((scribbles) => {
      this.consultation.scribbles = scribbles;
    });
  }

  changeTitle(newTitle: string) {
    this.meddoTitle.next(newTitle);
  }

  changeSidenavOpenedChange(newVal) {
    this.sidenavOpenedChange.next(newVal);
  }

  toggleScribbleSidenavOpenedStatus(status: boolean) {
    this.scribbleSidenavOpenedStatus.next(status);
  }

  openPrintWindow(scribble, scribbles) {
    var prtContent = `
    <div>
      ${scribbles}
    </div>`;
    var WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(prtContent);
    WinPrint.document.close();
    setTimeout(() => {
      if (
        scribble.scribbleType == "DOXPER" ||
        scribble.scribbleType == "E_PRESCRIPTION"
      ) {
        WinPrint.focus();
        let pdfDoc: any = WinPrint.document.getElementById("pdfDoc");
        pdfDoc.focus();
        // pdfDoc.contentWindow.print();
        // WinPrint.close();
      } else {
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      }
    }, 100);
  }

  printAllScribbles(consultationScribbles) {
    // let scribbles = consultationScribbles.map(scribble => `<img src="${scribble.filePath}" style="width:100%">`).reduce((a, img) => `${a} <div>${img}</div>`, '');
    // var prtContent = `
    // <div>
    //   ${scribbles}
    // </div>`;
    consultationScribbles.forEach((scribble) => {
      window.open(scribble.filePath, "_blank");
    });
    // var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    // WinPrint.document.write(prtContent);
    // WinPrint.document.close();
    // setTimeout(() => {

    //   WinPrint.focus();
    //   WinPrint.print();
    //   WinPrint.close();
    // }, 100)
  }

  printOnePage(scribble) {
    if (scribble.scribbleType == "DOXPER") {
      var scribbles = `<iframe id="pdfDoc" src="${scribble.filePath}" style="width:100%; height:100%"></iframe>`;
      this.openPrintWindow(scribble, scribbles);
    } else if (scribble.scribbleType == "E_PRESCRIPTION") {
      this.HttpClient.get(scribble.filePath, {
        responseType: "text",
      }).subscribe(
        (res) => {
          var scribbles = `<iframe id="pdfDoc" src="data:application/pdf;base64,${res}" style="width:100%; height:100%"></iframe>`;
          this.openPrintWindow(scribble, scribbles);
        },
        (err) => alert("Error while loading image")
      );
    } else {
      var scribbles = `<div><img src="${scribble.filePath}" style="width:100%"></div>`;
      this.openPrintWindow(scribble, scribbles);
    }
  }
}
