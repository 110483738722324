/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


/**
 * Basic Error Controller
 */
@Injectable()
class BasicErrorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  errorUsingGETResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingGET(): Observable<{[key: string]: {}}> {
    return this.errorUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorUsingHEADResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingHEAD(): Observable<{[key: string]: {}}> {
    return this.errorUsingHEADResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorUsingPOSTResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingPOST(): Observable<{[key: string]: {}}> {
    return this.errorUsingPOSTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorUsingPUTResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingPUT(): Observable<{[key: string]: {}}> {
    return this.errorUsingPUTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorUsingDELETEResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingDELETE(): Observable<{[key: string]: {}}> {
    return this.errorUsingDELETEResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorUsingOPTIONSResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingOPTIONS(): Observable<{[key: string]: {}}> {
    return this.errorUsingOPTIONSResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  errorUsingPATCHResponse(): Observable<HttpResponse<{[key: string]: {}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {}}>;
      })
    );
  }

  /**
   * @return OK
   */
  errorUsingPATCH(): Observable<{[key: string]: {}}> {
    return this.errorUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module BasicErrorControllerService {
}

export { BasicErrorControllerService }
