/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosisType } from '../models/resources-diagnosis-type-';
import { ResourceDiagnosisType } from '../models/resource-diagnosis-type-';
import { DiagnosisType } from '../models/diagnosis-type';
import { ResourcesDiagnosis } from '../models/resources-diagnosis-';
import { ResourceDiagnosis } from '../models/resource-diagnosis-';

/**
 * $ Proxy 195
 */
@Injectable()
class DiagnosisTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosisTypeEntityService.FindAllDiagnosisTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosisTypeUsingGETResponse(params: DiagnosisTypeEntityService.FindAllDiagnosisTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosistypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisType = null;
        _body = _resp.body as ResourcesDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.FindAllDiagnosisTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosisTypeUsingGET(params: DiagnosisTypeEntityService.FindAllDiagnosisTypeUsingGETParams): Observable<ResourcesDiagnosisType> {
    return this.findAllDiagnosisTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosisTypeUsingPOSTResponse(body: DiagnosisType): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosistypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosisTypeUsingPOST(body: DiagnosisType): Observable<ResourceDiagnosisType> {
    return this.saveDiagnosisTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param symptoms symptoms
   * @return OK
   */
  searchWithNativeQueryDiagnosisTypeUsingGETResponse(symptoms?: string): Observable<HttpResponse<ResourcesDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (symptoms != null) __params = __params.set('symptoms', symptoms.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosistypes/search/searchWithNativeQuery`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosisType = null;
        _body = _resp.body as ResourcesDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosisType>;
      })
    );
  }

  /**
   * @param symptoms symptoms
   * @return OK
   */
  searchWithNativeQueryDiagnosisTypeUsingGET(symptoms?: string): Observable<ResourcesDiagnosisType> {
    return this.searchWithNativeQueryDiagnosisTypeUsingGETResponse(symptoms).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosisTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosisTypeUsingGET(id: number): Observable<ResourceDiagnosisType> {
    return this.findByIdDiagnosisTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisTypeUsingPUTResponse(params: DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPUTParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisTypeUsingPUT(params: DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPUTParams): Observable<ResourceDiagnosisType> {
    return this.saveDiagnosisTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosisTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosisTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosisTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisTypeUsingPATCHResponse(params: DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosisTypeUsingPATCH(params: DiagnosisTypeEntityService.SaveDiagnosisTypeUsingPATCHParams): Observable<ResourceDiagnosisType> {
    return this.saveDiagnosisTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosisTypeDiagnosesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosisTypeDiagnosesUsingGET_1(id: number): Observable<ResourcesDiagnosis> {
    return this.diagnosisTypeDiagnosesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingPOSTResponse(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingPOST(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPOSTParams): Observable<ResourcesDiagnosis> {
    return this.diagnosisTypeDiagnosesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingPUTResponse(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingPUT(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPUTParams): Observable<ResourcesDiagnosis> {
    return this.diagnosisTypeDiagnosesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosisTypeDiagnosesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosisTypeDiagnosesUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosisTypeDiagnosesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingPATCHResponse(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosis = null;
        _body = _resp.body as ResourcesDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingPATCH(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingPATCHParams): Observable<ResourcesDiagnosis> {
    return this.diagnosisTypeDiagnosesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingGETResponse(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingGETParams): Observable<HttpResponse<ResourceDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}/diagnoses/${params.diagnosisId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosis = null;
        _body = _resp.body as ResourceDiagnosis;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosis>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   *
   * @return OK
   */
  diagnosisTypeDiagnosesUsingGET(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingGETParams): Observable<ResourceDiagnosis> {
    return this.diagnosisTypeDiagnosesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   */
  diagnosisTypeDiagnosesUsingDELETEResponse(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnosistypes/${params.id}/diagnoses/${params.diagnosisId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosisId`: diagnosisId
   */
  diagnosisTypeDiagnosesUsingDELETE(params: DiagnosisTypeEntityService.DiagnosisTypeDiagnosesUsingDELETEParams): Observable<void> {
    return this.diagnosisTypeDiagnosesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosisTypeEntityService {

  /**
   * Parameters for findAllDiagnosisTypeUsingGET
   */
  export interface FindAllDiagnosisTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveDiagnosisTypeUsingPUT
   */
  export interface SaveDiagnosisTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosisType;
  }

  /**
   * Parameters for saveDiagnosisTypeUsingPATCH
   */
  export interface SaveDiagnosisTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosisType;
  }

  /**
   * Parameters for diagnosisTypeDiagnosesUsingPOST
   */
  export interface DiagnosisTypeDiagnosesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosisTypeDiagnosesUsingPUT
   */
  export interface DiagnosisTypeDiagnosesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosisTypeDiagnosesUsingPATCH
   */
  export interface DiagnosisTypeDiagnosesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosisTypeDiagnosesUsingGET
   */
  export interface DiagnosisTypeDiagnosesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosisId
     */
    diagnosisId: string;
  }

  /**
   * Parameters for diagnosisTypeDiagnosesUsingDELETE
   */
  export interface DiagnosisTypeDiagnosesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosisId
     */
    diagnosisId: string;
  }
}

export { DiagnosisTypeEntityService }
