import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from "@angular/material";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiConfiguration } from 'src/app/api/emr/api-configuration';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-select-doctor-profile',
  templateUrl: './select-doctor-profile.component.html',
  styleUrls: ['./select-doctor-profile.component.scss']
})
export class SelectDoctorProfileComponent implements OnInit {
  doctors: any;
  pageInfo: any;
  fetchingDoctor: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private HttpClient: HttpClient,
    private emrApiConfiguration: ApiConfiguration,
    private MatSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SelectDoctorProfileComponent>
  ) { }

  ngOnInit() {
    this.getDoctors();
  }

  selectDoctor(doctor) {
    this.dialogRef.close(doctor);
  }

  getDoctors(size = "10",page?) { 
    this.fetchingDoctor = true;   
    this.HttpClient.get(
      this.emrApiConfiguration.rootUrl + '/api/v1/emr/patient/all-doctors',
      {
        params: {
          size: size,
          isListed : "false",
          speciality: this.data.type,
          page: page?page:"0"
        }
      }
    ).subscribe(
      res => {
        let response: any = res;
        if(response._embedded) {
          this.pageInfo = response.page;
          this.doctors = response._embedded.doctors;
          this.fetchingDoctor = false;
        } else {
          this.MatSnackBar.open('No Doctors found',"",{duration: 2000})
          this.dialogRef.close();
        }
      },
      err => {
        this.fetchingDoctor = false;
        this.MatSnackBar.open('Problem in fetching Doctors',"",{duration: 2000})
      }
    )
  }

  change(e) {
    this.getDoctors(e.pageSize,e.pageIndex);
  }

  speciality(speciality) {
    return speciality.map(sep => sep.name).join(",");
  }
}
