/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Link } from "../models/link";

/**
 * Web Mvc Endpoint Handler Mapping
 */
@Injectable()
class WebMvcEndpointHandlerMappingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  linksUsingGETResponse(): Observable<HttpResponse<{[key: string]: {[key: string]: Link}}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/actuator`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: {[key: string]: {[key: string]: Link}} = null;

        return _resp.clone({body: _body}) as HttpResponse<{[key: string]: {[key: string]: Link}}>;
      })
    );
  }

  /**
   * @return OK
   */
  linksUsingGET(): Observable<{[key: string]: {[key: string]: Link}}> {
    return this.linksUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module WebMvcEndpointHandlerMappingService {
}

export { WebMvcEndpointHandlerMappingService }
