// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  psychologyId: "50",
  physiotherapyId: "49",
  nutritionId: "51",
  sentaryDsn: 'https://e035a47b3548442f84bfb2a3adbd2b01@sentry.local.meddo.in/51',
  authBaseAPI: 'https://auth.staging.meddo.in/',
  emrBaseAPI: 'https://emr.staging.meddo.in',
  orderBaseAPI: 'https://order.staging.meddo.in',
  providerBaseAPI: 'https://provider.staging.meddo.in/',
  transcriberBaseAPI: 'https://transcriber.staging.meddo.in/',
  webAPI: 'https://web.staging.meddo.in',
  billingAPI: 'https://billing.staging.meddo.in',
  metabaseAPI: 'https://metabase.staging.meddo.in',
  otpToken: 'Basic TFJMNlpDRXQ1QWc3d2hhYTpMd3BycmpkTHpNYzl6U3E0WFk0aldDejNoTldZTVZWNg==',
  googleToken: '127006431690-k74kp2m8b9779ii85ln96gj6qpdh203h.apps.googleusercontent.com',
  settlementToken: 'Token c29a646bb06d4e0db4d2edee012959f5cad5d396',
  templateAPI: 'https://template-api.staging.meddo.in',
  versionCheckURL: '/version.json',
  onlyForGmvClinicIds: [2],
  covidTestId: 32680,
  dermaDicIds: [1, 3, 74, 1141, 812, 123, 314, 54, 53, 300, 10001, 1590, 10005, 10006, 10007, 10015, 10025, 10026, 10028],
  paymentBaseAPI: 'https://payments.staging.meddo.in',
  defaultAppointmentClinic: 23
};
// export const environment = {
//   production: true,
//   psychologyId: "99",
//   physiotherapyId: "98",
//   nutritionId: "100",
//   sentaryDsn: 'https://8a3489df8f50497f8edd8ffb6ce89159@sentry.local.meddo.in/4',
//   authBaseAPI: 'https://auth.uat.meddo.in/',
//   emrBaseAPI: 'https://emr.uat.meddo.in',
//   orderBaseAPI: 'https://order.uat.meddo.in',
//   providerBaseAPI: 'https://provider.uat.meddo.in/',
//   transcriberBaseAPI: 'https://transcriber.uat.meddo.in/',
//   webAPI: 'https://web.uat.meddo.in',
//   billingAPI: 'https://billing.api.meddo.in',
//   metabaseAPI: 'https://metabase.api.meddo.in',
//   otpToken: 'Basic dGVzdGp3dGNsaWVudGlkOlhZN2ttem9OemwxMDA=',
//   settlementToken: 'Token f414697b31c6cecf8cfeb000b4f766dc3240b11a',
//   templateAPI: 'https://template-api.uat.meddo.in',
//   versionCheckURL: "https://pos.uat.meddo.in/version.json",
//   onlyForGmvClinicIds: [254, 349],
//   covidTestId: 32680
// };

//f414697b31c6cecf8cfeb000b4f766dc3240b11a
//dGVzdGp3dGNsaWVudGlkOlhZN2ttem9OemwxMDA=
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
