import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import moment from "moment";
import { PatientEntityService } from "src/app/api/emr/services";
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-add-new-patient",
  templateUrl: "./add-new-patient.component.html",
  styleUrls: ["./add-new-patient.component.scss"],
})
export class AddNewPatientComponent implements OnInit {
  newPatientFormGroup: FormGroup;
  savingPatient: boolean = false;
  isuncover:boolean = false;
  bloodGroups = [
    {
      name: "O+ (positive)",
      value: "O+",
    },
    {
      name: "O- (negative)",
      value: "O-",
    },
    {
      name: "A+ (positive)",
      value: "A+",
    },
    {
      name: "A- (negative)",
      value: "A-",
    },
    {
      name: "B+ (positive)",
      value: "B+",
    },
    {
      name: "B- (negative)",
      value: "B-",
    },
    {
      name: "AB+ (positive)",
      value: "AB+",
    },
    {
      name: "AB- (negative)",
      value: "AB-",
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FormBuilder: FormBuilder,
    private PatientEntityService: PatientEntityService,
    private MatDialogRef: MatDialogRef<AddNewPatientComponent>,
    private snackBar: MatSnackBar
  ) {}

  setDateOfBirth() {
    let date = moment()
      .subtract(
        this.newPatientFormGroup.get("age").value,
        this.newPatientFormGroup.get("ageType").value
      )
      .format("YYYY-MM-DD");
    this.newPatientFormGroup.get("dateofbirth").setValue(date);
  }

  save() {
    this.savingPatient = true;
    this.PatientEntityService.savePatientUsingPOST(
      this.newPatientFormGroup.value
    ).subscribe(
      (patient) => this.MatDialogRef.close(patient),
      (err) => {
        if (err && err.error && err.error.message) {
          this.snackBar.open(err.error.message, "", {
            duration: 5000,
            verticalPosition: "top",
          });
          this.savingPatient = false;
        }
      }
    );
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
    this.newPatientFormGroup = this.FormBuilder.group({
      name: ["", Validators.required],
      alternateNumber: ["", Validators.pattern(/^[0-9]{10}$/)],
      gender: ["", Validators.required],
      dateofbirth: ["", Validators.required],
      bloodGroup: [""],
      weight: ["", [Validators.min(1), Validators.max(999)]],
      height: ["", [Validators.min(1), Validators.max(999)]],
      phone: [
        "",
        [Validators.required, Validators.pattern(/^[6-9]{1}[0-9]{9}$/)],
      ],
      ageType: ["years", Validators.required],
      age: ["", [Validators.required, Validators.min(1)]],
    });
    if (this.data.patientPhone && parseInt(this.data.patientPhone)) {
      this.newPatientFormGroup.get("phone").setValue(this.data.patientPhone);
    } else {
      this.newPatientFormGroup.get("name").setValue(this.data.patientPhone);
    }
    this.newPatientFormGroup
      .get("age")
      .valueChanges.subscribe((value) => this.setDateOfBirth());
    this.newPatientFormGroup
      .get("ageType")
      .valueChanges.subscribe((value) => this.setDateOfBirth());
  }
}
