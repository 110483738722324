/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesTaxes } from '../models/resources-taxes-';
import { ResourceTaxes } from '../models/resource-taxes-';
import { Taxes } from '../models/taxes';
import { ResourcesDiagnosticTypeInLab } from '../models/resources-diagnostic-type-in-lab-';
import { ResourceDiagnosticTypeInLab } from '../models/resource-diagnostic-type-in-lab-';

/**
 * $ Proxy 178
 */
@Injectable()
class TaxesEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TaxesEntityService.FindAllTaxesUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTaxesUsingGETResponse(params: TaxesEntityService.FindAllTaxesUsingGETParams): Observable<HttpResponse<ResourcesTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesTaxes = null;
        _body = _resp.body as ResourcesTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesTaxes>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.FindAllTaxesUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllTaxesUsingGET(params: TaxesEntityService.FindAllTaxesUsingGETParams): Observable<ResourcesTaxes> {
    return this.findAllTaxesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTaxesUsingPOSTResponse(body: Taxes): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/taxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveTaxesUsingPOST(body: Taxes): Observable<ResourceTaxes> {
    return this.saveTaxesUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTaxesUsingGETResponse(id: number): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/taxes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdTaxesUsingGET(id: number): Observable<ResourceTaxes> {
    return this.findByIdTaxesUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.SaveTaxesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTaxesUsingPUTResponse(params: TaxesEntityService.SaveTaxesUsingPUTParams): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.SaveTaxesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTaxesUsingPUT(params: TaxesEntityService.SaveTaxesUsingPUTParams): Observable<ResourceTaxes> {
    return this.saveTaxesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteTaxesUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/taxes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteTaxesUsingDELETE(id: number): Observable<void> {
    return this.deleteTaxesUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.SaveTaxesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTaxesUsingPATCHResponse(params: TaxesEntityService.SaveTaxesUsingPATCHParams): Observable<HttpResponse<ResourceTaxes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceTaxes = null;
        _body = _resp.body as ResourceTaxes;
        return _resp.clone({body: _body}) as HttpResponse<ResourceTaxes>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.SaveTaxesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveTaxesUsingPATCH(params: TaxesEntityService.SaveTaxesUsingPATCHParams): Observable<ResourceTaxes> {
    return this.saveTaxesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/taxes/${id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingGET_1(id: number): Observable<ResourcesDiagnosticTypeInLab> {
    return this.taxesDiagnosticTypeInLabUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingPOSTResponse(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingPOST(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingPOSTParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.taxesDiagnosticTypeInLabUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingPUTResponse(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingPUT(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingPUTParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.taxesDiagnosticTypeInLabUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  taxesDiagnosticTypeInLabUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/taxes/${id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  taxesDiagnosticTypeInLabUsingDELETE_1(id: number): Observable<void> {
    return this.taxesDiagnosticTypeInLabUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingPATCHResponse(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingPATCH(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingPATCHParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.taxesDiagnosticTypeInLabUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingGETResponse(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingGETParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}/diagnosticTypeInLab/${params.diagnostictypeinlabId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   *
   * @return OK
   */
  taxesDiagnosticTypeInLabUsingGET(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingGETParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.taxesDiagnosticTypeInLabUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   */
  taxesDiagnosticTypeInLabUsingDELETEResponse(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/taxes/${params.id}/diagnosticTypeInLab/${params.diagnostictypeinlabId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `TaxesEntityService.TaxesDiagnosticTypeInLabUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   */
  taxesDiagnosticTypeInLabUsingDELETE(params: TaxesEntityService.TaxesDiagnosticTypeInLabUsingDELETEParams): Observable<void> {
    return this.taxesDiagnosticTypeInLabUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module TaxesEntityService {

  /**
   * Parameters for findAllTaxesUsingGET
   */
  export interface FindAllTaxesUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveTaxesUsingPUT
   */
  export interface SaveTaxesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Taxes;
  }

  /**
   * Parameters for saveTaxesUsingPATCH
   */
  export interface SaveTaxesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Taxes;
  }

  /**
   * Parameters for taxesDiagnosticTypeInLabUsingPOST
   */
  export interface TaxesDiagnosticTypeInLabUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for taxesDiagnosticTypeInLabUsingPUT
   */
  export interface TaxesDiagnosticTypeInLabUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for taxesDiagnosticTypeInLabUsingPATCH
   */
  export interface TaxesDiagnosticTypeInLabUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for taxesDiagnosticTypeInLabUsingGET
   */
  export interface TaxesDiagnosticTypeInLabUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeinlabId
     */
    diagnostictypeinlabId: string;
  }

  /**
   * Parameters for taxesDiagnosticTypeInLabUsingDELETE
   */
  export interface TaxesDiagnosticTypeInLabUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeinlabId
     */
    diagnostictypeinlabId: string;
  }
}

export { TaxesEntityService }
