/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesAfterOrderPaidStatus } from '../models/resources-after-order-paid-status-';
import { ResourceAfterOrderPaidStatus } from '../models/resource-after-order-paid-status-';
import { AfterOrderPaidStatus } from '../models/after-order-paid-status';

/**
 * $ Proxy 214
 */
@Injectable()
class AfterOrderPaidStatusEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AfterOrderPaidStatusEntityService.FindAllAfterOrderPaidStatusUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAfterOrderPaidStatusUsingGETResponse(params: AfterOrderPaidStatusEntityService.FindAllAfterOrderPaidStatusUsingGETParams): Observable<HttpResponse<ResourcesAfterOrderPaidStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAfterOrderPaidStatus = null;
        _body = _resp.body as ResourcesAfterOrderPaidStatus;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAfterOrderPaidStatus>;
      })
    );
  }

  /**
   * @param params The `AfterOrderPaidStatusEntityService.FindAllAfterOrderPaidStatusUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAfterOrderPaidStatusUsingGET(params: AfterOrderPaidStatusEntityService.FindAllAfterOrderPaidStatusUsingGETParams): Observable<ResourcesAfterOrderPaidStatus> {
    return this.findAllAfterOrderPaidStatusUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAfterOrderPaidStatusUsingPOSTResponse(body: AfterOrderPaidStatus): Observable<HttpResponse<ResourceAfterOrderPaidStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAfterOrderPaidStatus = null;
        _body = _resp.body as ResourceAfterOrderPaidStatus;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAfterOrderPaidStatus>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAfterOrderPaidStatusUsingPOST(body: AfterOrderPaidStatus): Observable<ResourceAfterOrderPaidStatus> {
    return this.saveAfterOrderPaidStatusUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdInAfterOrderPaidStatusUsingGETResponse(id?: number): Observable<HttpResponse<ResourceAfterOrderPaidStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus/search/findByIdIn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAfterOrderPaidStatus = null;
        _body = _resp.body as ResourceAfterOrderPaidStatus;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAfterOrderPaidStatus>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdInAfterOrderPaidStatusUsingGET(id?: number): Observable<ResourceAfterOrderPaidStatus> {
    return this.findByIdInAfterOrderPaidStatusUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAfterOrderPaidStatusUsingGETResponse(id: number): Observable<HttpResponse<ResourceAfterOrderPaidStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAfterOrderPaidStatus = null;
        _body = _resp.body as ResourceAfterOrderPaidStatus;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAfterOrderPaidStatus>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAfterOrderPaidStatusUsingGET(id: number): Observable<ResourceAfterOrderPaidStatus> {
    return this.findByIdAfterOrderPaidStatusUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAfterOrderPaidStatusUsingPUTResponse(params: AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPUTParams): Observable<HttpResponse<ResourceAfterOrderPaidStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAfterOrderPaidStatus = null;
        _body = _resp.body as ResourceAfterOrderPaidStatus;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAfterOrderPaidStatus>;
      })
    );
  }

  /**
   * @param params The `AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAfterOrderPaidStatusUsingPUT(params: AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPUTParams): Observable<ResourceAfterOrderPaidStatus> {
    return this.saveAfterOrderPaidStatusUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteAfterOrderPaidStatusUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteAfterOrderPaidStatusUsingDELETE(id: number): Observable<void> {
    return this.deleteAfterOrderPaidStatusUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAfterOrderPaidStatusUsingPATCHResponse(params: AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPATCHParams): Observable<HttpResponse<ResourceAfterOrderPaidStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/afterOrderPaidStatus/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAfterOrderPaidStatus = null;
        _body = _resp.body as ResourceAfterOrderPaidStatus;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAfterOrderPaidStatus>;
      })
    );
  }

  /**
   * @param params The `AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAfterOrderPaidStatusUsingPATCH(params: AfterOrderPaidStatusEntityService.SaveAfterOrderPaidStatusUsingPATCHParams): Observable<ResourceAfterOrderPaidStatus> {
    return this.saveAfterOrderPaidStatusUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AfterOrderPaidStatusEntityService {

  /**
   * Parameters for findAllAfterOrderPaidStatusUsingGET
   */
  export interface FindAllAfterOrderPaidStatusUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveAfterOrderPaidStatusUsingPUT
   */
  export interface SaveAfterOrderPaidStatusUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: AfterOrderPaidStatus;
  }

  /**
   * Parameters for saveAfterOrderPaidStatusUsingPATCH
   */
  export interface SaveAfterOrderPaidStatusUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: AfterOrderPaidStatus;
  }
}

export { AfterOrderPaidStatusEntityService }
