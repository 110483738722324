/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesCommentOnDocument } from '../models/resources-comment-on-document-';
import { ResourceCommentOnDocument } from '../models/resource-comment-on-document-';
import { CommentOnDocument } from '../models/comment-on-document';
import { ResourcePatient } from '../models/resource-patient-';
import { ResourcesPatientHistory } from '../models/resources-patient-history-';
import { ResourcePatientHistory } from '../models/resource-patient-history-';
import { ResourcesPatientPreviousDocument } from '../models/resources-patient-previous-document-';
import { ResourcePatientPreviousDocument } from '../models/resource-patient-previous-document-';

/**
 * $ Proxy 219
 */
@Injectable()
class CommentOnDocumentEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CommentOnDocumentEntityService.FindAllCommentOnDocumentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllCommentOnDocumentUsingGETResponse(params: CommentOnDocumentEntityService.FindAllCommentOnDocumentUsingGETParams): Observable<HttpResponse<ResourcesCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesCommentOnDocument = null;
        _body = _resp.body as ResourcesCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.FindAllCommentOnDocumentUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllCommentOnDocumentUsingGET(params: CommentOnDocumentEntityService.FindAllCommentOnDocumentUsingGETParams): Observable<ResourcesCommentOnDocument> {
    return this.findAllCommentOnDocumentUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveCommentOnDocumentUsingPOSTResponse(body: CommentOnDocument): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/commentOnDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveCommentOnDocumentUsingPOST(body: CommentOnDocument): Observable<ResourceCommentOnDocument> {
    return this.saveCommentOnDocumentUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdCommentOnDocumentUsingGETResponse(id: number): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdCommentOnDocumentUsingGET(id: number): Observable<ResourceCommentOnDocument> {
    return this.findByIdCommentOnDocumentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentOnDocumentUsingPUTResponse(params: CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPUTParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentOnDocumentUsingPUT(params: CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPUTParams): Observable<ResourceCommentOnDocument> {
    return this.saveCommentOnDocumentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteCommentOnDocumentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteCommentOnDocumentUsingDELETE(id: number): Observable<void> {
    return this.deleteCommentOnDocumentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentOnDocumentUsingPATCHResponse(params: CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPATCHParams): Observable<HttpResponse<ResourceCommentOnDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceCommentOnDocument = null;
        _body = _resp.body as ResourceCommentOnDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourceCommentOnDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveCommentOnDocumentUsingPATCH(params: CommentOnDocumentEntityService.SaveCommentOnDocumentUsingPATCHParams): Observable<ResourceCommentOnDocument> {
    return this.saveCommentOnDocumentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentOnDocumentPatientUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentOnDocumentPatientUsingGET(id: number): Observable<ResourcePatient> {
    return this.commentOnDocumentPatientUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientUsingPOSTResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPOSTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientUsingPOST(params: CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPOSTParams): Observable<ResourcePatient> {
    return this.commentOnDocumentPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientUsingPUTResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPUTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientUsingPUT(params: CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPUTParams): Observable<ResourcePatient> {
    return this.commentOnDocumentPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  commentOnDocumentPatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  commentOnDocumentPatientUsingDELETE(id: number): Observable<void> {
    return this.commentOnDocumentPatientUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientUsingPATCHResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPATCHParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientUsingPATCH(params: CommentOnDocumentEntityService.CommentOnDocumentPatientUsingPATCHParams): Observable<ResourcePatient> {
    return this.commentOnDocumentPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingGET_1(id: number): Observable<ResourcesPatientHistory> {
    return this.commentOnDocumentPatientHistoriesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingPOSTResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPOSTParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingPOST(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPOSTParams): Observable<ResourcesPatientHistory> {
    return this.commentOnDocumentPatientHistoriesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingPUTResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPUTParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingPUT(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPUTParams): Observable<ResourcesPatientHistory> {
    return this.commentOnDocumentPatientHistoriesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  commentOnDocumentPatientHistoriesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  commentOnDocumentPatientHistoriesUsingDELETE_1(id: number): Observable<void> {
    return this.commentOnDocumentPatientHistoriesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingPATCHResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPATCHParams): Observable<HttpResponse<ResourcesPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientHistory = null;
        _body = _resp.body as ResourcesPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingPATCH(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingPATCHParams): Observable<ResourcesPatientHistory> {
    return this.commentOnDocumentPatientHistoriesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingGETResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingGETParams): Observable<HttpResponse<ResourcePatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientHistories/${params.patienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientHistory = null;
        _body = _resp.body as ResourcePatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientHistory>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   *
   * @return OK
   */
  commentOnDocumentPatientHistoriesUsingGET(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingGETParams): Observable<ResourcePatientHistory> {
    return this.commentOnDocumentPatientHistoriesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   */
  commentOnDocumentPatientHistoriesUsingDELETEResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientHistories/${params.patienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `patienthistoryId`: patienthistoryId
   *
   * - `id`: id
   */
  commentOnDocumentPatientHistoriesUsingDELETE(params: CommentOnDocumentEntityService.CommentOnDocumentPatientHistoriesUsingDELETEParams): Observable<void> {
    return this.commentOnDocumentPatientHistoriesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesPatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}/patientPreviousDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientPreviousDocument = null;
        _body = _resp.body as ResourcesPatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientPreviousDocument>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingGET_1(id: number): Observable<ResourcesPatientPreviousDocument> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingPOSTResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPOSTParams): Observable<HttpResponse<ResourcesPatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientPreviousDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientPreviousDocument = null;
        _body = _resp.body as ResourcesPatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingPOST(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPOSTParams): Observable<ResourcesPatientPreviousDocument> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingPUTResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPUTParams): Observable<HttpResponse<ResourcesPatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientPreviousDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientPreviousDocument = null;
        _body = _resp.body as ResourcesPatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingPUT(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPUTParams): Observable<ResourcesPatientPreviousDocument> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  commentOnDocumentPatientPreviousDocumentsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${id}/patientPreviousDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  commentOnDocumentPatientPreviousDocumentsUsingDELETE_1(id: number): Observable<void> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingPATCHResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPATCHParams): Observable<HttpResponse<ResourcesPatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientPreviousDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesPatientPreviousDocument = null;
        _body = _resp.body as ResourcesPatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesPatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingPATCH(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingPATCHParams): Observable<ResourcesPatientPreviousDocument> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingGETParams` containing the following parameters:
   *
   * - `patientpreviousdocumentId`: patientpreviousdocumentId
   *
   * - `id`: id
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingGETResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingGETParams): Observable<HttpResponse<ResourcePatientPreviousDocument>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientPreviousDocuments/${params.patientpreviousdocumentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatientPreviousDocument = null;
        _body = _resp.body as ResourcePatientPreviousDocument;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatientPreviousDocument>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingGETParams` containing the following parameters:
   *
   * - `patientpreviousdocumentId`: patientpreviousdocumentId
   *
   * - `id`: id
   *
   * @return OK
   */
  commentOnDocumentPatientPreviousDocumentsUsingGET(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingGETParams): Observable<ResourcePatientPreviousDocument> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingDELETEParams` containing the following parameters:
   *
   * - `patientpreviousdocumentId`: patientpreviousdocumentId
   *
   * - `id`: id
   */
  commentOnDocumentPatientPreviousDocumentsUsingDELETEResponse(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/commentOnDocuments/${params.id}/patientPreviousDocuments/${params.patientpreviousdocumentId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingDELETEParams` containing the following parameters:
   *
   * - `patientpreviousdocumentId`: patientpreviousdocumentId
   *
   * - `id`: id
   */
  commentOnDocumentPatientPreviousDocumentsUsingDELETE(params: CommentOnDocumentEntityService.CommentOnDocumentPatientPreviousDocumentsUsingDELETEParams): Observable<void> {
    return this.commentOnDocumentPatientPreviousDocumentsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module CommentOnDocumentEntityService {

  /**
   * Parameters for findAllCommentOnDocumentUsingGET
   */
  export interface FindAllCommentOnDocumentUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveCommentOnDocumentUsingPUT
   */
  export interface SaveCommentOnDocumentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: CommentOnDocument;
  }

  /**
   * Parameters for saveCommentOnDocumentUsingPATCH
   */
  export interface SaveCommentOnDocumentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: CommentOnDocument;
  }

  /**
   * Parameters for commentOnDocumentPatientUsingPOST
   */
  export interface CommentOnDocumentPatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentOnDocumentPatientUsingPUT
   */
  export interface CommentOnDocumentPatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentOnDocumentPatientUsingPATCH
   */
  export interface CommentOnDocumentPatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for commentOnDocumentPatientHistoriesUsingPOST
   */
  export interface CommentOnDocumentPatientHistoriesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentOnDocumentPatientHistoriesUsingPUT
   */
  export interface CommentOnDocumentPatientHistoriesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentOnDocumentPatientHistoriesUsingPATCH
   */
  export interface CommentOnDocumentPatientHistoriesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentOnDocumentPatientHistoriesUsingGET
   */
  export interface CommentOnDocumentPatientHistoriesUsingGETParams {

    /**
     * patienthistoryId
     */
    patienthistoryId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for commentOnDocumentPatientHistoriesUsingDELETE
   */
  export interface CommentOnDocumentPatientHistoriesUsingDELETEParams {

    /**
     * patienthistoryId
     */
    patienthistoryId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for commentOnDocumentPatientPreviousDocumentsUsingPOST
   */
  export interface CommentOnDocumentPatientPreviousDocumentsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentOnDocumentPatientPreviousDocumentsUsingPUT
   */
  export interface CommentOnDocumentPatientPreviousDocumentsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentOnDocumentPatientPreviousDocumentsUsingPATCH
   */
  export interface CommentOnDocumentPatientPreviousDocumentsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for commentOnDocumentPatientPreviousDocumentsUsingGET
   */
  export interface CommentOnDocumentPatientPreviousDocumentsUsingGETParams {

    /**
     * patientpreviousdocumentId
     */
    patientpreviousdocumentId: string;

    /**
     * id
     */
    id: number;
  }

  /**
   * Parameters for commentOnDocumentPatientPreviousDocumentsUsingDELETE
   */
  export interface CommentOnDocumentPatientPreviousDocumentsUsingDELETEParams {

    /**
     * patientpreviousdocumentId
     */
    patientpreviousdocumentId: string;

    /**
     * id
     */
    id: number;
  }
}

export { CommentOnDocumentEntityService }
