/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnosticType } from '../models/resources-diagnostic-type-';
import { ResourceDiagnosticType } from '../models/resource-diagnostic-type-';
import { DiagnosticType } from '../models/diagnostic-type';
import { ResourcesDiagnosticTypeInLab } from '../models/resources-diagnostic-type-in-lab-';
import { ResourceDiagnosticTypeInLab } from '../models/resource-diagnostic-type-in-lab-';

/**
 * $ Proxy 175
 */
@Injectable()
class DiagnosticTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindAllDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindAllDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindAllDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindAllDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findAllDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticTypeUsingPOSTResponse(body: DiagnosticType): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostictypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticTypeUsingPOST(body: DiagnosticType): Observable<ResourceDiagnosticType> {
    return this.saveDiagnosticTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContaining?projection=diagnosticTypesWithLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `isActive`: isActive
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActive?projection=diagnosticTypesWithLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `isActive`: isActive
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContaining?projection=diagnosticTypesWithLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `isActive`: isActive
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `isActive`: isActive
   *
   * - `id`: id
   *
   * @return OK
   */
  findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByDiagnosticTypeStatusAndNameContaining?projection=diagnosticTypesWithLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `isActive`: isActive
   *
   * @return OK
   */
  findByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.isActive != null) __params = __params.set('isActive', params.isActive.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByDiagnosticTypeStatusAndNameContainingAndIsActive?projection=diagnosticTypesWithLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `status`: status
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * - `isActive`: isActive
   *
   * @return OK
   */
  findByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByNameContainingDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByNameContaining?projection=diagnosticTypesWithLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByNameContainingDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByNameContainingDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByNameContainingDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETResponse(params: DiagnosticTypeEntityService.FindByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/search/findByNameContainingAndDiagnosticTypeInLabsNotNull`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.FindByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `name`: name
   *
   * @return OK
   */
  findByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGET(params: DiagnosticTypeEntityService.FindByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETParams): Observable<ResourcesDiagnosticType> {
    return this.findByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticTypeUsingGET(id: number): Observable<ResourceDiagnosticType> {
    return this.findByIdDiagnosticTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeUsingPUTResponse(params: DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeUsingPUT(params: DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPUTParams): Observable<ResourceDiagnosticType> {
    return this.saveDiagnosticTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeUsingPATCHResponse(params: DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticTypeUsingPATCH(params: DiagnosticTypeEntityService.SaveDiagnosticTypeUsingPATCHParams): Observable<ResourceDiagnosticType> {
    return this.saveDiagnosticTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeChildrenUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeChildrenUsingGET_1(id: number): Observable<ResourcesDiagnosticType> {
    return this.diagnosticTypeChildrenUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingPOSTResponse(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingPOST(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPOSTParams): Observable<ResourcesDiagnosticType> {
    return this.diagnosticTypeChildrenUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingPUTResponse(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingPUT(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPUTParams): Observable<ResourcesDiagnosticType> {
    return this.diagnosticTypeChildrenUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeChildrenUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeChildrenUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticTypeChildrenUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingPATCHResponse(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticType = null;
        _body = _resp.body as ResourcesDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingPATCH(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingPATCHParams): Observable<ResourcesDiagnosticType> {
    return this.diagnosticTypeChildrenUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeId`: diagnostictypeId
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingGETResponse(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingGETParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/children/${params.diagnostictypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeId`: diagnostictypeId
   *
   * @return OK
   */
  diagnosticTypeChildrenUsingGET(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingGETParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeChildrenUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeId`: diagnostictypeId
   */
  diagnosticTypeChildrenUsingDELETEResponse(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/children/${params.diagnostictypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeId`: diagnostictypeId
   */
  diagnosticTypeChildrenUsingDELETE(params: DiagnosticTypeEntityService.DiagnosticTypeChildrenUsingDELETEParams): Observable<void> {
    return this.diagnosticTypeChildrenUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingGET_1(id: number): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingPOSTResponse(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingPOST(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPOSTParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingPUTResponse(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingPUT(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPUTParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeDiagnosticTypeInLabsUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeDiagnosticTypeInLabsUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingPATCHResponse(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/diagnosticTypeInLabs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticTypeInLab = null;
        _body = _resp.body as ResourcesDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingPATCH(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingPATCHParams): Observable<ResourcesDiagnosticTypeInLab> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingGETResponse(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingGETParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/diagnosticTypeInLabs/${params.diagnostictypeinlabId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   *
   * @return OK
   */
  diagnosticTypeDiagnosticTypeInLabsUsingGET(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingGETParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   */
  diagnosticTypeDiagnosticTypeInLabsUsingDELETEResponse(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/diagnosticTypeInLabs/${params.diagnostictypeinlabId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnostictypeinlabId`: diagnostictypeinlabId
   */
  diagnosticTypeDiagnosticTypeInLabsUsingDELETE(params: DiagnosticTypeEntityService.DiagnosticTypeDiagnosticTypeInLabsUsingDELETEParams): Observable<void> {
    return this.diagnosticTypeDiagnosticTypeInLabsUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeParentUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticTypeParentUsingGET(id: number): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeParentUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeParentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeParentUsingPOSTResponse(params: DiagnosticTypeEntityService.DiagnosticTypeParentUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeParentUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeParentUsingPOST(params: DiagnosticTypeEntityService.DiagnosticTypeParentUsingPOSTParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeParentUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeParentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeParentUsingPUTResponse(params: DiagnosticTypeEntityService.DiagnosticTypeParentUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeParentUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeParentUsingPUT(params: DiagnosticTypeEntityService.DiagnosticTypeParentUsingPUTParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeParentUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeParentUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${id}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticTypeParentUsingDELETE(id: number): Observable<void> {
    return this.diagnosticTypeParentUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeParentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeParentUsingPATCHResponse(params: DiagnosticTypeEntityService.DiagnosticTypeParentUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostictypes/${params.id}/parent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticType = null;
        _body = _resp.body as ResourceDiagnosticType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticType>;
      })
    );
  }

  /**
   * @param params The `DiagnosticTypeEntityService.DiagnosticTypeParentUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticTypeParentUsingPATCH(params: DiagnosticTypeEntityService.DiagnosticTypeParentUsingPATCHParams): Observable<ResourceDiagnosticType> {
    return this.diagnosticTypeParentUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticTypeEntityService {

  /**
   * Parameters for findAllDiagnosticTypeUsingGET
   */
  export interface FindAllDiagnosticTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGET
   */
  export interface FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams {

    /**
     * status
     */
    status?: 'PATHOLOGY' | 'RADIOLOGY';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGET
   */
  export interface FindByDiagnosticTypeInLabsDiagnosticLabIdAndDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams {

    /**
     * status
     */
    status?: 'PATHOLOGY' | 'RADIOLOGY';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * isActive
     */
    isActive?: boolean;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGET
   */
  export interface FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingDiagnosticTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGET
   */
  export interface FindByDiagnosticTypeInLabsDiagnosticLabIdAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * isActive
     */
    isActive?: boolean;

    /**
     * id
     */
    id?: number;
  }

  /**
   * Parameters for findByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGET
   */
  export interface FindByDiagnosticTypeStatusAndNameContainingDiagnosticTypeUsingGETParams {

    /**
     * status
     */
    status?: 'PATHOLOGY' | 'RADIOLOGY';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for findByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGET
   */
  export interface FindByDiagnosticTypeStatusAndNameContainingAndIsActiveDiagnosticTypeUsingGETParams {

    /**
     * status
     */
    status?: 'PATHOLOGY' | 'RADIOLOGY';
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;

    /**
     * isActive
     */
    isActive?: boolean;
  }

  /**
   * Parameters for findByNameContainingDiagnosticTypeUsingGET
   */
  export interface FindByNameContainingDiagnosticTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for findByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGET
   */
  export interface FindByNameContainingAndDiagnosticTypeInLabsNotNullDiagnosticTypeUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * name
     */
    name?: string;
  }

  /**
   * Parameters for saveDiagnosticTypeUsingPUT
   */
  export interface SaveDiagnosticTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticType;
  }

  /**
   * Parameters for saveDiagnosticTypeUsingPATCH
   */
  export interface SaveDiagnosticTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: DiagnosticType;
  }

  /**
   * Parameters for diagnosticTypeChildrenUsingPOST
   */
  export interface DiagnosticTypeChildrenUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeChildrenUsingPUT
   */
  export interface DiagnosticTypeChildrenUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeChildrenUsingPATCH
   */
  export interface DiagnosticTypeChildrenUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeChildrenUsingGET
   */
  export interface DiagnosticTypeChildrenUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeId
     */
    diagnostictypeId: string;
  }

  /**
   * Parameters for diagnosticTypeChildrenUsingDELETE
   */
  export interface DiagnosticTypeChildrenUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeId
     */
    diagnostictypeId: string;
  }

  /**
   * Parameters for diagnosticTypeDiagnosticTypeInLabsUsingPOST
   */
  export interface DiagnosticTypeDiagnosticTypeInLabsUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeDiagnosticTypeInLabsUsingPUT
   */
  export interface DiagnosticTypeDiagnosticTypeInLabsUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeDiagnosticTypeInLabsUsingPATCH
   */
  export interface DiagnosticTypeDiagnosticTypeInLabsUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticTypeDiagnosticTypeInLabsUsingGET
   */
  export interface DiagnosticTypeDiagnosticTypeInLabsUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeinlabId
     */
    diagnostictypeinlabId: string;
  }

  /**
   * Parameters for diagnosticTypeDiagnosticTypeInLabsUsingDELETE
   */
  export interface DiagnosticTypeDiagnosticTypeInLabsUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnostictypeinlabId
     */
    diagnostictypeinlabId: string;
  }

  /**
   * Parameters for diagnosticTypeParentUsingPOST
   */
  export interface DiagnosticTypeParentUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeParentUsingPUT
   */
  export interface DiagnosticTypeParentUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticTypeParentUsingPATCH
   */
  export interface DiagnosticTypeParentUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { DiagnosticTypeEntityService }
