/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesAddress } from '../models/resources-address-';
import { ResourceAddress } from '../models/resource-address-';
import { Address } from '../models/address';
import { Resourcesstring } from '../models/resources-string-';
import { ResourcePatient } from '../models/resource-patient-';

/**
 * $ Proxy 216
 */
@Injectable()
class AddressEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AddressEntityService.FindAllAddressUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAddressUsingGETResponse(params: AddressEntityService.FindAllAddressUsingGETParams): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.FindAllAddressUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllAddressUsingGET(params: AddressEntityService.FindAllAddressUsingGETParams): Observable<ResourcesAddress> {
    return this.findAllAddressUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAddressUsingPOSTResponse(body: Address): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveAddressUsingPOST(body: Address): Observable<ResourceAddress> {
    return this.saveAddressUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.FindByPatientIdAddressUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdAddressUsingGETResponse(params: AddressEntityService.FindByPatientIdAddressUsingGETParams): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/addresses/search/findByPatientId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.FindByPatientIdAddressUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdAddressUsingGET(params: AddressEntityService.FindByPatientIdAddressUsingGETParams): Observable<ResourcesAddress> {
    return this.findByPatientIdAddressUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.FindByPatientIdAndIsActiveTrueAddressUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdAndIsActiveTrueAddressUsingGETResponse(params: AddressEntityService.FindByPatientIdAndIsActiveTrueAddressUsingGETParams): Observable<HttpResponse<ResourcesAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/addresses/search/findByPatientIdAndIsActiveTrue`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesAddress = null;
        _body = _resp.body as ResourcesAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesAddress>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.FindByPatientIdAndIsActiveTrueAddressUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `patientId`: patientId
   *
   * - `page`:
   *
   * @return OK
   */
  findByPatientIdAndIsActiveTrueAddressUsingGET(params: AddressEntityService.FindByPatientIdAndIsActiveTrueAddressUsingGETParams): Observable<ResourcesAddress> {
    return this.findByPatientIdAndIsActiveTrueAddressUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  getAllCodesAddressUsingGETResponse(): Observable<HttpResponse<Resourcesstring>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/addresses/search/getAllCodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Resourcesstring = null;
        _body = _resp.body as Resourcesstring;
        return _resp.clone({body: _body}) as HttpResponse<Resourcesstring>;
      })
    );
  }

  /**
   * @return OK
   */
  getAllCodesAddressUsingGET(): Observable<Resourcesstring> {
    return this.getAllCodesAddressUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAddressUsingGETResponse(id: number): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/addresses/${id}?projection=addresses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdAddressUsingGET(id: number): Observable<ResourceAddress> {
    return this.findByIdAddressUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.SaveAddressUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAddressUsingPUTResponse(params: AddressEntityService.SaveAddressUsingPUTParams): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/addresses/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.SaveAddressUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAddressUsingPUT(params: AddressEntityService.SaveAddressUsingPUTParams): Observable<ResourceAddress> {
    return this.saveAddressUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteAddressUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/addresses/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteAddressUsingDELETE(id: number): Observable<void> {
    return this.deleteAddressUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.SaveAddressUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAddressUsingPATCHResponse(params: AddressEntityService.SaveAddressUsingPATCHParams): Observable<HttpResponse<ResourceAddress>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/addresses/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceAddress = null;
        _body = _resp.body as ResourceAddress;
        return _resp.clone({body: _body}) as HttpResponse<ResourceAddress>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.SaveAddressUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveAddressUsingPATCH(params: AddressEntityService.SaveAddressUsingPATCHParams): Observable<ResourceAddress> {
    return this.saveAddressUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  addressPatientUsingGETResponse(id: number): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/addresses/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  addressPatientUsingGET(id: number): Observable<ResourcePatient> {
    return this.addressPatientUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.AddressPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  addressPatientUsingPOSTResponse(params: AddressEntityService.AddressPatientUsingPOSTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/addresses/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.AddressPatientUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  addressPatientUsingPOST(params: AddressEntityService.AddressPatientUsingPOSTParams): Observable<ResourcePatient> {
    return this.addressPatientUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.AddressPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  addressPatientUsingPUTResponse(params: AddressEntityService.AddressPatientUsingPUTParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/addresses/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.AddressPatientUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  addressPatientUsingPUT(params: AddressEntityService.AddressPatientUsingPUTParams): Observable<ResourcePatient> {
    return this.addressPatientUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  addressPatientUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/addresses/${id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  addressPatientUsingDELETE(id: number): Observable<void> {
    return this.addressPatientUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `AddressEntityService.AddressPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  addressPatientUsingPATCHResponse(params: AddressEntityService.AddressPatientUsingPATCHParams): Observable<HttpResponse<ResourcePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/addresses/${params.id}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcePatient = null;
        _body = _resp.body as ResourcePatient;
        return _resp.clone({body: _body}) as HttpResponse<ResourcePatient>;
      })
    );
  }

  /**
   * @param params The `AddressEntityService.AddressPatientUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  addressPatientUsingPATCH(params: AddressEntityService.AddressPatientUsingPATCHParams): Observable<ResourcePatient> {
    return this.addressPatientUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module AddressEntityService {

  /**
   * Parameters for findAllAddressUsingGET
   */
  export interface FindAllAddressUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByPatientIdAddressUsingGET
   */
  export interface FindByPatientIdAddressUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for findByPatientIdAndIsActiveTrueAddressUsingGET
   */
  export interface FindByPatientIdAndIsActiveTrueAddressUsingGETParams {
    sort?: string;
    size?: number;

    /**
     * patientId
     */
    patientId?: number;
    page?: number;
  }

  /**
   * Parameters for saveAddressUsingPUT
   */
  export interface SaveAddressUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Address;
  }

  /**
   * Parameters for saveAddressUsingPATCH
   */
  export interface SaveAddressUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Address;
  }

  /**
   * Parameters for addressPatientUsingPOST
   */
  export interface AddressPatientUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for addressPatientUsingPUT
   */
  export interface AddressPatientUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for addressPatientUsingPATCH
   */
  export interface AddressPatientUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { AddressEntityService }
