/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesDiagnostic } from '../models/resources-diagnostic-';
import { ResourceDiagnostic } from '../models/resource-diagnostic-';
import { Diagnostic } from '../models/diagnostic';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceDeclineReason } from '../models/resource-decline-reason-';
import { ResourcesDiagnosticReportValue } from '../models/resources-diagnostic-report-value-';
import { ResourceDiagnosticReportValue } from '../models/resource-diagnostic-report-value-';
import { ResourceDiagnosticTypeInLab } from '../models/resource-diagnostic-type-in-lab-';
import { ResourcesDiagnosticAndPatientHistory } from '../models/resources-diagnostic-and-patient-history-';
import { ResourceDiagnosticAndPatientHistory } from '../models/resource-diagnostic-and-patient-history-';

/**
 * $ Proxy 197
 */
@Injectable()
class DiagnosticEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiagnosticEntityService.FindAllDiagnosticUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticUsingGETResponse(params: DiagnosticEntityService.FindAllDiagnosticUsingGETParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.FindAllDiagnosticUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllDiagnosticUsingGET(params: DiagnosticEntityService.FindAllDiagnosticUsingGETParams): Observable<ResourcesDiagnostic> {
    return this.findAllDiagnosticUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticUsingPOSTResponse(body: Diagnostic): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveDiagnosticUsingPOST(body: Diagnostic): Observable<ResourceDiagnostic> {
    return this.saveDiagnosticUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param body body
   * @return OK
   */
  saveReasonDiagnosticUsingPOSTResponse(body: any): Observable<HttpResponse<any>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostic/update-decline-reason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = null;
        _body = _resp.body as any;
        return _resp.clone({body: _body}) as HttpResponse<any>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveReasonDiagnosticUsingPOST(body: any): Observable<any> {
    return this.saveReasonDiagnosticUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.FindByConsultationIdDiagnosticUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdDiagnosticUsingGETResponse(params: DiagnosticEntityService.FindByConsultationIdDiagnosticUsingGETParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

    /**
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  findByDiagnosticIdDiagnosticDocUsingGETResponse(params: any): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.diagnosticId != null) __params = __params.set('diagnosticId', params.diagnosticId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostic-document/find/by-diagnostic?`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

    /**
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `diagnosticId`: diagnosticId
   *
   * @return OK
   */
  findByDiagnosticIdDiagnosticDocUsingGET(params): Observable<ResourcesDiagnostic> {
    return this.findByDiagnosticIdDiagnosticDocUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param params The `DiagnosticEntityService.FindByConsultationIdDiagnosticUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdDiagnosticUsingGET(params: DiagnosticEntityService.FindByConsultationIdDiagnosticUsingGETParams): Observable<ResourcesDiagnostic> {
    return this.findByConsultationIdDiagnosticUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.FindByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETParams` containing the following parameters:
   *
   * - `state`: state
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETResponse(params: DiagnosticEntityService.FindByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETParams): Observable<HttpResponse<ResourcesDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.startDate != null) __params = __params.set('startDate', params.startDate.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.endDate != null) __params = __params.set('endDate', params.endDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/search/findByConsultationPatientIdInAndStateAndLastModifiedBetween`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnostic = null;
        _body = _resp.body as ResourcesDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.FindByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETParams` containing the following parameters:
   *
   * - `state`: state
   *
   * - `startDate`: startDate
   *
   * - `patientId`: patientId
   *
   * - `endDate`: endDate
   *
   * @return OK
   */
  findByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGET(params: DiagnosticEntityService.FindByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETParams): Observable<ResourcesDiagnostic> {
    return this.findByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}?projection=diagnostics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdDiagnosticUsingGET(id: number): Observable<ResourceDiagnostic> {
    return this.findByIdDiagnosticUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.SaveDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticUsingPUTResponse(params: DiagnosticEntityService.SaveDiagnosticUsingPUTParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.SaveDiagnosticUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticUsingPUT(params: DiagnosticEntityService.SaveDiagnosticUsingPUTParams): Observable<ResourceDiagnostic> {
    return this.saveDiagnosticUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteDiagnosticUsingDELETE(id: number): Observable<void> {
    return this.deleteDiagnosticUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.SaveDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticUsingPATCHResponse(params: DiagnosticEntityService.SaveDiagnosticUsingPATCHParams): Observable<HttpResponse<ResourceDiagnostic>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostic = null;
        _body = _resp.body as ResourceDiagnostic;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnostic>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.SaveDiagnosticUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveDiagnosticUsingPATCH(params: DiagnosticEntityService.SaveDiagnosticUsingPATCHParams): Observable<ResourceDiagnostic> {
    return this.saveDiagnosticUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.diagnosticConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticConsultationUsingPOSTResponse(params: DiagnosticEntityService.DiagnosticConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticConsultationUsingPOST(params: DiagnosticEntityService.DiagnosticConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.diagnosticConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticConsultationUsingPUTResponse(params: DiagnosticEntityService.DiagnosticConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticConsultationUsingPUT(params: DiagnosticEntityService.DiagnosticConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.diagnosticConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticConsultationUsingDELETE(id: number): Observable<void> {
    return this.diagnosticConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticConsultationUsingPATCHResponse(params: DiagnosticEntityService.DiagnosticConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticConsultationUsingPATCH(params: DiagnosticEntityService.DiagnosticConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.diagnosticConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDeclineReasonUsingGETResponse(id: number): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDeclineReasonUsingGET(id: number): Observable<ResourceDeclineReason> {
    return this.diagnosticDeclineReasonUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDeclineReasonUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDeclineReasonUsingPOSTResponse(params: DiagnosticEntityService.DiagnosticDeclineReasonUsingPOSTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDeclineReasonUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDeclineReasonUsingPOST(params: DiagnosticEntityService.DiagnosticDeclineReasonUsingPOSTParams): Observable<ResourceDeclineReason> {
    return this.diagnosticDeclineReasonUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDeclineReasonUsingPUTResponse(params: DiagnosticEntityService.DiagnosticDeclineReasonUsingPUTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDeclineReasonUsingPUT(params: DiagnosticEntityService.DiagnosticDeclineReasonUsingPUTParams): Observable<ResourceDeclineReason> {
    return this.diagnosticDeclineReasonUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticDeclineReasonUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticDeclineReasonUsingDELETE(id: number): Observable<void> {
    return this.diagnosticDeclineReasonUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDeclineReasonUsingPATCHResponse(params: DiagnosticEntityService.DiagnosticDeclineReasonUsingPATCHParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDeclineReasonUsingPATCH(params: DiagnosticEntityService.DiagnosticDeclineReasonUsingPATCHParams): Observable<ResourceDeclineReason> {
    return this.diagnosticDeclineReasonUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/diagnosticReportValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticReportValue = null;
        _body = _resp.body as ResourcesDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingGET_1(id: number): Observable<ResourcesDiagnosticReportValue> {
    return this.diagnosticDiagnosticReportValuesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingPOSTResponse(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticReportValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticReportValue = null;
        _body = _resp.body as ResourcesDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingPOST(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPOSTParams): Observable<ResourcesDiagnosticReportValue> {
    return this.diagnosticDiagnosticReportValuesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingPUTResponse(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticReportValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticReportValue = null;
        _body = _resp.body as ResourcesDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingPUT(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPUTParams): Observable<ResourcesDiagnosticReportValue> {
    return this.diagnosticDiagnosticReportValuesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticDiagnosticReportValuesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/diagnosticReportValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticDiagnosticReportValuesUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticDiagnosticReportValuesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingPATCHResponse(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticReportValues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticReportValue = null;
        _body = _resp.body as ResourcesDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingPATCH(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingPATCHParams): Observable<ResourcesDiagnosticReportValue> {
    return this.diagnosticDiagnosticReportValuesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticreportvalueId`: diagnosticreportvalueId
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingGETResponse(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingGETParams): Observable<HttpResponse<ResourceDiagnosticReportValue>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticReportValues/${params.diagnosticreportvalueId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticReportValue = null;
        _body = _resp.body as ResourceDiagnosticReportValue;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticReportValue>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticreportvalueId`: diagnosticreportvalueId
   *
   * @return OK
   */
  diagnosticDiagnosticReportValuesUsingGET(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingGETParams): Observable<ResourceDiagnosticReportValue> {
    return this.diagnosticDiagnosticReportValuesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticreportvalueId`: diagnosticreportvalueId
   */
  diagnosticDiagnosticReportValuesUsingDELETEResponse(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticReportValues/${params.diagnosticreportvalueId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticreportvalueId`: diagnosticreportvalueId
   */
  diagnosticDiagnosticReportValuesUsingDELETE(params: DiagnosticEntityService.DiagnosticDiagnosticReportValuesUsingDELETEParams): Observable<void> {
    return this.diagnosticDiagnosticReportValuesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingGET(id: number): Observable<ResourceDiagnosticTypeInLab> {
    return this.diagnosticDiagnosticTypeInLabUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingPOSTResponse(params: DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingPOST(params: DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPOSTParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.diagnosticDiagnosticTypeInLabUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingPUTResponse(params: DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPUTParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingPUT(params: DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPUTParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.diagnosticDiagnosticTypeInLabUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticDiagnosticTypeInLabUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticDiagnosticTypeInLabUsingDELETE(id: number): Observable<void> {
    return this.diagnosticDiagnosticTypeInLabUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingPATCHResponse(params: DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosticTypeInLab>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticTypeInLab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticTypeInLab = null;
        _body = _resp.body as ResourceDiagnosticTypeInLab;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticTypeInLab>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticTypeInLabUsingPATCH(params: DiagnosticEntityService.DiagnosticDiagnosticTypeInLabUsingPATCHParams): Observable<ResourceDiagnosticTypeInLab> {
    return this.diagnosticDiagnosticTypeInLabUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingGET_1Response(id: number): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/diagnosticsAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingGET_1(id: number): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingGET_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingPOSTResponse(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPOSTParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticsAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingPOST(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPOSTParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingPUTResponse(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPUTParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticsAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingPUT(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPUTParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingDELETE_1Response(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${id}/diagnosticsAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingDELETE_1(id: number): Observable<void> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingDELETE_1Response(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingPATCHResponse(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPATCHParams): Observable<HttpResponse<ResourcesDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticsAndPatientHistories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourcesDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingPATCH(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingPATCHParams): Observable<ResourcesDiagnosticAndPatientHistory> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingGETResponse(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingGETParams): Observable<HttpResponse<ResourceDiagnosticAndPatientHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticsAndPatientHistories/${params.diagnosticandpatienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosticAndPatientHistory = null;
        _body = _resp.body as ResourceDiagnosticAndPatientHistory;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosticAndPatientHistory>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingGETParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   *
   * @return OK
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingGET(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingGETParams): Observable<ResourceDiagnosticAndPatientHistory> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingDELETEResponse(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingDELETEParams): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/diagnostics/${params.id}/diagnosticsAndPatientHistories/${params.diagnosticandpatienthistoryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param params The `DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingDELETEParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `diagnosticandpatienthistoryId`: diagnosticandpatienthistoryId
   */
  diagnosticDiagnosticsAndPatientHistoriesUsingDELETE(params: DiagnosticEntityService.DiagnosticDiagnosticsAndPatientHistoriesUsingDELETEParams): Observable<void> {
    return this.diagnosticDiagnosticsAndPatientHistoriesUsingDELETEResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticEntityService {

  /**
   * Parameters for findAllDiagnosticUsingGET
   */
  export interface FindAllDiagnosticUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdDiagnosticUsingGET
   */
  export interface FindByConsultationIdDiagnosticUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for findByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGET
   */
  export interface FindByConsultationPatientIdInAndStateAndLastModifiedBetweenDiagnosticUsingGETParams {

    /**
     * state
     */
    state?: 'OPEN' | 'COLLECTED' | 'CLINIC_DEPOSIT' | 'ACKNOWLEADGE' | 'CENTER_DEPOSITED' | 'CENTER_AKNOWLEADGE' | 'REPORT' | 'DONE';

    /**
     * startDate
     */
    startDate?: string;

    /**
     * patientId
     */
    patientId?: number;

    /**
     * endDate
     */
    endDate?: string;
  }

  /**
   * Parameters for saveDiagnosticUsingPUT
   */
  export interface SaveDiagnosticUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Diagnostic;
  }

  /**
   * Parameters for saveDiagnosticUsingPATCH
   */
  export interface SaveDiagnosticUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Diagnostic;
  }

  /**
   * Parameters for diagnosticConsultationUsingPOST
   */
  export interface DiagnosticConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticConsultationUsingPUT
   */
  export interface DiagnosticConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticConsultationUsingPATCH
   */
  export interface DiagnosticConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDeclineReasonUsingPOST
   */
  export interface DiagnosticDeclineReasonUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDeclineReasonUsingPUT
   */
  export interface DiagnosticDeclineReasonUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDeclineReasonUsingPATCH
   */
  export interface DiagnosticDeclineReasonUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDiagnosticReportValuesUsingPOST
   */
  export interface DiagnosticDiagnosticReportValuesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticDiagnosticReportValuesUsingPUT
   */
  export interface DiagnosticDiagnosticReportValuesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticDiagnosticReportValuesUsingPATCH
   */
  export interface DiagnosticDiagnosticReportValuesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticDiagnosticReportValuesUsingGET
   */
  export interface DiagnosticDiagnosticReportValuesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticreportvalueId
     */
    diagnosticreportvalueId: string;
  }

  /**
   * Parameters for diagnosticDiagnosticReportValuesUsingDELETE
   */
  export interface DiagnosticDiagnosticReportValuesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticreportvalueId
     */
    diagnosticreportvalueId: string;
  }

  /**
   * Parameters for diagnosticDiagnosticTypeInLabUsingPOST
   */
  export interface DiagnosticDiagnosticTypeInLabUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDiagnosticTypeInLabUsingPUT
   */
  export interface DiagnosticDiagnosticTypeInLabUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDiagnosticTypeInLabUsingPATCH
   */
  export interface DiagnosticDiagnosticTypeInLabUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for diagnosticDiagnosticsAndPatientHistoriesUsingPOST
   */
  export interface DiagnosticDiagnosticsAndPatientHistoriesUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticDiagnosticsAndPatientHistoriesUsingPUT
   */
  export interface DiagnosticDiagnosticsAndPatientHistoriesUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticDiagnosticsAndPatientHistoriesUsingPATCH
   */
  export interface DiagnosticDiagnosticsAndPatientHistoriesUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: Array<string>;
  }

  /**
   * Parameters for diagnosticDiagnosticsAndPatientHistoriesUsingGET
   */
  export interface DiagnosticDiagnosticsAndPatientHistoriesUsingGETParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticandpatienthistoryId
     */
    diagnosticandpatienthistoryId: string;
  }

  /**
   * Parameters for diagnosticDiagnosticsAndPatientHistoriesUsingDELETE
   */
  export interface DiagnosticDiagnosticsAndPatientHistoriesUsingDELETEParams {

    /**
     * id
     */
    id: number;

    /**
     * diagnosticandpatienthistoryId
     */
    diagnosticandpatienthistoryId: string;
  }
}

export { DiagnosticEntityService }
