/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesMedicalProcedure } from '../models/resources-medical-procedure-';
import { ResourceMedicalProcedure } from '../models/resource-medical-procedure-';
import { MedicalProcedure } from '../models/medical-procedure';
import { ResourceConsultation } from '../models/resource-consultation-';
import { ResourceDeclineReason } from '../models/resource-decline-reason-';
import { ResourceProcedureType } from '../models/resource-procedure-type-';

/**
 * $ Proxy 200
 */
@Injectable()
class MedicalProcedureEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MedicalProcedureEntityService.FindAllMedicalProcedureUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicalProcedureUsingGETResponse(params: MedicalProcedureEntityService.FindAllMedicalProcedureUsingGETParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.FindAllMedicalProcedureUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllMedicalProcedureUsingGET(params: MedicalProcedureEntityService.FindAllMedicalProcedureUsingGETParams): Observable<ResourcesMedicalProcedure> {
    return this.findAllMedicalProcedureUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicalProcedureUsingPOSTResponse(body: MedicalProcedure): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/procedures`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveMedicalProcedureUsingPOST(body: MedicalProcedure): Observable<ResourceMedicalProcedure> {
    return this.saveMedicalProcedureUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.FindByConsultationIdMedicalProcedureUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdMedicalProcedureUsingGETResponse(params: MedicalProcedureEntityService.FindByConsultationIdMedicalProcedureUsingGETParams): Observable<HttpResponse<ResourcesMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/procedures/search/findByConsultationId`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesMedicalProcedure = null;
        _body = _resp.body as ResourcesMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.FindByConsultationIdMedicalProcedureUsingGETParams` containing the following parameters:
   *
   * - `sort`:
   *
   * - `size`:
   *
   * - `page`:
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  findByConsultationIdMedicalProcedureUsingGET(params: MedicalProcedureEntityService.FindByConsultationIdMedicalProcedureUsingGETParams): Observable<ResourcesMedicalProcedure> {
    return this.findByConsultationIdMedicalProcedureUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicalProcedureUsingGETResponse(id: number): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/procedures/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdMedicalProcedureUsingGET(id: number): Observable<ResourceMedicalProcedure> {
    return this.findByIdMedicalProcedureUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.SaveMedicalProcedureUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicalProcedureUsingPUTResponse(params: MedicalProcedureEntityService.SaveMedicalProcedureUsingPUTParams): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.SaveMedicalProcedureUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicalProcedureUsingPUT(params: MedicalProcedureEntityService.SaveMedicalProcedureUsingPUTParams): Observable<ResourceMedicalProcedure> {
    return this.saveMedicalProcedureUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteMedicalProcedureUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/procedures/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteMedicalProcedureUsingDELETE(id: number): Observable<void> {
    return this.deleteMedicalProcedureUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.SaveMedicalProcedureUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicalProcedureUsingPATCHResponse(params: MedicalProcedureEntityService.SaveMedicalProcedureUsingPATCHParams): Observable<HttpResponse<ResourceMedicalProcedure>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceMedicalProcedure = null;
        _body = _resp.body as ResourceMedicalProcedure;
        return _resp.clone({body: _body}) as HttpResponse<ResourceMedicalProcedure>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.SaveMedicalProcedureUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveMedicalProcedureUsingPATCH(params: MedicalProcedureEntityService.SaveMedicalProcedureUsingPATCHParams): Observable<ResourceMedicalProcedure> {
    return this.saveMedicalProcedureUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicalProcedureConsultationUsingGETResponse(id: number): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/procedures/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicalProcedureConsultationUsingGET(id: number): Observable<ResourceConsultation> {
    return this.medicalProcedureConsultationUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureConsultationUsingPOSTResponse(params: MedicalProcedureEntityService.MedicalProcedureConsultationUsingPOSTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureConsultationUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureConsultationUsingPOST(params: MedicalProcedureEntityService.MedicalProcedureConsultationUsingPOSTParams): Observable<ResourceConsultation> {
    return this.medicalProcedureConsultationUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureConsultationUsingPUTResponse(params: MedicalProcedureEntityService.MedicalProcedureConsultationUsingPUTParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureConsultationUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureConsultationUsingPUT(params: MedicalProcedureEntityService.MedicalProcedureConsultationUsingPUTParams): Observable<ResourceConsultation> {
    return this.medicalProcedureConsultationUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicalProcedureConsultationUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/procedures/${id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicalProcedureConsultationUsingDELETE(id: number): Observable<void> {
    return this.medicalProcedureConsultationUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureConsultationUsingPATCHResponse(params: MedicalProcedureEntityService.MedicalProcedureConsultationUsingPATCHParams): Observable<HttpResponse<ResourceConsultation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/consultation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceConsultation = null;
        _body = _resp.body as ResourceConsultation;
        return _resp.clone({body: _body}) as HttpResponse<ResourceConsultation>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureConsultationUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureConsultationUsingPATCH(params: MedicalProcedureEntityService.MedicalProcedureConsultationUsingPATCHParams): Observable<ResourceConsultation> {
    return this.medicalProcedureConsultationUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicalProcedureDeclineReasonUsingGETResponse(id: number): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/procedures/${id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicalProcedureDeclineReasonUsingGET(id: number): Observable<ResourceDeclineReason> {
    return this.medicalProcedureDeclineReasonUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureDeclineReasonUsingPOSTResponse(params: MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPOSTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureDeclineReasonUsingPOST(params: MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPOSTParams): Observable<ResourceDeclineReason> {
    return this.medicalProcedureDeclineReasonUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureDeclineReasonUsingPUTResponse(params: MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPUTParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureDeclineReasonUsingPUT(params: MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPUTParams): Observable<ResourceDeclineReason> {
    return this.medicalProcedureDeclineReasonUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicalProcedureDeclineReasonUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/procedures/${id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicalProcedureDeclineReasonUsingDELETE(id: number): Observable<void> {
    return this.medicalProcedureDeclineReasonUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureDeclineReasonUsingPATCHResponse(params: MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPATCHParams): Observable<HttpResponse<ResourceDeclineReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/declineReason`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDeclineReason = null;
        _body = _resp.body as ResourceDeclineReason;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDeclineReason>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureDeclineReasonUsingPATCH(params: MedicalProcedureEntityService.MedicalProcedureDeclineReasonUsingPATCHParams): Observable<ResourceDeclineReason> {
    return this.medicalProcedureDeclineReasonUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicalProcedureProcedureTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/procedures/${id}/procedureType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  medicalProcedureProcedureTypeUsingGET(id: number): Observable<ResourceProcedureType> {
    return this.medicalProcedureProcedureTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureProcedureTypeUsingPOSTResponse(params: MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPOSTParams): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/procedureType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureProcedureTypeUsingPOST(params: MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPOSTParams): Observable<ResourceProcedureType> {
    return this.medicalProcedureProcedureTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureProcedureTypeUsingPUTResponse(params: MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPUTParams): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/procedureType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureProcedureTypeUsingPUT(params: MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPUTParams): Observable<ResourceProcedureType> {
    return this.medicalProcedureProcedureTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  medicalProcedureProcedureTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/procedures/${id}/procedureType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  medicalProcedureProcedureTypeUsingDELETE(id: number): Observable<void> {
    return this.medicalProcedureProcedureTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureProcedureTypeUsingPATCHResponse(params: MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPATCHParams): Observable<HttpResponse<ResourceProcedureType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/procedures/${params.id}/procedureType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceProcedureType = null;
        _body = _resp.body as ResourceProcedureType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceProcedureType>;
      })
    );
  }

  /**
   * @param params The `MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  medicalProcedureProcedureTypeUsingPATCH(params: MedicalProcedureEntityService.MedicalProcedureProcedureTypeUsingPATCHParams): Observable<ResourceProcedureType> {
    return this.medicalProcedureProcedureTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module MedicalProcedureEntityService {

  /**
   * Parameters for findAllMedicalProcedureUsingGET
   */
  export interface FindAllMedicalProcedureUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for findByConsultationIdMedicalProcedureUsingGET
   */
  export interface FindByConsultationIdMedicalProcedureUsingGETParams {
    sort?: string;
    size?: number;
    page?: number;

    /**
     * consultationId
     */
    consultationId?: number;
  }

  /**
   * Parameters for saveMedicalProcedureUsingPUT
   */
  export interface SaveMedicalProcedureUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicalProcedure;
  }

  /**
   * Parameters for saveMedicalProcedureUsingPATCH
   */
  export interface SaveMedicalProcedureUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: MedicalProcedure;
  }

  /**
   * Parameters for medicalProcedureConsultationUsingPOST
   */
  export interface MedicalProcedureConsultationUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureConsultationUsingPUT
   */
  export interface MedicalProcedureConsultationUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureConsultationUsingPATCH
   */
  export interface MedicalProcedureConsultationUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureDeclineReasonUsingPOST
   */
  export interface MedicalProcedureDeclineReasonUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureDeclineReasonUsingPUT
   */
  export interface MedicalProcedureDeclineReasonUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureDeclineReasonUsingPATCH
   */
  export interface MedicalProcedureDeclineReasonUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureProcedureTypeUsingPOST
   */
  export interface MedicalProcedureProcedureTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureProcedureTypeUsingPUT
   */
  export interface MedicalProcedureProcedureTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for medicalProcedureProcedureTypeUsingPATCH
   */
  export interface MedicalProcedureProcedureTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { MedicalProcedureEntityService }
