import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SendOtpControllerService } from '../../../api/auth/services'
import { ApiConfiguration } from '../../../api/auth/api-configuration'
import { SendOtpControllerService as SendOtpControllerServiceOrder } from '../../../api/orders/services'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-send-otp-dialog',
  templateUrl: './send-otp-dialog.component.html',
  styleUrls: ['./send-otp-dialog.component.scss'],
  providers: [SendOtpControllerService, ApiConfiguration]
})
export class SendOtpDialogComponent implements OnInit {

  otp: String;
  mobile: String;
  clinicName: String;
  userId: String;
  @ViewChild('ngOtpInput') ngOtpInputRef:any;
  sendingOTP: boolean;
  dobSelected: boolean = true;
  interval: any;
  timeLeft: number = 59;
  forOrder: any;
  length = 6;
  config = {length:6, allowNumbersOnly:true};
  isOtpSkipped: boolean = false;
  isuncover: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<SendOtpDialogComponent>,
    private sendOtpControllerService : SendOtpControllerService,
    private sendOtpControllerServiceOrder : SendOtpControllerServiceOrder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.mobile = this.data.mobile;
    this.clinicName = this.data.clinicName;
    this.userId = this.data.patientId;
    this.forOrder = this.data.forOrder;
    if(this.forOrder) {
      this.length = 4;
      this.config.length = 4;
    }
  }

  ngOnInit() {
    let currentHost = window.location.hostname.split('.');
    var isDermaHost = currentHost.indexOf('uncover');
    if(isDermaHost>-1){
      this.isuncover = true;
    }
    this.sendOTP();
  }

  onOtpChange(value) {
    this.otp = value;
    if( this.otp && this.otp.length > this.length-1) {
      this.verifyOTP();
    }
  }

  startTimer() {
    this.timeLeft--;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
        clearInterval(this.interval);
      }
    },1000)
  }

  sendOTP() {
    if( this.data.mobile && !this.sendingOTP) {
      this.sendingOTP = true;
      if(this.forOrder) {
        this.sendOtpControllerServiceOrder.getSendOtpUsingPOST(this.mobile).subscribe(
          res => {
            this.sendingOTP = false;
            this.ngOtpInputRef.setValue(null);
            this.startTimer();
          },
          err=> {
            this.sendingOTP = false;
            this.snackBar.open('Wrong OTP Entered', 'Okay', {
              duration: 2000,
            });
            this.ngOtpInputRef.setValue(null);
          }
        )
      } else {
        this.sendOtpControllerService.getSendOtpUsingPOST(this.mobile, this.clinicName, this.userId).subscribe(
          res => {
            this.sendingOTP = false;
            this.ngOtpInputRef.setValue(null);
            this.startTimer();
          },
          err=> {
            this.sendingOTP = false;
            this.snackBar.open('Wrong OTP Entered', 'Okay', {
              duration: 2000,
            });
            this.ngOtpInputRef.setValue(null);
          }
        )
      }
    }
  }

  verifyOTP() {
    if( this.otp && this.otp.length > this.length-1) {
      let params = {
        mobile: this.mobile,
        otp: this.otp
      };
      if(this.forOrder) {
        this.sendOtpControllerServiceOrder.postVerifyOtpUsingGET(params).subscribe(
          res => {
            if(res && res== 'true') {
              this.isOtpSkipped = false;
              this.dialogRef.close({ data: true,  isOtpSkipped: this.isOtpSkipped });
            } else {
              this.snackBar.open('Wrong OTP Entered', 'Okay', {
                duration: 2000,
              });
              this.ngOtpInputRef.setValue(null);
            }
          },
          err => {
            this.snackBar.open('Wrong OTP Entered', 'Okay', {
              duration: 2000,
            });
            this.ngOtpInputRef.setValue(null);
          }
        )
      }
      else {
        this.sendOtpControllerService.getVerifyOtpUsingGET(params).subscribe(
          res => {
            if(res && res== 'true') {
              this.isOtpSkipped = false;
              this.dialogRef.close({ data: true,  isOtpSkipped: this.isOtpSkipped });
            } else {
              this.snackBar.open('Wrong OTP Entered', 'Okay', {
                duration: 2000,
              });
              this.ngOtpInputRef.setValue(null);
            }
          },
          err => {
            this.snackBar.open('Wrong OTP Entered', 'Okay', {
              duration: 2000,
            });
            this.ngOtpInputRef.setValue(null);
          }
        )
      }
    }
  }

  skippedOtp() {
    this.isOtpSkipped = true;
    this.dialogRef.close({ data: true,  isOtpSkipped: this.isOtpSkipped });
  }

}
