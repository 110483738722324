/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams
} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourceDiagnostics } from '../models/resource-diagnostics-';
import { DiagnosticDto } from '../models/diagnostic-dto';

/**
 * Diagnostic Controller
 */
@Injectable()
class DiagnosticControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param diagnosticDto diagnosticDto
   * @return OK
   */
  postDiagnosticUsingPOSTResponse(diagnosticDto: DiagnosticDto): Observable<HttpResponse<ResourceDiagnostics>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = diagnosticDto;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/diagnostic/post`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnostics = null;
        _body = _resp.body as ResourceDiagnostics;
        return _resp.clone({ body: _body }) as HttpResponse<ResourceDiagnostics>;
      })
    );
  }

  /**
   * @param diagnosticDto diagnosticDto
   * @return OK
   */
  postDiagnosticUsingPOST(diagnosticDto: DiagnosticDto): Observable<ResourceDiagnostics> {
    return this.postDiagnosticUsingPOSTResponse(diagnosticDto).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticControllerService.UploadDiagnosticReportForPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `file`: file
   *
   * - `diagnosticIds`: diagnosticIds
   *
   * @return OK
   */
  uploadDiagnosticReportForPatientHistoryUsingPOSTResponse(params: DiagnosticControllerService.UploadDiagnosticReportForPatientHistoryUsingPOSTParams): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.file != null) __params = __params.set('file', params.file.toString());
    (params.diagnosticIds || []).forEach((val, index) => { if (val != null) __params = __params.append('diagnosticIds', val.toString()) });
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient-history/diagnosticReport`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param params The `DiagnosticControllerService.UploadDiagnosticReportForPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `patientId`: patientId
   *
   * - `file`: file
   *
   * - `diagnosticIds`: diagnosticIds
   *
   * @return OK
   */
  uploadDiagnosticReportForPatientHistoryUsingPOST(params: DiagnosticControllerService.UploadDiagnosticReportForPatientHistoryUsingPOSTParams): Observable<string> {
    return this.uploadDiagnosticReportForPatientHistoryUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `DiagnosticControllerService.UploadReportForPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `diagnosticIds`: diagnosticIds
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  uploadReportForPatientHistoryUsingPOSTResponse(params: DiagnosticControllerService.UploadReportForPatientHistoryUsingPOSTParams): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
    __formData.append('file', params.file);
    (params.diagnosticIds || []).forEach((val, index) => { if (val != null) __params = __params.append('diagnosticIds', val.toString()) });
    if (params.consultationId != null) __params = __params.set('consultationId', params.consultationId.toString());
    if (params.patientId != null) __params = __params.set('patientId', params.patientId.toString());
    if (params.doctorInClinicId != null) __params = __params.set('doctorInClinicId', params.doctorInClinicId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/patient-history/report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param params The `DiagnosticControllerService.UploadReportForPatientHistoryUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `diagnosticIds`: diagnosticIds
   *
   * - `consultationId`: consultationId
   *
   * @return OK
   */
  uploadReportForPatientHistoryUsingPOST(params: DiagnosticControllerService.UploadReportForPatientHistoryUsingPOSTParams): Observable<string> {
    return this.uploadReportForPatientHistoryUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module DiagnosticControllerService {

  /**
   * Parameters for uploadDiagnosticReportForPatientHistoryUsingPOST
   */
  export interface UploadDiagnosticReportForPatientHistoryUsingPOSTParams {

    /**
     * patientId
     */
    patientId: number;

    /**
     * file
     */
    file: string;

    /**
     * diagnosticIds
     */
    diagnosticIds: Array<number>;
  }

  /**
   * Parameters for uploadReportForPatientHistoryUsingPOST
   */
  export interface UploadReportForPatientHistoryUsingPOSTParams {

    /**
     * file
     */
    file: Blob;

    /**
     * diagnosticIds
     */
    diagnosticIds: Array<number>;

    /**
     * consultationId
     */
    consultationId: number;
    /**
     * patientId
     */
    patientId: number;
    /**
     * doctorInClinicId
     */
    doctorInClinicId: number
  }
}

export { DiagnosticControllerService }
