/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ModelAndView } from '../models/model-and-view';

/**
 * Whitelabel Error Endpoint
 */
@Injectable()
class WhitelabelErrorEndpointService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  handleErrorUsingGETResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingGET(): Observable<ModelAndView> {
    return this.handleErrorUsingGETResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingHEADResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'HEAD',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingHEAD(): Observable<ModelAndView> {
    return this.handleErrorUsingHEADResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingPOSTResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingPOST(): Observable<ModelAndView> {
    return this.handleErrorUsingPOSTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingPUTResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingPUT(): Observable<ModelAndView> {
    return this.handleErrorUsingPUTResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingDELETEResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingDELETE(): Observable<ModelAndView> {
    return this.handleErrorUsingDELETEResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingOPTIONSResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'OPTIONS',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingOPTIONS(): Observable<ModelAndView> {
    return this.handleErrorUsingOPTIONSResponse().pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingPATCHResponse(): Observable<HttpResponse<ModelAndView>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/oauth/error`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ModelAndView = null;
        _body = _resp.body as ModelAndView;
        return _resp.clone({body: _body}) as HttpResponse<ModelAndView>;
      })
    );
  }

  /**
   * @return OK
   */
  handleErrorUsingPATCH(): Observable<ModelAndView> {
    return this.handleErrorUsingPATCHResponse().pipe(
      map(_r => _r.body)
    );
  }
}

module WhitelabelErrorEndpointService {
}

export { WhitelabelErrorEndpointService }
