/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClinicForAdminDashboard } from '../models/clinic-for-admin-dashboard';

/**
 * Doctor And Clinic Controller
 */
@Injectable()
class DoctorAndClinicControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param name name
   * @return OK
   */
  getDoctorsOrClinicsUsingGETResponse(name: string): Observable<HttpResponse<Array<ClinicForAdminDashboard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (name != null) __params = __params.set('name', name.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/search/doctor-clinic`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<ClinicForAdminDashboard> = null;
        _body = _resp.body as Array<ClinicForAdminDashboard>;
        return _resp.clone({body: _body}) as HttpResponse<Array<ClinicForAdminDashboard>>;
      })
    );
  }

  /**
   * @param name name
   * @return OK
   */
  getDoctorsOrClinicsUsingGET(name: string): Observable<Array<ClinicForAdminDashboard>> {
    return this.getDoctorsOrClinicsUsingGETResponse(name).pipe(
      map(_r => _r.body)
    );
  }


  /**
   * @param ids ids
   * @return OK
   */
  fetchClinicIdsFromDicIdsUsingGETResponse(ids: number): Observable<HttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ids != null) __params = __params.set('dicIds', ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/fetch-clinic-from-dic-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Array<number> = null;
        _body = _resp.body as Array<number>;
        return _resp.clone({body: _body}) as HttpResponse<Array<number>>;
      })
    );
  }

  /**
   * @param ids ids
   * @return OK
   */
  fetchClinicIdsFromDicIdsUsingGET(ids: number): Observable<Array<number>> {
    return this.fetchClinicIdsFromDicIdsUsingGETResponse(ids).pipe(
      map(_r => _r.body)
    );
  }
}

module DoctorAndClinicControllerService {
}

export { DoctorAndClinicControllerService }
