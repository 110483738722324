/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResourcesSpecialityDiagnosisType } from '../models/resources-speciality-diagnosis-type-';
import { ResourceSpecialityDiagnosisType } from '../models/resource-speciality-diagnosis-type-';
import { SpecialityDiagnosisType } from '../models/speciality-diagnosis-type';
import { ResourceDiagnosisType } from '../models/resource-diagnosis-type-';
import { ResourceSpeciality } from '../models/resource-speciality-';

/**
 * $ Proxy 223
 */
@Injectable()
class SpecialityDiagnosisTypeEntityService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.FindAllSpecialityDiagnosisTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSpecialityDiagnosisTypeUsingGETResponse(params: SpecialityDiagnosisTypeEntityService.FindAllSpecialityDiagnosisTypeUsingGETParams): Observable<HttpResponse<ResourcesSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourcesSpecialityDiagnosisType = null;
        _body = _resp.body as ResourcesSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourcesSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.FindAllSpecialityDiagnosisTypeUsingGETParams` containing the following parameters:
   *
   * - `sort`: sort
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  findAllSpecialityDiagnosisTypeUsingGET(params: SpecialityDiagnosisTypeEntityService.FindAllSpecialityDiagnosisTypeUsingGETParams): Observable<ResourcesSpecialityDiagnosisType> {
    return this.findAllSpecialityDiagnosisTypeUsingGETResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSpecialityDiagnosisTypeUsingPOSTResponse(body: SpecialityDiagnosisType): Observable<HttpResponse<ResourceSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityDiagnosisType = null;
        _body = _resp.body as ResourceSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param body body
   * @return OK
   */
  saveSpecialityDiagnosisTypeUsingPOST(body: SpecialityDiagnosisType): Observable<ResourceSpecialityDiagnosisType> {
    return this.saveSpecialityDiagnosisTypeUsingPOSTResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSpecialityDiagnosisTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityDiagnosisType = null;
        _body = _resp.body as ResourceSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findByIdSpecialityDiagnosisTypeUsingGET(id: number): Observable<ResourceSpecialityDiagnosisType> {
    return this.findByIdSpecialityDiagnosisTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityDiagnosisTypeUsingPUTResponse(params: SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPUTParams): Observable<HttpResponse<ResourceSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityDiagnosisType = null;
        _body = _resp.body as ResourceSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityDiagnosisTypeUsingPUT(params: SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPUTParams): Observable<ResourceSpecialityDiagnosisType> {
    return this.saveSpecialityDiagnosisTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  deleteSpecialityDiagnosisTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  deleteSpecialityDiagnosisTypeUsingDELETE(id: number): Observable<void> {
    return this.deleteSpecialityDiagnosisTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityDiagnosisTypeUsingPATCHResponse(params: SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPATCHParams): Observable<HttpResponse<ResourceSpecialityDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpecialityDiagnosisType = null;
        _body = _resp.body as ResourceSpecialityDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpecialityDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  saveSpecialityDiagnosisTypeUsingPATCH(params: SpecialityDiagnosisTypeEntityService.SaveSpecialityDiagnosisTypeUsingPATCHParams): Observable<ResourceSpecialityDiagnosisType> {
    return this.saveSpecialityDiagnosisTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingGETResponse(id: number): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingGET(id: number): Observable<ResourceDiagnosisType> {
    return this.specialityDiagnosisTypeDiagnosisTypeUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingPOSTResponse(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPOSTParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingPOST(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPOSTParams): Observable<ResourceDiagnosisType> {
    return this.specialityDiagnosisTypeDiagnosisTypeUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingPUTResponse(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPUTParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingPUT(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPUTParams): Observable<ResourceDiagnosisType> {
    return this.specialityDiagnosisTypeDiagnosisTypeUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialityDiagnosisTypeDiagnosisTypeUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialityDiagnosisTypeDiagnosisTypeUsingDELETE(id: number): Observable<void> {
    return this.specialityDiagnosisTypeDiagnosisTypeUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingPATCHResponse(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPATCHParams): Observable<HttpResponse<ResourceDiagnosisType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}/diagnosisType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceDiagnosisType = null;
        _body = _resp.body as ResourceDiagnosisType;
        return _resp.clone({body: _body}) as HttpResponse<ResourceDiagnosisType>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeDiagnosisTypeUsingPATCH(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeDiagnosisTypeUsingPATCHParams): Observable<ResourceDiagnosisType> {
    return this.specialityDiagnosisTypeDiagnosisTypeUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingGETResponse(id: number): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param id id
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingGET(id: number): Observable<ResourceSpeciality> {
    return this.specialityDiagnosisTypeSpecialityUsingGETResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingPOSTResponse(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPOSTParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingPOST(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPOSTParams): Observable<ResourceSpeciality> {
    return this.specialityDiagnosisTypeSpecialityUsingPOSTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingPUTResponse(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPUTParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPUTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingPUT(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPUTParams): Observable<ResourceSpeciality> {
    return this.specialityDiagnosisTypeSpecialityUsingPUTResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param id id
   */
  specialityDiagnosisTypeSpecialityUsingDELETEResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param id id
   */
  specialityDiagnosisTypeSpecialityUsingDELETE(id: number): Observable<void> {
    return this.specialityDiagnosisTypeSpecialityUsingDELETEResponse(id).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingPATCHResponse(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPATCHParams): Observable<HttpResponse<ResourceSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/v1/emr/specialitiesDiagnosisType/${params.id}/speciality`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: ResourceSpeciality = null;
        _body = _resp.body as ResourceSpeciality;
        return _resp.clone({body: _body}) as HttpResponse<ResourceSpeciality>;
      })
    );
  }

  /**
   * @param params The `SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPATCHParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `body`: body
   *
   * @return OK
   */
  specialityDiagnosisTypeSpecialityUsingPATCH(params: SpecialityDiagnosisTypeEntityService.SpecialityDiagnosisTypeSpecialityUsingPATCHParams): Observable<ResourceSpeciality> {
    return this.specialityDiagnosisTypeSpecialityUsingPATCHResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

module SpecialityDiagnosisTypeEntityService {

  /**
   * Parameters for findAllSpecialityDiagnosisTypeUsingGET
   */
  export interface FindAllSpecialityDiagnosisTypeUsingGETParams {

    /**
     * sort
     */
    sort?: string;

    /**
     * size
     */
    size?: string;

    /**
     * page
     */
    page?: string;
  }

  /**
   * Parameters for saveSpecialityDiagnosisTypeUsingPUT
   */
  export interface SaveSpecialityDiagnosisTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: SpecialityDiagnosisType;
  }

  /**
   * Parameters for saveSpecialityDiagnosisTypeUsingPATCH
   */
  export interface SaveSpecialityDiagnosisTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: SpecialityDiagnosisType;
  }

  /**
   * Parameters for specialityDiagnosisTypeDiagnosisTypeUsingPOST
   */
  export interface SpecialityDiagnosisTypeDiagnosisTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityDiagnosisTypeDiagnosisTypeUsingPUT
   */
  export interface SpecialityDiagnosisTypeDiagnosisTypeUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityDiagnosisTypeDiagnosisTypeUsingPATCH
   */
  export interface SpecialityDiagnosisTypeDiagnosisTypeUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityDiagnosisTypeSpecialityUsingPOST
   */
  export interface SpecialityDiagnosisTypeSpecialityUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityDiagnosisTypeSpecialityUsingPUT
   */
  export interface SpecialityDiagnosisTypeSpecialityUsingPUTParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }

  /**
   * Parameters for specialityDiagnosisTypeSpecialityUsingPATCH
   */
  export interface SpecialityDiagnosisTypeSpecialityUsingPATCHParams {

    /**
     * id
     */
    id: number;

    /**
     * body
     */
    body: string;
  }
}

export { SpecialityDiagnosisTypeEntityService }
